import React from 'react'
import GeneralIndex from '../GeneralIndex/GeneralIndex'
import PositiveCorrelatedIndex from '../PositiveCorrelatedIndex/PositiveCorrelatedIndex'
import NegativeCorrelatedIndex from '../NegativeCorrelatedIndex/NegativeCorrelatedIndex'
import { Grid } from '@mui/material'

const MetricsContent = ({ variable, country, inicialDate, timeInterval, isFetchingData }) => {
	return (
		<Grid container style={{ marginTop: '0px' }} spacing={3}>
			<Grid item xs={12}>
				<PositiveCorrelatedIndex
					variable={variable}
					country={country}
					inicialDate={inicialDate}
					timeInterval={timeInterval}
					isFetchingData={isFetchingData}
				/>
			</Grid>
			<Grid item xs={12}>
				<NegativeCorrelatedIndex
					variable={variable}
					country={country}
					inicialDate={inicialDate}
					timeInterval={timeInterval}
					isFetchingData={isFetchingData}
				/>
			</Grid>
			<Grid item xs={12}>
				<GeneralIndex
					variable={variable}
					country={country}
					inicialDate={inicialDate}
					timeInterval={timeInterval}
					isFetchingData={isFetchingData}
				/>
			</Grid>
		</Grid>
	)
}

export default MetricsContent
