import React, { useState, useEffect, memo } from 'react'
import { TextField, Grid, Card, Collapse, Button, styled } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const ManifestationsGlobalFilters = ({ valueControlled, onChange, toggleManifestations, openManifestations }) => {
	const [value, setValue] = useState(valueControlled || { higher: '', lower: '' })

	const onChangeValue = (newValue, type) => {
		setValue({ ...value, [type]: newValue })
	}

	useEffect(() => {
		onChange(value)
	}, [value])

	useEffect(() => {
		if (JSON.stringify(valueControlled) !== JSON.stringify(value)) {
			setValue(valueControlled)
		}
	}, [valueControlled])

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Button
						id='btn-toggle-manifestations'
						onClick={toggleManifestations}
						fullWidth
						style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
					>
						{!openManifestations && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{openManifestations && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						Engajamento
					</Button>
				</Grid>

				<Collapse in={openManifestations}>
					<Grid xs={12} container item style={{ marginBottom: '20px' }}>
						<Grid item xs={6}>
							<CssTextField
								id='input-manifestations-higher'
								variant='standard'
								onChange={(e) => onChangeValue(e.target.value, 'higher')}
								value={value.higher}
								label={<div>Maiores ou iguais a</div>}
								type='number'
								style={{ marginLeft: '15px', width: '100%' }}
							/>
						</Grid>
						<Grid item xs={6}>
							<CssTextField
								id='input-manifestations-lower'
								variant='standard'
								value={value.lower}
								onChange={(e) => onChangeValue(e.target.value, 'lower')}
								label='Menores ou iguais a'
								type='number'
								style={{ marginLeft: '50px', width: '100%' }}
							/>
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Card>
	)
}

export default memo(ManifestationsGlobalFilters)
