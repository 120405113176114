import axios from 'axios'
import { logoutAction } from '../store/redux/actions/user.actions'
import { store } from 'store/redux/store'
import { getLoggedUserToken, clearAuthLocalStorage, getSharingToken } from 'store/local-storage/store'
import { API_URL } from 'base'

const api = axios.create({ baseURL: `${API_URL}/api` })

api.interceptors.request.use(async (config) => {
	const path = window.location.pathname
	const pathIsSharing = path.split('/').slice(1, 3).join('/').includes('sharing/dashboard')
	const userToken = pathIsSharing ? getSharingToken() : getLoggedUserToken()
	if (userToken) {
		config.headers.Authorization = `Bearer ${userToken}`
	}

	return config
})

api.interceptors.response.use(
	(response) => {
		if (response.status === 403) {
			store.dispatch(logoutAction())
			clearAuthLocalStorage()
		}
		return response
	},
	(error) => {
		if (error.response && error.response.status === 403) {
			store.dispatch(logoutAction())
			clearAuthLocalStorage()
		}
		return Promise.reject(error)
	}
)

export default api
