Moment.locale('pt-br')
import React, { useState, useEffect } from 'react'
import { Grid, Dialog, IconButton, CardContent, DialogContent, DialogTitle, Collapse, List, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClockIcon, CloseIcon, LoadingIcon, CardIcon } from 'utils/SystemIcons'
import Moment from 'moment-timezone'
import api from 'service/service'
import { isEmpty } from 'lodash'

const grey = '#b8b8b8'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const checkValidLog = (logList, color) => {
	if (isEmpty(logList)) {
		return 'Não existem logs para este cartão!'
	} else {
		return logList.map((log) => {
			if (log.userOrSubUserName) {
				return (
					<ListItem key={log.date}>
						<Grid style={{ color: color }}>{getLogFormatted(log)}</Grid>
					</ListItem>
				)
			}
		})
	}
}

const getLogFormatted = (log) => {
	return `${
		'- O ' +
		log.userType.toLowerCase() +
		' ' +
		log.userOrSubUserName +
		' ' +
		log.log.toLowerCase() +
		' em ' +
		Moment(log.date).format('DD/MM/YYYY') +
		' às ' +
		Moment(log.date).format('HH:mm:ss') +
		'.'
	}`
}

const ModalCardLog = ({ isOpen, handleOnClose, card, color }) => {
	const classes = useStyles()
	const [cardInfo, setCardInfo] = useState(null)
	const [logList, setLogList] = useState(null)
	const [loadingLogList, setLoadingLogList] = useState(false)

	useEffect(() => {
		setCardInfo(card)
	}, [card])

	useEffect(() => {
		setLoadingLogList(true)
		if (cardInfo) {
			api.get(`/monitoring/card/get-log-by-type/${cardInfo.id}/${cardInfo?.type}`)
				.then((response) => {
					setLogList(response.data)
				})
				.finally(() => setLoadingLogList(false))
		}
	}, [cardInfo])

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
					maxWidth: '50em'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<ClockIcon className='fix-icon-margin-bottom-negative' size={25} color={color} /> Logs do cartão
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
				<CardContent style={{ padding: 0, margin: 0 }}>
					<Grid
						container
						style={{
							padding: '1.5em',
							borderRadius: '20px',
							color: 'rgb(120, 120, 120)',
							fontFamily: 'Poppins',
							fontWeight: '300',
							fontSize: '20px',
							minWidth: '35em'
						}}
					>
						<Grid item xs={12} style={{ display: 'flex', paddingTop: 20, paddingLeft: '0.6em' }}>
							<CardIcon
								className='fix-icon-margin-bottom-negative'
								size={25}
								color={color}
								style={{ marginTop: '0.15em', marginRight: '0.4em', color }}
							/>
							<div style={{ color: color, fontSize: 20 }}>{cardInfo?.name}</div>
							{loadingLogList && (
								<div style={{ marginLeft: '10px', marginTop: '2px' }}>
									<LoadingIcon size={20} thickness={1} style={{ color: color }} />
								</div>
							)}
						</Grid>

						<Collapse in={!loadingLogList}>
							{!loadingLogList && (
								<Grid container className='cursor-default' style={{ margin: '1em', fontSize: '0.7em', color: 'default' }}>
									<List>{checkValidLog(logList, color)}</List>
								</Grid>
							)}
						</Collapse>
					</Grid>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default ModalCardLog
