//notificationconfigdialog.util.js
export const typeEnum = [
	{ label: 'Apareça a palavra:', value: 'WORD' },
	{ label: 'O engajamento* alcance um valor maior do que:', value: 'ENGAGEMENT' },
	{ label: 'O número de postagens alcance um valor de:', value: 'OCCURRENCE' }
]

// export const typeEnumProfile = [
// 	{ label: 'O engajamento* alcance o valor de:', value: 'ENGAGEMENT' },
// 	{ label: 'Os posts alcancem um valor de:', value: 'OCCURRENCE' },
// 	{ label: 'Apareça a palavra:', value: 'WORD' }
// ]

const allTypes = [
	{ label: 'Apareça a palavra:', value: 'WORD' },
	{ label: 'O engajamento* alcance um valor maior do que:', value: 'ENGAGEMENT' },
	{ label: 'O número de postagens alcance um valor de:', value: 'OCCURRENCE' }
]

export const intervalUnitEnum = [
	{ label: 'Minutos', value: 'MINUTE' },
	{ label: 'Horas', value: 'HOUR' },
	{ label: 'Dias', value: 'DAY' },
	{ label: 'Semanas', value: 'WEEK' },
	{ label: 'Meses', value: 'MONTH' }
]

export const getTypeLabel = (value) => allTypes.find((it) => it.value === value)?.label
export const getTypeValue = (label) => allTypes.find((it) => it.label === label)?.value

export const getIntervalUnityLabel = (value) => intervalUnitEnum.find((it) => it.value === value)?.label
export const getIntervalUnityValue = (label) => intervalUnitEnum.find((it) => it.label === label)?.value
