import React, { useState, useEffect } from 'react'
import { Button, Grid, TextField, Dialog, IconButton, CardContent, DialogContent, DialogTitle, Card, styled, DialogContentText } from '@mui/material'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import { CloseIcon, PencilIcon, DeleteBinIcon } from 'utils/SystemIcons'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const ModalEditFolder = ({
	isOpen,
	toggle,
	folderId,
	loadGroups,
	isOpenDelete,
	onConfirmDelete,
	toggleDelete,
	type,
	folderName,
	canEditFolder,
	canDeleteFolder
}) => {
	const [thisFolderName, setThisFolderName] = useState(folderName || '')
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		setThisFolderName(folderName)
		setSubmitting(false)
	}, [isOpen, folderName])

	const registerGroup = () => {
		let isGroupNameCorrect = true
		if (thisFolderName === '') {
			sendMessageToast('Por favor digite um nome válido para a pasta.', constantsVox.TOAST.TYPES.WARNING)
			isGroupNameCorrect = false
		}

		if (isGroupNameCorrect) {
			setSubmitting(true)
			api.post('/folder/edit-folder', { folderName: thisFolderName, folderId, type })
				.then((response) => {
					loadGroups()
					toggle()
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => {
					setSubmitting(false)
				})
		}
	}

	return (
		<>
			<Dialog
				id='dialog-app'
				open={isOpen}
				onClose={toggle}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='sm'
			>
				<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
					<DialogTitle>
						<div className='jc-space-between d-flex ai-center'>
							<div>
								<PencilIcon className='fix-icon-margin-bottom-negative' size={25} color='#1d8cf8' />{' '}
								{canEditFolder ? 'Editar pasta' : 'Sem permissão para editar pasta'}
							</div>
							<div>
								<IconButton color='primary' style={{ outline: 'none' }} onClick={toggle}>
									<CloseIcon color='#1d8cf8' size={20} />
								</IconButton>
							</div>
						</div>
					</DialogTitle>
					{canEditFolder && (
						<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
							<CardContent style={{ borderRadius: '4px' }}>
								<Grid item xs={12}>
									<CssTextField
										variant='standard'
										label='Novo nome'
										size='small'
										className='d-flex'
										style={{ color: '#1d8cf8', borderRadius: '4px' }}
										value={thisFolderName}
										onChange={(e) => setThisFolderName(e.target.value)}
									/>
								</Grid>
							</CardContent>
							<CardContent style={{ borderRadius: '4px' }}>
								<Grid item xs={12} container justifyContent={'center'}>
									<Button
										style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
										variant='outlined'
										color='primary'
										disabled={submitting || thisFolderName == ''}
										onClick={registerGroup}
									>
										Salvar
									</Button>
								</Grid>
							</CardContent>
						</DialogContent>
					)}
					{!canEditFolder && (
						<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
							<DialogContentText id='alert-dialog-description'>contate o administrador</DialogContentText>
						</DialogContent>
					)}
					<Grid container item xs={12}>
						<Grid item xs={4} container justifyContent='flex-start' style={{ color: '#ff2b6b' }}>
							{canDeleteFolder && (
								<Button variant='text' color='inherit' onClick={toggleDelete} disabled={submitting} style={{ borderRadius: '25px' }}>
									<DeleteBinIcon color='#ff2b6b' size={20} style={{ transform: 'translate(0px, -2px)', marginRight: '5px' }} />
									Deletar
								</Button>
							)}
						</Grid>
					</Grid>
				</Card>
			</Dialog>
			<Dialog
				id='dialog-app'
				open={isOpenDelete}
				onClose={toggleDelete}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='sm'
			>
				<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
					<DialogTitle>
						<div className='jc-space-between d-flex ai-center'>
							<div style={{ color: '#ff2b6b' }}>
								<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' /> Deseja realmente deletar a
								pasta?
							</div>
						</div>
					</DialogTitle>
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<Grid item xs={12} style={{ borderRadius: '4px', color: 'rgb(170, 170, 170)', marginTop: '20px', marginLeft: '3px' }}>
							Seus cartões ainda estarão visiveis em &apos;TODOS&apos;.
						</Grid>
					</DialogContent>
					<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='center'>
						<Grid item xs={6} container justifyContent='flex-start'>
							<Button variant='text' color='inherit' onClick={toggleDelete} disabled={submitting} style={{ borderRadius: '25px' }}>
								Cancelar
							</Button>
						</Grid>
						<Grid item xs={6} container justifyContent='flex-end'>
							<Button
								variant='text'
								color='inherit'
								onClick={() => {
									setSubmitting(true)
									onConfirmDelete()
								}}
								style={{ color: '#ff2b6b', borderRadius: '25px' }}
								disabled={submitting}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Dialog>
		</>
	)
}

export default ModalEditFolder
