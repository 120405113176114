import React from 'react'
import { Grid } from '@mui/material'
import BarMetrics from 'pages/AccountManagement/components/BarMetrics/BarMetrics'
import NumericMetric from 'pages/AccountManagement/components/NumericMetric/NumericMetric'
import { getVideoLikesByReactionTypeFormatted } from '../../../../utils/viewPostMetrics.utils'

const FacebookPostTypeMetrics = ({ cardData }) => {
	const videoLikesByReactionType = getVideoLikesByReactionTypeFormatted(cardData)
	const descriptions = cardData.descriptions

	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.comment_count}
						title={descriptions.find((item) => item.key === 'facebook_comments_count')?.title}
						description={descriptions.find((item) => item.key === 'facebook_comments_count')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions_unique}
						title={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.blue_reels_play_count}
						title={descriptions.find((item) => item.key === 'facebook_blue_reels_play_count')?.title}
						description={descriptions.find((item) => item.key === 'facebook_blue_reels_play_count')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_avg_time_watched}
						title={descriptions.find((item) => item.key === 'facebook_video_avg_time_watched')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_avg_time_watched')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_view_time}
						title={descriptions.find((item) => item.key === 'facebook_video_view_time')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_view_time')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={12} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={videoLikesByReactionType.values}
						categories={videoLikesByReactionType.labels}
						title={descriptions.find((item) => item.key === 'facebook_video_likes_by_reaction_type')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_likes_by_reaction_type')?.description}
						label='Quantidade'
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default FacebookPostTypeMetrics
