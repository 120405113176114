import React, { useState } from 'react'
import { TextareaAutosize, Button } from '@mui/material'

const AddAnalysis = () => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div>
			<div className='hide-print'>
				<Button className='color-blue' onClick={() => setIsOpen(!isOpen)}>
					{isOpen ? 'Remover Descrição' : 'Adicionar Descrição'}
				</Button>
			</div>

			{isOpen && (
				<div>
					<TextareaAutosize
						aria-label='minimum height'
						rowsMin={3}
						placeholder='Adicionar descrição'
						cols={100}
						style={{
							border: 'none',
							color: 'black',
							padding: '0.3em',
							textAlign: 'center',
							fontSize: '1.1em'
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default AddAnalysis
