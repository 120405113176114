import React, { useState, useEffect, memo } from 'react'
import { Button, Grid, Collapse, Tooltip } from '@mui/material'
import './NetworkInfo.css'
import { GoVerified } from 'react-icons/go'
import defaultImage from 'assets/img/twitterEmpty.jpg'
import InspectIntervalButton from 'graphs/components/InspectIntervalButton/InspectIntervalButton'
import { connect } from 'react-redux'
import UserProfileMenuMore from 'components/UserProfileMenuMore/UserProfileMenuMore'

const NetworkInfo = ({ cards, nodeMetrics, globalFiltersTermRedux, fullScreenHandle }) => {
	const [open, setOpen] = useState(false)
	const [image, setImage] = useState(defaultImage)
	const [mainComparison, setMainComparison] = useState(nodeMetrics?.networkItem?.cardName)
	const [thisFilters, setThisFilters] = useState(globalFiltersTermRedux)
	useEffect(() => {
		loadInfo()
	}, [nodeMetrics])

	useEffect(() => {
		open === true && handleToggle()
		setThisFilters(globalFiltersTermRedux)
	}, [globalFiltersTermRedux])

	useEffect(() => {
		loadInfo()
	}, [fullScreenHandle])

	const loadInfo = () => {
		setImage(nodeMetrics?.imageUrl)
		setMainComparison(nodeMetrics?.networkItem?.cardName)
		if (nodeMetrics?.title && nodeMetrics?.networkItem?.source) {
			let newFilters = {
				...thisFilters,
				sources: [nodeMetrics.networkItem.source],
				advancedFilters: { ...thisFilters?.advancedFilters, authorNameSearch: [nodeMetrics?.title] }
			}
			setThisFilters(newFilters)
		}
	}

	const handleToggle = () => {
		setOpen(!open)
	}

	const checkImageError = () => {
		setImage(defaultImage)
	}

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<Button onClick={handleToggle} style={{ outline: 'none' }}>
					<img
						style={{
							width: '50px',
							height: '50px',
							borderRadius: '50px',
							border: 'solid 2px #1d8cf8'
						}}
						src={image}
						onError={checkImageError}
						alt=''
					/>
				</Button>{' '}
				<div
					style={{
						margin: '10px',
						fontWeight: '500',
						fontSize: '16px',
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						color: '#1d8cf8'
					}}
				>
					<div>
						{nodeMetrics?.title}
						{nodeMetrics?.author?.verified && (
							<Tooltip title='Usuário verificado' enterDelay={200} enterNextDelay={200}>
								<span
									style={{
										cursor: 'pointer',
										marginLeft: '0.3em'
									}}
								>
									<GoVerified size={15} />
								</span>
							</Tooltip>
						)}
					</div>
					{cards[0]?.type !== 'group' && (
						<UserProfileMenuMore
							// cardColor={cardColor}
							source={nodeMetrics?.networkItem?.source}
							cardTitle={nodeMetrics?.networkItem?.author?.username}
							selectedCard={cards[0]}
							showMenuItem={['haters', 'lovers']}
						/>
					)}
				</div>
			</div>
			{(fullScreenHandle.active === true || nodeMetrics?.networkItem?.type === 'account') && nodeMetrics?.networkItem?.author?.username && (
				<InspectIntervalButton
					cards={cards}
					filters={{
						...thisFilters,
						advancedFilters: { ...thisFilters.advancedFilters, authorNameSearch: [nodeMetrics?.networkItem?.author?.username] }
					}}
					border={false}
					mainComparison={mainComparison}
					cardType={cards[0]?.type}
					showCloud={true}
				/>
			)}
			<Collapse in={open}>
				<div className='divider-thin-default' />
				<Grid container style={{ fontSize: '14px', padding: '1em' }}>
					<Grid item md={6} xs={12}>
						<span style={{ fontSize: '1.1em', color: 'black' }}> Rank #{nodeMetrics?.score}</span>
					</Grid>
				</Grid>
				<Grid container style={{ fontSize: '14px', padding: '1em' }}>
					<Grid container item xs={12}>
						{nodeMetrics?.networkItem?.author?.name && (
							<Grid item md={6} xs={12}>
								<span
									style={{
										fontSize: '1.3em',
										color: 'black'
									}}
								>
									{nodeMetrics?.networkItem?.author?.name}
								</span>
							</Grid>
						)}
						{nodeMetrics?.networkItem?.author?.link && (
							<Grid item md={6} xs={12} style={{ textAlign: 'end' }}>
								<a
									href={nodeMetrics.networkItem.author.link}
									target='_blank'
									rel='noopener noreferrer'
									className='network-info-link-abrir-perfil'
								>
									Abrir perfil
								</a>
							</Grid>
						)}
					</Grid>

					{nodeMetrics?.networkItem?.author?.followerCount && (
						<Grid item xs={12}>
							<span
								style={{
									fontSize: '1.1em',
									color: 'black'
								}}
							>
								{nodeMetrics?.networkItem?.author?.followerCount}
							</span>{' '}
							<span style={{ color: '#9a9a9a' }}>seguidores</span>
						</Grid>
					)}
					{nodeMetrics?.networkItem?.author?.followingCount && (
						<Grid item xs={12}>
							<span
								style={{
									fontSize: '1.1em',
									color: 'black'
								}}
							>
								{nodeMetrics?.networkItem?.author?.followingCount}
							</span>{' '}
							<span style={{ color: '#9a9a9a' }}>seguindo</span>
						</Grid>
					)}
					{nodeMetrics?.networkItem?.author?.postCount && (
						<Grid item xs={12}>
							<span
								style={{
									fontSize: '1.1em',
									color: 'black'
								}}
							>
								{nodeMetrics?.networkItem?.author?.postCount}
							</span>{' '}
							<span style={{ color: '#9a9a9a' }}>posts</span>
						</Grid>
					)}
					{nodeMetrics?.networkItem?.bioText && (
						<Grid item xs={12} style={{ marginTop: '1em', color: '#9a9a9a' }}>
							{nodeMetrics?.networkItem?.author?.bioText}
						</Grid>
					)}
				</Grid>
				<div className='divider-thin-default' />
			</Collapse>
		</div>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(NetworkInfo))
