import React from 'react'
import AnalysisCardSkeletonLoad from '../AnalysisCardSkeletonLoad/AnalysisCardSkeletonLoad'

const MonitoringCardsSkeletonLoad = () => (
	<>
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
		<AnalysisCardSkeletonLoad />
	</>
)

export default MonitoringCardsSkeletonLoad
