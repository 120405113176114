import React, { memo } from 'react'
import { Grid, Button, Switch, Radio } from '@mui/material'
import { toggleSideMenuOption } from '../../utils/SideMenuFunctions'

const SingleOption = ({
	title = '',
	checked,
	isSwitch = true,
	option,
	value,
	thisOptions,
	onChangeOptions,
	isComp,
	isSubOption = false,
	isFinancial = false
}) => {
	const handleOnClick = () => {
		if (isFinancial) {
			onChangeOptions({
				predOnlyPositive: option === 'predOnlyPositive' ? !thisOptions.predOnlyPositive : thisOptions.predOnlyPositive,
				predFocus: option === 'predFocus' ? !thisOptions.predFocus : thisOptions.predFocus,
				predWithFocus: option === 'predWithFocus' ? !thisOptions.predWithFocus : thisOptions.predWithFocus,
				predWithoutFocus: option === 'predWithoutFocus' ? !thisOptions.predWithoutFocus : thisOptions.predWithoutFocus,
				predPosteriorAverage: option === 'predPosteriorAverage' ? !thisOptions.predPosteriorAverage : thisOptions.predPosteriorAverage,
				predMultiSerie: option === 'predMultiSerie' ? !thisOptions.predMultiSerie : thisOptions.predMultiSerie,
				predMultiSeriePostAverage:
					option === 'predMultiSeriePostAverage' ? !thisOptions.predMultiSeriePostAverage : thisOptions.predMultiSeriePostAverage,
				predMultiSeriePostAverageBenchmark:
					option === 'predMultiSeriePostAverageBenchmark'
						? !thisOptions.predMultiSeriePostAverageBenchmark
						: thisOptions.predMultiSeriePostAverageBenchmark
			})
		} else {
			toggleSideMenuOption(option, value, thisOptions, onChangeOptions, isComp)
		}
	}
	return (
		<Grid item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Button
				id={`btn-menu-single-${option}`}
				onClick={handleOnClick}
				fullWidth
				style={{
					height: '36.5px',
					fontFamily: 'Poppins',
					fontWeight: 'bold',
					fontSize: isSubOption ? '12px' : '14px',
					color: checked ? '#1d8cf8' : '#9a9a9a',
					justifyContent: 'space-between',
					borderRadius: '20px',
					width: isSubOption ? '100%' : '95%',
					backgroundColor: isSubOption ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)',
					marginTop: '10px',
					marginBottom: '10px',
					marginLeft: '10px',
					marginRight: isSubOption ? '0px' : '10px'
				}}
			>
				{title}
				{isSwitch && <Switch color='primary' checked={checked} />}
				{!isSwitch && <Radio color='primary' checked={checked} />}
			</Button>
		</Grid>
	)
}
export default memo(SingleOption)
