import React, { useState, useEffect, memo } from 'react'
import moment from 'moment-timezone'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { graphOptionsArea } from 'graphs/utils/GraphOptionsArea'
import InspectIntervalButton from '../../../components/InspectIntervalButton/InspectIntervalButton'
import { Grid } from '@mui/material'
import NowcastingOptionsMenu from '../NowcastingOptionsMenu/NowcastingOptionsMenu'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import NowcastingGraphLine from './NowcastingLine'
import NowcastingGraphBars from './NowcastingBar'
import NowcastingGraphTotals from './NowcastingTotal'

const defaultDate = {
	fromDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
}

const emptyProp = {
	options: {
		chart: {
			type: 'line'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const defaultOptions = {
	type: 'posts',
	engagement: false,
	amount: true,
	social: false,
	posts: true,
	gender: false,
	users: false,
	men: false,
	women: false,
	organization: false,
	noClass: false,
	sources: false,
	politics: false,
	comments: false,
	retweets: false
}

let interval = defaultDate
let canLockTime = false

const GraficoImpactoNowcasting = ({
	cards,
	fullScreenState = false,
	isReport = false,
	onPartialLoading,
	isFetchingData,
	filters,
	cardType = 'term',
	options = defaultOptions,
	changeExternalInterval,
	defaultGraphTime,
	lockTimeInterval
}) => {
	const [loading, setLoading] = useState(true)
	const [windowSize, setWindowSize] = useState(window.innerHeight)
	const [comparison, setComparison] = useState('absolute')
	const [graphTime, setGraphTime] = useState(defaultGraphTime)
	const [graphView, setGraphView] = useState('line')
	const [chart, setChart] = useState(<></>)

	useEffect(() => {
		canLockTime = false
		setGraphTime(defaultGraphTime)
	}, [defaultGraphTime])

	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: cards?.map((card) => card.id)
		},
		globalFilters: filters,
		componentProperties: {
			comparison,
			graphTime,
			hasEngagement: options.engagement,
			hasPosts: options.posts,
			hasUsers: options.users,
			hasMen: options.men,
			hasWomen: options.women,
			hasOrganization: options.organization,
			hasNoClass: options.noClass,
			hasLeft: options.left_wing,
			hasRight: options.right_wing,
			hasCenterLeft: options.centre_left_wing,
			hasCenterRight: options.centre_right_wing,
			hasCenter: options.centre,
			hasTwitter: options.twitter,
			hasFacebook: options.facebook,
			hasInstagram: options.instagram,
			hasTiktok: options.tiktok,
			hasYoutube: options.youtube,
			hasNews: options.news,
			hasComments: options.comments,
			hasShares: options.retweets,
			type: options.type
		}
	})

	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/analysis/nowcasting/get-graph-data',
		updateItemName: 'Nowcasting',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (filters.fromDate !== interval.fromDate || filters.toDate !== interval.toDate) {
			interval = { fromDate: filters.fromDate, toDate: filters.toDate }
			if (changeExternalInterval) {
				changeExternalInterval(interval)
			}
		}
	}, [filters.fromDate, filters.toDate])

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	useEffect(() => {
		if (!loading && onPartialLoading) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	if (verifyServerResponseCanShowToast(error) && !isReport) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	useEffect(() => {
		if (fullScreenState) {
			setWindowSize(435)
		} else {
			setWindowSize(window.innerHeight / 1.2 - 120)
		}
		if (isReport) {
			setWindowSize(350)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullScreenState])

	const onIntervalChange = (xaxis) => {
		if (xaxis === undefined || xaxis.min === undefined || xaxis.max === undefined) {
			interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
			changeExternalInterval(interval)
			canLockTime = false
		} else {
			const fromDate = moment(xaxis.min).tz('UTC').format('YYYY-MM-DD')
			const toDate = moment(xaxis.max).tz('UTC').format('YYYY-MM-DD')
			const fromHour = graphTime === 'day' ? '00:00:00' : moment(xaxis.min).format('HH:mm:ss')
			const toHour = graphTime === 'day' ? '23:59:59' : moment(xaxis.max).format('HH:mm:ss')

			interval = { fromDate, toDate, fromHour, toHour }
			canLockTime = true
			changeExternalInterval(interval)
		}
	}

	const handleChangeGraphType = (newComparison) => {
		setComparison(newComparison)
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const handleChangeGraphTime = (newTime) => {
		setGraphTime(newTime)
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const handleSelectGraphView = (newView) => {
		setGraphView(newView)
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		changeExternalInterval(interval)
	}

	const onClickLockInterval = () => {
		canLockTime = false
		lockTimeInterval(interval)
	}

	const getInterval = () => interval
	const getCanLockTime = () => canLockTime

	const getOptions = (type) => {
		if (data?.formatted?.options) {
			if (type === 'voxAi') {
				return graphOptionsArea(
					isReport,
					data?.formatted?.options?.title,
					data?.formatted?.options?.colors,
					'smooth',
					onIntervalChange,
					graphTime,
					false,
					false,
					true,
					'timelineVoxAi',
					true
				)
			} else {
				return graphOptionsArea(
					isReport,
					data?.formatted?.options?.title,
					data?.formatted?.options?.colors,
					'smooth',
					onIntervalChange,
					graphTime,
					false
				)
			}
		} else {
			return emptyProp.options
		}
	}

	useEffect(() => {
		if (isFetched && !isFetching) {
			setChart(handleChart())
		}
	}, [isFetched, isFetching, graphTime, graphView, options, comparison, filters])

	const handleChart = () => {
		if (graphTime === 'day' && graphView === 'bars') {
			return (
				<NowcastingGraphBars
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={true}
					height={windowSize / 1.2}
				/>
			)
		} else if (graphTime === 'day' && graphView === 'totals') {
			return (
				<NowcastingGraphTotals
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={false}
					height={windowSize / 1.2}
				/>
			)
		} else {
			return (
				<NowcastingGraphLine
					data={data?.formatted}
					isReport={isReport}
					title={data?.formatted?.options?.title}
					colors={data?.formatted?.options?.colors}
					curve={'smooth'}
					onIntervalChange={onIntervalChange}
					graphTime={graphTime}
					isSolid={false}
					height={windowSize / 1.2}
				/>
			)
		}
	}

	return (
		<>
			{loading ? (
				<GraphSkeletonLoad />
			) : (
				<>
					<Grid container>
						<Grid item xs={12}>
							{!isReport && (
								<NowcastingOptionsMenu
									timelineType={options.type}
									size={cards.length}
									fromDate={filters?.fromDate}
									toDate={filters?.toDate}
									handleChangeGraphType={handleChangeGraphType}
									handleChangeGraphTime={handleChangeGraphTime}
									handleSelectGraphView={handleSelectGraphView}
									selectedGraphView={graphView}
									initialGraphTime={defaultGraphTime}
									getCanLockTime={getCanLockTime}
									onClickLockInterval={onClickLockInterval}
								/>
							)}
						</Grid>
					</Grid>
					<Grid style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
						<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>{chart}</div>

						<Grid container direction='row' justifyContent='space-between' alignItems='center'>
							{!isReport && fullScreenState && (
								<div style={{ minHeight: '64.34px' }}>
									<InspectIntervalButton
										cards={cards}
										getInterval={getInterval}
										filters={filters}
										cardType={cardType}
										showRadar={cardType === 'term' && filters.sources.includes('twitter')}
										showTags={cardType === 'term'}
										graphData={{
											options: getOptions('voxAi'),
											series: data?.formatted?.series ? data?.formatted?.series : emptyProp.series,
											type: options.engagement === false && cards.length === 1 ? 'line' : 'area',
											width: '100%',
											height: windowSize / 1.2,
											graphTime: graphTime
										}}
										onIntervalChange={onIntervalChange}
									/>
								</div>
							)}
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}

export default memo(GraficoImpactoNowcasting)
