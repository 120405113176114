import React, { memo } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 60%)'

const useStyles = makeStyles(() => ({
	tabGroup: {
		marginTop: '0px',
		marginLeft: '8px',
		textAlign: 'left',
		float: 'left'
	},
	tab: {
		outline: 'none',
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
}))

const SourceTypeSelector = ({ sourceType, onSourceTypeChange, cardType, source }) => {
	const classes = useStyles()
	const handleSelectTab = (_, newValue) => {
		onSourceTypeChange(newValue)
	}
	return (
		<>
			<Tabs
				centered
				value={sourceType}
				className={classes.tabGroup}
				onChange={handleSelectTab}
				TabIndicatorProps={{ style: { background: blue } }}
			>
				<Tab id='tab-network-account' label='Contas/Páginas/Sites' value='account' className={classes.tab} />
				{/* {cardType !== 'group' && source.includes('twitter') && (
					<Tab id='tab-network-hashtags' label='Hashtags' value='hashtags' className={classes.tab} />
				)} */}
			</Tabs>
		</>
	)
}

export default memo(SourceTypeSelector)
