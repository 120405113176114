import React, { useState, useEffect } from 'react'
// import { useForm  } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import api from 'service/service'
import { Box, Container, CircularProgress } from '@mui/material'

// const defaultValues = {
//   terms: false,
// }

const ValidateEmail = () => {
	const { token } = useParams()

	//   const { register, formState: { errors }, handleSubmit, control } = useForm({defaultValues})
	//   const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(true)
	const [info, setInfo] = useState('')

	useEffect(() => {
		onLoad()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onLoad = async () => {
		setLoading(true)

		try {
			const request = await api.post('/validate-email', {
				token: token
			})

			setInfo(request.data.message)
		} catch (e) {
			setInfo('Ops, token inválido')
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<Container>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<Box bgcolor='white' p={10} mt={10} mb={10}>
						<Box display='flex' justifyContent='center' alignItems='center'>
							<img width='200em' alt='...' src={require('assets/img/logo-vox-radar.png')} />
						</Box>
						<Box textAlign='center' mt={10}>
							{loading && <CircularProgress />}
							{!loading && (
								<>
									<Box>
										<p style={{ color: '#000000' }}>
											<b>{info}</b>
										</p>
									</Box>
									<Box mt={4}>
										<a
											style={{
												color: '#1d8cf8',
												cursor: 'pointer'
											}}
											href='/login'
										>
											Entrar
										</a>
									</Box>
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default ValidateEmail
