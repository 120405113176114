import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	List,
	ListItem,
	Typography
} from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageUploader from 'react-images-upload'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import Gallery from 'react-photo-gallery'
import imageCompression from 'browser-image-compression'
import blobToBase64 from 'utils/blobToBase64'

const ImageAnnotationProjectTasks = ({ projectId, projectCvatId, isOpen, handleOnClose }) => {
	const auraProjectId = projectId
	const [tasks, setTasks] = useState([])
	const [, setLoading] = useState(true)
	const [, setError] = useState(null)
	const [uploading, setUploading] = useState(false)
	const [uploadMessage, setUploadMessage] = useState(null)
	const [key, setKey] = useState(Date.now())
	const [isFullScreen, setIsFullScreen] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [taskImages, setTaskImages] = useState(null)
	const [continuationToken, setContinuationToken] = useState(null)

	const fetchTasks = async (token = null, images = null) => {
		try {
			const response = await api.get(
				`/image-annotation/tasks/${auraProjectId}/${taskId}/${encodeURIComponent(token ? token : continuationToken)}`
			)
			if (Array.isArray(response.data.data)) {
				const newData = response.data.data.map((it) => {
					const newImages = images ? images : taskImages
					if (it.id === taskId) {
						it.images = [...newImages, ...it.images]
					}
					return it
				})
				setTasks(newData)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching tasks:', err)
			setError('Error fetching tasks')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchTasks()
	}, [auraProjectId])

	const getStatusColor = (status) => {
		switch (status) {
			case 'NEW':
				return 'blue'
			case 'IN_PROGRESS':
				return 'orange'
			case 'COMPLETED':
				return 'green'
			case 'ERROR':
				return 'red'
			default:
				return 'grey'
		}
	}

	const createTask = async (numberTasksInProject) => {
		const nameNewTask = `Task ${numberTasksInProject + 1}`
		try {
			const response = await api.post(`/image-annotation/create-task/${nameNewTask}/project/${auraProjectId}`)
			alert('Tarefa criada com sucesso no CVAT! Nome: ' + nameNewTask)
			await fetchTasks()
			return response.data
		} catch (error) {
			console.error('Erro ao criar tarefa:', error)
			alert('Erro ao criar tarefa.')
		}
	}

	const handleImageUpload = async (imageFile, image64) => {
		setUploading(true)
		setUploadMessage('Uploading images...')

		const numberOfExistingTasks = tasks.length
		const taskCreated = await createTask(numberOfExistingTasks)

		if (taskCreated?.data) {
			const taskId = taskCreated.data.id

			const images = await Promise.all(
				imageFile.map(async (file, index) => {
					if (file.type === 'image/webp') {
						const options = {
							maxSizeMB: 1,
							maxWidthOrHeight: 1920,
							useWebWorker: true,
							fileType: 'image/jpeg' // Specify the output format as JPEG
						}
						const compressedFile = await imageCompression(file, options)
						return await blobToBase64(compressedFile)
					} else {
						return image64[index]
					}
				})
			)

			try {
				await api.post(`/image-annotation/task/${taskId}/project/${auraProjectId}/upload-images`, { images })
				alert('Imagens enviadas com sucesso para a tarefa!')
				await fetchTasks()
			} catch (error) {
				console.error('Erro ao enviar imagens:', error)
				alert('Erro ao enviar imagens.')
			}
		} else {
			alert('Erro ao criar tarefa.')
		}
		setUploading(false)
		setUploadMessage(null)
		setKey(Date.now())
	}

	const handleInputsUpload = async (taskId) => {
		setUploading(true)
		setUploadMessage('Uploading inputs..')

		try {
			await api.post(`/image-annotation/task/${taskId}/project/${auraProjectId}/upload-inputs`)
			alert('Inputs enviadas com sucesso para o S3!')
			await fetchTasks()
		} catch (error) {
			console.error('Erro ao enviar inputs:', error)
			alert('Erro ao enviar inputs.')
		}

		setUploading(false)
		setUploadMessage(null)
	}

	const handleSyncData = async () => {
		setUploading(true)
		setUploadMessage('Sincronizando dados..')

		try {
			await api.post(`/image-annotation/project/${auraProjectId}/${projectCvatId}/sync-tasks`)
			alert('Dados sincronizados com o CVAT!')
			await fetchTasks()
		} catch (error) {
			console.error('Erro ao sincronizar dados:', error)
			alert('Erro ao sincronizar dados.')
		}

		setUploading(false)
		setUploadMessage(null)
	}

	return (
		<Dialog fullWidth={true} maxWidth='md' open={isOpen} onClose={handleOnClose}>
			<DialogTitle>Lista de Tarefas</DialogTitle>
			<DialogContent>
				<ImageUploader
					key={key}
					buttonClassName='image-button'
					withIcon={false}
					withLabel={false}
					buttonText={'Criar nova tarefa'}
					fileContainerStyle={{
						backgroundColor: 'transparent',
						boxShadow: 'none'
					}}
					onChange={handleImageUpload}
					imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
					maxFileSize={5242880}
				/>
				{uploading && (
					<Box display='flex' alignItems='center' flexDirection='column' mt={2}>
						<CircularProgress />
						<Typography variant='body2' mt={1}>
							{uploadMessage}
						</Typography>
					</Box>
				)}
				<Button variant='contained' onClick={handleSyncData}>
					Sincronizar dados
				</Button>
				<Box style={{ width: '95%', padding: '16px', backgroundColor: '#f7f7f7' }}>
					<List>
						{tasks.map((task) => (
							<ListItem key={task.id} style={{ padding: '8px', marginBottom: '8px' }}>
								<Card style={{ width: '100%' }}>
									<Grid container>
										<Grid item xs={4}>
											<CardContent style={{ padding: '8px' }}>
												<Typography variant='h6' gutterBottom>
													{`${task.id} - ${task.name}`}
												</Typography>
												<Chip
													label={task.status}
													style={{
														backgroundColor: getStatusColor(task.status),
														color: 'white',
														marginBottom: '8px',
														fontSize: '0.75rem'
													}}
												/>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													CVAT ID: {task.cvatId}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Projeto ID: {task.auraProjectsId}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Criado em: {moment(task.createdAt).format('DD/MM/YYYY')}
												</Typography>
												<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
													Status upload: {task.saveModelInputLastDate ? 'OK' : 'Não realizado'}
												</Typography>
												{task.saveModelInputLastDate && (
													<Typography variant='body2' style={{ fontSize: '0.875rem' }}>
														Ultimo upload em: {moment(task.saveModelInputLastDate).format('DD/MM/YYYY')}
													</Typography>
												)}
												{task.status === 'COMPLETED' && (
													<Button variant='contained' onClick={() => handleInputsUpload(task.id)}>
														Upload inputs S3
													</Button>
												)}
											</CardContent>
										</Grid>
										<Grid item xs={8}>
											<CardContent>
												{task.images.length > 0 && (
													<>
														{!isFullScreen && (
															<ImageGallery
																items={task?.images?.map((image) => ({
																	original: image.src,
																	originalHeight: '200px',
																	originalWidth: '300px'
																}))}
																useBrowserFullscreen={false}
																showBullets={task?.images?.length <= 10 ? true : false}
																showIndex={true}
																autoPlay={true}
																onScreenChange={() => {
																	setTaskId(task.id)
																	setTaskImages(task.images)
																	setContinuationToken(task.continuationTokenImages)
																	setIsFullScreen(!isFullScreen)
																}}
															/>
														)}
														{isFullScreen && task.id === taskId && (
															<Dialog
																fullWidth={true}
																maxWidth='md'
																open={isFullScreen}
																onClose={() => {
																	setTaskId(null)
																	setTaskImages(null)
																	setContinuationToken(null)
																	setIsFullScreen(false)
																}}
															>
																<Gallery
																	photos={task?.images?.map((image) => ({
																		...image,
																		height: 1,
																		width: 1
																	}))}
																/>
																{task.hasMoreImages && (
																	<Button
																		variant='contained'
																		onClick={() => fetchTasks(task.continuationTokenImages, task.images)}
																	>
																		Carregar mais
																	</Button>
																)}
															</Dialog>
														)}
													</>
												)}
											</CardContent>
										</Grid>
									</Grid>
								</Card>
							</ListItem>
						))}
					</List>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default ImageAnnotationProjectTasks
