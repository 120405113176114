import React, { memo, useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import LocationStateAndRegionGraph from 'graphs/LocationStateAndRegionGraph/LocationStateAndRegionGraph'
import LocationGraph from 'components/LocationGraph/LocationGraph'
import { connect } from 'react-redux'

const LocationContent = ({ cardStatus, cards, isFetchingData, globalFiltersTermRedux }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<LocationGraph cards={cardList} />
			)}
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<LocationStateAndRegionGraph
					cardStatus={cardStatus}
					cards={cardList}
					isFetchingData={isFetchingData}
					filters={globalFiltersTermRedux}
				/>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(LocationContent))
