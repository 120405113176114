import React, { useState, useEffect } from 'react'
import api from 'service/service'
import { Grid, Button, IconButton, CardContent, Dialog, DialogContent, TextField, DialogTitle } from '@mui/material'
import { EditIcon, CloseIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const EditTopicDialog = ({ topic, openEditDialog, toggleEditTopic, update, cardId }) => {
	const [thisTitle, setThisTitle] = useState(topic.title)
	const [thisSummary, setThisSummary] = useState(topic.summary)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		setThisTitle(topic.title)
		setThisSummary(topic.summary)
	}, [openEditDialog])

	const saveTopic = (event) => {
		event.preventDefault()

		setSubmitting(true)
		api.post('/topic-analysis/edit-topic', {
			topicId: topic.topic_modeling_ia_id,
			title: thisTitle,
			summary: thisSummary,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleEditTopic()
				update()
			})
	}

	return (
		<Dialog
			open={openEditDialog}
			onClose={toggleEditTopic}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth={'md'}
			PaperProps={{
				style: {
					overflowX: 'hidden',
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div>
						<EditIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8' }} /> Editar Tópico
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={toggleEditTopic}>
							<CloseIcon color='#1d8cf8' size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ overflowY: 'hidden', marginBottom: '0.2em', marginTop: '0.2em' }}>
				<CardContent style={{ borderRadius: '4px' }}>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label='Título'
							size='small'
							className='d-flex'
							style={{ color: '#1d8cf8' }}
							sx={{
								'& .MuiOutlinedInput-root': {
									fontFamily: 'Poppins',
									'& fieldset': {
										borderRadius: '25px'
									}
								}
							}}
							value={thisTitle}
							onChange={(e) => setThisTitle(e.target.value)}
						/>
					</Grid>
				</CardContent>
				<CardContent style={{ borderRadius: '4px' }}>
					<Grid item xs={12}>
						<TextField
							variant='outlined'
							label='Resumo'
							size='small'
							multiline
							minRows={5}
							maxRows={15}
							className='d-flex'
							style={{ color: '#1d8cf8' }}
							sx={{
								'& .MuiOutlinedInput-root': {
									fontFamily: 'Poppins',
									'& fieldset': {
										borderRadius: '15px'
									}
								}
							}}
							value={thisSummary}
							onChange={(e) => setThisSummary(e.target.value)}
						/>
					</Grid>
				</CardContent>
				<CardContent style={{ borderRadius: '4px' }}>
					<Grid item xs={12} container justifyContent={'center'}>
						<Button
							style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
							variant='outlined'
							color='primary'
							disabled={submitting || thisTitle == '' || thisSummary == ''}
							onClick={saveTopic}
						>
							Salvar
						</Button>
					</Grid>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default EditTopicDialog
