import React, { useState, useEffect, memo } from 'react'
import { Dialog, DialogContent, IconButton, DialogTitle, Grid } from '@mui/material'
import { CloseIcon, PlusIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import initFacebookSdk from 'utils/facebookSdkInit'
import axios from 'axios'
import api from 'service/service'
import MessageInstagram from './components/MessageInstagram/MessageInstagram'
import MessageFacebook from './components/MessageFacebook/MessageFacebook'
import MessageError from './components/MessageError/MessageError'
import NetworkButton from './components/NetworkButton/NetworkButton'
import MessageMetaAds from './components/MessageMetaAds/MessageMetaAds'

const isFirefox = typeof InstallTrigger !== 'undefined'

const scopeInstagram =
	'pages_show_list, read_insights, pages_read_engagement, pages_read_engagement, pages_manage_engagement, business_management, instagram_basic, instagram_manage_insights'
const scopeFacebook = 'pages_show_list, read_insights, pages_read_engagement, pages_read_engagement, pages_manage_engagement, business_management'
const scopeMetaAds = 'pages_show_list, ads_read, business_management'

const NetworksDialog = ({ item, isOpen, onCloseNetworksDialog }) => {
	const [openAlertDialogFacebook, setOpenAlertDialogFacebook] = useState(false)
	const [openAlertDialogInstagram, setOpenAlertDialogInstagram] = useState(false)
	const [openAlertDialogMetaAds, setOpenAlertDialogMetaAds] = useState(false)
	const [facebookPages, setFacebookPages] = useState([])
	const [shortTermUserData, setShortTermUserData] = useState(null)
	const [openMessage, setOpenMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const getUrlRequest = () => {
		const cardAccountId = item.id
		return `account-management/profile/get-networks/${cardAccountId}`
	}

	let { data, refetch, isFetched } = useUpdateData({
		url: getUrlRequest(),
		updateItemName: 'accountManagerNetworks',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: true,
		queryName: 'voxradar_ai_topics'
	})

	useEffect(() => {
		initFacebookSdk()
	}, [])

	useEffect(() => {
		if (shortTermUserData !== null) {
			loadFacebookPages()
		}
	}, [shortTermUserData])

	const loadFacebookPages = async () => {
		const userID = shortTermUserData.userID
		const provider = shortTermUserData.provider
		const shortTermUserToken = shortTermUserData.shortTermUserToken
		const longlivedPages = await getUserPages(userID, shortTermUserToken, provider)
		if (longlivedPages.data.length > 0) {
			longlivedPages.data.forEach(function (page) {
				const pageId = page.id
				const pageName = page.name
				const pageToken = page.access_token
				const longLivedUserToken = longlivedPages.longLivedUserToken
				setFacebookPages((facebookPages) => [
					...facebookPages,
					{ value: pageId, label: pageName, token: pageToken, userToken: longLivedUserToken }
				])
			})

			switch (provider) {
				case 'meta_facebook':
					setOpenAlertDialogFacebook(true)
					break
				case 'meta_instagram':
					setOpenAlertDialogInstagram(true)
					break
				case 'meta_ads':
					setOpenAlertDialogMetaAds(true)
					break
				default:
					break
			}
		} else {
			setOpenMessage('empty')
		}
	}

	const handleConnectMetaButton = (provider) => {
		/* eslint-disable no-undef */
		/* prettier-ignore */
		let  selectedScope = ''
		switch (provider) {
			case 'meta_facebook':
				selectedScope = scopeFacebook
				break
			case 'meta_instagram':
				selectedScope = scopeInstagram
				break
			case 'meta_ads':
				selectedScope = scopeMetaAds
				break
			default:
				break
		}
		FB.login(
			(response) => {
				if (response.authResponse) {
					setFacebookPages([])
					const shortTermUserToken = response.authResponse.accessToken
					const userID = response.authResponse.userID
					setShortTermUserData({ shortTermUserToken, userID, provider })
				}
			},
			{ scope: selectedScope }
		)
	}

	const getUserPages = async (userID, shortTermUserToken, provider) => {
		const { data } = await api
			.get(`/account-management/profile/get-facebook-pages/${userID}/${shortTermUserToken}/${provider}`)
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
		return data
	}

	const handleSelectPageAlertDialog = async (id, provider) => {
		getIntegrationData(provider, facebookPages, id, item.id)
	}

	const getIntegrationData = async (provider, userPages, selectedPageId, cardId) => {
		setLoading(true)
		const selectedFacebookPage = userPages.find((page) => page.value == selectedPageId)
		const selectedFacebookPageId = selectedFacebookPage.value
		const selectedFacebookPageLongLivedToken = selectedFacebookPage.token
		const selectedFacebookUserLongLivedToken = selectedFacebookPage.userToken
		let serviceId = selectedFacebookPageId
		let tokenToSave = selectedFacebookPageLongLivedToken

		if (provider == 'meta_instagram') {
			serviceId = await getInstagramBusinessAccountId(selectedFacebookPageId, selectedFacebookPageLongLivedToken)
		}

		if (provider == 'meta_ads') {
			tokenToSave = selectedFacebookUserLongLivedToken
		}

		saveCredentials(provider, tokenToSave, cardId, serviceId)
	}

	const getInstagramBusinessAccountId = async (pageId, pageToken) => {
		const { data } = await axios.get(`https://graph.facebook.com/v19.0/${pageId}?fields=instagram_business_account&access_token=${pageToken}`)
		return data.instagram_business_account.id
	}

	const saveCredentials = (provider, token, cardId, serviceId) => {
		if (serviceId === undefined) {
			sendMessageToast('Você não tem acesso ao Meta Ads dessa página', 'ERROR')
			setLoading(false)
			return
		}
		api.post('/account-management/credentials/create', {
			provider: provider,
			card_account_manager_id: cardId,
			access_token_1: serviceId,
			access_token_2: token,
			active: 1,
			available_versions: 'v1'
		}).then((e) => {
			setOpenAlertDialogFacebook(false)
			setOpenAlertDialogInstagram(false)
			setOpenAlertDialogMetaAds(false)
			setLoading(false)
			setOpenMessage('')
			refetch()
			if (e?.data?.toast) {
				sendMessageToast(e?.data?.toast?.message, e?.data?.toast?.type)
			}
		})
	}

	const onDisconnect = (cardAccountId, network) => {
		setLoading(true)
		const cardId = item.id
		api.post('/account-management/credentials/delete', {
			cardAccountId: cardAccountId,
			cardId: cardId,
			network: network
		}).then((e) => {
			setLoading(false)
			refetch()
			if (e?.data?.toast) {
				sendMessageToast(e?.data?.toast?.message, e?.data?.toast?.type)
			}
		})
	}

	const openInstagram = () => {
		setOpenMessage('instagram')
	}
	const openMetaAds = () => {
		setOpenMessage('metaAds')
	}

	const confirmInstagram = () => {
		handleConnectMetaButton('meta_instagram')
	}

	const openFacebook = () => {
		setOpenMessage('facebook')
	}

	const confirmFacebook = () => {
		handleConnectMetaButton('meta_facebook')
	}
	const confirmMetaAds = () => {
		handleConnectMetaButton('meta_ads')
	}

	return (
		<Dialog
			open={isOpen}
			onClose={onCloseNetworksDialog}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth='md'
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div>
						<PlusIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8', marginRight: '8px' }} />
						Conecte redes sociais ao perfil {item.name}
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={onCloseNetworksDialog}>
							<CloseIcon color='#1d8cf8' size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ overflowY: 'hidden', marginBottom: '15px' }}>
				<AlertDialog
					isOpen={openAlertDialogFacebook}
					loading={loading}
					title='Selecione uma das suas páginas do Facebook para conectar'
					options={facebookPages}
					onClose={() => setOpenAlertDialogFacebook(false)}
					onSelection={(value) => handleSelectPageAlertDialog(value, 'meta_facebook')}
				/>
				<AlertDialog
					isOpen={openAlertDialogInstagram}
					loading={loading}
					title='Selecione uma das suas páginas do Facebook que tenham acesso ao Instagram que deseja conectar'
					options={facebookPages}
					onClose={() => setOpenAlertDialogInstagram(false)}
					onSelection={(value) => handleSelectPageAlertDialog(value, 'meta_instagram')}
				/>
				<AlertDialog
					isOpen={openAlertDialogMetaAds}
					loading={loading}
					title='Selecione o Meta Ad que deseja conectar'
					options={facebookPages}
					onClose={() => setOpenAlertDialogMetaAds(false)}
					onSelection={(value) => handleSelectPageAlertDialog(value, 'meta_ads')}
					provider='meta_ads'
				/>
				<MessageInstagram
					isOpen={openMessage === 'instagram'}
					title='Requisitos para integração Instagram Business'
					onClose={() => setOpenMessage('')}
					onConfirm={confirmInstagram}
				/>
				<MessageFacebook
					isOpen={openMessage === 'facebook'}
					title='Requisitos para integração Facebook'
					onClose={() => setOpenMessage('')}
					onConfirm={confirmFacebook}
				/>
				<MessageMetaAds
					isOpen={openMessage === 'metaAds'}
					title='Requisitos para integração Meta Ads'
					onClose={() => setOpenMessage('')}
					onConfirm={confirmMetaAds}
				/>

				<MessageError isOpen={openMessage === 'empty'} title='Sem páginas para conectar' onClose={() => setOpenMessage('')} />
				<Grid
					container
					style={{ marginTop: '0em', width: '100%', padding: '1em' }}
					spacing={3}
					justifyContent='flex-start'
					alignItems='flex-start'
				>
					<Grid item xs={3}>
						<NetworkButton
							network='facebook'
							color='#3b5998'
							onConnect={openFacebook}
							onDisconnect={onDisconnect}
							connectedStatus={data?.facebook?.connectedStatus}
							cardAccountId={data?.facebook?.id}
							loading={!isFetched}
							buttonLoading={loading}
							label='Facebook'
						/>
					</Grid>

					<Grid item xs={3}>
						<NetworkButton
							network='instagram'
							color='#e1306c'
							onConnect={openInstagram}
							onDisconnect={onDisconnect}
							connectedStatus={data?.instagram?.connectedStatus}
							cardAccountId={data?.instagram?.id}
							loading={!isFetched}
							buttonLoading={loading}
							label='Instagram Business'
						/>
					</Grid>
					<Grid item xs={3}>
						<NetworkButton
							network='metaAds'
							color='#016AF2'
							onConnect={openMetaAds}
							onDisconnect={onDisconnect}
							connectedStatus={data?.metaAds?.connectedStatus}
							cardAccountId={data?.metaAds?.id}
							loading={!isFetched}
							buttonLoading={loading}
							label='Meta Ads'
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default memo(NetworksDialog)
