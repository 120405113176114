import React, { useEffect, useState, useRef } from 'react'
import {
	Grid,
	Dialog,
	CardContent,
	DialogContent,
	DialogTitle,
	Button,
	IconButton,
	Collapse,
	Box,
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Paper,
	Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useQueryClient } from 'react-query'
import { PlusIcon, CloseIcon, LoadingIcon, HistoryIcon } from 'utils/SystemIcons'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import SourceSelectorGlobalFilters from 'components/GlobalFilters/components/SourceSelectorGlobalFilters/SourceSelectorGlobalFilters'
import { DEFAULT_OCCURRENCE, DEFAULT_OCCURRENCE_OPTIONAL, DEFAULT_EXCLUSIVE } from './modalOccurrenceUtils'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import AddOccurrenceInputCard from './components/AddOccurrenceInputCard/AddOccurrenceInputCard'
// adiciona o objeto no postgres metadata, e passa o id e qualquer outra coisa que falaram pra fila de processamento

const grey = '#b8b8b8'
const color = '#1d8cf8'
const SERVICE_HISTORY_NAME = 'addOccurrence'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 100%)',
		boxShadow: 'none',
		color: 'white',
		backgroundColor: 'rgba(29, 138, 248, 100%)',
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 25%)',
			backgroundColor: 'rgba(29, 138, 248, 75%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const steps = [
	{
		label: 'Selecione a fonte da postagem',
		description: ''
	},
	{
		label: 'Adicione o conteúdo da postagem',
		description: ''
	}
]

const isFirefox = typeof InstallTrigger !== 'undefined'

const getAllFields = (source) => [...DEFAULT_OCCURRENCE, ...DEFAULT_OCCURRENCE_OPTIONAL, ...DEFAULT_EXCLUSIVE[source]]

const validateOnlyNumbers = (inputValue) => /^\d*$/g.test(inputValue)

const currentDate = moment().format('YYYY-MM-DD')
const minDate = '2020-01-01'

const validateNewOccurrence = (newOccurrence) => {
	const fieldErrors = []
	if (!newOccurrence.author_username || newOccurrence.author_username === '') {
		fieldErrors.push({ field: 'author_username', message: 'Campo deve ser preenchido' })
	}
	if (!newOccurrence.content_text || newOccurrence.content_text === '') {
		fieldErrors.push({ field: 'content_text', message: 'Campo deve ser preenchido' })
	}
	if (!newOccurrence.content_posted_at || newOccurrence.content_posted_at === '') {
		fieldErrors.push({ field: 'content_posted_at', message: 'Data inválida' })
	}

	if (!moment(dayjs(newOccurrence.content_posted_at).format('YYYY-MM-DD')).isBetween(minDate, currentDate, null, '[]')) {
		fieldErrors.push({ field: 'content_posted_at', message: 'Data inválida' })
	}

	if (newOccurrence?.content_attachments?.file) {
		const limit = 5 * 1024 * 1024 // 5MB
		if (newOccurrence.content_attachments.file.size > limit) {
			fieldErrors.push({ field: 'content_attachments', message: 'Selecione um arquivo menor que 5mb' })
		}
	}
	return fieldErrors
}

const RenderOccurencesTextFields = ({ source, onChange, fieldErrors, controlledValue, activeStep }) => {
	const isFirstRender = useRef(true)

	const [newOccurrenceForm, setNewOccurrenceForm] = useState(
		controlledValue ||
			getAllFields(source).reduce((acc, curr) => {
				acc[curr.key] = curr.value
				return acc
			}, {})
	)

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		} else {
			setNewOccurrenceForm(
				getAllFields(source).reduce((acc, curr) => {
					acc[curr.key] = curr.value
					return acc
				}, {})
			)
		}
	}, [source])

	const onChangeField = (value, field, inputType) => {
		if (inputType === 'number') {
			if (!validateOnlyNumbers(value)) {
				return
			} else {
				value = Number(value)
			}
		}
		const newOccurrence = { ...newOccurrenceForm, [field]: value }
		setNewOccurrenceForm(newOccurrence)
	}

	useEffect(() => {
		onChange(newOccurrenceForm)
	}, [newOccurrenceForm])

	return (
		<Grid container style={{ marginBottom: '1em' }}>
			{activeStep === 1 && (
				<Grid container item spacing={2} style={{ marginTop: 0 }}>
					<AddOccurrenceInputCard
						source={source}
						newOccurrenceForm={newOccurrenceForm}
						onChangeField={onChangeField}
						fieldErrors={fieldErrors}
					/>
				</Grid>
			)}
		</Grid>
	)
}

const ModalAddOccurrence = ({ isOpen, handleOnClose, cardId, cardName, editOccurrenceId, editOccurrenceSource, searchType }) => {
	const classes = useStyles()
	const [submitting, setSubmitting] = useState(false)
	const [newOccurrence, setNewOccurrence] = useState(null)
	const [selectedSource, setSelectedSource] = useState([editOccurrenceSource] || [])
	const [fieldErrors, setFieldErrors] = useState([])
	const [openHistory, setOpenHistory] = useState(false)
	const [activeStep, setActiveStep] = React.useState(editOccurrenceId ? 1 : 0)
	const queryClient = useQueryClient()

	const toggleHistory = () => {
		setOpenHistory(!openHistory)
	}

	const onSourceChange = (sources) => {
		setFieldErrors([])
		setSelectedSource(sources)
	}

	const onChangeOccurrenceObject = (newOccurrenceForm) => {
		if (fieldErrors.length > 0) {
			setFieldErrors([])
		}
		setNewOccurrence(newOccurrenceForm)
	}

	const handleClickAddOccurrence = () => {
		const thisFieldErrors = validateNewOccurrence(newOccurrence)
		if (thisFieldErrors.length > 0) {
			setFieldErrors(thisFieldErrors)
			return sendMessageToast('Existem campos inválidos em sua requisição', 'ERROR')
		}
		let newOccurrenceFormatted = {
			...newOccurrence,
			content_class: 'POST',
			content_posted_at: dayjs(newOccurrence.content_posted_at).format('YYYY-MM-DD') + ` ${newOccurrence.content_posted_at_hour}`
		}

		delete newOccurrenceFormatted.content_posted_at_hour
		if (selectedSource[0] === 'news') {
			newOccurrenceFormatted.author_domain = newOccurrenceFormatted.author_username
		}

		if (newOccurrenceFormatted?.content_attachments?.file) {
			newOccurrenceFormatted.content_attachments.file = {
				name: newOccurrenceFormatted.content_attachments.file.name,
				size: newOccurrenceFormatted.content_attachments.file.size,
				type: newOccurrenceFormatted.content_attachments.file.type
			}
		}

		const body = {
			newOccurrence: newOccurrenceFormatted,
			source: selectedSource[0],
			cardId,
			searchType
		}

		setSubmitting(true)
		api.post('/occurrences/add-new-occurrence', body)
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					handleOnClose()
				}
				queryClient.invalidateQueries('relevanceChange_useUpdateData')
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					setActiveStep(1)
				}
			})
			.finally(() => setSubmitting(false))
	}

	const handleNext = () => {
		const thisFieldErrors = validateNewOccurrence(newOccurrence)
		if (thisFieldErrors.length > 0 && activeStep === 1) {
			setFieldErrors(thisFieldErrors)
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
		}
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	useEffect(() => {
		if (editOccurrenceId) {
			setSubmitting(true)
			api.get(`/occurrences/get-user-occurrence-to-edit/${editOccurrenceSource}/${editOccurrenceId}`)
				.then((response) => {
					const dateParts = response.data.content_posted_at.split(' ')
					let filename = null
					if (response?.data?.content_attachments) {
						filename = response?.data?.content_attachments.split('/').pop()
						filename = filename.replace(/%3A\d+/, '')
					}
					const formattedUserOccurrenceEdit = {
						...response.data,
						content_posted_at: dayjs(dateParts[0]),
						content_posted_at_hour: dateParts[1],
						content_attachments: {
							lekao: 'sim',
							location: response.data.content_attachments,
							file: {
								name: filename
							}
						}
					}
					setNewOccurrence(formattedUserOccurrenceEdit)
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => setSubmitting(false))
		}
	}, [editOccurrenceId])

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			size='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
					maxWidth: '60em',
					maxHeight: '96vh',
					marginTop: '5em'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<PlusIcon className='fix-icon-margin-bottom-negative' size={25} color={color} />{' '}
						{editOccurrenceId ? 'Editar' : 'Adicionar nova'} postagem
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
				<CardContent style={{ padding: 0, margin: 0 }}>
					<Grid
						container
						style={{
							borderRadius: '20px',
							color: 'rgb(120, 120, 120)',
							fontFamily: 'Poppins',
							fontWeight: '300',
							fontSize: '20px',
							justifyContent: 'center'
						}}
					>
						<Grid container item xs={12} style={{ display: 'flex', paddingLeft: '0.6em', marginTop: '-1em' }}>
							<Box sx={{ marginTop: '1em' }}>
								<Stepper activeStep={activeStep} orientation='vertical'>
									{steps.map((step, index) => (
										<Step key={step.label}>
											<StepLabel>{step.label}</StepLabel>
											<StepContent>
												<Typography>{step.description}</Typography>

												{index === 0 && (
													<Grid item xs={12} style={{ height: '71px', display: 'flex', alignItems: 'flex-end' }}>
														{!editOccurrenceId && (
															<SourceSelectorGlobalFilters
																onSourceChange={onSourceChange}
																sourcesControlled={selectedSource}
																isOverview={false}
																singleSourceSelection
															/>
														)}
													</Grid>
												)}

												<Collapse in={!!selectedSource[0]} unmountOnExit>
													<Grid item xs={12}>
														{!submitting && (
															<RenderOccurencesTextFields
																source={selectedSource[0]}
																onChange={onChangeOccurrenceObject}
																fieldErrors={fieldErrors}
																controlledValue={newOccurrence}
																activeStep={index}
															/>
														)}
													</Grid>
												</Collapse>

												<Box sx={{ mb: 2 }}>
													<div>
														<Button
															style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
															variant='outlined'
															onClick={handleNext}
															sx={{ mt: 1, mr: 1 }}
														>
															{index === steps.length - 1 ? 'Terminar' : 'Continuar'}
														</Button>
														{!(editOccurrenceId && activeStep === 1) && (
															<Button
																style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
																disabled={index === 0}
																onClick={handleBack}
																sx={{ mt: 1, mr: 1 }}
															>
																Voltar
															</Button>
														)}
													</div>
												</Box>
											</StepContent>
										</Step>
									))}
								</Stepper>
								{activeStep === steps.length && (
									<Paper
										square
										elevation={0}
										style={{ borderRadius: '25px', maxWidth: '340px', marginTop: '1em', padding: '1em', minWidth: '340px' }}
									>
										<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
											<Button
												variant='outlined'
												className={classes.button}
												disabled={submitting}
												onClick={handleClickAddOccurrence}
												style={{ maxHeight: '36.5px', whiteSpace: 'nowrap' }}
											>
												<Collapse orientation='horizontal' in={!submitting}>
													{editOccurrenceId ? 'Salvar' : 'Adicionar postagem'}
												</Collapse>
												<Collapse orientation='horizontal' in={submitting}>
													<LoadingIcon size={20} color='#FFF' style={{ marginTop: '6px' }} />
												</Collapse>
											</Button>
											<Button
												variant='outlined'
												disabled={submitting}
												onClick={handleBack}
												style={{ borderRadius: '100px', fontFamily: 'Poppins', marginLeft: '2em' }}
											>
												Voltar
											</Button>
										</div>
									</Paper>
								)}
							</Box>
						</Grid>
					</Grid>
					<Button
						id='btn-open-ai-history'
						onClick={toggleHistory}
						variant='outlined'
						disabled={editOccurrenceId}
						style={{
							fontFamily: 'Poppins',
							borderRadius: '100px',
							fontSize: '14px',
							borderColor: 'rgba(29, 138, 248, 0.3)',
							marginTop: '2em'
						}}
					>
						<HistoryIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
						Histórico de importação
					</Button>
					<Dialog
						id='dialog-add-new-occurrence'
						open={openHistory}
						style={{ marginTop: '-6%' }}
						onClose={toggleHistory}
						PaperProps={{
							style: {
								borderRadius: '20px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
								maxWidth: '80em'
							}
						}}
						BackdropProps={{
							style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
						}}
						fullWidth={true}
						maxWidth='lg'
					>
						<DialogContent style={{ padding: 0, margin: 0, height: '590px', position: 'relative' }}>
							<ServiceHistory
								style={{ width: '100%', height: '100%' }}
								cardId={cardId}
								isOpen={openHistory}
								toggleOpenHistory={toggleHistory}
								cardType='term'
								serviceName={SERVICE_HISTORY_NAME}
								cardName={cardName}
								sources={selectedSource}
							/>
						</DialogContent>
					</Dialog>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default ModalAddOccurrence
