import React, { useEffect, useState, useRef, memo } from 'react'
import { Grid, Card, MenuItem, styled, Select, FormControl, Tabs, Tab, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TagIcon, AnalysisIcon, BarsIcon, BarsIcon2 } from 'utils/SystemIcons'
import { connect } from 'react-redux'
import { getFirstCard } from 'components/Occurrences/utils/occurrencesFunctions'
import api from 'service/service'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import OccurrencesCardSelector from 'components/Occurrences/components/FilterBar/components/OccurrencesCardSelector/OccurrencesCardSelector'
import TagViewerSkeletonLoad from 'components/SkeletonLoads/TagViewerSkeltonLoad/TagViewerSkeletonLoad'
import TagGraphLine from 'graphs/TagGraph/TagGraphLine'
import TagGraphLine100 from 'graphs/TagGraph/TagGraphLine100'
import TagGraphBars from 'graphs/TagGraph/TagGraphBars'
import TagGraphBars100 from 'graphs/TagGraph/TagGraphBars100'
import TagGraphTotal from 'graphs/TagGraph/TagGraphTotal'
import TagGraphTotal100 from 'graphs/TagGraph/TagGraphTotal100'
import TagSideMenu from './components/TagSideMenu/TagSideMenu'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: '#828282'
	}
}))

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const optionsList = [
	{
		title: 'Linha do tempo',
		type: 'line'
	},

	{
		title: 'Barras',
		type: 'bars'
	},

	{
		title: 'Totais',
		type: 'totals'
	}
]

const getIcon = (type) => {
	switch (type) {
		case 'line':
			return <AnalysisIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'bars':
			return <BarsIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'totals':
			return (
				<div style={{ color: '#1d8cf8' }}>
					<BarsIcon2 color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
				</div>
			)
	}
}

const TagViewer = ({ cards, mainComparison = '', globalFiltersRedux, externalFilters }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({ options: { title: '' }, series: [] })
	const [filteredData, setFilteredData] = useState({ options: { title: '' }, series: [] })
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, mainComparison))
	const [selectedOptionList, setSelectedOptionList] = useState('line')
	const [allCardsSelect, setAllCardsSelect] = useState(cards)
	const [type, setType] = useState('absolute')
	const [isFirstTime, setIsFirstTime] = useState(true)
	const isMounted = useRef(true)
	const cardRef = useRef(true)
	const loadingBarRef = useRef()
	const classes = useStyles()

	const [thisFilters, setThisFilters] = useState(externalFilters || globalFiltersRedux)

	useEffect(() => {
		if (externalFilters) {
			setThisFilters(externalFilters)
		} else {
			setThisFilters(globalFiltersRedux)
		}
	}, [externalFilters, globalFiltersRedux])

	useEffect(() => {
		setAllCardsSelect(cards)
		setSelectedCard(getFirstCard(cards, mainComparison))
	}, [cards])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		load()
	}, [selectedCard, thisFilters])

	const getBodyTagViewer = () => ({
		configGlobalFilters: {
			cardType: selectedCard?.type.toUpperCase(),
			cardIds: [selectedCard.id]
		},
		globalFilters: thisFilters
	})

	const load = () => {
		api.post('/tags/get-by-card-id', getBodyTagViewer()).then((res) => {
			setData(res.data)
			setFilteredData(res.data)
			setLoading(false)
		})
	}

	const onCardChange = (newCard) => {
		setSelectedCard(newCard)
	}

	const handleSelect = (event) => {
		setSelectedOptionList(event.target.value)
	}

	const onGraphTypeChange = (_, newValue) => {
		setType(newValue)
	}

	// const onPartialLoading = (status) => {
	// 	loadingBarRef.current.load(status)
	// }

	const onChangeFilter = (filter, isOpen) => {
		if (isOpen) {
			setIsFirstTime(false)
			let newData = data.series.filter((serie) => filter.includes(serie.name))
			setFilteredData({ ...data, series: newData })
		}
	}

	const handleData = (filteredData) => {
		if (isFirstTime) {
			let newData = filteredData.series.filter((serie) => !serie.name.includes('_'))
			return { ...filteredData, series: newData }
		} else {
			return filteredData
		}
	}

	return (
		<>
			<Card ref={cardRef} style={{ background: '#FFF', borderRadius: '20px' }}>
				<Grid container>
					<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={6}>
							<GraphTitle
								id='span-tags'
								title='TAGS'
								description='Grafico que mostra as tags das postagens no intervalo de tempo selecionado'
								icon={<TagIcon size={20} style={{ color: '#1d8cf8' }} />}
							/>
						</Grid>
						<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
							{data?.series?.length > 0 && <TagSideMenu series={data?.series} onChangeFilter={onChangeFilter} />}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<LoadingBar ref={loadingBarRef} />{' '}
					</Grid>
					<Grid item xs={4} style={{ margin: '0px' }}>
						<Tabs
							style={{ marginRight: '0px', float: 'left' }}
							value={type}
							onChange={onGraphTypeChange}
							TabIndicatorProps={{ style: { background: blue } }}
						>
							<Tab
								id='tab-tags-absolute'
								label='Valores Absolutos'
								value='absolute'
								style={{ outline: 'none' }}
								className={classes.tab}
							/>
							<Tab id='tab-tags-relative' label='Porcentagens' value='relative' style={{ outline: 'none' }} className={classes.tab} />
						</Tabs>
					</Grid>
					<Grid item xs={4} container justifyContent='center'>
						{allCardsSelect && (
							<Grid item xs={12} style={{ marginTop: '13px' }}>
								<OccurrencesCardSelector cards={allCardsSelect} onCardChange={onCardChange} selectedCard={selectedCard} />
							</Grid>
						)}
					</Grid>
					<Grid item xs={4} container justifyContent='flex-end'>
						<Grid item xs={6} style={{ marginTop: '6px', marginRight: '5px' }}>
							<StyledFormControl className={classes.formControl}>
								<StyledSelect
									id='select-tags-viewer'
									variant='standard'
									className={classes.select}
									value={selectedOptionList}
									onChange={handleSelect}
									style={{ marginTop: '7px' }}
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left'
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left'
										},
										PaperProps: {
											style: {
												marginTop: '0px',
												marginLeft: '-1px',
												borderRadius: '0px 0px 25px 25px',
												backdropFilter: 'blur(10px)',
												backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
												borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
											}
										}
									}}
								>
									{optionsList.map((option) => {
										return (
											<MenuItem key={option.type} value={option.type}>
												<Grid container justifyContent='flex-start' alignItems='center' style={{ fontFamily: 'Poppins' }}>
													{getIcon(option.type)}
													{option.title}
												</Grid>
											</MenuItem>
										)
									})}
								</StyledSelect>
							</StyledFormControl>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: 'rgba(0,0,0,0.001)', height: '1px', marginTop: '-1px', width: '100%' }} />
					<Grid item xs={12}>
						{!loading ? (
							!data || data?.series?.length == 0 ? (
								<div
									style={{
										height: '77vh',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontFamily: 'Poppins',
										fontSize: '24px',
										color: '#d6d6d6'
									}}
								>
									{`Sem dados de ${globalFiltersRedux.sources} para opção selecionada`}
								</div>
							) : (
								<>
									{filteredData?.series?.length > 0 ? (
										<>
											{selectedOptionList === 'line' && type === 'absolute' && <TagGraphLine data={handleData(filteredData)} />}
											{selectedOptionList === 'line' && type === 'relative' && (
												<TagGraphLine100 data={handleData(filteredData)} />
											)}
											{selectedOptionList === 'bars' && type === 'absolute' && <TagGraphBars data={handleData(filteredData)} />}
											{selectedOptionList === 'bars' && type === 'relative' && (
												<TagGraphBars100 data={handleData(filteredData)} />
											)}
											{selectedOptionList === 'totals' && type === 'absolute' && (
												<TagGraphTotal data={handleData(filteredData)} />
											)}
											{selectedOptionList === 'totals' && type === 'relative' && (
												<TagGraphTotal100 data={handleData(filteredData)} />
											)}
										</>
									) : (
										<div
											style={{
												height: '77vh',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												fontFamily: 'Poppins',
												fontSize: '24px',
												color: '#d6d6d6'
											}}
										>
											Ative pelo menos uma tag para ver os dados
										</div>
									)}
								</>
							)
						) : (
							<TagViewerSkeletonLoad />
						)}
					</Grid>
				</Grid>
			</Card>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(TagViewer))
