import React from 'react'
import { Grid, TextField } from '@mui/material'

const TiktokSpecificForm = ({ newOccurrenceForm, onChangeField, fieldErrors }) => {
	return (
		<Grid item xs={12} style={{ marginTop: '1em' }} container spacing={2}>
			<Grid item xs={3}>
				<TextField
					type={'number'}
					size='small'
					className='d-flex'
					style={{ color: '#1d8cf8' }}
					sx={{
						'& .MuiOutlinedInput-root': {
							fontFamily: 'Poppins',
							'& fieldset': {
								borderRadius: '15px'
							}
						}
					}}
					value={newOccurrenceForm['content_engagement_public_like_count']}
					onFocus={(event) => {
						event.target.select()
					}}
					onChange={(e) => onChangeField(e.target.value, 'content_engagement_public_like_count', 'number')}
					variant='outlined'
					label={'Curtidas'}
					error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_like_count')?.message}
					helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_like_count')?.message}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					type={'number'}
					size='small'
					className='d-flex'
					style={{ color: '#1d8cf8' }}
					sx={{
						'& .MuiOutlinedInput-root': {
							fontFamily: 'Poppins',
							'& fieldset': {
								borderRadius: '15px'
							}
						}
					}}
					value={newOccurrenceForm['content_engagement_public_comment_count']}
					onFocus={(event) => {
						event.target.select()
					}}
					onChange={(e) => onChangeField(e.target.value, 'content_engagement_public_comment_count', 'number')}
					variant='outlined'
					label={'Respostas'}
					error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_comment_count')?.message}
					helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_comment_count')?.message}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					type={'number'}
					size='small'
					className='d-flex'
					style={{ color: '#1d8cf8' }}
					sx={{
						'& .MuiOutlinedInput-root': {
							fontFamily: 'Poppins',
							'& fieldset': {
								borderRadius: '15px'
							}
						}
					}}
					value={newOccurrenceForm['content_engagement_public_share_count']}
					onFocus={(event) => {
						event.target.select()
					}}
					onChange={(e) => onChangeField(e.target.value, 'content_engagement_public_share_count', 'number')}
					variant='outlined'
					label={'Repostagens'}
					error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_share_count')?.message}
					helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_share_count')?.message}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					type={'number'}
					size='small'
					className='d-flex'
					style={{ color: '#1d8cf8' }}
					sx={{
						'& .MuiOutlinedInput-root': {
							fontFamily: 'Poppins',
							'& fieldset': {
								borderRadius: '15px'
							}
						}
					}}
					value={newOccurrenceForm['content_engagement_public_view_count']}
					onFocus={(event) => {
						event.target.select()
					}}
					onChange={(e) => onChangeField(e.target.value, 'content_engagement_public_view_count', 'number')}
					variant='outlined'
					label={'Visualizações de vídeo'}
					error={fieldErrors.find((fe) => fe.field === 'content_engagement_public_view_count')?.message}
					helperText={fieldErrors.find((fe) => fe.field === 'content_engagement_public_view_count')?.message}
				/>
			</Grid>
		</Grid>
	)
}

export default TiktokSpecificForm
