import React from 'react'
import moment from 'moment-timezone'
import { Grid, Button, Tooltip } from '@mui/material'
import constantsVox from 'constants-vox'
import {
	TwitterIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	ErrorIcon,
	CheckIcon,
	AlertExclamationIcon,
	LoadingIcon,
	NewsIcon
} from 'utils/SystemIcons'

const updatePostsStatus = constantsVox.CARD_PROFILE_UPDATE_POSTS.STATUS

const defineIcon = (source) => {
	if (source === 'twitter') {
		return <TwitterIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (source === 'facebook') {
		return <FacebookIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (source === 'instagram') {
		return <InstagramIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (source === 'tiktok') {
		return <TiktokIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (source === 'youtube') {
		return <YoutubeIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (source === 'news') {
		return <NewsIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else {
		return <TwitterIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	}
}

const getStatusTextAndIcon = (status) => {
	let icon = <>?</>
	let text = ''
	if (status === updatePostsStatus.SUCCESS) {
		icon = <CheckIcon size={25} color='#1d8cf8' style={{ marginTop: '0.3em' }} />
		text = 'Item Atualizado'
	} else if (status === updatePostsStatus.PROCESSING) {
		icon = <LoadingIcon size={25} thickness={2} color='#ac60f7' style={{ marginTop: '0.3em' }} />
		text = 'Processando'
	} else if (status === updatePostsStatus.INSUFFICIENT_DATA) {
		icon = <AlertExclamationIcon size={25} color='#eb9834' style={{ marginTop: '0.3em' }} />
		text = 'Sem dados suficientes'
	} else if (status === updatePostsStatus.INSUFFICIENT_CREDIT) {
		icon = <AlertExclamationIcon size={25} color='#eb9834' style={{ marginTop: '0.3em' }} />
		text = 'Sem créditos suficientes'
	} else if (status === updatePostsStatus.ERROR) {
		icon = <ErrorIcon size={25} color='#ff0000' style={{ marginTop: '0.3em' }} />
		text = 'Erro'
	} else {
		text = 'Status não identificado'
	}

	return { icon, text }
}

const formatTextContent = (text) => {
	const limit = 20
	if (text.length > limit) {
		return text.substring(0, limit) + '...'
	} else {
		return text
	}
}

const UpdatePostsHistoryItem = ({ item }) => {
	return (
		<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em', width: '50em' }}>
			<Tooltip title={getStatusTextAndIcon(item.status)?.text} placement='bottom' enterDelay={500} enterNextDelay={500}>
				<Button
					disableRipple
					id={`btn-load-item-${item.id}`}
					style={{
						cursor: 'pointer',
						maxHeight: '37px',
						minHeight: '37px',
						backgroundColor: '#fff',
						borderRadius: '25px 0px 0px 25px',
						width: '100%'
					}}
				>
					<Grid
						container
						style={{
							width: '100%',
							color: 'rgba(100,100,100, 0.9)',
							fontFamily: 'Poppins',
							fontSize: '13px',
							marginTop: '4px',
							alignItems: 'center'
						}}
					>
						<Grid item xs={1}>
							{defineIcon(item?.source)}
						</Grid>

						<Grid item xs={4}>
							Criado em: {moment(item.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}
						</Grid>
						<Grid item xs={4}>
							Conteúdo: {formatTextContent(item.post_info.content_text)}
						</Grid>
						<Grid item xs={3}>
							{getStatusTextAndIcon(item?.status)?.icon}
						</Grid>
					</Grid>
				</Button>
			</Tooltip>
		</Grid>
	)
}

export default UpdatePostsHistoryItem
