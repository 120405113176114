import React, { useState } from 'react'
import { Grid, Tabs, Tab, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReportIconHorizontal, ReportIconVertical } from 'utils/SystemIcons'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

const ReportContentChoiceMenu = ({ handleChangeGraphType, handleChangeGraphTime }) => {
	const [type, setType] = useState('horizontal')
	const [time, setTime] = useState('day')

	const classes = useStyles()

	const onGraphTypeChange = (_, newValue) => {
		setType(newValue)
		handleChangeGraphType(newValue)
	}

	const onGraphTimeChange = (_, newValue) => {
		setTime(newValue)
		handleChangeGraphTime(newValue)
	}

	return (
		<>
			<Grid container spacing={0} style={{ marginLeft: '20px' }}>
				<Grid item xs={6} style={{ margin: '0px' }}>
					<Tabs value={time} onChange={onGraphTimeChange} TabIndicatorProps={{ style: { background: blue } }}>
						<Tab label='Em Dias' value='day' style={{ outline: 'none' }} className={classes.tab} />
						<Tab label='Em Horas' value='hour' style={{ outline: 'none' }} className={classes.tab} />
					</Tabs>
				</Grid>
				<Grid item xs={6}>
					<Tabs
						style={{ marginRight: '40px', float: 'right' }}
						value={type}
						onChange={onGraphTypeChange}
						TabIndicatorProps={{ style: { background: blue } }}
					>
						<Tab
							label={
								<Grid container justifyContent='center' alignItems='center'>
									<ReportIconHorizontal size={15} style={{ color: '#1d8cf8', marginRight: '5px' }} />
									Modo Paisagem
								</Grid>
							}
							value='horizontal'
							style={{ outline: 'none' }}
							className={classes.tab}
						/>

						<Tab
							label={
								<Grid container justifyContent='center' alignItems='center'>
									<ReportIconVertical size={15} style={{ color: '#1d8cf8', marginRight: '5px' }} />
									Modo Retrato
								</Grid>
							}
							value='vertical'
							style={{ outline: 'none' }}
							className={classes.tab}
						/>
					</Tabs>
				</Grid>
			</Grid>
			<Divider style={{ marginBottom: '10px', background: '#f0f0f0' }} />
		</>
	)
}

export default ReportContentChoiceMenu
