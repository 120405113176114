import React, { useEffect, useState, useRef, memo } from 'react'
import FilterBar from './components/FilterBar/FilterBar'
import OccurrencesFeed from './components/OccurrencesFeed/OccurrencesFeed'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { getFirstCard } from './utils/occurrencesFunctions'
import { DEFAULT_FEED_EMPTY } from './utils/occurrencesConstants'

let page = 0
let firstTime = true
let firstTimeTab = true

const DEFAULT_ORDER = {
	date: 'desc',
	relevance: null
}

const Occurrences = ({
	cards,
	isDialog = false,
	mainComparison = '',
	isReport = false,
	pageSize = 20,
	filters,
	orderExternal,
	hideCardSelector = false,
	filtersWordClouds = { name: null, type: null },
	type,
	isComment,
	topicId = null,
	isTopPosts = false,
	isWidget = false,
	isCommentWordCloud = false
}) => {
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, mainComparison))
	const [loading, setLoading] = useState(true)
	const [mainFeed, setMainFeed] = useState(DEFAULT_FEED_EMPTY)
	const isMounted = useRef(true)
	const [orderFilters, setOrderFilters] = useState(orderExternal || DEFAULT_ORDER)
	const [externalOpenTags, setExternalOpenTags] = useState(false)
	const [externalMultipleSelection, setExternalMultipleSelection] = useState(false)
	const [selectedPostsIds, setSelectedPostsIds] = useState([])
	const [searchAfterState, setSearchAfterState] = useState([])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (filters?.sources.length > 0) {
			page = 0
			setSearchAfterState([])
			firstTimeTab = true
			load()
		}
	}, [selectedCard, orderFilters, filters])

	useEffect(() => {
		if (cards.length === 1) {
			setSelectedCard(getFirstCard(cards, mainComparison))
		}
	}, [cards])

	useEffect(() => {
		if (!mainFeed?.loading) {
			if (firstTime) {
				firstTime = false
			} else if (mainFeed.data?.length < 5 && !mainFeed.endResults) {
				load()
			}
		}
	}, [mainFeed])

	const load = async (isPagination = false, contentTextSearch) => {
		let searchAfter = !isTopPosts ? searchAfterState : []

		if (filters.sources.length === 0) {
			return
		}

		if (!isPagination) {
			setLoading(true)
			page = 0
			searchAfter = []
			setSearchAfterState(searchAfter)
		}

		let feed = { loading: false, data: [], endResults: true }
		try {
			let res = []
			if (isComment || isCommentWordCloud) {
				let accountManagerNetworkAccountId = null
				let postId = null

				if (!isCommentWordCloud) {
					postId = cards[0]?.id.split(':')[1]
				}

				if (filters.sources[0] === 'facebook') {
					accountManagerNetworkAccountId = cards[0]?.accountManagerFacebookAccountId
				} else if (filters.sources[0] === 'instagram') {
					accountManagerNetworkAccountId = cards[0]?.accountManagerInstagramAccountId
				}

				const body = {
					globalFilters: { ...filters, contentTextSearch },
					componentProperties: {
						postId,
						pageSize: 20,
						page,
						orderDate: orderFilters.date,
						orderRelevance: orderFilters.relevance,
						accountManagerNetworkAccountId,
						isWordCloud: isCommentWordCloud,
						wordCloudName: filtersWordClouds.name,
						wordCloudType: filtersWordClouds.type
					}
				}
				res = await api.post('/account-management/comments/get-comments-post', body)
			} else {
				const body = {
					configGlobalFilters: {
						cardType: type.toUpperCase(),
						cardIds: [selectedCard.id]
					},
					globalFilters: { ...filters },
					componentProperties: {
						pageSize,
						page,
						orderDate: orderFilters.date,
						orderRelevance: orderFilters.relevance,
						type,
						wordCloudName: filtersWordClouds.name,
						wordCloudType: filtersWordClouds.type,
						topicIds: topicId,
						searchAfter: searchAfter
					}
				}
				res = await api.post('/occurrences/get-with-filters', body)
			}

			if ((res.data.length !== 0 || !firstTimeTab) && isMounted.current) {
				feed = {
					...mainFeed,
					data: isPagination ? [...mainFeed.data, ...res.data] : res.data,
					loading: false,
					page: page,
					endResults: res.data.length < pageSize
				}
				page += 1
				setSearchAfterState(res.data[res.data.length - 1]?.sort)
			}
		} catch (error) {
			setMainFeed({ ...mainFeed, data: [], loading: false, page: 0, endResults: true })

			if (verifyServerResponseCanShowToast(error) && selectedCard.cardStatus.general === 'S') {
				//FIXME
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			}
		}

		setMainFeed(feed)

		firstTimeTab = false

		if (isMounted.current && !isPagination) {
			setLoading(false)
		}
	}

	const onOrderFiltersChange = (orderFilters) => {
		page = 0
		setSearchAfterState([])
		firstTimeTab = true
		setOrderFilters(orderFilters)
	}

	const onCardChange = (newCard) => setSelectedCard(newCard)

	const onHideAccount = () => {
		load()
	}

	const onStopWord = (newText) => {
		const cardStopWordsNew = { ...selectedCard }
		cardStopWordsNew.stopWords.words.push(newText)
		setSelectedCard(cardStopWordsNew)
		load()
	}

	const onRelevanceSubmitting = () => {
		//nada
	}

	const onChangeExternalOpenTags = () => {
		setExternalOpenTags(!externalOpenTags)
	}

	const onChangeExternalMultipleSelection = () => {
		setExternalMultipleSelection(!externalMultipleSelection)
		setSelectedPostsIds([])
	}

	const onChangeSelectedPostsIds = (postId) => {
		if (selectedPostsIds.includes(postId)) {
			setSelectedPostsIds(selectedPostsIds.filter((id) => id !== postId))
		} else {
			setSelectedPostsIds([...selectedPostsIds, postId])
		}
	}

	return (
		<>
			{!isReport && (
				<FilterBar
					cards={cards}
					onCardChange={onCardChange}
					orderFiltersControlled={orderFilters}
					onOrderFiltersChange={onOrderFiltersChange}
					isDialog={isDialog}
					loading={loading}
					mainComparison={mainComparison}
					filters={filters}
					hideCardSelector={hideCardSelector}
					type={type}
					onEnterSearch={load}
					isComment={isComment}
					wordCloudName={filtersWordClouds.name}
					onChangeExternalOpenTags={onChangeExternalOpenTags}
					externalOpenTags={externalOpenTags}
					isTopPosts={isTopPosts}
					selectedPostsIds={selectedPostsIds}
					onChangeExternalMultipleSelection={onChangeExternalMultipleSelection}
					externalMultipleSelection={externalMultipleSelection}
					isWidget={isWidget}
				/>
			)}
			<OccurrencesFeed
				cards={cards}
				selectedCard={selectedCard}
				orderFilters={orderFilters}
				filters={filters}
				loading={loading}
				isDialog={isDialog}
				onSubmitting={onRelevanceSubmitting}
				nextLoad={() => load(true)}
				feed={mainFeed}
				isReport={isReport}
				type={type}
				load={() => load(false)}
				isComment={isComment}
				onHideAccount={onHideAccount}
				onStopWord={onStopWord}
				externalOpenTags={externalOpenTags}
				isTopPosts={isTopPosts}
				onChangeSelectedPostsIds={onChangeSelectedPostsIds}
				selectedPostsIds={selectedPostsIds}
				externalMultipleSelection={externalMultipleSelection}
				isWidget={isWidget}
				isCommentWordCloud={isCommentWordCloud}
			/>
		</>
	)
}

export default memo(Occurrences)
