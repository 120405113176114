import React, { useState, useEffect } from 'react'
import { Grid, MenuItem, styled, Select, FormControl, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	DiscIcon,
	AnalysisIcon,
	BarsIcon,
	BarsIcon2,
	TwitterIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	CircleIcon,
	PercentIcon
} from 'utils/SystemIcons'
import TagViewerSkeletonLoad from 'components/SkeletonLoads/TagViewerSkeltonLoad/TagViewerSkeletonLoad'
import TagGraphLine from 'graphs/TagGraph/TagGraphLine'
import TagGraphLine100 from 'graphs/TagGraph/TagGraphLine100'
import TagGraphBars from 'graphs/TagGraph/TagGraphBars'
import TagGraphBars100 from 'graphs/TagGraph/TagGraphBars100'
import TagGraphTotal from 'graphs/TagGraph/TagGraphTotal'
import TagGraphTotal100 from 'graphs/TagGraph/TagGraphTotal100'
import TopicSideMenu from '../TopicSideMenu/TopicSideMenu'
import TagGraphBubble from 'graphs/TagGraph/TagGraphBubble'
import TagGraphBubble100 from 'graphs/TagGraph/TagGraphBubble100'

const titleStyle = {
	marginLeft: '5px',
	width: '93%',
	textAlign: 'left',
	fontFamily: 'Poppins',
	color: '#1d8cf8',
	fontSize: '20px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const defineIcon = (sources) => {
	const iconStyle = { color: '#1d8cf8', marginTop: '7px' }
	const containerStyle = { marginRight: '10px' }
	return (
		<div style={{ display: 'flex' }}>
			{sources.includes('twitter') && (
				<div style={containerStyle}>
					<TwitterIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('facebook') && (
				<div style={containerStyle}>
					<FacebookIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('instagram') && (
				<div style={containerStyle}>
					<InstagramIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('tiktok') && (
				<div style={containerStyle}>
					<TiktokIcon size={16} style={iconStyle} />
				</div>
			)}
			{sources.includes('youtube') && (
				<div style={containerStyle}>
					<YoutubeIcon size={16} style={iconStyle} />
				</div>
			)}
		</div>
	)
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: '#828282'
	}
}))

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const optionsList = [
	{
		title: 'Linha do tempo',
		type: 'line'
	},

	{
		title: 'Barras',
		type: 'bars'
	},

	{
		title: 'Totais',
		type: 'totals'
	}
]

const typeList = [
	{
		title: 'Valores Absolutos',
		type: 'absolute'
	},
	{
		title: 'Porcentagens',
		type: 'relative'
	}
]

const getIcon = (type) => {
	switch (type) {
		case 'absolute':
			return (
				<div style={{ color: '#1d8cf8', marginTop: '4px' }}>
					<CircleIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
				</div>
			)
		case 'relative':
			return (
				<div style={{ color: '#1d8cf8', marginTop: '4px' }}>
					<PercentIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
				</div>
			)
		case 'bubble':
			return <DiscIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'line':
			return <AnalysisIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'bars':
			return <BarsIcon color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
		case 'totals':
			return (
				<div style={{ color: '#1d8cf8', marginTop: '4px' }}>
					<BarsIcon2 color={'#1d8cf8'} size={15} style={{ marginRight: '10px' }} />
				</div>
			)
	}
}

const TopicAnalysisViewer = ({ data, isFetched, source }) => {
	const [filteredData, setFilteredData] = useState(data)
	const [selectedOptionList, setSelectedOptionList] = useState('bars')
	const [type, setType] = useState('relative')

	const classes = useStyles()

	useEffect(() => {
		setFilteredData(data)
	}, [data])

	const onChangeFilter = (filter, isOpen) => {
		if (isOpen) {
			let newData = data.series.filter((serie) => filter.includes(serie.name))
			setFilteredData({ ...data, series: newData })
		}
	}

	const handleSelect = (event) => {
		setSelectedOptionList(event.target.value)
	}

	const handleTypeSelect = (event) => {
		setType(event.target.value)
	}

	return (
		<Grid container>
			<Grid item xs={4} style={{ margin: '0px' }}>
				<Grid item xs={6} style={{ marginTop: '6px', marginLeft: '20px' }}>
					<StyledFormControl className={classes.formControl}>
						<StyledSelect
							variant='standard'
							className={classes.select}
							value={type}
							onChange={handleTypeSelect}
							style={{ marginTop: '7px' }}
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								PaperProps: {
									style: {
										marginTop: '0px',
										marginLeft: '-1px',
										borderRadius: '0px 0px 25px 25px',
										backdropFilter: 'blur(10px)',
										backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
										borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
									}
								}
							}}
						>
							{typeList.map((option) => {
								return (
									<MenuItem key={option.type} value={option.type}>
										<Grid container justifyContent='flex-start' alignItems='center' style={{ fontFamily: 'Poppins' }}>
											{getIcon(option.type)}
											{option.title}
										</Grid>
									</MenuItem>
								)
							})}
						</StyledSelect>
					</StyledFormControl>
				</Grid>
			</Grid>
			<Grid item xs={4} container justifyContent='center'></Grid>
			<Grid item xs={4} container justifyContent='flex-end'>
				<Grid item xs={6} style={{ marginTop: '6px', marginRight: '5px' }}>
					<StyledFormControl className={classes.formControl}>
						<StyledSelect
							variant='standard'
							className={classes.select}
							value={selectedOptionList}
							onChange={handleSelect}
							style={{ marginTop: '7px' }}
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								PaperProps: {
									style: {
										marginTop: '0px',
										marginLeft: '-1px',
										borderRadius: '0px 0px 25px 25px',
										backdropFilter: 'blur(10px)',
										backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
										borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
									}
								}
							}}
						>
							{optionsList.map((option) => {
								return (
									<MenuItem key={option.type} value={option.type}>
										<Grid container justifyContent='flex-start' alignItems='center' style={{ fontFamily: 'Poppins' }}>
											{getIcon(option.type)}
											{option.title}
										</Grid>
									</MenuItem>
								)
							})}
						</StyledSelect>
					</StyledFormControl>
				</Grid>
				{data?.series?.length > 0 && <TopicSideMenu series={data?.series} onChangeFilter={onChangeFilter} />}
			</Grid>
			<Divider style={{ backgroundColor: 'rgba(0,0,0,0.001)', height: '1px', marginTop: '-1px', width: '100%' }} />
			<Grid item xs={12} style={{ paddingLeft: '2em', paddingTop: '2em', marginBottom: '-1em' }}>
				{data?.options?.title && (
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>{defineIcon(source)}</div>
						<div style={titleStyle}>{data?.options?.title}</div>
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				{isFetched ? (
					!data || data?.series?.length == 0 ? (
						<div
							style={{
								height: '77vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontFamily: 'Poppins',
								fontSize: '24px',
								color: '#d6d6d6'
							}}
						>
							{`Sem dados de ${source} para opção selecionada`}
						</div>
					) : (
						<>
							{filteredData?.series?.length > 0 ? (
								<>
									{selectedOptionList === 'bubble' && type === 'absolute' && (
										<TagGraphBubble data={filteredData} height={'450px'} />
									)}
									{selectedOptionList === 'bubble' && type === 'relative' && (
										<TagGraphBubble100 data={filteredData} height={'450px'} />
									)}
									{selectedOptionList === 'line' && type === 'absolute' && <TagGraphLine data={filteredData} height={'450px'} />}
									{selectedOptionList === 'line' && type === 'relative' && <TagGraphLine100 data={filteredData} height={'450px'} />}
									{selectedOptionList === 'bars' && type === 'absolute' && <TagGraphBars data={filteredData} height={'450px'} />}
									{selectedOptionList === 'bars' && type === 'relative' && <TagGraphBars100 data={filteredData} height={'450px'} />}
									{selectedOptionList === 'totals' && type === 'absolute' && <TagGraphTotal data={filteredData} height={'450px'} />}
									{selectedOptionList === 'totals' && type === 'relative' && (
										<TagGraphTotal100 data={filteredData} height={'450px'} />
									)}
								</>
							) : (
								<div
									style={{
										height: '77vh',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontFamily: 'Poppins',
										fontSize: '24px',
										color: '#d6d6d6'
									}}
								>
									Ative pelo menos uma tag para ver os dados
								</div>
							)}
						</>
					)
				) : (
					<TagViewerSkeletonLoad />
				)}
			</Grid>
		</Grid>
	)
}

export default TopicAnalysisViewer
