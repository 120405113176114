import React from 'react'
import { BiNetworkChart } from 'react-icons/bi'
import { ListIcon, AnalysisIcon, OverviewIcon, CloudIcon } from 'utils/SystemIcons'

const analysisIcon = <AnalysisIcon className='icon-list-items-tab' size='20' />
const overviewIcon = <OverviewIcon className='icon-list-items-tab' size='20' />
const occurrencesIcon = <ListIcon className='icon-list-items-tab' size='20' />
const wordCloudIcon = <CloudIcon className='icon-list-items-tab' size='20' />
const networkIcon = <BiNetworkChart className='icon-list-items-tab' size='20' />

const menuDefaultNew = (twitterStatus = 'P', facebookStatus = 'P', instagramStatus = 'P', tiktokStatus = 'P') => {
	const getStatus = (sources) => {
		let hasError = sources.every((s) => s === 'W')
		if (hasError) {
			return {
				name: 'error',
				label: 'Ocorreu algum erro ao buscar dados.'
			}
		}
		let hasWarning = sources.every((s) => s === 'E')
		if (hasWarning) {
			return {
				name: 'warning',
				label: 'Nenhum dado encontrado para esta busca.'
			}
		}
		let isLoading = sources.every((s) => s === 'P')
		if (isLoading) {
			return { name: 'loading', label: 'Processando dados.' }
		} else {
			return { name: 'success', label: '' }
		}
	}

	return [
		{
			label: 'Overview',
			tab: 'analysis',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
			icon: analysisIcon
		},
		{
			label: 'Resumo das redes',
			tab: 'overview',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
			icon: overviewIcon
		},
		{
			label: 'Influenciadores',
			tab: 'network',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
			icon: networkIcon
		},
		{
			label: 'Postagens',
			tab: 'occurrences',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
			icon: occurrencesIcon
		},
		{
			label: 'Nuvens',
			tab: 'wordclouds',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus]),
			icon: wordCloudIcon
		}
	]
}

export { menuDefaultNew }
