//NotificationConfigDialog
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
	Button,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from '@mui/material'
import api from 'service/service'
import { HiOutlinePencil } from 'react-icons/hi'
import {
	getIntervalUnityLabel,
	getIntervalUnityValue,
	getTypeLabel,
	// getTypeValue,
	typeEnum
} from './utils/notificationConfigDialog.util'
import { CloseIcon, ChevronLeftIcon, LoadingIcon, NotificationIcon, EditIcon, NotificationPlusIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import WhatsappConfiguration from './components/WhatsappConfiguration/WhatsappConfiguration'
import QueryBuilder from 'components/QueryBuilder/QueryBuilder'

const grey = '#b8b8b8'
const isFirefox = typeof InstallTrigger !== 'undefined'

const headCells = [
	{
		id: 'type',
		label: 'Caso'
	},
	{
		id: 'value',
		label: ''
	},
	{
		id: 'active',
		label: 'Ativo'
	},
	{
		align: 'center',
		id: 'actions',
		label: 'Editar'
	}
]

const defaultNotification = {
	id: null,
	type: '',
	value: '',
	intervalQuantity: '',
	intervalUnity: '',
	active: false
}

const useStyles = makeStyles({
	newButton: {
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 ) !important',
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
		backgroundColor: 'rgba(255, 255, 255, 0.1)',
		color: '#1d8cf8 !important',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.5) !important'
		},
		height: '100px !important',
		width: '300px !important',
		letterSpacing: '0.1px !important',
		textTransform: 'none !important',
		fontWeight: '600 !important',
		justifyContent: 'space-between !important',
		marginBottom: '10px !important',
		borderRadius: '100px'
	},
	saveButton: {
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 ) !important',
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
		fontFamily: 'Poppins',
		borderRadius: '100px',
		backgroundColor: 'rgba(255, 255, 255, 0.0)',
		color: '#1d8cf8 !important',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.3) !important'
		},
		letterSpacing: '0.1px !important',
		textTransform: 'none !important',
		justifyContent: 'center !important',
		width: '250px',
		height: '40px'
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const formatLabelByType = (type) => {
	switch (type) {
		case 'WORD':
			return 'Palavra'
		case 'ENGAGEMENT':
			return 'Quantidade'
		case 'OCCURRENCE':
			return 'Quantidade'
		default:
			return 'Selecione o tipo'
	}
}

const NotificationActive = ({ notification, sendMessageToast }) => {
	const [checked, setChecked] = useState(notification.active)
	const [isLoadingSwitch, setIsLoadingSwitch] = useState(false)

	const handleChange = (event) => {
		setIsLoadingSwitch(true)
		api.post('/notification/config', { ...notification, active: event.target.checked })
			.then(() => {
				setChecked(!event.target.checked)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
			.finally(() => {
				setIsLoadingSwitch(false)
			})
	}

	return <Switch color='primary' disabled={isLoadingSwitch} checked={checked} onChange={handleChange} />
}

const NotificationConfigDialog = ({ cardId, cardType, isOpen, onClose, name }) => {
	const [errorMessageType, setErrorMessageType] = useState(null)
	const [errorMessageValue, setErrorMessageValue] = useState(null)
	// const [errorMessageIntervalQuantity, setErrorMessageIntervalQuantity] = useState(null)
	// const [errorMessageIntervalUnity, setErrorMessageIntervalUnity] = useState(null)
	const [notifications, setNotifications] = useState([])
	const [whatsappNumbers, setWhatsappNumbers] = useState([])
	const [currentNotification, setCurrentNotification] = useState(defaultNotification)
	const [menuVisible, setMenuVisible] = useState(true)
	const [rulesVisible, setRulesVisible] = useState(false)
	const [tableVisible, setTableVisible] = useState(false)
	const [sendWhatsapp, setSendWhatsapp] = useState(false)
	const [tab /*, setTab*/] = useState('simple')
	const [backFrom, setBackFrom] = useState('default')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [openModal, setOpenModal] = useState(isOpen)
	const [loadingCreateOrEditNotification, setLoadingCreateOrEditNotification] = useState(false)
	const [loadingCardNotifications, setLoadingCardNotifications] = useState(true)
	const [, /*loadingCardWhatsapp*/ setloadingCardWhatsapp] = useState(true)
	const classes = useStyles()
	const [openWhatsappConfig, setOpenWhatsappConfig] = useState(false)
	const [query, setQuery] = useState({})

	useEffect(() => {
		setOpenModal(isOpen)
	}, [openModal])

	useEffect(() => {
		if (currentNotification.type) {
			setErrorMessageType(null)
		}
		if (currentNotification.value && currentNotification.value !== '') {
			setErrorMessageValue(null)
		}
	}, [currentNotification])

	useEffect(() => {
		setQuery({})
		setSendWhatsapp(false)
		setCurrentNotification(defaultNotification)
	}, [tab])

	useEffect(() => {
		if (tableVisible) {
			setLoadingCardNotifications(true)
			api.get(`/notification/config/${cardType}/${cardId}`)
				.then((response) => {
					setNotifications(response.data)
				})
				.catch((error) => {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				})
				.finally(() => {
					setLoadingCardNotifications(false)
				})
		}
	}, [tableVisible])

	useEffect(() => {
		if (rulesVisible) {
			setloadingCardWhatsapp(true)
			api.get('/notification/get-whatsapp-numbers')
				.then((response) => {
					setWhatsappNumbers(response.data)
				})
				.catch((error) => {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				})
				.finally(() => {
					setloadingCardWhatsapp(false)
				})
		}
	}, [rulesVisible])

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleAddNotification = () => {
		let qtdErrors = 0
		if (!currentNotification.type && tab === 'simple') {
			qtdErrors++
			setErrorMessageType('Escolha um caso')
		} else {
			setErrorMessageType(null)
		}

		if (
			currentNotification.type === 'WORD' &&
			tab === 'simple' &&
			(currentNotification.value === '' || currentNotification.value === null || currentNotification.value.trim().length === 0)
		) {
			qtdErrors++
			setErrorMessageValue('Preencha com uma palavra válida')
		} else {
			if (currentNotification.value === '') {
				qtdErrors++
				setErrorMessageValue('Preencha uma quantidade válida')
			} else {
				setErrorMessageValue(null)
			}
		}

		if (qtdErrors === 0) {
			setLoadingCreateOrEditNotification(true)
			api.post('/notification/config', {
				id: currentNotification.id,
				notification_type: currentNotification.type,
				value: currentNotification.value,
				interval_quantity: currentNotification.intervalQuantity,
				active: currentNotification.active,
				cardId,
				cardType,
				interval_unity: getIntervalUnityValue(currentNotification.intervalUnity),
				query,
				sendWhatsapp,
				whatsappNumbers
			})
				.then((res) => {
					sendMessageToast(res?.data?.toast?.message, res?.data?.toast?.type)
				})
				.catch((error) => {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				})
				.finally(() => {
					onClose()
					setLoadingCreateOrEditNotification(false)
				})
		}
	}

	const editNotifications = (row) => {
		setCurrentNotification({
			...row,
			type: row.notification_type,
			intervalUnity: getIntervalUnityLabel(row.intervalUnity),
			value: row.value
		})
		setBackFrom('edit')
		handleNewClick()
	}

	// const goBackTable = () => {
	// 	setCurrentNotification(defaultNotification)
	// 	setTableVisible(true)
	// }

	const handleChangeNotificationType = (e) => {
		// const value = e.target.value
		// const notification = typeEnum.find((it) => it.value === value)

		setCurrentNotification({
			...currentNotification,
			type: e.target.value,
			value: ''
		})
	}

	const handleNewClick = () => {
		setMenuVisible(false)
		setRulesVisible(true)
		setTableVisible(false)
	}

	const handleBack = () => {
		if (backFrom === 'default') {
			setMenuVisible(true)
			setRulesVisible(false)
			setTableVisible(false)
			setSendWhatsapp(false)
			setCurrentNotification(defaultNotification)
		} else {
			setMenuVisible(false)
			setRulesVisible(false)
			setTableVisible(true)
			setSendWhatsapp(false)
			setBackFrom('default')
		}
	}

	const handleEdit = () => {
		setMenuVisible(false)
		setRulesVisible(false)
		setTableVisible(true)
	}

	// const onChangeTab = (_, newValue) => {
	// 	setTab(newValue)
	// }

	return (
		<>
			<Dialog
				id='dialog-app'
				fullWidth={true}
				maxWidth='md'
				style={{ marginTop: '4%' }}
				open={openModal}
				onClose={onClose}
				BackdropProps={{ classes: { root: classes.backDrop } }}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						minHeight: '400px',
						maxHeight: '600px'
					}
				}}
			>
				<DialogTitle>
					<div className='jc-space-between d-flex ai-center'>
						{menuVisible && (
							<div style={{ color: '#1d8cf8', fontSize: 20 }}>
								<NotificationIcon className='fix-icon-margin-bottom-negative' size={25} color='#1d8cf8' /> Notificações do cartão{' '}
								{name}
							</div>
						)}

						{rulesVisible && (
							<div style={{ display: 'flex', alignItems: 'center', color: '#1d8cf8' }}>
								<Tooltip title='Voltar'>
									<IconButton color='primary' component='span' onClick={handleBack}>
										<ChevronLeftIcon size={25} color='#1d8cf8' />
									</IconButton>
								</Tooltip>
								<Typography gutterBottom variant='h6' component='div' style={{ marginBottom: 2, marginLeft: 10, fontSize: 20 }}>
									Me notifique caso:
								</Typography>
							</div>
						)}

						{tableVisible && (
							<div style={{ display: 'flex', alignItems: 'center', color: '#1d8cf8' }}>
								<Tooltip title='Voltar'>
									<IconButton color='primary' component='span' onClick={handleBack}>
										<ChevronLeftIcon size={25} color='#1d8cf8' />
									</IconButton>
								</Tooltip>
								<Typography gutterBottom variant='h6' component='div' style={{ marginBottom: 2, marginLeft: 10, fontSize: 20 }}>
									Editar / ver todas
								</Typography>
							</div>
						)}

						<div>
							<IconButton color='primary' style={{ outline: 'none' }} onClick={onClose}>
								<CloseIcon color={'#1d8cf8'} size={20} />
							</IconButton>
						</div>
					</div>
				</DialogTitle>
				<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
					{/* desomentar quando formos implementar regras combinadas */}
					{/* {rulesVisible && (
						<Tabs
							style={{ marginTop: '0px', marginLeft: '20px', textAlign: 'left', float: 'left' }}
							centered
							value={tab}
							onChange={onChangeTab}
							TabIndicatorProps={{ style: { background: '#1d8cf8' } }}
						>
							<Tab className={classes.tab} label='Simples' value='simple' style={{ outline: 'none' }} />
							<Tab className={classes.tab} label='Avançado' value='advanced' style={{ outline: 'none' }} />
						</Tabs>
					)} */}

					<CardContent style={{ padding: 0, margin: 0 }}>
						{menuVisible && (
							<Grid
								container
								style={{
									height: '300px'
								}}
							>
								<Grid item xs={6} container justifyContent='center' alignItems='center'>
									<Button classes={{ root: classes.newButton }} onClick={handleNewClick}>
										<Grid
											container
											justifyContent='center'
											alignItems='center'
											style={{ fontFamily: 'Poppins', letterSpacing: '1px' }}
										>
											<NotificationPlusIcon size={30} color='#1d8cf8' style={{ marginRight: '10px' }} />
											ME NOTIFIQUE
										</Grid>
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='center' alignItems='center'>
									<Button classes={{ root: classes.newButton }} onClick={handleEdit}>
										<Grid
											container
											justifyContent='center'
											alignItems='center'
											style={{ fontFamily: 'Poppins', letterSpacing: '1px' }}
										>
											<EditIcon size={30} color='#1d8cf8' style={{ marginRight: '10px' }} />
											EDITAR / VER TODAS
										</Grid>
									</Button>
								</Grid>
							</Grid>
						)}

						{tableVisible && (
							<Grid
								container
								style={{
									padding: '1.5em',
									color: 'rgb(120, 120, 120)',
									fontFamily: 'Poppins',
									fontWeight: '300',
									fontSize: '20px',
									minWidth: '35em'
								}}
							>
								<TableContainer>
									<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
										<TableHead>
											<TableRow>
												{headCells.map((headCell) => (
													<TableCell key={headCell.id} align={headCell.align || 'left'} padding='normal'>
														{headCell.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{loadingCardNotifications ? (
												<TableRow>
													<TableCell align='center' colSpan={6} style={{ padding: '20px' }}>
														<LoadingIcon color='#1d8cf8' size={40} thickness={4} />
													</TableCell>
												</TableRow>
											) : notifications?.length === 0 ? (
												<TableRow style={{ height: 53 }}>
													<TableCell align='center' colSpan={6}>
														Nenhuma notificação encontrada
													</TableCell>
												</TableRow>
											) : (
												notifications?.map((row) => (
													<TableRow key={row.id}>
														<TableCell>{getTypeLabel(row.notification_type)}</TableCell>
														<TableCell>{row.value}</TableCell>
														<TableCell>
															<NotificationActive
																notification={{ ...row, cardId, cardType }}
																sendMessageToast={sendMessageToast}
															/>
														</TableCell>
														<TableCell align='center'>
															<Button onClick={() => editNotifications(row)}>
																<HiOutlinePencil size={20} />
															</Button>
														</TableCell>
													</TableRow>
												))
											)}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component='div'
									count={notifications.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelDisplayedRows={({ from, to, count }) => {
										return from + '-' + to + ' de ' + count
									}}
									labelRowsPerPage='Notificações por página'
								/>
							</Grid>
						)}
						{rulesVisible && (
							<>
								<Grid
									container
									justifyContent='center'
									alignItems='baseline'
									spacing={2}
									style={{ minWidth: 750, marginTop: 90, marginBottom: 20 }}
								>
									{tab === 'simple' && (
										<>
											<Grid item xs={6}>
												<FormControl fullWidth>
													<InputLabel>Caso:</InputLabel>
													<Select
														value={currentNotification.type}
														label='Tipo'
														variant='standard'
														placeholder='Selecione'
														onChange={handleChangeNotificationType}
														style={{ width: '100%' }}
														MenuProps={{
															anchorOrigin: {
																vertical: 'bottom',
																horizontal: 'left'
															},
															transformOrigin: {
																vertical: 'top',
																horizontal: 'left'
															},
															PaperProps: {
																style: {
																	marginTop: '5px',
																	marginLeft: '-1px',
																	borderRadius: '0px 0px 10px 10px',
																	backdropFilter: 'blur(10px)',
																	backgroundColor: `${
																		isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'
																	}`,
																	borderBottom: `1px solid ${
																		isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'
																	}`,
																	borderLeft: `1px solid ${
																		isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'
																	}`,
																	borderRight: `1px solid ${
																		isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'
																	}`,
																	boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
																}
															}
														}}
													>
														{typeEnum.map((it) => (
															<MenuItem key={it.value} value={it?.value}>
																{it?.label}
															</MenuItem>
														))}
													</Select>
													<FormHelperText style={{ color: 'red' }}>{errorMessageType}</FormHelperText>
												</FormControl>
											</Grid>
											{currentNotification.type && (
												<Grid item xs={3}>
													<TextField
														variant='standard'
														label={formatLabelByType(currentNotification.type)}
														type={currentNotification.type === 'WORD' ? 'text' : 'number'}
														InputLabelProps={{
															shrink: true
														}}
														value={currentNotification.value}
														onChange={(e) => setCurrentNotification({ ...currentNotification, value: e.target.value })}
													/>
													<FormHelperText style={{ color: 'red' }}>{errorMessageValue}</FormHelperText>
												</Grid>
											)}
										</>
									)}

									{tab === 'advanced' && (
										<Grid
											item
											xs={12}
											style={{
												padding: '1em',
												maxHeight: window.innerHeight - 50,
												overflow: 'auto'
											}}
										>
											<QueryBuilder
												getSearchTermsOnAdd={(query) => {
													let q = query
													setQuery(q)
												}}
												isNotification={true}
											/>
										</Grid>
									)}

									{/* {(currentNotification.type || (query?.format?.sqlFormat && query?.isValid)) && (
										<Grid item xs={12} container justifyContent='center' alignItems='baseline' style={{ marginTop: '10px' }}>
											<Grid item style={{ display: 'flex', alignItems: 'center' }}>
												<Checkbox
													disabled={loadingCardWhatsapp}
													value={sendWhatsapp}
													color='primary'
													onChange={(e, checked) => setSendWhatsapp(checked)}
												/>
												<div style={{ marginTop: '-2px' }}>Enviar notificações pro Whatsapp </div>
											</Grid>
										</Grid>
									)} */}
								</Grid>
								{/* <Collapse in={sendWhatsapp}>
									<Grid item xs={12} container justifyContent='center' alignItems='baseline' style={{ marginTop: '0px' }}>
										<Grid item style={{ display: 'flex', alignItems: 'center' }}>
											<Button
												classes={{ root: classes.saveButton }}
												variant='contained'
												onClick={() => setOpenWhatsappConfig(true)}
												disabled={loadingCreateOrEditNotification}
											>
												<WhatsappIcon size={20} style={{ marginRight: '10px', color: '#25D366' }} />
												Configurar números
											</Button>
										</Grid>
									</Grid>
								</Collapse> */}
							</>
						)}
					</CardContent>

					{rulesVisible && (
						<Grid container justifyContent='center' alignItems='baseline'>
							<Grid container justifyContent='center' style={{ marginTop: '10px', marginBottom: '10px' }}>
								{(currentNotification.type || (query?.format?.sqlFormat && query?.isValid)) && (
									<Button
										classes={{ root: classes.saveButton }}
										variant='outlined'
										onClick={handleAddNotification}
										disabled={loadingCreateOrEditNotification}
									>
										Salvar
									</Button>
								)}
							</Grid>
							{currentNotification.type === 'ENGAGEMENT' && (
								<Typography style={{ fontSize: '0.8em', marginTop: '2em', opacity: 0.8 }}>
									*Engajamento: quantidade de ações no post (likes, retweets, comentários)
								</Typography>
							)}
						</Grid>
					)}
				</DialogContent>
			</Dialog>
			<WhatsappConfiguration
				whatsappNumbers={whatsappNumbers}
				setWhatsappNumbers={setWhatsappNumbers}
				open={openWhatsappConfig}
				onClose={() => setOpenWhatsappConfig(false)}
			/>
		</>
	)
}

export default NotificationConfigDialog
