import React, { useState, memo } from 'react'
import { Grid, Button, Dialog, Divider, Tab, Tabs, Collapse, DialogContent, Badge } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styled from '@emotion/styled'
import { DownloadIcon, HistoryIcon } from 'utils/SystemIcons'
import ExportXls from '../ExportXlsx/ExportXlsx'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import ExportEmail from '../ExportEmail/ExportEmail'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = '#1d8af8'
const grey = '#828282'

const blueTransparent = 'rgba(29, 138, 248, 10%)'

const useStyles = makeStyles({
	button: {
		maxWidth: '350px',
		fontFamily: 'Poppins',
		color: blue,
		background: 'transparent',
		border: `1px solid ${blueTransparent}`,
		'&:hover': {
			backgroundColor: blueTransparent,
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		},
		'&:disabled': {
			backgroundColor: 'transparent'
		}
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey,
		minWidth: '156px'
	}
})

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		right: -20,
		top: 12,
		backgroundColor: '#f50057'
	}
})

const serviceName = 'exportPostsOccurrences'

const ExportPostsData = ({ cardId, cardStatus, filters, type, wordCloudName, cardName, selectedPostsIds }) => {
	const [openHistory, setOpenHistory] = useState(false)
	const [openXlsx, setOpenXlsx] = useState(false)
	const [openEmail, setOpenEmail] = useState(false)
	const [tab, setTab] = useState(type === 'term' ? 'xlsx' : 'pdf')

	const classes = useStyles()

	const toggleHistory = () => {
		setOpenHistory(!openHistory)
	}

	const toggleXlsx = () => {
		setOpenXlsx(!openXlsx)
	}

	const toggleEmail = () => {
		setOpenEmail(!openEmail)
	}

	const onTabChange = (_, newTab) => {
		setTab(newTab)
	}

	return (
		<>
			<Grid item xs={12} style={{ margin: '0px' }}>
				<h4 style={{ fontFamily: 'Poppins', textAlign: 'center', marginTop: '30px', letterSpacing: '4px' }}>EXPORTAR POSTAGENS</h4>
				<div style={{ marginTop: '-10px', display: 'flex', justifyContent: 'center' }}>
					{type === 'term' && (
						<Tabs centered value={tab} onChange={onTabChange} TabIndicatorProps={{ style: { background: '#1d8cf8' } }}>
							<Tab className={classes.tab} label='XLSX' value='xlsx' style={{ outline: 'none' }} />
							{/* <Tab className={classes.tab} label='PDF' value='pdf' style={{ outline: 'none' }} /> */}
							<Tab className={classes.tab} label={'EMAIL'} value='email' style={{ outline: 'none' }} />
						</Tabs>
					)}
				</div>
				<Divider />
			</Grid>

			<Collapse in={tab == 'email' && selectedPostsIds.length == 0}>
				<Grid item xs={12} style={{ marginTop: '20px' }}>
					<div
						style={{
							fontFamily: 'Poppins',
							color: '#b8b8b8',
							margin: 'auto',
							fontWeight: '500',
							fontSize: '20px',
							textAlign: 'center',
							float: 'center'
						}}
					>
						Selecione pelo menos uma postagem
					</div>
				</Grid>
			</Collapse>

			<Grid container spacing={1} justifyContent='center' alignItems='center' style={{ marginTop: '30px', marginBottom: '30px' }}>
				<Grid item xs={6} container justifyContent='center'>
					<Button
						style={{ width: '100%', borderRadius: '100px' }}
						className={classes.button}
						onClick={tab == 'xlsx' ? toggleXlsx : toggleEmail}
						disabled={tab == 'email' && selectedPostsIds.length == 0}
					>
						<DownloadIcon size={20} style={{ marginRight: '10px' }} />
						<StyledBadge
							badgeContent={selectedPostsIds.length == 0 ? null : selectedPostsIds.length}
							color='error'
							style={{ fontFamily: 'Poppins' }}
						>
							NOVA EXPORTAÇÃO
						</StyledBadge>
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='center'>
					<Button style={{ width: '100%', borderRadius: '100px' }} className={classes.button} onClick={toggleHistory}>
						<HistoryIcon size={20} style={{ marginRight: '10px' }} />
						HISTÓRICO
					</Button>
				</Grid>
			</Grid>

			<Dialog
				id='dialog-xlsx'
				open={openXlsx}
				onClose={toggleXlsx}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='md'
			>
				<ExportXls
					cardId={cardId}
					cardStatus={cardStatus}
					filters={filters}
					wordCloudName={wordCloudName}
					toggleXlsx={toggleXlsx}
					cardName={cardName}
					selectedPostsIds={selectedPostsIds}
				/>
			</Dialog>
			<Dialog
				id='dialog-email'
				open={openEmail}
				onClose={toggleEmail}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='md'
			>
				<ExportEmail
					cardId={cardId}
					filters={filters}
					wordCloudName={wordCloudName}
					toggleEmail={toggleEmail}
					cardName={cardName}
					selectedPostsIds={selectedPostsIds}
				/>
			</Dialog>
			<Dialog
				id='dialog-xlsx'
				open={openHistory}
				onClose={toggleHistory}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='lg'
			>
				<DialogContent style={{ padding: 0, margin: 0, height: '700px', position: 'relative' }}>
					<ServiceHistory
						style={{ width: '100%', height: '100%' }}
						showFilterBar={true}
						cardId={cardId}
						isOpen={openHistory}
						toggleOpenHistory={toggleHistory}
						cardType={type}
						serviceName={serviceName}
						cardName={cardName}
						source={filters.sources}
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default memo(ExportPostsData)
