import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid } from '@mui/material'

const TwitterSummarySkeletonLoad = () => (
	<>
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Skeleton height='14.5em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
			<Grid item xs={3}>
				<Skeleton height='10em' style={{ borderRadius: '20px' }} />
			</Grid>
		</Grid>
	</>
)

export default TwitterSummarySkeletonLoad
