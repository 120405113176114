import React, { useState, memo } from 'react'
import { IconButton, Grid, Collapse, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { ChevronLeftIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import api from 'service/service'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { connect } from 'react-redux'

const MoveToFolder = ({ foldersRedux, typeRedux, toggleMoveToFolder, reportSource, cardId, cardFolderId, isOpen, onMovedToFolder, color }) => {
	const [submitting, setSubmitting] = useState(false)

	const handleChangeSelectFolder = (event) => {
		if (submitting) {
			sendMessageToast('Não é possível submeter múltiplas requisições ao mesmo tempo!', constantsVox.TOAST.TYPES.WARNING)
		}
		let folderId = Number(event.target.value)

		setSubmitting(true)
		api.post('/monitoring/set-card-folder', { folderId, cardId: cardId, cardType: typeRedux, source: reportSource })
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
				onMovedToFolder()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	return (
		<Collapse in={isOpen}>
			{isOpen && (
				<>
					<Grid container>
						<IconButton color='primary' onClick={toggleMoveToFolder} style={{ borderRadius: '100px' }}>
							<ChevronLeftIcon size={20} style={{ color: color }} />
						</IconButton>

						<Grid item style={{ color, fontFamily: 'Poppins', marginTop: '8px' }}>
							MINHAS PASTAS
						</Grid>
					</Grid>
					<Grid container style={{ marginLeft: '15px' }}>
						<FormControl component='fieldset'>
							<RadioGroup aria-label='group' name='groups' value={cardFolderId} onChange={handleChangeSelectFolder}>
								{foldersRedux
									?.filter((folder) => folder.type == typeRedux.toUpperCase()) //arrumar isso aqui pra fazer essa busca na base de acordo com oq foi escolhido no monitoramento
									?.map((folder) => (
										<FormControlLabel
											key={folder.id}
											disabled={submitting}
											value={folder.id}
											control={<Radio size='small' color='default' />}
											label={<div className='color-grey fs-0-9i'>{folder.name}</div>}
										/>
									))}
								{foldersRedux?.filter((folder) => folder.type == typeRedux.toUpperCase())?.length == 0 && (
									<Grid item style={{ color: 'rgba(0,0,0,0.5)', fontFamily: 'Poppins', marginTop: '8px', fontSize: '14px' }}>
										Crie uma pasta para mover
									</Grid>
								)}
							</RadioGroup>
						</FormControl>
					</Grid>
				</>
			)}
		</Collapse>
	)
}

const mapStateToProps = ({ store: { global, card } }) => ({
	foldersRedux: global.folder.currentFolders,
	typeRedux: card.cardType
})

export default connect(mapStateToProps)(memo(MoveToFolder))
