import React from 'react'
import { AnalysisIcon, RewindIcon, BarsIcon, DocumentIcon } from 'utils/SystemIcons'

const metricsIcon = <AnalysisIcon className='icon-list-items-tab' size='20' />
const backtestingIcon = <RewindIcon className='icon-list-items-tab' size='20' />
const outliersIcon = <BarsIcon className='icon-list-items-tab' size='20' />
const modelSummary = <DocumentIcon className='icon-list-items-tab' size='20' />

const menuDefaultNew = () => {
	return [
		{
			label: 'Métricas',
			tab: 'metrics',
			status: { name: 'success', label: '' },
			icon: metricsIcon
		},
		{
			label: 'Backtesting',
			tab: 'backtesting',
			status: { name: 'success', label: '' },
			icon: backtestingIcon
		},
		{
			label: 'Previsões fora da amostra',
			tab: 'outliers',
			status: { name: 'success', label: '' },
			icon: outliersIcon
		},
		{
			label: 'Resumo dos modelos',
			tab: 'modelSummary',
			status: { name: 'success', label: '' },
			icon: modelSummary
		}
	]
}

export { menuDefaultNew }
