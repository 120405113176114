import { Autocomplete, Chip, Paper, TextField, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const isFirefox = typeof InstallTrigger !== 'undefined'
const TAGS_REGEX = /[a-zA-Z0-9\b\0 _;]/
const TAGS_REGEX_STRING = /^[a-zA-Z0-9\b\0 _;]+$/
const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'
let lastOnDeleteTime = null

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const handleInputTag = ({ value, setErrorText, setError, setCurrentText }) => {
	const dateDiff = new Date().getTime() - lastOnDeleteTime
	const isValidTag = validateTag(value, setErrorText)
	setError(!isValidTag)
	if (dateDiff > 100) {
		setCurrentText(value)
	}
}

const handleOnChangeValue = ({ value, setErrorText, setError, setTags, setCurrentText }) => {
	let addMultiple = value.flatMap((item) => item.split(';')).filter((it) => it.trim().length > 0)
	const addMultipleUnique = Array.from(new Set(addMultiple))
	handleChangeTags({ tags: addMultipleUnique, setErrorText, setError, setTags, setCurrentText })
}

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	}
})

const RenderTagLabel = ({ tag }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					backgroundColor: blue
				}}
			>
				{tag}
			</div>
		</div>
	)
}

const validateTag = (tag, setErrorText) => {
	if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
		setErrorText('Não pode começar ou terminar com espaço vazio, este será removido ao adicionar')
		return false
	}

	if (!TAGS_REGEX_STRING.test(tag) && tag !== '') {
		setErrorText('Não pode conter certos caracteres especiais')
		return false
	}

	setErrorText('')
	return true
}

const removeExtraSpaces = (tag) => {
	let newTag = tag
	while (newTag.includes('  ')) {
		newTag = newTag.replace('  ', ' ')
	}
	while (newTag[0] === ' ') {
		newTag = newTag.substring(1)
	}
	while (newTag[newTag.length - 1] === ' ') {
		newTag = newTag.substring(0, newTag.length - 1)
	}
	return newTag
}

const handleChangeTags = ({ tags, setErrorText, setError, setTags, setCurrentText }) => {
	tags = tags.map((tag) => removeExtraSpaces(tag.trim()))

	let isValid = true
	let isUnique = true

	tags.forEach((tag, index) => {
		if (tags.indexOf(tag) !== index) {
			isUnique = false
		}
	})

	tags.forEach((tag) => {
		if (!validateTag(tag, setErrorText)) {
			isValid = false
		}
	})

	if (isValid && isUnique) {
		setError(false)
		setTags(tags)
		setErrorText('')
	} else {
		setError(true)
		if (isUnique === false) {
			setErrorText('Não pode ser repetido')
		}
		setCurrentText(tags[tags.length - 1])
	}
}

const SearchTags = ({ searchTags, onChangeTags, icon, title = '' }) => {
	const [currentText, setCurrentText] = useState('')
	const [tags, setTags] = useState(searchTags)
	const [errorText, setErrorText] = useState('')
	const [error, setError] = useState(false)

	useEffect(() => {
		onChangeTags(tags)
	}, [tags])

	return (
		<Autocomplete
			multiple
			freeSolo
			PaperComponent={CustomPaper}
			disableClearable
			options={[]}
			value={tags}
			inputValue={currentText}
			onInputChange={(_, value) => handleInputTag({ value, setErrorText, setError, setCurrentText })}
			onChange={(_, value) => handleOnChangeValue({ value, setErrorText, setError, setTags, setCurrentText })}
			style={{ width: '100%' }}
			renderInput={(params) => (
				<div style={{ position: 'relative' }}>
					<CssTextField
						error={error}
						onKeyDown={(event) => {
							if (!TAGS_REGEX.test(event.key)) {
								event.preventDefault()
							}
						}}
						variant='standard'
						label={
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{icon}
									<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>{title}</div>
								</div>
							</div>
						}
						helperText={
							errorText ||
							(currentText.length > 0 &&
								'Pressione Enter para adicionar, separe por ponto e vírgula (;) para adicionar múltiplos ao mesmo tempo')
						}
						{...params}
					/>
				</div>
			)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						size='medium'
						style={{ color: white, backgroundColor: blue, borderColor: blue }}
						key={index}
						variant='outlined'
						label={<RenderTagLabel tag={option} />}
						{...getTagProps({ index })}
						onDelete={(e) => {
							const props = getTagProps({ index })
							props.onDelete(e)
							lastOnDeleteTime = new Date().getTime()
						}}
					/>
				))
			}
		/>
	)
}

export default SearchTags
