import moment from 'moment-timezone'
import { Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import ComponentCard from 'components/ComponentCard/ComponentCard'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux'
import api from 'service/service'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { MessageIcon, UserIcon, ExportIcon, LoadingIcon, AlertIcon } from 'utils/SystemIcons'
import constants from 'constants-vox'

const getExportIcon = (type, status) => {
	if (status === constants.EXPORT_DATA.STATUS.PROCESSING) {
		return (
			<Tooltip title='Processando'>
				<IconButton style={{ padding: 1, fontSize: '1em' }}>
					<LoadingIcon size={18} thickness={1} color='#1d8cf8' />
				</IconButton>
			</Tooltip>
		)
	} else if (status === constants.EXPORT_DATA.STATUS.DONE) {
		switch (type) {
			case 'term':
				return <MessageIcon size={20} className='fix-icon-margin-bottom-negative' color='#1d8cf8' />
			case 'profile':
				return <UserIcon size={20} className='fix-icon-margin-bottom-negative' color='#1d8cf8' />
			default:
				break
		}
	} else {
		return <AlertIcon size={20} className='fix-icon-margin-bottom-negative' />
	}
}

const ExportItem = ({ item }) => {
	return (
		<Grid container item xs={3} style={{ height: '1.5em' }}>
			<Grid item style={{ marginRight: '0.5em' }}>
				{getExportIcon(item.type, item.status)}
			</Grid>
			<Grid item xs={6} style={{ fontWeight: '500', color: '#696969' }}>
				{moment(item.created_at).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')}
			</Grid>
			<Grid item xs={5}>
				{item.status === constants.EXPORT_DATA.STATUS.DONE && (
					<a href={item.url} style={{ marginLeft: '0.5em' }} target='_blank' rel='noreferrer'>
						Abrir
					</a>
				)}
			</Grid>
		</Grid>
	)
}

const ExportContent = ({ cards, globalFiltersRedux, cardType }) => {
	const [isSolo, setIsSolo] = useState(cards.length === 1)
	const [isLoading, setIsLoadig] = useState(false)

	let { data, isFetching } = useUpdateData({
		url: `/export/get-all-by-type-card-id/${cardType}/${cards[0]?.id}`,
		updateItemName: 'Cartoes',
		refetchInterval: 10000,
		freshDataTime: 10000
	})

	useEffect(() => {
		setIsSolo(cards.length === 1)
	}, [cards])

	const onExport = () => {
		if (cards[0]?.id && isSolo) {
			setIsLoadig(true)
			const cardId = cards[0]?.id
			api.post('/export/create', { filters: globalFiltersRedux, type: cardType, cardId })
				.then((response) => {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				})
				.catch((error) => {
					if (error?.response?.data?.toast) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(setIsLoadig(false))
		}
	}

	return (
		<ComponentCard
			style={{ width: '100%' }}
			isLoading={isLoading || isFetching}
			hasFullScreen={false}
			title={{
				name: 'Exportar cartão com filtros globais',
				icon: (
					<ExportIcon
						size='20'
						style={{
							color: '#1d8cf8'
						}}
					/>
				)
				//, description: 'Exporta dados do cartão com os filtros ativos'
			}}
		>
			{isSolo ? (
				<Grid container style={{ padding: '2em' }}>
					<Grid item xs={12}>
						<Button disabled={isLoading} onClick={onExport}>
							Exportar cartão com filtros globais atuais
						</Button>
					</Grid>
					<Divider style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: '1px', width: '100%', marginTop: '2em', marginBottom: '2em' }} />
					<Grid item xs={12}>
						<Typography gutterBottom variant='h5' className='m-0' component='div'>
							Histórico
						</Typography>
						<Grid container direction='column' style={{ margin: '0px' }} spacing={0}>
							{data.map((it) => (
								<ExportItem key={it.id} item={it} />
							))}
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid container direction='row' justifyContent='center' alignItems='center' style={{ height: '10em' }}>
					<h2 style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
						Componente não disponível para o modo comparativo
					</h2>
				</Grid>
			)}
		</ComponentCard>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	cards: store.card.comparison.cards,
	cardType: store.card.comparison.type
})

export default connect(mapStateToProps, null)(memo(ExportContent))
