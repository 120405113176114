import React, { useEffect, useState } from 'react'
import { Grid, Card, Divider, Pagination } from '@mui/material'
import { connect } from 'react-redux'
import { UsersIcon } from 'utils/SystemIcons'
import { saveAllCardsCanOpenAction, saveCardTypeAction, resetCardsComparisonAction } from 'store/redux/actions/card.actions'
import { NewProfileDialogAccountManagement } from './components/NewProfileDialogAccountManagement/NewProfileDialogAccountManagement'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import FoldersSideContainer from 'components/FoldersSideContainer/FoldersSideContainer'
import constantsVox from 'constants-vox'
import AnalysisCardSearch from 'components/AnalysisCardSearch/AnalysisCardSearch'

import { useUpdateData } from 'hooks/ReactQueryHooks'
import AnalysisCardContainer from 'components/AnalysisCardContainer/AnalysisCardContainer'
import { resetGlobalFiltersTermAction } from 'store/redux/actions/global.actions'

const AccountManagement = ({ saveAllCardsCanOpenDispatch, saveCardTypeDispatch, resetCardsComparisonDispatch, resetGlobalFiltersDispatch, user }) => {
	const [filterNameCard, setFilterNameCard] = useState('')
	const [loading, setLoading] = useState(true)
	const [folder, setFolder] = useState({ id: 'ALL_ID', name: 'Todos' })
	const [numberOfPages, setNumberOfPages] = useState(1)
	const [page, setPage] = useState(0)

	//PERMISSIONS
	let permissionCreateCard = !user.subUser || (user.subUser && user.subUser.permissionCreate)
	let permissionRemoveCard = !user.subUser || (user.subUser && user.subUser.permissionRemove)
	let isUserAdmin = user?.isAdmin

	let profiles = useUpdateData({
		url: `/account-management/profile/get-all?folderId=${folder.id}&page=${page}`,
		updateItemName: 'Profiles',
		refetchInterval: 10000,
		freshDataTime: 10000
	})

	useEffect(() => {
		if (profiles.isFetched) {
			setLoading(false)
		}
	}, [profiles.isFetched])

	useEffect(() => {
		resetCardsComparisonDispatch()
		resetGlobalFiltersDispatch()
	}, [profiles.isFetched])

	const refetchProfiles = () => profiles.refetch()

	useEffect(() => {
		saveAllCardsCanOpenDispatch(profiles?.data?.items || [], { tab: constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT, source: null })
		setNumberOfPages(profiles?.data?.totalPages || 1)
		saveCardTypeDispatch()
	}, [profiles.data])

	const handleGetSelectedFolder = (folder) => {
		setFolder(folder)
	}

	const handleChangeSearchFilter = (value) => {
		setFilterNameCard(value)
	}

	const handleChangePage = (_, value) => {
		setPage(value - 1)
	}

	return (
		<Grid container style={{ paddingLeft: '1.4em', paddingRight: '1.4em' }}>
			<Grid item xs={12}>
				<GraphTitle title='Minhas Contas' icon={<UsersIcon size={25} style={{ color: '#1d8cf8' }} />} description='' />
				<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '0.4em' }} />
			</Grid>
			<Grid container style={{ marginTop: '30px' }}>
				<Grid item xs={2} style={{ height: '86vh' }}>
					<FoldersSideContainer
						type={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}
						getSelectedFolder={handleGetSelectedFolder}
						canEditFolder={permissionCreateCard}
						canDeleteFolder={permissionRemoveCard}
					/>
				</Grid>

				<Grid item xs={10}>
					<Card
						style={{
							borderRadius: '20px',
							marginLeft: '20px',
							backgroundColor: 'rgba(255, 255, 255, 0.2)',
							border: '1px solid rgba(245, 245, 245, 0.5)',
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}}
					>
						<Grid container style={{ backgroundColor: 'transparent', marginBottom: '27px', borderRadius: '10px 10px 0px 0px' }}>
							<Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
								<div style={{ marginLeft: '12px' }}>
									<NewProfileDialogAccountManagement
										cardType={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}
										onCreated={refetchProfiles}
										onEdited={refetchProfiles}
										canCreate={permissionCreateCard}
									/>
								</div>
							</Grid>
							<Grid item xs={4} style={{ marginTop: '4px' }}>
								<AnalysisCardSearch
									onChange={handleChangeSearchFilter}
									loading={loading}
									cardType={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}
									folderId={folder.id}
									view={true} //??
									isComp={false} //??
									compCards={[]} //??
								/>
							</Grid>

							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>

						<AnalysisCardContainer
							filterNameCard={filterNameCard}
							folder={folder}
							page={page}
							refetchItems={refetchProfiles}
							items={profiles?.data?.items}
							isLoading={loading}
							permissionCreateCard={permissionCreateCard}
							permissionRemoveCard={permissionRemoveCard}
							isUserAdmin={isUserAdmin}
						/>

						<Grid container alignItems='center' style={{ height: '5vh' }}>
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%' }} />
							<Grid item xs={3} style={{ display: 'flex' }}>
								<div
									style={{
										whiteSpace: 'nowrap',
										fontFamily: 'Poppins',
										fontWeight: '300',
										fontSize: '14px',
										marginLeft: '15px',
										letterSpacing: '3px',
										textTransform: 'uppercase'
									}}
								>
									{folder.name}
								</div>
								{!!profiles?.data?.profilesCount && (
									<div
										style={{
											whiteSpace: 'nowrap',
											fontFamily: 'Poppins',
											fontWeight: '300',
											fontSize: '14px',
											marginLeft: '15px',
											letterSpacing: '3px',
											textTransform: 'uppercase',
											color: '#4fddf8'
										}}
									>
										({profiles?.data?.profilesCount})
									</div>
								)}
							</Grid>
							<Grid item xs={6} container justifyContent='center' alignItems='center'>
								<Pagination count={numberOfPages} page={page + 1} onChange={handleChangePage} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = ({ store: { user } }) => ({
	user
})

const mapDispatchToProps = (dispatch) => ({
	saveAllCardsCanOpenDispatch: (allCards, searchPropsForComparativeBar) =>
		dispatch(saveAllCardsCanOpenAction(allCards, searchPropsForComparativeBar)),
	saveCardTypeDispatch: () => dispatch(saveCardTypeAction('ACCOUNT_MANAGEMENT')),
	resetCardsComparisonDispatch: () => dispatch(resetCardsComparisonAction()),
	resetGlobalFiltersDispatch: () => dispatch(resetGlobalFiltersTermAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement)
