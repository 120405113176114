import React, { memo } from 'react'
import { Grid } from '@mui/material'
import Summary from 'components/Summary/Summary'

import './TwitterContentBodyAccount.css'

const TwitterContentBodyAccount = ({ cards, filters, summaryTwitter }) => {
	return (
		<div className='twitter-body-container'>
			{summaryTwitter && (
				<Grid container className='break-print'>
					<Summary cards={cards} filtersReport={{ ...filters, source: 'twitter' }} isReport />
				</Grid>
			)}
		</div>
	)
}

export default memo(TwitterContentBodyAccount)
