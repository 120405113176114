import React, { useState, useEffect, memo, useRef } from 'react'
import { Tooltip, Grid, IconButton, TextField, Divider, Collapse, styled } from '@mui/material'
import { SearchIcon, NewFolderIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import ModalEditFolder from './components/ModalEditFolder/ModalEditFolder'
import FolderItem from './components/FolderItem/FolderItem'
import api from 'service/service'
import ModalNewFolder from './components/ModalNewFolder/ModalNewFolder'
import { connect } from 'react-redux'
import { saveCurrentFoldersAction } from 'store/redux/actions/global.actions'
import constants from 'constants-vox'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 0.1)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const FoldersSideContainer = ({ type, getSelectedFolder, saveCurrentFoldersDispatch, canEditFolder, canDeleteFolder }) => {
	const [folders, setFolders] = useState(null)
	const [filteredfolders, setFilteredFolders] = useState(null)
	const [folderFilter, setFolderFilter] = useState('')
	const [loading, setLoading] = useState(true)
	const [editFolderObject, setEditFolderObject] = useState({ id: null, name: '' })
	const [isEditingFolder, setIsEditingFolder] = useState(false)
	const [isDeletingFolder, setIsDeletingFolder] = useState(false)
	const [isCreatingFolder, setIsCreatingFolder] = useState(false)
	const [selectedFolderId, setSelectedFolderId] = useState('ALL_ID')

	const isMounted = useRef(true)

	useEffect(() => {
		openFolder('ALL_ID', 'Todos')
		isMounted.current = true
		loadFolders()
		return () => {
			isMounted.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type])

	useEffect(() => {
		setFilteredFolders(
			folders?.filter(
				(f) =>
					f.name
						?.replace(/,/g, ' ')
						.toLowerCase()
						.indexOf((folderFilter || '').toString().toLowerCase()) > -1
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderFilter])

	const loadFolders = async () => {
		setLoading(true)
		setFolderFilter('')
		try {
			api.get(`folder/get-folders-by-user-id/${type}`)
				.then((response) => {
					if (isMounted.current) {
						const filteredFolders = response?.data?.filter((folder) => folder.name !== 'ALL')
						setFolders(filteredFolders)
						setFilteredFolders(filteredFolders)
						saveCurrentFoldersDispatch(filteredFolders)
					}
				})
				.finally(() => setLoading(false))
		} catch (error) {
			if (isMounted.current) {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			}
		}
	}

	const deleteFolder = async () => {
		try {
			const source = 'twitter' //só temos o bubble burst do twitter no momento, futuramente padronizar isso
			const response = await api.post('/folder/delete', {
				deleteFolderId: editFolderObject?.id,
				type,
				source
			})
			openFolder('ALL_ID', 'Todos')
			if (response?.data?.toast) {
				sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
			}
			loadFolders()
		} catch (error) {
			if (verifyServerResponseCanShowToast(error)) {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			}
		}
		setIsDeletingFolder(false)
		setIsEditingFolder(false)
	}

	const toggleIsEditingFolder = (folderId, name) => {
		if (!isEditingFolder) {
			setEditFolderObject({ id: folderId, name })
		}
		setIsEditingFolder(!isEditingFolder)
	}

	const openFolder = (id, name) => {
		setSelectedFolderId(id)
		getSelectedFolder({ id, name })
	}

	const toggleCreateGroupState = () => {
		setIsCreatingFolder(!isCreatingFolder)
	}

	const onCreatedFolder = () => {
		loadFolders()
	}

	return (
		<>
			<div
				style={{
					borderRadius: '20px',
					backgroundColor: 'rgba(255, 255, 255, 0.2)',
					border: '1px solid rgba(245, 245, 245, 0.5)',
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}}
			>
				<Grid container style={{ marginBottom: '10px', borderRadius: '10px 10px 0px 0px' }}>
					<Grid item xs={9} style={{ paddingLeft: '8px', height: '48px', marginTop: '4px' }}>
						<CssTextField
							value={folderFilter}
							variant='standard'
							onChange={(e) => setFolderFilter(e.target.value)}
							fullWidth
							label={
								<div style={{ display: 'flex', marginTop: '-6px', whiteSpace: 'nowrap' }}>
									<SearchIcon size={17} style={{ position: 'relative', transform: 'translate(0px, 5px)', color: '#3d8cf8' }} />
									<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>Buscar Pasta</div>
								</div>
							}
						/>
					</Grid>
					<Grid item xs={3}>
						<div style={{ marginLeft: '13px', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Tooltip title='Nova pasta' placement='bottom' enterDelay={500} enterNextDelay={500}>
								<span>
									<IconButton onClick={toggleCreateGroupState}>
										<NewFolderIcon
											size={19}
											style={{ position: 'relative', transform: 'translate(0px, 0px)', color: '#3d8cf8' }}
										/>
									</IconButton>
								</span>
							</Tooltip>
						</div>
					</Grid>
					<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
				</Grid>
				<div
					style={{
						minHeight: '71.5vh',
						maxHeight: '71.5vh',
						marginTop: '20px',
						paddingLeft: '8px',
						paddingRight: '8px',
						overflowY: 'auto',
						overflowX: 'hidden'
					}}
				>
					<Collapse in={!loading}>
						{!loading && (
							<>
								<FolderItem
									key='ALL'
									id='ALL_ID'
									name='TODOS'
									toggleIsEditingFolder={toggleIsEditingFolder}
									openFolder={openFolder}
									isEditable={false}
									isSelected={selectedFolderId === 'ALL_ID'}
									isAll
								/>
								{type !== 'report' && type !== constants.CARD_TYPE.ACCOUNT_MANAGEMENT && (
									<FolderItem
										key='COMP'
										id='COMPARATIVE_ID'
										name='COMPARATIVOS'
										toggleIsEditingFolder={toggleIsEditingFolder}
										openFolder={openFolder}
										isEditable={false}
										isSelected={selectedFolderId === 'COMPARATIVE_ID'}
										isComp
									/>
								)}
								{type !== constants.CARD_TYPE.ACCOUNT_MANAGEMENT && (
									<FolderItem
										key='DEMO'
										id='DEMO_ID'
										name='CARTÕES DEMO'
										toggleIsEditingFolder={toggleIsEditingFolder}
										openFolder={openFolder}
										isEditable={false}
										isSelected={selectedFolderId === 'DEMO_ID'}
										isDemo
									/>
								)}
								<Divider
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.01)',
										height: '0.5px',
										width: '100%',
										marginTop: '12px',
										marginBottom: '12px'
									}}
								/>
								{filteredfolders?.map((folder) => (
									<FolderItem
										key={folder.id}
										id={folder.id}
										name={folder.name}
										toggleIsEditingFolder={toggleIsEditingFolder}
										openFolder={openFolder}
										isEditable={canEditFolder || canDeleteFolder}
										isSelected={selectedFolderId == folder.id}
									/>
								))}
							</>
						)}
					</Collapse>
				</div>
			</div>
			<ModalNewFolder
				isOpen={isCreatingFolder}
				toggle={toggleCreateGroupState}
				onCreatedFolder={onCreatedFolder}
				type={type}
				canCreate={canEditFolder}
			/>
			<ModalEditFolder
				isOpen={isEditingFolder}
				toggle={toggleIsEditingFolder}
				folderId={editFolderObject.id}
				folderName={editFolderObject.name}
				loadGroups={loadFolders}
				isOpenDelete={isDeletingFolder}
				onConfirmDelete={deleteFolder}
				toggleDelete={() => setIsDeletingFolder(!isDeletingFolder)}
				type={type}
				canEditFolder={canEditFolder}
				canDeleteFolder={canDeleteFolder}
			/>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

const mapDispatchToProps = (dispatch) => ({
	saveCurrentFoldersDispatch: (folders) => dispatch(saveCurrentFoldersAction(folders))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(FoldersSideContainer))
