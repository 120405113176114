import React, { useEffect, useState } from 'react'
const { forwardRef, useImperativeHandle } = React
import PotentialReachRenderer from './components/PotentialReachRenderer'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'

const getComparativeValue = (data) => {
	let totalValue = 0
	data?.map((card) => {
		totalValue += card?.selectedInterval?.metrics?.potential_reach_metric || 0
	})

	return totalValue
}

const PotentialReachWidget = ({ cards, filters, isFetchingData = true }, ref) => {
	const [interval, setInterval] = useState({ fromDate: filters.fromDate, toDate: filters.toDate })
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setInterval({ fromDate: filters.fromDate, toDate: filters.toDate })
	}, [filters.fromDate, filters.toDate, cards])

	useImperativeHandle(ref, () => ({
		f(newInterval) {
			setInterval(newInterval)
		}
	}))

	const getBody = () => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: { ...filters, fromDate: interval.fromDate, toDate: interval.toDate }
	})

	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/mini-summary',
		updateItemName: 'PotentialReach' + cards?.map((card) => card.id),
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	if (verifyServerResponseCanShowToast(error)) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	return (
		<div
			style={{
				position: 'absolute',
				marginTop: '80px',
				marginLeft: '25px'
			}}
		>
			{!loading && !!getComparativeValue(data) && <PotentialReachRenderer loading={isFetching} value={getComparativeValue(data)} />}
		</div>
	)
}

export default forwardRef(PotentialReachWidget)
