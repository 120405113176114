import constants from '../../constants-vox'
import moment from 'moment'
let pt = require('apexcharts/dist/locales/pt.json')

const formatValue = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return value
		} else {
			return value?.toFixed(2)
		}
	} else {
		return null
	}
}

export const graphOptionsArea = (
	isReport,
	title,
	colors,
	curve = 'smooth',
	onIntervalChange,
	graphTime,
	isSolid,
	zoom = true,
	toolbar = true,
	id = 'area',
	isAi = false
) => {
	let utc = graphTime === 'days' ? true : false
	let tooltipFormat = graphTime === 'hour' ? 'dd MMM HH:mm' : 'dd MMM'

	let options = {
		colors: colors,
		annotations: {
			// points: [
			// 	{
			// 		x: new Date('17 Jun 2022').getTime(),
			// 		y: 72653,
			// 		marker: {
			// 			size: 8,
			// 			fillColor: 'transparent',
			// 			strokeColor: '#1d8cf8',
			// 			radius: 2,
			// 			cssClass: 'apexcharts-custom-class'
			// 		},
			// 		label: {
			// 			borderColor: '#1d8cf8',
			// 			offsetY: 0,
			// 			style: {
			// 				color: '#fff',
			// 				background: '#1d8cf8'
			// 			},
			// 			text: 'Evento atípico'
			// 		}
			// 	}
			// ]
			// xaxis: [
			// {
			// 	id: 'teste',
			// 	x: new Date('13 Mar 2023').getTime(),
			// 	x2: new Date('14 Mar 2023').getTime(),
			// 	fillColor: 'rgba(29, 140, 248, 0.01)',
			// 	borderColor: 'rgba(29, 140, 248, 0.5)',
			// 	strokeDashArray: 4,
			// 	opacity: 1,
			// 	label: {
			// 		click: function (e) {
			// 		},
			// 		borderRadius: 10,
			// 		orientation: 'horizontal',
			// 		textAnchor: 'start',
			// 		borderColor: 'rgba(29, 140, 248, 0.1)',
			// 		style: {
			// 			fontSize: '12px',
			// 			color: '#1d8cf8',
			// 			background: 'rgba(29, 140, 248, 0.1)'
			// 		},
			// 		offsetY: -14,
			// 		offsetX: 4,
			// 		text: 'Ver insight'
			// 	}
			// }
			// ]
		},
		chart: {
			id: id,
			events: {
				zoomed: function (chartContext, { xaxis }) {
					if (onIntervalChange) {
						onIntervalChange(xaxis)
					}
				}
			},
			foreColor: '#525f7f',
			height: 350,
			locales: [pt],
			defaultLocale: 'pt',
			animations: {
				enabled: true
			},
			selection: {
				enabled: true,
				fill: {
					color: '#1d8cf8',
					opacity: 0.1
				},
				stroke: {
					width: 1,
					dashArray: 3,
					color: '#1d8cf8',
					opacity: 0.4
				}
			},
			zoom: {
				enabled: zoom,
				type: 'x'
			},
			toolbar: {
				show: toolbar,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: !isReport,
					selection: false,
					zoom: !isReport && !isAi,
					zoomin: !isReport && !isAi,
					zoomout: !isReport && !isAi,
					pan: false,
					reset: (!isReport && !isAi) | '<img src="/static/icons/reset.png" width="20">',
					customIcons: []
				},
				autoSelected: 'zoom',
				export: {
					csv: {
						filename: 'voxradar_export',
						columnDelimiter: ',',
						dateFormatter(timestamp) {
							return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
						}
					}
				}
			}
		},
		plotOptions: {
			bar: {
				columnWidth: '33%'
			}
		},
		title: {
			text: `${title}`,
			align: 'left',
			margin: 10,
			offsetX: 0,
			offsetY: 0,
			floating: false,
			style: {
				fontSize: '25px',
				fontWeight: 'regular',
				fontFamily: 'Poppins',
				color: '#525f7f'
			}
		},
		grid: {
			show: true,
			borderColor: 'rgba(82, 95, 127, 0.3)',
			position: 'back',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			colors: colors,
			curve: curve
		},
		fill: {
			colors: colors,
			opacity: 1,
			type: isSolid ? 'solid' : 'gradient',
			gradient: {
				shade: 'dark',
				type: 'vertical',
				shadeIntensity: 0.2,
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 0.5,
				opacityTo: 0.25,
				stops: [0, 100]
			}
		},
		markers: {
			colors: colors,
			size: 0,
			strokeColors: 'rgba(255, 255, 255, 0)',
			hover: {
				size: 9
			}
		},
		legend: {
			show: true,
			fontSize: isReport ? '15px' : '12px',
			showForSingleSeries: true,
			markers: {
				fillColors: colors
			}
		},
		tooltip: {
			shared: true,
			marker: {
				show: true
			},
			x: {
				show: true,
				format: tooltipFormat,
				formatter: undefined
			}
		},
		theme: {
			palette: 'palette1'
		},
		xaxis: {
			type: 'datetime',
			axisBorder: {
				show: true,
				color: 'rgba(82, 95, 127, 0.3)',
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: 0
			},
			labels: {
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				datetimeUTC: utc
			}
		},
		yaxis: {
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: -10,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => formatValue(value)
			}
		}
	}

	return options
}
