import React, { useState, useEffect, memo } from 'react'
import { Tooltip, IconButton, Menu, Button, Grid } from '@mui/material'
import { DotsVerticalIcon, InfoIcon, CloseIcon, UpdateIcon, NotificationIcon } from 'utils/SystemIcons'
import constantsVox from 'constants-vox'
import NotificationConfigDialog from 'components/AnalysisCardMenu/components/NotificationConfigDialog/NotificationConfigDialog'

const isFirefox = typeof InstallTrigger !== 'undefined'
const IconSize = 15
const Iconstyle = { color: '#9A9A9A', marginRight: '0.3em' }
const Buttonstyle = {
	borderRadius: '100px',
	textAlign: 'center',
	color: '#9A9A9A',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '15em',
	fontFamily: 'Poppins'
}

const InfoIconOption = <InfoIcon size={IconSize} style={Iconstyle} />
const UpdateIconOption = <UpdateIcon size={IconSize} style={Iconstyle} />
// const FilterIconOption = <FilterIcon size={IconSize} style={Iconstyle} />
const CloseIconOption = <CloseIcon size={IconSize} style={Iconstyle} />
const NotificationOption = <NotificationIcon size={IconSize} style={Iconstyle} />

const ButtonOption = ({ title, icon, onClick }) => (
	<Grid item xs={12} style={{ height: '3em' }} container justifyContent='center' alignItems='center'>
		<Button style={Buttonstyle} onClick={onClick}>
			{icon}
			{title}
		</Button>
	</Grid>
)

const canShowNotificationButton = (type, isActive, permissionEditCard) => {
	return type !== 'report' && permissionEditCard && type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (isActive || type === 'group')
}

const ComparativeBarItemMenu = ({
	onToggle,
	handleOpenCardInfo,
	handleRemove,
	isComp,
	isSolo,
	handleOpenUpdatePosts,
	showUpdatePosts = false,
	card
}) => {
	const [openMenu, setOpenMenu] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [isOpenNotificationsModal, setIsOpenNotificationsModal] = useState(false)

	useEffect(() => {
		onToggle(openMenu)
	}, [openMenu])

	const toggleMenu = (event) => {
		if (openMenu) {
			setAnchorEl(null)
		} else {
			setAnchorEl(event.currentTarget)
		}
		setOpenMenu(!openMenu)
	}

	const handleNotifyCard = () => {
		if (!isOpenNotificationsModal) {
			toggleMenu()
		}
		setIsOpenNotificationsModal(!isOpenNotificationsModal)
	}

	return (
		<>
			{!isComp && (
				<Tooltip title='Opções' placement='left' enterDelay={500} enterNextDelay={500}>
					<IconButton size='small' onClick={toggleMenu}>
						<DotsVerticalIcon size={20} style={{ color: '#1d8cf8' }} />
					</IconButton>
				</Tooltip>
			)}
			<Menu
				open={openMenu}
				onClose={toggleMenu}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				PaperProps={{
					style: {
						minWidth: '15em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				{showUpdatePosts && (
					<ButtonOption
						title='ATUALIZAR MÉTRICAS'
						icon={UpdateIconOption}
						onClick={() => {
							handleOpenUpdatePosts()
							toggleMenu()
						}}
					/>
				)}
				{!isComp && (
					<ButtonOption
						title='INFORMAÇÕES'
						icon={InfoIconOption}
						onClick={() => {
							handleOpenCardInfo()
							toggleMenu()
						}}
					/>
				)}
				{!card?.isDemo && canShowNotificationButton(card?.type, card?.active, true) && !isComp && (
					<ButtonOption
						title='NOTIFICAÇÕES'
						icon={NotificationOption}
						onClick={() => {
							handleNotifyCard()
						}}
					/>
				)}
				{/*ADICIONAR NA HORA QUE OS GRUPOS ESTEJAM SENDO USADOS PARA FILTRAR*/}
				{/* <ButtonOption title={'FILTRAR'} icon={FilterIconOption} /> */}
				{!isSolo && <ButtonOption title='REMOVER' icon={CloseIconOption} onClick={() => handleRemove()} />}
			</Menu>

			{isOpenNotificationsModal && (
				<NotificationConfigDialog
					isOpen={isOpenNotificationsModal}
					cardId={card?.id}
					cardType={card?.type}
					onClose={handleNotifyCard}
					name={card?.name}
				/>
			)}
		</>
	)
}

export default memo(ComparativeBarItemMenu)
