import React, { useEffect, memo } from 'react'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import TokenGauge from 'components/TokenGauge/TokenGauge'
import { saveUserCreditsAction } from 'store/redux/actions/user.actions'
import { connect } from 'react-redux'

const CreditsGauge = ({ saveUserCreditsDispatch }) => {
	let { data } = useUpdateData({
		url: '/credits/get-info',
		updateItemName: 'voxradarAiEngagement',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: true,
		queryName: 'voxradarAiCredits'
	})

	useEffect(() => {
		saveUserCreditsDispatch(data)
	}, [data])

	return (
		<>
			<TokenGauge isHeader={true} value={data?.remainingMonth || 0} maxValue={data?.month || 100} />
		</>
	)
}
const mapDispatchToProps = (dispatch) => ({
	saveUserCreditsDispatch: (creditInfo) => dispatch(saveUserCreditsAction(creditInfo))
})

export default connect(null, mapDispatchToProps)(memo(CreditsGauge))
