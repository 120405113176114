import React, { memo } from 'react'
import { Grid, Switch } from '@mui/material'
import { toggleReportContentOption } from '../../utils/ReportContentFunctions'

const SingleOption = ({ title = '', checked, option, value, thisOptions, setThisOptions }) => {
	return (
		<Grid
			container
			direction='row'
			justifyContent='space-between'
			style={{ paddingLeft: '10px', marginBottom: '3px', marginTop: '3px', paddingRight: '10px' }}
		>
			<Grid
				item
				xs={10}
				style={{
					fontFamily: 'Poppins',
					fontWeight: 'bold',
					fontSize: '11px',
					color: checked ? '#1d8cf8' : '#9a9a9a',
					borderRadius: '9px 0px 0px 9px',
					backgroundColor: 'rgba(255,255,255,1)',
					alignItems: 'center',
					display: 'flex',
					paddingLeft: '2em',
					textTransform: 'uppercase'
				}}
			>
				{title}
			</Grid>
			<Grid
				item
				xs={2}
				style={{
					display: 'flex',
					justifyContent: 'center',
					borderRadius: '0px 9px 9px 0px',
					backgroundColor: 'rgba(255,255,255,1)'
				}}
			>
				<Switch color='primary' checked={checked} onChange={() => toggleReportContentOption(option, value, thisOptions, setThisOptions)} />
			</Grid>
		</Grid>
	)
}
export default memo(SingleOption)
