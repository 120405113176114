import { USER_ACTION_TYPES } from '../actions/user.actions'
import { CARD_ACTION_TYPES } from '../actions/card.actions'
import { GLOBAL_ACTION_TYPES } from '../actions/global.actions'
import { MONITORING_ACTION_TYPES } from '../actions/monitoring.actions'
import { verifyCardComparisonStatuses } from 'utils/generalFunctions'
import { MAIN_REDUCER_INITIAL_CONFIG } from './utils/mainUtils'

//Se for o primeiro cartão, vai adicionar o tipo, se não vai entrar no if e depois coloca o status da comparação
const buildAddCardComparisonObject = (state, payload) => {
	let newComparisonState = { ...state }

	if (state.card.comparison.cards.length > 0) {
		newComparisonState.card.comparison = {
			...state.card.comparison,
			cards: payload?.switchCardId
				? [...state.card.comparison.cards.map((card) => (card.id === payload.switchCardId ? payload.card : card))]
				: [...state.card.comparison.cards, payload.card]
		}
	} else {
		newComparisonState.card.comparison = {
			...state.card.comparison,
			type: payload.card.type,
			cards: [payload.card]
		}
	}

	newComparisonState.card.comparison.status = verifyCardComparisonStatuses(newComparisonState.card.comparison.cards)

	return newComparisonState
}

const buildAddCardComparisonByIdComparisonObject = (state, cardId, isComparative) => {
	const cardFound = state.card.allCardsCanOpen.find((card) => Number(cardId) === card.id)
	if (cardFound) {
		let newComparisonState = { ...state }

		if (isComparative) {
			newComparisonState.card.comparison = {
				...state.card.comparison,
				type: cardFound.type,
				cards: cardFound.cards,
				status: cardFound.cardStatus
			}
		} else {
			newComparisonState.card.comparison = {
				...state.card.comparison,
				type: cardFound.type,
				cards: [cardFound],
				status: verifyCardComparisonStatuses([cardFound], newComparisonState.card.comparison.type)
			}
		}

		return newComparisonState
	} else {
		return state
	}
}

const buildRemoveCardComparisonObject = (state, payload) => {
	let filteredCards = state.card.comparison.cards.filter((item) => item.id !== payload.cardId)

	let newComparisonState = { ...state }

	newComparisonState.card.comparison = {
		...state.card.comparison,
		cards: filteredCards
	}

	newComparisonState.card.comparison.status = verifyCardComparisonStatuses(newComparisonState.card.comparison.cards)

	return newComparisonState
}

export default (state = MAIN_REDUCER_INITIAL_CONFIG, action) => {
	switch (action.type) {
		//**USER
		case USER_ACTION_TYPES.LOGIN:
			return {
				...state,
				user: action.payload
			}
		case USER_ACTION_TYPES.LOGOUT:
			return MAIN_REDUCER_INITIAL_CONFIG
		case USER_ACTION_TYPES.RESTORE_LOGGED_USER:
			return {
				...state,
				user: action.payload.loggedUser
			}
		case USER_ACTION_TYPES.UPDATE_USER_DATA:
			return {
				...state,
				user: { ...state.user, ...action.payload.updatedUserData }
			}
		case USER_ACTION_TYPES.SAVE_USER_CREDITS:
			return {
				...state,
				user: { ...state.user, creditInfo: action.payload.creditInfo }
			}
		//**CARDS
		case CARD_ACTION_TYPES.ADD_CARD_COMPARISON:
			return buildAddCardComparisonObject(state, action.payload)
		case CARD_ACTION_TYPES.REMOVE_CARD_COMPARISON:
			return buildRemoveCardComparisonObject(state, action.payload)
		case CARD_ACTION_TYPES.RESET_CARDS_COMPARISON:
			return {
				...state,
				card: { ...state.card, comparison: MAIN_REDUCER_INITIAL_CONFIG.card.comparison }
			}
		case CARD_ACTION_TYPES.SAVE_ALL_CARDS_CAN_OPEN:
			return {
				...state,
				card: {
					...state.card,
					allCardsCanOpen: action.payload.cardsCanOpen,
					searchPropsForComparativeBar: action.payload.searchPropsForComparativeBar
				}
			}
		case CARD_ACTION_TYPES.ADD_CARD_COMPARISON_BY_ID:
			return buildAddCardComparisonByIdComparisonObject(state, action.payload.cardId, action.payload.isComparative)
		case CARD_ACTION_TYPES.SAVE_CARD_TYPE_ACTION:
			return {
				...state,
				card: {
					...state.card,
					cardType: action.payload.cardType
				}
			}
		case CARD_ACTION_TYPES.UPDATE_CARD_COMPARISON_STATUS_ACTION:
			return {
				...state,
				card: {
					...state.card,
					comparison: {
						...state.card.comparison,
						status: action.payload.newComparisonStatus
					}
				}
			}
		//**GLOBAL
		//Filters
		case GLOBAL_ACTION_TYPES.SAVE_GLOBAL_FILTERS:
			return {
				...state,
				global: {
					...state.global,
					filters: action.payload.newFilters
				}
			}
		case GLOBAL_ACTION_TYPES.RESET_GLOBAL_FILTERS:
			return {
				...state,
				global: {
					...state.global,
					filters: MAIN_REDUCER_INITIAL_CONFIG.global.filters
				}
			}
		//FIXME YOUTUBE verificar se esse CHANGE_SOURCES_TO_DEFAULT tá funcionando normalmente
		case GLOBAL_ACTION_TYPES.CHANGE_SOURCES_TO_DEFAULT:
			return {
				...state,
				global: {
					...state.global,
					filters: {
						...state.global.filters,
						sources: MAIN_REDUCER_INITIAL_CONFIG.global.filters.sources
					}
				}
			}
		//Redirect
		case GLOBAL_ACTION_TYPES.CHANGE_REDIRECT_URL:
			return {
				...state,
				global: {
					...state.global,
					redirectUrl: action.payload.redirectUrl
				}
			}
		//MONITORING
		case MONITORING_ACTION_TYPES.SAVE_MONITORING_FILTERS:
			return {
				...state,
				monitoring: {
					...state.monitoring,
					filters: {
						...state.monitoring.filters,
						...action.payload
					}
				}
			}
		//Folder
		case GLOBAL_ACTION_TYPES.SAVE_CURRENT_FOLDERS:
			return {
				...state,
				global: {
					...state.global,
					folder: { ...state.global.folder, currentFolders: action.payload.folders }
				}
			}
		// case GLOBAL_ACTION_TYPES.SAVE_CURRENT_SELECTED_FOLDER_ID_ACTION: //não é usado atualmente
		// 	return {
		// 		...state,
		// 		global: {
		// 			...state.global,
		// 			folder: { ...state.global.folder, selectedFolderId: action.payload.folderId }
		// 		}
		// 	}
		default:
			return state
	}
}
