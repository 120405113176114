import React, { memo } from 'react'
import { Grid } from '@mui/material'
import './InstagramContentBodyAccount.css'
import Summary from 'components/Summary/Summary'

const InstagramContentBodyAccount = ({ cards, filters, summaryInstagram }) => {
	return (
		<div className='instagram-body-container'>
			{summaryInstagram && (
				<Grid container>
					<Grid container item className='break-print'>
						<Grid item xs={12}>
							<Summary cards={cards} filtersReport={{ ...filters, source: 'instagram' }} isReport />
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

export default memo(InstagramContentBodyAccount)
