import React, { useState } from 'react'
import { styled, IconButton } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { SaveIcon, RefreshIcon, PlusIcon, MoveResizeIcon, ShareIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		borderRadius: '20px',
		backdropFilter: 'blur(10px)',
		backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
	}
}))

const CustomTitle = ({ title }) => {
	return <div style={{ color: '#1d8cf8', fontFamily: 'poppins', fontSize: '1.4em' }}>{title}</div>
}

const AddRemoveWidgetButton = ({ toggleAddWidgetMode, deactivateEditMode, addWidgetMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip
			title={<CustomTitle title='Adicionar / Remover Widget' />}
			placement='left'
			enterDelay={0}
			enterNextDelay={0}
			disabled={addWidgetMode}
		>
			<span>
				<IconButton
					id='btn-add-widget-dashboard'
					color='primary'
					onPointerEnter={() => setIsHovering(true)}
					onPointerLeave={() => setIsHovering(false)}
					onClick={() => {
						toggleAddWidgetMode()
						deactivateEditMode()
					}}
					style={{
						backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
						border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(240, 240, 240, 0.5)',
						boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
					}}
				>
					<PlusIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
				</IconButton>
			</span>
		</HtmlTooltip>
	)
}

const EditButton = ({ toggleEditMode, editMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	const defineIconColor = () => {
		if (editMode) {
			return '#fff'
		}
		if (isHovering) {
			return '#1d8cf8'
		}
		return '#616161'
	}

	const defineBorderColor = () => {
		if (editMode) {
			return '1px solid rgba(29, 138, 248, 0.1)'
		}
		if (isHovering) {
			return '1px solid rgba(29, 138, 248, 0.1)'
		}
		return '1px solid rgba(240, 240, 240, 0.5)'
	}

	const defineBackgroundColor = () => {
		if (editMode) {
			return '#1d8cf8'
		}
		if (isHovering) {
			return 'rgba(29, 138, 248, 0.1)'
		}
		return 'transparent'
	}

	return (
		<HtmlTooltip title={<CustomTitle title='Mover / Redimensionar' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-edit-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={toggleEditMode}
				style={{
					marginTop: '0.75em',
					border: defineBorderColor(),
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )',
					backgroundColor: defineBackgroundColor()
				}}
			>
				<MoveResizeIcon size={18} style={{ color: defineIconColor() }} />
			</IconButton>
		</HtmlTooltip>
	)
}

const DefaultDashboardButton = ({ onDefaultLayout, deactivateEditMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip title={<CustomTitle title='Voltar ao dashboard padrão' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-default-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={() => {
					onDefaultLayout()
					deactivateEditMode()
				}}
				style={{
					marginTop: '0.75em',
					backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
					border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(240, 240, 240, 0.5)',
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
				}}
			>
				<RefreshIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
			</IconButton>
		</HtmlTooltip>
	)
}

const SaveDashboardButton = ({ onSaveLayout, cardList, currentLayout, deactivateEditMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip title={<CustomTitle title='Salvar dashboard' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-save-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={() => {
					onSaveLayout(cardList[0]?.id, currentLayout.id)
					deactivateEditMode()
				}}
				style={{
					backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
					border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(240, 240, 240, 0.5)',
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
				}}
			>
				<SaveIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
			</IconButton>
		</HtmlTooltip>
	)
}

const ShareDashboardButton = ({ toggleShareMenu, deactivateEditMode }) => {
	const [isHovering, setIsHovering] = useState(false)

	return (
		<HtmlTooltip title={<CustomTitle title='Compartilhar Dashboard' />} placement='left' enterDelay={0} enterNextDelay={0}>
			<IconButton
				id='btn-default-dashboard'
				color='primary'
				onPointerEnter={() => setIsHovering(true)}
				onPointerLeave={() => setIsHovering(false)}
				onClick={() => {
					toggleShareMenu()
					deactivateEditMode()
				}}
				style={{
					marginTop: '2em',
					marginBottom: '2em',
					backgroundColor: isHovering ? 'rgba(29, 138, 248, 0.1)' : 'transparent',
					border: isHovering ? '1px solid rgba(29, 138, 248, 0.1)' : '1px solid rgba(29, 138, 248, 0.5)',
					boxShadow: '0 2px 10px 0 rgba( 200, 200, 200, 0.5 )'
				}}
			>
				<ShareIcon size={18} style={{ color: isHovering ? '#1d8cf8' : '#616161' }} />
			</IconButton>
		</HtmlTooltip>
	)
}

export { HtmlTooltip, CustomTitle, AddRemoveWidgetButton, EditButton, DefaultDashboardButton, SaveDashboardButton, ShareDashboardButton }
