import React, { useEffect, useState } from 'react'
import { styled, Grid, Drawer, Button } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { SettingsIcon, UsersIcon, PowerIcon, UserIcon } from 'utils/SystemIcons'
import { logoutAction, restoreLoggedUserAction } from 'store/redux/actions/user.actions'
import { getLoggedUser, clearAuthLocalStorage } from 'store/local-storage/store'
import Notifications from 'components/Notifications/Notifications'
import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation/BreadcrumbsNavigation'
import CreditsGauge from 'components/CreditsGauge/CreditsGauge'
import TermsCountGauge from 'components/TermsCountGauge/TermsCountGauge'
import ProfilesCountGauge from 'components/ProfilesCountGauge/ProfilesCountGauge'
import LastLoginUserUpdater from './components/LastLoginUserUpdater/LastLoginUserUpdater'
import constantsVox from 'constants-vox'
import VoxLogo from 'assets/img/react-logo-2.png'
import './Header.css'

const isFirefox = typeof InstallTrigger !== 'undefined'

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		borderRadius: '20px',
		backdropFilter: 'blur(10px)',
		backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#1d8cf8'
	}
}))

const Header = ({ user, restoreLoggedUserDispatch, logoutDispatch }) => {
	const currentBaseUrl = window.location.origin.replace(/(http:\/\/|https:\/\/|www\.)/g, '').replace(/\/$/, '')
	const [tab, setTab] = useState(null)
	const [whiteLabel, setWhiteLabel] = useState(null)

	let whiteLabelResponse = useUpdateData({
		url: '/user-white-label/get',
		body: { currentBaseUrl },
		updateItemName: 'Cartoes',
		refetchInterval: 600000,
		freshDataTime: 600000,
		initialGraphData: { images: { logo: VoxLogo } },
		isEnabled: !constantsVox?.WHITE_LABEL?.BASE_DOMAINS?.includes(currentBaseUrl),
		method: 'post',
		queryName: 'getUserWhiteLabel'
	})

	useEffect(() => {
		if (!user) {
			restoreLoggedUserDispatch(getLoggedUser())
		}
	}, [])

	useEffect(() => {
		if (constantsVox?.WHITE_LABEL?.BASE_DOMAINS?.includes(currentBaseUrl)) {
			setWhiteLabel({ logo: VoxLogo, companyName: 'vox', companySecondName: 'radar' })
		} else {
			if (whiteLabelResponse.isFetched) {
				if (whiteLabelResponse.data?.images?.logo) {
					setWhiteLabel({ logo: whiteLabelResponse.data?.images?.logo, companyName: whiteLabelResponse.data?.company_name })
				} else {
					setWhiteLabel({ logo: VoxLogo, companyName: 'vox', companySecondName: 'radar' })
				}
			}
		}
	}, [whiteLabelResponse.isFetched])

	const history = useHistory()
	if (!user) {
		return null
	}

	const handleLogoutAction = () => {
		toggleUserMenu()
		logoutDispatch()
		clearAuthLocalStorage()
		history.push('/')
	}

	const handleProfileAction = () => {
		toggleUserMenu()
		history.push('/profile')
	}
	const handleSubUserAction = () => {
		toggleUserMenu()
		history.push('/sub-users')
	}

	const showHideSubUser = () => user && !user.subUser

	const handleHomeAction = () => {
		history.push('/')
	}

	const onChangeTab = (newTab) => {
		setTab(newTab)
	}

	const toggleUserMenu = () => {
		if (tab === 'userMenu') {
			onChangeTab(null)
		} else {
			onChangeTab('userMenu')
		}
	}

	const toggleNotificationsMenu = () => {
		if (tab === 'notifications') {
			onChangeTab(null)
		} else {
			onChangeTab('notifications')
		}
	}

	return (
		<Grid container className='header-container-app-bar'>
			<LastLoginUserUpdater />
			<Grid item xs={6} style={{ display: 'flex', marginTop: '0px' }}>
				<Button
					id='btn-voxradar'
					size='small'
					style={{ outline: 'none', marginLeft: '8px', borderRadius: '100px' }}
					onClick={handleHomeAction}
				>
					{whiteLabel?.logo && <img src={whiteLabel.logo} alt='react-logo' style={{ width: '30px' }} />}

					{whiteLabel?.companyName && (
						<div
							style={{
								fontFamily: 'Poppins',
								marginLeft: '6px',
								textTransform: 'uppercase',
								letterSpacing: '5px',
								fontSize: '15px',
								fontWeight: 'bold',
								display: 'flex',
								cursor: 'default',
								color: '#1d8cf8'
							}}
						>
							{whiteLabel.companyName}
						</div>
					)}
					{whiteLabel?.companySecondName && (
						<div
							style={{
								fontFamily: 'Poppins',
								marginLeft: '6px',
								textTransform: 'uppercase',
								letterSpacing: '5px',
								fontSize: '15px',
								fontWeight: '300',
								display: 'flex',
								cursor: 'default',
								color: '#013e9b'
							}}
						>
							{whiteLabel.companySecondName}
						</div>
					)}
				</Button>
				<div style={{ marginLeft: '80px', marginTop: '8px' }}>
					<BreadcrumbsNavigation />
				</div>
			</Grid>
			<Grid item xs={6} style={{ marginTop: '3px' }} container direction='row' justifyContent='flex-end' alignItems='center'>
				{/* <ExportHistory isOpen={tab === 'export'} toggle={toggleExportHistoryMenu} /> */}
				<CreditsGauge />
				<TermsCountGauge />
				<ProfilesCountGauge />
				<Notifications isOpen={tab === 'notifications'} toggle={toggleNotificationsMenu} />

				<HtmlTooltip
					arrow
					title={
						<>
							{tab !== 'userMenu' && (
								<div style={{ margin: '1em' }}>
									<div style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#878787', fontSize: '1.9em' }}>
										{user?.subUser?.name || user?.name}
									</div>
									<div
										style={{
											marginTop: '0.5em',
											fontFamily: 'Poppins',
											fontWeight: 'bold',
											color: '#1d8cf8',
											fontSize: '17px'
										}}
									>
										{user?.subUser?.email || user?.email}
									</div>
								</div>
							)}
						</>
					}
				>
					<Button
						onClick={toggleUserMenu}
						style={{
							border: `solid 2px ${tab === 'userMenu' ? '#1d8cf8' : '#a3a3a3'}`,
							borderRadius: '100%',
							cursor: 'pointer',
							height: '29px',
							width: '29px',
							minWidth: '29px',
							minHeight: '29px',
							maxHeight: '29px',
							maxWidth: '29px'
						}}
					>
						<div style={{ position: 'absolute', top: '1px' }}>
							<UserIcon size={25} color={tab === 'userMenu' ? '#1d8cf8' : '#a3a3a3'} />
						</div>
					</Button>
				</HtmlTooltip>
				<Drawer
					anchor='right'
					open={tab === 'userMenu'}
					onClose={toggleUserMenu}
					className='right-drawer-system'
					BackdropProps={{
						timeout: 500,
						style: {
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}`
						}
					}}
					PaperProps={{
						style: {
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
				>
					<Grid container style={{ width: '15em', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', paddingTop: '1em' }}>
						<Grid
							item
							xs={12}
							className='nav-link-custom-style'
							style={{ display: 'flex', alignItems: 'center', height: '3em', cursor: 'pointer' }}
							onClick={handleProfileAction}
						>
							<div className='nav-item-custom-style'>
								<SettingsIcon
									size={17}
									style={{ color: '#191f31', marginRight: '0.5em' }}
									className='fix-icon-margin-bottom-negative'
								/>
								PERFIL
							</div>
						</Grid>
						<div style={{ width: '80%', marginTop: '0.5em', marginBottom: '0.5em' }}>
							<div className='divider-thin-default' />
						</div>
						{user.isValidEmail && showHideSubUser() && (
							<>
								<Grid
									item
									xs={12}
									className='nav-link-custom-style'
									style={{ display: 'flex', alignItems: 'center', height: '3em', cursor: 'pointer' }}
									onClick={handleSubUserAction}
								>
									<div className='nav-item-custom-style'>
										<>
											<UsersIcon
												size={17}
												style={{ color: '#191f31', marginRight: '0.5em' }}
												className='fix-icon-margin-bottom-negative'
											/>
											SUB USUÁRIOS
										</>
									</div>
								</Grid>
								<div style={{ width: '80%', marginTop: '0.5em', marginBottom: '0.5em' }}>
									<div className='divider-thin-default' />
								</div>
							</>
						)}
						<Grid
							item
							xs={12}
							className='nav-link-custom-style'
							style={{ display: 'flex', alignItems: 'center', height: '3em', cursor: 'pointer' }}
							onClick={handleLogoutAction}
						>
							<div className='nav-item-custom-style'>
								<PowerIcon size={17} style={{ color: '#191f31', marginRight: '0.5em' }} className='fix-icon-margin-bottom-negative' />
								LOG OFF
							</div>
						</Grid>
					</Grid>
				</Drawer>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

const mapDispatchToProps = (dispatch) => ({
	restoreLoggedUserDispatch: (loggedUser) => dispatch(restoreLoggedUserAction(loggedUser)),
	logoutDispatch: () => dispatch(logoutAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
