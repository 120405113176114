import React from 'react'
import NumericMetric from '../../NumericMetric/NumericMetric'
import BarMetrics from '../../BarMetrics/BarMetrics'
import GenderAgeDistributionAccountManagement from '../../GenderAgeDistributionAccountManagement/GenderAgeDistributionAccountManagement'
import TopCommentsAccountManager from '../../TopCommentsAccountManager/TopCommentsAccountManager'
import CommentsSentiment from '../../CommentsSentiment/CommentsSentiment'
import PostsFeedAccountManager from '../../PostsFeedAccountManager/PostsFeedAccountManager'
import WordCloudAccountManager from '../../WordCloudAccountManager/WordCloudAccountManager'
export { getComponentsToRender, LAYOUTS_DEFAULT_INSTAGRAM, DEFAULT_WIDGETS_INSTAGRAM }

const getComponentsToRender = (instagramMetrics, editMode, addWidgetMode, removeWidget, cards, descriptions) => [
	{
		Component: (
			<NumericMetric
				data={instagramMetrics?.analytics?.followers_count}
				title={descriptions.find((item) => item.key === 'instagram_followers_count')?.title}
				graphData={instagramMetrics?.apiSeries?.graphs?.followersFormatted}
				source='instagram'
				isOverview={true}
				editMode={editMode}
				keyName={'instagram_followers_count'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_followers_count')?.description}
			/>
		),
		key: 'instagram_followers_count'
	},
	{
		Component: (
			<NumericMetric
				data={instagramMetrics?.analytics?.follows_count}
				title={descriptions.find((item) => item.key === 'instagram_follows_count')?.title}
				graphData={instagramMetrics?.apiSeries?.graphs?.followsFormatted}
				source='instagram'
				isOverview={true}
				editMode={editMode}
				keyName={'instagram_follows_count'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_follows_count')?.description}
			/>
		),
		key: 'instagram_follows_count'
	},
	{
		Component: (
			<NumericMetric
				data={instagramMetrics?.apiSeries?.graphsTotalMetrics?.newFollowersFormattedTotal}
				title={descriptions.find((item) => item.key === 'instagram_new_followers_count')?.title}
				graphData={instagramMetrics?.apiSeries?.graphs?.newFollowersFormatted}
				source='instagram'
				isOverview={true}
				editMode={editMode}
				keyName={'instagram_new_followers_count'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_new_followers_count')?.description}
			/>
		),
		key: 'instagram_new_followers_count'
	},
	{
		Component: (
			<NumericMetric
				data={instagramMetrics?.apiSeries?.graphsTotalMetrics?.profileViewsFormattedTotal}
				title={descriptions.find((item) => item.key === 'instagram_profile_views')?.title}
				graphData={instagramMetrics?.apiSeries?.graphs?.profileViewsFormatted}
				source='instagram'
				isOverview={true}
				editMode={editMode}
				keyName={'instagram_profile_views'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_profile_views')?.description}
			/>
		),
		key: 'instagram_profile_views'
	},
	{
		Component: (
			<NumericMetric
				data={instagramMetrics?.analytics?.media_count}
				title={descriptions.find((item) => item.key === 'instagram_media_count')?.title}
				editMode={editMode}
				keyName={'instagram_media_count'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_media_count')?.description}
			/>
		),
		key: 'instagram_media_count'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={instagramMetrics?.apiSeries?.graphsTotalMetrics?.viewsImpressionsReachFormattedTotal?.keys}
				values={instagramMetrics?.apiSeries?.graphsTotalMetrics?.viewsImpressionsReachFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'instagram_profile_views')?.title}
				label='Quantidade'
				graphData={instagramMetrics?.apiSeries?.graphs?.viewsImpressionsReachFormatted}
				source='instagram'
				editMode={editMode}
				keyName={'instagram_exposure_profile'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_profile_views')?.description}
			/>
		),
		key: 'instagram_exposure_profile'
	},
	{
		Component: (
			<BarMetrics
				isHorizontal={false}
				categories={instagramMetrics?.apiSeries?.graphsTotalMetrics?.clicksAndEmailContactsFormattedTotal?.keys}
				values={instagramMetrics?.apiSeries?.graphsTotalMetrics?.clicksAndEmailContactsFormattedTotal?.values}
				title={descriptions.find((item) => item.key === 'instagram_page_clicks_and_actions')?.title}
				label='Quantidade'
				graphData={instagramMetrics?.apiSeries?.graphs?.clicksAndEmailContactsFormatted}
				source='instagram'
				editMode={editMode}
				keyName={'instagram_page_clicks_and_actions'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_page_clicks_and_actions')?.description}
			/>
		),
		key: 'instagram_page_clicks_and_actions'
	},
	{
		Component: (
			<GenderAgeDistributionAccountManagement
				title={descriptions.find((item) => item.key === 'instagram_followers_by_gender_and_age')?.title}
				data={instagramMetrics?.apiSeries?.graphs?.genderAgeFormatted}
				source='instagram'
				graphData={instagramMetrics?.apiSeries?.graphs?.genderAgeTimelineFormatted}
				editMode={editMode}
				keyName={'instagram_followers_by_gender_and_age'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_followers_by_gender_and_age')?.description}
			/>
		),
		key: 'instagram_followers_by_gender_and_age'
	},
	{
		Component: (
			<BarMetrics
				categories={instagramMetrics?.apiSeries?.topMetrics?.followersByCity?.keys}
				values={instagramMetrics?.apiSeries?.topMetrics?.followersByCity?.values}
				title={descriptions.find((item) => item.key === 'instagram_followers_by_city')?.title}
				label='Quantidade'
				graphData={instagramMetrics?.apiSeries?.graphs?.followersByCityFormatted}
				source='instagram'
				editMode={editMode}
				keyName={'instagram_followers_by_city'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_followers_by_city')?.description}
			/>
		),
		key: 'instagram_followers_by_city'
	},
	{
		Component: (
			<BarMetrics
				categories={instagramMetrics?.apiSeries?.topMetrics?.followersByCountry?.keys}
				values={instagramMetrics?.apiSeries?.topMetrics?.followersByCountry?.values}
				title={descriptions.find((item) => item.key === 'instagram_followers_by_country')?.title}
				label='Quantidade'
				graphData={instagramMetrics?.apiSeries?.graphs?.followersByCountryFormatted}
				source='instagram'
				editMode={editMode}
				keyName={'instagram_followers_by_country'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_followers_by_country')?.description}
			/>
		),
		key: 'instagram_followers_by_country'
	},
	{
		Component: (
			<TopCommentsAccountManager
				title={descriptions.find((item) => item.key === 'instagram_top_posts')?.title}
				cards={cards}
				source='instagram'
				editMode={editMode}
				keyName={'instagram_top_posts'}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_top_posts')?.description}
			/>
		),
		key: 'instagram_top_posts'
	},
	{
		Component: (
			<CommentsSentiment
				sentiment={instagramMetrics?.instagramCommentsAnalytics?.sentiment}
				title={descriptions.find((item) => item.key === 'instagram_sentiments_comments_bar')?.title}
				keyName='instagram_sentiments_comments_bar'
				source='instagram'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_sentiments_comments_bar')?.description}
			/>
		),
		key: 'instagram_sentiments_comments_bar'
	},
	{
		Component: (
			<PostsFeedAccountManager
				title={descriptions.find((item) => item.key === 'instagram_posts_feed')?.title}
				keyName='instagram_posts_feed'
				cards={cards}
				source='instagram'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_posts_feed')?.description}
			/>
		),
		key: 'instagram_posts_feed'
	},
	{
		Component: (
			<WordCloudAccountManager
				title={descriptions.find((item) => item.key === 'instagram_word_clouds')?.title}
				keyName='instagram_word_clouds'
				cards={cards}
				source='instagram'
				editMode={editMode}
				addWidgetMode={addWidgetMode}
				removeWidget={removeWidget}
				description={descriptions.find((item) => item.key === 'instagram_word_clouds')?.description}
			/>
		),
		key: 'instagram_word_clouds'
	}
]

const LAYOUTS_DEFAULT_INSTAGRAM = {
	lg: [
		{ i: 'instagram_followers_count', x: 0, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_follows_count', x: 6, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_new_followers_count', x: 0, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_profile_views', x: 4, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_media_count', x: 8, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_top_posts', x: 0, y: 8, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_sentiments_comments_bar', x: 6, y: 8, w: 6, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_exposure_profile', x: 0, y: 23, w: 6, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_page_clicks_and_actions', x: 6, y: 23, w: 6, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_country', x: 0, y: 35, w: 6, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_city', x: 6, y: 35, w: 6, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_gender_and_age', x: 0, y: 50, w: 12, h: 16, minH: 16, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_posts_feed', x: 0, y: 66, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_word_clouds', x: 0, y: 81, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	md: [
		{ i: 'instagram_followers_count', x: 0, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_follows_count', x: 6, y: 0, w: 6, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_new_followers_count', x: 0, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_profile_views', x: 4, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_media_count', x: 8, y: 5, w: 4, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_top_posts', x: 0, y: 8, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_sentiments_comments_bar', x: 6, y: 8, w: 6, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_exposure_profile', x: 0, y: 23, w: 6, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_page_clicks_and_actions', x: 6, y: 23, w: 6, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_country', x: 0, y: 35, w: 6, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_city', x: 6, y: 35, w: 6, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_gender_and_age', x: 0, y: 50, w: 12, h: 16, minH: 16, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_posts_feed', x: 0, y: 66, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_word_clouds', x: 0, y: 81, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	],
	sm: [
		{ i: 'instagram_followers_count', x: 0, y: 0, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_follows_count', x: 0, y: 0, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_new_followers_count', x: 0, y: 5, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_profile_views', x: 0, y: 5, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_media_count', x: 0, y: 5, w: 12, h: 4, minH: 4, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_top_posts', x: 0, y: 8, w: 12, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_sentiments_comments_bar', x: 0, y: 8, w: 12, h: 15, minH: 8, maxH: 17, minW: 3, maxW: 12 },
		{ i: 'instagram_exposure_profile', x: 0, y: 23, w: 12, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_page_clicks_and_actions', x: 0, y: 23, w: 12, h: 12, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_country', x: 0, y: 35, w: 12, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_city', x: 0, y: 35, w: 12, h: 15, minH: 11, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_followers_by_gender_and_age', x: 0, y: 50, w: 12, h: 16, minH: 16, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_posts_feed', x: 0, y: 62, w: 12, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 },
		{ i: 'instagram_word_clouds', x: 0, y: 77, w: 6, h: 15, minH: 15, maxH: 17, minW: 6, maxW: 12 }
	]
}

const DEFAULT_WIDGETS_INSTAGRAM = [
	'instagram_followers_count',
	'instagram_follows_count',
	'instagram_new_followers_count',
	'instagram_profile_views',
	'instagram_media_count',
	'instagram_top_posts',
	'instagram_sentiments_comments_bar',
	'instagram_exposure_profile',
	'instagram_page_clicks_and_actions',
	'instagram_followers_by_city',
	'instagram_followers_by_country',
	'instagram_followers_by_gender_and_age'
]
