const fixSourceCardConfigs = (card) => {
	if (card?.cardStatus?.twitter === 'S') {
		return 'twitter'
	} else if (card?.cardStatus.facebook === 'S') {
		return 'facebook'
	} else if (card?.cardStatus.instagram === 'S') {
		return 'instagram'
	} else if (card?.cardStatus.news === 'S') {
		return 'news'
	}
}

const getFirstCard = (cards, mainComparison) => {
	let card = {}
	if (mainComparison === '') {
		card = cards[0]
	} else {
		card = cards.find((x) => x.name === mainComparison)
	}
	return card
}

const canLoadSource = (source, card) => {
	return (
		(source === 'twitter' && card?.cardStatus?.twitter === 'S') ||
		(source === 'facebook' && card?.cardStatus.facebook === 'S') ||
		(source === 'instagram' && card?.cardStatus.instagram === 'S') ||
		(source === 'tiktok' && card?.cardStatus.tiktok === 'S') ||
		(source === 'youtube' && card?.cardStatus.youtube === 'S') ||
		(source === 'news' && card?.cardStatus.news === 'S')
	)
}

const itemActiveColor = (isActive) => (isActive ? '#1d8cf8' : '#c0c0c0')

export { fixSourceCardConfigs, getFirstCard, canLoadSource, itemActiveColor }
