Moment.locale('pt-br')
import React, { useState, useEffect } from 'react'
import {
	Card,
	Grid,
	Dialog,
	IconButton,
	CardContent,
	DialogContent,
	DialogTitle,
	Collapse,
	CircularProgress,
	Chip,
	Tooltip,
	Button,
	Badge,
	styled,
	Avatar
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	InfoIcon,
	CloseIcon,
	LoadingIcon,
	FacebookIcon,
	TwitterIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	NewsIcon,
	ExternalLinkIcon,
	CardIcon,
	ClockIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	PauseIcon,
	PlayIcon,
	MetaAdsIcon
} from 'utils/SystemIcons'
import Moment from 'moment-timezone'
import api from 'service/service'
import constantsVox from 'constants-vox'
import { Tweet } from 'react-twitter-widgets'
import { FacebookEmbed } from 'react-social-media-embed'
import ModalCardLog from 'components/ModalCardLog/ModalCardLog'
import ModalCardConfig from 'components/ModalCardConfig/ModalCardConfig'
import CardTagLabel from 'components/CardTags/components/CardTagLabel/CardTagLabel'
import { isUndefined } from 'lodash'
import ActivatePauseAccountsDialog from './components/ActivatePauseAccountsDialog/ActivatePauseAccountsDialog'

const grey = '#b8b8b8'
const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'
const red = '#ff2b6b'
const purple = '#d41993'

const iconSize = 17

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	},
	groupActivatePauseAccountsButton: {
		textTransform: 'none',
		paddingLeft: '1em',
		paddingRight: '1em',
		borderRadius: '20px',
		marginLeft: '2em',
		marginTop: '-2px',
		height: '1.6em',
		color: purple,
		border: `1px solid ${purple}`,
		'&:disabled': {
			color: grey,
			border: `1px solid ${grey}`
		}
	}
})

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		backgroundColor: '#1d8cf8'
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const formatQuery = (query, color, type, index = null) => {
	return (
		<Chip
			key={query}
			avatar={
				index !== 0 ? (
					<Avatar style={{ padding: '0px 1px 0px 1px', backgroundColor: 'white', fontWeight: 'bold', color: 'black', fontSize: '7.5px' }}>
						{type}
					</Avatar>
				) : (
					<></>
				)
			}
			size='small'
			label={query}
			style={{ color: 'white', backgroundColor: color, margin: '2px' }}
		/>
	)
}

const translateQuery = (query, flag = '') => {
	if (flag !== 'card') {
		let newStr = query.toString()
		newStr = newStr.replaceAll('termField', 'termo')
		newStr = newStr.replaceAll('AND', 'E')
		newStr = newStr.replaceAll('OR', 'OU')
		newStr = newStr.replaceAll('hashtagField', 'hashtag')
		newStr = newStr.replaceAll('twitterAccountField', 'Conta do Twitter')
		newStr = newStr.replaceAll('facebookPageField', 'Conta do Facebook')
		newStr = newStr.replaceAll('instagramAccountField', 'Conta do Instagram')
		newStr = newStr.replaceAll('tiktokAccountField', 'Conta do Tiktok')
		newStr = newStr.replaceAll('youtubeAccountField', 'Conta do Youtube')
		return newStr
	} else {
		return query.map((it) => (
			<Grid key={it?.id}>
				<Card
					elevation={0}
					style={{
						width: 'fit-content',
						borderRadius: '20px',
						padding: '2px',
						backgroundColor: 'rgba(255,255,255,0.6)',
						border: '3px solid rgba(29, 138, 248, 0.2)'
					}}
				>
					{it?.query?.termsAnd?.map((it, index) => formatQuery(it, blue, 'AND', index))}
					{it?.query?.termsOr?.map((it) => formatQuery(it, grey, 'OR'))}
					{it?.query?.termsNot?.map((it) => formatQuery(it, red, 'NOT'))}
				</Card>
			</Grid>
		))
	}
}

const categories = {
	GENERAL: 'GERAL',
	POLITICS: 'POLÍTICA',
	RELIGION: 'RELIGIÃO',
	'MEDIA CHANNEL': 'CANAL DE MÍDIA'
}

const ModalCardInfo = ({ isOpen, handleOnClose, card, color }) => {
	const classes = useStyles()
	const [cardInfo, setCardInfo] = useState(null)
	const [sourceInfo, setSourceInfo] = useState(null)
	const [loadingSourceInfo, setLoadingSourceInfo] = useState(false)
	const [openCardLog, setOpenCardLog] = useState(false)
	const [openCardConfig, setOpenCardConfig] = useState(false)
	const [source, setSource] = useState(null)
	const [openStopWords, setOpenStopWords] = useState(false)
	const [openDictionary, setOpenDictionary] = useState(false)
	const [openStopAccounts, setOpenStopAccounts] = useState(false)
	const [openClassification, setOpenClassification] = useState(false)
	const [openTags, setOpenTags] = useState(false)
	const [activatePauseAccountsOperation, setactivatePauseAccountsOperation] = useState(null)

	useEffect(() => {
		setCardInfo(card)
	}, [card])

	useEffect(() => {
		loadCardInfo()
	}, [cardInfo])

	const loadCardInfo = () => {
		setLoadingSourceInfo(true)
		if (cardInfo) {
			api.get(`/monitoring/card/get-info-by-type/${cardInfo.id}/${cardInfo?.type}`)
				.then((response) => {
					setSourceInfo(response.data)
				})
				.finally(() => setLoadingSourceInfo(false))
		}
	}

	const defineUrl = (source) => {
		if (source === 'twitter') {
			let twitterAccount = removeAt(sourceInfo?.twitterAccount)
			return `https://twitter.com/${twitterAccount}`
		} else if (source === 'facebook') {
			let facebookAccount = removeAt(sourceInfo?.facebookPage)
			return `https://www.facebook.com/${facebookAccount}`
		} else if (source === 'instagram') {
			let instagramAccount = removeAt(sourceInfo?.instagramAccount)
			return `https://www.instagram.com/${instagramAccount}/`
		} else if (source === 'tiktok') {
			let tiktokAccount = removeAt(sourceInfo?.tiktokAccount)
			return `https://www.tiktok.com/@${tiktokAccount}/`
		} else if (source === 'youtube') {
			let youtubeAccount = removeAt(sourceInfo?.youtubeAccount)
			return `https://www.youtube.com/${youtubeAccount}/`
		}
	}

	const removeAt = (str) => {
		if (str !== null && str !== undefined) {
			if (str.charAt(0) === '@') {
				return str.slice(1)
			}
			return str
		}
		return ''
	}

	const defineStatusText = (status, account) => {
		if (cardInfo?.type === 'term' || (cardInfo?.type === 'profile' && account) || cardInfo?.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
			switch (status) {
				case 'S':
					return 'Ativa'
				case 'P':
					return 'Processando'
				case 'W':
					return cardInfo?.type !== 'profile' ? 'Bloqueio encontrado' : 'Erro na conta (Verifique a conta)'
				case 'E':
					return 'Não foram encontrados dados'
				case 'C':
					return 'Processando'
				case 'A':
					return 'Processando'
			}
		} else if (status === 'P' || status === 'C' || status === 'A') {
			return 'Processando'
		} else {
			return 'Desativada'
		}
	}

	const defineStatusColor = (status, account) => {
		if (cardInfo?.type === 'term' || (cardInfo?.type === 'profile' && account) || cardInfo?.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
			switch (status) {
				case 'S':
					return color
				case 'P':
					return color
				case 'W':
					return '#ffa200'
				case 'E':
					return 'rgb(120, 120, 120)'
				case 'C':
					return color
				case 'A':
					return color
				default:
					return 'rgb(120, 120, 120)'
			}
		} else if (status === 'P' || status === 'C' || status === 'A') {
			return color
		} else {
			return 'rgb(120, 120, 120)'
		}
	}

	const defineLoadingStatus = (status) => {
		if (status === 'P' || status === 'C' || status === 'A') {
			return true
		} else {
			return false
		}
	}

	const checkIfIsUndefined = (stopAccount) => {
		return !isUndefined(stopAccount) ? stopAccount.length : 0
	}

	const handleOnClickActivatePause = (operation) => {
		setactivatePauseAccountsOperation(operation)
	}

	const handleCloseActivatePauseAccountsDialog = () => {
		setactivatePauseAccountsOperation(null)
	}

	const handleOnSuccessActivatePauseAccountsDialog = () => {
		loadCardInfo()
	}

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<InfoIcon className='fix-icon-margin-bottom-negative' size={25} color={color} /> Informações do cartão
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
				<CardContent style={{ padding: 0, margin: 0 }}>
					<Grid
						container
						style={{
							padding: '1.5em',
							borderRadius: '20px',
							color: 'rgb(120, 120, 120)',
							fontFamily: 'Poppins',
							fontWeight: '300',
							fontSize: '20px',
							minWidth: '35em'
						}}
					>
						<Grid item xs={12} style={{ display: 'flex', paddingTop: 20, paddingLeft: '0.6em', marginBottom: '1em' }}>
							<CardIcon
								className='fix-icon-margin-bottom-negative'
								size={25}
								color={color}
								style={{ marginTop: '0.15em', marginRight: '0.4em', color }}
							/>
							<div style={{ color: color, fontSize: 20 }}>{cardInfo?.name}</div>
							{openCardLog && (
								<ModalCardLog isOpen={openCardLog} handleOnClose={() => setOpenCardLog(false)} card={cardInfo} color={color} />
							)}
							{openCardConfig && (
								<ModalCardConfig
									isOpen={openCardConfig}
									handleOnClose={() => setOpenCardConfig(false)}
									card={cardInfo}
									color={color}
									source={source}
								/>
							)}
							{loadingSourceInfo && (
								<div style={{ marginLeft: '10px', marginTop: '2px' }}>
									<LoadingIcon size={20} thickness={1} style={{ color }} />
								</div>
							)}
							{(cardInfo?.type === 'term' || cardInfo?.type === 'profile' || cardInfo?.type === 'group') && !loadingSourceInfo && (
								<div style={{ marginLeft: '2.5em' }}>
									<Button
										size='small'
										style={{
											textTransform: 'none',
											paddingLeft: '1em',
											paddingRight: '1em',
											color,
											borderRadius: '20px',
											marginLeft: '2em',
											marginTop: '-2px',
											border: `1px solid ${color}`,
											height: '1.6em'
										}}
										onClick={() => setOpenCardLog(true)}
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<ClockIcon size={13} color={color} style={{ marginRight: '0.4em', color }} />
											VER LOGS
										</div>
									</Button>
								</div>
							)}
						</Grid>

						<Collapse in={!loadingSourceInfo}>
							{!loadingSourceInfo && (
								<Grid container className='cursor-default' style={{ margin: '1em', fontSize: '0.7em' }}>
									{(sourceInfo?.query?.format?.sqlFormat || sourceInfo?.cardQuery) && (
										<Grid
											item
											xs={12}
											style={{
												textAlign: 'left',
												color: 'rgb(120, 120, 120)',
												fontFamily: 'Poppins',
												fontWeight: '300',
												marginBottom: '1.5em'
											}}
										>
											{cardInfo?.type === 'term' && cardInfo?.searchType === 'PROFILE' ? 'Grupos:' : 'Tema:'}
											<span style={{ color: color, marginLeft: '7px' }}>
												{cardInfo?.type === 'term' && cardInfo?.searchType === 'PROFILE'
													? cardInfo.groupsName.map((it, index) => (index !== 0 ? ' & ' + it.name : it.name))
													: sourceInfo?.cardQuery
													? translateQuery(sourceInfo?.cardQuery, 'card')
													: translateQuery(sourceInfo?.query?.format?.sqlFormat)}
											</span>
										</Grid>
									)}

									{cardInfo?.createdAt && (
										<Grid
											item
											xs={12}
											style={{ textAlign: 'left', color: 'rgb(120, 120, 120)', fontFamily: 'Poppins', fontWeight: '300' }}
										>
											Criado em:
											<span style={{ color: color, marginLeft: '7px' }}>
												{Moment(cardInfo.createdAt).format('DD/MM/YYYY HH:mm:ss')}
											</span>
										</Grid>
									)}

									{sourceInfo?.timeZone && (
										<Grid
											item
											xs={12}
											style={{ textAlign: 'left', color: 'rgb(120, 120, 120)', fontFamily: 'Poppins', fontWeight: '300' }}
										>
											Fuso horário:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.timeZone}</span>
										</Grid>
									)}
									{sourceInfo?.printableName && (
										<Grid item xs={12}>
											País:
											<span style={{ color: color, marginLeft: '7px' }}>
												{sourceInfo?.printableName}
												{sourceInfo?.generalLanguage && ` (${sourceInfo?.generalLanguage})`}
											</span>
										</Grid>
									)}
									{sourceInfo?.category && (
										<Grid item xs={12}>
											Categoria:
											<span style={{ color: color, marginLeft: '7px' }}>{categories[sourceInfo?.category]}</span>
										</Grid>
									)}
									{sourceInfo?.category && card.type === 'term' && cardInfo.searchType !== 'PROFILE' && (
										<Grid item xs={12}>
											Contagem de termos:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.termsCount}</span>
										</Grid>
									)}
									{sourceInfo?.politicalPartyName && (
										<Grid item xs={12}>
											Partido político:
											<span style={{ color: color, marginLeft: '7px' }}>
												{sourceInfo?.politicalPartyName}
												{sourceInfo?.initials &&
													!sourceInfo?.initials.includes('SEM PARTIDO') &&
													` (${sourceInfo?.initials})`}
											</span>
										</Grid>
									)}
									{sourceInfo?.twitterAccount && (
										<Grid item xs={12}>
											Conta do Twitter:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.twitterAccount}</span>
											<a
												href={defineUrl('twitter') || ''}
												target='_blank'
												rel='noopener noreferrer'
												style={{ textDecoration: 'none', outlineColor: 'none' }}
											>
												<Tooltip title='Abrir Original' placement='right-end'>
													<IconButton size='small' style={{ marginLeft: '2px' }}>
														<ExternalLinkIcon color={color} size={17} />
													</IconButton>
												</Tooltip>
											</a>
										</Grid>
									)}
									{sourceInfo?.facebookPage && (
										<Grid item xs={12}>
											Conta do Facebook:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.facebookPage}</span>
											<a
												href={defineUrl('facebook') || ''}
												target='_blank'
												rel='noopener noreferrer'
												style={{ textDecoration: 'none', outlineColor: 'none' }}
											>
												<Tooltip title='Abrir Original' placement='right-end'>
													<IconButton size='small' style={{ marginLeft: '2px' }}>
														<ExternalLinkIcon color={color} size={17} />
													</IconButton>
												</Tooltip>
											</a>
										</Grid>
									)}
									{sourceInfo?.instagramAccount && (
										<Grid item xs={12}>
											Conta do Instagram:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.instagramAccount}</span>
											<a
												href={defineUrl('instagram') || ''}
												target='_blank'
												rel='noopener noreferrer'
												style={{ textDecoration: 'none', outlineColor: 'none' }}
											>
												<Tooltip title='Abrir Original' placement='right-end'>
													<IconButton size='small' style={{ marginLeft: '2px' }}>
														<ExternalLinkIcon color={color} size={17} />
													</IconButton>
												</Tooltip>
											</a>
										</Grid>
									)}
									{sourceInfo?.tiktokAccount && (
										<Grid item xs={12}>
											Conta do Tiktok:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.tiktokAccount}</span>
											<a
												href={defineUrl('tiktok') || ''}
												target='_blank'
												rel='noopener noreferrer'
												style={{ textDecoration: 'none', outlineColor: 'none' }}
											>
												<Tooltip title='Abrir Original' placement='right-end'>
													<IconButton size='small' style={{ marginLeft: '2px' }}>
														<ExternalLinkIcon color={color} size={17} />
													</IconButton>
												</Tooltip>
											</a>
										</Grid>
									)}
									{sourceInfo?.youtubeAccount && (
										<Grid item xs={12}>
											Conta do Youtube:
											<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.youtubeAccount}</span>
											<a
												href={defineUrl('youtube') || ''}
												target='_blank'
												rel='noopener noreferrer'
												style={{ textDecoration: 'none', outlineColor: 'none' }}
											>
												<Tooltip title='Abrir Original' placement='right-end'>
													<IconButton size='small' style={{ marginLeft: '2px' }}>
														<ExternalLinkIcon color={color} size={17} />
													</IconButton>
												</Tooltip>
											</a>
										</Grid>
									)}
									{card.type !== 'group' && card.type !== 'report' && (
										<>
											<Grid item xs={12} style={{ marginTop: '20px' }}>
												Fontes sendo monitoradas:
											</Grid>
											{card.type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
												<Grid
													item
													xs={12}
													container
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													style={{ marginTop: '15px', position: 'relative' }}
												>
													{defineLoadingStatus(sourceInfo?.status?.twitter) && (
														<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
															<CircularProgress
																thickness={1.2}
																style={{ color: color, width: '26px', height: '26px' }}
															/>
														</div>
													)}
													<TwitterIcon
														size={iconSize}
														color={`${defineStatusColor(sourceInfo?.status?.twitter, sourceInfo?.twitterAccount)}`}
													/>
													<div style={{ marginLeft: '10px' }}>Twitter status: </div>
													{sourceInfo?.status?.twitter && (
														<span style={{ color: color, marginLeft: '0.3em' }}>{`${defineStatusText(
															sourceInfo?.status?.twitter,
															sourceInfo?.twitterAccount
														)}`}</span>
													)}
													{!sourceInfo?.status?.twitter && (
														<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
													)}
													<IconButton
														size='small'
														style={{ outline: 'none' }}
														onClick={() => {
															setSource('twitter')
															setOpenCardConfig(true)
														}}
													>
														<InfoIcon
															size={15}
															style={{
																color: color,
																cursor: 'pointer'
															}}
														/>
													</IconButton>
												</Grid>
											)}
											<Grid
												item
												xs={12}
												container
												direction='row'
												justifyContent='flex-start'
												alignItems='center'
												style={{ marginTop: '15px', position: 'relative' }}
											>
												{defineLoadingStatus(sourceInfo?.status?.facebook) && (
													<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
														<CircularProgress thickness={1.2} style={{ color: color, width: '26px', height: '26px' }} />
													</div>
												)}
												<FacebookIcon
													size={iconSize}
													color={`${defineStatusColor(sourceInfo?.status?.facebook, sourceInfo?.facebookPage)}`}
												/>
												<div style={{ marginLeft: '10px' }}>Facebook status: </div>
												{sourceInfo?.status?.facebook && (
													<span style={{ color: color, marginLeft: '0.3em' }}>{`${defineStatusText(
														sourceInfo?.status?.facebook,
														sourceInfo?.facebookPage
													)}`}</span>
												)}
												{!sourceInfo?.status?.facebook && (
													<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
												)}
												{card.type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
													<IconButton
														size='small'
														style={{ outline: 'none' }}
														onClick={() => {
															setSource('facebook')
															setOpenCardConfig(true)
														}}
													>
														<InfoIcon
															size={15}
															style={{
																color: color,
																cursor: 'pointer'
															}}
														/>
													</IconButton>
												)}
												{card.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
													<>
														<div style={{ marginLeft: '20px' }}>Conta: </div>
														{sourceInfo?.status?.facebook && (
															<span style={{ color: color, marginLeft: '0.3em' }}>{sourceInfo?.facebookPage}</span>
														)}
													</>
												)}
											</Grid>
											<Grid
												item
												xs={12}
												container
												direction='row'
												justifyContent='flex-start'
												alignItems='center'
												style={{ marginTop: '15px', position: 'relative' }}
											>
												{defineLoadingStatus(sourceInfo?.status?.instagram) && (
													<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
														<CircularProgress thickness={1.2} style={{ color: color, width: '26px', height: '26px' }} />
													</div>
												)}
												<InstagramIcon
													size={iconSize}
													color={`${defineStatusColor(sourceInfo?.status?.instagram, sourceInfo?.instagramAccount)}`}
												/>
												<div style={{ marginLeft: '10px' }}>Instagram status: </div>
												{sourceInfo?.status?.instagram && (
													<span style={{ color: color, marginLeft: '0.3em' }}>
														{`${defineStatusText(sourceInfo?.status?.instagram, sourceInfo?.instagramAccount)}`}
													</span>
												)}
												{!sourceInfo?.status?.instagram && (
													<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
												)}
												{card.type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
													<IconButton
														size='small'
														style={{ outline: 'none' }}
														onClick={() => {
															setSource('instagram')
															setOpenCardConfig(true)
														}}
													>
														<InfoIcon
															size={15}
															style={{
																color: color,
																cursor: 'pointer'
															}}
														/>
													</IconButton>
												)}
												{card.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
													<>
														<div style={{ marginLeft: '20px' }}>Conta: </div>
														{sourceInfo?.status?.instagram && (
															<span style={{ color: color, marginLeft: '0.3em' }}>{sourceInfo?.instagramAccount}</span>
														)}
													</>
												)}
											</Grid>
											{card.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
												<Grid
													item
													xs={12}
													container
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													style={{ marginTop: '15px', position: 'relative' }}
												>
													{defineLoadingStatus(sourceInfo?.status?.metaAds) && (
														<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
															<CircularProgress
																thickness={1.2}
																style={{ color: color, width: '26px', height: '26px' }}
															/>
														</div>
													)}
													<MetaAdsIcon
														size={iconSize}
														color={`${defineStatusColor(sourceInfo?.status?.metaAds, sourceInfo?.metaAdsAccount)}`}
													/>
													<div style={{ marginLeft: '10px' }}>Meta Ads status: </div>
													{sourceInfo?.status?.metaAds && (
														<span style={{ color: color, marginLeft: '0.3em' }}>
															{`${defineStatusText(sourceInfo?.status?.metaAds, sourceInfo?.metaAdsAccount)}`}
														</span>
													)}
													{!sourceInfo?.status?.metaAds && (
														<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
													)}
													{card.type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
														<>
															<div style={{ marginLeft: '20px' }}>Conta: </div>
															{sourceInfo?.status?.metaAds && (
																<span style={{ color: color, marginLeft: '0.3em' }}>
																	{sourceInfo?.metaAdsAccount}
																</span>
															)}
														</>
													)}
												</Grid>
											)}{' '}
											{card.type === 'term' && card.searchType !== 'PROFILE' && (
												<Grid
													item
													xs={12}
													container
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													style={{ marginTop: '15px', position: 'relative' }}
												>
													{defineLoadingStatus(sourceInfo?.status?.tiktok) && (
														<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
															<CircularProgress
																thickness={1.2}
																style={{ color: color, width: '26px', height: '26px' }}
															/>
														</div>
													)}
													<TiktokIcon
														size={iconSize}
														color={`${defineStatusColor(sourceInfo?.status?.tiktok, sourceInfo?.tiktokAccount)}`}
													/>
													<div style={{ marginLeft: '10px' }}>Tiktok status: </div>
													{sourceInfo?.status?.tiktok && (
														<span style={{ color: color, marginLeft: '0.3em' }}>
															{`${defineStatusText(sourceInfo?.status?.tiktok, sourceInfo?.tiktokAccount)}`}
														</span>
													)}
													{!sourceInfo?.status?.tiktok && (
														<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
													)}
													<IconButton
														size='small'
														style={{ outline: 'none' }}
														onClick={() => {
															setSource('tiktok')
															setOpenCardConfig(true)
														}}
													>
														<InfoIcon
															size={15}
															style={{
																color: color,
																cursor: 'pointer'
															}}
														/>
													</IconButton>
												</Grid>
											)}
											{card.type === 'term' && card.searchType !== 'PROFILE' && (
												<Grid
													item
													xs={12}
													container
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													style={{ marginTop: '15px', position: 'relative' }}
												>
													{defineLoadingStatus(sourceInfo?.status?.youtube) && (
														<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
															<CircularProgress
																thickness={1.2}
																style={{ color: color, width: '26px', height: '26px' }}
															/>
														</div>
													)}
													<YoutubeIcon
														size={iconSize}
														color={`${defineStatusColor(sourceInfo?.status?.youtube, sourceInfo?.youtubeAccount)}`}
													/>
													<div style={{ marginLeft: '10px' }}>Youtube status: </div>
													{sourceInfo?.status?.youtube && (
														<span style={{ color: color, marginLeft: '0.3em' }}>
															{`${defineStatusText(sourceInfo?.status?.youtube, sourceInfo?.youtubeAccount)}`}
														</span>
													)}
													{!sourceInfo?.status?.youtube && (
														<span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>
													)}
													<IconButton
														size='small'
														style={{ outline: 'none' }}
														onClick={() => {
															setSource('youtube')
															setOpenCardConfig(true)
														}}
													>
														<InfoIcon
															size={15}
															style={{
																color: color,
																cursor: 'pointer'
															}}
														/>
													</IconButton>
												</Grid>
											)}
										</>
									)}
									{card.type === 'term' && card.searchType !== 'PROFILE' && (
										<Grid
											item
											xs={12}
											container
											direction='row'
											justifyContent='flex-start'
											alignItems='center'
											style={{ marginTop: '15px', position: 'relative' }}
										>
											{defineLoadingStatus(sourceInfo?.status?.news) && (
												<div style={{ position: 'absolute', transform: 'translate(-5px, 3px)' }}>
													<CircularProgress thickness={1.2} style={{ color: color, width: '26px', height: '26px' }} />
												</div>
											)}
											<NewsIcon size={iconSize} color={`${defineStatusColor(sourceInfo?.status?.news)}`} />
											<div style={{ marginLeft: '10px' }}>News status: </div>
											{sourceInfo?.status?.news && (
												<span style={{ color: color, marginLeft: '0.3em' }}>{`${defineStatusText(
													sourceInfo?.status?.news
												)}`}</span>
											)}
											{!sourceInfo?.status?.news && <span style={{ color: color, marginLeft: '0.3em' }}>Desativada</span>}
											<IconButton
												size='small'
												style={{ outline: 'none' }}
												onClick={() => {
													setSource('news')
													setOpenCardConfig(true)
												}}
											>
												<InfoIcon
													size={15}
													style={{
														color: color,
														cursor: 'pointer'
													}}
												/>
											</IconButton>
										</Grid>
									)}

									{card.type === 'group' && sourceInfo?.accounts?.length > 0 && (
										<>
											<>
												<Grid item xs={12} style={{ marginTop: '20px' }}>
													Contas do grupo: <span style={{ color: purple }}>({sourceInfo?.accounts.length})</span>{' '}
													<Button
														className={classes.groupActivatePauseAccountsButton}
														disabled={sourceInfo?.accounts.every((acc) => !acc.active)}
														onClick={() => handleOnClickActivatePause('pause')}
													>
														Pausar contas
													</Button>
													<Button
														className={classes.groupActivatePauseAccountsButton}
														disabled={sourceInfo?.accounts.every((acc) => acc.active)}
														onClick={() => handleOnClickActivatePause('activate')}
													>
														Ativar contas
													</Button>
												</Grid>

												<Grid item xs={12} container style={{ marginTop: '10px' }}>
													{sourceInfo?.accounts.map((option, index) => (
														<Chip
															key={index}
															style={{ color: '#FFF', backgroundColor: color, margin: '5px' }}
															variant='outlined'
															label={
																<div>
																	<span style={{ marginRight: '0.3em' }}>
																		{option.active ? (
																			<PlayIcon
																				className='fix-icon-margin-bottom-negative'
																				size={15}
																				color='#FFF'
																			/>
																		) : (
																			<PauseIcon
																				className='fix-icon-margin-bottom-negative'
																				size={15}
																				color='#FFF'
																			/>
																		)}
																	</span>
																	{option.name}
																</div>
															}
														/>
													))}
												</Grid>
											</>
											<>
												<Grid item xs={12} style={{ marginTop: '20px' }}>
													Cartões de tema ligados ao grupo:{' '}
													<span style={{ color: blue }}>({sourceInfo?.cardTerms.length})</span>{' '}
												</Grid>

												<Grid item xs={12} container style={{ marginTop: '10px' }}>
													{sourceInfo?.cardTerms.map((option, index) => (
														<Chip
															key={index}
															style={{ color: '#FFF', backgroundColor: blue, margin: '5px' }}
															variant='outlined'
															label={
																<div>
																	<span style={{ marginRight: '0.3em' }}>
																		{option.active ? (
																			<PlayIcon
																				className='fix-icon-margin-bottom-negative'
																				size={15}
																				color='#FFF'
																			/>
																		) : (
																			<PauseIcon
																				className='fix-icon-margin-bottom-negative'
																				size={15}
																				color='#FFF'
																			/>
																		)}
																	</span>
																	{option.name}
																</div>
															}
														/>
													))}
												</Grid>
											</>
										</>
									)}
									{sourceInfo?.tags?.value?.length > 0 && (
										<>
											<Grid item xs={12} style={{ marginTop: '20px' }}>
												Tags:
											</Grid>
											<Grid item xs={12} container style={{ marginTop: '10px' }}>
												{sourceInfo?.tags?.value.map((option, index) => (
													<Chip
														key={index}
														style={{ color: '#FFF', backgroundColor: color, margin: '5px' }}
														variant='outlined'
														label={option}
													/>
												))}
											</Grid>
										</>
									)}
									{sourceInfo?.stopWords?.length > 0 && (
										<Grid item xs={12}>
											<Card
												elevation={0}
												style={{
													borderRadius: '20px',
													marginTop: '1em',
													backgroundColor: 'rgba(255,255,255,0.6)',
													border: openStopWords ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
												}}
											>
												<Button
													onClick={() => {
														setOpenStopWords(!openStopWords)
													}}
													fullWidth
													style={{
														fontFamily: 'Poppins',
														color: openStopWords ? '#1d8cf8' : '#9a9a9a',
														justifyContent: 'flex-start',
														backgroundColor: openStopWords && 'whitesmoke'
													}}
												>
													{!openStopWords && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
													{openStopWords && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
													Palavras ocultadas
													<StyledBadge
														badgeContent={sourceInfo?.stopWords?.length}
														color='error'
														style={{ margin: '0.9em 1em 1.1em 1.5em', fontFamily: 'Poppins' }}
													/>
												</Button>
												<Collapse in={openStopWords}>
													<Grid item xs={12} container style={{ padding: '1em' }}>
														{sourceInfo?.stopWords.map((option, index) => (
															<Chip
																key={index}
																style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																variant='outlined'
																label={option}
															/>
														))}
													</Grid>
												</Collapse>
											</Card>
										</Grid>
									)}
									{(sourceInfo?.dictionaryPositive?.length > 0 || sourceInfo?.dictionaryNegative?.length > 0) && (
										<Grid item xs={12}>
											<Card
												elevation={0}
												style={{
													borderRadius: '20px',
													marginTop: '1em',
													backgroundColor: 'rgba(255,255,255,0.6)',
													border: openDictionary ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
												}}
											>
												<Button
													onClick={() => {
														setOpenDictionary(!openDictionary)
													}}
													fullWidth
													style={{
														fontFamily: 'Poppins',
														color: openDictionary ? '#1d8cf8' : '#9a9a9a',
														justifyContent: 'flex-start',
														backgroundColor: openDictionary && 'whitesmoke'
													}}
												>
													{!openDictionary && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
													{openDictionary && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
													Palavras Positivas e Negativas
													<StyledBadge
														badgeContent={sourceInfo?.dictionaryPositive?.length + sourceInfo?.dictionaryNegative?.length}
														color='error'
														style={{ margin: '0.9em 1em 1.1em 1.5em', fontFamily: 'Poppins' }}
													/>
												</Button>
												<Collapse in={openDictionary}>
													<Grid style={{ padding: '1em' }}>
														{sourceInfo?.dictionaryPositive?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginLeft: '10px' }}>
																	Palavras positivas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.dictionaryPositive.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.dictionaryNegative?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Palavras negativas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.dictionaryNegative.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
													</Grid>
												</Collapse>
											</Card>
										</Grid>
									)}
									{(sourceInfo?.stopAccountTwitter?.length > 0 ||
										sourceInfo?.stopAccountFacebook?.length > 0 ||
										sourceInfo?.stopAccountInstagram?.length > 0 ||
										sourceInfo?.stopAccountNews?.length > 0) && (
										<Grid item xs={12}>
											<Card
												elevation={0}
												style={{
													borderRadius: '20px',
													marginTop: '1em',
													backgroundColor: 'rgba(255,255,255,0.6)',
													border: openStopAccounts ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
												}}
											>
												<Button
													onClick={() => {
														setOpenStopAccounts(!openStopAccounts)
													}}
													fullWidth
													style={{
														fontFamily: 'Poppins',
														color: openStopAccounts ? '#1d8cf8' : '#9a9a9a',
														justifyContent: 'flex-start',
														backgroundColor: openStopAccounts && 'whitesmoke'
													}}
												>
													{!openStopAccounts && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
													{openStopAccounts && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
													Contas e Domínios Retirados
													<StyledBadge
														badgeContent={
															sourceInfo?.stopAccountTwitter?.length +
															sourceInfo?.stopAccountFacebook?.length +
															checkIfIsUndefined(sourceInfo?.stopAccountInstagram) +
															checkIfIsUndefined(sourceInfo?.stopAccountTiktok) +
															checkIfIsUndefined(sourceInfo?.stopAccountYoutube) +
															checkIfIsUndefined(sourceInfo?.stopAccountNews)
														}
														color='error'
														style={{ margin: '0.9em 1em 1.1em 1.5em', fontFamily: 'Poppins' }}
													/>
												</Button>
												<Collapse in={openStopAccounts}>
													<Grid style={{ padding: '1em' }}>
														{sourceInfo?.stopAccountTwitter?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginLeft: '10px' }}>
																	Contas do Twitter retiradas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountTwitter.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.stopAccountFacebook?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Contas do Facebook retiradas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountFacebook.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.stopAccountInstagram?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Contas do Instagram retiradas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountInstagram.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.stopAccountTiktok?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Contas do Tiktok retiradas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountTiktok.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.stopAccountYoutube?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Contas do Youtube retiradas:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountYoutube.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{sourceInfo?.stopAccountNews?.length > 0 && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Domínios de notícias retirados:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.stopAccountNews.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#b8b8b8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
													</Grid>
												</Collapse>
											</Card>
										</Grid>
									)}
									{sourceInfo?.bubbleBurstTwitterAccount?.length > 0 && (
										<>
											<Grid
												item
												xs={12}
												style={{ textAlign: 'left', color: 'rgb(120, 120, 120)', fontFamily: 'Poppins', fontWeight: '300' }}
											>
												Analisado segundo a perspectiva da conta:
												<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.bubbleBurstTwitterAccount}</span>
											</Grid>
										</>
									)}
									{sourceInfo?.bubbleBurstTweetId && (
										<>
											<Grid item xs={12}>
												ID do Tweet analisado:
												<span style={{ color: color, marginLeft: '7px' }}>{sourceInfo?.bubbleBurstTweetId}</span>
												<a
													href={`https://twitter.com/anyuser/status/${sourceInfo?.bubbleBurstTweetId}`}
													target='_blank'
													rel='noopener noreferrer'
													style={{ textDecoration: 'none', outlineColor: 'none' }}
												>
													<Tooltip title='Abrir Original' placement='right-end'>
														<IconButton size='small' style={{ marginLeft: '2px' }}>
															<ExternalLinkIcon color={color} size={17} />
														</IconButton>
													</Tooltip>
												</a>
											</Grid>
											<Grid item xs={12} container justifyContent='center' alignItems='center'>
												<Card
													style={{
														marginTop: '30px',
														width: '50%',
														overflow: 'auto',
														height: '300px',
														borderRadius: '20px',
														backdropFilter: 'blur(10px)',
														backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
														border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
														boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
														padding: '20px'
													}}
												>
													<div>
														<Tweet tweetId={sourceInfo?.bubbleBurstTweetId} />
													</div>
												</Card>
											</Grid>
										</>
									)}
									{sourceInfo?.facebookPostUrl && (
										<Grid item xs={12} container>
											<Grid item xs={12}>
												URL do Post original:
												<a
													href={sourceInfo?.facebookPostUrl}
													target='_blank'
													rel='noopener noreferrer'
													style={{ textDecoration: 'none', outlineColor: 'none' }}
												>
													<span style={{ color: color, marginLeft: '7px' }}>abrir original</span>
													<Tooltip title='Abrir Original' placement='right-end'>
														<IconButton size='small' style={{ marginLeft: '2px' }}>
															<ExternalLinkIcon color={color} size={17} />
														</IconButton>
													</Tooltip>
												</a>
											</Grid>
											<Grid item xs={12} container>
												<Grid container justifyContent='center' alignItems='center'>
													<Card
														style={{
															marginTop: '30px',
															width: '60%',
															overflow: 'auto',
															height: '300px',
															borderRadius: '20px',
															backdropFilter: 'blur(10px)',
															backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
															border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
															boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
															padding: '20px'
														}}
													>
														<FacebookEmbed url={sourceInfo?.facebookPostUrl} width={500} />
													</Card>
												</Grid>
											</Grid>
										</Grid>
									)}
									{(sourceInfo?.manualClassificationAccount?.twitter?.haters?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.twitter?.lovers?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.facebook?.haters?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.facebook?.lovers?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.instagram?.haters?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.instagram?.lovers?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.tiktok?.haters?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.tiktok?.lovers?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.youtube?.haters?.length > 0 ||
										sourceInfo?.manualClassificationAccount?.youtube?.lovers?.length > 0) && (
										<Grid item xs={12}>
											<Card
												elevation={0}
												style={{
													borderRadius: '20px',
													marginTop: '1em',
													backgroundColor: 'rgba(255,255,255,0.6)',
													border: openClassification ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
												}}
											>
												<Button
													onClick={() => {
														setOpenClassification(!openClassification)
													}}
													fullWidth
													style={{
														fontFamily: 'Poppins',
														color: openClassification ? '#1d8cf8' : '#9a9a9a',
														justifyContent: 'flex-start',
														backgroundColor: openClassification && 'whitesmoke'
													}}
												>
													{!openClassification && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
													{openClassification && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
													Contas Haters e Lovers
													<StyledBadge
														badgeContent={
															(sourceInfo?.manualClassificationAccount?.twitter?.haters?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.twitter?.lovers?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.facebook?.haters?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.facebook?.lovers?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.instagram?.haters?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.instagram?.lovers?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.tiktok?.haters?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.tiktok?.lovers?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.youtube?.haters?.length || 0) +
															(sourceInfo?.manualClassificationAccount?.youtube?.lovers?.length || 0)
														}
														color='error'
														style={{ margin: '0.9em 1em 1.1em 1.5em', fontFamily: 'Poppins' }}
													/>
												</Button>
												<Collapse in={openClassification}>
													<Grid style={{ padding: '1em' }}>
														{(sourceInfo?.manualClassificationAccount?.twitter?.haters?.length > 0 ||
															sourceInfo?.manualClassificationAccount?.twitter?.lovers?.length > 0) && (
															<>
																<Grid item xs={12} style={{ marginLeft: '10px' }}>
																	Twitter:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.manualClassificationAccount?.twitter?.haters.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																	{sourceInfo?.manualClassificationAccount?.twitter?.lovers.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{(sourceInfo?.manualClassificationAccount?.facebook?.haters?.length > 0 ||
															sourceInfo?.manualClassificationAccount?.facebook?.lovers?.length > 0) && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Facebook:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.manualClassificationAccount?.facebook?.haters.map(
																		(option, index) => (
																			<Chip
																				key={index}
																				style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																				variant='outlined'
																				label={option}
																			/>
																		)
																	)}
																	{sourceInfo?.manualClassificationAccount?.facebook?.lovers.map(
																		(option, index) => (
																			<Chip
																				key={index}
																				style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																				variant='outlined'
																				label={option}
																			/>
																		)
																	)}
																</Grid>
															</>
														)}
														{(sourceInfo?.manualClassificationAccount?.instagram?.haters?.length > 0 ||
															sourceInfo?.manualClassificationAccount?.instagram?.lovers?.length > 0) && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Instagram:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.manualClassificationAccount?.instagram?.haters.map(
																		(option, index) => (
																			<Chip
																				key={index}
																				style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																				variant='outlined'
																				label={option}
																			/>
																		)
																	)}
																	{sourceInfo?.manualClassificationAccount?.instagram?.lovers.map(
																		(option, index) => (
																			<Chip
																				key={index}
																				style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																				variant='outlined'
																				label={option}
																			/>
																		)
																	)}
																</Grid>
															</>
														)}
														{(sourceInfo?.manualClassificationAccount?.tiktok?.haters?.length > 0 ||
															sourceInfo?.manualClassificationAccount?.tiktok?.lovers?.length > 0) && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Tiktok:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.manualClassificationAccount?.tiktok?.haters.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																	{sourceInfo?.manualClassificationAccount?.tiktok?.lovers.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
														{(sourceInfo?.manualClassificationAccount?.youtube?.haters?.length > 0 ||
															sourceInfo?.manualClassificationAccount?.youtube?.lovers?.length > 0) && (
															<>
																<Grid item xs={12} style={{ marginTop: '20px', marginLeft: '10px' }}>
																	Youtube:
																</Grid>
																<Grid item xs={12} container style={{ marginTop: '10px' }}>
																	{sourceInfo?.manualClassificationAccount?.youtube?.haters.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#ff2b6b', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																	{sourceInfo?.manualClassificationAccount?.youtube?.lovers.map((option, index) => (
																		<Chip
																			key={index}
																			style={{ color: '#FFF', backgroundColor: '#1d8cf8', margin: '5px' }}
																			variant='outlined'
																			label={option}
																		/>
																	))}
																</Grid>
															</>
														)}
													</Grid>
												</Collapse>
											</Card>
										</Grid>
									)}
									{sourceInfo?.tagingRules?.length > 0 && (
										<Grid item xs={12}>
											<Card
												elevation={0}
												style={{
													borderRadius: '20px',
													marginTop: '1em',
													backgroundColor: 'rgba(255,255,255,0.6)',
													border: openTags ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
												}}
											>
												<Button
													onClick={() => {
														setOpenTags(!openTags)
													}}
													fullWidth
													style={{
														fontFamily: 'Poppins',
														color: openTags ? '#1d8cf8' : '#9a9a9a',
														justifyContent: 'flex-start',
														backgroundColor: openTags && 'whitesmoke'
													}}
												>
													{!openTags && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
													{openTags && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
													Regras de Tagueamento
													<StyledBadge
														badgeContent={sourceInfo?.tagingRules?.length}
														color='error'
														style={{ margin: '0.9em 1em 1.1em 1.5em', fontFamily: 'Poppins' }}
													/>
												</Button>
												<Collapse in={openTags}>
													<Grid item xs={12} container style={{ padding: '1em' }}>
														{sourceInfo?.tagingRules?.map((option, index) => (
															<Tooltip key={index} title={option?.query || ''} placement='top'>
																<Chip
																	style={{
																		padding: '0.1em',
																		color: white,
																		backgroundColor: blue,
																		borderColor: blue,
																		margin: '5px'
																	}}
																	variant='outlined'
																	label={<CardTagLabel tag={option?.tag_key} />}
																/>
															</Tooltip>
														))}
													</Grid>
												</Collapse>
											</Card>
										</Grid>
									)}
								</Grid>
							)}
						</Collapse>
					</Grid>
				</CardContent>
				{activatePauseAccountsOperation && (
					<ActivatePauseAccountsDialog
						onSuccess={handleOnSuccessActivatePauseAccountsDialog}
						operation={activatePauseAccountsOperation}
						cardId={card.id}
						close={handleCloseActivatePauseAccountsDialog}
						color={color}
					/>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ModalCardInfo
