import React, { useState } from 'react'
import { Grid, Card, Button, Dialog, DialogContent, Tooltip, IconButton, Divider } from '@mui/material'
import { CopyIcon, TwitterIcon, FacebookIcon, InstagramIcon, ChartIcon, AllSourcesIcon } from 'utils/SystemIcons'
import { makeStyles } from '@mui/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TypeAnimation } from 'react-type-animation'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import TopicLineGraph from '../TopicLineGraph/TopicLineGraph'
import InspectIntervalButton from 'graphs/components/InspectIntervalButton/InspectIntervalButton'
import TopicItemMenu from '../TopicItemMenu/TopicItemMenu'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const titleStyle = {
	marginLeft: '5px',
	width: '93%',
	textAlign: 'left',
	fontFamily: 'Poppins',
	color: '#1d8cf8',
	fontSize: '20px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const defineIcon = (sources) => {
	const iconStyle = { color: '#1d8cf8', marginTop: '7px' }

	if (sources.includes('twitter')) {
		return <TwitterIcon size={16} style={iconStyle} />
	} else if (sources.includes('facebook')) {
		return <FacebookIcon size={16} style={iconStyle} />
	} else if (sources.includes('instagram')) {
		return <InstagramIcon size={16} style={iconStyle} />
	} else {
		return <AllSourcesIcon size={25} color={iconStyle.color} style={{ marginTop: '2px' }} />
	}
}

const getPostsIds = (topic, topics, ids) => {
	if (topic.topic_modeling_ia_ids.length === 1) {
		ids.push(topic.topic_modeling_ia_id)
	} else {
		topic.topic_modeling_ia_ids.forEach((id) => {
			ids.push(id)
			if (id !== topic.topic_modeling_ia_id) {
				const nextTopic = topics.find((topic) => topic.topic_modeling_ia_id === id)
				if (nextTopic) {
					getPostsIds(nextTopic, topics, ids)
				}
			}
		})
	}
}

const textStyle = { color: 'black', textAlign: 'left', fontFamily: 'Poppins', whiteSpace: 'pre-wrap' }

const TopicListItem = ({ topic, graphData, index, source, filters, cards, cardType, update, topics }) => {
	const [openDialog, setOpenDialog] = useState(false)

	const data = { options: graphData.options, series: graphData.series.filter((serie) => serie.name === topic.title) }

	let topicIds = []

	getPostsIds(topic, topics, topicIds)

	topicIds = [...new Set(topicIds)]

	const classes = useStyles()

	const handleClose = () => {
		setOpenDialog(false)
	}

	const onCopy = React.useCallback(() => {
		sendMessageToast('Texto copiado', constantsVox.TOAST.TYPES.INFO)
	}, [])

	return (
		<>
			<Grid item xs={12}>
				<Card
					style={{
						height: '100%',
						borderRadius: '20px',
						marginLeft: '1em',
						marginRight: '1em',
						marginBottom: '2em',
						border: '1px solid rgba(200,200,200, 0.25)'
					}}
					elevation={0}
				>
					<Grid item xs={12} container style={{ height: '40px', marginBottom: '1em' }}>
						<Grid item xs={11}>
							<div
								style={{
									fontSize: '20px',
									fontFamily: 'Poppins',
									marginTop: '0.5em',
									marginLeft: '1em',
									color: '#1d8cf8',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}
							>
								{topic.title}
							</div>
						</Grid>
						<Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
							<TopicItemMenu topic={topic} update={update} topics={topics} cardId={cards[0].id} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div style={{ marginLeft: '1.8em', marginTop: '0.8em', fontFamily: 'Poppins', fontSize: '17px' }}>
							{data?.series[0]?.numberOfPostsLabel || '0 postagens'}
						</div>
					</Grid>
					<Grid item xs={12}>
						<div
							style={{
								marginTop: '25px',
								marginLeft: '25px',
								height: '15vh',
								overflow: 'auto'
							}}
						>
							<div style={{ marginRight: '8px' }}>
								{!!topic?.summary && (
									<TypeAnimation
										sequence={[`${topic.summary || ''}`]}
										speed={99}
										wrapper='div'
										cursor={false}
										repeat={1}
										style={textStyle}
									/>
								)}
							</div>
						</div>

						<div style={{ height: '6vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<CopyToClipboard onCopy={onCopy} text={topic.summary || ''}>
								<Tooltip title={'Copiar texto'} placement='bottom' enterDelay={500} enterNextDelay={500}>
									<IconButton style={{ marginLeft: '-10px' }}>
										<CopyIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
									</IconButton>
								</Tooltip>
							</CopyToClipboard>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div style={{ marginLeft: '32px', marginBottom: '25px', display: 'flex', maxHeight: '36.5px' }}>
							<Button
								disableElevation
								variant='outlined'
								onClick={() => setOpenDialog(!openDialog)}
								style={{ outline: 'none', maxHeight: '36.5px', whiteSpace: 'nowrap' }}
								className={classes.button}
							>
								<div style={{ display: 'flex', marginLeft: '5px', marginRight: '5px' }}>
									<ChartIcon size={20} style={{ color: blue, marginRight: '5px', marginTop: '2px' }} />
									Ver gráfico
								</div>
							</Button>
							<Grid
								container
								style={{
									marginRight: '2em',
									marginLeft: '2em',
									marginBottom: '1em'
								}}
							>
								<InspectIntervalButton cards={cards} filters={filters} cardType={cardType} topicId={topicIds} />
							</Grid>
						</div>
					</Grid>
				</Card>
			</Grid>

			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					fullWidth={true}
					maxWidth={'lg'}
					PaperProps={{
						style: {
							overflowX: 'hidden',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogContent style={{ overflowY: 'hidden', marginBottom: '0.2em', marginTop: '0.2em' }}>
						<>
							<Grid item xs={12} style={{ minHeight: '31px', marginLeft: '20px', paddingRight: '39px' }}>
								{!!topic?.title && (
									<div style={{ display: 'flex' }}>
										<div style={{ width: '20px', marginRight: '5px' }}>{defineIcon(source)}</div>
										<TypeAnimation
											sequence={[`${topic?.title}`]}
											speed={99}
											wrapper='div'
											cursor={false}
											repeat={1}
											style={titleStyle}
										/>
									</div>
								)}
								<Divider style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: '1px', marginTop: '0em', width: '100%' }} />
							</Grid>
							<TopicLineGraph data={data} height={'400px'} index={index} />
						</>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

const TopicList = ({ data, source, filters, cards, cardType, update }) => {
	return (
		<div style={{ marginTop: `${data?.topics?.length > 0 ? '1em' : '0em'}` }}>
			{data?.topics?.length > 0 &&
				data?.topics[0].status === 'SUCCESS' &&
				data?.topics
					?.filter((topic) => topic.topic_modeling_ia_type === 'TOPIC')
					?.map((topic, index) => (
						<TopicListItem
							key={topic.title}
							topic={topic}
							graphData={data?.graphData}
							index={index}
							source={source}
							filters={filters}
							cards={cards}
							cardType={cardType}
							update={update}
							topics={data?.topics}
						/>
					))}
		</div>
	)
}

export default TopicList
