import React, { useState, useEffect, memo } from 'react'
import { useQuery } from 'hooks/ReactRouterDomHooks'
import { Grid, LinearProgress } from '@mui/material'
import { connect } from 'react-redux'
import { saveGlobalFiltersTermAction, changeRedirectUrlAction } from 'store/redux/actions/global.actions'
import {
	addCardComparisonByIdsAction,
	saveAllCardsCanOpenAction,
	resetCardsComparisonAction,
	saveCardTypeAction
} from 'store/redux/actions/card.actions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import api from 'service/service'
import { withStyles } from '@mui/styles'
//menu: sentiment,overview,occurrences,network,wordclouds,location

//http://localhost:3000/redirect?menu=location&registerIds=24&registerType=term
//http://localhost:3000/redirect?menu=overview&registerIds=27&registerType=profile
//http://localhost:3000/redirect?menu=occurrences&registerIds=1&registerType=group

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(225, 225, 225, 100%)'

const LoadingBlue = withStyles(() => ({
	root: {
		height: 1
	},
	colorPrimary: {
		backgroundColor: blue
	},
	bar: {
		borderRadius: 5,
		backgroundColor: grey
	}
}))(LinearProgress)

const DEFAULT_REDIRECT = { menu: null, registerIds: null, registerType: null }

let redirectUrlProps = DEFAULT_REDIRECT

const CARD_BASE_URL = '/monitoring/card/get-all-by-user-id/'
// const HISTORIC_BASE_URL = '/historic/get-all-historic-by-user-id/'
//Cartao historico não tem notificação no momento

const Redirect = ({
	changeRedirectUrlDispatch,
	saveAllCardsCanOpenDispatch,
	addCardComparisonByIdDispatch,
	history,
	cardComparison,
	resetCardsComparisonDispatch,
	saveCardTypeDispatch
}) => {
	useQuery().forEach((value, key) => (redirectUrlProps[key] = value))
	const { registerType, registerId, menu, notificationType /*,isComparativeCard*/ } = redirectUrlProps

	const [isLoading, setIsLoading] = useState(true)
	const [hasError, setHasError] = useState(false)
	//Pega os parametros da URL e poe no objeto redirectUrlProps

	const load = async () => {
		// const isComparativeCardBool = isComparativeCard === 'true' //não usamos o comparativo nas notificações no momento
		changeRedirectUrlDispatch(null) //reseta a url de redirecionamento pro usuario poder navegar no site
		if (registerType === 'account-management') {
			setIsLoading(false)
			setHasError(false)
			history.push({ pathname: '/account-management' })
			return
		}
		setIsLoading(true)
		api.get('/monitoring/get-by-type-and-ids/' + registerType + `/${registerId}`).then((res) => {
			if (res.data[0].cardStatus.general !== 'S') {
				sendMessageToast('Esse cartão não pode mais ser aberto', constantsVox.TOAST.TYPES.ERROR)
				setIsLoading(false)
				resetCardsComparisonDispatch()
				history.push({ pathname: '/products' })
			} else {
				if (registerType) {
					// let url = isComparativeCardBool ? HISTORIC_BASE_URL : CARD_BASE_URL
					let source = 'twitter' // source do bubble burst (só temos twitter agora)
					let url = CARD_BASE_URL
					api.get(url + registerType + '?source=null&folderId=ALL_ID&page=null&isCardSearchingFilter=null&filterCardName=null')
						.then((res) => {
							saveAllCardsCanOpenDispatch(res.data.items || [], { tab: registerType, source })
							addCardComparisonByIdDispatch(registerId)
							//"Can't perform a React state update on an unmounted component"
							setIsLoading(false)
							setHasError(false)
						})
						.catch((error) => {
							//tem que repetir esse setIsLoading aqui no .catch mesmo, não dá pra por no finally,
							//pq se não vai dar o history.push ali no .then e depois vai pro finally, daí o componente já foi desfeito e vai ter o erro vermelho
							setIsLoading(false)
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						})
				} else {
					sendMessageToast('Tipo não informado', constantsVox.TOAST.TYPES.ERROR)
					setIsLoading(false)
					setHasError(true)
				}
			}
		})
	}

	useEffect(() => {
		load()
	}, [])

	useEffect(() => {
		if (cardComparison.length > 0 && !isLoading) {
			switch (registerType) {
				case 'term':
					saveCardTypeDispatch('term')
					history.push({ pathname: '/monitoring/sentiment-analysis', state: { menu } })
					break
				case 'profile':
					saveCardTypeDispatch('profile')
					history.push({ pathname: '/monitoring/social-profile', state: { menu } })
					break
				case 'group':
					saveCardTypeDispatch('group')
					history.push({ pathname: '/monitoring/accounts-group', state: { menu } })
					break
				default:
					break
			}
		}
	}, [cardComparison, isLoading])

	return (
		<Grid container style={{ height: '88vh', fontFamily: 'Poppins' }} justifyContent='center' alignItems='center'>
			{(isLoading && (
				<div style={{ marginTop: '-3em' }}>
					<h1 style={{ opacity: 0.9, color: '#1d8cf8' }}> {notificationType === 'web' ? 'CARREGANDO...' : 'REDIRECIONANDO...'}</h1>
					<LoadingBlue style={{ width: '100%', height: '2px', marginTop: '-1em' }} />
				</div>
			)) ||
				(hasError && <h1 style={{ opacity: 0.9, color: '#1d8cf8' }}>Não foi possível encontrar a rota informada.</h1>)}
		</Grid>
	)
}

const mapDispatchToProps = (dispatch) => ({
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters)),
	addCardComparisonByIdDispatch: (cardId) => dispatch(addCardComparisonByIdsAction(cardId)),
	changeRedirectUrlDispatch: (newUrl) => dispatch(changeRedirectUrlAction(newUrl)),
	saveAllCardsCanOpenDispatch: (allCards, searchPropsForComparativeBar) =>
		dispatch(saveAllCardsCanOpenAction(allCards, searchPropsForComparativeBar)),
	resetCardsComparisonDispatch: () => dispatch(resetCardsComparisonAction()),
	saveCardTypeDispatch: (type) => dispatch(saveCardTypeAction(type))
})

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	cardComparison: store.card.comparison.cards
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(Redirect))
