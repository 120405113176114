import React, { useState } from 'react'
import { Grid, Tabs, Tab, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

const CardAllBySourceMenu = ({ handleChangeGraphType }) => {
	const [type, setType] = useState('global')

	const classes = useStyles()

	const onGraphTypeChange = (_, newValue) => {
		setType(newValue)
		handleChangeGraphType(newValue)
	}

	return (
		<>
			<Grid container spacing={0} style={{ marginLeft: '20px' }}>
				<Grid item xs={12}>
					<Tabs value={type} onChange={onGraphTypeChange} TabIndicatorProps={{ style: { background: blue } }}>
						<Tab id='tab-overview-global' label='Total' value='global' style={{ outline: 'none' }} className={classes.tab} />
						<Tab id='tab-overview-posts' label='Postagens' value='total_posts' style={{ outline: 'none' }} className={classes.tab} />
						<Tab
							id='tab-overview-engagement'
							label='Engajamento'
							value='engagement'
							style={{ outline: 'none' }}
							className={classes.tab}
						/>
						<Tab
							id='tab-overview-comment'
							label='Comentários'
							value='comment_count'
							style={{ outline: 'none' }}
							className={classes.tab}
						/>
					</Tabs>
				</Grid>
			</Grid>
			<Divider style={{ marginBottom: '10px', background: '#f0f0f0' }} />
		</>
	)
}

export default CardAllBySourceMenu
