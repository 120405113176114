import React, { memo } from 'react'
import { Grid } from '@mui/material'
import Chart from 'react-apexcharts'
import GraphLegend from 'components/GraphLegend/GraphLegend'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'

const getGenderOptions = (colors) => ({
	colors,
	chart: {
		type: 'donut'
	},
	labels: ['Masculino', 'Feminino', 'Indefinido'],
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
})

const RenderGraph = memo(({ cardId, index, title, series, numberOfOptions, isHorizontal, firstAxis, secondAxis }) => {
	const formatValue = (value) => {
		if (value !== null) {
			if (Number.isInteger(value)) {
				return value
			} else {
				return value?.toFixed(2)
			}
		} else {
			return null
		}
	}

	const graphOptions = {
		colors: [blue, purple],
		chart: {
			type: 'bar',
			height: 350,
			stacked: true,
			toolbar: {
				show: true
			},
			zoom: {
				enabled: false
			}
		},
		plotOptions: {
			bar: {
				horizontal: isHorizontal,
				borderRadius: 15,
				borderRadiusApplication: 'end', // "around" / "end"
				borderRadiusWhenStacked: 'last' // "all"/"last"
			}
		},
		grid: {
			show: true,
			borderColor: 'rgba(82, 95, 127, 0.3)',
			position: 'back',
			[firstAxis]: {
				lines: {
					show: false
				}
			},
			[secondAxis]: {
				lines: {
					show: true
				}
			}
		},
		dataLabels: {
			enabled: true,
			offsetX: 0,
			textAnchor: 'middle',
			style: {
				fontSize: '13px',
				fontFamily: 'Roboto',
				colors: ['#fff']
			},
			background: {
				enabled: true,
				foreColor: '#fff',
				padding: 4,
				borderRadius: 10,
				borderWidth: 1,
				borderColor: 'transparent',
				opacity: 0.15
			},
			dropShadow: {
				enabled: true,
				top: 1,
				left: 0,
				blur: 1,
				color: '#000',
				opacity: 0.45
			}
		},
		[firstAxis]: {
			type: 'category',
			categories: [
				'Entre 13 e 17 anos',
				'Entre 18 e 24 anos',
				'Entre 25 e 34 anos',
				'Entre 35 e 44 anos',
				'Entre 45 e 54 anos',
				'Entre 55 e 64 anos',
				'Mais de 65 anos'
			],
			labels: {
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				}
			}
		},
		[secondAxis]: {
			type: 'category',
			categories: [
				'Entre 13 e 17 anos',
				'Entre 18 e 24 anos',
				'Entre 25 e 34 anos',
				'Entre 35 e 44 anos',
				'Entre 45 e 54 anos',
				'Entre 55 e 64 anos',
				'Mais de 65 anos'
			],
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: -10,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => formatValue(value)
			}
		},
		legend: {
			enabled: true,
			position: 'top',
			offsetY: 15,
			markers: {
				radius: 12
			}
		},
		fill: {
			opacity: 1
		}
	}

	return (
		<Grid
			item
			xs={!isHorizontal ? getXs(index, numberOfOptions) : 12}
			key={cardId}
			style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins' }}
		>
			<div style={{ marginBottom: '1em' }}>
				<GraphLegend title={title} />
			</div>
			<Chart type={'bar'} options={graphOptions} series={series} width='100%' height={'400px'} />
		</Grid>
	)
})

const getXs = (index, numberOfOptions) => {
	if (numberOfOptions === 1) {
		return 12
	} else if (numberOfOptions === 2) {
		return 6
	} else if (numberOfOptions === 3) {
		return 4
	} else if (numberOfOptions === 4) {
		return 6
	} else if (numberOfOptions === 5) {
		if (index === 0 || index === 1) {
			return 6
		} else {
			return 4
		}
	}
}

const GenderAgeChart = ({ selectedOption, data, colors }) => {
	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '2.5em', paddingLeft: '15px', paddingRight: '15px', marginBottom: '5px' }}>
				{selectedOption === 'bars' &&
					data?.formatted?.map((fd, index) => (
						<RenderGraph
							key={fd.info.id}
							cardId={fd.info.id}
							index={index}
							title={fd.info.name}
							series={fd.seriesMaleFemale}
							numberOfOptions={data?.formatted?.length}
							isHorizontal={false}
							firstAxis='xaxis'
							secondAxis='yaxis'
						/>
					))}
				{selectedOption === 'infographic' && (
					<>
						<Grid item xs={6}>
							{data?.formatted?.map((fd, index) => (
								<RenderGraph
									key={fd.info.id}
									cardId={fd.info.id}
									index={index}
									title={fd.info.name + ' (idade)'}
									series={fd.seriesAll}
									numberOfOptions={data?.formatted?.length}
									isHorizontal={true}
									firstAxis='yaxis'
									secondAxis='xaxis'
								/>
							))}
						</Grid>
						<Grid item xs={6}>
							{data?.formatted?.map((fd, index) => (
								<Grid key={fd.info.id}>
									<div style={{ padding: '1em' }}>
										<GraphLegend title={fd.info.name + ' (gênero)'} />
									</div>
									<div style={{ padding: '1em' }}>
										<Chart options={getGenderOptions(colors)} series={fd.seriesTotal} type='donut' width='100%' height='400px' />
									</div>
								</Grid>
							))}
						</Grid>
					</>
				)}
			</Grid>
		</>
	)
}

export default memo(GenderAgeChart)
