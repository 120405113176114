import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '@mui/material'

const TrendingTwitterSkeletonLoad = () => (
	<div style={{ width: '100%', display: 'flex', paddingLeft: '10px', overflow: 'scroll' }}>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
		<div>
			<Card
				style={{
					borderRadius: '20px',
					marginRight: '20px',
					marginTop: '20px',
					marginBottom: '10px',
					height: '700px',
					width: '250px',
					maxHeight: '70vh'
				}}
			>
				<Skeleton height='700px' width='100%' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
			</Card>
		</div>
	</div>
)

export default TrendingTwitterSkeletonLoad
