import React, { useState, memo } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const tabsize = 44

const useStyles = makeStyles(() => ({
	tab: {
		minWidth: tabsize,
		width: tabsize,
		height: tabsize,
		minHeight: tabsize,
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
}))

const NetworkSizeSelector = ({ size, onChangeValue }) => {
	const [selectSize, setSelectSize] = useState(size || 10)
	const classes = useStyles()

	const handleSelect = (_, newValue) => {
		setSelectSize(newValue)
		onChangeValue(newValue)
	}

	return (
		<Tabs
			style={{ marginTop: '0px', marginLeft: '8px', textAlign: 'left', float: 'left' }}
			centered
			value={selectSize}
			onChange={handleSelect}
			TabIndicatorProps={{ style: { background: blue } }}
		>
			<Tab label='10' value={10} style={{ outline: 'none' }} className={classes.tab} disabled={false} />

			<Tab label='25' value={25} style={{ outline: 'none' }} className={classes.tab} disabled={false} />

			<Tab label='50' value={50} style={{ outline: 'none' }} className={classes.tab} disabled={false} />
		</Tabs>
	)
}

export default memo(NetworkSizeSelector)
