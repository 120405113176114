import React, { useState, useEffect, useRef } from 'react'
import { Chip, Grid, TextField, Autocomplete, Paper, styled, Collapse, Button, Checkbox } from '@mui/material'
import { PlusIcon, ChevronUpIcon, LoadingIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material'
import { useQueryClient } from 'hooks/ReactQueryHooks'
import api from 'service/service'

const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />
const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'
const white = '#ffffff'
const TAGS_REGEX = /[a-z0-9\b\0 _]/
const TAGS_REGEX_STRING = /^[a-z0-9\b\0 _]+$/

let lastOnDeleteTime = null

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '0px 0px 25px 25px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

function getUniqueStrings(array1, array2) {
	const uniqueStrings = []

	for (const str of array1) {
		if (!array2.includes(str) && !uniqueStrings.includes(str)) {
			uniqueStrings.push(str)
		}
	}

	for (const str of array2) {
		if (!array1.includes(str) && !uniqueStrings.includes(str)) {
			uniqueStrings.push(str)
		}
	}

	return uniqueStrings
}

const RendertagLabel = ({ tag }) => {
	let splitTag = [tag]

	if (tag?.includes('_')) {
		splitTag = tag?.split('_')
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					backgroundColor: blue
				}}
			>
				{splitTag[0]}
			</div>
			{splitTag.length > 1 && (
				<div
					style={{
						marginLeft: '8px',
						paddingLeft: '8px',
						paddingRight: '8px',
						backgroundColor: white,
						borderColor: blue,
						color: blue,
						border: '1px solid white',
						borderRadius: '25px'
					}}
				>
					{splitTag[1]}
				</div>
			)}
		</div>
	)
}

const checkTagType = (tag, initialTags, manualTags) => {
	let tagType = 'MANUAL'
	initialTags.forEach((initialTag) => {
		if (initialTag.tag_key === tag) {
			tagType = initialTag.type
		}
	})
	manualTags.forEach((manualTag) => {
		if (manualTag === tag) {
			tagType = 'MANUAL'
		}
	})
	return tagType
}

const tagStringToObject = (tag, initialTags, manualTags) => {
	const splitTag = tag?.split('_')
	if (splitTag?.length === 1) {
		return {
			type: checkTagType(tag, initialTags, manualTags),
			tag_key: tag,
			tag: tag
		}
	}
	return {
		type: checkTagType(tag, initialTags, manualTags),
		tag_key: tag,
		tag: splitTag[0],
		subtag: splitTag[1]
	}
}

const removeExtraSpaces = (tag) => {
	let newTag = tag
	while (newTag.includes('  ')) {
		newTag = newTag.replace('  ', ' ')
	}
	while (newTag[0] === ' ') {
		newTag = newTag.substring(1)
	}
	while (newTag[newTag.length - 1] === ' ') {
		newTag = newTag.substring(0, newTag.length - 1)
	}
	return newTag
}

const formatInitialTags = (tags, isFilter) => {
	let newTags = []
	if (tags?.length > 0 && !isFilter) {
		tags.forEach((tag) => {
			if (tag.subtag) {
				newTags.push(`${tag.tag}_${tag.subtag}`)
			} else {
				newTags.push(tag.tag)
			}
		})
	} else {
		newTags = tags || []
	}
	return newTags
}

const PostTags = ({
	isOpen,
	isFocused,
	occurrenceId,
	cardId,
	source,
	initialTags,
	toggleTags,
	isFilter,
	onChangeTags,
	hasCollapseUp = false,
	style,
	cardType
}) => {
	const [submitting, setSubmitting] = useState(false)
	const [tags, setTags] = useState(formatInitialTags(initialTags, isFilter))
	const [tagsObject, setTagsObject] = useState([])
	const [currentText, setCurrentText] = useState('')
	const [error, setError] = useState(false)
	const [errorText, setErrorText] = useState('')
	const autocompleteRef = useRef()
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(false)
	const isMounted = useRef(true)
	const [manualTags, setManualTags] = useState([])
	const queryClient = useQueryClient()

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (isOpen && isFocused) {
			autocompleteRef.current.focus()
		}
	}, [isOpen, isFocused])

	useEffect(() => {
		let newTags = []
		tags.forEach((tag) => {
			if (validateTag(tag)) {
				newTags.push(tagStringToObject(tag, initialTags, manualTags))
			}
		})
		setTagsObject(newTags)
	}, [tags])

	useEffect(() => {
		if (isOpen && !isFilter) {
			saveTags()
		}
	}, [tagsObject])

	const saveTags = async () => {
		setSubmitting(true)
		await api
			.post('/occurrences/update-tags', {
				occurrenceId,
				cardId,
				tagsObject,
				source,
				cardType: cardType.toUpperCase()
			})
			.then(() => {
				invalidateQueriesOnChangeCard()
				loadTagHistory()
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				setTimeout(() => {
					if (isOpen && isFocused) {
						autocompleteRef.current.focus()
					}
				}, 300)
			})
	}

	const validateTag = (tag) => {
		if (tag === '') {
			setErrorText('A tag não pode ser vazia')
			return false
		}
		if (tag.trim().length === 0) {
			setErrorText('A tag não pode ser composta apenas de espaços')
			return false
		}
		if (tag[0] === '_' || tag[tag.length - 1] === '_') {
			setErrorText('A tag não pode começar ou terminar com _')
			return false
		}
		if (tag.includes(' _') || tag.includes('_ ')) {
			setErrorText('A tag não pode conter espaços antes ou depois de _')
			return false
		}
		if (tag.includes('__')) {
			setErrorText('A tag não pode conter mais que um _ seguido')
			return false
		}
		const splitTag = tag.split('_')
		if (splitTag.length > 2) {
			setErrorText('A tag não pode conter mais que um _')
			return false
		}
		if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
			setErrorText('A tag não pode começar ou terminar com espaço vazio')
			return false
		}

		if (tag[0] === ' ' || tag[tag.length - 1] === ' ') {
			setErrorText('A tag não pode começar ou terminar com espaço vazio')
			return false
		}

		if (tag !== tag.toLowerCase()) {
			setErrorText('Tags não podem conter letra maiúscula')
			return false
		}

		if (!TAGS_REGEX_STRING.test(tag)) {
			setErrorText('A tag não pode conter caracteres especiais')
			return false
		}

		setErrorText('')
		return true
	}

	const handleChangeTags = (tags) => {
		tags = tags.map((tag) => removeExtraSpaces(tag.trim()))

		let isValid = true
		let isUnique = true

		tags.forEach((tag, index) => {
			if (tags.indexOf(tag) !== index) {
				isUnique = false
			}
		})

		tags.forEach((tag) => {
			if (!validateTag(tag)) {
				isValid = false
			}
		})

		if (isValid && isUnique) {
			setError(false)
			setTags(tags)
			if (onChangeTags) {
				onChangeTags(tags)
			}
			setErrorText('')
		} else {
			setError(true)
			if (isUnique === false) {
				setErrorText('A tag não pode ser repetida')
			}
			setCurrentText(tags[tags.length - 1])
		}
	}

	const loadTagHistory = async () => {
		setLoading(true)
		const response = await api.get('/tag-history/get-all-user-tag-history')
		const data = response.data
		if (isMounted.current) {
			setOptions(data.map((it) => it.tag_name) || [])
		}

		setLoading(false)
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={props.id} id={`btn-check-tag-${option}`}>
				<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				<Chip
					style={{
						color: white,
						backgroundColor: '#1d8cf8',
						borderColor: '#1d8cf8'
					}}
					variant='outlined'
					label={option}
				/>
			</li>
		)
	}

	const handleInputTag = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		const isValidTag = validateTag(text)
		setError(!isValidTag)
		if (dateDiff > 100) {
			setCurrentText(text)
		}
	}

	const handleOnChangeValue = (value) => {
		const addMultiple = value.flatMap((item) => item.split(';'))
		if (addMultiple.length > tags.length) {
			setManualTags(getUniqueStrings(tags, addMultiple))
		}
		handleChangeTags(addMultiple)
	}

	const invalidateQueriesOnChangeCard = () => {
		queryClient.invalidateQueries('relevanceChange_useUpdateData')
	}

	return (
		<Collapse in={isOpen}>
			<Grid container style={style}>
				<Grid item xs={12}>
					<Autocomplete
						disabled={submitting}
						multiple
						freeSolo
						PaperComponent={CustomPaper}
						disableClearable
						value={tags}
						options={options}
						inputValue={currentText}
						onInputChange={(_, value) => handleInputTag(value)}
						onChange={(_, value) => handleOnChangeValue(value)}
						style={{ width: '100%' }}
						renderOption={optionRenderer}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<CssTextField
									error={error}
									onKeyDown={(event) => {
										if (!TAGS_REGEX.test(event.key)) {
											event.preventDefault()
										}
									}}
									inputRef={autocompleteRef}
									variant='standard'
									onFocus={loadTagHistory}
									label={
										<div style={{ display: 'flex' }}>
											{loading ? (
												<LoadingIcon
													size={20}
													thickness={1}
													style={{
														color: '#1d8cf8',
														transform: 'translate(0px, 2px)',
														marginRight: '7px'
													}}
												/>
											) : (
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<PlusIcon size={20} style={{ marginRight: '0.5em', color: blue }} />
													Adicionar tags
												</div>
											)}
										</div>
									}
									helperText={errorText}
									{...params}
								/>
							</div>
						)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									size='medium'
									style={{ color: white, backgroundColor: blue, borderColor: blue }}
									key={index}
									variant='outlined'
									label={<RendertagLabel tag={option} />}
									{...getTagProps({ index })}
									onDelete={(e) => {
										const props = getTagProps({ index })
										props.onDelete(e)
										lastOnDeleteTime = new Date().getTime()
									}}
								/>
							))
						}
					/>
					<div style={{ height: '1em', marginTop: '0.3em', color: grey }}>
						{currentText &&
							currentText !== '' &&
							'Pressione Enter para adicionar a tag escreva ( _ ) para adicionar sub-tags exemplo: tag_subtag'}
					</div>
				</Grid>
				{hasCollapseUp && (
					<Grid item xs={12}>
						<Button onClick={toggleTags} style={{ width: '100%', borderRadius: '0px 0px 25px 25px', height: '3em' }}>
							<ChevronUpIcon size={20} style={{ marginRight: '0.5em' }} />
						</Button>
					</Grid>
				)}
			</Grid>
		</Collapse>
	)
}

export default PostTags
