import React, { useState, useEffect, memo } from 'react'
import { Button, Grid, Card, Slide } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChevronLeftIcon, LoadingIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { GridLoader } from 'react-spinners'
import './Css/loader.css'
import api from 'service/service'
import logo from 'assets/img/react-logo-2.png'
import logoWhite from 'assets/img/whiteIcon.png'
import { connect } from 'react-redux'

window.Buffer = window.Buffer || require('buffer').Buffer

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 40%)'
const isFirefox = typeof InstallTrigger !== 'undefined'
const serviceName = 'voxIa'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		height: '30vh',
		width: '30vh',
		borderRadius: '30px',
		backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.1)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
		color: grey,
		background: 'transparent',
		'&:hover': {
			color: blue,
			backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.1)'}`,
			boxShadow: '0 30px 30px 0 rgba( 200, 200, 200, 0.5 )'
		}
	},
	buttonLoading: {
		fontFamily: 'Poppins',
		height: '30vh',
		width: '30vh'
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const renderButtonContent = (creditsLoading, creditCostAmount, remainingCredits, topicQuantity) => {
	const hasntEnoughCredits = remainingCredits < creditCostAmount
	let componentToRender = <></>

	if (creditsLoading) {
		componentToRender = <LoadingIcon size={60} thickness={1} style={{ color: '#ac60f7' }} alignCenter />
	} else {
		componentToRender = (
			<div>
				<img src={logo} style={{ width: '6vw' }} />
				<div>
					<span style={{ fontSize: '17px' }}>analisar até {topicQuantity} topicos</span>
					{hasntEnoughCredits ? (
						<div style={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
							<span style={{ marginLeft: '12px' }}>Não possui créditos suficientes</span>
						</div>
					) : (
						<p style={{ fontSize: '12px' }}>custará {creditCostAmount} de seus créditos</p>
					)}
				</div>
			</div>
		)
	}

	return componentToRender
}

const TopicAnalysisRequest = ({ cards, filters, open, SwitchOpenRequest, status, remainingCreditsRedux, changeStatus }) => {
	const [thisFilters, setThisFilters] = useState(filters)
	const [submitting, setSubmitting] = useState(false)
	const [creditsLoading, setCreditsLoading] = useState(true)
	const [creditCostAmount, setCreditCostAmount] = useState(null)
	const classes = useStyles()

	useEffect(() => {
		if (status) {
			setSubmitting(false)
		}
	}, [status])

	useEffect(() => {
		load()
	}, [])

	useEffect(() => {
		setThisFilters(filters)
	}, [filters])

	const load = () => {
		api.get(`/credits/get-cost-amount/${serviceName}`)
			.then((response) => {
				setCreditCostAmount(response.data)
				setCreditsLoading(false)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	const newTopicAnalysis = (version) => {
		const body = {
			configGlobalFilters: { cardIds: [cards[0].id], cardType: cards[0].type.toUpperCase() },
			globalFilters: thisFilters,
			componentProperties: {
				cardName: cards[0].name,
				version
			}
		}

		if (!submitting) {
			setSubmitting(true)
			api.post('/topic-analysis/new-analysis', body).catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
		}
	}

	return (
		<Slide direction='right' in={open} mountOnEnter unmountOnExit style={{ position: 'relative' }}>
			<Card
				style={{
					position: 'absolute',
					backdropFilter: 'blur(10px)',
					zIndex: '100',
					width: '100%',
					height: '100%',
					borderRadius: '20px',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.5)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}}
			>
				<Grid container style={{ height: '100%' }}>
					<Grid item xs={1}>
						<Button
							id='btn-toggle-analysis-request'
							color='primary'
							disabled={status === 'PROCESSING'}
							onClick={SwitchOpenRequest}
							style={{
								height: '100%',
								width: '40px',
								minWidth: '40px',
								borderRadius: '0px 0px 0px 0px',
								borderRight: '1px solid rgba(200,200,200, 0.2)'
							}}
						>
							{status !== 'PROCESSING' && <ChevronLeftIcon size={25} style={{ color: '#1d8cf8' }} />}
						</Button>
					</Grid>
					{(status === 'SUCCESS' || status === undefined) && (
						<Grid item xs={10} container style={{ height: '100%' }} justifyContent='space-around' alignItems='center'>
							<Grid item md={12} lg={4} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
								<Button
									className={classes.button}
									onClick={() => {
										changeStatus()
										newTopicAnalysis('LIGHT')
									}}
									disabled={submitting || creditsLoading || remainingCreditsRedux < creditCostAmount?.lightCreditCost}
								>
									{renderButtonContent(creditsLoading, creditCostAmount?.lightCreditCost, remainingCreditsRedux, '10')}
								</Button>
							</Grid>
							<Grid item md={12} lg={4} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
								<Button
									className={classes.button}
									onClick={() => {
										changeStatus()
										newTopicAnalysis('FULL')
									}}
									disabled={submitting || creditsLoading || remainingCreditsRedux < creditCostAmount?.fullCreditCost}
								>
									{renderButtonContent(creditsLoading, creditCostAmount?.fullCreditCost, remainingCreditsRedux, '30')}
								</Button>
							</Grid>
						</Grid>
					)}
					{status === 'PROCESSING' && (
						<Grid item xs={10} container style={{ height: '55vh', padding: '5%' }} justifyContent='space-around' alignItems='center'>
							<Grid item md={12} lg={4} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
								<Button className={classes.buttonLoading} disabled>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
											width: '100%',
											marginTop: '-34px'
										}}
									>
										<div className='orb'></div>
										<div style={{ position: 'absolute' }}>
											<img src={logoWhite} style={{ width: '100px' }} />
										</div>
										<div
											style={{
												position: 'absolute',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginBottom: '3px'
											}}
										>
											<GridLoader color={'rgba(255,255,255, 0.2)'} size={26} />
										</div>
										{/* <div
										style={{
											height: '1em',
											width: '5em',
											position: 'absolute',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: '9em'
										}}
									>
										<AiProgressBar inProgress={inProgress} handleReady={handleReady} />
									</div> */}
									</div>
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Card>
		</Slide>
	)
}

const mapStateToProps = ({ store }) => ({
	remainingCreditsRedux: store?.user?.creditInfo?.remainingMonth
})

export default connect(mapStateToProps)(memo(TopicAnalysisRequest))
