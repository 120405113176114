import React, { useEffect, useState, useRef, memo } from 'react'
import { Grid, Card, IconButton, Tooltip, Dialog, DialogContent, Menu, MenuItem, styled, Button } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { HideIcon, CloseIcon, CloudIcon, DownloadIcon, DotsVerticalIcon, DropIcon } from 'utils/SystemIcons'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import { connect } from 'react-redux'
import { getFirstCard } from 'components/Occurrences/utils/occurrencesFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { MuiColorInput } from 'mui-color-input'
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import api from 'service/service'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import InspectIntervalButton from 'graphs/components/InspectIntervalButton/InspectIntervalButton'
import OccurrencesCardSelector from 'components/Occurrences/components/FilterBar/components/OccurrencesCardSelector/OccurrencesCardSelector'
import HiddenList from './components/HiddenListDialog/HiddenList'
import SelectOptionList from 'components/SelectOptionList/SelectOptionList'
import constantsVox from 'constants-vox'
import NetworkGraphSkeletonLoad from 'components/SkeletonLoads/NetworkGraphSkeletonLoad/NetworkGraphSkeletonLoad'

const MuiColorInputStyled = styled(MuiColorInput)`
	& .MuiColorInput-Button {
		outline: none;
	}
`

const allTypesSelect = [
	{
		title: 'Palavras',
		type: 'words'
	},
	{
		title: 'Emojis',
		type: 'emojis'
	},
	{
		title: 'Bio',
		type: 'bio'
	},
	{
		title: 'Menções',
		type: 'mentions'
	},
	{
		title: 'Hashtags',
		type: 'hashtags'
	}
]

const isFirefox = typeof InstallTrigger !== 'undefined'

const WordClouds = ({
	cards,
	mainComparison = '',
	globalFiltersRedux,
	externalFilters,
	isDialog = true,
	isWidget = false,
	height,
	isCommentWordCloud = false
}) => {
	const [data, setData] = useState({ words: [], emojis: [], emojisCode: [], hashtags: [], mentions: [], bio: [] })
	const [wordCloudData, setWordCloudData] = useState([])
	const [loading, setLoading] = useState(false)
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, mainComparison))
	const [type, setType] = useState('words')
	const [allCardsSelect, setAllCardsSelect] = useState(cards)
	const [windowSize, setWindowSize] = useState({ width: '1770px', height: '750px' })
	const [selectedItemCloud, setSelectedItemCloud] = useState({ name: null, score: null, originalScore: null, originalName: null, type: null })
	const [isOpenDialog, setIsOpenDialog] = useState(false)
	const [isOpenDialogHidden, setIsOpenDialogHidden] = useState(false)
	const [hideListWords, setHideListWords] = useState([])
	const [thisChart, setThisChart] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const [color, setColor] = useState('#1d8cf8')
	const [isRandomColor, setIsRandomColor] = useState(true)
	const [openColorPicker, setOpenColorPicker] = useState(false)

	const isMounted = useRef(true)
	const cardRef = useRef(true)
	const loadingBarRef = useRef()

	const [thisFilters, setThisFilters] = useState(externalFilters || globalFiltersRedux)

	useEffect(() => {
		if (externalFilters) {
			setThisFilters(externalFilters)
		} else {
			setThisFilters(globalFiltersRedux)
		}
	}, [externalFilters, globalFiltersRedux])

	useEffect(() => {
		setAllCardsSelect(cards)
		setSelectedCard(getFirstCard(cards, mainComparison))
	}, [cards])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (
			(thisFilters.sources.length === 1 && thisFilters.sources.includes('news')) ||
			(type === 'bio' && ['facebook', 'instagram', 'tiktok', 'youtube'].some((it) => thisFilters.sources.includes(it)))
		) {
			setType('words')
		}
		load()
	}, [selectedCard, thisFilters, cards])

	useEffect(() => {
		if (!loading) {
			renderWordCloud(type)
		}
	}, [data, loading, type, cards, hideListWords])

	useEffect(() => {
		setWindowSize({ width: cardRef.current.offsetWidth, height: '750px' })
	}, [cardRef.current])

	useEffect(() => {
		if (!loading) {
			if (hideListWords.length == 0) {
				setIsOpenDialogHidden(false)
			}
			renderWordCloud(type)
		}
	}, [hideListWords])

	useEffect(() => {
		const chart = anychart.tagCloud(wordCloudData)

		const tooltip = isCommentWordCloud ? '{%value} comentários ({%yPercentOfTotal}%)' : '{%value} postagens ({%yPercentOfTotal}%)'

		chart.angles([0])
		chart.height('100%')
		chart.width(windowSize.width)
		chart.mode('spiral')
		if (!isRandomColor) {
			chart.normal().fill(color)
		}
		chart.tooltip().format(tooltip)
		chart.background({ fill: '#fff 0' })
		chart.listen('pointClick', (e) => {
			let name = e.point.get('x')
			let originalName = name
			let value = e.point.get('value')

			if (type === 'emojis') {
				originalName = data?.emojisCode[e.point.index][0]
			}
			setLoading(true)
			setTimeout(() => {
				setLoading(false)
			}, 1000)

			setSelectedItemCloud({ name, score: value, originalScore: value, originalName, type })
			setIsOpenDialog(true)
		})
		setThisChart(chart)
	}, [wordCloudData])

	const load = () => {
		setLoading(true)
		onPartialLoading(true)
		reset()
		const body = {
			configGlobalFilters: {
				cardType: selectedCard.type.toUpperCase(),
				cardIds: selectedCard.id
			},
			globalFilters: thisFilters
		}

		api.post('/word-clouds/get-cloud', body).then((res) => {
			if (isMounted.current) {
				setData(res.data)
				setLoading(false)
			}
		})
	}

	const destroyWord = (word) => {
		setHideListWords([...hideListWords, word])
	}

	const reset = async () => {
		setData({ words: [], emojis: [], emojisCode: [], hashtags: [], mentions: [], bio: [] })
		setHideListWords([])
	}

	const renderWordCloud = (type) => {
		let titleWords = selectedCard?.name?.match(/\w+/g).map((word) => word?.toLowerCase()) || []
		let termWords = selectedCard?.querySql?.match(/\w+/g).map((word) => word?.toLowerCase()) || []
		let filteredData

		filteredData = data[type]?.filter(
			(item) =>
				!hideListWords.includes(item[0].toString()) && !titleWords.includes(item[0].toString()) && !termWords.includes(item[0].toString())
		)

		setWordCloudData(filteredData)

		onPartialLoading(false)
	}

	const onCardChange = (newCard) => {
		setSelectedCard(newCard)
	}

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const onChangeType = (type) => {
		setType(type.type)
		renderWordCloud(type.type)
	}

	const handleClose = () => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 1000)
		setIsOpenDialog(false)
	}

	const handleHide = () => {
		destroyWord(selectedItemCloud.name)
		handleClose()
	}

	const handleRemoveWord = (name) => {
		const newList = hideListWords.filter((item) => item !== name)
		setHideListWords(newList)
	}

	const onAddPositiveText = () => {
		const formStateFormatted = {
			text: selectedItemCloud.name,
			cardId: selectedCard.id,
			type: constantsVox.MANUAL_CLASSIFICATION_DICTIONARY.POSITIVE
		}
		api.post('/card-term/manual-classification-dictionary/add-tags', formStateFormatted)
			.then(() => {
				sendMessageToast('Palavra positiva adicionada ao dicionário de classificação manual.', constantsVox.TOAST.TYPES.SUCCESS)
				setTimeout(() => renderWordCloud(type), 6000)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					setTimeout(() => renderWordCloud(type), 6000)
				}
			})
	}

	const onAddNegativeText = () => {
		const formStateFormatted = {
			text: selectedItemCloud.name,
			cardId: selectedCard.id,
			type: constantsVox.MANUAL_CLASSIFICATION_DICTIONARY.NEGATIVE
		}
		api.post('/card-term/manual-classification-dictionary/add-tags', formStateFormatted)
			.then(() => {
				sendMessageToast('Palavra negativa adicionada ao dicionário de classificação manual.', constantsVox.TOAST.TYPES.SUCCESS)
				setTimeout(() => renderWordCloud(type), 6000)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					setTimeout(() => renderWordCloud(type), 6000)
				}
			})
	}

	const savePng = () => {
		setLoading(true)
		thisChart.saveAsPng({ width: windowSize.width, height: 600, quality: 1, filename: 'Nuvem de palavras' })

		setTimeout(() => {
			setLoading(false)
		}, 1000)
	}

	const saveSvg = () => {
		setLoading(true)
		thisChart.saveAsSvg({ width: windowSize.width, height: 600, quality: 1, filename: 'Nuvem de palavras' })

		setTimeout(() => {
			setLoading(false)
		}, 1000)
	}

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget)
		renderWordCloud(type)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
		renderWordCloud(type)
	}

	const handleColorChange = (color) => {
		setColor(color)
	}

	return (
		<Grid item xs={isDialog || isWidget ? 12 : 6}>
			<Card ref={cardRef} style={{ borderRadius: isWidget ? '0px' : '20px' }}>
				{!isWidget && (
					<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={12}>
							<GraphTitle
								id='span-sentiments-nowcasting'
								title='Nuvens'
								icon={<CloudIcon size={25} style={{ color: '#1d8cf8' }} />}
								description={`
							Top 50 palavras mais utilizadas nas conversas, após tratamento de pronomes, artigos e advérbios. 
							Interaja com a palavra que te chama mais atenção para descobrir mais detalhes de uma narrativa. Opte também por visualizar
							 os top 50 emojis mais utilizados nas conversas e interaja com cada elemento para aprofundar sua análise. 
											`}
							/>
						</Grid>
					</Grid>
				)}
				<Grid container style={{ background: 'transparent' }}>
					<Grid item xs={4} container direction='row' justifyContent='flex-start' alignItems='center'>
						{allCardsSelect && (
							<Grid item xs={12} style={{ marginTop: '11px' }}>
								<OccurrencesCardSelector cards={allCardsSelect} onCardChange={onCardChange} selectedCard={selectedCard} />
							</Grid>
						)}
					</Grid>
					<Grid item xs={4} container justifyContent='center'></Grid>
					<Grid item xs={4} container justifyContent='flex-end'>
						<Grid item xs={6} style={{ marginTop: '4px', marginRight: '5px' }}>
							<SelectOptionList options={allTypesSelect} onOptionChange={onChangeType} selectedOption={type} filters={thisFilters} />
						</Grid>
						{hideListWords.length > 0 && (
							<Tooltip title='Elementos escondidos'>
								<IconButton
									onClick={() => {
										setIsOpenDialogHidden(true)
										renderWordCloud(type)
									}}
									style={{ marginRight: '5px', marginTop: '5px' }}
								>
									<HideIcon color={'#1d8cf8'} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title='Opções da nuvem'>
							<IconButton id='btn-open-menu-wordcloud' onClick={handleMenuClick} style={{ marginRight: '5px', marginTop: '5px' }}>
								<DotsVerticalIcon color={'#1d8cf8'} />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item xs={12}>
						<LoadingBar ref={loadingBarRef} />{' '}
					</Grid>
					<Grid item xs={12} style={{ width: windowSize.width, height: isDialog ? '600px' : '33em' }}>
						{!loading ? (
							!data || data[type]?.length == 0 ? (
								<div
									style={{
										width: windowSize.width,
										height: height || (isDialog ? '600px' : '500px'),
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontFamily: 'Poppins',
										fontSize: '24px',
										color: '#d6d6d6'
									}}
								>
									Sem dados para opção selecionada
								</div>
							) : (
								<AnyChart width={'100%'} height={height || (isDialog ? 600 : 500)} instance={thisChart} />
							)
						) : (
							<NetworkGraphSkeletonLoad />
						)}
					</Grid>
				</Grid>
			</Card>
			<Menu
				open={!!anchorEl}
				onClose={handleMenuClose}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				PaperProps={{
					style: {
						minWidth: '15em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.7)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				<MenuItem
					onClick={() => savePng()}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em', display: 'flex', alignItems: 'center' }}>
						<DownloadIcon size={20} color={'#1d8cf8'} style={{ marginRight: '8px' }} /> Baixar como PNG
					</span>
				</MenuItem>
				<MenuItem
					onClick={() => saveSvg()}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em', display: 'flex', alignItems: 'center' }}>
						<DownloadIcon size={20} color={'#1d8cf8'} style={{ marginRight: '8px' }} /> Baixar como SVG
					</span>
				</MenuItem>
				<MenuItem
					id='btn-menu-wordcloud-randomcolor'
					onClick={() => {
						setIsRandomColor(true)
						renderWordCloud(type)
					}}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em', display: 'flex', alignItems: 'center' }}>
						<DropIcon size={20} color={'#1d8cf8'} style={{ marginRight: '8px' }} /> Cores Aleatórias
					</span>
				</MenuItem>
				<MenuItem
					id='btn-menu-wordcloud-colorpicker'
					onClick={() => {
						setOpenColorPicker(!openColorPicker)
						setIsRandomColor(false)
						renderWordCloud(type)
					}}
					style={{ justifyContent: 'center', fontFamily: 'Poppins', fontWeight: '500', margin: '5px', borderRadius: '25px' }}
				>
					<span style={{ textTransform: 'uppercase', opacity: '0.65', fontSize: '0.95em', display: 'flex', alignItems: 'center' }}>
						<DropIcon size={20} color={'#1d8cf8'} style={{ marginRight: '8px' }} /> Selecionar cor
					</span>
				</MenuItem>
			</Menu>
			<Dialog
				open={openColorPicker}
				onClose={() => {
					setOpenColorPicker(false)
					renderWordCloud(type)
				}}
				fullWidth={true}
				maxWidth='xs'
				PaperProps={{
					style: {
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(255, 255, 255, 0.8)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.9)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						borderRadius: '20px'
					}
				}}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(2px)',
						backgroundColor: 'transparent'
					}
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ margin: '10px', borderRadius: '100px' }}>
						<MuiColorInputStyled value={color} onChange={handleColorChange} />
					</div>
					<div>
						<Button
							id='btn-wordcloud-colorpicker-apply'
							fullWidth
							onClick={() => {
								setIsRandomColor(false)
								setOpenColorPicker(false)
								renderWordCloud(type)
							}}
							style={{
								justifyContent: 'center',
								fontFamily: 'Poppins',
								fontWeight: '500',
								margin: '5px',
								borderRadius: '25px',
								width: '15em'
							}}
						>
							Aplicar
						</Button>
					</div>
				</div>
			</Dialog>
			<Dialog
				open={isOpenDialog}
				onClose={handleClose}
				fullWidth={true}
				maxWidth='sm'
				PaperProps={{
					style: {
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(255, 255, 255, 0.8)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.9)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						borderRadius: '20px'
					}
				}}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(2px)',
						backgroundColor: 'transparent'
					}
				}}
			>
				<DialogContent style={{ overflowY: 'hidden' }}>
					<Grid spacing={1} container direction='row'>
						<Grid item xs={6} container direction='column' alignItems='flex-start'>
							<Tooltip title='Esconder'>
								<IconButton onClick={() => handleHide()}>
									<HideIcon color={'#1d8cf8'} />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs={6} container direction='column' alignItems='flex-end'>
							<Tooltip title='Fechar'>
								<IconButton onClick={() => handleClose()}>
									<CloseIcon color={'#1d8cf8'} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid spacing={1} container direction='column' justifyContent='center' alignItems='center'>
						<Grid item style={{ marginBottom: '-15px' }}>
							<h3 style={{ fontFamily: 'Poppins', fontSize: '50px', color: '#1d8cf8' }}>{selectedItemCloud.name}</h3>
						</Grid>
						<Grid item>
							<h3 style={{ fontFamily: 'Poppins', fontSize: '30px', color: '#1d8cf8' }}>
								{selectedItemCloud.originalScore} {isCommentWordCloud ? 'Comentários' : 'Postagens'}
							</h3>
						</Grid>
						<Grid item container>
							<Grid xs={4} item container justifyContent='flex-start'>
								{selectedCard.type === 'term' && selectedItemCloud.type !== 'emojis' && (
									<Tooltip title='Marcar como positiva' placement='right'>
										<IconButton
											onClick={() => {
												onAddPositiveText()
												handleClose()
											}}
										>
											<ThumbUpOffAltIcon
												fontSize='small'
												style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'rgba(29, 138, 248, 100%)' }}
											/>
										</IconButton>
									</Tooltip>
								)}
							</Grid>
							<Grid item xs={4}>
								<InspectIntervalButton
									cards={cards}
									filters={thisFilters}
									filtersWordClouds={{ name: selectedItemCloud.originalName, type }}
									mainComparison={selectedCard.name}
									hideCardSelector={true}
									cardType={cards[0]?.type}
									showCloud={false}
									isCommentWordCloud={isCommentWordCloud}
									style={{ display: 'flex', justifyContent: 'center' }}
								/>
							</Grid>
							<Grid item container xs={4} justifyContent='flex-end'>
								{selectedCard.type === 'term' && selectedItemCloud.type !== 'emojis' && (
									<Tooltip title='Marcar como negativa' placement='left'>
										<IconButton
											onClick={() => {
												onAddNegativeText()
												handleClose()
											}}
										>
											<ThumbDownOffAltIcon
												fontSize='small'
												style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'rgba(255, 43, 107, 100%)' }}
											/>
										</IconButton>
									</Tooltip>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			{hideListWords.length > 0 && (
				<HiddenList
					isOpenDialogHidden={isOpenDialogHidden}
					setIsOpenDialogHidden={setIsOpenDialogHidden}
					hideListWords={hideListWords}
					handleRemoveWord={handleRemoveWord}
					onClose={() => renderWordCloud(type)}
				/>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(WordClouds))
