import React, { memo } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'
import moment from 'moment'
import constants from 'constants-vox'

const emptyProp = {
	options: {
		chart: {
			type: 'bubble'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const formatValue = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return value
		} else {
			return value?.toFixed(2)
		}
	} else {
		return null
	}
}

const formatBubbleSeries = (series) => {
	if (series) {
		return series.map((serie) => {
			return {
				name: serie.name,
				data: serie.data.map((data) => {
					return [data[0], data[1], data[1]]
				})
			}
		})
	}
}

const TagGraphBubble = ({ data, height = window.innerHeight / 1.2 - 120 }) => {
	const getOptions = () => {
		if (data?.options) {
			return {
				colors: [
					'#008FFB',
					'#00E396',
					'#FEB019',
					'#FF4560',
					'#775DD0',
					'#104469',
					'#3cb44b',
					'#ffe119',
					'#4363d8',
					'#f58231',
					'#911eb4',
					'#46f0f0',
					'#f032e6',
					'#bcf60c',
					'#fabebe',
					'#008080',
					'#e6beff',
					'#9a6324',
					'#fffac8',
					'#800000',
					'#aaffc3',
					'#808000',
					'#ffd8b1',
					'#000075',
					'#808080',
					'#e3e1e1',
					'#000000'
				],
				chart: {
					height: 350,
					type: 'bubble',
					toolbar: {
						show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: false,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom',
						export: {
							csv: {
								filename: 'voxradar_export',
								columnDelimiter: ',',
								dateFormatter(timestamp) {
									return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
								}
							}
						}
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth'
				},
				tooltip: {
					x: {
						format: 'dd/MM/yy HH:mm'
					}
				},
				xaxis: {
					type: 'datetime',
					labels: {
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						datetimeUTC: false
					}
				},
				yaxis: {
					labels: {
						show: true,
						align: 'right',
						minWidth: 0,
						maxWidth: 160,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						offsetX: -10,
						offsetY: 0,
						rotate: 0,
						formatter: (value) => formatValue(value)
					}
				},
				legend: {
					show: true,
					showForSingleSeries: true
				}
			}
		} else {
			return emptyProp.options
		}
	}

	return (
		<Grid style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
			<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>
				<Chart
					options={getOptions()}
					series={data?.series ? formatBubbleSeries(data?.series) : emptyProp.series}
					// series={series}
					type={'bubble'}
					width={'100%'}
					height={height}
				/>
			</div>
		</Grid>
	)
}

export default memo(TagGraphBubble)
