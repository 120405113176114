import React, { memo, useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import TopicAnalysis from 'components/TopicAnalysis/TopicAnalysis'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { AllSourcesIcon } from 'utils/SystemIcons'
import AiAnalysis from 'components/AiAnalysis/AiAnalysis'

const emptyMessage = (cards) => (
	<div
		style={{
			height: '40vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontFamily: 'Poppins',
			fontSize: '24px',
			color: '#bdbbbb'
		}}
	>
		{cards.length > 1 ? 'Não disponível para comparações' : 'Componente não disponível para a fonte escolhida (Notícias)'}
	</div>
)

const VoxRadarIaContent = ({ cardStatus, cards, isFetchingData, source }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube() || source.includes('news')) && (
				<Grid container item xs={12}>
					<Card style={{ height: '100%', width: '100%', borderRadius: '20px', position: 'relative', backgroundColor: 'transparent' }}>
						<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
							<GraphTitle id='span-gender-age' title='Análise com IA' icon={<AllSourcesIcon size={25} color={'#1d8cf8'} />} />
						</Grid>
						{cards.length > 1 || (source.length === 1 && source.includes('news')) ? (
							emptyMessage(cards)
						) : (
							<AiAnalysis cardStatus={cardStatus} cards={cardList} isFetchingData={isFetchingData} />
						)}
					</Card>
				</Grid>
			)}
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube() || source.includes('news')) && (
				<Grid container item xs={12}>
					<Card style={{ height: '100%', width: '100%', borderRadius: '20px', position: 'relative', backgroundColor: 'transparent' }}>
						<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
							<GraphTitle id='span-gender-age' title='Principais tópicos' icon={<AllSourcesIcon size={25} color={'#1d8cf8'} />} />
						</Grid>
						{cards.length > 1 || (source.length === 1 && source.includes('news')) ? (
							emptyMessage(cards)
						) : (
							<TopicAnalysis cardStatus={cardStatus} cards={cardList} isFetchingData={isFetchingData} />
						)}
					</Card>
				</Grid>
			)}
		</Grid>
	)
}

export default memo(VoxRadarIaContent)
