//DAVI: Esse é o componente novo de media viewer, o antigo é o MultipleImageView,
//tava muito acoplado, então resolvi refatorar e criar um novo componente, mas não mexer no antigo por enquanto
import React, { useState } from 'react'
import { Button, Dialog, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const grey = 'rgba(184, 184, 184, 100%)'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const isVideo = (url) => {
	return !!url?.match(/\.(mp4|webm|ogg)$/i)
}

const isImage = (url) => {
	return !!url?.match(/\.(jpeg|jpg|gif|png|bmp|svg|webp)$/i)
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const MultipleMediaViewer = ({ multipleMedias, containerStyle = {}, objectFit = 'cover' }) => {
	const [openDialogMedia, setOpenDialogMedia] = useState(false)
	const classes = useStyles()
	const firstMedia = multipleMedias && multipleMedias[0]?.url

	const handleOnCloseImage = () => {
		setOpenDialogMedia(!openDialogMedia)
	}

	return (
		<>
			{multipleMedias && multipleMedias.length > 0 && (
				<>
					<Grid item xs={12} container justifyContent='flex-end' style={containerStyle}>
						<Button
							onClick={() => {
								setOpenDialogMedia(!openDialogMedia)
							}}
							style={{ borderRadius: '20px', maxHeight: '200px' }}
						>
							{isVideo(firstMedia) && (
								<video
									src={firstMedia}
									style={{
										width: '100%',
										height: '100%',
										objectFit: objectFit,
										borderRadius: '20px',
										marginBlock: 'auto'
									}}
									muted
								/>
							)}
							{isImage(firstMedia) && (
								<img
									src={firstMedia}
									style={{
										width: '100%',
										height: '100%',
										objectFit: objectFit,
										borderRadius: '20px',
										marginBlock: 'auto'
									}}
								/>
							)}
							{!isVideo(firstMedia) && !isImage(firstMedia) && 'Mídia com formato indisponível'}
						</Button>
					</Grid>
					{openDialogMedia && (
						<Dialog
							style={{ margin: 'auto' }}
							id='dialog-app'
							open={openDialogMedia}
							onClose={handleOnCloseImage}
							BackdropProps={{ classes: { root: classes.backDrop } }}
							PaperProps={{
								style: {
									borderRadius: '20px',
									backdropFilter: 'blur(10px)',
									backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
									border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
									boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
								}
							}}
						>
							<Carousel showArrows={true} showThumbs={false} showStatus={false}>
								{multipleMedias.map((media) => (
									<div key={media.url}>
										{isVideo(media.url) && (
											<span style={{ backgroundColor: 'black' }}>
												<video
													src={media.url}
													controls
													style={{
														maxHeight: '40em',
														maxWidth: '40em',
														marginBlock: 'auto'
													}}
												/>
												<div style={{ height: '1em', width: '100%', backgroundColor: 'black' }} />
											</span>
										)}
										{isImage(media.url) && (
											<img
												src={media.url}
												style={{
													maxHeight: '40em',
													maxWidth: '40em',
													borderRadius: '20px',
													marginBlock: 'auto'
												}}
											/>
										)}
										{!isVideo(media.url) && !isImage(media.url) && 'Mídia com formato indisponível'}
									</div>
								))}
							</Carousel>
						</Dialog>
					)}
				</>
			)}
		</>
	)
}

export default MultipleMediaViewer
