import React, { memo, useState } from 'react'
import { Grid, Button, Collapse, Tooltip } from '@mui/material'
import { DeleteBinIcon, CheckIcon, CloseIcon, DownloadIcon, MailIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'

const ExportPostsOccurrencesHistoryItem = ({ item }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const toggleConfirmDelete = () => {
		setTimeout(() => {
			setConfirmDelete(!confirmDelete)
		}, 300)
	}

	const handleConfirmClick = (e, id) => {
		e.preventDefault()
		setTimeout(() => {
			setSubmitting(true)
			removeExport(e, id)
		}, 300)
	}

	const onClickExport = (url) => {
		if (item?.type === 'email') {
			const cardTermExportId = item?.id
			const type = 'email'

			api.post('/occurrences/export-posts-history', {
				cardTermExportId,
				type
			})
				.then((response) => {
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
		} else {
			const link = document.createElement('a')
			link.href = url
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	const removeExport = async (event, exportId) => {
		event.preventDefault()
		api.post('/occurrences/remove-exported-post', {
			exportId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	return (
		<Grid item xs={12}>
			<Collapse in={!submitting} timeout={300}>
				<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em', width: '100%' }}>
					<Tooltip title={item?.export_name} placement='bottom-start' enterDelay={500} enterNextDelay={500}>
						<Button
							id={`btn-load-item-${item?.export_name}`}
							disabled={submitting}
							onClick={() => {
								onClickExport(item?.url)
							}}
							style={{
								maxHeight: '37px',
								height: '37px',
								backgroundColor: '#fff',
								borderRadius: '25px 0px 0px 25px',
								width: `${confirmDelete ? '45.4em' : '50em'}`,
								display: 'flex',
								justifyContent: 'flex-start',
								alignContent: 'flex-start',
								marginLeft: '3em'
							}}
						>
							<div
								style={{
									width: '100%',
									color: 'rgba(100,100,100, 0.9)',
									marginLeft: '3px',
									display: 'flex',
									justifyContent: 'flex-start',
									alignContent: 'flex-start'
								}}
							>
								<div style={{ marginRight: '8px', marginTop: '3px' }}>
									{item?.type === 'email' ? (
										<MailIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
									) : (
										<DownloadIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
									)}
								</div>
								<div
									style={{
										fontFamily: 'Poppins',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										fontSize: '13px',
										textOverflow: 'ellipsis',
										textAlign: 'left',
										marginTop: '4px'
									}}
								>
									{item?.export_name}
								</div>
							</div>
						</Button>
					</Tooltip>
					{!confirmDelete && (
						<Button
							id={`btn-delete-item-${item?.title}`}
							disabled={submitting}
							onClick={toggleConfirmDelete}
							style={{
								height: '37px',
								color: 'rgba(100,100,100, 0.9)',
								backgroundColor: '#fff',
								borderRadius: '0px 25px 25px 0px',
								fontFamily: 'Poppins',
								width: '2em'
							}}
						>
							<DeleteBinIcon size={20} style={{ color: 'rgba(100,100,100, 0.9)' }} />
						</Button>
					)}
					{confirmDelete && (
						<div style={{ width: '2em', display: 'flex' }}>
							<Button
								id={`btn-confirm-delete-item-${item?.title}`}
								disabled={submitting}
								onClick={(e) => handleConfirmClick(e, item.id)}
								style={{
									color: '#1d8cf8',
									backgroundColor: '#fff',
									borderRadius: '0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CheckIcon size={25} style={{ color: '#1d8cf8' }} />
							</Button>
							<Button
								id={`btn-cancel-delete-item-${item?.title}`}
								disabled={submitting}
								onClick={toggleConfirmDelete}
								style={{
									color: '#f50057',
									backgroundColor: '#fff',
									borderRadius: '0px 25px 25px 0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CloseIcon size={25} style={{ color: '#f50057' }} />
							</Button>
						</div>
					)}
				</Grid>
			</Collapse>
		</Grid>
	)
}

export default memo(ExportPostsOccurrencesHistoryItem)
