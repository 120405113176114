import React, { memo } from 'react'
import Chart from 'react-apexcharts'

let buildRadarOptions = (colors, labels) => ({
	chart: {
		dropShadow: {
			enabled: true,
			blur: 1,
			left: 1,
			top: 1
		}
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	markers: {
		size: 4,
		colors: colors,
		strokeColor: colors,
		strokeWidth: 2
	},
	tooltip: {
		y: {
			formatter: function (val) {
				return val
			}
		}
	},
	stroke: {
		width: 2
	},
	fill: {
		opacity: 0.1
	},
	xaxis: {
		categories: labels,
		labels: {
			show: true,
			style: {
				colors: ['#000', '#000', '#000', '#000', '#000', '#000', '#000'],
				fontSize: '12px',
				fontFamily: 'Poppins',
				fontWeight: 500,
				cssClass: 'apexcharts-xaxis-label'
			}
		}
	},
	yaxis: {
		labels: {
			formatter: function (val) {
				return Math.round(val * 10) / 10
			}
		}
	}
})

const RadarChart = ({ series, height, width, colors, labels }) => {
	return <Chart options={buildRadarOptions(colors, labels)} series={series} width={width} height={height} type='radar' />
}

export default memo(RadarChart)
