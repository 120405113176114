import React, { useState, memo } from 'react'
import api from 'service/service'
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material'
import NewMonitoringDialogTerm from './components/NewMonitoringDialogTerm/NewMonitoringDialogTerm'
import NewMonitoringDialogProfile from './components/NewMonitoringDialogProfile/NewMonitoringDialogProfile'
import NewMonitoringDialogGroup from './components/NewMonitoringDialogGroup/NewMonitoringDialogGroup'
import NewMonitoringDialogReportTwitter from './components/NewMonitoringDialogReportTwitter/NewMonitoringDialogReportTwitter'
import { CloseIcon, CardIcon, AlertIconSimple } from 'utils/SystemIcons'

const DefineLimitSourceText = (type) => {
	switch (type) {
		case 'term':
			return 'de tema'
		case 'profile':
			return 'de conta'
		case 'report':
			return 'do Twitter'
	}
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const ButtonModalNewInsight = ({ onSubmit, type, cards, disabled = false, canCreate, createByOnlyGroupName }) => {
	const [newCardModal, setNewCardModal] = useState(false)
	const [newInfoModal, setNewInfoModal] = useState(false)
	const [error, setError] = useState('')

	let color = type === 'term' ? '#1d8cf8' : type === 'profile' ? '#ac60f7' : type === 'group' ? '#d41993' : '#18c1c4'

	const toggleNewCardModal = () => {
		if (type === 'term') {
			setNewCardModal(!newCardModal)
		} else {
			api.get(`/user/user-settings/${type}`)
				.then(() => {
					setNewCardModal(!newCardModal)
				})
				.catch((error) => {
					setError(error?.response?.data?.toast?.message)
					setNewInfoModal(true)
				})
				.finally()
		}
	}

	const closeNewMonitoringDialog = () => {
		setNewCardModal(false)
	}

	const handleClose = () => {
		setNewInfoModal(false)
	}

	return (
		<>
			<Button className='outline-box-shadow-focus-none button-new-card-sentiment-analysis' onClick={toggleNewCardModal} disabled={disabled}>
				Novo +
			</Button>
			{newInfoModal && (
				<Dialog
					open={newInfoModal}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogTitle id='alert-dialog-title'>
						<Grid container direction='row'>
							<Grid item xs={11} container justifyContent='flex-start' alignItems='center'>
								<CardIcon style={{ marginRight: '0px', color }} />
								<AlertIconSimple style={{ marginLeft: '-5px', marginRight: '10px', color }} />
								Limite de cartões {DefineLimitSourceText(type)} atingido
							</Grid>
							<Grid spacing={1} item xs={1} container direction='row' justifyContent='flex-end' alignItems='center'>
								<Tooltip title='Fechar'>
									<IconButton onClick={() => handleClose()} style={{ marginTop: '-4px', marginRight: '-20px' }}>
										<CloseIcon color={color} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</DialogTitle>

					<DialogContent>
						<DialogContentText id='alert-dialog-description'>{error}</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
			{type === 'term' && newCardModal && canCreate && <NewMonitoringDialogTerm onSubmit={onSubmit} close={closeNewMonitoringDialog} />}
			{type === 'profile' && newCardModal && canCreate && <NewMonitoringDialogProfile onSubmit={onSubmit} close={closeNewMonitoringDialog} />}
			{type === 'group' && newCardModal && canCreate && (
				<NewMonitoringDialogGroup
					onSubmit={onSubmit}
					close={closeNewMonitoringDialog}
					cards={cards}
					createByOnlyGroupName={createByOnlyGroupName}
				/>
			)}
			{type === 'report' && newCardModal && canCreate && (
				<NewMonitoringDialogReportTwitter onSubmit={onSubmit} close={closeNewMonitoringDialog} cards={cards} />
			)}
		</>
	)
}

export default memo(ButtonModalNewInsight)
