import React, { useState, memo, useEffect } from 'react'
import { Collapse } from '@mui/material'
import { connect } from 'react-redux'
import IconSourceButtonGroup from './components/IconSourceButtonGroup/IconSourceButtonGroup'

const SourceSelectorGlobalFilters = ({
	onSourceChange,
	sourcesControlled,
	isOverview = false,
	cardTypeRedux,
	comparisonStatusControlled,
	comparisonStatusRedux,
	singleSourceSelection
}) => {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		let comparisonStatus
		if (!comparisonStatusControlled) {
			comparisonStatus = { ...comparisonStatusRedux }
		} else {
			comparisonStatus = { ...comparisonStatusControlled }
		}
		delete comparisonStatus.general
		const activeSources = Object.keys(comparisonStatus).filter((key) => comparisonStatus[key] === 'S')
		let newSourcesToChange = null
		if (!sourcesControlled.every((it) => activeSources.includes(it))) {
			if (singleSourceSelection) {
				newSourcesToChange = [activeSources[0]]
			} else {
				newSourcesToChange = activeSources
			}
		}

		if (newSourcesToChange) {
			handleSelect(newSourcesToChange)
		}
		setLoading(false)
	}, [comparisonStatusRedux])

	const handleSelect = (newValue) => {
		onSourceChange(newValue)
	}

	return (
		<>
			<Collapse in={!isOverview}>
				{!loading && (
					<IconSourceButtonGroup
						sourcesStatus={comparisonStatusControlled || comparisonStatusRedux}
						selectedSources={sourcesControlled}
						cardType={cardTypeRedux}
						onChange={handleSelect}
						singleSourceSelection={singleSourceSelection}
					/>
				)}
			</Collapse>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	comparisonStatusRedux: store.card.comparison.status,
	cardTypeRedux: store.card.cardType
})

export default connect(mapStateToProps)(memo(SourceSelectorGlobalFilters))
