import React, { useEffect, useRef, memo } from 'react'
import { Grid } from '@mui/material'
import AnalysisCard from 'components/AnalysisCard/AnalysisCard'
import MonitoringCardsSkeletonLoad from 'components/SkeletonLoads/MonitoringCardsSkeletonLoad/MonitoringCardsSkeletonLoad'

const AnalysisCardContainer = ({
	filterNameCard,
	folder,
	page,
	refetchItems,
	items,
	isLoading,
	permissionCreateCard,
	permissionRemoveCard,
	isUserAdmin,
	termsRemainingMonth = null
}) => {
	const scrollableDiv = useRef(null)

	useEffect(() => {
		if (scrollableDiv.current !== null) {
			scrollableDiv.current.scrollTop = 0
		}
	}, [page])

	return (
		<Grid
			ref={scrollableDiv}
			id='scrollableDiv'
			container
			spacing={3}
			style={{
				minHeight: '68vh',
				maxHeight: '68vh',
				marginBottom: '1px',
				overflowY: 'auto',
				overflowX: 'hidden',
				paddingLeft: '1em',
				paddingRight: '1em',
				paddingBottom: '1em'
			}}
		>
			{isLoading ? (
				<MonitoringCardsSkeletonLoad />
			) : (
				items
					.filter(
						(p) =>
							p.name
								?.replace(/,/g, ' ')
								.toLowerCase()
								.indexOf((filterNameCard || '').toString().toLowerCase()) > -1
					)
					.map((it) => (
						<Grid item key={it.id} lg={3} md={6} sm={12}>
							<AnalysisCard
								folder={folder}
								refetchItems={refetchItems}
								item={it}
								permissionCreateCard={permissionCreateCard}
								permissionRemoveCard={permissionRemoveCard}
								isUserAdmin={isUserAdmin}
								termsRemainingMonth={termsRemainingMonth}
							/>
						</Grid>
					))
			)}
		</Grid>
	)
}

export default memo(AnalysisCardContainer)
