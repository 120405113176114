import React, { memo, useState, useRef } from 'react'
import { ListItemIcon, Menu, MenuItem, MenuList, Paper, Typography, Fade, Divider } from '@mui/material'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import constantsVox from 'constants-vox'
import { ForbiddenIcon } from 'utils/SystemIcons'
import { useQueryClient } from 'hooks/ReactQueryHooks'

const isFirefox = typeof InstallTrigger !== 'undefined'

const getContextMenu = (contextMenu, event) => {
	if (contextMenu === null) {
		return {
			mouseX: event.clientX + 2,
			mouseY: event.clientY - 6
		}
	} else {
		return null
	}
}

const SelectText = ({ children, cardId, onStopWord }) => {
	const [text, setText] = useState('')
	const [contextMenu, setContextMenu] = useState(null)
	const ref = useRef()
	const queryClient = useQueryClient()

	const onContainerClick = (event) => {
		event.preventDefault()
		let selectedText = document.getSelection().toString()
		if (selectedText && selectedText !== '' && selectedText.trim().length !== 0) {
			setText(selectedText)
			setContextMenu(getContextMenu(contextMenu, event))
		} else {
			setText('')
		}
	}

	const handleClose = () => {
		setContextMenu(null)
		setText('')
	}

	const onAddPositiveText = (text) => {
		const formStateFormatted = {
			text: text,
			cardId: cardId,
			type: constantsVox.MANUAL_CLASSIFICATION_DICTIONARY.POSITIVE
		}
		api.post('/card-term/manual-classification-dictionary/add-tags', formStateFormatted)
			.then(() => {
				sendMessageToast('Palavra positiva adicionada ao dicionário de classificação manual.', constantsVox.TOAST.TYPES.SUCCESS)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const onAddNegativeText = (text) => {
		const formStateFormatted = {
			text: text,
			cardId: cardId,
			type: constantsVox.MANUAL_CLASSIFICATION_DICTIONARY.NEGATIVE
		}
		api.post('/card-term/manual-classification-dictionary/add-tags', formStateFormatted)
			.then(() => {
				sendMessageToast('Palavra negativa adicionada ao dicionário de classificação manual.', constantsVox.TOAST.TYPES.SUCCESS)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const handleStopWord = (text) => {
		let newText = text.trim().toLowerCase()
		if (newText) {
			const formStateFormatted = {
				text: newText,
				cardId: cardId
			}
			api.post('/card-term/stop-words/add-words', formStateFormatted)
				.then(() => {
					invalidateQueriesOnChangeCard()
					onStopWord(newText)
					sendMessageToast('Palavra retirada da pesquisa.', constantsVox.TOAST.TYPES.SUCCESS)
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
		}
	}

	const invalidateQueriesOnChangeCard = () => {
		queryClient.invalidateQueries('relevanceChange_useUpdateData')
	}

	return (
		<div>
			<div ref={ref.current} onClick={onContainerClick}>
				{children}
			</div>
			<Paper sx={{ width: 230 }}>
				<Menu
					open={contextMenu !== null}
					onClose={handleClose}
					anchorReference='anchorPosition'
					anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
					TransitionComponent={Fade}
					PaperProps={{
						style: {
							minWidth: '15em',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
				>
					<MenuList>
						<MenuItem disabled>
							<Typography variant='inherit' style={{ fontFamily: 'Roboto', fontSize: '16px', color: 'black', fontWeight: 'bold' }}>
								{text}
							</Typography>
						</MenuItem>
						<Divider variant='middle' light />
						<MenuItem
							onClick={() => {
								onAddPositiveText(text)
								handleClose()
							}}
						>
							<ListItemIcon>
								<ThumbUpOffAltIcon
									fontSize='small'
									style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'rgba(29, 138, 248, 100%)' }}
								/>
							</ListItemIcon>
							<Typography variant='inherit' style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'rgba(29, 138, 248, 100%)' }}>
								Adicionar como positiva
							</Typography>
						</MenuItem>
						<MenuItem
							onClick={() => {
								onAddNegativeText(text)
								handleClose()
							}}
						>
							<ListItemIcon>
								<ThumbDownOffAltIcon
									fontSize='small'
									style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'rgba(255, 43, 107, 100%)' }}
								/>
							</ListItemIcon>
							<Typography variant='inherit' style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'rgba(255, 43, 107, 100%)' }}>
								Adicionar como negativa
							</Typography>
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleStopWord(text)
								handleClose()
							}}
						>
							<ListItemIcon>
								<ForbiddenIcon fontSize='small' style={{ fontFamily: 'Poppins', fontSize: '16px', color: 'default' }} />
							</ListItemIcon>
							<Typography variant='inherit' style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'default' }}>
								Ocultar postagens contendo este texto
							</Typography>
						</MenuItem>
					</MenuList>
				</Menu>
			</Paper>
		</div>
	)
}

export default memo(SelectText)
