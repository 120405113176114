import React, { useState, useEffect, memo } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Card, styled } from '@mui/material'
import { LoadingIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const StyledSelect = styled(Select)({
	color: '#1d8cf8',
	'&::after': {
		borderBottom: '2px solid #1d8cf8'
	},
	'&::before': {
		borderBottom: '2px solid transparent'
	}
})

const StyledContainedSelect = styled(Select)({
	borderRadius: '100px',
	color: '#1d8cf8'
})

const StyledInputLabel = styled(InputLabel)({
	color: 'darkgrey',
	'&.Mui-focused': {
		color: 'darkgrey'
	}
})

const SelectVox = ({
	items,
	onChange,
	label,
	hasEmpty,
	initialValue,
	emptyLabel,
	isLoading,
	style,
	variant = 'outlined',
	isCard = false,
	size = 'medium',
	id = ''
}) => {
	const [selectedValue, setSelectedValue] = useState(initialValue || 'loading')
	const [loading, setLoading] = useState(isLoading)

	useEffect(() => {
		if (items.length > 0 && selectedValue !== 'loading') {
			onChange(items.find((it) => it.value === selectedValue) || '')
		}
	}, [selectedValue, items])

	useEffect(() => {
		setSelectedValue(initialValue)
	}, [initialValue])

	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	const handleChangeSelect = (e) => {
		setSelectedValue(e.target.value)
	}

	return (
		<div style={{ ...style }}>
			{loading ? (
				<div
					style={{
						color: '#1d8cf8',
						justifyContent: 'center',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<LoadingIcon size={30} thickness={2} />
				</div>
			) : (
				<FormControl variant={variant} style={{ width: '100%' }} size={size}>
					<StyledInputLabel htmlFor='outlined-age-native-simple'>{label}</StyledInputLabel>
					{isCard && (
						<Card
							elevation={0}
							style={{
								borderRadius: '10px 10px 10px 10px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 0 5px 0 rgba( 200, 200, 200, 0.7 )'
							}}
						>
							<StyledSelect
								style={{ backgroundColor: 'transparent', width: '100%' }}
								disableUnderline
								MenuProps={{
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left'
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left'
									},
									PaperProps: {
										style: {
											marginTop: '-7px',
											marginLeft: '-1px',
											borderRadius: '0px 0px 10px 10px',
											backdropFilter: 'blur(10px)',
											backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
											borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
											boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
										}
									}
								}}
								value={selectedValue === 'loading' ? '' : selectedValue}
								onChange={handleChangeSelect}
								label={label}
							>
								{hasEmpty && <MenuItem value=''>{emptyLabel}</MenuItem>}
								{items.map((item) => {
									return (
										<MenuItem key={item.value} value={item.value}>
											{item.label}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Card>
					)}
					{!isCard && (
						<StyledContainedSelect
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								PaperProps: {
									style: {
										borderRadius: '25px',
										backdropFilter: 'blur(10px)',
										backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
										borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
									}
								}
							}}
							value={selectedValue === 'loading' ? '' : selectedValue}
							onChange={handleChangeSelect}
							label={label}
							id={id}
						>
							{hasEmpty && <MenuItem value=''>{emptyLabel}</MenuItem>}
							{items.map((item) => {
								return (
									<MenuItem key={item.value} value={item.value}>
										{item.label}
									</MenuItem>
								)
							})}
						</StyledContainedSelect>
					)}
				</FormControl>
			)}
		</div>
	)
}

export default memo(SelectVox)
