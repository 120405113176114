import React, { useState, memo, useRef, useEffect } from 'react'
import { Grid, Tabs, Tab, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FiMapPin } from 'react-icons/fi'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import LocationGraph from './components/LocationGraph/LocationGraph'
// import LocationStateGraph from './components/LocationStateGraph/LocationStateGraph'

import Chart from 'react-apexcharts'
import ComponentCard from 'components/ComponentCard/ComponentCard'
import { MapPinIcon } from 'utils/SystemIcons'
import { useUpdateData } from 'hooks/ReactQueryHooks'

const blue = '#1d8af8'
const grey = '#828282'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const volumePieOptions = (categories) => ({
	colors: [
		'#e6194b',
		'#3cb44b',
		'#ffe119',
		'#4363d8',
		'#f58231',
		'#911eb4',
		'#46f0f0',
		'#f032e6',
		'#bcf60c',
		'#fabebe',
		'#008080',
		'#e6beff',
		'#9a6324',
		'#fffac8',
		'#800000',
		'#aaffc3',
		'#808000',
		'#ffd8b1',
		'#000075',
		'#808080',
		'#e3e1e1',
		'#000000'
	],
	chart: {
		type: 'donut'
	},
	labels: categories,
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	legend: {
		show: true,
		position: 'right'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
})

const getTitle = (locationView) => {
	switch (locationView) {
		case 'volume':
			return 'Volume'
		case 'gender':
			return 'Gênero'
		case 'impact':
			return 'Sentimento'
		case 'age':
			return 'Idade'
		default:
			break
	}
}

const LocationStateAndRegionGraph = ({ cards, isFetchingData, filters, initialLocationView, isReport, hasReportTitle }) => {
	const [locationView, setLocationView] = useState(initialLocationView || 'volume')
	const classes = useStyles()
	const isMounted = useRef(true)
	const [errorFilterComponent, setErrorFilterComponent] = useState(null)
	const [thisFilters, setThisFilters] = useState(filters)
	const [fullScreenState, setFullScreenState] = useState(false)

	useEffect(() => {
		if (!isReport) {
			setThisFilters(filters)
		}
	}, [filters])

	useEffect(() => {
		isMounted.current = true
		if (
			(thisFilters.sources.length === 1 && (thisFilters.sources.includes('news') || thisFilters.sources.includes('youtube'))) ||
			(thisFilters.sources.length === 2 && thisFilters.sources.includes('news') && thisFilters.sources.includes('youtube'))
		) {
			setErrorFilterComponent('Componente não disponível para a fonte escolhida.')
		} else {
			setErrorFilterComponent(null)
		}

		return () => {
			isMounted.current = false
		}
	}, [thisFilters.sources])

	const getBody = (locationType) => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: thisFilters,
		componentProperties: {
			locationType,
			locationView
		}
	})

	let stateData = useUpdateData({
		url: '/location/get-location-graph-all',
		updateItemName: 'LocationState' + cards?.map((card) => card.id),
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'useUpdateData',
		method: 'post',
		body: getBody('state')
	})

	let regionData = useUpdateData({
		url: '/location/get-location-graph-all',
		updateItemName: 'LocationRegion' + cards?.map((card) => card.id),
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'useUpdateData',
		method: 'post',
		body: getBody('region')
	})

	const canLoadPieGraphState = () => locationView === 'volume' && stateData?.data?.series?.length === 1
	const canLoadPieGraphRegion = () => locationView === 'volume' && regionData?.data?.series?.length === 1

	const handleChangeLocationView = (_, value) => {
		setLocationView(value)
	}

	const handleChangeFullScreen = (newState) => {
		setFullScreenState(newState)
	}

	const isSoftLoad = () => (stateData.isFetching || regionData.isFetching) && (stateData.isFetched || regionData.isFetched)

	useEffect(() => {
		if (locationView === 'age') {
			setLocationView('volume')
		}
	}, [thisFilters.sources])

	return (
		<>
			{!isReport ? (
				<ComponentCard
					style={{ width: '100%' }}
					isLoading={isSoftLoad()}
					title={{
						name: 'Localidade Geral',
						icon: (
							<MapPinIcon
								size='20'
								style={{
									color: '#1d8cf8',
									marginTop: '0.15em'
								}}
							/>
						)
						// ,description: 'adicionar teste'
					}}
					onChangeFullScreen={handleChangeFullScreen}
				>
					{!errorFilterComponent ? (
						<>
							<Grid container spacing={0} style={{ margin: '10px' }}>
								<Grid item xs={6}>
									<Tabs
										value={locationView}
										onChange={handleChangeLocationView}
										TabIndicatorProps={{ style: { background: blue } }}
									>
										<Tab
											id='tab-location-volume'
											label='Volume'
											value='volume'
											style={{ outline: 'none' }}
											className={classes.tab}
										/>
										<Tab
											id='tab-location-impact'
											label='Sentimento'
											value='impact'
											style={{ outline: 'none' }}
											className={classes.tab}
										/>
										<Tab
											id='tab-location-gender'
											label='Gênero'
											value='gender'
											style={{ outline: 'none' }}
											className={classes.tab}
										/>
										<Tab
											id='tab-location-age'
											label='Idade'
											value='age'
											style={{ outline: 'none' }}
											className={classes.tab}
											disabled={!thisFilters.sources.includes('twitter')}
										/>
									</Tabs>
								</Grid>
							</Grid>
							<Divider style={{ marginTop: '-10px', marginBottom: '20px' }} />
							<Grid
								container
								style={{
									marginTop: '1em',
									paddingLeft: '1em',
									paddingRight: '1em',
									// overflow: `${fullScreenState ? '100vh' : '1vh'}`,
									overflow: 'auto',
									height: `${fullScreenState ? '100%' : '85vh'}`
								}}
							>
								<Grid container item xs={12}>
									<Grid item xs={12}>
										<GraphTitle id='span-location-region' title='Região' />
									</Grid>
									<Grid item xs={canLoadPieGraphRegion() ? 7 : 12}>
										<LocationGraph
											data={regionData.data}
											viewType={locationView}
											loading={!regionData.isFetched && regionData.isFetching}
										/>
									</Grid>
									{canLoadPieGraphRegion() && (
										<Grid item xs={5} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
											<Chart
												options={volumePieOptions(regionData.data?.categories) || {}}
												series={regionData.data?.series[0]?.data || []}
												type='donut'
												width='100%'
												height={350}
											/>
										</Grid>
									)}
								</Grid>
								<Grid container item xs={12}>
									<Grid item xs={12}>
										<GraphTitle id='span-location-state' title='Estado' />
									</Grid>
									<Grid item xs={canLoadPieGraphState() ? 7 : 12}>
										<LocationGraph
											data={stateData.data}
											viewType={locationView}
											loading={!stateData.isFetched && stateData.isFetching}
										/>
									</Grid>
									{canLoadPieGraphState() && (
										<Grid item xs={5} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
											<Chart
												options={volumePieOptions(stateData.data?.categories) || {}}
												series={stateData.data?.series[0]?.data || []}
												type='donut'
												width='100%'
												height={350}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
						</>
					) : (
						<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingBottom: '5em' }}>
							<h2 id='span-location-state-region-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
								{errorFilterComponent}
							</h2>
						</Grid>
					)}
				</ComponentCard>
			) : (
				<Grid container item xs={12} style={{ marginTop: '1em' }}>
					{hasReportTitle && (
						<Grid item xs={12}>
							<GraphTitle
								title='Localidade Geral'
								icon={
									<FiMapPin
										size='20'
										style={{
											color: '#1d8cf8',
											marginTop: '0.15em'
										}}
									/>
								}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<h3 style={{ marginTop: '1em', color: '#000' }}>{getTitle(locationView)}</h3>
					</Grid>

					<Grid item xs={canLoadPieGraphRegion() ? 7 : 12}>
						<LocationGraph data={regionData.data} viewType={locationView} loading={regionData.isFetching} isReport={isReport} />
					</Grid>
					{canLoadPieGraphRegion() && (
						<Grid item xs={5} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
							<Chart
								options={volumePieOptions(regionData.data?.categories) || {}}
								series={regionData.data?.series[0]?.data || []}
								type='donut'
								width='100%'
								height={350}
							/>
						</Grid>
					)}
				</Grid>
			)}
		</>
	)
}

export default memo(LocationStateAndRegionGraph)
