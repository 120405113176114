import React, { memo } from 'react'
import { Grid, Card, IconButton, Tooltip, Dialog, DialogContent } from '@mui/material'
import { ShowIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const HiddenList = ({ isOpenDialogHidden, setIsOpenDialogHidden, hideListWords, handleRemoveWord, onClose }) => {
	const handleClose = () => {
		setIsOpenDialogHidden(false)
		onClose()
	}

	return (
		<Dialog
			open={isOpenDialogHidden}
			onClose={handleClose}
			fullWidth={true}
			maxWidth='sm'
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.9)'}`,
					borderRadius: '25px',
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(2px)',
					backgroundColor: 'transparent'
				}
			}}
		>
			<DialogContent style={{ height: '500px' }}>
				<Grid spacing={1} container direction='row' style={{ marginBottom: '20px' }}>
					{hideListWords?.map((item) => (
						<Grid item xs={12} key={item}>
							<Card
								elevation={0}
								style={{
									borderRadius: '20px',
									marginTop: '1em',
									width: '100%',
									backgroundColor: 'rgba(255,255,255,0.8)',
									border: '1px solid rgba(230,230,230,1)'
								}}
							>
								<Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									style={{
										fontFamily: 'Poppins',
										fontWeight: 'bold',
										color: '#1d8cf8',
										justifyContent: 'flex-start'
									}}
								>
									<Grid item xs={6} container direction='row' justifyContent='flex-start' alignItems='center'>
										<div style={{ marginLeft: '20px' }}>{item}</div>
									</Grid>
									<Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
										<Tooltip title='Mostrar palavra'>
											<IconButton onClick={() => handleRemoveWord(item)}>
												<ShowIcon color={'#1d8cf8'} />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
							</Card>
						</Grid>
					))}
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default memo(HiddenList)
