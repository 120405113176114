import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: false
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const AgeComp = ({ data, isReport, hideOnEmpty }) => {
	const [barsData, setBarsData] = useState(barsDataProps)
	const [hide, setHide] = useState(false)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = data.map((it) => it.name)
		let series = [] //[{name: '', data: []}]

		data.forEach((summary) =>
			summary.selectedInterval.metrics.age.series.forEach((serie) => {
				let serieFoundIndex = series.findIndex((s) => s.name === serie.name)
				if (serieFoundIndex !== -1) {
					series[serieFoundIndex].data.push(serie.data[0])
				} else {
					series.push({ name: serie.name, data: [serie.data[0]] })
				}
			})
		)

		const optionsBars = {
			colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
			chart: {
				type: 'bar',
				stacked: true,
				stackType: '100%',
				toolbar: {
					show: !isReport
				}
			},
			plotOptions: {
				bar: {
					horizontal: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories
			},
			fill: {
				opacity: 1
			},
			yaxis: {
				show: false
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		if (hideOnEmpty) {
			let hideData = []
			series.forEach((it) => hideData.push(...it.data.filter((d) => d)))
			setHide(hideData.length < 1)
		}

		setBarsData(apexData)
	}

	return (
		<>
			{!hide && (
				<Chart
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
					options={barsData.options}
					series={barsData.series}
					type='bar'
					width={'100%'}
					height={300}
				/>
			)}
		</>
	)
}

export default AgeComp
