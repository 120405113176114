import { Grid } from '@mui/material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import moment from 'moment-timezone'
import SocialProfileAccountManagement from 'pages/AccountManagement/modules/SocialProfileAccountManagement/SocialProfileAccountManagement'
import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import api from 'service/service'
import { setSharingToken } from 'store/local-storage/store'
import { saveCardTypeAction, saveAllCardsCanOpenAction, addCardComparisonByIdsAction } from 'store/redux/actions/card.actions'

const SharingDashboard = ({
	allCardsCanOpenRedux,
	saveAllCardsCanOpenDispatch,
	saveCardTypeDispatch,
	addCardComparisonByIdDispatch,
	history,
	location
}) => {
	const [loading, setLoading] = useState(false)
	const [sharingDashboard, setSharingDashboard] = useState(null)
	const { sharingLinkToken } = useParams()
	const [error, setError] = useState(null)

	useEffect(() => {
		api.get(`/sharing/dashboard/get-shared/${sharingLinkToken}`)
			.then((response) => {
				const expireDate = moment(response.data.sharingDashboard.expire_date, constantsVox.JAVASCRIPT.FORMAT_DATETIMEZONE_DEFAULT_SYSTEM)
				const now = moment().format(constantsVox.JAVASCRIPT.FORMAT_DATETIMEZONE_DEFAULT_SYSTEM)
				if (expireDate.isSameOrAfter(now)) {
					setError(null)
					setSharingDashboard(response.data.sharingDashboard)
					setSharingToken(response.data.sharingDashboard.auth_token)
					saveAllCardsCanOpenDispatch(response.data.cardList || [], { tab: constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT, source: null })
					saveCardTypeDispatch('ACCOUNT_MANAGEMENT')
				} else {
					sendMessageToast('Link de compartilhamento expirado', constantsVox.TOAST.TYPES.ERROR)

					setError(
						'Este link de compartilhamento expirou em ' +
							moment(response.data.sharingDashboard.expire_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
					)
				}
			})
			.catch((error) => {
				setError(error?.response?.data?.toast?.message)
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	useEffect(() => {
		if (allCardsCanOpenRedux.length > 0) {
			addCardComparisonByIdDispatch(
				allCardsCanOpenRedux.map((it) => it.id),
				false
			)
		}
	}, [allCardsCanOpenRedux])

	return (
		<div>
			<Grid container className='header-container-app-bar' style={{ height: '3.3em' }}>
				<span style={{ color: '#7a7a7a', fontFamily: 'Poppins' }}>
					{sharingDashboard?.name ? sharingDashboard?.name : 'Dashboard compartilhável'}
				</span>
			</Grid>
			{loading || error ? (
				<div
					className='sharing-page-content'
					style={{
						textAlign: 'center',
						color: 'gray',
						height: '85vh',
						alignContent: 'center'
					}}
				>
					<h1>{loading ? 'Carregando...' : error}</h1>
				</div>
			) : (
				<SocialProfileAccountManagement history={history} location={location} isSharing sharingDashboard={sharingDashboard} />
			)}
		</div>
	)
}

const mapStateToProps = ({ store }) => ({
	allCardsCanOpenRedux: store.card.allCardsCanOpen
})

const mapDispatchToProps = (dispatch) => ({
	saveAllCardsCanOpenDispatch: (allCards, searchPropsForComparativeBar) =>
		dispatch(saveAllCardsCanOpenAction(allCards, searchPropsForComparativeBar)),
	saveCardTypeDispatch: (cardType) => dispatch(saveCardTypeAction(cardType)),
	addCardComparisonByIdDispatch: (profileId, isComparative) => dispatch(addCardComparisonByIdsAction(profileId, isComparative))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(SharingDashboard))
