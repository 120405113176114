import React, { memo, useEffect } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import constantsVox from 'constants-vox'
import { fillObjectBlankProperties } from 'utils/generalFunctions'

import './SocialNetworksNewInsightTerm.css'
import SourceCheckContent from './components/SourceCheckContent/SourceCheckContent'

const SocialNetworksNewInsight = ({ formState, onChangeForm, fieldErrors, statusType, card, socialListeningCardTermSources, messageToast }) => {
	const hasSource = {
		twitter: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.TWITTER),
		facebook: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.FACEBOOK),
		instagram: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.INSTAGRAM),
		tiktok: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.TIKTOK),
		youtube: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.YOUTUBE),
		news: !!socialListeningCardTermSources.find((it) => it === constantsVox.SOCIAL_SOURCES.NEWS)
	}

	useEffect(() => {
		if (statusType === 'edit') {
			const hasTwitterSearch = card?.cardStatus?.twitter !== null && hasSource.twitter
			const hasFacebookSearch = card?.cardStatus?.facebook !== null && hasSource.facebook
			const hasInstagramSearch = card?.cardStatus?.instagram !== null && hasSource.instagram
			const hasTiktokSearch = card?.cardStatus?.tiktok !== null && hasSource.tiktok
			const hasYoutubeSearch = card?.cardStatus?.youtube !== null && hasSource.youtube
			const hasNewsSearch = card?.cardStatus?.news !== null && hasSource.news
			const stopAccounts = card?.stopAccounts
			const manualClassificationAccount = card?.manualClassificationAccount

			let infoObject = { hasTwitterSearch, hasFacebookSearch, hasInstagramSearch, hasTiktokSearch, hasYoutubeSearch, hasNewsSearch }
			if (stopAccounts) {
				infoObject.stopAccounts = fillObjectBlankProperties(formState.stopAccounts, stopAccounts)
			}
			if (manualClassificationAccount) {
				infoObject.manualClassificationAccount = fillObjectBlankProperties(formState.manualClassificationAccount, manualClassificationAccount)
			}
			onChangeForm(infoObject)
		}
	}, [])

	const handleChangeCheckAccount = (value, fieldname) => {
		onChangeForm({ [fieldname]: value })
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-1i mt-1i'>
				<span className='color-blue fs-1-5'>Fontes que você deseja monitorar</span>
			</Grid>
			{fieldErrors?.verifySources?.error && (
				<Grid item xs={12} className='ta-center color-red'>
					{fieldErrors?.verifySources?.error}
				</Grid>
			)}
			{[
				{ source: 'twitter', onChangeCheckPath: 'hasTwitterSearch', isChecked: !!formState.hasTwitterSearch, hasSource: hasSource.twitter },
				{
					source: 'facebook',
					onChangeCheckPath: 'hasFacebookSearch',
					isChecked: !!formState.hasFacebookSearch,
					hasSource: hasSource.facebook
				},
				{
					source: 'instagram',
					onChangeCheckPath: 'hasInstagramSearch',
					isChecked: formState.hasInstagramSearch,
					hasSource: hasSource.instagram
				},
				{
					source: 'tiktok',
					onChangeCheckPath: 'hasTiktokSearch',
					isChecked: !!formState.hasTiktokSearch,
					hasSource: hasSource.tiktok,
					hide: formState.searchType !== constantsVox.CARD.SEARCH_TYPE.TERM
				},
				{
					source: 'youtube',
					onChangeCheckPath: 'hasYoutubeSearch',
					isChecked: !!formState.hasYoutubeSearch,
					hasSource: hasSource.youtube,
					hide: formState.searchType !== constantsVox.CARD.SEARCH_TYPE.TERM
				},
				{
					source: 'news',
					onChangeCheckPath: 'hasNewsSearch',
					isChecked: !!formState.hasNewsSearch,
					hasSource: hasSource.news,
					hide: formState.searchType !== constantsVox.CARD.SEARCH_TYPE.TERM
				}
			]
				.filter((s) => !s.hide)
				.map((it) => (
					<SourceCheckContent
						key={it.source}
						source={it.source}
						hasSource={it.hasSource}
						isChecked={it.isChecked}
						onChangeCheck={(value) => handleChangeCheckAccount(value, it.onChangeCheckPath)}
						formState={formState}
						onChangeForm={onChangeForm}
						messageToast={messageToast}
						fieldErrors={fieldErrors}
					/>
				))}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	socialListeningCardTermSources: store.user.socialListeningCardTermSources
})

export default connect(mapStateToProps)(memo(SocialNetworksNewInsight))
