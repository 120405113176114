import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { BsFillCircleFill } from 'react-icons/bs'
import { MenuItem, Select, FormControl, Collapse } from '@mui/material'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		},
		width: '100%'
	}
}))

const getColor = (index) => {
	if (index === 0) {
		return '#008FFB'
	}
	if (index === 1) {
		return '#00E396'
	}
	if (index === 2) {
		return '#FEB019'
	}
	if (index === 3) {
		return '#FF4560'
	}
	if (index === 4) {
		return '#775DD0'
	}
	if (index === 5) {
		return '#ffcc00'
	}
}

const OccurrencesCardSelector = ({ cards, onCardChange, selectedCard }) => {
	const [cardsList, setCardsList] = useState(cards)
	const [selectedOption, setSelectedOption] = useState(selectedCard)
	const classes = useStyles()

	useEffect(() => {
		if (!selectedCard || !cards.find((it) => it.id === selectedOption?.id)) {
			onCardChange(cards[0])
			setSelectedOption(cards[0])
		}
		setCardsList(cards)
	}, [cards])

	const handleSelect = (e) => {
		let foundCard = cardsList.find((card) => card.id === e.target.value)
		setSelectedOption(foundCard)
		onCardChange(foundCard)
	}

	const label = (index, name) => (
		<div
			style={{
				marginLeft: '15px',
				marginRight: '10px',
				marginTop: '4px',
				fontSize: '14px',
				fontFamily: 'Poppins',
				color: 'rgb(61, 61, 61)',
				whiteSpace: 'nowrap'
			}}
		>
			<BsFillCircleFill size={7} style={{ color: getColor(index), position: 'relative', transform: 'translate(-2px, -1px)' }} /> {name}
		</div>
	)

	return (
		<Collapse in={cardsList.length > 1}>
			<FormControl className={classes.formControl}>
				<Select
					fullWidth
					variant='standard'
					className={classes.select}
					value={selectedOption?.id}
					onChange={handleSelect}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left'
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left'
						},
						PaperProps: {
							style: {
								marginTop: '0px',
								marginLeft: '-1px',
								borderRadius: '0px 0px 25px 25px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
							}
						}
					}}
				>
					{cardsList.map((card, index) => {
						return (
							<MenuItem key={card.id} value={card.id}>
								{label(index, card.name)}
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		</Collapse>
	)
}

export default OccurrencesCardSelector
