import React, { useState, useEffect, useRef, memo } from 'react'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { Grid, Card } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import MiniCardAllBySourcePercent from '../MiniCardAllBySourcePercent/MiniCardAllBySourcePercent'
import CardAllBySourceMenu from '../CardAllBySourceMenu/CardAllBySourceMenu'
import MiniCardSkeletonOverviewPercent from 'components/SkeletonLoads/MiniCardSkeletonOverviewPercent/MiniCardSkeletonOverviewPercent'

const CardAllBysourcePercent = ({ data, title, loading = false, isSocialProfile }) => {
	const loadingBarRef = useRef()
	const [graphType, setGraphType] = useState('global')
	const [hardLoading, setHardLoading] = useState(true)
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			loadingBarRef?.current?.load(loading && !hardLoading)
		}
		if (!loading) {
			setHardLoading(false)
		}

		return () => {
			isMounted.current = false
		}
	}, [loading])

	const handleChangeGraphType = (type) => {
		setGraphType(type)
	}

	return (
		<Grid item xs={12}>
			<Card style={{ borderRadius: '20px', width: '100%', height: '100%' }}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12} style={{ marginTop: '11px' }}>
						<GraphTitle id='span-minicard-percent' title={title} titleCentered={true} />
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				{hardLoading && <MiniCardSkeletonOverviewPercent />}
				{!hardLoading && (
					<>
						<Grid container>
							<Grid item xs={12}>
								<div style={{ pointerEvents: loading ? 'none' : 'all' }}>
									<CardAllBySourceMenu handleChangeGraphType={handleChangeGraphType} />
								</div>
							</Grid>
						</Grid>

						<Grid container justifyContent='center'>
							<MiniCardAllBySourcePercent data={data} metric={graphType} isSocialProfile={isSocialProfile} />
						</Grid>
					</>
				)}
			</Card>
		</Grid>
	)
}
export default memo(CardAllBysourcePercent)
