import React, { memo, useState, useEffect, useRef } from 'react'
import { Grid, TextField, Chip, Autocomplete, Paper, styled } from '@mui/material'
import { HashtagIcon, LoadingIcon } from 'utils/SystemIcons'
import api from 'service/service'
import './TagsNewInsightProfile.css'
let lastOnDeleteTime = null

const isFirefox = typeof InstallTrigger !== 'undefined'

const white = '#ffffff'
const blue = '#1d8cf8'
const purple = '#ac60f7'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const TagsNewInsightProfile = ({ formState, onChangeForm, fieldErrors, statusType, cardId }) => {
	const [typingText, setTypingText] = useState('')
	const [tags, setTags] = useState([])
	const [autocompleteTags, setAutocompleteTags] = useState([])
	const [loading, setLoading] = useState(true)

	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		setTags(formState?.tags?.value)
	}, [formState?.tags])

	const onChangeText = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingText(text)
		}
	}

	const load = async () => {
		setLoading(true)

		const response = await api.get(`/social-profile/tags/${cardId}`)
		const data = response.data

		if (isMounted.current) {
			if (statusType === 'edit') {
				onChangeForm({
					tags: {
						value: data?.cardTags?.tags?.value || []
					}
				})
			}
			setAutocompleteTags(data?.userTags?.changes || [])
		}

		setLoading(false)
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-1i mt-1i'>
				<span className='fs-1-5' style={{ color: purple }}>
					Tags para esse monitoramento (Opcional)
				</span>
			</Grid>
			<Grid item xs={12} style={{ width: '100%' }}>
				<Autocomplete
					disabled={loading}
					className='tags-profile'
					multiple
					freeSolo
					PaperComponent={CustomPaper}
					disableClearable
					inputValue={typingText}
					value={tags}
					options={autocompleteTags}
					onInputChange={(_, value) => onChangeText(value)}
					onChange={(_, value) =>
						onChangeForm({
							tags: {
								value
							}
						})
					}
					style={{ width: '100%' }}
					renderOption={(props, option) => {
						return (
							<li {...props} key={props.id} style={{ fontFamily: 'Poppins' }}>
								{option}
							</li>
						)
					}}
					renderInput={(params) => (
						<div style={{ position: 'relative' }}>
							<CssTextField
								variant='standard'
								label={
									<div style={{ display: 'flex' }}>
										{loading ? (
											<LoadingIcon
												size={20}
												thickness={1}
												style={{ color: '#ac60f7', transform: 'translate(0px, 2px)', marginRight: '7px' }}
											/>
										) : (
											<HashtagIcon
												size={20}
												style={{ color: '#ac60f7', transform: 'translate(0px, 2px)', marginRight: '7px' }}
											/>
										)}
										Tags
									</div>
								}
								error={!!fieldErrors?.tags?.error}
								helperText={fieldErrors?.tags?.error}
								{...params}
							/>
						</div>
					)}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								style={{ color: white, backgroundColor: purple, borderColor: purple }}
								key={index}
								variant='outlined'
								label={option}
								{...getTagProps({ index })}
								onDelete={(e) => {
									const props = getTagProps({ index })
									props.onDelete(e)
									lastOnDeleteTime = new Date().getTime()
								}}
							/>
						))
					}
				/>

				<div style={{ height: '1em', marginTop: '0.3em', color: blue }}>
					{typingText && typingText !== '' && 'Pressione Enter para adicionar a tag'}
				</div>
			</Grid>
		</Grid>
	)
}

export default memo(TagsNewInsightProfile)
