import React, { Fragment, useState, useEffect } from 'react'
import { DialogTitle, DialogContentText, DialogContent, Dialog, IconButton, Grid } from '@mui/material'
import { CloseIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const DialogContents = ({ open, title, text, handleClose }) => {
	const [openDialog, setOpenDialog] = useState(open)

	useEffect(() => {
		setOpenDialog(open)
	}, [open])

	return (
		<>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
			>
				<DialogTitle id='alert-dialog-title'>
					<Grid container direction='row' style={{ minWidth: '300px' }}>
						<Grid item xs={11}>
							{title || ''}
						</Grid>
						<Grid spacing={1} item xs={1} container direction='row' justifyContent='flex-end' alignItems='center'>
							<IconButton onClick={() => handleClose()} style={{ marginTop: '-4px', marginRight: '-20px' }}>
								<CloseIcon color={'#1d8cf8'} />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent>
					<DialogContentText id='alert-dialog-description'>{text || ''}</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default DialogContents
