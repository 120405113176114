import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid, Card } from '@mui/material'

const AnalysisCardSkeletonLoad = () => (
	<Grid item lg={3} md={6}>
		<Card style={{ borderRadius: '20px' }}>
			<Skeleton height='238px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
		</Card>
	</Grid>
)

export default AnalysisCardSkeletonLoad
