import React from 'react'
import './ContentReport.css'

const Content = ({ headerIcon, headerText, breakPrint, hide, children }) => {
	if (hide) {
		return null
	}
	return (
		<div className={breakPrint ? 'break-print content-report' : 'content-report'}>
			<div className='content-report-header'>
				{headerIcon}
				<h2 className='text-report-header'>{headerText}</h2>
			</div>
			<div>{children}</div>
		</div>
	)
}

export default Content
