import { Card, CardActionArea, CardContent, Grid, IconButton, Typography, Collapse } from '@mui/material'
import moment from 'moment-timezone'
import React, { memo, useState } from 'react'
import {
	UserIcon,
	MessageIcon,
	GroupIcon,
	CloseIcon,
	NotificationUnreadIcon,
	TwitterIcon,
	FacebookIcon,
	ExportIcon,
	AlertExclamationIcon
} from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { addCardComparisonByIdsAction } from 'store/redux/actions/card.actions'
import { saveGlobalFiltersTermAction } from 'store/redux/actions/global.actions'
import { GLOBAL_FILTERS_DEFAULT } from 'store/redux/reducers/utils/mainUtils'
import api from 'service/service'

const CardType = ({ type }) => {
	const lowerCaseType = type.toLowerCase()

	if (lowerCaseType === 'term') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<MessageIcon size={15} color={'#1d8cf8'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px', marginTop: '-2px' }}>Tema</div>
			</div>
		)
	} else if (lowerCaseType === 'profile') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<UserIcon size={15} color={'#ac60f7'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px' }}>Conta</div>
			</div>
		)
	} else if (lowerCaseType === 'group') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<GroupIcon size={15} color={'#d41993'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px' }}>Grupo</div>
			</div>
		)
	} else if (lowerCaseType === 'twitter_bubble_burst') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<TwitterIcon size={15} color={'#18c1c4'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px' }}>Fura Bolha do Twitter</div>
			</div>
		)
	} else if (lowerCaseType === 'facebook_bubble_burst') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<FacebookIcon size={15} color={'#18c1c4'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px' }}>Fura Bolha do Facebook</div>
			</div>
		)
	} else if (lowerCaseType === 'export_data') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<ExportIcon size={15} color={'#18c1c4'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px' }}>Exportação de Dados</div>
			</div>
		)
	} else if (lowerCaseType === 'user_billing') {
		return (
			<div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
				<AlertExclamationIcon size={15} color={'#FEB019'} />
				<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '5px', marginTop: '-2px' }}>Utilização da conta</div>
			</div>
		)
	}
	return null
}

const NotificationCard = ({ data, onClose, refetchNotifications, saveGlobalFiltersDispatch }) => {
	const [isCollapsed, setIsCollapsed] = useState(true)
	const history = useHistory()

	const handleClickNotificationCard = (cardId, cardType, notificationValue, notificationType) => {
		if (notificationType.includes('EXPORT_CARD')) {
			const link = document.createElement('a')
			link.href = notificationValue
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} else if (!notificationType.includes('USER_BILLING')) {
			goToCard(cardId, cardType, notificationValue, notificationType)
		}
	}

	const goToCard = (cardId, cardType, notificationValue, notificationType) => {
		let newFilters = { ...GLOBAL_FILTERS_DEFAULT }
		let menu = 'analysis'

		if (notificationType === 'WORD') {
			newFilters.contentTextSearch = `"${notificationValue}"`
			menu = 'occurrences'
		}
		saveGlobalFiltersDispatch(newFilters)
		onClose()
		history.push(`/redirect?menu=${menu}&registerId=${cardId}&registerType=${cardType.toLowerCase()}&notificationType=web`)
		// if (cardType.toLowerCase() === 'term') {
		// 	if (notificationType === 'WORD') {
		// 		history.push({ pathname: '/monitoring/sentiment-analysis', state: { menu } })
		// 	} else {
		// 		history.push('/monitoring/sentiment-analysis')
		// 	}
		// } else if (cardType.toLowerCase() === 'profile') {
		// 	if (notificationType === 'WORD') {
		// 		history.push({ pathname: '/monitoring/social-profile', state: { menu } })
		// 	} else {
		// 		history.push('/monitoring/social-profile')
		// 	}
		// } else if (cardType.toLowerCase() === 'group') {
		// 	if (notificationType === 'WORD') {
		// 		history.push({ pathname: '/monitoring/accounts-group', state: { menu } })
		// 	} else {
		// 		history.push('/monitoring/accounts-group')
		// 	}
		// } else if (cardType.toLowerCase() === 'twitter_bubble_burst') {
		// 	history.push({ pathname: '/monitoring', state: { cardType: 'report', source: 'twitter', title: data.title } })
		// } else if (cardType.toLowerCase() === 'facebook_bubble_burst') {
		// 	history.push({ pathname: '/monitoring', state: { cardType: 'report', source: 'facebook', title: data.title } })
		// } else {
		// 	history.push(`/redirect?menu=${menu}&registerId=${cardId}&registerType=${cardType.toLowerCase()}&notificationType=web`)
		// }
	}

	const dismissNotification = (notification) => {
		setIsCollapsed(false)
		api.post('notification/dismiss', { id: notification.id })
			.then(() => {
				refetchNotifications()
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	return (
		<Collapse in={isCollapsed}>
			<Card
				elevation={0}
				key={data.id}
				style={{
					marginRight: '10px',
					marginLeft: '10px',
					marginBottom: '20px',
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: 'rgba(250, 250, 250, 0.5)',
					border: '1px solid rgba( 255, 255, 255, 0.6 )',
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}}
				sx={{ width: '90%', margin: '0 auto', marginBottom: '20px', borderRadius: '20px' }}
			>
				<Grid container style={{ background: '#fbfbfb' }}>
					<Grid item xs={6} container justifyContent='flex-start' alignItems='center'>
						<CardType type={data?.type} />
						{!data.read_at && <NotificationUnreadIcon size={10} style={{ marginLeft: '0.8em', color: '#f50057' }} />}
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end'>
						<IconButton
							color='primary'
							style={{ padding: '0.3em', outline: 'none', cursor: 'pointer', marginLeft: '2px', marginBottom: 0 }}
							onClick={() => dismissNotification(data)}
						>
							<CloseIcon color='#a3a3a3' size={17} />
						</IconButton>
					</Grid>
				</Grid>
				<CardActionArea onClick={() => handleClickNotificationCard(data.register_id, data.type, data.value, data.notification_type)}>
					<CardContent style={{ paddingTop: '1em' }}>
						<Grid container justifyContent='space-between' style={{ alignItems: 'center' }}>
							<Grid item>
								<Typography gutterBottom variant='h5' className='m-0' component='div'>
									{data.title}
								</Typography>
							</Grid>
						</Grid>

						<Typography variant='body2' style={{ marginTop: 15 }}>
							{data.description}
						</Typography>
						<Grid container justifyContent='flex-end'>
							{moment(data.created_at).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}
						</Grid>
					</CardContent>
				</CardActionArea>
			</Card>
		</Collapse>
	)
}

const mapDispatchToProps = (dispatch) => ({
	addCardComparisonByIdDispatch: (cardId) => dispatch(addCardComparisonByIdsAction(cardId)),
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters))
})

const mapStateToProps = ({ store }) => ({
	monitoringType: store.card?.cardType || null
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(NotificationCard))
