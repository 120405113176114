import React, { useState, useEffect } from 'react'
import { Grid, Tabs, Tab, Divider, Tooltip, Button, Collapse } from '@mui/material'
import { ClockIcon } from 'utils/SystemIcons'
import { makeStyles } from '@mui/styles'
import { canRenderHourInterval } from 'graphs/utils/TimelinesFunctions'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	},
	buttonLockInterval: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey,
		borderColor: grey,
		borderBottom: '2px solid transparent',
		'&:hover': {
			borderBottom: '2px solid #1d8cf8',
			color: blue,
			borderColor: blue,
			backgroundColor: blue
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

let timer

const SensitivityByTimeMenu = ({
	fromDate,
	toDate,
	handleChangeGraphType,
	handleChangeGraphTime,
	initialGraphTime,
	getCanLockTime,
	onClickLockInterval
}) => {
	const [type, setType] = useState('default')
	const [time, setTime] = useState(initialGraphTime)
	const [canLockTime, setCanLockTime] = useState(getCanLockTime())
	const classes = useStyles()

	useEffect(() => {
		checkZoom()
		setTime(initialGraphTime)
		return () => {
			clearTimeout(timer)
		}
	}, [initialGraphTime])

	const checkZoom = () => {
		setCanLockTime(getCanLockTime())
		timer = setTimeout(checkZoom, 500)
	}

	const onGraphTypeChange = (_, newValue) => {
		setType(newValue)
		handleChangeGraphType(newValue)
	}

	const onGraphTimeChange = (_, newValue) => {
		setTime(newValue)
		handleChangeGraphTime(newValue)
	}

	return (
		<>
			<Grid container spacing={0}>
				<Grid item xs={4}>
					<Tabs value={time} onChange={onGraphTimeChange} TabIndicatorProps={{ style: { background: blue } }}>
						{canRenderHourInterval(fromDate, toDate, 'months', 3) ? (
							<Tab label='Horas' value='hour' style={{ outline: 'none' }} className={classes.tab} />
						) : (
							<Tooltip title='Intervalo selecionado não disponível para visualização em horas (maior que 3 meses)' placement='right'>
								<Button
									disableRipple
									style={{ borderRadius: 0, backgroundColor: 'transparent', textTransform: 'uppercase', width: '13em' }}
									className={classes.tab}
								>
									Horas
								</Button>
							</Tooltip>
						)}
						<Tab label='Dias' value='day' style={{ outline: 'none' }} className={classes.tab} />
					</Tabs>
				</Grid>
				<Grid container justifyContent={'center'} alignItems={'center'} item xs={4}>
					{/* <Collapse in={canLockTime}>
						<Button
							onClick={onClickLockInterval}
							style={{ borderRadius: 0, backgroundColor: 'transparent', textTransform: 'uppercase', height: '48px' }}
							className={classes.buttonLockInterval}
						>
							<ClockIcon size={17} style={{ color: '#1d8cf8', marginRight: '0.5em' }} />
							manter intervalo
						</Button>
					</Collapse> */}
				</Grid>
				<Grid item xs={4}>
					<Tabs style={{ float: 'right' }} value={type} onChange={onGraphTypeChange} TabIndicatorProps={{ style: { background: blue } }}>
						<Tab label='Padrão' value='default' style={{ outline: 'none' }} className={classes.tab} />
						<Tab label='Saldo' value='outcome' style={{ outline: 'none' }} className={classes.tab} />
					</Tabs>
				</Grid>
			</Grid>
			<Divider style={{ marginBottom: '10px', background: '#f0f0f0' }} />
		</>
	)
}

export default SensitivityByTimeMenu
