import React from 'react'
import { Grid, Tooltip, Collapse } from '@mui/material'
import {
	LikeIcon,
	AngryIcon,
	CommentIcon,
	HahaIcon,
	HeartIcon,
	SadIcon,
	ShareIcon,
	WowIcon,
	RetweetIcon,
	EyeIcon,
	UserCheckIcon,
	PlayIcon,
	PlayIconSmall,
	MinusIcon,
	MinusCircleIcon,
	ReplyIcon
} from 'utils/SystemIcons'

const iconSize = 14
const tooltipDelay = 200

const OccurrenceCardMetrics = ({ cardData, cardColor, source, type }) => {
	return (
		<>
			{source === 'twitter' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					{(['term', 'profile', 'group'].includes(type) ? cardData.content.isPost : !cardData.is_retweet) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
									<HeartIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? (cardData?.content?.engagement?.loveCount || 0) + (cardData?.content?.engagement?.likeCount || 0)
										: cardData?.favorite_count}
								</div>
							</Tooltip>
						</Grid>
					)}
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Retweets' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<RetweetIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type) ? cardData?.content?.engagement?.shareCount : cardData?.retweet_count}
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type)
									? cardData?.content?.engagement?.commentCount
									: cardData?.public_metrics && cardData?.public_metrics?.reply_count}
							</div>
						</Tooltip>
					</Grid>
					{cardData?.non_public_metrics && (
						<>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										{cardData?.non_public_metrics?.impression_count}
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Cliques no perfil' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<UserCheckIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										{cardData?.non_public_metrics?.user_profile_clicks}
									</div>
								</Tooltip>
							</Grid>
						</>
					)}
					{cardData?.organic_metrics && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<PlayIconSmall size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{cardData?.organic_metrics?.video?.view_count}
								</div>
							</Tooltip>
						</Grid>
					)}
					{(cardData?.content?.engagement?.viewCount > 0 || cardData?.post?.engagement?.view_count > 0) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.viewCount
										: cardData?.post?.engagement?.view_count}
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
			{source === 'news' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Reações' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{(cardData.content.engagement.angryCount || 0) +
									(cardData.content.engagement.hahaCount || 0) +
									(cardData.content.engagement.likeCount || 0) +
									(cardData.content.engagement.loveCount || 0) +
									(cardData.content.engagement.sadCount || 0) +
									(cardData.content.engagement.wowCount || 0)}
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{cardData?.content?.engagement?.commentCount}
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Compartilhamentos' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{cardData?.content?.engagement?.shareCount}
							</div>
						</Tooltip>
					</Grid>
				</Grid>
			)}
			{source === 'facebook' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					{cardData?.post?.media_product_type === 'REEL' && (
						<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações do Reel' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									<span style={{ direction: 'rtl' }}>{cardData.post?.metrics?.blue_reels_play_count}</span>
								</div>
							</Tooltip>
						</Grid>
					)}
					{cardData?.post?.media_product_type !== 'REEL' && (
						<>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Curtidas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData.content?.engagement?.likeCount
												: cardData.post?.engagement?.like_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Ameis' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<HeartIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.loveCount
												: cardData?.post?.engagement?.love_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Hahas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<HahaIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.hahaCount
												: cardData?.post?.engagement?.haha_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Wows' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<WowIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.wowCount
												: cardData?.post?.engagement?.wow_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Tristes' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<SadIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.sadCount
												: cardData?.post?.engagement?.sad_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
								<Tooltip title='Grrs' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<div style={{ cursor: 'pointer' }}>
										<AngryIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
										<span style={{ direction: 'rtl' }}>
											{['term', 'profile', 'group'].includes(type)
												? cardData?.content?.engagement?.angryCount
												: cardData?.post?.engagement?.angry_count}
										</span>
									</div>
								</Tooltip>
							</Grid>
						</>
					)}
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								<span style={{ direction: 'rtl' }}>
									{['term', 'profile', 'group'].includes(type)
										? cardData.content?.engagement?.commentCount
										: cardData.post?.engagement?.comment_count}
								</span>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Compartilhamentos' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								<span style={{ direction: 'rtl' }}>
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.shareCount
										: cardData?.post?.engagement?.share_count}
								</span>
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.negative_feedback !== undefined}>
							<Tooltip title='Feedback negativo' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MinusIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									<span style={{ direction: 'rtl' }}>{cardData?.post?.negative_feedback}</span>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.negative_feedback_unique !== undefined}>
							<Tooltip title='Feedback negativo unico' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<MinusCircleIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									<span style={{ direction: 'rtl' }}>{cardData?.post?.negative_feedback_unique}</span>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.video_views !== undefined}>
							<Tooltip title='Visualizações de video' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<PlayIconSmall size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									<span style={{ direction: 'rtl' }}>{cardData?.post?.video_views}</span>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
					<Grid item xs={4} md={3} style={{ color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Collapse in={cardData?.post?.video_views_unique !== undefined}>
							<Tooltip title='Visualizações de video unicas' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<PlayIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									<span style={{ direction: 'rtl' }}>{cardData?.post?.video_views_unique}</span>
								</div>
							</Tooltip>
						</Collapse>
					</Grid>
				</Grid>
			)}
			{source === 'instagram' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type)
									? cardData?.content?.engagement?.likeCount
									: cardData?.post?.engagement?.like_count}
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type)
									? cardData?.content?.engagement?.commentCount
									: cardData?.post?.engagement?.comment_count}
							</div>
						</Tooltip>
					</Grid>

					{(cardData?.content?.engagement?.viewCount > 0 || cardData?.post?.engagement?.view_count > 0) && (
						<Grid item xs={4} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.viewCount
										: cardData?.post?.engagement?.view_count}
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
			{['tiktok', 'youtube'].includes(source) && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type)
									? cardData?.content?.engagement?.likeCount
									: cardData?.post?.engagement?.like_count}
							</div>
						</Tooltip>
					</Grid>
					<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Comentários' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer' }}>
								<CommentIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{['term', 'profile', 'group'].includes(type)
									? cardData?.content?.engagement?.commentCount
									: cardData?.post?.engagement?.comment_count}
							</div>
						</Tooltip>
					</Grid>

					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.viewCount
							: cardData?.post?.engagement?.view_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Visualizações de video' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<EyeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.viewCount
										: cardData?.post?.engagement?.view_count}
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.shareCount
							: cardData?.post?.engagement?.share_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Compartilhamentos' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<ShareIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.shareCount
										: cardData?.post?.engagement?.share_count}
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
					{(
						['term', 'profile', 'group'].includes(type)
							? cardData?.content?.engagement?.quoteCount
							: cardData?.post?.engagement?.reply_count
					) ? (
						<Grid item xs={5} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Respostas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer' }}>
									<ReplyIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{['term', 'profile', 'group'].includes(type)
										? cardData?.content?.engagement?.quoteCount
										: cardData?.post?.engagement?.reply_count}
								</div>
							</Tooltip>
						</Grid>
					) : (
						<div></div>
					)}
				</Grid>
			)}
			{source === 'metaAds' && (
				<Grid container spacing={2} justifyContent='flex-end'>
					<Grid item xs={6} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<div style={{ width: 'fit-content', margin: '0 0 auto auto' }}>
							<div style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Impressões:</div>
							{cardData?.post?.metrics?.impressions}
						</div>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<div style={{ width: 'fit-content', margin: '0 0 auto auto' }}>
							<div style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Cliques:</div>
							{cardData?.post?.metrics?.clicks}
						</div>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default OccurrenceCardMetrics
