import { Avatar, Box, Button, Card, CardContent, Chip, Grid, List, ListItem, Typography } from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageAnnotationProjectTasks from './ImageAnnotationProjectTasks'
import ImageAnnotationCreateProject from './ImageAnnotationCreateProject'

const ImageAnnotationListProjects = () => {
	const [projects, setProjects] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [openTask, setOpenTask] = useState(false)
	const [projectId, setProjectId] = useState()
	const [projectCvatId, setProjectCvatId] = useState()
	const [isCreatingProject, setIsCreatingProject] = useState(false)

	const fetchProjects = async () => {
		try {
			const response = await api.get('/image-annotation/projects')
			if (Array.isArray(response.data.data)) {
				setProjects(response.data.data)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching projects:', err)
			setError('Error fetching projects')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchProjects()
	}, [])

	const getStatusColor = (status) => {
		switch (status) {
			case 'NEW':
				return 'blue'
			case 'IN_PROGRESS':
				return 'orange'
			case 'COMPLETED':
				return 'green'
			case 'ERROR':
				return 'red'
			default:
				return 'grey'
		}
	}

	const handleProjectClick = (projectId, projectCvatId) => {
		setProjectId(projectId)
		setProjectCvatId(projectCvatId)
		setOpenTask(true)
	}

	const onCloseCreating = async () => {
		setIsCreatingProject(false)
		await fetchProjects()
	}

	const handleDownload = (url) => {
		const link = document.createElement('a')
		link.href = url
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		return <div>{error}</div>
	}

	const handleWidth = (length) => {
		if (length === 1) {
			return 300
		}
		if (length <= 4) {
			return 150
		}
		if (length <= 9) {
			return 100
		}
		if (length <= 12) {
			return 75
		}
		return 75
	}

	const handleHeight = (length) => {
		if (length <= 2) {
			return 225
		}
		if (length <= 6) {
			return 112.5
		}
		if (length <= 12) {
			return 75
		}
		return 75
	}

	return (
		<Box style={{ width: '90%', maxWidth: 800, margin: '0 auto', padding: '16px', backgroundColor: '#f7f7f7' }}>
			<Typography variant='h4' gutterBottom>
				Lista de Projetos
			</Typography>

			<Button variant='contained' color='primary' onClick={() => setIsCreatingProject(true)}>
				Criar novo projeto
			</Button>

			{isCreatingProject && <ImageAnnotationCreateProject isOpen={isCreatingProject} handleOnClose={onCloseCreating} />}

			<List>
				{projects.map((project) => (
					<ListItem key={project.id} style={{ padding: 0, marginBottom: '16px', cursor: 'pointer' }}>
						<Card style={{ width: '100%' }}>
							<Grid container>
								<Grid item xs={6}>
									<CardContent onClick={() => handleProjectClick(project.id, project.cvatId)}>
										<Typography variant='h5' gutterBottom>
											{`${project.id} - ${project.name}`}
										</Typography>
										<Chip
											label={project.status}
											style={{
												backgroundColor: getStatusColor(project.status),
												color: 'white',
												marginBottom: '8px'
											}}
										/>
										<Typography variant='body2'>CVAT ID: {project.cvatId}</Typography>
										<Typography variant='body2'>
											Último Modelo Rodado: {project.lastRunModel ? project.lastRunModel : null}
										</Typography>
										<Typography variant='body2'>Versão Modelo: {project.modelVersion}</Typography>
										{project.modelLastRunDate && (
											<Typography variant='body2'>
												Rodado pela última vez em: {moment(project.modelLastRunDate).format('DD/MM/YYYY')}
											</Typography>
										)}
										<Typography variant='body2'>Logos IDs: {project.auraLogosIds.join(', ')}</Typography>
										<Typography variant='body2'>Projeto criado em: {moment(project.createdAt).format('DD/MM/YYYY')}</Typography>
									</CardContent>
									{project.modelBasePath && (
										<Button
											variant='outlined'
											style={{ marginLeft: '15px' }}
											onClick={() => handleDownload(project.modelBasePath)}
										>
											Download modelo base
										</Button>
									)}
								</Grid>
								<Grid item xs={6}>
									<CardContent onClick={() => handleProjectClick(project.id, project.cvatId)}>
										{project.auraLogosPaths && project.auraLogosPaths.length > 0 && (
											<Grid container direction={'row'} style={{ maxHeight: 225, maxWidth: 300 }}>
												{project.auraLogosPaths?.map((image) => (
													<Grid item key={image}>
														<Avatar
															src={image}
															sx={{
																width: handleWidth(project.auraLogosPaths.length),
																height: handleHeight(project.auraLogosPaths.length),
																objectFit: 'contain',
																backgroundColor: 'white'
															}}
															variant='square'
														/>
													</Grid>
												))}
											</Grid>
										)}
									</CardContent>
								</Grid>
							</Grid>
						</Card>
					</ListItem>
				))}
			</List>

			{openTask && (
				<ImageAnnotationProjectTasks
					projectId={projectId}
					projectCvatId={projectCvatId}
					isOpen={openTask}
					handleOnClose={() => setOpenTask(false)}
				/>
			)}
		</Box>
	)
}

export default ImageAnnotationListProjects
