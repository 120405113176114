import React from 'react'
import {
	getImpressionsViralAndNotUniqueFormatted,
	getImpressionsPaidOrganicUniqueFormatted,
	getImpressionsFanPaidOrganicUniqueFormatted,
	getImpressionsByStoryTypeUniqueFormatted,
	getVideoAdBreakFormatted,
	getVideoCompleteViews30sFormatted,
	getVideoCompleteViewsOrganicFormatted,
	getVideoViewTimeByAgeBucketAndGenderFormatted,
	getVideoViewTimeByCountryIdFormatted,
	getVideoViewTimeByDistributionTypeFormatted,
	getVideoViewTimeByRegionIdFormatted,
	getVideoViewByDistribuitionTypeFormatted,
	getVideoRetentionGraphFormatted,
	getVideoReactionsByTypeTotalFormatted,
	getVideoStoriesByActionTypeFormatted,
	getEngagementFormatted,
	getActivityFormatted,
	getClicksUniqueFormatted,
	getNegativeFeedBackFormatted
} from '../../../../utils/viewPostMetrics.utils'
import { Grid } from '@mui/material'
import BarMetrics from 'pages/AccountManagement/components/BarMetrics/BarMetrics'
import NumericMetric from 'pages/AccountManagement/components/NumericMetric/NumericMetric'

const FacebookPostTypeMetrics = ({ cardData }) => {
	const impressionsViralAndNotUnique = getImpressionsViralAndNotUniqueFormatted(cardData)
	const impressionsPaidOrganicUnique = getImpressionsPaidOrganicUniqueFormatted(cardData)
	const impressionsFanPaidOrganicUnique = getImpressionsFanPaidOrganicUniqueFormatted(cardData)
	const impressionsByStoryTypeUnique = getImpressionsByStoryTypeUniqueFormatted(cardData)
	const videoAdBreak = getVideoAdBreakFormatted(cardData)
	const videoCompleteViews30s = getVideoCompleteViews30sFormatted(cardData)
	const videoCompleteViewsOrganic = getVideoCompleteViewsOrganicFormatted(cardData)
	const videoViewTimeByAgeBucketAndGender = getVideoViewTimeByAgeBucketAndGenderFormatted(cardData)
	const videoViewTimeByCountryId = getVideoViewTimeByCountryIdFormatted(cardData)
	const videoViewTimeByDistributionType = getVideoViewTimeByDistributionTypeFormatted(cardData)
	const videoViewTimeByRegionId = getVideoViewTimeByRegionIdFormatted(cardData)
	const videoViewByDistribuitionType = getVideoViewByDistribuitionTypeFormatted(cardData)
	const videoRetentionGraph = getVideoRetentionGraphFormatted(cardData)
	const videoReactionsByTypeTotal = getVideoReactionsByTypeTotalFormatted(cardData)
	const videoStoriesByActionType = getVideoStoriesByActionTypeFormatted(cardData)
	const engagement = getEngagementFormatted(cardData)
	const activity = getActivityFormatted(cardData)
	const clicks = getClicksUniqueFormatted(cardData)
	const negativeFeedBack = getNegativeFeedBackFormatted(cardData)
	const descriptions = cardData.descriptions

	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_views}
						title={descriptions.find((item) => item.key === 'facebook_video_views')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_views')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_views_unique}
						title={descriptions.find((item) => item.key === 'facebook_video_views_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_views_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_followers}
						title={descriptions.find((item) => item.key === 'facebook_video_followers')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_followers')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.comment_count}
						title={descriptions.find((item) => item.key === 'facebook_comments_count')?.title}
						description={descriptions.find((item) => item.key === 'facebook_comments_count')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions}
						title={descriptions.find((item) => item.key === 'facebook_impressions')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions_unique}
						title={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={videoRetentionGraph.values}
						categories={videoRetentionGraph.labels}
						title={descriptions.find((item) => item.key === 'facebook_video_retention_graph')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_retention_graph')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={videoReactionsByTypeTotal.values}
						categories={videoReactionsByTypeTotal.labels}
						title={descriptions.find((item) => item.key === 'facebook_video_reactions_by_type_total')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_reactions_by_type_total')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={videoStoriesByActionType.values}
						categories={videoStoriesByActionType.labels}
						title={descriptions.find((item) => item.key === 'facebook_video_stories_by_action_type')?.title}
						description={descriptions.find((item) => item.key === 'facebook_video_stories_by_action_type')?.description}
						label='Quantidade'
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsViralAndNotUnique.values}
						categories={impressionsViralAndNotUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_viral_nonviral_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_viral_nonviral_unique')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsPaidOrganicUnique.values}
						categories={impressionsPaidOrganicUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_paid_unique_organic')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_paid_unique_organic')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsFanPaidOrganicUnique.values}
						categories={impressionsFanPaidOrganicUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_fan_unique_and_paid')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_fan_unique_and_paid')?.description}
						label='Quantidade'
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={12} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsByStoryTypeUnique.values}
						categories={impressionsByStoryTypeUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_by_story_type_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_by_story_type_unique')?.description}
						label='Quantidade'
					/>
				</Grid>
			</Grid>
			<>
				<Grid container spacing={3} style={{ marginTop: '1em' }}>
					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoCompleteViews30s.values}
							categories={videoCompleteViews30s.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_complete_views_30s_unique')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_complete_views_30s_unique')?.description}
							label='Quantidade'
						/>
					</Grid>

					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoCompleteViewsOrganic.values}
							categories={videoCompleteViewsOrganic.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_complete_views_organic')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_complete_views_organic')?.description}
							label='Quantidade'
						/>
					</Grid>
					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoAdBreak.values}
							categories={videoAdBreak.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_ad_break_ad_impressions')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_ad_break_ad_impressions')?.description}
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '1em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoViewTimeByAgeBucketAndGender.values}
							categories={videoViewTimeByAgeBucketAndGender.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_view_time_by_age_bucket_and_gender')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_view_time_by_age_bucket_and_gender')?.description}
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '1em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoViewTimeByCountryId.values}
							categories={videoViewTimeByCountryId.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_view_time_by_country_id')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_view_time_by_country_id')?.description}
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '1em' }}>
					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoViewTimeByRegionId.values}
							categories={videoViewTimeByRegionId.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_view_time_by_region_id')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_view_time_by_region_id')?.description}
							label='Quantidade'
						/>
					</Grid>

					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoViewTimeByDistributionType.values}
							categories={videoViewTimeByDistributionType.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_view_time_by_distribution_type')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_view_time_by_distribution_type')?.description}
							label='Quantidade'
						/>
					</Grid>
					<Grid item xs={4} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoViewByDistribuitionType.values}
							categories={videoViewByDistribuitionType.labels}
							title={descriptions.find((item) => item.key === 'facebook_video_views_by_distribution_type')?.title}
							description={descriptions.find((item) => item.key === 'facebook_video_views_by_distribution_type')?.description}
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '2em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={engagement.values}
							categories={engagement.labels}
							title={descriptions.find((item) => item.key === 'facebook_reactions')?.title}
							description={descriptions.find((item) => item.key === 'facebook_reactions')?.description}
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '2em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={activity.values}
							categories={activity.labels}
							title='Atividade'
							description='Atividade dos posts por tipo'
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '2em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={clicks.values}
							categories={clicks.labels}
							title='Clicks no post'
							description='Clicks únicos no post por tipo'
							label='Quantidade'
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '2em' }}>
					<Grid item xs={4} style={{ height: '10em' }}>
						<NumericMetric
							data={cardData?.post?.metrics?.activity}
							title={descriptions.find((item) => item.key === 'facebook_activity')?.title}
							description={descriptions.find((item) => item.key === 'facebook_activity')?.description}
							source='facebook'
							isOverview={true}
						/>
					</Grid>
					<Grid item xs={4} style={{ height: '10em' }}>
						<NumericMetric
							data={cardData?.post?.metrics?.activity_unique}
							title={descriptions.find((item) => item.key === 'facebook_activity_unique')?.title}
							description={descriptions.find((item) => item.key === 'facebook_activity_unique')?.description}
							source='facebook'
							isOverview={true}
						/>
					</Grid>
					<Grid item xs={4} style={{ height: '10em' }}>
						<NumericMetric
							data={cardData?.post?.metrics?.clicks_unique}
							title={descriptions.find((item) => item.key === 'facebook_clicks_unique')?.title}
							description={descriptions.find((item) => item.key === 'facebook_clicks_unique')?.description}
							source='facebook'
							isOverview={true}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} style={{ marginTop: '1em' }}>
					<Grid item xs={12} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={negativeFeedBack.values}
							categories={negativeFeedBack.labels}
							title='Feedbacks Negativos'
							description='O número de vezes que as pessoas executaram uma ação negativa na sua publicação (por exemplo, a ocultaram).'
							label='Quantidade'
						/>
					</Grid>
				</Grid>
			</>
		</>
	)
}

export default FacebookPostTypeMetrics
