const verifyOptions = (source, thisOptions) => {
	let count = 0
	switch (source) {
		case 'twitter':
			thisOptions.summaryTwitter === true && count++
			thisOptions.locationTwitter === true && count++
			thisOptions.sentimentsTwitter === true && count++
			thisOptions.timelineTwitter === true && count++
			thisOptions.networkTwitter === true && count++
			break
		case 'facebook':
			thisOptions.summaryFacebook === true && count++
			thisOptions.locationFacebook === true && count++
			thisOptions.sentimentsFacebook === true && count++
			thisOptions.timelineFacebook === true && count++
			thisOptions.networkFacebook === true && count++
			break
		case 'instagram':
			thisOptions.summaryInstagram === true && count++
			thisOptions.locationInstagram === true && count++
			thisOptions.sentimentsInstagram === true && count++
			thisOptions.timelineInstagram === true && count++
			thisOptions.networkInstagram === true && count++
			break
		case 'tiktok':
			thisOptions.summaryTiktok === true && count++
			thisOptions.locationTiktok === true && count++
			thisOptions.sentimentsTiktok === true && count++
			thisOptions.timelineTiktok === true && count++
			thisOptions.networkTiktok === true && count++
			break
		case 'youtube':
			thisOptions.summaryYoutube === true && count++
			thisOptions.locationYoutube === true && count++
			thisOptions.sentimentsYoutube === true && count++
			thisOptions.timelineYoutube === true && count++
			thisOptions.networkYoutube === true && count++
			break
		case 'news':
			thisOptions.summaryNews === true && count++
			thisOptions.timelineNews === true && count++
			thisOptions.networkNews === true && count++
			break
	}
	return count > 1 ? true : false
}

const toggleReportContentOption = (option, value, thisOptions, setThisOptions) => {
	switch (option) {
		case 'twitter':
			setThisOptions({
				...thisOptions,
				twitter: value,
				summaryTwitter: value,
				locationTwitter: value,
				sentimentsTwitter: value,
				timelineTwitter: value,
				networkTwitter: value,
				sentimentsTwitterBalance: value,
				sentimentsTwitterStandard: value,
				timelineTwitterEngagement: value,
				timelineTwitterPosts: value,
				networkTwitterAccounts: value,
				networkTwitterHashtags: value,
				networkTwitterSentiments: value,
				locationTwitterVolume: value,
				locationTwitterSentiments: value,
				locationTwitterGender: value
			})
			break
		case 'facebook':
			setThisOptions({
				...thisOptions,
				facebook: value,
				summaryFacebook: value,
				locationFacebook: value,
				sentimentsFacebook: value,
				timelineFacebook: value,
				sentimentsFacebookBalance: value,
				sentimentsFacebookStandard: value,
				timelineFacebookEngagement: value,
				timelineFacebookPosts: value,
				networkFacebook: value,
				networkFacebookAccounts: value,
				networkFacebookSentiments: value,
				locationFacebookVolume: value,
				locationFacebookSentiments: value,
				locationFacebookGender: value
			})
			break
		case 'instagram':
			setThisOptions({
				...thisOptions,
				instagram: value,
				summaryInstagram: value,
				locationInstagram: value,
				sentimentsInstagram: value,
				sentimentsInstagramBalance: value,
				sentimentsInstagramStandard: value,
				timelineInstagram: value,
				timelineInstagramEngagement: value,
				timelineInstagramPosts: value,
				networkInstagram: value,
				networkInstagramAccounts: value,
				networkInstagramSentiments: value,
				locationInstagramVolume: value,
				locationInstagramSentiments: value,
				locationInstagramGender: value
			})
			break
		case 'tiktok':
			setThisOptions({
				...thisOptions,
				tiktok: value,
				summaryTiktok: value,
				locationTiktok: value,
				sentimentsTiktok: value,
				sentimentsTiktokBalance: value,
				sentimentsTiktokStandard: value,
				timelineTiktok: value,
				timelineTiktokEngagement: value,
				timelineTiktokPosts: value,
				networkTiktok: value,
				networkTiktokAccounts: value,
				networkTiktokSentiments: value,
				locationTiktokVolume: value,
				locationTiktokSentiments: value,
				locationTiktokGender: value
			})
			break
		case 'youtube':
			setThisOptions({
				...thisOptions,
				youtube: value,
				summaryYoutube: value,
				locationYoutube: value,
				sentimentsYoutube: value,
				sentimentsYoutubeBalance: value,
				sentimentsYoutubeStandard: value,
				timelineYoutube: value,
				timelineYoutubeEngagement: value,
				timelineYoutubePosts: value,
				networkYoutube: value,
				networkYoutubeAccounts: value,
				networkYoutubeSentiments: value,
				locationYoutubeVolume: value,
				locationYoutubeSentiments: value,
				locationYoutubeGender: value
			})
			break
		case 'news':
			setThisOptions({
				...thisOptions,
				news: value,
				summaryNews: value,
				timelineNews: value,
				networkNews: value,
				timelineNewsEngagement: value,
				timelineNewsPosts: value,
				networkNewsAccounts: value
			})
			break
		case 'summaryTwitter':
			if (thisOptions.twitter === false) {
				setThisOptions({ ...thisOptions, summaryTwitter: value, twitter: value })
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryTwitter: value })
			} else {
				setThisOptions({ ...thisOptions, summaryTwitter: value, twitter: value })
			}
			break
		case 'summaryFacebook':
			if (thisOptions.facebook === false) {
				setThisOptions({ ...thisOptions, summaryFacebook: value, facebook: value })
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryFacebook: value })
			} else {
				setThisOptions({ ...thisOptions, summaryFacebook: value, facebook: value })
			}
			break
		case 'summaryInstagram':
			if (thisOptions.instagram === false) {
				setThisOptions({ ...thisOptions, summaryInstagram: value, instagram: value })
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryInstagram: value })
			} else {
				setThisOptions({ ...thisOptions, summaryInstagram: value, instagram: value })
			}
			break
		case 'summaryTiktok':
			if (thisOptions.tiktok === false) {
				setThisOptions({ ...thisOptions, summaryTiktok: value, tiktok: value })
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryTiktok: value })
			} else {
				setThisOptions({ ...thisOptions, summaryTiktok: value, tiktok: value })
			}
			break
		case 'summaryYoutube':
			if (thisOptions.youtube === false) {
				setThisOptions({ ...thisOptions, summaryYoutube: value, youtube: value })
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryYoutube: value })
			} else {
				setThisOptions({ ...thisOptions, summaryYoutube: value, youtube: value })
			}
			break
		case 'summaryNews':
			if (thisOptions.news === false) {
				setThisOptions({ ...thisOptions, summaryNews: value, news: value })
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({ ...thisOptions, summaryNews: value })
			} else {
				setThisOptions({ ...thisOptions, summaryNews: value, news: value })
			}
			break
		case 'locationTwitter':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterVolume: value,
					locationTwitterGender: value,
					locationTwitterSentiments: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterVolume: value,
					locationTwitterGender: value,
					locationTwitterSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterVolume: value,
					locationTwitterGender: value,
					locationTwitterSentiments: value
				})
			}

			break
		case 'locationFacebook':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookVolume: value,
					locationFacebookGender: value,
					locationFacebookSentiments: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookVolume: value,
					locationFacebookGender: value,
					locationFacebookSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookVolume: value,
					locationFacebookGender: value,
					locationFacebookSentiments: value
				})
			}

			break
		case 'locationInstagram':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramVolume: value,
					locationInstagramGender: value,
					locationInstagramSentiments: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramVolume: value,
					locationInstagramGender: value,
					locationInstagramSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramVolume: value,
					locationInstagramGender: value,
					locationInstagramSentiments: value
				})
			}

			break
		case 'locationTiktok':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokVolume: value,
					locationTiktokGender: value,
					locationTiktokSentiments: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokVolume: value,
					locationTiktokGender: value,
					locationTiktokSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokVolume: value,
					locationTiktokGender: value,
					locationTiktokSentiments: value
				})
			}

			break
		case 'locationYoutube':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeVolume: value,
					locationYoutubeGender: value,
					locationYoutubeSentiments: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeVolume: value,
					locationYoutubeGender: value,
					locationYoutubeSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeVolume: value,
					locationYoutubeGender: value,
					locationYoutubeSentiments: value
				})
			}

			break
		case 'sentimentsTwitter':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value,
					sentimentsTwitterStandard: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value,
					sentimentsTwitterStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value,
					sentimentsTwitterStandard: value
				})
			}

			break
		case 'sentimentsFacebook':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value,
					sentimentsFacebookStandard: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value,
					sentimentsFacebookStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value,
					sentimentsFacebookStandard: value
				})
			}

			break
		case 'sentimentsInstagram':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value,
					sentimentsInstagramStandard: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value,
					sentimentsInstagramStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value,
					sentimentsInstagramStandard: value
				})
			}

			break
		case 'sentimentsTiktok':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value,
					sentimentsTiktokStandard: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value,
					sentimentsTiktokStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value,
					sentimentsTiktokStandard: value
				})
			}
			break
		case 'sentimentsYoutube':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value,
					sentimentsYoutubeStandard: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value,
					sentimentsYoutubeStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value,
					sentimentsYoutubeStandard: value
				})
			}
			break
		case 'timelineTwitter':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterEngagement: value,
					timelineTwitterPosts: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTwitter: value,
					timelineTwitterEngagement: value,
					timelineTwitterPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterEngagement: value,
					timelineTwitterPosts: value
				})
			}

			break
		case 'timelineFacebook':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookEngagement: value,
					timelineFacebookPosts: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineFacebook: value,
					timelineFacebookEngagement: value,
					timelineFacebookPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookEngagement: value,
					timelineFacebookPosts: value
				})
			}

			break
		case 'timelineInstagram':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramEngagement: value,
					timelineInstagramPosts: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineInstagram: value,
					timelineInstagramEngagement: value,
					timelineInstagramPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramEngagement: value,
					timelineInstagramPosts: value
				})
			}

			break
		case 'timelineTiktok':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokEngagement: value,
					timelineTiktokPosts: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTiktok: value,
					timelineTiktokEngagement: value,
					timelineTiktokPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokEngagement: value,
					timelineTiktokPosts: value
				})
			}
			break
		case 'timelineYoutube':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubeEngagement: value,
					timelineYoutubePosts: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineYoutube: value,
					timelineYoutubeEngagement: value,
					timelineYoutubePosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubeEngagement: value,
					timelineYoutubePosts: value
				})
			}
			break
		case 'timelineNews':
			if (thisOptions.news === false) {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsEngagement: value,
					timelineNewsPosts: value
				})
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineNews: value,
					timelineNewsEngagement: value,
					timelineNewsPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsEngagement: value,
					timelineNewsPosts: value
				})
			}

			break
		case 'networkTwitter':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterAccounts: value,
					networkTwitterHashtags: value,
					networkTwitterSentiments: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterAccounts: value,
					networkTwitterHashtags: value,
					networkTwitterSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterAccounts: value,
					networkTwitterHashtags: value,
					networkTwitterSentiments: value
				})
			}

			break
		case 'networkFacebook':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookAccounts: value,
					networkFacebookSentiments: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkFacebook: value,
					networkFacebookAccounts: value,
					networkFacebookSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookAccounts: value,
					networkFacebookSentiments: value
				})
			}

			break
		case 'networkInstagram':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					networkInstagram: value,
					networkInstagramAccounts: value,
					networkInstagramSentiments: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkInstagram: value,
					networkInstagramAccounts: value,
					networkInstagramSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					networkInstagram: value,
					networkInstagramAccounts: value,
					networkInstagramSentiments: value
				})
			}

			break
		case 'networkTiktok':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					networkTiktok: value,
					networkTiktokAccounts: value,
					networkTiktokSentiments: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTiktok: value,
					networkTiktokAccounts: value,
					networkTiktokSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					networkTiktok: value,
					networkTiktokAccounts: value,
					networkTiktokSentiments: value
				})
			}
			break
		case 'networkYoutube':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					networkYoutube: value,
					networkYoutubeAccounts: value,
					networkYoutubeSentiments: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkYoutube: value,
					networkYoutubeAccounts: value,
					networkYoutubeSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					networkYoutube: value,
					networkYoutubeAccounts: value,
					networkYoutubeSentiments: value
				})
			}
			break
		case 'networkNews':
			if (thisOptions.news === false) {
				setThisOptions({
					...thisOptions,
					news: value,
					networkNews: value,
					networkNewsAccounts: value
				})
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkNews: value,
					networkNewsAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					news: value,
					networkNews: value,
					networkNewsAccounts: value
				})
			}

			break
		case 'sentimentsTwitterStandard':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterStandard: value
				})
			} else if (thisOptions.sentimentsTwitter === false) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitter: value,
					sentimentsTwitterStandard: value
				})
			} else if (thisOptions.sentimentsTwitterBalance === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitterStandard: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitter: value,
					sentimentsTwitterStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterStandard: value
				})
			}
			break
		case 'sentimentsTwitterBalance':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value
				})
			} else if (thisOptions.sentimentsTwitter === false) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value
				})
			} else if (thisOptions.sentimentsTwitterStandard === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitterBalance: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					sentimentsTwitter: value,
					sentimentsTwitterBalance: value
				})
			}
			break
		case 'sentimentsFacebookStandard':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookStandard: value
				})
			} else if (thisOptions.sentimentsFacebook === false) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebook: value,
					sentimentsFacebookStandard: value
				})
			} else if (thisOptions.sentimentsFacebookBalance === true) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebookStandard: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebook: value,
					sentimentsFacebookStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookStandard: value
				})
			}
			break
		case 'sentimentsFacebookBalance':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value
				})
			} else if (thisOptions.sentimentsFacebook === false) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value
				})
			} else if (thisOptions.sentimentsFacebookStandard === true) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebookBalance: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					sentimentsFacebook: value,
					sentimentsFacebookBalance: value
				})
			}
			break
		case 'sentimentsInstagramStandard':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramStandard: value
				})
			} else if (thisOptions.sentimentsInstagram === false) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagram: value,
					sentimentsInstagramStandard: value
				})
			} else if (thisOptions.sentimentsInstagramBalance === true) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagramStandard: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagram: value,
					sentimentsInstagramStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramStandard: value
				})
			}
			break
		case 'sentimentsTiktokStandard':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokStandard: value
				})
			} else if (thisOptions.sentimentsTiktok === false) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktok: value,
					sentimentsTiktokStandard: value
				})
			} else if (thisOptions.sentimentsTiktokBalance === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktokStandard: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktok: value,
					sentimentsTiktokStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokStandard: value
				})
			}
			break
		case 'sentimentsYoutubeStandard':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeStandard: value
				})
			} else if (thisOptions.sentimentsYoutube === false) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutube: value,
					sentimentsYoutubeStandard: value
				})
			} else if (thisOptions.sentimentsYoutubeBalance === true) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutubeStandard: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutube: value,
					sentimentsYoutubeStandard: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeStandard: value
				})
			}
			break
		case 'sentimentsInstagramBalance':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value
				})
			} else if (thisOptions.sentimentsInstagram === false) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value
				})
			} else if (thisOptions.sentimentsInstagramStandard === true) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagramBalance: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					sentimentsInstagram: value,
					sentimentsInstagramBalance: value
				})
			}
			break
		case 'sentimentsTiktokBalance':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value
				})
			} else if (thisOptions.sentimentsTiktok === false) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value
				})
			} else if (thisOptions.sentimentsTiktokStandard === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktokBalance: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					sentimentsTiktok: value,
					sentimentsTiktokBalance: value
				})
			}
			break
		case 'sentimentsYoutubeBalance':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value
				})
			} else if (thisOptions.sentimentsYoutube === false) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value
				})
			} else if (thisOptions.sentimentsYoutubeStandard === true) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutubeBalance: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					sentimentsYoutube: value,
					sentimentsYoutubeBalance: value
				})
			}
			break
		case 'timelineTwitterEngagement':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterEngagement: value
				})
			} else if (thisOptions.timelineTwitter === false) {
				setThisOptions({
					...thisOptions,
					timelineTwitter: value,
					timelineTwitterEngagement: value
				})
			} else if (thisOptions.timelineTwitterPosts === true) {
				setThisOptions({
					...thisOptions,
					timelineTwitterEngagement: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTwitter: value,
					timelineTwitterEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterEngagement: value
				})
			}
			break
		case 'timelineTwitterPosts':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterPosts: value
				})
			} else if (thisOptions.timelineTwitter === false) {
				setThisOptions({
					...thisOptions,
					timelineTwitter: value,
					timelineTwitterPosts: value
				})
			} else if (thisOptions.timelineTwitterEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineTwitterPosts: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTwitter: value,
					timelineTwitterPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					timelineTwitter: value,
					timelineTwitterPosts: value
				})
			}
			break
		case 'timelineFacebookEngagement':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookEngagement: value
				})
			} else if (thisOptions.timelineFacebook === false) {
				setThisOptions({
					...thisOptions,
					timelineFacebook: value,
					timelineFacebookEngagement: value
				})
			} else if (thisOptions.timelineFacebookPosts === true) {
				setThisOptions({
					...thisOptions,
					timelineFacebookEngagement: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineFacebook: value,
					timelineFacebookEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookEngagement: value
				})
			}
			break
		case 'timelineFacebookPosts':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookPosts: value
				})
			} else if (thisOptions.timelineFacebook === false) {
				setThisOptions({
					...thisOptions,
					timelineFacebook: value,
					timelineFacebookPosts: value
				})
			} else if (thisOptions.timelineFacebookEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineFacebookPosts: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineFacebook: value,
					timelineFacebookPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					timelineFacebook: value,
					timelineFacebookPosts: value
				})
			}
			break
		case 'timelineInstagramEngagement':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramEngagement: value
				})
			} else if (thisOptions.timelineInstagram === false) {
				setThisOptions({
					...thisOptions,
					timelineInstagram: value,
					timelineInstagramEngagement: value
				})
			} else if (thisOptions.timelineInstagramPosts === true) {
				setThisOptions({
					...thisOptions,
					timelineInstagramEngagement: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineInstagram: value,
					timelineInstagramEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramEngagement: value
				})
			}
			break
		case 'timelineInstagramPosts':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramPosts: value
				})
			} else if (thisOptions.timelineInstagram === false) {
				setThisOptions({
					...thisOptions,
					timelineInstagram: value,
					timelineInstagramPosts: value
				})
			} else if (thisOptions.timelineInstagramEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineInstagramPosts: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineInstagram: value,
					timelineInstagramPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					timelineInstagram: value,
					timelineInstagramPosts: value
				})
			}
			break
		case 'timelineTiktokEngagement':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokEngagement: value
				})
			} else if (thisOptions.timelineTiktok === false) {
				setThisOptions({
					...thisOptions,
					timelineTiktok: value,
					timelineTiktokEngagement: value
				})
			} else if (thisOptions.timelineTiktokPosts === true) {
				setThisOptions({
					...thisOptions,
					timelineTiktokEngagement: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTiktok: value,
					timelineTiktokEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokEngagement: value
				})
			}
			break
		case 'timelineTiktokPosts':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokPosts: value
				})
			} else if (thisOptions.timelineTiktok === false) {
				setThisOptions({
					...thisOptions,
					timelineTiktok: value,
					timelineTiktokPosts: value
				})
			} else if (thisOptions.timelineTiktokEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineTiktokPosts: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineTiktok: value,
					timelineTiktokPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					timelineTiktok: value,
					timelineTiktokPosts: value
				})
			}
			break
		case 'timelineYoutubeEngagement':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubeEngagement: value
				})
			} else if (thisOptions.timelineYoutube === false) {
				setThisOptions({
					...thisOptions,
					timelineYoutube: value,
					timelineYoutubeEngagement: value
				})
			} else if (thisOptions.timelineYoutubePosts === true) {
				setThisOptions({
					...thisOptions,
					timelineYoutubeEngagement: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineYoutube: value,
					timelineYoutubeEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubeEngagement: value
				})
			}
			break
		case 'timelineYoutubePosts':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubePosts: value
				})
			} else if (thisOptions.timelineYoutube === false) {
				setThisOptions({
					...thisOptions,
					timelineYoutube: value,
					timelineYoutubePosts: value
				})
			} else if (thisOptions.timelineYoutubeEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineYoutubePosts: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineYoutube: value,
					timelineYoutubePosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					timelineYoutube: value,
					timelineYoutubePosts: value
				})
			}
			break
		case 'timelineNewsEngagement':
			if (thisOptions.news === false) {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsEngagement: value
				})
			} else if (thisOptions.timelineNews === false) {
				setThisOptions({
					...thisOptions,
					timelineNews: value,
					timelineNewsEngagement: value
				})
			} else if (thisOptions.timelineNewsPosts === true) {
				setThisOptions({
					...thisOptions,
					timelineNewsEngagement: value
				})
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineNews: value,
					timelineNewsEngagement: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsEngagement: value
				})
			}
			break
		case 'timelineNewsPosts':
			if (thisOptions.news === false) {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsPosts: value
				})
			} else if (thisOptions.timelineNews === false) {
				setThisOptions({
					...thisOptions,
					timelineNews: value,
					timelineNewsPosts: value
				})
			} else if (thisOptions.timelineNewsEngagement === true) {
				setThisOptions({
					...thisOptions,
					timelineNewsPosts: value
				})
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					timelineNews: value,
					timelineNewsPosts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					news: value,
					timelineNews: value,
					timelineNewsPosts: value
				})
			}
			break
		case 'networkTwitterAccounts':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterAccounts: value
				})
			} else if (thisOptions.networkTwitter === false) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterAccounts: value
				})
			} else if (thisOptions.networkTwitterSentiments === true || thisOptions.networkTwitterHashtags === true) {
				setThisOptions({
					...thisOptions,
					networkTwitterAccounts: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterAccounts: value
				})
			}
			break
		case 'networkTwitterSentiments':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterSentiments: value
				})
			} else if (thisOptions.networkTwitter === false) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterSentiments: value
				})
			} else if (thisOptions.networkTwitterAccounts === true || thisOptions.networkTwitterHashtags === true) {
				setThisOptions({
					...thisOptions,
					networkTwitterSentiments: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterSentiments: value
				})
			}
			break
		case 'networkTwitterHashtags':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterHashtags: value
				})
			} else if (thisOptions.networkTwitter === false) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterHashtags: value
				})
			} else if (thisOptions.networkTwitterSentiments === true || thisOptions.networkTwitterAccounts === true) {
				setThisOptions({
					...thisOptions,
					networkTwitterHashtags: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTwitter: value,
					networkTwitterHashtags: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					networkTwitter: value,
					networkTwitterHashtags: value
				})
			}
			break
		case 'networkFacebookAccounts':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookAccounts: value
				})
			} else if (thisOptions.networkFacebook === false) {
				setThisOptions({
					...thisOptions,
					networkFacebook: value,
					networkFacebookAccounts: value
				})
			} else if (thisOptions.networkFacebookSentiments === true) {
				setThisOptions({
					...thisOptions,
					networkFacebookAccounts: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkFacebook: value,
					networkFacebookAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookAccounts: value
				})
			}
			break
		case 'networkFacebookSentiments':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookSentiments: value
				})
			} else if (thisOptions.networkFacebook === false) {
				setThisOptions({
					...thisOptions,
					networkFacebook: value,
					networkFacebookSentiments: value
				})
			} else if (thisOptions.networkFacebookAccounts === true) {
				setThisOptions({
					...thisOptions,
					networkFacebookSentiments: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkFacebook: value,
					networkFacebookSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkFacebook: value,
					networkFacebookSentiments: value
				})
			}
			break
		case 'networkInstagramAccounts':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					networkInstagram: value,
					networkInstagramAccounts: value
				})
			} else if (thisOptions.networkInstagram === false) {
				setThisOptions({
					...thisOptions,
					networkInstagram: value,
					networkInstagramAccounts: value
				})
			} else if (thisOptions.networkInstagramSentiments === true) {
				setThisOptions({
					...thisOptions,
					networkInstagramAccounts: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkInstagram: value,
					networkInstagramAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					networkInstagram: value,
					networkInstagramAccounts: value
				})
			}
			break
		case 'networkInstagramSentiments':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					networkInstagram: value,
					networkInstagramSentiments: value
				})
			} else if (thisOptions.networkInstagram === false) {
				setThisOptions({
					...thisOptions,
					networkInstagram: value,
					networkInstagramSentiments: value
				})
			} else if (thisOptions.networkInstagramAccounts === true) {
				setThisOptions({
					...thisOptions,
					networkInstagramSentiments: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkInstagram: value,
					networkInstagramSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkInstagram: value,
					networkInstagramSentiments: value
				})
			}
			break
		case 'networkTiktokAccounts':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					networkTiktok: value,
					networkTiktokAccounts: value
				})
			} else if (thisOptions.networkTiktok === false) {
				setThisOptions({
					...thisOptions,
					networkTiktok: value,
					networkTiktokAccounts: value
				})
			} else if (thisOptions.networkTiktokSentiments === true) {
				setThisOptions({
					...thisOptions,
					networkTiktokAccounts: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTiktok: value,
					networkTiktokAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					networkTiktok: value,
					networkTiktokAccounts: value
				})
			}
			break
		case 'networkTiktokSentiments':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					networkTiktok: value,
					networkTiktokSentiments: value
				})
			} else if (thisOptions.networkTiktok === false) {
				setThisOptions({
					...thisOptions,
					networkTiktok: value,
					networkTiktokSentiments: value
				})
			} else if (thisOptions.networkTiktokAccounts === true) {
				setThisOptions({
					...thisOptions,
					networkTiktokSentiments: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkTiktok: value,
					networkTiktokSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkTiktok: value,
					networkTiktokSentiments: value
				})
			}
			break
		case 'networkYoutubeAccounts':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					networkYoutube: value,
					networkYoutubeAccounts: value
				})
			} else if (thisOptions.networkYoutube === false) {
				setThisOptions({
					...thisOptions,
					networkYoutube: value,
					networkYoutubeAccounts: value
				})
			} else if (thisOptions.networkYoutubeSentiments === true) {
				setThisOptions({
					...thisOptions,
					networkYoutubeAccounts: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkYoutube: value,
					networkYoutubeAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					networkYoutube: value,
					networkYoutubeAccounts: value
				})
			}
			break
		case 'networkYoutubeSentiments':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					networkYoutube: value,
					networkYoutubeSentiments: value
				})
			} else if (thisOptions.networkYoutube === false) {
				setThisOptions({
					...thisOptions,
					networkYoutube: value,
					networkYoutubeSentiments: value
				})
			} else if (thisOptions.networkYoutubeAccounts === true) {
				setThisOptions({
					...thisOptions,
					networkYoutubeSentiments: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkYoutube: value,
					networkYoutubeSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					networkYoutube: value,
					networkYoutubeSentiments: value
				})
			}
			break
		case 'networkNewsAccounts':
			if (thisOptions.news === false) {
				setThisOptions({
					...thisOptions,
					news: value,
					networkNews: value,
					networkNewsAccounts: value
				})
			} else if (thisOptions.networkNews === false) {
				setThisOptions({
					...thisOptions,
					networkNews: value,
					networkNewsAccounts: value
				})
			} else if (verifyOptions('news', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					networkNews: value,
					networkNewsAccounts: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					news: value,
					networkNews: value,
					networkNewsAccounts: value
				})
			}
			break
		case 'locationTwitterVolume':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterVolume: value
				})
			} else if (thisOptions.locationTwitter === false) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterVolume: value
				})
			} else if (thisOptions.locationTwitterSentiments === true || thisOptions.locationTwitterGender === true) {
				setThisOptions({
					...thisOptions,
					locationTwitterVolume: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterVolume: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterVolume: value
				})
			}
			break
		case 'locationTwitterSentiments':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterSentiments: value
				})
			} else if (thisOptions.locationTwitter === false) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterSentiments: value
				})
			} else if (thisOptions.locationTwitterVolume === true || thisOptions.locationTwitterGender === true) {
				setThisOptions({
					...thisOptions,
					locationTwitterSentiments: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterSentiments: value
				})
			}
			break
		case 'locationTwitterGender':
			if (thisOptions.twitter === false) {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterGender: value
				})
			} else if (thisOptions.locationTwitter === false) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterGender: value
				})
			} else if (thisOptions.locationTwitterSentiments === true || thisOptions.locationTwitterVolume === true) {
				setThisOptions({
					...thisOptions,
					locationTwitterGender: value
				})
			} else if (verifyOptions('twitter', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTwitter: value,
					locationTwitterGender: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					twitter: value,
					locationTwitter: value,
					locationTwitterGender: value
				})
			}
			break
		case 'locationFacebookVolume':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookVolume: value
				})
			} else if (thisOptions.locationFacebook === false) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookVolume: value
				})
			} else if (thisOptions.locationFacebookSentiments === true || thisOptions.locationFacebookGender === true) {
				setThisOptions({
					...thisOptions,
					locationFacebookVolume: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookVolume: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookVolume: value
				})
			}
			break
		case 'locationFacebookSentiments':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookSentiments: value
				})
			} else if (thisOptions.locationFacebook === false) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookSentiments: value
				})
			} else if (thisOptions.locationFacebookVolume === true || thisOptions.locationFacebookGender === true) {
				setThisOptions({
					...thisOptions,
					locationFacebookSentiments: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookSentiments: value
				})
			}
			break
		case 'locationFacebookGender':
			if (thisOptions.facebook === false) {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookGender: value
				})
			} else if (thisOptions.locationFacebook === false) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookGender: value
				})
			} else if (thisOptions.locationFacebookSentiments === true || thisOptions.locationFacebookVolume === true) {
				setThisOptions({
					...thisOptions,
					locationFacebookGender: value
				})
			} else if (verifyOptions('facebook', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationFacebook: value,
					locationFacebookGender: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					facebook: value,
					locationFacebook: value,
					locationFacebookGender: value
				})
			}
			break
		case 'locationInstagramVolume':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramVolume: value
				})
			} else if (thisOptions.locationInstagram === false) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramVolume: value
				})
			} else if (thisOptions.locationInstagramSentiments === true || thisOptions.locationInstagramGender === true) {
				setThisOptions({
					...thisOptions,
					locationInstagramVolume: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramVolume: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramVolume: value
				})
			}
			break
		case 'locationInstagramSentiments':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramSentiments: value
				})
			} else if (thisOptions.locationInstagram === false) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramSentiments: value
				})
			} else if (thisOptions.locationInstagramVolume === true || thisOptions.locationInstagramGender === true) {
				setThisOptions({
					...thisOptions,
					locationInstagramSentiments: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramSentiments: value
				})
			}
			break
		case 'locationInstagramGender':
			if (thisOptions.instagram === false) {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramGender: value
				})
			} else if (thisOptions.locationInstagram === false) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramGender: value
				})
			} else if (thisOptions.locationInstagramSentiments === true || thisOptions.locationInstagramVolume === true) {
				setThisOptions({
					...thisOptions,
					locationInstagramGender: value
				})
			} else if (verifyOptions('instagram', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationInstagram: value,
					locationInstagramGender: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					instagram: value,
					locationInstagram: value,
					locationInstagramGender: value
				})
			}
			break
		case 'locationTiktokVolume':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokVolume: value
				})
			} else if (thisOptions.locationTiktok === false) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokVolume: value
				})
			} else if (thisOptions.locationTiktokSentiments === true || thisOptions.locationTiktokGender === true) {
				setThisOptions({
					...thisOptions,
					locationTiktokVolume: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokVolume: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokVolume: value
				})
			}
			break
		case 'locationTiktokSentiments':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokSentiments: value
				})
			} else if (thisOptions.locationTiktok === false) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokSentiments: value
				})
			} else if (thisOptions.locationTiktokVolume === true || thisOptions.locationTiktokGender === true) {
				setThisOptions({
					...thisOptions,
					locationTiktokSentiments: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokSentiments: value
				})
			}
			break
		case 'locationTiktokGender':
			if (thisOptions.tiktok === false) {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokGender: value
				})
			} else if (thisOptions.locationTiktok === false) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokGender: value
				})
			} else if (thisOptions.locationTiktokSentiments === true || thisOptions.locationTiktokVolume === true) {
				setThisOptions({
					...thisOptions,
					locationTiktokGender: value
				})
			} else if (verifyOptions('tiktok', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationTiktok: value,
					locationTiktokGender: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					tiktok: value,
					locationTiktok: value,
					locationTiktokGender: value
				})
			}
			break
		case 'locationYoutubeVolume':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeVolume: value
				})
			} else if (thisOptions.locationYoutube === false) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeVolume: value
				})
			} else if (thisOptions.locationYoutubeSentiments === true || thisOptions.locationYoutubeGender === true) {
				setThisOptions({
					...thisOptions,
					locationYoutubeVolume: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeVolume: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeVolume: value
				})
			}
			break
		case 'locationYoutubeSentiments':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeSentiments: value
				})
			} else if (thisOptions.locationYoutube === false) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeSentiments: value
				})
			} else if (thisOptions.locationYoutubeVolume === true || thisOptions.locationYoutubeGender === true) {
				setThisOptions({
					...thisOptions,
					locationYoutubeSentiments: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeSentiments: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeSentiments: value
				})
			}
			break
		case 'locationYoutubeGender':
			if (thisOptions.youtube === false) {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeGender: value
				})
			} else if (thisOptions.locationYoutube === false) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeGender: value
				})
			} else if (thisOptions.locationYoutubeSentiments === true || thisOptions.locationYoutubeVolume === true) {
				setThisOptions({
					...thisOptions,
					locationYoutubeGender: value
				})
			} else if (verifyOptions('youtube', thisOptions) === true) {
				setThisOptions({
					...thisOptions,
					locationYoutube: value,
					locationYoutubeGender: value
				})
			} else {
				setThisOptions({
					...thisOptions,
					youtube: value,
					locationYoutube: value,
					locationYoutubeGender: value
				})
			}
			break
	}
}

export { toggleReportContentOption }
