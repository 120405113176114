import React, { useEffect, useState, memo, useRef } from 'react'
import RadarChart from 'graphs/RadarChart/RadarChart'
import { Grid, Card } from '@mui/material'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import api from 'service/service'
import { RadarIcon } from 'utils/SystemIcons'
import GraphTitle from 'components/GraphTitle/GraphTitle'

const emptyProp = { series: [], labels: [] }

const PoliticalRadar = ({ cards, globalFiltersRedux, externalFilters }) => {
	const [data, setData] = useState(emptyProp)
	const [thisFilters, setThisFilters] = useState(externalFilters || globalFiltersRedux)
	const isMounted = useRef(true)
	const loadingBarRef = useRef()

	useEffect(() => {
		if (externalFilters) {
			setThisFilters(externalFilters)
		} else {
			setThisFilters(globalFiltersRedux)
		}
	}, [externalFilters, globalFiltersRedux])

	useEffect(() => {
		load()
	}, [])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const getBody = () => ({
		configGlobalFilters: {
			cardIds: cards?.map((card) => card.id),
			cardType: cards[0].type.toUpperCase()
		},
		globalFilters: thisFilters
	})

	const load = () => {
		onPartialLoading(true)
		api.post('/political-radar/get-radar', getBody()).then((res) => {
			if (isMounted.current) {
				setData(res?.data || emptyProp)
				onPartialLoading(false)
			}
		})
	}

	return (
		<Card style={{ background: '#FFF', borderRadius: '20px' }}>
			<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
				<Grid item xs={12}>
					<GraphTitle id='span-political-radar' title='RADAR POLÍTICO' icon={<RadarIcon size={25} style={{ color: '#1d8cf8' }} />} />
				</Grid>
			</Grid>
			<LoadingBar ref={loadingBarRef} />
			<RadarChart
				height={'300%'}
				width='100%'
				series={data?.series}
				colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']}
				labels={data?.labels}
			/>
		</Card>
	)
}

export default memo(PoliticalRadar)
