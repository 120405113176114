import React from 'react'
import DialogInformation from 'components/DialogInformation/DialogInformation'
import { Grid } from '@mui/material'

const defaultStyle = { display: 'flex', marginLeft: '15px', alignItems: 'center', marginTop: '5px' }
const defaultTextStyle = {
	whiteSpace: 'nowrap',
	fontFamily: 'Poppins',
	fontSize: '16px',
	marginLeft: '15px',
	fontWeight: '500',
	color: '#878787',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const centeredStyle = { display: 'flex', alignItems: 'center' }
const centeredTextStyle = {
	whiteSpace: 'nowrap',
	fontFamily: 'Poppins',
	fontSize: '16px',
	fontWeight: '500',
	color: '#878787',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const centeredIconStyle = {
	marginTop: '4px',
	marginRight: '8px'
}

const defaultIconStyle = {
	marginTop: '4px'
}

const GraphTitle = ({ id, title, icon, description, titleCentered = false, isReport = false }) => {
	return (
		<Grid item xs={titleCentered ? 12 : 4} container direction='row' justifyContent={titleCentered ? 'center' : 'flex-start'} alignItems='center'>
			<div style={titleCentered ? centeredStyle : defaultStyle}>
				{icon && <div style={titleCentered ? centeredIconStyle : defaultIconStyle}>{icon}</div>}
				<div id={id} style={titleCentered ? centeredTextStyle : defaultTextStyle}>
					{title !== '' && title?.charAt(0)?.toUpperCase() + title?.slice(1)}
				</div>
				{description !== '' && description && !isReport && <DialogInformation title={title} text={description} />}
			</div>
		</Grid>
	)
}

export default GraphTitle
