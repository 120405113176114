import React, { useState, useEffect } from 'react'
import { Drawer, Grid, IconButton, TextField, Button, styled } from '@mui/material'
import { ChevronRightIcon, CloudUploadIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'
import '../../GlobalFilters.css'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const SaveFilter = ({ isOpen, toggleSaveMenu, type, config }) => {
	const [filterName, setFilterName] = useState('')
	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		setFilterName('')
		setSubmitting(false)
		setError(false)
	}, [isOpen])

	const saveFilter = async (event) => {
		event.preventDefault()

		setSubmitting(true)
		if (filterName === '') {
			setError(true)
			setSubmitting(false)
		} else {
			setError(false)
			api.post('/global-filters/save-filter', {
				filterName,
				type,
				config
			})
				.then((response) => {
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => {
					setSubmitting(false)
					toggleSaveMenu()
				})
		}
	}

	const handleInputChange = (e) => {
		setFilterName(e.target.value)
	}

	return (
		<Drawer
			anchor='right'
			open={isOpen}
			onClose={() => toggleSaveMenu()}
			className='right-drawer-system'
			BackdropProps={{
				timeout: 500,
				style: { backdropFilter: 'blur(3px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
			}}
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<IconButton id='btn-toggle-save-filter' color='primary' onClick={toggleSaveMenu}>
					<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
				</IconButton>
			</Grid>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						backgroundColor: 'white',
						paddingTop: '0.5em',
						paddingLeft: '1em',
						paddingRight: '1em',
						paddingBottom: '1em',
						width: '25em',
						height: '14em',
						marginTop: '1em',
						marginLeft: '3em',
						borderRadius: '25px'
					}}
				>
					<Grid item xs={12} style={{ fontFamily: 'Poppins' }} container justifyContent='flex-start' alignItems='center'>
						<CloudUploadIcon size={25} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						Salvar Filtro Atual
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em' }}>
						<CssTextField
							id='input-filter-name'
							disabled={submitting}
							fullWidth
							variant='standard'
							onChange={handleInputChange}
							value={filterName}
							label={'Nome do filtro'}
							error={error}
							helperText={error ? 'Escreva um nome para o filtro' : ' '}
						/>
					</Grid>
					<Grid item xs={12} container justifyContent='center' alignItems='center'>
						<Button
							id='btn-save-filter'
							disabled={submitting}
							onClick={saveFilter}
							variant='outlined'
							style={{ marginBottom: '1em', color: blue, borderRadius: '100px', fontFamily: 'Poppins' }}
						>
							Salvar filtro
						</Button>
					</Grid>
				</Grid>
			</div>
		</Drawer>
	)
}

export default SaveFilter
