import React from 'react'
import { Drawer, Button, Grid, IconButton, TextField, styled } from '@mui/material'
import { ChevronRightIcon, CloudUploadIcon } from 'utils/SystemIcons'
const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const DashboardSaveMenu = ({ isOpen, toggleSaveMenu, layoutName, onSaveLayout, onChangeTemplateName, error = false }) => {
	const SaveLayout = () => {
		toggleSaveMenu()
		onSaveLayout(null, null)
	}

	return (
		<Drawer
			anchor='right'
			open={isOpen}
			onClose={() => toggleSaveMenu()}
			className='right-drawer-system'
			BackdropProps={{
				timeout: 500,
				style: { backdropFilter: 'blur(3px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
			}}
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<IconButton id='btn-toggle-save-menu-dashboard' color='primary' onClick={toggleSaveMenu}>
					<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
				</IconButton>
			</Grid>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						backgroundColor: 'white',
						paddingTop: '0.5em',
						paddingLeft: '1em',
						paddingRight: '1em',
						paddingBottom: '1em',
						width: '25em',
						height: '14em',
						marginTop: '1em',
						marginLeft: '3em',
						borderRadius: '25px'
					}}
				>
					<Grid item xs={12} style={{ fontFamily: 'Poppins' }} container justifyContent='flex-start' alignItems='center'>
						<CloudUploadIcon size={25} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						Salvar dashboard como template
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em' }}>
						<CssTextField
							id='input-dashboard-name'
							// disabled={submitting}
							fullWidth
							variant='standard'
							onChange={onChangeTemplateName}
							value={layoutName}
							label={'Nome do dashboard'}
							error={error}
							helperText={error ? 'Escreva um nome para o dashboard' : ' '}
						/>
					</Grid>
					<Grid item xs={12} container justifyContent='center' alignItems='center'>
						<Button
							id='btn-save-dashboard'
							// disabled={submitting}
							onClick={SaveLayout}
							variant='outlined'
							style={{ marginBottom: '1em', color: blue, borderRadius: '100px', fontFamily: 'Poppins' }}
						>
							Salvar Dashboard
						</Button>
					</Grid>
				</Grid>
			</div>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						marginTop: '0.1em',
						paddingBottom: '1em',
						paddingLeft: '1em',
						paddingRight: '1em',
						maxHeight: '84vh',
						overflow: 'auto'
					}}
				>
					{/* {savedLayoutsList.length > 0 &&
						savedLayoutsList.map((dashboard) => (
							<DashboardOption
								key={dashboard?.id}
								dashboard={dashboard}
								toggleLoadMenu={toggleLoadMenu}
								onLayoutApply={onLayoutApply}
								onDeleteLayout={onDeleteLayout}
							/>
						))}
					{savedLayoutsList.length === 0 && (
						<Grid
							item
							xs={12}
							style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Poppins', fontSize: '24px', color: '#d6d6d6' }}
						>
							salve um dashboard para ver aqui
						</Grid>
					)} */}
				</Grid>
			</div>
		</Drawer>
	)
}

export default DashboardSaveMenu
