import React from 'react'
import Skeleton from 'react-loading-skeleton'

const OccurrenceSkeletonLoad = ({ isWidget = false }) => (
	<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
		<div style={{ marginBottom: '25px', marginLeft: isWidget ? '25px' : '0px', marginRight: isWidget ? '25px' : '0px' }}>
			<Skeleton height='300px' width='100%' style={{ borderRadius: '20px', lineHeight: 'inherit' }} />
		</div>
	</div>
)

export default OccurrenceSkeletonLoad
