import React, { useEffect, useState } from 'react'
import imageEmpty from 'assets/img/twitterEmpty.jpg'
import { makeStyles } from '@mui/styles'
import { Grid, Button, Dialog, DialogContent } from '@mui/material'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		color: blue,
		background: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const SummaryItem = ({ item, rank, color = '#1d8cf8', order = 'score' }) => {
	const [image, setImage] = useState({})
	const [open, setOpen] = useState(false)
	useEffect(() => {
		if (item.author.profileImageUrl) {
			setImage(item.author.profileImageUrl)
		} else {
			setImage(imageEmpty)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const classes = useStyles()

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const checkImageError = () => {
		setImage(imageEmpty)
	}

	const openExternalLink = () => {
		window.open(item?.author?.link, '_blank')
	}
	const CheckItemType = () => (
		<Grid
			container
			item
			style={{
				marginLeft: '5.8em',
				alignItems: 'center',
				display: 'contents'
			}}
		>
			<div style={{ width: '50px', height: '50px', marginLeft: '10px', marginTop: '-5px' }}>
				<Button onClick={handleClickOpen}>
					<img
						style={{
							borderRadius: '50px',
							height: '50px',
							width: '50px',
							border: `solid 1px ${color}`
						}}
						src={image}
						onError={checkImageError}
					/>
				</Button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					fullWidth={true}
					maxWidth='sm'
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						classes: {
							root: classes.backDrop
						}
					}}
				>
					<DialogContent style={{ overflowY: 'hidden' }}>
						<Grid container style={{ marginBottom: '25px' }}>
							<Grid item xs={6} style={{ display: 'flex' }}>
								<img
									style={{
										marginLeft: '6px',
										borderRadius: '50px',
										height: '50px',
										width: '50px',
										border: `solid 1px ${color}`
									}}
									src={image}
									onError={checkImageError}
								/>
								<div style={{ fontFamily: 'Poppins', fontSize: '20px', color: color, marginTop: '10px', marginLeft: '10px' }}>
									<b>{item?.key}</b>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div style={{ marginTop: '8px', float: 'right' }}>
									<Button onClick={openExternalLink} style={{ color: blue }}>
										Abrir
									</Button>
								</div>
							</Grid>
							<Grid item xs={6}>
								<div style={{ fontFamily: 'Poppins', fontSize: '17px', color: color, marginTop: '10px', marginLeft: '10px' }}>
									<b>Score posts</b>
									<b style={{ marginLeft: '10px' }}>{Math.round(item?.impactMetric)}</b>
								</div>
								{!!item?.score && (
									<div style={{ fontFamily: 'Poppins', fontSize: '12px', color: color, marginTop: '0px', marginLeft: '10px' }}>
										<b>Score perfil</b>
										<b style={{ marginLeft: '10px' }}>{Math.round(item?.score)}</b>
									</div>
								)}
							</Grid>
							<Grid item xs={6}>
								<div style={{ fontFamily: 'Poppins', fontSize: '17px', color: color, marginTop: '10px', marginLeft: '10px' }}>
									<b>Rank</b>
									<b style={{ marginLeft: '10px' }}>#{rank}</b>
								</div>
							</Grid>
							{!!item?.author?.likeCountAuthor && (
								<Grid item xs={6} style={{ display: 'flex' }}>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '20px',
											color: '#474747',
											marginTop: '10px',
											marginLeft: '10px'
										}}
									>
										<b>{item?.author?.likeCountAuthor}</b>
									</div>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '14px',
											color: '#474747',
											marginTop: '15px',
											marginLeft: '7px'
										}}
									>
										Likes da conta
									</div>
								</Grid>
							)}
							{!!item?.engagement?.likeCount && (
								<Grid item xs={6} style={{ display: 'flex' }}>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '20px',
											color: '#474747',
											marginTop: '10px',
											marginLeft: '10px'
										}}
									>
										<b>{item?.engagement?.likeCount}</b>
									</div>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '14px',
											color: '#474747',
											marginTop: '15px',
											marginLeft: '7px'
										}}
									>
										Likes nos posts em questão
									</div>
								</Grid>
							)}
							{!!item?.author?.followerCount && (
								<Grid item xs={6} style={{ display: 'flex' }}>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '20px',
											color: '#474747',
											marginTop: '10px',
											marginLeft: '10px'
										}}
									>
										<b>{item?.author?.followerCount}</b>
									</div>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '14px',
											color: '#474747',
											marginTop: '15px',
											marginLeft: '7px'
										}}
									>
										Seguidores
									</div>
								</Grid>
							)}
							{!!item?.author?.followingCount && (
								<Grid item xs={6} style={{ display: 'flex' }}>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '20px',
											color: '#474747',
											marginTop: '10px',
											marginLeft: '10px'
										}}
									>
										<b>{item?.author?.followingCount}</b>
									</div>
									<div
										style={{
											fontFamily: 'Poppins',
											fontSize: '14px',
											color: '#474747',
											marginTop: '15px',
											marginLeft: '7px'
										}}
									>
										Seguindo
									</div>
								</Grid>
							)}
							{!!item?.author?.bioText && (
								<>
									<Grid item xs={12}>
										<div
											style={{
												fontFamily: 'Poppins',
												fontSize: '17px',
												color: '#474747',
												marginTop: '10px',
												marginLeft: '10px'
											}}
										>
											<b>Bio</b>
										</div>
									</Grid>
									<Grid item xs={12}>
										<div
											style={{
												fontFamily: 'Poppins',
												fontSize: '12px',
												color: '#474747',
												marginTop: '10px',
												marginLeft: '10px'
											}}
										>
											{item?.author?.bioText}
										</div>
									</Grid>
								</>
							)}
						</Grid>
					</DialogContent>
				</Dialog>
			</div>
			<Grid item container style={{ marginLeft: '20px', marginTop: '20px' }}>
				<Grid item xs={12} style={{ fontFamily: 'Poppins', fontSize: '15px', color: color }}>
					<b>{item?.key}</b>
				</Grid>
				{order === 'score' && (
					<>
						<Grid item xs={12} style={{ fontFamily: 'Poppins', marginLeft: '0px', marginTop: '-20px' }}>
							<h5> Score posts: {Math.round(item?.impactMetric)} </h5>
						</Grid>
						{!!item?.score && (
							<Grid item xs={12} style={{ fontFamily: 'Poppins', marginLeft: '0px', marginTop: '-50px' }}>
								<h6> Score perfil: {Math.round(item?.score)} </h6>
							</Grid>
						)}
					</>
				)}
				{order === 'posts' && (
					<>
						<Grid item xs={12} style={{ fontFamily: 'Poppins', marginLeft: '0px', marginTop: '-20px' }}>
							<h5> Número de posts: {Math.round(item?.author?.postCount)} </h5>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	)

	return <CheckItemType />
}

export default SummaryItem
