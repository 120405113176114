import React, { useEffect, useState, useRef } from 'react'
import { Tooltip, InputAdornment, TextField, Autocomplete, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BsFillCircleFill } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { connect } from 'react-redux'
import { removeCardComparisonAction, addCardComparisonAction } from 'store/redux/actions/card.actions'
import ModalCardInfo from 'components/ModalCardInfo/ModalCardInfo'
import './ComparativeBarItem.css'
import ComparativeBarItemMenu from '../ComparativeBarItemMenu/ComparativeBarItemMenu'
import ModalUpdatePosts from './components/ModalUpdatePosts/ModalUpdatePosts'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles(() => ({
	'@global': {
		'.MuiAutocomplete-option': {
			color: '#1D253B',
			fontFamily: 'Poppins'
		}
	},
	quantityRoot: {
		color: 'black',
		backgroundColor: 'transparent',
		opacity: 1,
		borderRadius: '20px',
		'&:hover': {
			backgroundColor: 'transparent',
			borderRadius: '20px',
			opacity: 1
		},
		'&:focus-within': {
			backgroundColor: 'transparent',
			borderRadius: '20px',
			opacity: 1
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid transparent'
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: '1px solid transparent'
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid transparent',
			borderRadius: '20px',
			outline: 'none'
		},
		'& .Mui-disabled': {
			color: 'transparent',
			opacity: 1
		},
		'& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
			border: '1px solid transparent'
		}
	},
	selectRoot: {
		color: 'transparent'
	},
	icon: {
		color: 'red',
		fill: 'red'
	},
	selectPaper: {
		backgroundColor: 'transparent',
		border: '1px solid transparent',
		borderRadius: '20px',
		color: 'black',
		'& li:hover': {
			backgroundColor: 'transparent'
		}
	},
	inputRoot: {
		color: '#1D8CF8'
	},
	ripple: {
		color: 'red'
	},
	paper: {
		marginTop: '0.5em',
		minWidth: '15em',
		borderRadius: '20px',
		backdropFilter: 'blur(10px)',
		backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
	}
}))

const getColor = (index) => {
	if (index === 0) {
		return '#008FFB'
	}
	if (index === 1) {
		return '#00E396'
	}
	if (index === 2) {
		return '#FEB019'
	}
	if (index === 3) {
		return '#FF4560'
	}
	if (index === 4) {
		return '#775DD0'
	}
	if (index === 5) {
		return '#ffcc00'
	}
}
let prevSize = null

const categories = {
	GENERAL: 'GERAL',
	POLITICS: 'POLÍTICA',
	RELIGION: 'RELIGIÃO',
	'MEDIA CHANNEL': 'CANAL DE MÍDIA'
}

const ComparativeBarItem = ({
	sources,
	id = '',
	name,
	index,
	size,
	candidatesList,
	legendItems,
	isComp = false,
	addCardComparisonDispatch,
	removeCardComparisonDispatch,
	cardId,
	isSelected,
	isSolo = false,
	card
}) => {
	const [autocompleteValue, setAutocompleteValue] = useState(null)
	const [openCardInfo, setOpenCardInfo] = useState(false)
	const [openUpdatePosts, setOpenUpdatePosts] = useState(false)
	const [openMenu, setOpenMenu] = useState(false)
	const autocompleteRef = useRef()

	useEffect(() => {
		if (size !== prevSize && !isSelected) {
			setAutocompleteValue(null)
			autocompleteRef?.current.blur()
			prevSize = size
		}
	}, [size])

	useEffect(() => {
		autocompleteRef?.current.blur()
	}, [openCardInfo, openMenu])

	let color = getColor(index)

	const classes = useStyles()

	const handleOpenCardInfo = () => {
		setOpenCardInfo(true)
	}

	const handleOpenUpdatePosts = () => {
		setOpenUpdatePosts(true)
	}

	const handleCloseUpdatePosts = () => {
		setOpenUpdatePosts(false)
	}

	const handleCloseCardInfo = () => {
		setOpenCardInfo(false)
	}

	const handleOpenMenu = (status) => {
		setOpenMenu(status)
	}

	const label = (
		<div
			style={{
				display: 'flex',
				fontFamily: 'Poppins',
				marginLeft: '0.3em',
				color: '#67779e',
				marginTop: '-0.4em',
				whiteSpace: 'nowrap',
				width: '75%'
			}}
		>
			<div>
				{!isComp && <BsFillCircleFill size={10} style={{ color: color, position: 'relative', transform: 'translate(-2px, -1px)' }} />}
				{isComp && <HiPlus size={15} style={{ color: color, position: 'relative', transform: 'translate(0px, 1px)' }} />}
			</div>
			<div style={{ textTransform: 'capitalize', marginTop: '-1.5px', marginLeft: '9px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
				{name}
			</div>
		</div>
	)

	const handleChange = (newValue) => {
		setAutocompleteValue(newValue)
		const switchCardId = isSelected && cardId
		addCardComparisonDispatch(newValue, switchCardId)
	}

	const handleRemove = () => {
		removeCardComparisonDispatch(cardId)
	}

	const options = candidatesList.map((option) => {
		return {
			category: option.type !== 'group' ? categories[option.searchCategoryName] : '',
			...option
		}
	})

	return (
		<>
			<Grid item xs>
				<Tooltip title='' placement='top-start'>
					<Autocomplete
						id={id}
						key={cardId}
						noOptionsText='Não encontrado'
						classes={{
							root: classes.quantityRoot,
							inputRoot: classes.inputRoot,
							paper: classes.paper
						}}
						disableClearable
						autoComplete
						autoHighlight
						value={autocompleteValue}
						onChange={(_, value) => handleChange(value)}
						options={options.sort((a, b) => b.active - a.active).sort((a, b) => -b.category?.localeCompare(a.category))}
						groupBy={(option) => option.category}
						getOptionDisabled={(item) => !!legendItems.find((li) => li.id === item.id)}
						getOptionLabel={(option) => option.name}
						style={{ width: '100%' }}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							)
						}}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<TextField
									variant='outlined'
									{...params}
									label={label}
									inputRef={autocompleteRef}
									className='input-container-comparative-bar-item'
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment position='end'>
												<ComparativeBarItemMenu
													showUpdatePosts={
														card?.type === 'profile' &&
														['twitter', 'facebook', 'instagram', 'tiktok'].some((it) => sources.includes(it))
													}
													onToggle={handleOpenMenu}
													handleOpenCardInfo={handleOpenCardInfo}
													handleOpenUpdatePosts={handleOpenUpdatePosts}
													handleRemove={handleRemove}
													isComp={isComp}
													isSolo={isSolo}
													card={card}
												/>
											</InputAdornment>
										)
									}}
								/>
							</div>
						)}
					/>
				</Tooltip>
			</Grid>
			{openCardInfo && <ModalCardInfo isOpen={openCardInfo} handleOnClose={handleCloseCardInfo} card={card} color='#1d8cf8' />}
			{openUpdatePosts && card?.type === 'profile' && (
				<ModalUpdatePosts isOpen={openUpdatePosts} handleOnClose={handleCloseUpdatePosts} card={card} color='#1d8cf8' />
			)}
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	addCardComparisonDispatch: (id_card, switchCardIndex) => dispatch(addCardComparisonAction(id_card, switchCardIndex)),
	removeCardComparisonDispatch: (id_card) => dispatch(removeCardComparisonAction(id_card))
})

const mapStateToProps = ({ store }) => ({
	sources: store?.global?.filters?.sources
})

export default connect(mapStateToProps, mapDispatchToProps)(ComparativeBarItem)
