import React, { memo, useState, useEffect } from 'react'
import { Button, Card, Dialog, DialogContent, Grid, IconButton, Tooltip } from '@mui/material'
import { InsightIcon, CopyIcon } from 'utils/SystemIcons'
import { makeStyles } from '@mui/styles'
import api from 'service/service'
import { TypeAnimation } from 'react-type-animation'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import CopyToClipboard from 'react-copy-to-clipboard'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { GridLoader } from 'react-spinners'
import logoWhite from 'assets/img/whiteIcon.png'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { connect } from 'react-redux'
import logo from 'assets/img/react-logo-2.png'

const isFirefox = typeof InstallTrigger !== 'undefined'
const serviceName = 'topicsInsight'
const messageInsufficientData = 'Sem dados suficientes para realizar o serviço!'
const topicAnalysisStatus = constantsVox.CARD_TERM_TOPIC_ANALYSIS.STATUS
const textStyle = { color: 'black', textAlign: 'left', fontFamily: 'Poppins', whiteSpace: 'pre-wrap' }
const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles({
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	buttonLoading: {
		fontFamily: 'Poppins',
		height: '30vh',
		width: '30vh'
	},
	button: {
		fontFamily: 'Poppins',
		height: '30vh',
		width: '30vh',
		borderRadius: '30px',
		backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.1)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
		color: grey,
		background: 'transparent',
		'&:hover': {
			color: blue,
			backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.1)'}`,
			boxShadow: '0 30px 30px 0 rgba( 200, 200, 200, 0.5 )'
		}
	}
})

const TopicInsight = ({ dataTopics, cards, remainingCredits }) => {
	const [openInsight, setOpenInsight] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [creditCostAmount, setCreditCostAmount] = useState(null)
	const [infoMessage, setInfoMessage] = useState('')
	const classes = useStyles()

	const getUrlRequest = () => {
		const cardIaConfigId = dataTopics?.topics[0]?.card_ia_config_id
		return `/topic-analysis/get-insight/${cardIaConfigId}`
	}

	let { data, refetch, isFetching } = useUpdateData({
		url: getUrlRequest(),
		updateItemName: 'voxradarAiTopicsInsight',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: isLoading,
		queryName: 'voxradar_ai_topics_insight'
	})

	const load = () => {
		api.get(`/credits/get-cost-amount/${serviceName}`)
			.then((response) => {
				setCreditCostAmount(response.data)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	useEffect(() => {
		load()
	}, [])

	useEffect(() => {
		refetch()
	}, [dataTopics])

	useEffect(() => {
		data && typeof data !== 'string' && setIsLoading(false)
		if (data === topicAnalysisStatus.INSUFFICIENT_DATA) {
			setInfoMessage(messageInsufficientData)
			setIsLoading(false)
		}
	}, [isFetching])

	const handleNewInsight = () => {
		const cardIaConfigId = dataTopics?.topics[0]?.card_ia_config_id
		const cardTermId = cards[0]?.id
		api.post('/topic-analysis/new-insight', { cardIaConfigId, cardTermId })
			.then((response) => {
				setIsLoading(true)
				if (response.data.Id) {
					sendMessageToast('Enviado para processamento! Aguarde um instante!', constantsVox.TOAST.TYPES.SUCCESS)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const onClickInsightButton = () => {
		data === topicAnalysisStatus.PROCESSING && setIsLoading(true)
		data === topicAnalysisStatus.INSUFFICIENT_DATA && setInfoMessage(messageInsufficientData)
		setOpenInsight(true)
	}

	const onCopy = React.useCallback(() => {
		sendMessageToast('Texto copiado', constantsVox.TOAST.TYPES.INFO)
	}, [])

	const handleClose = () => {
		setInfoMessage('')
		setIsLoading(false)
		setOpenInsight(false)
	}

	const handleQuestionsAnswers = (insight, index) => {
		return (
			<Grid item xs={12} padding={1} key={index}>
				<Card style={{ height: '100%', width: '100%', borderRadius: '20px' }}>
					<Grid item xs={12} container style={{ background: '#fbfbfb', height: '40px', borderBottom: '1px solid #f5f5f5' }}>
						<Grid item xs={12}>
							<GraphTitle id={`span-insight-${index}`} title={`#${index}`} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div
							style={{
								marginTop: '25px',
								marginLeft: '25px',
								marginBottom: '25px',
								overflow: 'hidden'
							}}
						>
							{insight.map((questionAnswer, key) => {
								const title = key === 0 ? 'Pergunta' : 'Resposta'
								return (
									<div style={{ marginRight: '8px' }} key={key}>
										<div style={{ color: '#1d8cf8', fontSize: '17.5px' }}>{title}</div>
										<Grid container>
											<Grid item xs={11}>
												<TypeAnimation
													sequence={questionAnswer}
													speed={99}
													wrapper='div'
													cursor={false}
													repeat={1}
													style={textStyle}
												/>
											</Grid>
											<Grid item xs={1}>
												<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													<CopyToClipboard onCopy={onCopy} text={questionAnswer}>
														<Tooltip title={'Copiar texto'} placement='bottom' enterDelay={500} enterNextDelay={500}>
															<IconButton>
																<CopyIcon size={15} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
															</IconButton>
														</Tooltip>
													</CopyToClipboard>
												</div>
											</Grid>
										</Grid>
									</div>
								)
							})}
						</div>
					</Grid>
				</Card>
			</Grid>
		)
	}

	return (
		<>
			<Button
				id='btn-open-topics-insight'
				disableElevation
				onClick={onClickInsightButton}
				variant='outlined'
				style={{
					outline: 'none',
					maxHeight: '36.5px',
					whiteSpace: 'nowrap',
					marginRight: '2em'
				}}
				className={classes.mainButton}
			>
				<InsightIcon size={21} style={{ cursor: 'pointer', marginRight: '5px' }} />
				Insights
			</Button>

			{openInsight && (
				<Dialog
					open={openInsight}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					fullWidth={true}
					maxWidth={'lg'}
					PaperProps={{
						style: {
							overflowX: 'hidden',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogContent style={{ overflowY: 'auto', marginBottom: '0.2em', marginTop: '0.2em' }}>
						{!data && !isLoading && (
							<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '100px' }}>
								<Button className={classes.button} onClick={handleNewInsight} disabled={creditCostAmount > remainingCredits}>
									<div>
										<img src={logo} style={{ width: '6vw' }} />
										<div>
											<span style={{ fontSize: '17px' }}>Gerar insights dos tópicos</span>
											{creditCostAmount > remainingCredits ? (
												<div style={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
													<span style={{ marginLeft: '12px' }}>Não possui créditos suficientes</span>
												</div>
											) : (
												<p style={{ fontSize: '12px' }}>Custará {creditCostAmount} de seus créditos</p>
											)}
										</div>
									</div>
								</Button>
							</Grid>
						)}
						{isLoading && (
							<Grid item xs={12} container style={{ height: '55vh', padding: '5%' }} justifyContent='space-around' alignItems='center'>
								<Grid item md={12} lg={4} style={{ justifyContent: 'center', display: 'flex', marginTop: '0.5em' }}>
									<Button className={classes.buttonLoading} disabled>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
												width: '100%',
												marginTop: '-34px'
											}}
										>
											<div className='orb'></div>
											<div style={{ position: 'absolute' }}>
												<img src={logoWhite} style={{ width: '100px' }} />
											</div>
											<div
												style={{
													position: 'absolute',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													marginBottom: '3px'
												}}
											>
												<GridLoader color={'rgba(255,255,255, 0.2)'} size={26} />
											</div>
										</div>
									</Button>
								</Grid>
							</Grid>
						)}
						{data && typeof data !== 'string' && data.map((insight, index) => handleQuestionsAnswers(insight, index + 1))}
						{infoMessage && typeof data === 'string' && (
							<Grid container direction='row' justifyContent='center' alignItems='center' style={{ height: '400px' }}>
								<h2 id='span-insight-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
									{infoMessage}
								</h2>
							</Grid>
						)}
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	remainingCredits: store?.user?.creditInfo?.remainingMonth
})

export default connect(mapStateToProps)(memo(TopicInsight))
