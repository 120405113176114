import React, { useState, memo, useEffect } from 'react'
import Chart from 'react-apexcharts'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			toolbar: {
				show: false
			}
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: true
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const formatValue = (val) => {
	if (val !== undefined) {
		return val.toFixed(1)
	}
	return 0
}

const emptyMetrics = { post_count: 0, engagement: 0, comment_count: 0 }

const MiniCardAllBySourceNumber = ({ data, isGroup = false }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		if (data) {
			load()
		}
	}, [data])

	const load = () => {
		let categories = ['POSTAGENS', 'ENGAJAMENTO', 'COMENTÁRIOS']
		let series = []

		const element = data[0]
		const name = element?.name || ''
		let metrics = element?.selectedInterval?.metrics || emptyMetrics

		const dataMetric = Object.keys(metrics)
		for (let indexMetric = 0; indexMetric < dataMetric.length; indexMetric++) {
			if (dataMetric[indexMetric] === ('post_count' || 'engagement' || 'comment_count')) {
				series.push({
					name: name,
					data: [
						formatValue(element?.selectedInterval?.metrics['post_count'] || 0),
						formatValue(element?.selectedInterval?.metrics['engagement'] || 0),
						formatValue(element?.selectedInterval?.metrics['comment_count'] || 0)
					]
				})
			}
		}

		const optionsBars = {
			colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: true
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				textAnchor: 'middle',
				style: {
					fontSize: '13px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: 'transparent',
					opacity: 0.15
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: true,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '11px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},

			fill: {
				opacity: 1
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		setBarsData(apexData)
	}

	return (
		<Chart
			style={{
				color: 'black',
				textAlign: 'left',
				fontFamily: 'Poppins'
			}}
			options={barsData.options}
			series={barsData.series}
			type='bar'
			width={'100%'}
			height={isGroup ? '80%' : '65%'}
		/>
	)
}

export default memo(MiniCardAllBySourceNumber)
