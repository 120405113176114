import React, { memo } from 'react'
import { Button, Grid, Collapse, Card, Switch, Chip } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import { connect } from 'react-redux'

const gray = '#9a9a9a'
const blue = '#1d8cf8'

const renderStopWords = (cards) =>
	cards.map((it) => (
		<Grid item xs={12} key={it.id} style={{ marginBottom: '1em' }}>
			<div style={{ borderRadius: '20px', padding: '8px', width: 'fit-content', color: blue, border: '1px solid #909090' }}>
				<div style={{ fontWeight: 'bold' }}>{it?.name}</div>
				{it?.stopWords?.words?.map((word) => (
					<Chip
						key={word}
						style={{
							marginLeft: '3px',
							marginTop: '2px',
							backgroundColor: gray,
							color: '#fff'
						}}
						variant='outlined'
						label={word}
					/>
				))}
			</div>
		</Grid>
	))

const StopWordsFilters = ({ toggleStopWords, openStopWords, onStopWordsChange, newFilters, comparisonCardsRedux }) => {
	const onChangeIsStopWords = (_, isStopWordsActive) => {
		onStopWordsChange(isStopWordsActive)
	}

	const collapseDisabled = comparisonCardsRedux.every((it) => it?.stopWords?.words?.length < 1)

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Button
					className='button-open-collapse'
					id='btn-toggle-stopwords'
					onClick={toggleStopWords}
					fullWidth
					disabled={collapseDisabled}
					style={{
						fontFamily: 'Poppins',
						fontWeight: 'bold',
						color: collapseDisabled ? gray : blue,
						justifyContent: 'flex-start'
					}}
				>
					{!openStopWords && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openStopWords && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Palavras Ocultadas
				</Button>
				<Collapse in={openStopWords} style={{ width: '100%' }} disabled={true}>
					<Grid
						container
						style={{
							display: 'flex',
							fontFamily: 'Poppins',
							fontSize: '15px',
							color: 'rgba(0, 0, 0, 0.65)',
							padding: '0.5em 1em 1em 1em'
						}}
					>
						<div style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
							<Switch size='small' onChange={onChangeIsStopWords} checked={newFilters.isStopWordsActive} />
							Posts com as seguintes palavras {!newFilters.isStopWordsActive && 'não'} estão sendo ocultados
						</div>
						{renderStopWords(comparisonCardsRedux)}
					</Grid>
				</Collapse>
			</Grid>
		</Card>
	)
}

const mapStateToProps = ({ store }) => ({
	comparisonCardsRedux: store.card.comparison.cards
})

export default connect(mapStateToProps)(memo(StopWordsFilters))
