import React, { useEffect, useState, memo } from 'react'
import { Grid, Card, IconButton, Slide } from '@mui/material'
import { DotsVerticalIcon, ChevronRightIcon } from 'utils/SystemIcons'
import SingleOption from 'graphs/GraficoNowcasting/components/SideMenu/components/SingleOption/SingleOption'

const isFirefox = typeof InstallTrigger !== 'undefined'

const FinancialSideMenu = ({ options, onChangeOptions }) => {
	const [openSideMenu, setOpenSideMenu] = useState(false)
	const [thisOptions, setThisOptions] = useState(options)

	useEffect(() => {
		if (options !== thisOptions) {
			setThisOptions(options)
		}
	}, [options])

	useEffect(() => {
		if (options !== thisOptions) {
			onChangeOptions(thisOptions)
		}
	}, [thisOptions])

	const onClickSideMenuButton = () => {
		setOpenSideMenu(!openSideMenu)
	}

	return (
		<>
			<IconButton color='primary' onClick={onClickSideMenuButton}>
				<DotsVerticalIcon size={25} style={{ color: '#1d8cf8' }} />
			</IconButton>
			<Slide direction='left' in={openSideMenu} mountOnEnter unmountOnExit>
				<Grid style={{ position: 'absolute', zIndex: '100' }}>
					<Card
						style={{
							width: '500px',
							height: '530px',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}}
					>
						<IconButton color='primary' onClick={onClickSideMenuButton}>
							<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
						</IconButton>
						<div style={{ height: '560px', overflowY: 'auto' }}>
							<Grid container>
								<SingleOption
									title={'Previsão apenas positiva'}
									checked={thisOptions.predOnlyPositive}
									isSwitch={true}
									option={'predOnlyPositive'}
									value={!thisOptions.predOnlyPositive}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão Focus'}
									checked={thisOptions.predFocus}
									isSwitch={true}
									option={'predFocus'}
									value={!thisOptions.predFocus}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão com Focus'}
									checked={thisOptions.predWithFocus}
									isSwitch={true}
									option={'predWithFocus'}
									value={!thisOptions.predWithFocus}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão sem Focus'}
									checked={thisOptions.predWithoutFocus}
									isSwitch={true}
									option={'predWithoutFocus'}
									value={!thisOptions.predWithoutFocus}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão media posterior'}
									checked={thisOptions.predPosteriorAverage}
									isSwitch={true}
									option={'predPosteriorAverage'}
									value={!thisOptions.predPosteriorAverage}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão serie multipla'}
									checked={thisOptions.predMultiSerie}
									isSwitch={true}
									option={'predMultiSerie'}
									value={!thisOptions.predMultiSerie}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								<SingleOption
									title={'Previsão serie multipla media posterior'}
									checked={thisOptions.predMultiSeriePostAverage}
									isSwitch={true}
									option={'predMultiSeriePostAverage'}
									value={!thisOptions.predMultiSeriePostAverage}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/>
								{/* <SingleOption
									title={'Previsão serie multipla media posterior benchmark'}
									checked={thisOptions.predMultiSeriePostAverageBenchmark}
									isSwitch={true}
									option={'predMultiSeriePostAverageBenchmark'}
									value={!thisOptions.predMultiSeriePostAverageBenchmark}
									thisOptions={thisOptions}
									setThisOptions={setThisOptions}
									isComp={false}
									isFinancial={true}
								/> */}
							</Grid>
						</div>
					</Card>
				</Grid>
			</Slide>
		</>
	)
}

export default memo(FinancialSideMenu)
