import React, { useEffect, useState, memo } from 'react'
import { TextField } from '@mui/material'
import InputMask from 'react-input-mask'

const HourInput = ({ value, inputLabel, onChange }) => {
	const [hour, setHour] = useState(value)
	const [error] = useState(null) //nao tem erro no momento

	useEffect(() => {
		setHour(value)
	}, [value])

	const onChangeHourInput = (value) => {
		const validateArrayHour = value.split(':') //[hh,mm,ss] sempre nessa ordem

		if (!(Number(validateArrayHour[0]) > 23 || Number(validateArrayHour[1]) > 59 || Number(validateArrayHour[2]) > 59)) {
			setHour(value)
			onChange(value)
		}
	}

	return (
		<InputMask mask='99:99:99' value={hour} disabled={false} maskChar='0' onChange={(e) => onChangeHourInput(e.target.value)}>
			{() => (
				<TextField
					fullWidth
					variant='outlined'
					size='small'
					helperText={error}
					error={!!error}
					label={inputLabel}
					inputProps={{ style: { textAlign: 'center' } }}
				/>
			)}
		</InputMask>
	)
}

export default memo(HourInput)
