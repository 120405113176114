import React, { useState, useEffect, memo, useRef } from 'react'
import NetworkGraph from 'graphs/NetworkGraph/NetworkGraph'
// import InterfaceGoogleTrends from './components/InterfaceGoogleTrends/InterfaceGoogleTrends'
import './SentimentAnalysis.css'
import SentimentAnalysisContent from './components/SentimentAnalysisContent/SentimentAnalysisContent'
import Overview from '../components/Overview/Overview'
import WordClouds from '../components/WordClouds/WordClouds'
import ComparativeBar from 'components/ComparativeBar/ComparativeBar'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import { menuDefaultNew } from './utils/functionsSentimentAnalysis'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { connect } from 'react-redux'
import { verifyCanShowMenuItem, verifyCardComparisonStatuses } from 'utils/generalFunctions'
// import GeolocationGraph from 'graphs/GeolocationGrpah/GeolocationGraph'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import GlobalfilterBar from 'components/GlobalFilters/components/GlobalFilterBar/GlobalFilterBar'
import OccurrencesContent from '../../../../components/OccurrencesContent/OccurrencesContent'
import LocationContent from './components/LocationContent/LocationContent'
import ExportContent from '../components/ExportContent/ExportContent'
import VoxRadarIaContent from './components/VoxRadarIaContent/VoxRadarIaContent'
import constantsVox from 'constants-vox'
import { updateCardComparisonStatusAction } from 'store/redux/actions/card.actions'

const SentimentAnalysis = ({ cardComparison, history, globalFiltersRedux, comparisonStatusRedux, location, updateCardComparisonStatusDispatch }) => {
	const [cards, setCards] = useState(cardComparison)
	const [tab, setTab] = useState(location.state?.menu || 'analysis')
	const [menuState, setMenuState] = useState(null)
	const isMounted = useRef(true)
	const [loading, setLoading] = useState(true)

	let updatedCards = useUpdateData({
		url: `/monitoring/get-by-type-and-ids/term/${cards?.map((card) => card.id)}`,
		updateItemName: 'Update cards',
		refetchInterval: 5000,
		freshDataTime: 5000
	})
	if (updatedCards.error && cards.length > 0) {
		if (updatedCards.error?.response?.data?.toast) {
			sendMessageToast(updatedCards.error?.response?.data?.toast?.message, updatedCards.error?.response?.data?.toast?.type)
		}

		history.push('/monitoring')
	}

	useEffect(() => {
		setCards(cardComparison)
	}, [cardComparison])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [tab])

	useEffect(() => {
		setLoading(true)
		if (updatedCards?.data?.length) {
			//Atualiza status no redux (filtro global de source)
			const newCardStatus = verifyCardComparisonStatuses(updatedCards?.data, constantsVox.CARD_TYPE.TERM)
			updateCardComparisonStatusDispatch(newCardStatus)
			//Atualiza status para o menu lateral
			let canShowMenuItem = verifyCanShowMenuItem(updatedCards?.data)
			setMenuState(
				menuDefaultNew(
					canShowMenuItem?.twitter_data_status,
					canShowMenuItem?.news_data_status,
					canShowMenuItem?.facebook_data_status,
					canShowMenuItem?.instagram_data_status,
					canShowMenuItem?.tiktok_data_status,
					canShowMenuItem?.youtube_data_status
				)
			)
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCards?.data, globalFiltersRedux])

	if (cards.length < 1) {
		history.push('/monitoring')
		return null
	}

	const onSelectItem = (item) => {
		setTab(item)
	}

	return (
		<>
			<SideDrawer itemsToRender={menuState} onSelectItem={onSelectItem} selectedValue={tab} />
			<div className='page-content'>
				<GlobalfilterBar isOverview={tab === 'overview'} disableSourceSelector={tab === 'report'} />
				<ComparativeBar cards={cardComparison} style={{ marginTop: '25px', marginBottom: '25px' }} />
				{tab === 'analysis' && !loading && (
					<SentimentAnalysisContent cardStatus={comparisonStatusRedux} cards={cards} isFetchingData={tab === 'analysis'} />
				)}
				{tab === 'overview' && !loading && <Overview cardStatus={comparisonStatusRedux} cards={cards} isFetchingData={tab === 'overview'} />}
				{tab === 'wordclouds' && !loading && <WordClouds cardStatus={comparisonStatusRedux} cards={cards} />}
				{tab === 'topics' && !loading && (
					<VoxRadarIaContent
						cardStatus={comparisonStatusRedux}
						cards={cards}
						source={globalFiltersRedux.sources}
						isFetchingData={tab === 'topics'}
					/>
				)}
				{tab === 'network' && !loading && (
					<NetworkGraph cards={cardComparison} isFetchingData={tab === 'network'} cardType={cards[0]?.type} />
				)}
				{tab === 'occurrences' && !loading && <OccurrencesContent cards={cardComparison} type='term' />}
				{tab === 'export' && !loading && <ExportContent cards={cardComparison} type='term' />}
				{tab === 'location' && !loading && (
					<LocationContent cardStatus={comparisonStatusRedux} cards={cards} isFetchingData={tab === 'location'} />
				)}
				{/* {tab === 'report' && <ReportContent idsToCompare={cards?.map((card) => card.id)} />} */}
			</div>
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	cardComparison: store.card.comparison.cards,
	globalFiltersRedux: store.global.filters,
	comparisonStatusRedux: store.card.comparison.status
})

const mapDispatchToProps = (dispatch) => ({
	updateCardComparisonStatusDispatch: (newComparisonStatus) => dispatch(updateCardComparisonStatusAction(newComparisonStatus))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(SentimentAnalysis))
