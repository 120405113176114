import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import { LinearProgress } from '@mui/material'

const primary = 'rgba(255, 255, 255, 70%)'
const background = 'rgba(255, 255, 255, 40%)'

const LoadingBlue = withStyles(() => ({
	root: {
		height: 5
	},
	colorPrimary: {
		borderRadius: 100,
		backgroundColor: background
	},
	bar: {
		borderRadius: 100,
		backgroundColor: primary
	}
}))(LinearProgress)

const AiProgressBar = ({ inProgress, handleReady }) => {
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		if (inProgress === false) {
			setProgress(100)
			handleReady()
		}
	}, [inProgress])

	useEffect(() => {
		const timer = setInterval(() => {
			if (inProgress) {
				setProgress((oldProgress) => {
					const diff = 10 / 12
					return Math.min(oldProgress + diff, 100)
				})
			}
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return <LoadingBlue variant='determinate' value={progress} />
}

export default AiProgressBar
