import React, { useState, useEffect, memo } from 'react'
import { Button, Card, Dialog, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material'
import { PauseIcon, PlayIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'
import { useQueryClient } from 'hooks/ReactQueryHooks'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

const termRouteDownload = '/card-term/monitoring/download-status'
const profileRouteDownload = '/card-profile/monitoring/download-status'
const isFirefox = typeof InstallTrigger !== 'undefined'

const verifyUserType = (user) => {
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const PauseDownloadButton = ({ color = '#1d8cf8', downloading, cardType, cardId, canPause, user }) => {
	const [activeColor, setActiveColor] = useState(color)
	const [submitting, setSubmitting] = useState(false)
	const [changeStatus, setChangeStatus] = useState(false)
	const queryClient = useQueryClient()

	useEffect(() => {
		if (downloading && canPause) {
			setActiveColor(color)
		} else {
			setActiveColor('#9A9A9A')
		}
	}, [downloading, cardType])

	const updateDownloadStatus = async (status) => {
		if (canPause) {
			const route = cardType === 'term' ? termRouteDownload : profileRouteDownload
			const userOrSubUserId = verifyUserType(user)
			const log = downloading === true ? 'Pausou o cartão' : 'Ativou o cartão'
			setSubmitting(true)
			api.post(route, { status, cardId, userOrSubUserId, log })
				.then((response) => {
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
					queryClient.invalidateQueries('onDemoCardChange_useUpdateData')
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => setSubmitting(false))
		}
	}

	const handleClick = () => {
		if (!submitting) {
			updateDownloadStatus(!downloading)
		}
	}

	const toggleChange = () => {
		setChangeStatus(!changeStatus)
	}

	const formatConfirmIcon = () => {
		return downloading === true ? (
			<PauseIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />
		) : (
			<PlayIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' />
		)
	}

	return (
		<>
			{downloading && (
				<Tooltip
					title={canPause ? 'Pausar cartão' : 'Sem permissão para pausar cartão'}
					placement='bottom-start'
					enterDelay={500}
					enterNextDelay={500}
				>
					<IconButton onClick={toggleChange} style={{ marginLeft: '-10px' }}>
						<PlayIcon size={25} style={{ color: activeColor, cursor: 'pointer' }} />
					</IconButton>
				</Tooltip>
			)}
			{!downloading && (
				<Tooltip
					title={canPause ? 'Ativar cartão' : 'Sem permissão para ativar cartão'}
					placement='bottom-start'
					enterDelay={500}
					enterNextDelay={500}
				>
					<IconButton onClick={toggleChange} style={{ marginLeft: '-10px' }}>
						<PauseIcon size={25} style={{ color: activeColor, cursor: 'pointer' }} />
					</IconButton>
				</Tooltip>
			)}

			{changeStatus && canPause && (
				<Dialog
					id='dialog-app'
					open={changeStatus}
					onClose={toggleChange}
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
					}}
					fullWidth={true}
					maxWidth='sm'
				>
					<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
						<DialogTitle>
							<div className='jc-space-between d-flex ai-center'>
								<div style={{ color: '#ff2b6b' }}>
									{formatConfirmIcon()} Deseja realmente {downloading === true ? 'pausar' : 'ativar'} o cartão?
								</div>
							</div>
						</DialogTitle>

						<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='space-between'>
							<Button onClick={toggleChange} style={{ borderRadius: '25px' }}>
								CANCELAR
							</Button>
							<Button
								style={{ color: '#ff2b6b', borderRadius: '25px' }}
								onClick={() => {
									toggleChange()
									handleClick()
								}}
							>
								{downloading === true ? 'PAUSAR' : 'ATIVAR'}
							</Button>
						</Grid>
					</Card>
				</Dialog>
			)}
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(PauseDownloadButton))
