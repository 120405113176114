import React, { useState, memo, useEffect } from 'react'
import { Button, Dialog, DialogContent, Divider, Grid } from '@mui/material'
import { StatsIcon } from 'utils/SystemIcons'
import FacebookPostMetrics from './components/FacebookPostMetrics/FacebookPostMetrics'
import InstagramPostMetrics from './components/InstagramPostMetrics/InstagramPostMetrics'
import MetaAdsPostMetrics from './components/MetaAdsPostMetrics/MetaAdsPostMetrics'
import GraphTitle from 'components/GraphTitle/GraphTitle'

const isFirefox = typeof InstallTrigger !== 'undefined'

const ViewPostMetrics = ({ cardData, source, cardColor }) => {
	const [open, setOpen] = useState(false)

	const onClickGetComments = () => {
		setOpen(true)
	}
	const handleOnClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Button
				id='btn-occurrences-comments'
				size='small'
				onClick={onClickGetComments}
				style={{
					marginLeft: '8px',
					borderRadius: '100px',
					fontFamily: 'Poppins',
					fontSize: '12px',
					color: cardColor
				}}
			>
				<StatsIcon size={13} style={{ color: cardColor, marginRight: '5px' }} />
				Métricas
			</Button>
			{open && (
				<Dialog
					style={{ margin: 'auto' }}
					id='dialog-app-comments'
					fullWidth={true}
					open={open}
					maxWidth='xl'
					onClose={handleOnClose}
					BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
					PaperProps={{
						style: {
							borderRadius: '20px',
							height: '70%',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
				>
					<DialogContent style={{ marginBottom: '15px' }}>
						<Grid container style={{ padding: '0em 1em 0em 1em' }}>
							<GraphTitle
								id='span-voxradar-ai'
								title='Métricas dos posts'
								icon={<StatsIcon size={23} style={{ color: '#1d8cf8', marginBottom: '0.2em' }} />}
							/>
							<Divider style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: '1px', marginTop: '1em', width: '100%' }} />
						</Grid>
						<Grid container>
							{source === 'facebook' && <FacebookPostMetrics cardData={cardData} />}
							{source === 'instagram' && <InstagramPostMetrics cardData={cardData} />}
							{source === 'metaAds' && <MetaAdsPostMetrics cardData={cardData} />}
						</Grid>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

export default memo(ViewPostMetrics)
