import { setLoggedUser } from 'store/local-storage/store'

const USER_ACTION_TYPES = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	RESTORE_LOGGED_USER: 'RESTORE_LOGGED_USER',
	UPDATE_USER_DATA: 'UPDATE_USER_DATA',
	SAVE_USER_CREDITS: 'SAVE_USER_CREDITS'
}

//é usado tanto no login quanto ao atualizar algum dado do usuário dentro do sistema (ex: mostrar ou esconder as demonstrações)
const loginAction = (user) => {
	setLoggedUser(user) //salva no local storage
	return { type: USER_ACTION_TYPES.LOGIN, payload: user }
}

const logoutAction = () => ({
	type: USER_ACTION_TYPES.LOGOUT
})

const restoreLoggedUserAction = (loggedUser) => ({ type: USER_ACTION_TYPES.RESTORE_LOGGED_USER, payload: { loggedUser } })

const updateUserDataAction = (updatedUserData) => ({
	type: USER_ACTION_TYPES.UPDATE_USER_DATA,
	payload: { updatedUserData }
})

const saveUserCreditsAction = (creditInfo) => ({
	type: USER_ACTION_TYPES.SAVE_USER_CREDITS,
	payload: { creditInfo }
})

export { USER_ACTION_TYPES, loginAction, logoutAction, restoreLoggedUserAction, updateUserDataAction, saveUserCreditsAction }
