import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '@mui/material'

const NetworkButtonSkeletonLoad = () => (
	<Card style={{ borderRadius: '30px', width: '160px' }}>
		<Skeleton height='160px' width='160px' style={{ marginTop: '-20px', lineHeight: 'inherit' }} />
	</Card>
)

export default NetworkButtonSkeletonLoad
