import React from 'react'
import FacebookPostTypeMetrics from './modules/FacebookPostTypeMetrics/FacebookPostTypeMetrics'
import FacebookReelsTypeMetrics from './modules/FacebookReelsTypeMetrics/FacebookReelsTypeMetrics'
import FacebookVideoTypeMetrics from './modules/FacebookVideoTypeMetrics/FacebookVideoTypeMetrics'

const FacebookPostMetrics = ({ cardData }) => {
	let isPost = cardData?.post?.media_product_type === 'POST'
	let isReels = cardData?.post?.media_product_type === 'REEL'
	let isVideo = cardData?.post?.media_product_type === 'VIDEO'

	return (
		<>
			{isPost && <FacebookPostTypeMetrics cardData={cardData} />}
			{isReels && <FacebookReelsTypeMetrics cardData={cardData} />}
			{isVideo && <FacebookVideoTypeMetrics cardData={cardData} />}
		</>
	)
}

export default FacebookPostMetrics
