import React, { useState, useEffect, memo } from 'react'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { TbCloudUpload } from 'react-icons/tb'
import { Grid, Card, CardContent, Button, TextField, CircularProgress, Alert, AlertTitle } from '@mui/material'
import api from 'service/service'
import { useSelector, useDispatch } from 'react-redux'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import constantsVox from 'constants-vox'
import personOutlineImage from 'assets/img/mike.jpg'
import { updateUserDataAction } from 'store/redux/actions/user.actions'
import { setLoggedUser } from 'store/local-storage/store'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import ImageUploader from 'react-images-upload'

const defaultPasswordReq = { password: '', newPassword: '' }
const defaultPasswordConfirm = ''

//retirar rotas
// /my-sub-user
// /profile

const Profile = () => {
	const dispatch = useDispatch()
	const userData = useSelector((state) => state.store.user)
	const [updatingProfile, setUpdatingProfile] = useState({ name: '', email: '' })
	const [updatingWhiteLabel, setUpdatingWhiteLabel] = useState({ company_name: '', images: { logo: '' }, domains: [] })
	const [passwordReq, setPasswordReq] = useState(defaultPasswordReq)
	const [passwordConfirm, setPasswordConfirm] = useState(defaultPasswordConfirm)
	const [loading, setLoading] = useState(true)
	const [resendValidationEmailLoading, setResendValidationEmailLoading] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [iconVisible, setIconVisible] = useState(false)
	const [visible, setVisible] = useState('password')

	useEffect(() => {
		load()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const load = async () => {
		try {
			setLoading(true)
			const response = await api.get(`/profile/${userData.subUser?.id}`)
			dispatch(updateUserDataAction(response.data))
			setLoggedUser({ ...userData, ...response.data })
			setUpdatingProfile({
				name: response.data?.subUser?.name || response.data?.name,
				email: response.data?.subUser?.email || response.data?.email
			})
			setUpdatingWhiteLabel({
				company_name: response?.data?.whiteLabelCompanyName ?? '',
				images: response?.data?.whiteLabelImages ?? { logo: '' },
				domains: response?.data?.whiteLabelDomains ?? []
			})
		} catch (error) {
			if (verifyServerResponseCanShowToast(error)) {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			}
		} finally {
			setLoading(false)
		}
	}

	const updateWhiteLabel = async (event) => {
		event.preventDefault()
		if (!updatingWhiteLabel.company_name || updatingWhiteLabel.company_name.trim() === '') {
			sendMessageToast('Digite um nome válido', constantsVox.TOAST.TYPES.WARNING)
		} else if (!updatingWhiteLabel.images || !updatingWhiteLabel.images.logo || updatingWhiteLabel.images.logo.trim() === '') {
			sendMessageToast('Adicione uma imagem válida', constantsVox.TOAST.TYPES.WARNING)
		} else {
			setUpdating(true)
			if (!userData.subUser) {
				api.post('/profile-update-white-label', { ...updatingWhiteLabel })
					.then((response) => {
						if (response?.data?.toast) {
							sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						}
						setUpdatingWhiteLabel({ company_name: '', images: { logo: '' }, domains: [] })
						setPasswordConfirm('')
						setVisible('reset')
						load()
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setUpdating(false)
					})
			}
		}
	}

	const updateProfile = async (event) => {
		event.preventDefault()
		let isValidName = true
		let isValidEmail = true

		if (!updatingProfile.name || updatingProfile.name.trim() === '') {
			sendMessageToast('Digite um nome válido', constantsVox.TOAST.TYPES.WARNING)
			isValidName = false
		}

		if (!updatingProfile.email || updatingProfile.email.trim() === '' || !validateEmail(updatingProfile.email.trim())) {
			sendMessageToast('Digite um email válido', constantsVox.TOAST.TYPES.WARNING)
			isValidEmail = false
		}

		if (isValidName && isValidEmail) {
			setUpdating(true)

			if (!userData.subUser) {
				api.post('/profile-update', { ...updatingProfile })
					.then((response) => {
						if (response?.data?.toast) {
							sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						}
						setUpdatingProfile({ name: '', email: '' })
						setPasswordConfirm('')
						setVisible('reset')
						load()
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setUpdating(false)
					})
			} else if (userData.subUser) {
				api.post('/sub-user-update', { ...updatingProfile })
					.then((response) => {
						if (response?.data?.toast) {
							sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						}
						setUpdatingProfile({ name: '', email: '' })
						setPasswordConfirm('')
						setVisible('reset')
						load()
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setUpdating(false)
					})
			}
		}
	}

	const updatePassword = async (event) => {
		event.preventDefault()
		let isValidPass = true
		let isValidConfirm = true
		if (passwordReq.password === '' || !passwordReq.password) {
			isValidPass = false
			sendMessageToast('Digite sua senha atual', constantsVox.TOAST.TYPES.WARNING)
		}
		if (passwordConfirm !== passwordReq.newPassword || passwordConfirm === '') {
			isValidConfirm = false
			sendMessageToast('Senhas não coincidem', constantsVox.TOAST.TYPES.WARNING)
		}

		if (isValidPass && isValidConfirm) {
			setUpdating(true)
			if (!userData.subUser) {
				api.post('/profile-update-password', { ...passwordReq })
					.then((response) => {
						if (response?.data?.toast) {
							sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						}
						load()
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setUpdating(false)
						setPasswordReq(defaultPasswordReq)
						setPasswordConfirm(defaultPasswordConfirm)
					})
			} else if (userData.subUser) {
				api.post('/sub-user-update-password', { ...passwordReq })
					.then((response) => {
						if (response?.data?.toast) {
							sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						}
						load()
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setUpdating(false)
						setPasswordReq(defaultPasswordReq)
						setPasswordConfirm(defaultPasswordConfirm)
					})
			}
		}
	}

	const validateEmail = (email) => {
		let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return re.test(email)
	}

	const handlePasswordVisibility = () => {
		setIconVisible(!iconVisible)
		if (visible === 'password') {
			setVisible('text')
		} else {
			setVisible('password')
		}
	}

	const resendVerifyEmail = () => {
		setResendValidationEmailLoading(true)
		api.post('/resend-verification-email')
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => setResendValidationEmailLoading(false))
	}

	const getImageDimensionsFromFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader()

			reader.onload = () => {
				const img = new Image()
				img.src = reader.result
				img.onload = () =>
					resolve({
						width: img.naturalWidth,
						height: img.naturalHeight
					})
			}
			reader.readAsDataURL(file)
		})
	}

	const handleFileInput = async (imageFile, image64) => {
		const imageDimensions = await getImageDimensionsFromFile(imageFile[0])
		if (imageDimensions.height !== 1024 || imageDimensions.width !== 1024) {
			sendMessageToast('Dimensões erradas!', constantsVox.TOAST.TYPES.WARNING)
		} else if (imageFile[0].type.split('/')[1] !== 'png') {
			sendMessageToast('Formato errado!', constantsVox.TOAST.TYPES.WARNING)
		} else {
			setUpdatingWhiteLabel({ ...updatingWhiteLabel, images: { logo: image64[0] } })
		}
	}

	return (
		<>
			<Grid container className='page-1-content'>
				{!userData.isValidEmail && (
					<Grid item xs={12}>
						<Alert severity='warning'>
							{userData.subUser?.id ? (
								<AlertTitle>É necessário que a conta principal valide o e-mail!</AlertTitle>
							) : (
								<>
									<AlertTitle>Valide seu email!</AlertTitle>
									Caso não tenha recebido seu email de validação verifique na caixa de spam, lixo eletrônico, ou
									<Button style={{ outline: 'none' }} onClick={resendVerifyEmail}>
										{resendValidationEmailLoading ? <CircularProgress size={25} style={{ color: 'black' }} /> : 'Reenviar e-mail'}
									</Button>
									.
								</>
							)}
						</Alert>
					</Grid>
				)}
				<Grid item xs={12} style={{ marginTop: '2em' }}>
					<Card>
						<CardContent style={{ display: 'flex', justifyContent: 'center' }}>
							<div>
								<div
									style={{
										border: '0.5em solid black',
										width: 'fit-content',
										borderRadius: '50%'
									}}
								>
									<img
										alt='...'
										src={personOutlineImage}
										style={{
											height: '10em',
											borderRadius: '50%'
										}}
									/>
								</div>
								<h5 style={{ textAlign: 'center' }}>{userData?.subUser?.name || userData?.name}</h5>
								<p style={{ textAlign: 'center' }}>{userData?.subUser?.email || userData?.name}</p>
							</div>
						</CardContent>
					</Card>
				</Grid>
				{userData?.hasWhiteLabel && (
					<Grid item xs={12} style={{ marginTop: '2em' }}>
						<Card>
							<CardContent>
								<h5>WhiteLabel</h5>
								<Grid container spacing={3}>
									<Grid item xs={4}>
										<div style={{ marginBottom: '2em' }}>
											<span style={{ fontWeight: 'bold' }}>Domínios:</span>{' '}
											{updatingWhiteLabel?.domains.map((it, index) => (index === 0 ? it : ', ' + it))}
										</div>
										<TextField
											size='small'
											style={{ width: '100%' }}
											value={updatingWhiteLabel?.company_name}
											onChange={(e) =>
												setUpdatingWhiteLabel({
													...updatingWhiteLabel,
													company_name: e.target.value
												})
											}
											label='Empresa'
											variant='outlined'
											disabled={updating || loading}
										/>
									</Grid>
									<Grid item xs={4}>
										<div style={{ fontWeight: 'bold' }}>Logo da Empresa</div>
										{!loading && (
											<ImageUploader
												buttonClassName='image-button'
												withIcon={false}
												withLabel={false}
												buttonText={
													updatingWhiteLabel.images.logo ? (
														<img
															src={`${updatingWhiteLabel.images.logo}`}
															alt='logo'
															style={{ width: '128px', height: '128px' }}
														/>
													) : (
														<span>
															<TbCloudUpload /> Upload imagem
														</span>
													)
												}
												fileContainerStyle={{
													backgroundColor: 'transparent',
													boxShadow: 'none',
													right: '200px',
													width: '165px',
													height: '105px'
												}}
												onChange={handleFileInput}
												singleImage
												// imgExtension={['.png']}
												maxFileSize={5242880}
											/>
										)}
										<div style={{ fontFamily: 'Poppins', color: 'rgba(0, 0, 0, 0.65)', fontSize: '12px' }}>
											Selecione uma imagem .png (1024 x 1024)
										</div>
									</Grid>
									<Grid container item>
										<Button className='primary-button-app' onClick={updateWhiteLabel} disabled={updating || loading}>
											Salvar
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				)}
				<Grid item xs={12} style={{ marginTop: '2em' }}>
					<Card>
						<CardContent>
							<h5>Editar Perfil</h5>
							<Grid container spacing={3}>
								<Grid item xs={4}>
									<TextField
										size='small'
										style={{ width: '100%' }}
										value={updatingProfile?.name}
										onChange={(e) =>
											setUpdatingProfile({
												...updatingProfile,
												name: e.target.value
											})
										}
										label='Nome'
										variant='outlined'
										disabled={updating || loading}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										size='small'
										style={{ width: '100%' }}
										value={updatingProfile?.email}
										onChange={(e) =>
											setUpdatingProfile({
												...updatingProfile,
												email: e.target.value
											})
										}
										label='Email'
										variant='outlined'
										disabled={updating || loading}
									/>
								</Grid>
								<Grid container item>
									<Button className='primary-button-app' onClick={updateProfile} disabled={updating || loading}>
										Salvar
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{ marginTop: '2em' }}>
					<Card>
						<CardContent>
							<h5 className='title'>Alterar Senha</h5>
							<Grid container spacing={3}>
								<Grid container item spacing={3}>
									<Grid item xs={3}>
										<TextField
											size='small'
											style={{ width: '100%' }}
											value={passwordReq?.password}
											onChange={(e) => {
												setPasswordReq({
													...passwordReq,
													password: e.target.value
												})
											}}
											label='Senha Atual'
											variant='outlined'
											type={visible}
											disabled={updating || loading}
										/>
									</Grid>
									<Grid
										item
										xs={1}
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<Button
											onClick={handlePasswordVisibility}
											style={{
												padding: 0,
												minWidth: 0,
												height: 'fit-content',
												outline: 'none'
											}}
										>
											{iconVisible && (
												<IoMdEye
													size={18}
													style={{
														color: '#b8b8b8',
														position: 'relative',
														transform: 'translate(0%, -5%)'
													}}
												/>
											)}
											{!iconVisible && (
												<IoMdEyeOff
													size={18}
													style={{
														color: '#b8b8b8',
														position: 'relative',
														transform: 'translate(0%, -5%)'
													}}
												/>
											)}
										</Button>
									</Grid>
								</Grid>
								<Grid item md={3}>
									<TextField
										size='small'
										style={{ width: '100%' }}
										value={passwordReq?.newPassword}
										onChange={(e) =>
											setPasswordReq({
												...passwordReq,
												newPassword: e.target.value
											})
										}
										label='Nova Senha'
										variant='outlined'
										disabled={updating || loading}
										type='password'
									/>
								</Grid>
								<Grid item md={3}>
									<TextField
										size='small'
										style={{ width: '100%' }}
										value={passwordConfirm}
										onChange={(e) => setPasswordConfirm(e.target.value)}
										label='Confirmar'
										variant='outlined'
										disabled={updating || loading}
										type='password'
									/>
								</Grid>
								<Grid container item>
									<Button className='primary-button-app' onClick={updatePassword} disabled={updating || loading}>
										Salvar
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	)
}

export default memo(Profile)
