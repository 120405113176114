import React, { useEffect, useState, memo } from 'react'
import { TableCell } from '@mui/material'
import EmptyImageFeed from 'assets/img/twitterEmpty.jpg'

const InfluencerImage = ({ type, column, idx2 }) => {
	const [imageUrl, setImageUrl] = useState(column?.profileImageUrl)

	useEffect(() => {
		setImageUrl(column?.profileImageUrl)
	}, [column?.profileImageUrl])

	return (
		<TableCell style={{ padding: '10px' }}>
			{idx2 === 0 ? (
				<div style={{ display: 'flex', alignItems: 'center', fontSize: '1.1em' }}>
					{column.profileImageUrl && type === 'influencers' && (
						<img
							style={{
								width: '50px',
								height: '50px',
								borderRadius: '50px',
								border: 'solid 2px rgba(29, 138, 248, 100%)',
								marginRight: '0.5em'
							}}
							src={imageUrl || EmptyImageFeed}
							onError={() => setImageUrl(null)}
							alt=''
						/>
					)}
					{column.name}
				</div>
			) : (
				column
			)}
		</TableCell>
	)
}

export default memo(InfluencerImage)
