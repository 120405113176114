import React, { useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import api from 'service/service'
import {
	InputAdornment,
	Grow,
	TextField,
	Button,
	Checkbox,
	Box,
	Container,
	FormControl,
	Grid,
	CircularProgress,
	FormHelperText,
	Link
} from '@mui/material'
import { AccountBalance, Email, Lock, AccountCircle } from '@mui/icons-material'
import { EyeIcon, ClockIcon, TrendingIcon } from 'utils/SystemIcons'

import 'react-phone-input-2/lib/material.css'
import './Register.css'

const defaultValues = {
	terms: false
}

const Register = () => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		watch
	} = useForm({ defaultValues })
	const [serverErrors, setServerErrors] = useState()
	const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(false)

	const password = useRef({})
	password.current = watch('password', '')

	const onSubmit = async (formData) => {
		setLoading(true)

		try {
			const data = await api.post('/register', {
				...formData
			})
			if (data.status === 201) {
				setStep(1)
			}
		} catch (e) {
			if (e.response.status === 422) {
				e.response.data.errors.forEach((e) => {
					setServerErrors(e[Object.keys(e)])
				})
			} else if (e.response.data) {
				setServerErrors(e.response.data.message)
			} else {
				setServerErrors('Falha na requisição.')
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<Container>
				<Grid container>
					<Grid item md={5}>
						<Grow in={true}>
							<Box pt={6} pl={2} pr={2} mt={10}>
								<div>
									<div style={{ display: 'flex' }}>
										<EyeIcon size={35} style={{ marginTop: '1.1em', color: '#1d8cf8' }} />
										<h3 style={{ fontFamily: 'Poppins', marginLeft: '10px' }}>Monitoramento</h3>
									</div>
									<div>
										<p style={{ fontFamily: 'Poppins' }}>
											Monitore os principais assuntos e entenda as tendências e temas que estão em evidência para seu publico.
										</p>
									</div>
								</div>

								<div>
									<div style={{ display: 'flex' }}>
										<ClockIcon size={35} style={{ marginTop: '1.1em', color: '#1d8cf8' }} />
										<h3 style={{ fontFamily: 'Poppins', marginLeft: '10px' }}>Tempo real</h3>
									</div>
									<div>
										<p style={{ fontFamily: 'Poppins' }}>
											Esteja sempre informado do que estão falando sobre o tema em tempo real.
										</p>
									</div>
								</div>

								<div>
									<div style={{ display: 'flex' }}>
										<TrendingIcon size={35} style={{ marginTop: '1.1em', color: '#1d8cf8' }} />
										<h3 style={{ fontFamily: 'Poppins', marginLeft: '10px' }}>Sempre à frente</h3>
									</div>
									<div>
										<p style={{ fontFamily: 'Poppins' }}>
											Esteja sempre à frente, crie análises comparativas para entender melhor os players de seu mercado.
										</p>
									</div>
								</div>
							</Box>
						</Grow>
					</Grid>
					<Grid item md={7}>
						<Grow in={true}>
							<Box bgcolor='white' pt={2} pb={6} pl={2} pr={2} mt={10} mb={10}>
								<Box display='flex' justifyContent='center' alignItems='center' pb={4} pt={4}>
									<div className='logo'></div>
								</Box>

								{step === 0 && (
									<form onSubmit={handleSubmit(onSubmit)}>
										<FormControl fullWidth={true} error={errors ? true : false}>
											<Box m={1}>
												<TextField
													id='fullname'
													label='Nome completo'
													variant='outlined'
													fullWidth={true}
													error={errors.fullname ? true : false}
													helperText={errors.fullname ? errors.fullname.message : ''}
													{...register('fullname', {
														required: 'Nome completo é obrigatório'
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<AccountCircle />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												<Controller
													name='phone'
													control={control}
													rules={{
														required: 'Telefone é obrigatório'
													}}
													render={({ field }) => (
														<PhoneInput
															country='br'
															onlyCountries={['br']}
															containerStyle={{
																width: '100%'
															}}
															inputStyle={{
																width: '100%',
																color: '#000000',
																padding: '30px 30px 30px 50px'
															}}
															value={field.value}
															inputProps={{
																name: field.name
															}}
															onChange={(e) => field.onChange(e)}
														/>
													)}
												/>

												{errors.phone && (
													<FormHelperText className='fix-errors-custom'>{errors.phone.message}</FormHelperText>
												)}
											</Box>
											<Box m={1}>
												<TextField
													id='company'
													label='Empresa'
													variant='outlined'
													fullWidth={true}
													error={errors.company ? true : false}
													helperText={errors.company ? errors.company.message : ''}
													{...register('company', {
														required: 'Nome da empresa é obrigatório'
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<AccountBalance />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												<TextField
													id='Email'
													label='Email'
													variant='outlined'
													fullWidth={true}
													error={errors.email ? true : false}
													helperText={errors.email ? errors.email.message : ''}
													{...register('email', {
														required: 'Email é obrigatório',
														pattern: {
															value: /\S+@\S+\.\S+/,
															message: 'Digite um email válido'
														}
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<Email />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												<TextField
													id='password'
													label='Senha'
													variant='outlined'
													fullWidth={true}
													type='password'
													autoComplete='current-password'
													error={errors.password ? true : false}
													helperText={errors.password ? errors.password.message : ''}
													{...register('password', {
														required: 'Senha é obrigatório',
														minLength: {
															value: 6,
															message: 'Sua senha deve conter no mínimo 6 caracteres'
														}
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<Lock />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												<TextField
													id='password_confirm'
													label='Confirme a nova senha'
													variant='outlined'
													fullWidth={true}
													type='password'
													autoComplete='current-password'
													error={errors.password_confirm ? true : false}
													helperText={errors.password_confirm ? errors.password_confirm.message : ''}
													{...register('password_confirm', {
														validate: (value) => value === password.current || 'Senhas não conferem'
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<Lock />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												<label>
													<Controller
														name='terms'
														control={control}
														rules={{
															required: 'Aceite os termos e condições'
														}}
														render={({ field }) => (
															<Checkbox
																color='primary'
																onChange={(e) => field.onChange(e.target.checked)}
																checked={field.value}
																helperText={errors.terms ? errors.terms.message : ''}
															/>
														)}
													/>
													<span />
													Estou de acordo com os termos{' '}
													<a
														href='https://s3.amazonaws.com/static.docs/termos+e+condicoes+de+uso+v1.pdf'
														target='_blank'
														rel='noopener noreferrer'
													>
														Termos e condições
													</a>
													.
												</label>
												{errors.terms && (
													<FormHelperText className='fix-errors-custom'>{errors.terms.message}</FormHelperText>
												)}
											</Box>

											<Box m={1}>
												{serverErrors && <FormHelperText className='fix-errors-custom'>{serverErrors}</FormHelperText>}
											</Box>

											<Box m={1}>
												{!loading && (
													<Button variant='contained' color='primary' type='submit'>
														Cadastrar
													</Button>
												)}

												{loading && <CircularProgress />}
											</Box>
										</FormControl>
									</form>
								)}
								{step === 1 && (
									<>
										<Box m={1}>
											<p style={{ color: '#000000' }}>
												<b>Obrigado por realizar o cadastro.</b>
											</p>
											<p style={{ color: '#000000' }}>Nossos consultores irão entrar em contato o mais breve possível!</p>
										</Box>
										<Box m={4} textAlign='center'>
											<Link href='/login' color='primary'>
												Entrar
											</Link>
										</Box>
									</>
								)}
							</Box>
						</Grow>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}

export default Register
