import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'
const red = 'rgb(255, 43, 107)'
const lightgrey = 'rgb(220, 220, 220)'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: false
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const SentimentsComp = ({ data, isReport, hideOnEmpty }) => {
	const [barsData, setBarsData] = useState(barsDataProps)
	const [hide, setHide] = useState(false)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let positives = []
		let negatives = []
		let neutral = []
		let bots = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			seriesData.push(element?.selectedInterval?.metrics?.post_count || 0)

			positives.push(element?.selectedInterval?.metrics?.post_count_good || 0)
			negatives.push(element?.selectedInterval?.metrics?.post_count_bad || 0)
			neutral.push(element?.selectedInterval?.metrics?.post_count_neutral || 0)
			bots.push(element?.selectedInterval?.metrics?.post_count_likely_bot || 0)
		}

		const series = [
			{
				name: 'Positivos',
				data: positives
			},
			{
				name: 'Negativos',
				data: negatives
			},
			{
				name: 'Neutros',
				data: neutral
			},
			{
				name: 'Bots',
				data: bots
			}
		]

		const optionsBars = {
			colors: [blue, red, purple, lightgrey],
			chart: {
				type: 'bar',
				stacked: true,
				stackType: '100%',
				toolbar: {
					show: !isReport
				}
			},
			plotOptions: {
				bar: {
					horizontal: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories
			},
			fill: {
				opacity: 1
			},
			yaxis: {
				show: false
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		if (hideOnEmpty) {
			let hideData = []
			series.forEach((it) => hideData.push(...it.data.filter((d) => d)))
			setHide(hideData.length < 1)
		}

		setBarsData(apexData)
	}

	return (
		<>
			{!hide && (
				<Chart
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
					options={barsData.options}
					series={barsData.series}
					type='bar'
					width='100%'
					height={300}
				/>
			)}
		</>
	)
}

export default SentimentsComp
