import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { withStyles } from '@mui/styles'
import { LinearProgress } from '@mui/material'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(225, 225, 225, 100%)'

const LoadingBlue = withStyles(() => ({
	root: {
		height: 1
	},
	colorPrimary: {
		backgroundColor: blue
	},
	bar: {
		borderRadius: 5,
		backgroundColor: grey
	}
}))(LinearProgress)

const LoadingProp = withStyles(() => ({
	root: {
		height: 1
	},
	colorPrimary: {
		backgroundColor: 'transparent'
	},
	bar: {
		borderRadius: 5,
		backgroundColor: 'transparent'
	}
}))(LinearProgress)

const LoadingBar = (_, ref) => {
	const [loading, setLoading] = useState(false)

	useImperativeHandle(ref, () => ({
		load(isLoading) {
			setLoading(isLoading)
		}
	}))

	return (
		<>
			{loading && <LoadingBlue />}
			{!loading && <LoadingProp />}
		</>
	)
}

export default forwardRef(LoadingBar)
