import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs } from '@mui/material'
import { findNavigationBreadcrumbs } from './utils/findNavigationBreadcrumbs'
import { SeparatorIcon } from 'utils/SystemIcons'
import { useSelector } from 'react-redux'
import './BreadcrumbsNavigation.css'

const BreadcrumbsNavigation = () => {
	let location = useLocation()
	const cards = useSelector((reducer) => reducer?.store?.card?.comparison?.cards)

	return (
		<Breadcrumbs aria-label='breadcrumb' separator={<SeparatorIcon color={'#a0a0a0'} />} style={{ marginLeft: '-1.5em' }}>
			{findNavigationBreadcrumbs(location.pathname, cards)?.map((nav, index) =>
				nav.path ? (
					<Link
						key={index}
						to={{ pathname: nav.path, state: nav.pathState }}
						style={{ color: nav.isLast ? '#1d8cf8' : '#9A9A9A' }}
						className='link-breadcrumbs'
					>
						<span>{nav.icon}</span>
						<span style={{ marginLeft: '0.3em', fontSize: '13px', letterSpacing: '2px' }}>{nav.label.toUpperCase()}</span>
					</Link>
				) : (
					<span
						key={index}
						style={{
							color: nav.isLast ? '#1d8cf8' : '#9A9A9A',
							cursor: 'default'
						}}
					>
						<span>{nav.icon}</span>
						<span style={{ marginLeft: '0.3em', fontSize: '13px', letterSpacing: '2px' }}>{nav.label.toUpperCase()}</span>
					</span>
				)
			)}
		</Breadcrumbs>
	)
}

export default BreadcrumbsNavigation
