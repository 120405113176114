import React, { useState } from 'react'
import api from 'service/service'
import { Card, Grid, CardContent, Button, DialogTitle, DialogContent, Dialog, IconButton, Tooltip, TextField } from '@mui/material'
import { CloseIcon, SaveIcon, CardIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const SaveCompButton = ({ ids }) => {
	const [showSaveCompModal, setShowSaveCompModal] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [cardName, setCardName] = useState('')

	const toggleSaveCompModal = () => {
		if (!submitting) {
			setShowSaveCompModal(!showSaveCompModal)
		} else {
			setShowSaveCompModal(true)
		}
		return
	}

	const registerHistoryCard = async (event) => {
		event.preventDefault()

		setSubmitting(true)
		api.post('/cartoes-historico/criar', {
			ids,
			cardName
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleSaveCompModal()
			})
	}

	return (
		<div style={{ marginLeft: '10px', justifyContent: 'center' }}>
			<Tooltip title='Salvar comparação' placement='left'>
				<IconButton color='primary' onClick={toggleSaveCompModal} size='small' style={{ marginTop: '5px' }}>
					<SaveIcon size={20} style={{ color: '#008FFB' }} />
				</IconButton>
			</Tooltip>
			<Dialog
				open={showSaveCompModal}
				onClose={toggleSaveCompModal}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='sm'
			>
				<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
					<DialogTitle>
						<div className='jc-space-between d-flex ai-center'>
							<div>
								<CardIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8' }} /> Salvar cartão
								comparativo
							</div>
							<div>
								<IconButton color='primary' style={{ outline: 'none' }} onClick={toggleSaveCompModal}>
									<CloseIcon color='#1d8cf8' size={20} />
								</IconButton>
							</div>
						</div>
					</DialogTitle>
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12}>
								<TextField
									variant='standard'
									label='Nome do cartão'
									size='small'
									className='d-flex'
									style={{ color: '#1d8cf8', borderRadius: '4px' }}
									value={cardName}
									onChange={(e) => setCardName(e.target.value)}
								/>
							</Grid>
						</CardContent>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12} container justifyContent={'center'}>
								<Button
									style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
									variant='outlined'
									color='primary'
									disabled={submitting || cardName == ''}
									onClick={registerHistoryCard}
								>
									Salvar
								</Button>
							</Grid>
						</CardContent>
					</DialogContent>
				</Card>
			</Dialog>
		</div>
	)
}

export default SaveCompButton
