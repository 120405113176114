import React, { useState, useEffect, memo } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import { Button, Card, Grid, Dialog } from '@mui/material'
import pt from 'date-fns/locale/pt'
import moment from 'moment-timezone'
import { makeStyles } from '@mui/styles'
import HourInput from './components/HourInput/HourInput'
import { connect } from 'react-redux'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	root: {
		color: 'rgb(82, 95, 127)',
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		color: blue
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const DateIntervalSelectorBar = ({ fromDate, toDate, fromHour, toHour, onApply, globalFiltersRedux, onClose, isOpen }) => {
	const [dropdownOpen, setDropdownOpen] = useState(isOpen)
	const [startDate, setStartDate] = useState(moment(fromDate).toDate())
	const [endDate, setEndDate] = useState(moment(toDate).toDate())
	const [startHour, setStartHour] = useState(fromHour)
	const [endHour, setEndHour] = useState(toHour)

	const classes = useStyles()

	useEffect(() => {
		setStartDate(moment(fromDate).toDate())
	}, [fromDate])

	useEffect(() => {
		setEndDate(moment(toDate).toDate())
	}, [toDate])

	useEffect(() => {
		setStartHour(fromHour)
	}, [fromHour])

	useEffect(() => {
		setEndHour(toHour)
	}, [toHour])

	useEffect(() => {
		setDropdownOpen(isOpen)
	}, [isOpen])

	const toggle = () => {
		setStartDate(moment(globalFiltersRedux.fromDate).toDate())
		setEndDate(moment(globalFiltersRedux.toDate).toDate())
		setStartHour(globalFiltersRedux.fromHour)
		setEndHour(globalFiltersRedux.toHour)
		onClose()
	}

	const handleChangeDate = ({ selection }) => {
		setStartDate(selection.startDate)
		setEndDate(selection.endDate)
	}

	const onChangeStartHour = (value) => {
		setStartHour(value)
	}

	const onChangeEndHour = (value) => {
		setEndHour(value)
	}

	const onSearchButtonPress = () => {
		onApply(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), startHour, endHour)
		toggle()
	}

	return (
		<Dialog
			open={dropdownOpen}
			onClose={toggle}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth='md'
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				classes: {
					root: classes.backDrop
				}
			}}
		>
			<Card
				elevation={0}
				style={{
					margin: '10px',
					borderRadius: '20px',
					paddingBottom: '2em',
					height: '530px'
				}}
			>
				<DateRange
					style={{ background: 'transparent', width: '420px' }}
					editableDateInputs={false}
					onChange={handleChangeDate}
					moveRangeOnFirstSelection={false}
					ranges={[{ startDate, endDate, key: 'selection' }]}
					months={2}
					locale={pt}
					direction='horizontal'
					maxDate={moment().tz('America/Sao_Paulo').toDate()}
					minDate={moment.tz('2015-01-01', 'America/Sao_Paulo').toDate()}
				/>

				<Grid container style={{ marginTop: '1em' }} alignItems='flex-end'>
					<Grid container item md={6} sm={12} style={{ justifyContent: 'center' }}>
						<Grid item xs={5}>
							<HourInput value={startHour} inputLabel='Hora inicial' onChange={onChangeStartHour} />
						</Grid>
					</Grid>
					<Grid container item md={6} sm={12} style={{ justifyContent: 'center' }}>
						<Grid item xs={5}>
							<HourInput value={endHour} inputLabel='Hora final' onChange={onChangeEndHour} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} container style={{ marginTop: '1em' }} alignItems='flex-end' justifyContent='center'>
					<Button
						id='btn-calendar-save'
						variant='outlined'
						onClick={onSearchButtonPress}
						color='primary'
						style={{
							marginTop: '1em',
							width: '20em',
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							color: '#1d8cf8',
							borderColor: '#1d8cf8',
							borderRadius: '100px'
						}}
					>
						Salvar
					</Button>
				</Grid>
			</Card>
		</Dialog>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps, null)(memo(DateIntervalSelectorBar))
