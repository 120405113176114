import React, { useState } from 'react'
import { Grid, Card, Button, Switch, Collapse, styled, Badge } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		backgroundColor: '#1d8cf8'
	}
})

const subTag = (obj, onChangeHierarchy) => {
	return (
		<Grid key={obj?.subtagName} item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Grid container justifyContent='space-between'>
				<Grid item xs={9}>
					<Button
						disabled
						style={{
							height: '36.5px',
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							fontSize: '12px',
							color: '#9a9a9a',
							justifyContent: 'space-between',
							borderRadius: '20px',
							width: '100%',
							backgroundColor: 'rgba(255,255,255,0)',
							marginTop: '10px',
							marginBottom: '10px',
							marginLeft: '10px',
							marginRight: '0px'
						}}
					>
						{obj?.subtagName}
					</Button>
				</Grid>
				<Grid item xs={3} container style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
					<div style={{ marginLeft: '30px', marginTop: '7px' }}>
						<Switch onClick={() => onChangeHierarchy(obj)} color='primary' checked={obj?.active} />
					</div>
				</Grid>
			</Grid>
		</Grid>
	)
}

const TagOption = ({ obj, onChangeHierarchy, onChangeAllSubtags }) => {
	const [isOpen, setIsOpen] = useState(false)

	const isComp = obj?.subtags?.length > 0

	return (
		<Grid item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Card
				elevation={0}
				style={{
					borderRadius: '20px',
					marginTop: '1em',
					margin: '10px',
					width: '95%',
					backgroundColor: 'rgba(255,255,255,0.6)'
				}}
			>
				<Grid container justifyContent='space-between'>
					<Grid item xs={9}>
						<Button
							onClick={() => isComp && setIsOpen(!isOpen)}
							disabled={!isComp}
							fullWidth
							style={{
								height: '36.5px',
								fontFamily: 'Poppins',
								fontWeight: 'bold',
								color: '#1d8cf8',
								justifyContent: 'flex-start',
								borderRadius: '0px',
								width: '100%',
								backgroundColor: 'rgba(255,255,255,1)'
							}}
						>
							{isComp && !isOpen && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
							{isComp && isOpen && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
							{!isComp && <ChevronUpIcon size={20} style={{ marginRight: '10px', color: 'transparent' }} />}
							{obj?.name}
							{isComp && (
								<StyledBadge
									badgeContent={obj?.subtags?.filter((sub) => sub.active).length}
									color='error'
									style={{ margin: '0.9em 0 1.1em 1.5em', fontFamily: 'Poppins' }}
								/>
							)}
						</Button>
					</Grid>
					<Grid item xs={3} container style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
						<div style={{ marginLeft: '29px' }}>
							<Switch onClick={() => onChangeHierarchy(obj)} color='primary' checked={obj?.active} />
						</div>
					</Grid>
				</Grid>
				{isComp && (
					<Collapse in={isOpen}>
						<div style={{ height: '10px' }} />
						{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
						<div style={{ display: 'flex', marginLeft: '10px', justifyContent: 'space-between' }}>
							<Button
								onClick={() => onChangeAllSubtags(obj, true)}
								style={{ textTransform: 'none', borderRadius: '100px', fontFamily: 'Poppins' }}
							>
								Ativar todas
							</Button>
							<Button
								onClick={() => onChangeAllSubtags(obj, false)}
								style={{ textTransform: 'none', borderRadius: '100px', fontFamily: 'Poppins', color: '#9a9a9a' }}
							>
								Desativar todas
							</Button>
						</div>
						<Grid container>{obj?.subtags?.map((sub) => subTag(sub, onChangeHierarchy))}</Grid>
					</Collapse>
				)}
			</Card>
		</Grid>
	)
}

export default TagOption
