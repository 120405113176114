import React, { useState, useRef, memo, useEffect } from 'react'
import NetworkGraphRealTime from './components/NetworkGraphRealTime/NetworkGraphRealTime'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullScreenButton from '../../components/FullScreenButton/FullScreenButton'
import { BiNetworkChart } from 'react-icons/bi'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import InspectIntervalButton from '../components/InspectIntervalButton/InspectIntervalButton'
import { Grid, Card, Divider } from '@mui/material'
import NetworkMenu from './components/NetworkMenu/NetworkMenu'
import NetworkSummary from 'components/NetworkSummary/NetworkSummary'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import SourceTypeSelector from './components/SourceTypeSelector/SourceTypeSelector'
import { connect } from 'react-redux'

const DEFAULT_SOURCE_TYPE = 'account'
const NetworkGraph = ({ cards, isFetchingData, globalFiltersRedux, cardType }) => {
	const [fullScreenState, setFullScreenState] = useState(false)
	const [sourceType, setSourceType] = useState(DEFAULT_SOURCE_TYPE)
	const [type, setType] = useState('graphChart')
	const [errorFilterComponent, setErrorFilterComponent] = useState(null)
	const [errorGroupFilterComponent, setErrorGroupFilterComponent] = useState(null)

	const fullScreenHandle = useFullScreenHandle()
	const loadingBarRef = useRef()

	useEffect(() => {
		setSourceType(DEFAULT_SOURCE_TYPE)
		setErrorFilterComponent(null)
		setErrorGroupFilterComponent(null)
	}, [globalFiltersRedux.sources])

	const handleChangeSourceType = (sourceType) => {
		setSourceType(sourceType)
	}

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const handleChangeGraphType = (type) => setType(type)

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<FullScreen handle={fullScreenHandle} onChange={() => setFullScreenState(!fullScreenState)}>
					<Card style={{ height: '100%', borderRadius: '20px' }}>
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<Grid item xs={6}>
								<GraphTitle
									id='span-network'
									title='Influenciadores'
									icon={<BiNetworkChart size={25} style={{ color: '#1d8cf8' }} />}
									description='Quem são os principais influenciadores e as principais hashtags para o tema analisado?
										 Visualize em forma de grafo ou diagrama de sankey, interaja com os personagens e obtenha mais detalhes sobre o engajamento.'
								/>
							</Grid>
							<Grid item xs={6}>
								<FullScreenButton fullScreenState={fullScreenState} handle={fullScreenHandle} />
							</Grid>
						</Grid>
						<div>
							<LoadingBar ref={loadingBarRef} />
						</div>

						{!errorFilterComponent ? (
							<>
								{!errorGroupFilterComponent ? (
									<>
										<Grid container>
											<Grid item xs={6}>
												<SourceTypeSelector
													sourceType={sourceType}
													onSourceTypeChange={handleChangeSourceType}
													cardType={cardType}
													source={globalFiltersRedux.sources}
												/>
											</Grid>
											<Grid item xs={6}>
												<NetworkMenu handleChangeGraphType={handleChangeGraphType} />
											</Grid>
										</Grid>
										<Divider
											style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }}
										/>
										<NetworkGraphRealTime
											cards={cards}
											fullScreenHandle={fullScreenHandle}
											fullScreenState={fullScreenState}
											setFullScreenState={setFullScreenState}
											sourceType={sourceType}
											onPartialLoading={onPartialLoading}
											type={type}
											isFetchingData={isFetchingData}
											filters={globalFiltersRedux}
											cardType={cardType}
										/>
										{fullScreenState && (
											<div style={{ marginLeft: '20px', marginBottom: '20px' }}>
												<InspectIntervalButton
													cards={cards}
													filters={globalFiltersRedux}
													cardType={cards[0]?.type}
													showCloud={true}
												/>
											</div>
										)}
									</>
								) : (
									<Grid container direction='row' justifyContent='center' alignItems='center'>
										<h2 style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>{errorGroupFilterComponent}</h2>
									</Grid>
								)}
							</>
						) : (
							<Grid container direction='row' justifyContent='center' alignItems='center'>
								<h2 id='span-network-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
									{errorFilterComponent}
								</h2>
							</Grid>
						)}
					</Card>
				</FullScreen>
			</Grid>

			{!errorFilterComponent && (
				<Grid container item xs={12}>
					<NetworkSummary cardsIds={cards?.map((card) => card.id)} cards={cards} filters={globalFiltersRedux} cardType={cardType} />
				</Grid>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(NetworkGraph))
