import React, { useState, useEffect, memo, useRef } from 'react'
import { Chip, Grid, TextField, Autocomplete, Paper, styled, Switch, Collapse, Card, Button, FormHelperText } from '@mui/material'
import { PlusIcon, MinusIcon, LoadingIcon, ChevronDownIcon, ChevronUpIcon, ForbiddenIcon } from 'utils/SystemIcons'
import DialogInformation from 'components/DialogInformation/DialogInformation'
import CardTags from 'components/CardTags/CardTags'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import './OptionalConfigNewInsightTerm.css'
import constantsVox from 'constants-vox'
import { cloneDeep } from 'lodash'

const red = 'rgba(255, 43, 107, 100%)'
const white = '#ffffff'
const blue = 'rgba(29, 138, 248, 100%)'
const gray = '#9a9a9a'

const title = 'Dicionário de classificação'
const title2 = 'Dados de histórico'
const title3 = 'Regras de tagueamento'
const description = 'Posts que contenham as palavras adicionadas aqui serão automaticamente classificados como positivos ou negativos.'
const description2 = 'Se a opção for selecionada, busca também posts antes da data de criação. (Consumirá parte dos seus créditos)'
const description3 =
	'Adicione regras de tagueamento para melhorar a classificação das postagens. As tags serão adicionadas automaticamente nas postagens que atendam as regras especificadas aqui. Caso não queira adicionar tags, deixe o campo vazio.'

let lastOnDeleteTime = null

const isFirefox = typeof InstallTrigger !== 'undefined'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}
const messageToast = (message) => {
	sendMessageToast(message, constantsVox.TOAST.TYPES.WARNING)
}

const validateStopWords = (valueArray, formState, onChangeForm, event) => {
	if (event.keyCode) {
		//se tiver, quer dizer que foi pressionado enter, que quer dizer que a palavra foi adicionada (e não removida)
		const error = verifyStartsAndEndsWithSymbols(valueArray)
		if (error) {
			return messageToast(error)
		}
		valueArray = valueArray.flatMap((item) => item.split(';'))
		valueArray = valueArray.filter((item) => !['@', ';', ''].includes(item))

		const duplicates = [...new Set(valueArray.filter((str, index, arr) => arr.indexOf(str) !== index))]
		if (duplicates.length > 0) {
			const duplicatedWords = duplicates.join(', ')
			messageToast('As seguintes palavras já foram adicionadas: ' + duplicatedWords)
		} else {
			onChangeForm({
				stopWords: { ...formState.stopWords, words: valueArray }
			})
		}
	} else {
		onChangeForm({
			stopWords: { ...formState.stopWords, words: valueArray }
		})
	}
}

const verifyStartsAndEndsWithSymbols = (valueArray) => {
	let error = false
	if (valueArray.some((acc) => acc.startsWith(';'))) {
		error = 'O texto não pode iniciar com o caractere ;'
	} else if (valueArray.some((acc) => acc.endsWith(';'))) {
		error = 'O texto não pode terminar com o caractere ;'
	} else if (valueArray.some((acc) => acc.startsWith('@') && acc.length === 1)) {
		error = 'O texto não pode ter somente o caractere @'
	}

	return error
}

const validatePositiveAndNegative = (valueArray, formState, type, onChangeForm, event) => {
	if (event.keyCode) {
		//se tiver, quer dizer que foi pressionado enter, que quer dizer que a palavra foi adicionada (e não removida)
		const error = verifyStartsAndEndsWithSymbols(valueArray)
		if (error) {
			return messageToast(error)
		}
		valueArray = valueArray.flatMap((item) => item.split(';'))
		valueArray = valueArray.filter((item) => !['@', ';', ''].includes(item))
		const formPositiveLowerCase = formState?.manualClassificationDictionary?.positive.map((item) => item?.toLowerCase())
		const formNegativeLowerCase = formState?.manualClassificationDictionary?.negative.map((item) => item?.toLowerCase())

		const valueLowerCase = valueArray.map((item) => item?.toLowerCase())
		const uniqueValueLowerCase = valueLowerCase.slice(type === 'negative' ? formNegativeLowerCase.length : formPositiveLowerCase.length)

		const duplicatesOnAddNew = uniqueValueLowerCase.filter((str, index, arr) => arr.indexOf(str) !== index)

		const allLowerCaseSet = new Set([...formPositiveLowerCase, ...formNegativeLowerCase])

		const duplicates = uniqueValueLowerCase.filter((item) => allLowerCaseSet.has(item))
		if (duplicates.length > 0 || duplicatesOnAddNew.length > 0) {
			const uniqueDuplicates = [...new Set([...duplicates, ...duplicatesOnAddNew])]
			const duplicatedWords = uniqueDuplicates.join(', ')
			messageToast('As seguintes palavras já foram adicionadas: ' + duplicatedWords)
		} else {
			let objectToAdd = {}
			type === 'positive' ? (objectToAdd.positive = valueArray) : (objectToAdd.negative = valueArray)
			onChangeForm({
				manualClassificationDictionary: {
					...formState.manualClassificationDictionary,
					...objectToAdd
				}
			})
		}
	} else {
		let objectToAdd = {}
		type === 'positive' ? (objectToAdd.positive = valueArray) : (objectToAdd.negative = valueArray)
		onChangeForm({
			manualClassificationDictionary: {
				...formState.manualClassificationDictionary,
				...objectToAdd
			}
		})
	}
}

const OptionalConfigNewInsightTerm = ({ formState, fieldErrors, onChangeForm, statusType, card, onChangeFieldErrors }) => {
	const isMounted = useRef(true)
	const [typingTextPositive, setTypingTextPositive] = useState('')
	const [typingTextNegative, setTypingTextNegative] = useState('')
	const [typingStopWords, setTypingStopWords] = useState('')
	const [loading] = useState(false)
	const [openDictioary, setOpenDictioary] = useState(false)
	const [openHistoric, setOpenHistoric] = useState(false)
	const [openStopWords, setOpenStopWords] = useState(false)
	const [openTags, setOpenTags] = useState(false)

	useEffect(() => {
		isMounted.current = true
		if (statusType === 'edit') {
			load()
		}
		return () => {
			isMounted.current = false
		}
	}, [])

	const load = () => {
		// setLoading(true)
		const manualClassificationDictionary = cloneDeep(card.manualClassificationDictionary) || { negative: [], positive: [] }
		const stopWords = cloneDeep(card.stopWords) || { words: [] }
		const tagsGlobalFilters = cloneDeep(card.tags) || { value: [] }
		onChangeForm({ manualClassificationDictionary, stopWords, tagsGlobalFilters })

		// api.get(`/card-term/manual-classification-dictionary/get-tags/${card.id}`).then((responseDictionary) => {
		// 	manualClassificationDictionary = responseDictionary.data.manual_classification_dictionary || { negative: [], positive: [] }
		// 	api.get(`/card-term/stop-words/get-words/${card.id}`)
		// 		.then((responseStopWords) => {
		// 			stopWords = responseStopWords.data.stop_word || { words: [] }
		// 		})
		// 		.then(() => {
		// 			api.get(`/card-term/tags/get-tag-config/${card.id}`)
		// 				.then((responseTags) => {
		// 					tags = responseTags.data.tags || { value: [] }
		// 				})
		// 				.then(() => {
		// 					onChangeForm({ manualClassificationDictionary, stopWords, tags })
		// 					setLoading(false)
		// 				})
		// 		})
		// })
	}

	const onChangeTextPositive = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingTextPositive(text)
		}
	}

	const onChangeTextNegative = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingTextNegative(text)
		}
	}

	const handleChangeHasHistoric = (e) => {
		onChangeForm({ hasHistoric: !!e.target.checked })
	}

	const onChangeHideWords = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingStopWords(text)
		}
	}

	const onChangeTags = (value) => {
		onChangeForm({ tagsGlobalFilters: value })
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<div className='color-blue fs-1-5'>Configurações opcionais</div>
			</Grid>
			{statusType === 'create' && formState.searchType === constantsVox.CARD.SEARCH_TYPE.TERM && (
				<Card
					elevation={0}
					style={{
						borderRadius: '25px',
						marginTop: '1em',
						marginLeft: '1em',
						width: '100%',
						backgroundColor: 'rgba(255,255,255,0.6)',
						border: '1px solid rgba(200, 200, 200, 0.2)'
					}}
				>
					<Button
						id='btn-toggle-historic'
						onClick={() => setOpenHistoric(!openHistoric)}
						fullWidth
						style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
					>
						{!openHistoric && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{openHistoric && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						Dados de histórico
					</Button>
					<Collapse in={openHistoric} style={{ width: '100%' }}>
						<Grid item xs={12} style={{ padding: '1.5em', font: 'Poppins' }}>
							<Switch checked={formState.hasHistoric} onChange={handleChangeHasHistoric} />
							Buscar dados anteriores a data de criação do cartão
							<DialogInformation title={title2} text={description2} marginTop={0} />
							<div style={{ marginLeft: '5em', marginTop: '-5px', marginBottom: '1em', fontSize: '12px', color: '#8a8888' }}>
								(Consumirá parte dos seus créditos)
							</div>
						</Grid>
					</Collapse>
				</Card>
			)}
			<Card
				elevation={0}
				style={{
					borderRadius: '25px',
					marginTop: '1em',
					marginLeft: '1em',
					width: '100%',
					backgroundColor: 'rgba(255,255,255,0.6)',
					border: '1px solid rgba(200, 200, 200, 0.2)'
				}}
			>
				<Grid item xs={12}>
					<Button
						onClick={() => setOpenStopWords(!openStopWords)}
						fullWidth
						style={{
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							color: blue,
							justifyContent: 'flex-start'
						}}
					>
						{!openStopWords && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{openStopWords && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						<span style={{ color: blue }}>Ocultar certas postagens</span>
					</Button>
				</Grid>
				<Collapse in={openStopWords} style={{ width: '100%' }}>
					<Grid item xs={12}>
						<Autocomplete
							className='stop-words'
							disabled={loading}
							multiple
							freeSolo
							PaperComponent={CustomPaper}
							disableClearable
							inputValue={typingStopWords}
							value={formState?.stopWords?.words}
							options={[]}
							onInputChange={(_, value) => onChangeHideWords(value)}
							isOptionEqualToValue={() => false}
							onChange={(event, value) => validateStopWords(value, formState, onChangeForm, event)}
							style={{ margin: '0.5em 1em 0em 1em' }}
							renderInput={(params) => (
								<div style={{ position: 'relative' }}>
									<CssTextField
										variant='standard'
										label={
											<div style={{ display: 'flex' }}>
												{loading ? (
													<LoadingIcon
														size={20}
														thickness={1}
														style={{ color: gray, transform: 'translate(0px, 2px)', marginRight: '7px' }}
													/>
												) : (
													<ForbiddenIcon
														size={20}
														style={{ color: gray, transform: 'translate(0px, 2px)', marginRight: '7px' }}
													/>
												)}
												Ocultar as postagens que tenham as palavras:
											</div>
										}
										error={!!fieldErrors?.stopWords?.words?.error}
										helperText={fieldErrors?.stopWords?.words?.error}
										{...params}
									/>
								</div>
							)}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										style={{ color: white, backgroundColor: gray, borderColor: gray }}
										key={index}
										variant='outlined'
										label={option}
										{...getTagProps({ index })}
										onDelete={(e) => {
											const props = getTagProps({ index })
											props.onDelete(e)
											lastOnDeleteTime = new Date().getTime()
										}}
									/>
								))
							}
						/>
						<div style={{ height: '1em', color: blue, margin: '0.25em 1em 1em 1em' }}>
							{typingStopWords && typingStopWords !== '' && 'Pressione Enter para adicionar a palavra'}
						</div>
					</Grid>
				</Collapse>
			</Card>
			<Card
				elevation={0}
				style={{
					borderRadius: '25px',
					marginTop: '1em',
					marginLeft: '1em',
					width: '100%',
					backgroundColor: 'rgba(255,255,255,0.6)',
					border: '1px solid rgba(200, 200, 200, 0.2)'
				}}
			>
				<Button
					id='btn-toggle-classification'
					onClick={() => setOpenDictioary(!openDictioary)}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openDictioary && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openDictioary && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Dicionário de classificação
				</Button>
				<Collapse in={openDictioary} style={{ width: '100%' }}>
					<Grid container style={{ padding: '30px' }}>
						<Grid item xs={12} style={{ width: '100%' }}>
							<div>
								Adicionar palavras de guia para classificação de posts
								<DialogInformation title={title} text={description} marginTop={0} />
							</div>

							<Autocomplete
								className='manual-classification-dictionary'
								disabled={loading}
								multiple
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								inputValue={typingTextPositive}
								value={formState?.manualClassificationDictionary?.positive}
								options={[]}
								onInputChange={(_, value) => onChangeTextPositive(value)}
								isOptionEqualToValue={() => false}
								onChange={(event, value) => validatePositiveAndNegative(value, formState, 'positive', onChangeForm, event)}
								style={{ width: '100%' }}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													{loading ? (
														<LoadingIcon
															size={20}
															thickness={1}
															style={{ color: blue, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
													) : (
														<PlusIcon
															size={20}
															style={{ color: blue, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
													)}
													Palavras positivas
												</div>
											}
											error={!!fieldErrors?.manualClassificationDictionary?.positive?.error}
											helperText={fieldErrors?.manualClassificationDictionary?.positive?.error}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											style={{ color: white, backgroundColor: blue, borderColor: blue }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
							<div style={{ height: '1em', marginTop: '0.3em', color: blue }}>
								{typingTextPositive && typingTextPositive !== '' && 'Pressione Enter para adicionar a palavra'}
							</div>
						</Grid>
						<Grid item xs={12} style={{ marginTop: '1em' }}>
							<Autocomplete
								className='manual-classification-dictionary'
								disabled={loading}
								multiple
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								value={formState?.manualClassificationDictionary?.negative}
								options={[]}
								inputValue={typingTextNegative}
								isOptionEqualToValue={() => false}
								onChange={(event, value) => validatePositiveAndNegative(value, formState, 'negative', onChangeForm, event)}
								onInputChange={(_, value) => onChangeTextNegative(value)}
								style={{ width: '100%' }}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													{loading ? (
														<LoadingIcon
															size={20}
															thickness={1}
															style={{ color: red, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
													) : (
														<MinusIcon
															size={20}
															style={{ color: red, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
													)}
													Palavras negativas
												</div>
											}
											error={!!fieldErrors?.manualClassificationDictionary?.negative?.error}
											helperText={fieldErrors?.manualClassificationDictionary?.negative?.error}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											style={{ color: white, backgroundColor: red, borderColor: red }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
							<div style={{ height: '1em', marginTop: '0.3em', color: blue }}>
								{typingTextNegative && typingTextNegative !== '' && 'Pressione Enter para adicionar a palavra'}
							</div>
						</Grid>
					</Grid>
				</Collapse>
			</Card>
			<Card
				elevation={0}
				style={{
					borderRadius: '25px',
					marginTop: '1em',
					marginLeft: '1em',
					width: '100%',
					backgroundColor: 'rgba(255,255,255,0.6)',
					border: '1px solid rgba(200, 200, 200, 0.2)'
				}}
			>
				<Button
					id='btn-toggle-tags'
					onClick={() => setOpenTags(!openTags)}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openTags && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openTags && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Regras de tagueamento
				</Button>
				<Collapse in={openTags} style={{ width: '100%' }}>
					<Grid item xs={12} style={{ padding: '1.5em', font: 'Poppins' }}>
						Adicionar regras de tagueamento ao cartão
						<DialogInformation title={title3} text={description3} marginTop={0} />
						<CardTags onChangeTags={onChangeTags} externalTags={formState?.tagsGlobalFilters} onChangeFieldErrors={onChangeFieldErrors} />
						<div style={{ marginLeft: '30px' }}>
							<FormHelperText style={{ color: 'red', fontSize: '16px' }}>{fieldErrors?.tagsGlobalFilters?.error}</FormHelperText>
						</div>
					</Grid>
				</Collapse>
			</Card>
		</Grid>
	)
}

export default memo(OptionalConfigNewInsightTerm)
