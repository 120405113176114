import React, { useEffect, useState } from 'react'
import { IconButton, TextField, Tooltip, Grid, Dialog, Button, styled, FormHelperText } from '@mui/material'
import { DotsIcon, CloseIcon, SearchIcon } from 'utils/SystemIcons'
import QueryBuilder from 'components/QueryBuilder/QueryBuilder'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const queryObjectReset = { format: { jsonLogicFormat: '' }, isValid: true }

const CssTextFieldSearch = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

function formatQuery(input) {
	const sanitizedString = input
		.replace(/[^a-zA-Z0-9() %çáéíóúâêîôûàèìòùãõ]/gi, '')
		.replaceAll('(', ' ( ')
		.replaceAll(')', ' ) ')
		.replace(/\s+/g, ' ')

	const words = sanitizedString.split(/\s+/)

	const formattedWords = words.map((word) => {
		const upperCaseWord = word.toUpperCase()
		if (upperCaseWord === 'AND' || upperCaseWord === 'OR' || upperCaseWord === 'NOT') {
			return upperCaseWord
		}
		return word && word !== '(' && word !== ')' ? `"${word.toLowerCase()}"` : `${word.toLowerCase()}`
	})

	const formattedString = formattedWords.join(' ')

	return formattedString.trim().replaceAll('" "', ' ')
}

function validateParentheses(str) {
	const stack = []

	for (let i = 0; i < str.length; i++) {
		const char = str[i]
		if (char === '(') {
			stack.push(char) // Empilhe os parênteses de abertura.
		} else if (char === ')') {
			if (stack.length === 0) {
				return false // Encontrou um parêntese de fechamento sem correspondente.
			}
			stack.pop() // Desempilhe um parêntese de abertura correspondente.
		}
	}

	return stack.length === 0 // A pilha deve estar vazia no final se os parênteses estiverem corretos.
}

function validateString(str) {
	const sanitizedString = str.replaceAll('(', ' ( ').replaceAll(')', ' ) ').replace(/\s+/g, ' ')

	const words = sanitizedString.split(/\s+/)

	for (let i = 0; i < words.length; i++) {
		const word = words[i].toUpperCase()

		if (word === '(') {
			const previousWord = words[i - 1]
			const nextWord = words[i + 1]
			if (nextWord && nextWord === ')') {
				return false
			}
			if (nextWord && ['AND', 'OR', 'NOT'].includes(nextWord.toUpperCase())) {
				return false
			}
			if (previousWord && !['AND', 'OR', 'NOT'].includes(previousWord.toUpperCase())) {
				return false
			}
		} else if (word === ')') {
			const nextWord = words[i + 1]
			const previousWord = words[i - 1]
			if (previousWord && previousWord === '(') {
				return false
			}
			if (previousWord && ['AND', 'OR', 'NOT'].includes(previousWord.toUpperCase())) {
				return false
			}
			if (nextWord && !['AND', 'OR', 'NOT'].includes(nextWord.toUpperCase())) {
				return false
			}
		} else if (word === 'AND' || word === 'OR' || word === 'NOT') {
			const previousWord = words[i - 1]
			const nextWord = words[i + 1]
			if (!previousWord || !nextWord || (previousWord === '(' && nextWord === ')')) {
				return false
			}
		}
	}

	return true
}

const validateQuery = (query) => {
	return validateParentheses(query) && validateString(query)
}

const tooltipDelay = 500

const SearchBarGlobalFilters = ({
	onSearchEnterOrEmpty = null,
	onSearchChange = null,
	contentTextSearch,
	title = 'Buscar',
	icon = <SearchIcon size={17} style={{ color: blue, marginTop: '4px' }} />,
	isMainSearch = false,
	isQuerySearch = true,
	isTaging = false,
	tag = '',
	id = null,
	externalQueryText = '',
	jsonQuery = null,
	onChangeQueryObject = null,
	onChangeFieldErrors = null
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const [openQueryBuilder, setOpenQueryBuilder] = useState(false)
	const [queryText, setQueryText] = useState(externalQueryText)
	const [queryObject, setQueryObject] = useState({})
	const [error, setError] = useState(false)
	const [thiscontentTextSearch, setcontentTextSearch] = useState(contentTextSearch)
	const [message, setMessage] = useState({ error: false, text: '' })

	useEffect(() => {
		setcontentTextSearch(contentTextSearch)
	}, [contentTextSearch])

	useEffect(() => {
		isTaging && handleMessage()
	}, [thiscontentTextSearch, isFocused])

	useEffect(() => {
		isTaging && message.text !== '' && onChangeFieldErrors({ tags: { error: ' ' } })
	}, [message])

	const handleInputChange = (e) => {
		if (onSearchChange) {
			onSearchChange(e.target.value)
		}
		handleOnChangeText(e.target.value)
		if (isTaging) {
			setQueryText(e.target.value)
		}
	}

	const clearSearch = () => {
		if (onSearchChange) {
			onSearchChange('')
		}

		if (!isTaging) {
			handleOnChangeText('')
		} else {
			setQueryText('')
			setQueryObject(queryObjectReset)
			onSerchButtonPress('')
			onConfirmTagRule(true, '')
		}
	}

	const onClose = () => {
		setError(false)
		setOpenQueryBuilder(false)
	}

	const onChangeLabel = (newText) => {
		if (newText) {
			setQueryText(newText)
		}
	}

	const onChangeQuery = (newQuery) => {
		setQueryObject(newQuery)
	}

	const onSerchButtonPress = (newQueryText = null) => {
		const isApplyQuery = true
		handleOnChangeText(newQueryText ?? queryText, isApplyQuery)
		setOpenQueryBuilder(false)
	}

	const onConfirmTagRule = (resetQueryObject = false, newQueryText = null) => {
		if (onChangeQueryObject) {
			if (queryObject?.isValid !== undefined && queryObject?.isValid === false) {
				setError(!queryObject?.isValid)
			} else {
				onChangeQueryObject(resetQueryObject ? queryObjectReset : queryObject, newQueryText ?? queryText)
				onClose()
			}
		}
	}

	const handleOnChangeText = (text, isApplyQuery) => {
		setcontentTextSearch(text)
		if (onSearchChange) {
			onSearchChange(text)
		}
		if (text === '' || (isMainSearch && isApplyQuery)) {
			!isTaging && onSearchEnterOrEmpty(text)
		}
	}

	const handleMessage = () => {
		if (thiscontentTextSearch !== contentTextSearch && isFocused) {
			setMessage({ error: false, text: 'Pressione Enter para adicionar' })
		} else if (!thiscontentTextSearch) {
			setMessage({ error: true, text: 'Adicione pelo menos uma regra' })
		} else if (thiscontentTextSearch !== contentTextSearch && !isFocused) {
			setMessage({ error: true, text: 'Verifique se a regra foi adicionada' })
		} else {
			setMessage({ error: false, text: '' })
		}
	}

	return (
		<>
			<Grid container style={{ marginTop: '-1px' }}>
				<Grid item xs={11} style={{ display: 'flex', width: '100%' }}>
					<CssTextFieldSearch
						id={id ?? 'input-search-text'}
						variant='standard'
						InputLabelProps={{ shrink: (thiscontentTextSearch !== '' && !!thiscontentTextSearch) || isFocused }}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						value={thiscontentTextSearch}
						onChange={handleInputChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								isMainSearch && onSearchEnterOrEmpty(thiscontentTextSearch)
								if (isTaging) {
									if (validateQuery(thiscontentTextSearch)) {
										onSerchButtonPress(formatQuery(thiscontentTextSearch))
										onConfirmTagRule(true, formatQuery(thiscontentTextSearch))
										onChangeFieldErrors({ tags: { error: false } })
									} else {
										onChangeFieldErrors({ tags: { error: 'Verifique se a regra é válida' } })
									}
								}
							}
						}}
						fullWidth
						label={
							<div style={{ display: 'flex' }}>
								{icon}
								<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>{title}</div>
							</div>
						}
					/>
					{thiscontentTextSearch !== '' && (
						<Grid style={{ borderBottom: '1px solid rgba(118, 118, 118, 0.87)' }}>
							<Tooltip title='Limpar Busca' placement='left' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<IconButton
									size='small'
									style={{
										marginTop: '20px',
										outline: 'none',
										float: 'right'
									}}
									onClick={clearSearch}
								>
									<CloseIcon size={17} style={{ color: blue }} />
								</IconButton>
							</Tooltip>
						</Grid>
					)}
				</Grid>
				<Grid item xs={1} style={{ borderBottom: '1px solid rgba(118, 118, 118, 0.87)' }}>
					{isQuerySearch && (
						<Tooltip title='Busca Avançada' placement='left' enterDelay={tooltipDelay / 2} enterNextDelay={tooltipDelay / 2}>
							<IconButton size='small' style={{ marginTop: '15px', float: 'right' }} onClick={() => setOpenQueryBuilder(true)}>
								<DotsIcon size={22} style={{ color: blue }} />
							</IconButton>
						</Tooltip>
					)}
				</Grid>
			</Grid>
			{isTaging && <Grid style={{ color: message.error ? 'red' : blue, fontSize: '13px' }}>{message.text}</Grid>}
			{openQueryBuilder && (
				<Dialog
					open={true}
					onClose={onClose}
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
					}}
					fullWidth={true}
					maxWidth='md'
				>
					{isTaging && (
						<Grid item xs={12}>
							<div style={{ marginTop: '1em', marginLeft: '2.5em' }}>
								<FormHelperText style={{ color: blue, fontSize: '16px' }}>
									Taguear como {tag} caso as postagens tenham:
								</FormHelperText>
							</div>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						style={{
							padding: '1em',
							maxHeight: window.innerHeight - 50,
							overflow: 'auto'
						}}
					>
						<QueryBuilder onChangeLabel={onChangeLabel} jsonQuery={jsonQuery} onChangeQuery={onChangeQuery} />
					</Grid>
					{isTaging && error && (
						<Grid item xs={12} container justifyContent='center'>
							<FormHelperText style={{ color: 'red', fontSize: '16px' }}>
								Regra Invalida verifique todos os campos e tente novamente
							</FormHelperText>
						</Grid>
					)}
					<Grid item xs={12} container justifyContent='center'>
						{!isTaging && (
							<Button
								variant='outlined'
								onClick={() => onSerchButtonPress()}
								color='primary'
								style={{
									marginTop: '1em',
									marginBottom: '1em',
									width: '20em',
									fontFamily: 'Poppins',
									fontWeight: 'bold',
									color: '#1d8cf8',
									borderColor: '#1d8cf8',
									borderRadius: '100px'
								}}
							>
								{isMainSearch ? 'Buscar' : 'Ok'}
							</Button>
						)}
						{isTaging && (
							<Button
								variant='outlined'
								onClick={() => {
									onSerchButtonPress()
									onConfirmTagRule()
								}}
								color='primary'
								style={{
									marginTop: '1em',
									marginBottom: '1em',
									width: '20em',
									fontFamily: 'Poppins',
									fontWeight: 'bold',
									color: '#1d8cf8',
									borderColor: '#1d8cf8',
									borderRadius: '100px'
								}}
							>
								{'Ok'}
							</Button>
						)}
					</Grid>
				</Dialog>
			)}
		</>
	)
}

export default SearchBarGlobalFilters
