import React, { memo, useState } from 'react'
import { Grid, Button, Card, CardContent } from '@mui/material'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AnalysisCardMenu from 'components/AnalysisCardMenu/AnalysisCardMenu'
import { LinkIcon } from 'utils/SystemIcons'
import { addCardComparisonByIdsAction } from 'store/redux/actions/card.actions'
import { handleClickRedirectCard, CardIcon, getCardColorByCardType, getBoxShadowByCardType, getClassNameByCardType } from './analysisCardUtils'
import AnalysisCardFooter from './components/AnalysisCardFooter/AnalysisCardFooter'
import constantsVox from 'constants-vox'
import './AnalysisCard.css'
import NetworksDialog from './components/NetworksDialog/NetworksDialog'

const formatReportStatus = (status) => {
	if (status === 'TWEET_TEXT_ERROR') {
		return 'Erro no texto do tweet'
	} else if (status === 'TWEET_NOT_FOUND') {
		return 'Tweet não encontrado verifique o tweet'
	} else if (status === 'USER_NOT_FOUND') {
		return 'Usuário não encontrado verifique o úsuario'
	} else if (status === 'USER_PRIVACY') {
		return 'Erro Conta Privada'
	} else if (status === 'PROCESSING_R') {
		return 'Erro no processamento'
	} else if (status === 'ERROR_FOLLOWERS') {
		return 'Erro ao buscar seguidores'
	} else if (status === 'ERROR_FRIENDS') {
		return 'Erro ao buscar amigos'
	} else if (status === 'INSUFFICIENT_RETWEETS') {
		return 'Erro retweets insuficientes'
	} else if (status === 'INSUFFICIENT_LIKES') {
		return 'Erro retweets insuficientes'
	} else if (status === 'INCOMPLETE_FRIENDS_LIST') {
		return 'Erro Lista de amigos incompleta'
	} else if (status === 'INCOMPLETE_FOLLOWERS_LIST') {
		return 'Erro Lista de seguidores incompleta'
	}
}

const formatSearchStatus = (status, type, reportStatus = null, accounts, folderId) => {
	if (type === 'group' && accounts?.length === 1 && accounts[0] === null && folderId !== 'COMPARATIVE_ID') {
		return 'Cartão sem contas vinculadas'
	}
	switch (status) {
		case 'S':
			return 'Abrir'
		case 'E':
			return 'Nenhuma ocorrência'
		case 'W':
			if (type === 'report' && reportStatus) {
				const res = formatReportStatus(reportStatus)
				return res
			} else {
				return 'Falha ao buscar dados'
			}
		case 'P':
		case 'C':
		case 'A':
			return 'Processando'
		default:
			return 'Cartão sem status válido.'
	}
}

const checkConnectButtonStatus = (cardStatus) =>
	cardStatus?.general === null &&
	cardStatus?.twitter === null &&
	cardStatus?.facebook === null &&
	cardStatus?.instagram === null &&
	cardStatus?.metaAds === null

const AnalysisCard = ({
	folder,
	refetchItems,
	item,
	addCardComparisonByIdDispatch,
	permissionCreateCard,
	permissionRemoveCard,
	isUserAdmin,
	termsRemainingMonth = null
}) => {
	const [isOpenNetworksDialog, setIsOpenNetworksDialog] = useState(false)
	let cardStatus = item?.cardStatus?.general
	let cardType = item?.type
	let searchType = item?.searchType
	let isDemo = item?.isDemo
	let className = getClassNameByCardType(cardType)
	let color = getCardColorByCardType(cardType)
	let shadowColor = getBoxShadowByCardType(cardType)

	const history = useHistory()

	const handleClickOpenCard = (profileId, isComparative, cardType, linkDownload) => {
		handleClickRedirectCard(cardType, history, linkDownload)

		if (cardType !== 'report') {
			addCardComparisonByIdDispatch(profileId, isComparative)
		}
	}

	const onCloseNetworksDialog = () => {
		setIsOpenNetworksDialog(false)
	}

	const openNetworksDialog = () => {
		setIsOpenNetworksDialog(true)
	}

	const handleText = () => {
		return !isDemo ? (
			'Tema'
		) : (
			<>
				<span>Tema</span> <span style={{ color: '#1d8cf8' }}>(Demonstração)</span>
			</>
		)
	}

	return (
		<Card
			className='cursor-default'
			style={{
				minWidth: '15em',
				borderRadius: '20px',
				borderBottom: `1px solid ${color}`,
				boxShadow: `0px 7px 10px -2px ${shadowColor}`
			}}
		>
			<CardContent>
				{isOpenNetworksDialog && <NetworksDialog item={item} isOpen={isOpenNetworksDialog} onCloseNetworksDialog={onCloseNetworksDialog} />}
				<Grid container>
					<Grid item xs={10} style={{ height: '2em' }}>
						<div className='d-flex ai-center'>
							<CardIcon status={cardStatus} type={cardType} termsRemainingMonth={termsRemainingMonth} searchType={searchType} />
							{cardType === 'term' && (
								<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '10px' }}>
									{termsRemainingMonth <= 0 && constantsVox.CARD_TYPE.TERM === searchType
										? 'Coleta pausada (sem termos)'
										: handleText()}
								</div>
							)}
							{cardType === 'profile' && <div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '10px' }}>Conta</div>}
							{cardType === 'group' && <div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '10px' }}>Grupo</div>}
							{cardType === 'report' && <div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '10px' }}>Fura Bolha</div>}
							{cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
								<div style={{ fontSize: '0.9em', color: '#9A9A9A', marginLeft: '10px' }}>Gerenciamento de contas</div>
							)}
						</div>
					</Grid>
					<Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '0.5em' }}>
						<AnalysisCardMenu
							card={item}
							refetchItems={refetchItems}
							selectedFolder={folder.id}
							color={color}
							permissionCreateCard={permissionCreateCard}
							permissionRemoveCard={permissionRemoveCard}
							isUserAdmin={isUserAdmin}
						/>
					</Grid>
				</Grid>

				<Grid container style={{ marginTop: '10px' }}>
					<Grid item xs={12}>
						<div style={{ textAlign: 'start' }}>
							<div className='title-analysis-card'>{item.name}</div>
						</div>
					</Grid>
				</Grid>

				<Grid container style={{ marginTop: '10px' }}>
					<Grid container className='jc-space-between mt-1 ai-center'>
						{!checkConnectButtonStatus(item?.cardStatus) && (
							<Button
								id='btn-open-card'
								size='small'
								className={className}
								style={{
									marginLeft: '0em',
									textTransform: 'none',
									paddingLeft: '1em',
									paddingRight: '1em',
									height: '2.5em'
								}}
								onClick={() => handleClickOpenCard(item.id, item.isComparative, cardType, item.linkDownload)}
								disabled={cardStatus !== 'S' || (cardType === 'group' && !item?.accounts && folder.id !== 'COMPARATIVE_ID')}
							>
								{formatSearchStatus(cardStatus, cardType, item.status, item.accounts, folder.id)}
							</Button>
						)}

						{checkConnectButtonStatus(item?.cardStatus) && cardType === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT && (
							<Grid container direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
								<Button
									id='btn-connect-card'
									size='small'
									className={className}
									style={{
										marginLeft: '0em',
										textTransform: 'none',
										paddingLeft: '1em',
										paddingRight: '1em',
										height: '2.5em'
									}}
									onClick={() => setIsOpenNetworksDialog(true)}
								>
									Conectar contas
									<LinkIcon size={22} style={{ cursor: 'pointer', color: '#FFF', marginLeft: '10px' }} />
								</Button>
							</Grid>
						)}
					</Grid>
				</Grid>
			</CardContent>
			<AnalysisCardFooter item={item} color={color} canEdit={permissionCreateCard} openNetworksDialog={openNetworksDialog} />
		</Card>
	)
}

const mapDispatchToProps = (dispatch) => ({
	addCardComparisonByIdDispatch: (profileId, isComparative) => dispatch(addCardComparisonByIdsAction(profileId, isComparative))
})

export default connect(null, mapDispatchToProps)(memo(AnalysisCard))
