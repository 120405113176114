//Types
const MONITORING_ACTION_TYPES = {
	SAVE_MONITORING_FILTERS: 'SAVE_MONITORING_FILTERS'
}

//Actions
const saveMonitoringFiltersAction = (filters) => {
	return {
		type: MONITORING_ACTION_TYPES.SAVE_MONITORING_FILTERS,
		payload: filters
	}
}

//Exports
export { MONITORING_ACTION_TYPES, saveMonitoringFiltersAction }
