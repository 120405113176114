import React, { memo } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import constantsVox from 'constants-vox'

import 'react-toastify/dist/ReactToastify.css'

const Toast = () => <ToastContainer pauseOnHover closeOnClick position='bottom-left' />

const sendMessageToast = (text, type) => {
	let toastType = 'default'
	switch (type) {
		case constantsVox.TOAST.TYPES.SUCCESS:
			toastType = 'success'
			break
		case constantsVox.TOAST.TYPES.WARNING:
			toastType = 'warning'
			break
		case constantsVox.TOAST.TYPES.INFO:
			toastType = 'info'
			break
		case constantsVox.TOAST.TYPES.ERROR:
			toastType = 'error'
			break
		default:
			break
	}
	if (text && text !== '') {
		if (toastType === 'default') {
			toast(text, { position: 'bottom-left' })
		} else {
			toast[toastType](text)
		}
	}
}

export default memo(Toast)
export { sendMessageToast }
