import React, { memo, useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import MiniCards from 'components/MiniCards/MiniCards'
import constantsVox from 'constants-vox'
const allSources = Object.values(constantsVox.SOCIAL_SOURCES)
	.filter((it) => it !== 'META_ADS')
	.map((s) => s.toLowerCase())

const Overview = ({ cardStatus, cards, isFetchingData, globalFiltersTermRedux, isSocialProfile = false, comparisonStatusRedux, cardTypeRedux }) => {
	const [cardList, setCardList] = useState(cards)
	const [filtersAllSources, setFiltersAllSources] = useState({ ...globalFiltersTermRedux, sources: allSources })
	const [summarySources, setSummarySources] = useState(
		Object.entries(comparisonStatusRedux)
			.filter((e) => e[1] === 'S' && e[0] !== 'general')
			.map((it) => it[0])
	)

	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'
	const canShowNews = () => cardStatus.news === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	useEffect(() => {
		setSummarySources(
			Object.entries(comparisonStatusRedux)
				.filter((e) => e[1] === 'S' && e[0] !== 'general')
				.map((it) => it[0])
		)
	}, [comparisonStatusRedux])

	useEffect(() => {
		setFiltersAllSources({ ...globalFiltersTermRedux, sources: allSources })
	}, [globalFiltersTermRedux])

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube() || canShowNews()) && (
				<>
					<MiniCards
						filters={filtersAllSources}
						cards={cardList}
						summarySources={summarySources}
						menuType='summary'
						isFetchingData={isFetchingData}
						isSocialProfile={isSocialProfile}
						cardType={cardTypeRedux}
					/>
				</>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters,
	comparisonStatusRedux: store.card.comparison.status,
	cardTypeRedux: store.card.cardType
})

export default connect(mapStateToProps)(memo(Overview))
