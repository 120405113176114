import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MiniCardSkeletonOverviewNumber = ({ height = '265.5px' }) => (
	<div style={{ marginTop: '-4px', borderRadius: '0px' }}>
		<Skeleton height={height} style={{ borderRadius: '0px' }} />
	</div>
)

export default MiniCardSkeletonOverviewNumber
