import React, { useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const blue = '#1d8af8'
const grey = '#b8b8b8'

const NetworkMenu = ({ handleChangeGraphType }) => {
	const [type, setType] = useState('graphChart')

	const classes = useStyles()

	const onGraphTypeChange = (event, newValue) => {
		setType(newValue)
		handleChangeGraphType(newValue)
	}

	return (
		<Tabs
			style={{ marginTop: '0px', float: 'right' }}
			centered
			value={type}
			onChange={onGraphTypeChange}
			TabIndicatorProps={{ style: { background: blue } }}
		>
			<Tab label='Grafo' value='graphChart' style={{ outline: 'none' }} className={classes.tab} />
			<Tab label='Sankey' value='sankeyChart' style={{ outline: 'none' }} className={classes.tab} />
		</Tabs>
	)
}

export default NetworkMenu
