import React, { memo } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'

const emptyProp = {
	options: {
		chart: {
			type: 'bar'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const formatValue = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return value
		} else {
			return value?.toFixed(2)
		}
	} else {
		return null
	}
}

const formatValuePercentage = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return `${value}%`
		} else {
			return `${value?.toFixed(2)}%`
		}
	} else {
		return null
	}
}

const sum = (array) => {
	return array.reduce((a, b) => a + b, 0)
}

//a function that takes an array of numbers and returns the percentage of each number in relation to the sum of all numbers
const getPercentage = (array) => {
	const sum = array.reduce((a, b) => a + b, 0)
	return array.map((value) => (value * 100) / sum)
}

const formatSeriesToTotal = (series) => {
	const formated = series.map((serie) => {
		let values = serie?.data?.map((data) => data[1])
		return sum(values)
	})

	const total = [
		{
			data: getPercentage(formated)
		}
	]

	return total
}

const getCategories = (data) => {
	if (data?.series) {
		return data.series.map((serie) => serie.name)
	} else {
		return []
	}
}

const TagGraphTotal100 = ({ data, height = window.innerHeight / 1.2 - 120 }) => {
	const getOptions = () => {
		if (data?.options) {
			return {
				colors: [
					'#008FFB',
					'#00E396',
					'#FEB019',
					'#FF4560',
					'#775DD0',
					'#104469',
					'#3cb44b',
					'#ffe119',
					'#4363d8',
					'#f58231',
					'#911eb4',
					'#46f0f0',
					'#f032e6',
					'#bcf60c',
					'#fabebe',
					'#008080',
					'#e6beff',
					'#9a6324',
					'#fffac8',
					'#800000',
					'#aaffc3',
					'#808000',
					'#ffd8b1',
					'#000075',
					'#808080',
					'#e3e1e1',
					'#000000'
				],
				chart: {
					height: 350,
					type: 'bar',
					toolbar: {
						show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: false,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom',
						export: {
							csv: {
								filename: 'voxradar_export',
								columnDelimiter: ','
							}
						}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: (value) => formatValuePercentage(value),
					offsetX: 0,
					textAnchor: 'middle',
					style: {
						fontSize: '13px',
						fontFamily: 'Roboto',
						colors: ['#fff']
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderRadius: 10,
						borderWidth: 1,
						borderColor: 'transparent',
						opacity: 0.15
					},
					dropShadow: {
						enabled: true,
						top: 1,
						left: 0,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				},
				xaxis: {
					categories: getCategories(data),
					labels: {
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						}
					}
				},
				yaxis: {
					labels: {
						show: true,
						align: 'right',
						minWidth: 0,
						maxWidth: 160,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						offsetX: -10,
						offsetY: 0,
						rotate: 0,
						formatter: (value) => formatValue(value)
					},
					max: 100
				},
				tooltip: {
					enabled: false
				}
			}
		} else {
			return emptyProp.options
		}
	}

	return (
		<Grid style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
			<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>
				<Chart
					options={getOptions()}
					series={data?.series ? formatSeriesToTotal(data?.series) : emptyProp.series}
					type={'bar'}
					width={'100%'}
					height={height}
				/>
			</div>
		</Grid>
	)
}

export default memo(TagGraphTotal100)
