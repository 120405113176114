import React, { useState } from 'react'
import { Card, CardContent, Tooltip, Button, Collapse, IconButton } from '@mui/material'
import FacebookIconImage from 'assets/img/facebook-icon.png'
import { VerifiedIcon, ExternalLinkIcon, ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import moment from 'moment-timezone'

const ProfileFacebookHeader = ({ analytics }) => {
	const [imageUrl, setImageUrl] = useState(analytics?.profile_picture?.data?.url || null)
	const [seeMore, setSeeMore] = useState(false)
	return (
		<Card style={{ borderRadius: '20px' }}>
			<CardContent>
				<div style={{ borderRadius: '20px ', margin: '10px' }}>
					<div
						style={{
							display: 'flex',
							borderRadius: '100px',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						<img
							style={{ width: '4em', height: '4em', borderRadius: '100px', border: 'solid 2px #1d8cf8', backgroundColor: '#1d8cf8' }}
							src={imageUrl || FacebookIconImage}
							onError={() => setImageUrl(null)}
						/>
						<div style={{ marginLeft: '20px' }}>
							<div style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', color: '#1d8cf8' }}>
								{analytics?.name}
								{analytics?.verified && (
									<Tooltip title='Usuário verificado' enterDelay={0} enterNextDelay={0}>
										<span
											style={{
												cursor: 'pointer',
												marginLeft: '0.3em'
											}}
										>
											<VerifiedIcon size={15} />
										</span>
									</Tooltip>
								)}
							</div>

							{analytics?.username && (
								<div style={{ marginTop: '1px', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold', color: '#1d8cf8' }}>
									@{analytics?.username}
								</div>
							)}

							{analytics?.about && (
								<div style={{ marginTop: '15px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#1d8cf8' }}>
									{analytics?.about}
								</div>
							)}

							<IconButton size='small' style={{ fontSize: '12px', borderRadius: '100px' }} onClick={() => setSeeMore(!seeMore)}>
								{!seeMore && <ChevronDownIcon size={20} />}
								{seeMore && <ChevronUpIcon size={20} />}
							</IconButton>

							<Collapse in={seeMore}>
								{analytics?.emails && analytics?.emails.length > 0 && (
									<div style={{ display: 'flex', marginTop: '15px' }}>
										<div
											style={{
												marginRight: '5px',
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											emails:
										</div>
										{analytics?.emails.map((email) => (
											<div
												key={email}
												style={{
													fontFamily: 'Poppins',
													fontSize: '12px',
													fontWeight: 'bold',
													color: '#1d8cf8'
												}}
											>
												{email}
											</div>
										))}
									</div>
								)}

								{analytics?.birthday && (
									<div style={{ display: 'flex', marginTop: '1px' }}>
										<div
											style={{
												marginRight: '5px',
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											Aniversário:
										</div>
										<div
											style={{
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											{moment(analytics?.birthday).format('DD/MM/YYYY')}
										</div>
									</div>
								)}

								{analytics?.location && (
									<div style={{ display: 'flex', marginTop: '1px' }}>
										<div
											style={{
												marginRight: '5px',
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											Latitude:
										</div>
										<div
											style={{
												marginRight: '10px',
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											{analytics?.location?.latitude}
										</div>
										<div
											style={{
												marginRight: '5px',
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											Longitude:
										</div>
										<div
											style={{
												fontFamily: 'Poppins',
												fontSize: '12px',
												fontWeight: 'bold',
												color: '#1d8cf8'
											}}
										>
											{analytics?.location?.longitude}
										</div>
									</div>
								)}

								{analytics?.website && (
									<Button
										variant='outlined'
										onClick={() => window.open(analytics?.website, '_blank')}
										style={{
											marginTop: '15px',
											fontFamily: 'Poppins',
											fontSize: '12px',
											fontWeight: 'bold',
											color: '#1d8cf8',
											borderRadius: '100px',
											marginRight: '15px'
										}}
									>
										Abrir Site
										<ExternalLinkIcon color={'#1d8cf8'} size={17} style={{ marginLeft: '8px' }} />
									</Button>
								)}
								{analytics?.link && (
									<Button
										variant='outlined'
										onClick={() => window.open(analytics?.link, '_blank')}
										style={{
											marginTop: '15px',
											fontFamily: 'Poppins',
											fontSize: '12px',
											fontWeight: 'bold',
											color: '#1d8cf8',
											borderRadius: '100px'
										}}
									>
										Abrir Página
										<ExternalLinkIcon color={'#1d8cf8'} size={17} style={{ marginLeft: '8px' }} />
									</Button>
								)}
							</Collapse>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export default ProfileFacebookHeader
