const downloadBase64File = (base64Content, fileName) => {
	const decodedContent = atob(base64Content)
	const arrayBuffer = new ArrayBuffer(decodedContent.length)
	const uint8Array = new Uint8Array(arrayBuffer)
	for (let i = 0; i < decodedContent.length; i++) {
		uint8Array[i] = decodedContent.charCodeAt(i)
	}
	const blob = new Blob([uint8Array], { type: 'application/octet-stream' })
	const url = URL.createObjectURL(blob)

	const link = document.createElement('a')
	link.href = url
	link.download = fileName
	document.body.appendChild(link)

	link.click()

	document.body.removeChild(link)

	URL.revokeObjectURL(url)
}

export { downloadBase64File }
