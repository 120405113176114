import React, { Fragment, memo } from 'react'
import { Grid } from '@mui/material'
import Summary from 'components/Summary/Summary'
import NetworkSummary from 'components/NetworkSummary/NetworkSummary'
import LocationStateAndRegionGraph from 'graphs/LocationStateAndRegionGraph/LocationStateAndRegionGraph'

import './TwitterContentBodyTerm.css'

const TwitterContentBodyTerm = ({
	cards,
	filters,
	networkTwitterAccounts,
	networkTwitterSentiments,
	networkTwitterHashtags,
	summaryTwitter,
	locationTwitterVolume,
	locationTwitterSentiments,
	locationTwitterGender
}) => {
	return (
		<div className='twitter-body-container'>
			{summaryTwitter && (
				<Grid container className='break-print'>
					<Summary cards={cards} isReport={true} filtersReport={{ ...filters, source: 'twitter' }} />
				</Grid>
			)}

			{cards?.map((card) => (
				<Fragment key={card.id}>
					{networkTwitterAccounts && (
						<Grid container className='break-print'>
							<Grid item xs={12}>
								<NetworkSummary
									cardsIds={card.id}
									filters={{ ...filters, source: 'twitter' }}
									isReport={true}
									initialView={'accounts'}
								/>
							</Grid>
						</Grid>
					)}
					{networkTwitterSentiments && (
						<Grid container className='break-print'>
							<Grid item xs={12}>
								<NetworkSummary
									cardsIds={card.id}
									filters={{ ...filters, source: 'twitter' }}
									isReport={true}
									hasTitle={false}
									initialView={'sentiments'}
								/>
							</Grid>
						</Grid>
					)}
					{networkTwitterHashtags && (
						<Grid container className='break-print'>
							<Grid item xs={12}>
								<NetworkSummary
									cardsIds={card.id}
									filters={{ ...filters, source: 'twitter' }}
									isReport={true}
									hasTitle={false}
									initialView={'hashtags'}
								/>
							</Grid>
						</Grid>
					)}
				</Fragment>
			))}
			{locationTwitterVolume && (
				<Grid container className='break-print'>
					<Grid item xs={12}>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'twitter' }}
							initialLocationView='volume'
							isReport={true}
							hasReportTitle={true}
						/>
					</Grid>
				</Grid>
			)}
			{locationTwitterGender && (
				<Grid container className='break-print'>
					<Grid item xl='12'>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'twitter' }}
							initialLocationView='gender'
							isReport={true}
						/>
					</Grid>
				</Grid>
			)}
			{locationTwitterSentiments && (
				<Grid container className='break-print'>
					<Grid item xl='12'>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'twitter' }}
							initialLocationView='impact'
							isReport={true}
						/>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

export default memo(TwitterContentBodyTerm)
