import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import ImageAnnotationCreateLogo from './ImageAnnotationCreateLogo'

const ImageAnnotationListLogos = () => {
	const [logos, setLogos] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [isCreatingLogo, setIsCreatingLogo] = useState(false)

	const fetchLogos = async () => {
		try {
			const response = await api.get('/image-annotation/logos')
			if (Array.isArray(response.data.data)) {
				setLogos(response.data.data)
			} else {
				throw new Error('Unexpected response format')
			}
		} catch (err) {
			console.error('Error fetching logos:', err)
			setError('Error fetching logos')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchLogos()
	}, [])

	const onCloseCreating = async () => {
		setIsCreatingLogo(false)
		await fetchLogos()
	}

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		return <div>{error}</div>
	}

	return (
		<Box style={{ width: '90%', maxWidth: 800, margin: '0 auto', padding: '16px', backgroundColor: '#f7f7f7' }}>
			<Typography variant='h4' gutterBottom>
				Lista de Logos
			</Typography>
			<Button variant='contained' onClick={() => setIsCreatingLogo(true)}>
				Criar novo logo
			</Button>
			<List>
				{logos.map((logo) => (
					<ListItem key={logo.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
						<ListItemIcon>
							<Avatar
								src={logo.imagePath}
								sx={{ width: 56, height: 56, objectFit: 'contain', backgroundColor: 'white' }}
								variant='square'
							/>
						</ListItemIcon>
						<ListItemText primary={logo.name} secondary={`${logo.cleanName}`} />
						<Typography style={{ marginLeft: '16px', color: logo.projectName[0] === 'Logo ainda sem projetos' ? 'green' : 'black' }}>
							{logo.projectName.join(', ')}
						</Typography>
					</ListItem>
				))}
			</List>
			{isCreatingLogo && <ImageAnnotationCreateLogo isOpen={isCreatingLogo} handleOnClose={onCloseCreating} />}
		</Box>
	)
}

export default ImageAnnotationListLogos
