import React from 'react'
import { Card, Grid } from '@mui/material'

const MetaAdsInfoContent = ({ post, isCard }) => {
	return (
		<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ height: '100%', padding: isCard ? '1em' : '0em' }} spacing={1}>
			<Grid
				item
				xs={12}
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					fontFamily: 'Poppins',
					color: '#878787',
					alignItems: 'flex-start'
				}}
			>
				<div>Nome do Ad:</div>
				<div style={{ marginLeft: '8px', color: '#1d8cf8' }}>{post?.ad_name || ''}</div>
			</Grid>
			<Grid
				item
				xs={12}
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					fontFamily: 'Poppins',
					color: '#878787',
					alignItems: 'flex-start'
				}}
			>
				<div>Nome da campanha:</div>
				<div style={{ marginLeft: '8px', color: '#1d8cf8' }}>{post?.campaign_name || ''}</div>
			</Grid>
			<Grid
				item
				xs={12}
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					fontFamily: 'Poppins',
					color: '#878787',
					alignItems: 'flex-start'
				}}
			>
				<div>Objetivo do Anúncio:</div>
				<div style={{ marginLeft: '8px', color: '#1d8cf8' }}>{post?.objective || ''}</div>
			</Grid>
		</Grid>
	)
}

const MetaAdsInfo = ({ post, isCard = true }) => {
	return (
		<>
			{isCard ? (
				<Card
					style={{
						borderRadius: '20px',
						width: '100%',
						height: '100%'
					}}
				>
					<MetaAdsInfoContent post={post} isCard={isCard} />
				</Card>
			) : (
				<MetaAdsInfoContent post={post} isCard={isCard} />
			)}
		</>
	)
}
export default MetaAdsInfo
