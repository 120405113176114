import React, { memo } from 'react'
import { Grid, Card, IconButton } from '@mui/material'
import { CloseIcon, EmojiIcon, BarsIcon } from 'utils/SystemIcons'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import CommentsSentimentGraph from './components/CommentsSentimentGraph/CommentsSentimentGraph'
import '../../Widgets.css'

const CommentsSentiment = ({ sentiment, title, addWidgetMode, editMode, removeWidget, keyName }) => {
	return (
		<>
			{addWidgetMode && (
				<div style={{ position: 'absolute', right: '1px' }}>
					<IconButton onClick={() => removeWidget(keyName)}>
						<CloseIcon color={'#1d8cf8'} />
					</IconButton>
				</div>
			)}

			<Card
				style={{
					borderRadius: '20px',
					width: '100%',
					height: '100%',
					pointerEvents: editMode ? 'none' : '',
					userSelect: editMode ? 'none' : ''
				}}
				className={editMode ? 'editBox' : ''}
			>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle id='span-gender-age' title={title} icon={<EmojiIcon size={25} style={{ color: '#1d8cf8' }} />} />
					</Grid>
				</Grid>
				{!editMode && !addWidgetMode && (
					<Grid item xs={12} style={{ height: '100%' }}>
						<CommentsSentimentGraph data={sentiment} />
					</Grid>
				)}
				{(editMode || addWidgetMode) && (
					<Grid item xs={12} style={{ height: '100%', padding: '5em' }}>
						<div
							style={{
								height: '85%',
								backgroundColor: '#ededed',
								borderRadius: '25px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<BarsIcon size={100} style={{ color: '#1d8cf8' }} />
						</div>
					</Grid>
				)}
			</Card>
		</>
	)
}

export default memo(CommentsSentiment)
