import React from 'react'
import moment from 'moment'
import constantsVox from 'constants-vox'

import './HeaderReport.css'

const HeaderReport = ({ viewType = 'vertical', cardType, title, subTitle, image }) => {
	return (
		<>
			{viewType == 'vertical' && (
				<div className='container-header'>
					<h1 className='title-report'>{title !== '' ? title : 'Relatório Vox Radar'}</h1>
					{constantsVox.CARD_TYPE.TERM === cardType && (
						<h3 className='info-report'>{subTitle !== '' ? subTitle : 'Análise de desempenho de temas em redes sociais'}</h3>
					)}
					{constantsVox.CARD_TYPE.ACCOUNT === cardType && (
						<h3 className='info-report'>{subTitle !== '' ? subTitle : 'Análise de desempenho de contas em redes sociais'}</h3>
					)}
					<div className='info-header-report'>Relatório gerado em {moment().format('DD/MM/YYYY')}.</div>
					<div>
						<img src={image} style={{ height: '8em', width: '8em' }} alt='logo' />
					</div>
				</div>
			)}
			{viewType == 'horizontal' && (
				<div className='container-header-horizontal'>
					<h1 className='title-report'>{title !== '' ? title : 'Relatório Vox Radar'}</h1>
					{constantsVox.CARD_TYPE.TERM === cardType && (
						<h3 className='info-report'>{subTitle !== '' ? subTitle : 'Análise de desempenho de temas em redes sociais'}</h3>
					)}
					{constantsVox.CARD_TYPE.ACCOUNT === cardType && (
						<h3 className='info-report'>{subTitle !== '' ? subTitle : 'Análise de desempenho de contas em redes sociais'}</h3>
					)}
					<div className='info-header-report'>Relatório gerado em {moment().format('DD/MM/YYYY')}.</div>
					<div>
						<img src={image} style={{ height: '8em', width: '8em' }} alt='logo' />
					</div>
				</div>
			)}
		</>
	)
}

export default HeaderReport
