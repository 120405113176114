export { validateStopAccounts, validateHatersAndLovers, validateCardQuery, verifyCardQueryError }

const verifyCardQueryError = (cardQuery) => {
	if (cardQuery.length === 0) {
		return 'Adicione pelo menos uma coleta de palavras-chave.'
	} else {
		let errorTitle = 0
		let errorTermsAnd = 0
		let errorTermsOr = 0
		let errorGeneral = 0
		let queryIndex
		cardQuery.map((query, index) => {
			if (!query?.title || query?.title?.trim() === '') {
				errorTitle++
			}
			if (query?.query?.termsAnd?.length === 0) {
				errorTermsAnd++
			}
			if (query?.query?.termsOr?.length === 1) {
				errorTermsOr++
				queryIndex = index
			}
			if (query?.query?.termsAnd?.length + query?.query?.termsOr?.length + query?.query?.termsNot?.length > 30) {
				errorGeneral++
			}
		})
		if (errorTitle !== 0) {
			return 'Verifique se foi inserido um título para a coleta.'
		}
		if (errorTermsAnd !== 0) {
			return 'Verifique se os campos obrigatórios estão preenchidos.'
		}
		if (errorTermsOr !== 0) {
			return `Essa coleta é equivalente a adicionar tudo nas palavras Simultâneas. 
			Se é isso que deseja, adicione (${cardQuery[queryIndex]?.query?.termsOr[0]}) dentro das palavras Simultâneas, caso contrário, adicione mais palavras nesse campo.`
		}
		if (errorGeneral !== 0) {
			return 'Verifique que o número total de palavras-chave não exceda 30. Caso contrário, divida a coleta em duas ou mais.'
		}

		return false
	}
}

const defineLength = (key, queryAnd, queryOr, queryNot) => {
	switch (key) {
		case 'termsAnd':
			return queryAnd.length
		case 'termsOr':
			return queryOr.length
		case 'termsNot':
			return queryNot.length
	}
}

const validateCardQuery = (value, key, id, query, messageToast, onChangeQuery, event) => {
	if (event.keyCode) {
		const error = verifyStartsAndEndsWithSymbols(value, true)
		if (error) {
			return messageToast(error)
		}
		value = value.flatMap((item) => item.split(';'))
		value = value.filter((item) => !['@', '#', ';', ''].includes(item))
		const queryAndLowerCase = query?.termsAnd?.map((item) => item?.toLowerCase())
		const queryOrLowerCase = query?.termsOr?.map((item) => item?.toLowerCase())
		const queryNotLowerCase = query?.termsNot?.map((item) => item?.toLowerCase())
		const valueLowerCase = value?.map((item) => item?.toLowerCase())
		const uniqueValueLowerCase = valueLowerCase.slice(defineLength(key, queryAndLowerCase, queryOrLowerCase, queryNotLowerCase))
		const duplicatesOnAddNew = uniqueValueLowerCase.filter((str, index, arr) => arr.indexOf(str) !== index)
		const allLowerCaseSet = new Set([...queryAndLowerCase, ...queryOrLowerCase, ...queryNotLowerCase])
		const duplicates = uniqueValueLowerCase.filter((item) => allLowerCaseSet.has(item))
		if (duplicates.length > 0 || duplicatesOnAddNew.length > 0) {
			const uniqueDuplicates = [...new Set([...duplicates, ...duplicatesOnAddNew])]
			const duplicatedTerms = uniqueDuplicates.join(', ')
			messageToast('Os seguintes termos já foram adicionados: ' + duplicatedTerms)
		} else {
			onChangeQuery(value, key, id, '')
		}
	} else {
		onChangeQuery(value, key, id, '')
	}
}

//Funções Publicas
const validateStopAccounts = (valueArray, source, type, formState, messageToast, onChangeForm, event) => {
	if (event.keyCode) {
		const error = verifyStartsAndEndsWithSymbols(valueArray)
		if (error) {
			return messageToast(error)
		}
		valueArray = valueArray.flatMap((item) => item.split(';'))
		valueArray = valueArray.filter((item) => !['@', ';', ''].includes(item))
		if (source !== 'news') {
			valueArray = valueArray.map((acc) => (acc.startsWith('@') ? acc : '@' + acc))
		}

		const duplicates = [...new Set(valueArray.filter((str, index, arr) => arr.indexOf(str) !== index))]
		if (duplicates.length > 0) {
			const duplicatedAccounts = duplicates.join(', ')
			messageToast('As seguintes contas já foram adicionadas: ' + duplicatedAccounts)
		} else {
			validateBeforeCreatingAccountTag(valueArray, source, type, formState, onChangeForm)
		}
	} else {
		validateBeforeCreatingAccountTag(valueArray, source, type, formState, onChangeForm)
	}
}

const validateHatersAndLovers = (valueArray, source, type, formState, messageToast, onChangeForm, event) => {
	if (event.keyCode) {
		const error = verifyStartsAndEndsWithSymbols(valueArray)
		if (error) {
			return messageToast(error)
		}
		//adiciona em lote: cria valores digitados separados por ;
		valueArray = valueArray.flatMap((item) => item.split(';'))
		//remove os valores que são só @ ou ; (no adicionar em lote remove e adiciona o resto, se for uma palavra sozinha vai no if error ali em cima e dá a mensagem)
		valueArray = valueArray.filter((item) => !['@', ';', ''].includes(item))
		//adiciona @ nas palavras que não tem
		valueArray = valueArray.map((acc) => (acc.startsWith('@') ? acc : '@' + acc))
		//passa lovers e haters do form e os valores atuais do autocomplete atual para lower case
		const formLoversLowerCase = formState?.manualClassificationAccount[source].lovers.map((item) => item?.toLowerCase())
		const formHatersLowerCase = formState?.manualClassificationAccount[source].haters.map((item) => item?.toLowerCase())
		const valueLowerCase = valueArray.map((item) => item?.toLowerCase())
		const uniqueValueLowerCase = valueLowerCase.slice(type === 'haters' ? formHatersLowerCase.length : formLoversLowerCase.length)
		//verifica se tem valor duplicado na própria digitação
		const duplicatesOnAddNew = uniqueValueLowerCase.filter((str, index, arr) => arr.indexOf(str) !== index)
		//verifica se tem valor duplicado no haters e lovers
		const allLowerCaseSet = new Set([...formLoversLowerCase, ...formHatersLowerCase])
		//procura palavras duplicadas no lovers e haters
		const duplicates = uniqueValueLowerCase.filter((item) => allLowerCaseSet.has(item))
		if (duplicates.length > 0 || duplicatesOnAddNew.length > 0) {
			const uniqueDuplicates = [...new Set([...duplicates, ...duplicatesOnAddNew])]
			const duplicatedAccounts = uniqueDuplicates.join(', ')
			messageToast('As seguintes contas já foram adicionadas: ' + duplicatedAccounts)
		} else {
			validateBeforeCreatingAccountTag(valueArray, source, type, formState, onChangeForm)
		}
	} else {
		validateBeforeCreatingAccountTag(valueArray, source, type, formState, onChangeForm)
	}
}

//Funções privadas
const verifyStartsAndEndsWithSymbols = (valueArray, isQuery = false) => {
	let error = false
	if (valueArray.some((acc) => acc.startsWith(';'))) {
		error = 'O texto não pode iniciar com o caractere ;'
	} else if (valueArray.some((acc) => acc.endsWith(';'))) {
		error = 'O texto não pode terminar com o caractere ;'
	} else if (valueArray.some((acc) => acc.startsWith('@') && acc.length === 1)) {
		error = 'O texto não pode ter somente o caractere @'
	} else if (isQuery) {
		if (valueArray.some((acc) => acc.startsWith('#') && acc.length === 1)) {
			error = 'O texto não pode ter somente o caractere #'
		}
	}

	return error
}

const validateBeforeCreatingAccountTag = (accounts, source, column, formState, onChangeForm) => {
	if (column === 'stop') {
		onChangeForm({
			stopAccounts: {
				...formState.stopAccounts,
				[source]: accounts
			}
		})
	} else {
		onChangeForm({
			manualClassificationAccount: {
				...formState.manualClassificationAccount,
				[source]: { ...formState.manualClassificationAccount[source], [column]: accounts }
			}
		})
	}
}
