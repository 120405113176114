import moment from 'moment-timezone'

const canRenderHourInterval = (fromDate, toDate, interval, intervalSize) => {
	const fromDateFormatted = moment(fromDate, 'YYYY-MM-DD')
	const toDateFormatted = moment(toDate, 'YYYY-MM-DD')
	const diffTime = toDateFormatted.diff(fromDateFormatted, interval)
	return diffTime < intervalSize
}

export { canRenderHourInterval }
