import React, { memo } from 'react'
import { Button, Grid, Collapse, Card, Divider } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import CheckButtonGroup from 'components/CheckButtonGroup/CheckButtonGroup'
const blue = 'rgba(29, 138, 248, 100%)'
const lightGrey = 'rgba(184, 184, 184, 100%)'

const ClassificationFilters = ({ toggleClassification, openClassification, onClassificationTypeChange, newFilters }) => {
	const classificationStates = [
		{ label: 'automaticamente', checked: newFilters.classification.hasAutomaticClassification },
		{ label: 'manualmente', checked: newFilters.classification.hasManualClassification }
	]

	const sentimentStates = [
		{ label: 'positivos', key: 'positive', checked: newFilters.classification.positive, color: blue },
		{ label: 'negativos', key: 'negative', checked: newFilters.classification.negative, color: blue },
		{ label: 'neutros', key: 'neutral', checked: newFilters.classification.neutral, color: blue },
		{
			label: 'irrelevantes',
			key: 'irrelevant',
			checked: newFilters.classification.irrelevant,
			color: blue,
			hide: !newFilters.classification.hasManualClassification
		}
	]

	const onChangeClassification = (list) => {
		let classificationObject = {
			hasAutomaticClassification: newFilters.classification.hasAutomaticClassification,
			hasManualClassification: newFilters.classification.hasManualClassification
		}
		list.forEach((item) => {
			if (item.label === 'automaticamente') {
				if (item.checked !== classificationObject.hasAutomaticClassification) {
					classificationObject.hasAutomaticClassification = item.checked
				}
			}
			if (item.label === 'manualmente') {
				if (item.checked !== classificationObject.hasManualClassification) {
					classificationObject.hasManualClassification = item.checked
				}
			}
		})
		if (
			newFilters.classification.irrelevant &&
			!classificationObject.hasManualClassification &&
			!(newFilters.classification.positive || newFilters.classification.negative || newFilters.classification.neutral)
		) {
			classificationObject.positive = true
			classificationObject.negative = true
			classificationObject.neutral = true
		}

		onClassificationTypeChange({
			...newFilters.classification,
			...classificationObject
		})
	}

	const onChangeSentiment = (list) => {
		let formattedSentiment = {}

		list.forEach((item) => {
			formattedSentiment[item.key] = item.checked
		})

		onClassificationTypeChange({ ...newFilters.classification, ...formattedSentiment })
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Button
					id='btn-toggle-classification'
					onClick={toggleClassification}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openClassification && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openClassification && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Classificação
				</Button>

				<Collapse in={openClassification} style={{ width: '100%' }}>
					<Grid container style={{ marginTop: '5px' }}>
						<Grid item xs={12} container>
							<Grid xs={3} item>
								<div
									style={{
										height: '48px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										fontFamily: 'Poppins',
										fontWeight: 'bold',
										fontSize: '13px',
										color: '#707070'
									}}
								>
									VER CLASSIFICADOS :
								</div>
							</Grid>
							<Grid xs={6} item>
								<CheckButtonGroup
									atLeastOneMarked
									checkedControlled={classificationStates}
									onChange={onChangeClassification}
									fontSize='1.2em'
									uncheckedColor={lightGrey}
								/>
							</Grid>
						</Grid>
						<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />

						<Grid container item style={{ marginTop: '0.6em' }}>
							<CheckButtonGroup
								atLeastOneMarked
								checkedControlled={sentimentStates}
								onChange={onChangeSentiment}
								fontSize='1.2em'
								uncheckedColor={lightGrey}
							/>
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Card>
	)
}

export default memo(ClassificationFilters)
