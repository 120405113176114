import React, { useState, memo } from 'react'
import { Grid, Card, IconButton, Slide, Slider } from '@mui/material'
import { DotsVerticalIcon, ChevronRightIcon } from 'utils/SystemIcons'
import SingleOption from './components/SingleOption/SingleOption'
import MultiOption from './components/MultiOption/MultiOption'

const isFirefox = typeof InstallTrigger !== 'undefined'

const defaultTextStyle = {
	whiteSpace: 'nowrap',
	fontFamily: 'Poppins',
	fontSize: '16px',
	fontWeight: '500',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const SideMenu = ({ options, onChangeOptions, isComp, sources = ['twitter'], cardType = 'term', politicalOptions }) => {
	const [openSideMenu, setOpenSideMenu] = useState(false)

	const onClickSideMenuButton = () => {
		setOpenSideMenu(!openSideMenu)
	}

	const handleCreateSourcesOption = () => {
		return sources.map((source, index) => (
			<SingleOption
				key={`source-option-${index}`}
				title={source === 'news' ? 'notícias' : source}
				checked={!!options[source]}
				isSwitch={!isComp}
				option={source}
				value={!options[source]}
				thisOptions={options}
				onChangeOptions={onChangeOptions}
				isComp={isComp}
				isSubOption={true}
			/>
		))
	}

	const handleCreatePoliticalOption = () => {
		if (politicalOptions) {
			return politicalOptions.map((political, index) => (
				<SingleOption
					key={`political-option-${index}`}
					title={political.name}
					checked={!!options[political.key]}
					isSwitch={!isComp}
					option={political.key}
					value={!options[political.key]}
					thisOptions={options}
					onChangeOptions={onChangeOptions}
					isComp={isComp}
					isSubOption={true}
				/>
			))
		} else {
			return <></>
		}
	}

	const handleChange = (_, value) => {
		onChangeOptions({ ...options, type: !value ? 'posts' : 'engagement', posts: !value && options.amount, engagement: value && options.amount })
	}

	return (
		<>
			<IconButton id='btn-open-menu-nowcasting' color='primary' onClick={onClickSideMenuButton}>
				<DotsVerticalIcon size={25} style={{ color: '#1d8cf8' }} />
			</IconButton>
			<Slide direction='left' in={openSideMenu} mountOnEnter unmountOnExit>
				<Grid style={{ position: 'absolute', zIndex: '100' }}>
					<Card
						style={{
							width: '400px',
							height: '600px',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}}
					>
						<Grid container alignItems={'center'}>
							<Grid item xs={2}>
								<IconButton id='btn-toggle-menu-nowcasting' color='primary' onClick={onClickSideMenuButton}>
									<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
								</IconButton>
							</Grid>
							<Grid item xs={3} justifyContent={'flex-end'}>
								<div style={{ ...defaultTextStyle, color: options.type === 'posts' ? '#1d8cf8' : '#878787' }}>Postagens</div>
							</Grid>
							<Grid item xs={1} style={{ marginRight: '15px' }}>
								<Slider
									value={options.type === 'posts' ? 0 : 100}
									onChange={handleChange}
									step={null}
									marks={[{ value: 0 }, { value: 100 }]}
								/>
							</Grid>
							<Grid item xs={4} justifyContent={'flex-start'}>
								<div style={{ ...defaultTextStyle, color: options.type === 'engagement' ? '#1d8cf8' : '#878787' }}>Engajamento</div>
							</Grid>
						</Grid>

						<div style={{ height: '560px', overflowY: 'auto' }}>
							<Grid container>
								<SingleOption
									title={options.type === 'posts' ? 'Postagens' : 'Engajamento'}
									checked={options.amount}
									isSwitch={!isComp}
									option={'amount'}
									value={!options.amount}
									thisOptions={options}
									onChangeOptions={onChangeOptions}
									isComp={isComp}
								/>
								<SingleOption
									title={'Comentários'}
									checked={options.comments}
									isSwitch={!isComp}
									option={'comments'}
									value={!options.comments}
									thisOptions={options}
									onChangeOptions={onChangeOptions}
									isComp={isComp}
								/>
								{cardType === 'term' && (
									<SingleOption
										title={'Usuários'}
										checked={options.users}
										isSwitch={!isComp}
										option={'users'}
										value={!options.users}
										thisOptions={options}
										onChangeOptions={onChangeOptions}
										isComp={isComp}
									/>
								)}
								{sources.includes('twitter') && cardType === 'term' && (
									<>
										<SingleOption
											title={'Retweets'}
											checked={options.retweets}
											isSwitch={!isComp}
											option={'retweets'}
											value={!options.retweets}
											thisOptions={options}
											onChangeOptions={onChangeOptions}
											isComp={isComp}
										/>
									</>
								)}

								{cardType === 'term' && !(sources.includes('news') && sources.length == 1) && (
									<MultiOption
										mainChecked={options.gender}
										isComp={isComp}
										MainTitle={'Gênero'}
										option={'gender'}
										value={!options.gender}
										thisOptions={options}
										onChangeOptions={onChangeOptions}
										content={
											<Grid container>
												<SingleOption
													title={'masculino'}
													checked={!!options.men}
													isSwitch={!isComp}
													option={'men'}
													value={!options.men}
													thisOptions={options}
													onChangeOptions={onChangeOptions}
													isComp={isComp}
													isSubOption={true}
												/>
												<SingleOption
													title={'feminino'}
													checked={!!options.women}
													isSwitch={!isComp}
													option={'women'}
													value={!options.women}
													thisOptions={options}
													onChangeOptions={onChangeOptions}
													isComp={isComp}
													isSubOption={true}
												/>
												<SingleOption
													title={'organização'}
													checked={!!options.organization}
													isSwitch={!isComp}
													option={'organization'}
													value={!options.organization}
													thisOptions={options}
													onChangeOptions={onChangeOptions}
													isComp={isComp}
													isSubOption={true}
												/>
												<SingleOption
													title={'sem classificação'}
													checked={!!options.noClass}
													isSwitch={!isComp}
													option={'noClass'}
													value={!options.noClass}
													thisOptions={options}
													onChangeOptions={onChangeOptions}
													isComp={isComp}
													isSubOption={true}
												/>
											</Grid>
										}
									/>
								)}
								{cardType === 'term' && (
									<MultiOption
										mainChecked={options.sources}
										isComp={isComp}
										MainTitle={'Redes'}
										option={'sources'}
										value={!options.sources}
										thisOptions={options}
										onChangeOptions={onChangeOptions}
										content={<Grid container>{handleCreateSourcesOption()}</Grid>}
										sources={sources}
									/>
								)}
								{sources.includes('twitter') && cardType === 'term' && (
									<MultiOption
										mainChecked={options.politics}
										isComp={isComp}
										MainTitle='Política'
										option='politics'
										value={!options.politics}
										thisOptions={options}
										onChangeOptions={onChangeOptions}
										content={<Grid container>{handleCreatePoliticalOption()}</Grid>}
									/>
								)}
							</Grid>
						</div>
					</Card>
				</Grid>
			</Slide>
		</>
	)
}

export default memo(SideMenu)
