import React from 'react'
import { FiActivity, FiMapPin, FiList } from 'react-icons/fi'
import { BiNetworkChart } from 'react-icons/bi'
import { OverviewIcon, AllSourcesIcon } from 'utils/SystemIcons'

const sentimentIcon = <FiActivity className='icon-list-items-tab' size='20' />
const networkIcon = <BiNetworkChart className='icon-list-items-tab' size='20' />
const locationIcon = <FiMapPin className='icon-list-items-tab' size='20' />
const overviewIcon = <OverviewIcon className='icon-list-items-tab' size='20' />
const voxIAIcon = <AllSourcesIcon className='icon-list-items-tab' size='20' />
const occurrencesIcon = <FiList className='icon-list-items-tab' size='20' />

const menuDefaultNew = (
	twitterStatus = 'P',
	newsStatus = 'P',
	facebookStatus = 'P',
	instagramStatus = 'P',
	tiktokStatus = 'P',
	youtubeStatus = 'P'
) => {
	const getStatus = (sources) => {
		let hasError = sources.every((s) => s === 'W')
		if (hasError) {
			return {
				name: 'error',
				label: 'Ocorreu algum erro ao buscar dados.'
			}
		}
		let hasWarning = sources.every((s) => s === 'E')
		if (hasWarning) {
			return {
				name: 'warning',
				label: 'Nenhum dado encontrado para esta busca.'
			}
		}
		let isLoading = sources.every((s) => s === 'P')
		if (isLoading) {
			return { name: 'loading', label: 'Processando dados.' }
		} else {
			return { name: 'success', label: '' }
		}
	}

	return [
		{
			label: 'Overview',
			tab: 'analysis',
			status: getStatus([twitterStatus, newsStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: sentimentIcon
		},
		{
			// twitter || facebook || instagram || news
			label: 'Análise com IA',
			tab: 'topics',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, newsStatus, tiktokStatus, youtubeStatus]),
			icon: voxIAIcon
		},
		{
			label: 'Resumo das redes',
			tab: 'overview',
			status: getStatus([twitterStatus, newsStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: overviewIcon
		},
		{
			label: 'Postagens',
			tab: 'occurrences',
			status: getStatus([twitterStatus, newsStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: occurrencesIcon
		},
		{
			// twitter || news
			label: 'Influenciadores',
			tab: 'network',
			status: getStatus([twitterStatus, newsStatus]),
			icon: networkIcon
		},
		{
			// twitter || facebook || instagram
			label: 'Localidade',
			tab: 'location',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus]),
			icon: locationIcon
		}
		// {
		// 	// twitter || facebook || instagram || news
		// 	label: 'Exportar',
		// 	tab: 'export',
		// 	status: getStatus([twitterStatus, facebookStatus, instagramStatus, newsStatus]),
		// 	icon: buildDefaultIcon(ExportIcon)
		// },
		// {
		// 	label: 'Relatório',
		// 	tab: 'report',
		// 	status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus, youtubeStatus, newsStatus]),
		// 	icon: buildDefaultIcon(ReportIcon)
		// }
	]
}

export { menuDefaultNew }
