import React from 'react'
import { getEngagementFormattedInstagram, getVideoMetricsFormattedInstagram } from '../../utils/viewPostMetrics.utils'
import { Grid } from '@mui/material'
import BarMetrics from 'pages/AccountManagement/components/BarMetrics/BarMetrics'
import NumericMetric from 'pages/AccountManagement/components/NumericMetric/NumericMetric'

const InstagramPostMetrics = ({ cardData, isSinglePost = true }) => {
	let isVideo = isSinglePost ? cardData?.post?.media_product_type === 'REELS' : true
	const engagement = getEngagementFormattedInstagram(cardData)
	const videoMetrics = getVideoMetricsFormattedInstagram(cardData)
	const descriptions = cardData.descriptions

	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions}
						title={descriptions.find((item) => item.key === 'instagram_impressions')?.title}
						description={descriptions.find((item) => item.key === 'instagram_impressions')?.description}
						source='instagram'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.reach}
						title={descriptions.find((item) => item.key === 'instagram_reach')?.title}
						description={descriptions.find((item) => item.key === 'instagram_reach')?.description}
						source='instagram'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.total_interactions_count}
						title={descriptions.find((item) => item.key === 'instagram_total_interactions')?.title}
						description={descriptions.find((item) => item.key === 'instagram_total_interactions')?.description}
						source='instagram'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={isVideo ? 6 : 12} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={engagement.values}
						categories={engagement.labels}
						title='Reações'
						description='Número de reações da mídia por tipo'
						label='Quantidade'
					/>
				</Grid>
				{isVideo && (
					<Grid item xs={6} style={{ height: '20em' }}>
						<BarMetrics
							style={{ paddingBottom: '2em' }}
							isHorizontal={false}
							values={videoMetrics.values}
							categories={videoMetrics.labels}
							title='Métricas de vídeo'
							description='Métricas de vídeo por tipo'
							label='Quantidade'
						/>
					</Grid>
				)}
			</Grid>
		</>
	)
}

export default InstagramPostMetrics
