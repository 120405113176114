import React, { memo } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DownloadIcon } from 'utils/SystemIcons'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	buttonNoborder: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 0%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 10%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	}
})

const DownloadXlsButton = ({ border = true }) => {
	const classes = useStyles()

	return (
		<Button
			disableElevation
			variant={'contained'}
			// onClick={}
			style={{ outline: 'none' }}
			className={border ? classes.button : classes.buttonNoborder}
		>
			<DownloadIcon size={16} style={{ color: blue, marginRight: '5px' }} />
			Baixar XLSX
		</Button>
	)
}

export default memo(DownloadXlsButton)
