import React, { useState, useEffect, memo } from 'react'
import Chart from 'react-apexcharts'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import { graphOptionsFinancial } from 'graphs/utils/GraphOptionsFinancial'
import { Grid } from '@mui/material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import DownloadXlsButton from 'components/DownloadXlsButton/DownloadXlsButton'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import moment from 'moment-timezone'

const emptyProp = {
	options: {
		chart: {
			type: 'line'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const defaultInitialDate = moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD')

const MetricGraph = ({
	onPartialLoading,
	metric = 'positiveCorrelatedIndex',
	variable = 'inflation',
	country = 'BR',
	inicialDate = defaultInitialDate,
	timeInterval = '3m',
	fullScreenState,
	isReport = false,
	isFetchingData = true,
	colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
}) => {
	const [loading, setLoading] = useState(true)
	const [windowSize, setWindowSize] = useState(window.innerHeight)

	const getUrlRequest = () => {
		return `/financial/get-economic-metric/${variable}/${metric}/${country}/${inicialDate}/${timeInterval}`
	}

	let { data, error, isFetching, isFetched } = useUpdateData({
		url: getUrlRequest(),
		updateItemName: 'Financial',
		refetchInterval: 60000000,
		freshDataTime: 60000000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData'
	})

	useEffect(() => {
		if (isFetched) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	useEffect(() => {
		if (!loading && onPartialLoading) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, isFetched])

	useEffect(() => {
		if (fullScreenState) {
			setWindowSize(435)
		} else {
			setWindowSize(window.innerHeight / 1.2 - 120)
		}
		if (isReport) {
			setWindowSize(350)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullScreenState])

	if (verifyServerResponseCanShowToast(error) && !isReport) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	return (
		<>
			{loading ? (
				<GraphSkeletonLoad />
			) : (
				<>
					<Grid container>
						<Grid item xs={12}>
							{/* options */}
						</Grid>
					</Grid>
					<Grid style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
						<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>
							<Chart
								options={
									data?.formatted?.options
										? graphOptionsFinancial(isReport, data?.formatted?.options?.title, colors, 'smooth', null, 'day')
										: emptyProp.options
								}
								series={data?.formatted?.series ? data?.formatted?.series : emptyProp.series}
								type={'line'}
								width={'100%'}
								height={windowSize / 1.2}
							/>
						</div>
						<Grid container direction='row' justifyContent='space-between' alignItems='center'>
							<div style={{ minHeight: '64.34px' }}>
								<DownloadXlsButton />
							</div>
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}

export default memo(MetricGraph)
