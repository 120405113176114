import React, { memo } from 'react'
import NetworkSummarySkeletonLoad from 'components/SkeletonLoads/NetworkSummarySkeletonLoad/NetworkSummarySkeletonLoad'
import { Grid } from '@mui/material'
import { RenderAccounts, RenderHashtags, RenderPositive, RenderNegative } from './modules/NetworkSummaryModule/NetworkSummaryModule'

const NetworkSummaryContent = ({ data, loading, isGraphComponent, isReport, order }) => {
	return (
		<div style={!isGraphComponent && isReport ? { backgroundColor: 'white', borderRadius: '4px' } : { borderRadius: '4px' }}>
			<div style={{ textAlign: 'center' }}>
				{loading ? (
					<NetworkSummarySkeletonLoad />
				) : (
					<Grid container>
						{data.accounts && (
							<>
								<br />
								<RenderAccounts formattedData={data} isReport={isReport} order={order} />
							</>
						)}
						{data.positive && (
							<>
								<br />
								<RenderPositive formattedData={data} isReport={isReport} order={order} />
							</>
						)}
						{data.negative && (
							<>
								<br />
								<RenderNegative formattedData={data} isReport={isReport} order={order} />
							</>
						)}
						{data.hashtags && (
							<>
								<br />
								<RenderHashtags formattedData={data} isReport={isReport} order={order} />
							</>
						)}
						{!data.accounts && !data.positive && !data.negative && !data.hashtags && (
							<div
								style={{
									marginTop: '1em',
									width: '100%',
									height: '200px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontFamily: 'Poppins',
									fontSize: '24px',
									color: '#d6d6d6'
								}}
							>
								Sem dados para opção selecionada
							</div>
						)}
					</Grid>
				)}
			</div>
		</div>
	)
}

export default memo(NetworkSummaryContent)
