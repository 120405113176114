import React, { useState, useEffect } from 'react'
import { Card, CardContent, Button, Collapse, IconButton } from '@mui/material'
import InstagramIconImage from 'assets/img/instagram-icon.png'
import { ExternalLinkIcon, ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'

const ProfileInstagramHeader = ({ analytics }) => {
	const [imageUrl, setImageUrl] = useState(null)
	const [seeMore, setSeeMore] = useState(false)

	const handleErrorImg = () => {
		setImageUrl(InstagramIconImage)
	}

	useEffect(() => {
		setImageUrl(analytics?.profile_picture_url)
	}, [analytics])

	return (
		<Card style={{ borderRadius: '20px' }}>
			<CardContent>
				<div style={{ borderRadius: '20px ', margin: '10px' }}>
					<div
						style={{
							display: 'flex',
							borderRadius: '100px',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						<img
							style={{ width: '4em', height: '4em', borderRadius: '100px', border: 'solid 2px #1d8cf8', backgroundColor: '#1d8cf8' }}
							src={imageUrl}
							onError={handleErrorImg}
						/>
						<div style={{ marginLeft: '20px' }}>
							<div style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', color: '#1d8cf8' }}>{analytics?.name}</div>
							{analytics?.username && (
								<div style={{ marginTop: '1px', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold', color: '#1d8cf8' }}>
									@{analytics?.username}
								</div>
							)}
							{analytics?.biography && (
								<div style={{ marginTop: '15px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#1d8cf8' }}>
									{analytics?.biography}
								</div>
							)}

							<IconButton size='small' style={{ fontSize: '12px', borderRadius: '100px' }} onClick={() => setSeeMore(!seeMore)}>
								{!seeMore && <ChevronDownIcon size={20} />}
								{seeMore && <ChevronUpIcon size={20} />}
							</IconButton>

							<Collapse in={seeMore}>
								{analytics?.website && (
									<Button
										variant='outlined'
										onClick={() => window.open(analytics?.website, '_blank')}
										style={{
											marginTop: '15px',
											fontFamily: 'Poppins',
											fontSize: '12px',
											fontWeight: 'bold',
											color: '#1d8cf8',
											borderRadius: '100px',
											marginRight: '15px'
										}}
									>
										Abrir Site
										<ExternalLinkIcon color={'#1d8cf8'} size={17} style={{ marginLeft: '8px' }} />
									</Button>
								)}
							</Collapse>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export default ProfileInstagramHeader
