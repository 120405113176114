import React, { memo } from 'react'
import { Tooltip, Grid, Button } from '@mui/material'
import { FolderIcon, DotsVerticalIcon, AllCardsIcon, ComparativeCardIcon, DemoIcon } from 'utils/SystemIcons'
import './FolderItem.css'

const selectedStyle = {
	height: '3em',
	width: '100%',
	borderRadius: '20px',
	border: '1px solid rgba(29, 138, 248, 0.5)'
}

const defaultStyle = {
	height: '3em',
	width: '100%',
	borderRadius: '20px',
	border: '1px solid rgba(0, 0, 0, 0.0)'
}

const FolderItem = ({
	id,
	name,
	openFolder,
	toggleIsEditingFolder,
	isEditable = true,
	isSelected = false,
	isComp = false,
	isAll = false,
	isDemo = false
}) => {
	return (
		<Grid container alignContent='center' style={{ marginTop: '8px', marginBottom: '8px' }}>
			<Grid item xs style={{ overflow: 'hidden' }}>
				<Button color='primary' onClick={() => openFolder(id, name)} style={isSelected ? selectedStyle : defaultStyle}>
					<Tooltip title={name} placement='right' enterDelay={500} enterNextDelay={500}>
						<Grid container justifyContent='flex-start' alignContent='center' style={{ marginTop: '0.5em' }}>
							<div
								style={{
									color: isSelected ? '#3d8cf8' : 'rgba(0, 0, 0, 0.9)',
									display: 'flex'
								}}
							>
								<div>
									{!(isComp || isAll || isDemo) && (
										<FolderIcon size={19} style={{ marginRight: '8px', color: isSelected ? '#3d8cf8' : 'rgba(0, 0, 0, 0.2)' }} />
									)}
									{isAll && (
										<AllCardsIcon
											size={18}
											style={{ marginRight: '8px', color: isSelected ? '#3d8cf8' : 'rgba(0, 0, 0, 0.2)' }}
										/>
									)}
									{isComp && (
										<ComparativeCardIcon
											size={19}
											style={{ marginRight: '8px', color: isSelected ? '#3d8cf8' : 'rgba(0, 0, 0, 0.2)' }}
										/>
									)}
									{isDemo && (
										<DemoIcon size={19} style={{ marginRight: '8px', color: isSelected ? '#3d8cf8' : 'rgba(0, 0, 0, 0.2)' }} />
									)}
								</div>
								<div
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontFamily: 'Poppins',
										fontSize: '12px'
									}}
								>
									{name}
								</div>
							</div>
						</Grid>
					</Tooltip>
					{isEditable && (
						<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 10, top: 8 }}>
							<Tooltip title='Opções' placement='right' enterDelay={500} enterNextDelay={500}>
								<div className='folder-item-pseudo-icon-button' onClick={() => toggleIsEditingFolder(id, name)}>
									<DotsVerticalIcon size={15} style={{ color: '#3d8cf8' }} />
								</div>
							</Tooltip>
						</Grid>
					)}
				</Button>
			</Grid>
		</Grid>
	)
}

export default memo(FolderItem)
