import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from '@mui/material'
import { LoadingIcon, ErrorIcon, AlertIcon } from 'utils/SystemIcons'
import TooltipCustom from 'components/TooltipCustom/TooltipCustom'
import './ListItemsTab.css'

const loadingIcon = <LoadingIcon color='#1d8cf8' size={20} thickness={2} />
const errorIcon = <ErrorIcon size={20} color='#ff0000' />
const alertIcon = <AlertIcon size={20} color='#d4d400' />

const getIcon = (item) => {
	if (item?.status.name === 'loading') {
		return loadingIcon
	} else if (item?.status.name === 'error') {
		return errorIcon
	} else if (item?.status.name === 'warning') {
		return alertIcon
	} else {
		return item?.icon
	}
}

let isFirstRender = true

const ListItemsTab = ({ itemsToRender, onSelectItem, selectedValue }) => {
	const [value, setValue] = useState(false)

	useEffect(() => {
		if (selectedValue) {
			setValue(itemsToRender?.find((itr) => itr?.status.name === 'success' && itr.tab === selectedValue)?.tab || false)
		} else {
			setValue(itemsToRender?.find((itr) => itr?.status.name === 'success')?.tab || false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsToRender])

	useEffect(() => {
		if (onSelectItem && value !== false) {
			onSelectItem(isFirstRender ? selectedValue : value)
			isFirstRender = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const handleClick = (selected) => {
		if (selected.status.name !== 'success') {
			return
		}

		if (selected.onlyAction) {
			selected.onlyAction()
		} else {
			setValue(selected.tab)
		}
	}

	return (
		<div className='tab-container-list-items-tab'>
			<Tabs orientation='vertical' variant='scrollable' scrollButtons={false} value={value}>
				{itemsToRender?.map((item) => (
					<Tab
						id={`btn-menu-${item.tab}`}
						key={item.label}
						value={item.tab}
						label={item.label}
						className='tab-item-list-items-tab'
						onClick={() => handleClick(item)}
						icon={
							<TooltipCustom text={item.status.label} placement='bottom-end' color='#9A9A9A'>
								<div className='icon-container-list-items-tab'>{getIcon(item)}</div>
							</TooltipCustom>
						}
					/>
				))}
			</Tabs>
		</div>
	)
}

export default ListItemsTab
