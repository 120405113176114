import React, { useEffect, memo, useState } from 'react'
import { Grid, TextField, Button, Collapse, Card, styled } from '@mui/material'
import { TwitterIcon } from 'utils/SystemIcons'
import { Tweet } from 'react-twitter-widgets'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const getTweetId = (str) => {
	let result = str.match(/\d+/g)

	return (result && result.filter((e) => e.length > 10)[0]) || ''
}

const TweetNewInsightReport = ({ formState, fieldErrors, onChangeForm, statusType, card }) => {
	const [tweetAdded, setTweetAdded] = useState(false)

	useEffect(() => {
		setTweetAdded(false)
	}, [])

	useEffect(() => {
		if (statusType === 'edit') {
			setTweetAdded(false)
			onChangeForm({ tweetId: card.tweetID, tweetUrl: card.tweetID })
		}
	}, [])

	const handleAddTweet = () => {
		setTweetAdded(!tweetAdded)
		onChangeForm({ tweetId: getTweetId(formState?.tweetUrl) })
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<span className='fs-1-5' style={{ color: '#18c1c4' }}>
					Analisar a repercussão do tweet :
				</span>
			</Grid>
			<Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }}>
				<Collapse in={!tweetAdded}>
					<CssTextField
						variant='standard'
						label={
							<div style={{ display: 'flex' }}>
								<TwitterIcon size={20} style={{ color: '#18c1c4', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
								ID ou URL do Tweet
							</div>
						}
						error={!!fieldErrors?.tweetId?.error}
						helperText={fieldErrors?.tweetId?.error}
						size='small'
						className='d-flex'
						style={{ color: '#18c1c4', borderRadius: '4px' }}
						value={formState.tweetUrl}
						onChange={(e) => onChangeForm({ tweetUrl: e.target.value })}
					/>
				</Collapse>
				<Collapse in={tweetAdded}>
					{tweetAdded && (
						<>
							<Grid container justifyContent='center' alignItems='center'>
								<a
									href={`https://twitter.com/anyuser/status/${formState?.tweetId}`}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: 'none', outlineColor: 'none' }}
								>
									<Button style={{ borderRadius: '100px', marginBottom: '8px', color: '#18c1c4' }}>Abrir original</Button>
								</a>
							</Grid>
							<Grid container justifyContent='center' alignItems='center'>
								<Card
									style={{
										width: '50%',
										overflow: 'auto',
										height: '300px',
										borderRadius: '20px',
										backdropFilter: 'blur(10px)',
										backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
										border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
										boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
										padding: '20px'
									}}
								>
									{formState?.tweetId !== '' && (
										<div>
											<Tweet tweetId={getTweetId(formState?.tweetId)} />
										</div>
									)}
									{formState?.tweetId === '' && (
										<div style={{ fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
											VERIFIQUE QUE O TEXTO ESTEJA CORRETO
										</div>
									)}
								</Card>
							</Grid>
						</>
					)}
				</Collapse>
			</Grid>
			<Grid item xs={12} container justifyContent='center'>
				<Collapse in={formState.tweetUrl && formState.tweetUrl.length > 0}>
					<Button
						onClick={handleAddTweet}
						variant='outlined'
						style={{ minWidth: '200px', borderRadius: '100px', color: '#18c1c4', border: 'solid 1px #18c1c4', fontFamily: 'Poppins' }}
					>
						{!tweetAdded && statusType !== 'edit' && 'Adicionar'}
						{!tweetAdded && statusType === 'edit' && 'Verificar'}
						{tweetAdded && 'Remover'}
					</Button>
				</Collapse>
			</Grid>
		</Grid>
	)
}

export default memo(TweetNewInsightReport)
