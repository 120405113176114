import constants from '../../constants-vox'
const auth = constants.AUTH_TOKEN_KEY
const sharingAuth = constants.SHARING_AUTH_TOKEN_KEY

export const setLoggedUser = (userData) => localStorage.setItem(auth, JSON.stringify(userData))
export const getLoggedUser = () => {
	try {
		return JSON.parse(localStorage.getItem(auth))
	} catch (e) {
		clearAuthLocalStorage()
	}
}
export const getLoggedUserToken = () => (localStorage.getItem(auth) ? JSON.parse(localStorage.getItem(auth))?.token : null)
export const clearAuthLocalStorage = () => localStorage.removeItem(auth)

//sharing
export const setSharingToken = (sharingToken) => localStorage.setItem(sharingAuth, sharingToken)
export const getSharingToken = () => localStorage.getItem(sharingAuth)
// export const clearSharingAuthLocalStorage = () => localStorage.removeItem(sharingAuth)
