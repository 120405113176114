import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, Collapse, Card, Button, Checkbox, Chip, TextField, Autocomplete, styled, Paper } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ChevronDownIcon, ChevronUpIcon, HashtagIcon, LoadingIcon } from 'utils/SystemIcons'
import api from 'service/service'
let lastOnDeleteTime = null

const white = '#ffffff'

const blue = 'rgba(29, 138, 248, 100%)'

const isFirefox = typeof InstallTrigger !== 'undefined'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />
const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />

const GroupSpecificGlobalFilters = ({ tags, onTagsGroupChange, toggleGroupFilter, openGroupFilter, isCreateGroup = false }) => {
	const [thisTags, setThisTags] = useState(tags || [])
	const [typingText, setTypingText] = useState('')
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(true)

	const isMounted = useRef(true)

	useEffect(() => {
		setThisTags(tags)
	}, [tags])

	useEffect(() => {
		isMounted.current = true
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	const onChangeText = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingText(text)
		}
	}

	const load = async () => {
		setLoading(true)

		const response = await api.get(`/social-profile/tags/${undefined}`)
		const data = response.data

		if (isMounted.current) {
			setOptions(data?.userTags?.changes || [])
		}

		setLoading(false)
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={props.id} id={`btn-check-tag-${option}`}>
				<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				<Chip
					style={{
						color: white,
						backgroundColor: isCreateGroup ? '#d41993' : '#1d8cf8',
						borderColor: isCreateGroup ? '#d41993' : '#1d8cf8'
					}}
					variant='outlined'
					label={option}
				/>
			</li>
		)
	}

	const handleOnChangeTags = (value) => {
		onTagsGroupChange(value)
		setThisTags(value)
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Button
					id='btn-toggle-group-filter'
					onClick={toggleGroupFilter}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: isCreateGroup ? '#d41993' : '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openGroupFilter && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openGroupFilter && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					{isCreateGroup ? 'Filtrar por tags' : 'Tags'}
				</Button>
			</Grid>

			<Collapse in={openGroupFilter}>
				<div style={{ marginLeft: '15px', marginRight: '15px', marginBottom: '20px', marginTop: '20px' }}>
					<Grid item xs={12} style={{ width: '100%' }}>
						<Autocomplete
							id='btn-open-tags-profile'
							disabled={loading}
							className='tags-profile'
							multiple
							noOptionsText='Nenhuma conta contem essa tag'
							disableClearable
							disableCloseOnSelect
							PaperComponent={CustomPaper}
							inputValue={typingText}
							value={thisTags}
							options={options}
							onInputChange={(_, value) => onChangeText(value)}
							onChange={(_, value) => handleOnChangeTags(value)}
							style={{ width: '100%' }}
							renderInput={(params) => (
								<div style={{ position: 'relative' }}>
									<CssTextField
										variant='standard'
										label={
											<div style={{ display: 'flex' }}>
												{loading ? (
													<LoadingIcon
														size={20}
														thickness={1}
														style={{
															color: isCreateGroup ? '#d41993' : '#1d8cf8',
															transform: 'translate(0px, 2px)',
															marginRight: '7px'
														}}
													/>
												) : (
													<HashtagIcon
														size={20}
														style={{
															color: isCreateGroup ? '#d41993' : '#1d8cf8',
															transform: 'translate(0px, 2px)',
															marginRight: '7px'
														}}
													/>
												)}
												Buscar tags
											</div>
										}
										{...params}
									/>
								</div>
							)}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										style={{
											color: white,
											backgroundColor: isCreateGroup ? '#d41993' : '#1d8cf8',
											borderColor: isCreateGroup ? '#d41993' : '#1d8cf8'
										}}
										key={index}
										variant='outlined'
										label={option}
										{...getTagProps({ index })}
										onDelete={(e) => {
											const props = getTagProps({ index })
											props.onDelete(e)
											lastOnDeleteTime = new Date().getTime()
										}}
									/>
								))
							}
							renderOption={optionRenderer}
						/>

						<div style={{ height: '1em', marginTop: '0.3em', color: isCreateGroup ? '#d41993' : '#1d8cf8' }}>
							{typingText && typingText !== '' && 'Pressione Enter para adicionar a tag'}
						</div>
					</Grid>
				</div>
			</Collapse>
		</Card>
	)
}

export default memo(GroupSpecificGlobalFilters)
