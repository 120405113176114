import React, { useState, memo, useEffect } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import { graphOptionsArea } from 'graphs/utils/GraphOptionsArea'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import VoxAi from 'components/VoxAI/VoxAi'

const emptyProp = {
	options: {
		chart: {
			type: 'line'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const defaultOptions = {
	type: 'engagement',
	engagement: true,
	social: false,
	posts: false,
	gender: false,
	users: false,
	men: false,
	women: false,
	noClass: false,
	politics: false,
	comments: false,
	retweets: false
}

const graphTime = 'day'
const comparison = 'absolute'

const AiAnalysis = ({ cards, globalFiltersRedux, cardType = 'term', options = defaultOptions }) => {
	const [loading, setLoading] = useState(true)
	const [filters, setFilters] = useState(globalFiltersRedux)

	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: cards?.map((card) => card.id)
		},
		globalFilters: filters,
		componentProperties: {
			comparison,
			graphTime,
			hasEngagement: options.engagement,
			hasPosts: options.posts,
			hasUsers: options.users,
			hasMen: options.men,
			hasWomen: options.women,
			hasNoClass: options.noClass,
			hasLeft: options.left_wing,
			hasRight: options.right_wing,
			hasCenterLeft: options.centre_left_wing,
			hasCenterRight: options.centre_right_wing,
			hasCenter: options.centre,
			hasComments: options.comments,
			hasShares: options.retweets,
			type: options.type
		}
	})

	let { data, isFetched, refetch } = useUpdateData({
		url: '/analysis/nowcasting/get-graph-data',
		updateItemName: 'Nowcasting',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		setFilters(globalFiltersRedux)
		refetch()
	}, [globalFiltersRedux])

	useEffect(() => {
		if (isFetched === true) {
			setLoading(false)
		}
	}, [isFetched])

	return (
		<Grid container item xs={12}>
			{loading ? (
				<div style={{ height: '100%', width: '100%', borderRadius: '20px', position: 'relative' }}>
					<GraphSkeletonLoad />
				</div>
			) : (
				<VoxAi
					cards={cards}
					graphData={{
						options: graphOptionsArea(
							false,
							data?.formatted?.options?.title,
							data?.formatted?.options?.colors,
							'smooth',
							null,
							'Day',
							false,
							false,
							true,
							'timelineVoxAi',
							true
						),
						series: data?.formatted?.series ? data?.formatted?.series : emptyProp.series,
						type: 'area',
						width: '100%',
						height: window.innerHeight / 1.2,
						graphTime: 'Day'
					}}
					isFetchingData={true}
					graphFetched={isFetched}
				/>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(AiAnalysis))
