import React, { useState } from 'react'
import { Card, CardContent, Tooltip } from '@mui/material'
import MetaAdsIconImage from 'assets/img/meta-icon.png'
import { VerifiedIcon } from 'utils/SystemIcons'

const ProfileMetaAdsHeader = ({ analytics }) => {
	const [imageUrl, setImageUrl] = useState(analytics?.profile_picture?.data?.url || null)

	return (
		<Card style={{ borderRadius: '20px' }}>
			<CardContent>
				<div style={{ borderRadius: '20px ', margin: '10px' }}>
					<div
						style={{
							display: 'flex',
							borderRadius: '100px',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						<img
							style={{ width: '4em', height: '4em', borderRadius: '100px', border: 'solid 2px #1d8cf8', backgroundColor: '#1d8cf8' }}
							src={imageUrl || MetaAdsIconImage}
							onError={() => setImageUrl(null)}
						/>
						<div style={{ marginLeft: '20px' }}>
							<div style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', color: '#1d8cf8' }}>
								{analytics?.account_name}
								{analytics?.verified && (
									<Tooltip title='Usuário verificado' enterDelay={0} enterNextDelay={0}>
										<span
											style={{
												cursor: 'pointer',
												marginLeft: '0.3em'
											}}
										>
											<VerifiedIcon size={15} />
										</span>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export default ProfileMetaAdsHeader
