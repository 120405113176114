import React from 'react'
import { Button, Dialog, IconButton, Card, Grid } from '@mui/material'
import { ChevronLeftIcon, FacebookIcon, LoadingIcon, MetaAdsIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const FacebookPageOption = ({ option, onSelection, provider }) => {
	let icon

	if (provider === 'meta_ads') {
		icon = <MetaAdsIcon size={20} style={{ marginRight: '1em' }} />
	} else {
		icon = <FacebookIcon size={20} style={{ marginRight: '1em' }} />
	}

	return (
		<Grid item xs={12}>
			<Card
				elevation={1}
				style={{
					borderRadius: '25px',
					margin: '1em',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`
				}}
			>
				<Button
					style={{
						fontFamily: 'Poppins',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
					fullWidth
					onClick={() => onSelection(option.value)}
				>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
						{icon}
						{option.label}
					</div>
					{provider === 'meta_ads' && (
						<div style={{ textTransform: 'none', marginLeft: '2em', fontFamily: 'Poppins', color: '#9a9a9a' }}>{option.value}</div>
					)}
				</Button>
			</Card>
		</Grid>
	)
}

const AlertDialog = ({ isOpen, onClose, title, options, onSelection, loading, provider = 'facebook' }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth='sm'
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
		>
			<div>
				<IconButton color={'primary'} onClick={onClose}>
					<ChevronLeftIcon color={'#1d8cf8'} />
				</IconButton>
			</div>

			<h2 style={{ paddingBottom: 20, textAlign: 'center', fontFamily: 'Poppins', fontWeight: 'lighter' }}>{title}</h2>
			<div style={{ height: '50vh', overflow: 'auto' }}>
				{loading ? (
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<LoadingIcon size={70} thickness={2} color='#1d8cf8' />
					</div>
				) : (
					options.length > 0 &&
					options.map((option) => {
						return <FacebookPageOption key={option.value} option={option} onSelection={onSelection} provider={provider} />
					})
				)}
			</div>
		</Dialog>
	)
}

export default AlertDialog
