import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MiniCardSkeletonOverviewTotal = () => (
	<div style={{ marginTop: '-4px', borderRadius: '0px' }}>
		<Skeleton height='165px' style={{ borderRadius: '0px' }} />
	</div>
)

export default MiniCardSkeletonOverviewTotal
