import React, { useState, useEffect } from 'react'
import api from 'service/service'
import { Card, Grid, CardContent, Button, DialogTitle, DialogContent, Dialog, IconButton, Tooltip, TextField } from '@mui/material'
import { CloseIcon, EditIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const AiEditTitle = ({ id, oldTitle = '', refetch, setIsFirstTime, cardTermId }) => {
	const [showSaveTitleModal, setShowTitleModal] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [title, setTitle] = useState(oldTitle)

	useEffect(() => {
		if (showSaveTitleModal) {
			setTitle(oldTitle)
		}
	}, [showSaveTitleModal])

	const toggleTitleModal = () => {
		if (!submitting) {
			setShowTitleModal(!showSaveTitleModal)
		} else {
			setShowTitleModal(true)
		}
		return
	}

	const registerTitle = async (event) => {
		event.preventDefault()

		setSubmitting(true)
		api.post('/voxradar-ai/edit-title', {
			id,
			title,
			cardTermId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleTitleModal()
				setIsFirstTime(false)
				refetch()
			})
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end', marginTop: '-36px' }}>
			<Tooltip title={'Editar'} placement='bottom' enterDelay={500} enterNextDelay={500}>
				<IconButton id='btn-edit-ai-title' onClick={toggleTitleModal}>
					<EditIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
				</IconButton>
			</Tooltip>
			<Dialog
				open={showSaveTitleModal}
				onClose={toggleTitleModal}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='sm'
			>
				<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
					<DialogTitle>
						<div className='jc-space-between d-flex ai-center'>
							<div id='span-edit-ai-title'>
								<EditIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#1d8cf8', marginRight: '8px' }} />
								Editar título da análise
							</div>
							<div>
								<IconButton id='btn-close-edit-ai-title' color='primary' style={{ outline: 'none' }} onClick={toggleTitleModal}>
									<CloseIcon color='#1d8cf8' size={20} />
								</IconButton>
							</div>
						</div>
					</DialogTitle>
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12}>
								<TextField
									variant='standard'
									label='Título da análise'
									size='small'
									className='d-flex'
									style={{ color: '#1d8cf8', borderRadius: '4px' }}
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								/>
							</Grid>
						</CardContent>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12} container justifyContent={'center'}>
								<Button
									style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
									variant='outlined'
									color='primary'
									disabled={submitting || title == ''}
									onClick={registerTitle}
								>
									Salvar
								</Button>
							</Grid>
						</CardContent>
					</DialogContent>
				</Card>
			</Dialog>
		</div>
	)
}

export default AiEditTitle
