import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Tabs, Tab, Divider, Card, Pagination } from '@mui/material'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import Trending from '../Trending/Trending'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { EyeIcon } from 'utils/SystemIcons'
import { saveAllCardsCanOpenAction, resetCardsComparisonAction, saveCardTypeAction } from 'store/redux/actions/card.actions'
import { resetGlobalFiltersTermAction, changeSourcesToDefaultAction } from 'store/redux/actions/global.actions'
import FoldersSideContainer from 'components/FoldersSideContainer/FoldersSideContainer'
import ButtonModalNewInsight from './components/ButtonModalNewInsight/ButtonModalNewInsight'
import AnalysisCardSearch from 'components/AnalysisCardSearch/AnalysisCardSearch'
import AnalysisCardContainer from 'components/AnalysisCardContainer/AnalysisCardContainer'
import PausedFilter from './components/PausedFilter/PausedFilter'
import { getCardColorByCardType } from 'components/AnalysisCard/analysisCardUtils'
import './Monitoring.css'

const blue = 'rgba(29, 138, 248, 100%)'

const loadTabsAllFalse = {
	monitoring: false,
	trends: false
}

const DEFAULT_FOLDER_STATE = { id: 'ALL_ID', name: 'Todos' }

const Monitoring = ({
	location,
	saveAllCardsCanOpenDispatch,
	resetCardsComparisonDispatch,
	resetGlobalFiltersDispatch,
	saveCardTypeDispatch,
	user,
	changeSourcesToDefaultDispatch
}) => {
	const [tab, setTab] = useState(location.state?.cardType || 'term')
	const [source, setSource] = useState(location.state?.source || 'twitter')
	const [folder, setFolder] = useState(DEFAULT_FOLDER_STATE)
	const [loading, setLoading] = useState(true)
	const [filterCardName, setFilterCardName] = useState(location.state?.title || '')
	const [numberOfPages, setNumberOfPages] = useState(1)
	const [page, setPage] = useState(0)
	const [isCardSearchingFilter, setIsCardSearchingFilter] = useState(true)
	const canLoadTab = { ...loadTabsAllFalse, monitoring: true }

	// {data, isError, error, isFetching, refetch, isFetched, refetch } // alguns parâmetros para acesso
	const getCardsUrl = () =>
		`/monitoring/card/get-all-by-user-id/${tab}?folderId=${folder.id}&page=${page}&isCardSearchingFilter=${isCardSearchingFilter}&filterCardName=${filterCardName}`
	let cards = useUpdateData({
		url: getCardsUrl(),
		updateItemName: 'Cartoes',
		refetchInterval: 10000,
		freshDataTime: 10000,
		isEnabled: true,
		queryName: 'onDemoCardChange_useUpdateData'
	})

	//PERMISSIONS
	let permissionCreateCard = !user.subUser || (user.subUser && user.subUser.permissionCreate)
	let permissionRemoveCard = !user.subUser || (user.subUser && user.subUser.permissionRemove)
	let isUserAdmin = user?.isAdmin

	let termsCountInfo = useUpdateData({
		url: '/credits/get-terms-count-info',
		updateItemName: 'termsCountInfo',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: true,
		queryName: 'termsCountInfo'
	})

	//SPECIFIC
	useEffect(() => {
		resetCardsComparisonDispatch()
		resetGlobalFiltersDispatch()
		// if (tab === 'profile') {
		// 	setTimeout(() => {
		// 		changeSourcesToDefaultDispatch(tab)
		// 	}, 200)
		// }
	}, [])

	useEffect(() => {
		setPage(0)
		setFilterCardName('')
		setSource('twitter')
		saveCardTypeDispatch(tab)
	}, [tab])

	useEffect(() => {
		if (location.state?.cardType) {
			setTab(location.state?.cardType)
		}
		if (location.state?.source) {
			setSource(location.state?.source)
		}
		if (location.state?.title) {
			setFilterCardName(location.state?.title)
		}
	}, [location.state])

	const onTabChange = (_, newTab) => {
		changeSourcesToDefaultDispatch(newTab)
		setTab(newTab)
	}

	//GENERAL

	useEffect(() => {
		if (cards.isFetched) {
			setLoading(false)
		}
	}, [cards.isFetched])

	useEffect(() => {
		saveAllCardsCanOpenDispatch(cards?.data?.items || [], { tab, source })
		setNumberOfPages(cards?.data?.totalPages || 1)
	}, [cards.data])

	useEffect(() => {
		if (cards?.error?.response?.data?.toast) {
			sendMessageToast(cards?.error?.response?.data?.toast?.message, cards?.error?.response?.data?.toast?.type)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards?.error])

	const handleGetSelectedFolder = (folder) => {
		setPage(0)
		setFolder(folder)
	}

	const handleChangeSearchFilter = (filterCardName) => {
		setFilterCardName(filterCardName)
		if (page !== 0) {
			setPage(0)
		}
	}

	const refetchItems = () => cards.refetch()

	const handleChangePage = (_, value) => {
		setPage(value - 1)
	}

	const onPausedFilterChange = (isSearching) => {
		setPage(0)
		setIsCardSearchingFilter(isSearching)
	}

	return (
		<>
			<div>
				<Grid container style={{ marginBottom: '2em', paddingLeft: '1.4em', paddingRight: '1.4em' }}>
					<Grid item xs={2}>
						<GraphTitle
							id='span-monitoring'
							title='Monitoramento'
							icon={<EyeIcon size={25} style={{ color: '#1d8cf8' }} />}
							description=''
						/>
					</Grid>
					<Grid item xs={10} container justifyContent='flex-start'>
						<Tabs
							style={{ marginLeft: '2em' }}
							value={tab}
							variant='fullWidth'
							onChange={onTabChange}
							TabIndicatorProps={{ style: { background: blue } }}
						>
							<Tab
								id='tab-term'
								label='Temas'
								value='term'
								style={{ fontFamily: 'Poppins', fontsize: '12px', color: 'grey', outline: 'none', width: '15em' }}
							/>
							<Tab
								id='tab-profile'
								label='Contas'
								value='profile'
								style={{ fontFamily: 'Poppins', fontsize: '12px', color: 'grey', outline: 'none', width: '15em' }}
							/>
							<Tab
								id='tab-group'
								label='Grupos'
								value='group'
								style={{ fontFamily: 'Poppins', fontsize: '12px', color: 'grey', outline: 'none', width: '15em' }}
							/>
						</Tabs>
					</Grid>
					<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-2px' }} />
				</Grid>
				{canLoadTab && (tab === 'term' || tab === 'profile' || tab === 'group' || tab === 'report') && (
					<Grid container style={{ paddingLeft: '1.4em' }}>
						<Grid item xs={2} style={{ height: '86vh' }}>
							<FoldersSideContainer
								type={tab}
								getSelectedFolder={handleGetSelectedFolder}
								canEditFolder={permissionCreateCard}
								canDeleteFolder={permissionRemoveCard}
							/>
						</Grid>
						<Grid item xs={10}>
							<Card
								style={{
									marginLeft: '20px',
									marginRight: '20px',
									borderRadius: '20px',
									backgroundColor: 'rgba(255, 255, 255, 0.2)',
									border: '1px solid rgba(245, 245, 245, 0.5)',
									boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
								}}
							>
								<Grid container style={{ backgroundColor: 'transparent', marginBottom: '27px', borderRadius: '10px 10px 0px 0px' }}>
									<Grid item xs={4} container justifyContent='flex-start' alignContent='center'>
										<div style={{ marginLeft: '12px' }}>
											<ButtonModalNewInsight
												onSubmit={cards.refetch}
												type={tab}
												cards={cards?.data}
												source={source}
												disabled={false}
												canCreate={permissionCreateCard}
											/>
										</div>
									</Grid>
									<Grid item xs={4} style={{ marginTop: '4px' }}>
										<AnalysisCardSearch onChange={handleChangeSearchFilter} />
									</Grid>

									{tab !== 'group' && tab !== 'report' && (
										<PausedFilter isCardSearching={isCardSearchingFilter} onPausedFilterChange={onPausedFilterChange} />
									)}

									<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
								</Grid>

								<AnalysisCardContainer
									filterCardName={filterCardName}
									folder={folder}
									page={page}
									refetchItems={refetchItems}
									items={cards?.data?.items}
									isLoading={loading}
									permissionCreateCard={permissionCreateCard}
									permissionRemoveCard={permissionRemoveCard}
									isUserAdmin={isUserAdmin}
									termsRemainingMonth={termsCountInfo?.data?.remainingMonth || 0}
								/>

								<Grid container alignItems='center' style={{ height: '5vh' }}>
									<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%' }} />
									<Grid item xs={3} style={{ display: 'flex' }}>
										<div
											style={{
												whiteSpace: 'nowrap',
												fontFamily: 'Poppins',
												fontWeight: '300',
												fontSize: '14px',
												marginLeft: '15px',
												letterSpacing: '3px',
												textTransform: 'uppercase'
											}}
										>
											{folder.name}
										</div>
										{!!cards?.data?.cardCount && (
											<div
												style={{
													whiteSpace: 'nowrap',
													fontFamily: 'Poppins',
													fontWeight: '300',
													fontSize: '14px',
													marginLeft: '15px',
													letterSpacing: '3px',
													textTransform: 'uppercase',
													color: getCardColorByCardType(tab)
												}}
											>
												({cards?.data?.cardCount})
											</div>
										)}
									</Grid>
									<Grid item xs={6} container justifyContent='center' alignItems='center'>
										<Pagination count={numberOfPages} page={page + 1} onChange={handleChangePage} />
									</Grid>
								</Grid>
							</Card>
						</Grid>
					</Grid>
				)}

				{canLoadTab && tab === 'trends' && <Trending />}
			</div>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	saveAllCardsCanOpenDispatch: (allCards, searchPropsForComparativeBar) =>
		dispatch(saveAllCardsCanOpenAction(allCards, searchPropsForComparativeBar)),
	resetCardsComparisonDispatch: () => dispatch(resetCardsComparisonAction()),
	resetGlobalFiltersDispatch: () => dispatch(resetGlobalFiltersTermAction()),
	saveCardTypeDispatch: (type) => dispatch(saveCardTypeAction(type)),
	changeSourcesToDefaultDispatch: (cardType) => dispatch(changeSourcesToDefaultAction(cardType))
})

const mapStateToProps = ({ store: { user, monitoring } }) => ({
	user,
	tabFilter: monitoring.filters?.tab,
	searchFilter: monitoring.filters.search,
	folderIdFilter: monitoring.filters.folderId,
	viewFilter: monitoring.filters.view
})

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring)
