import React, { useState, memo } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const tabsize = 44

const useStyles = makeStyles(() => ({
	tab: {
		height: tabsize,
		minHeight: tabsize,
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
}))

const NetworkSortSelector = ({ order, onChangeSort }) => {
	const [thisOrder, setThisOrder] = useState(order || 'score')
	const classes = useStyles()

	const handleSelect = (_, newValue) => {
		setThisOrder(newValue)
		onChangeSort(newValue)
	}

	return (
		<>
			<Tabs
				style={{ marginTop: '0px', marginLeft: '8px', textAlign: 'left', float: 'left' }}
				centered
				value={thisOrder}
				onChange={handleSelect}
				TabIndicatorProps={{ style: { background: blue } }}
			>
				<Tab label='ordenar por score' value={'score'} style={{ outline: 'none' }} className={classes.tab} disabled={false} />

				<Tab label='ordenar por número de posts' value={'posts'} style={{ outline: 'none' }} className={classes.tab} disabled={false} />
			</Tabs>
		</>
	)
}

export default memo(NetworkSortSelector)
