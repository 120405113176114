import React, { useState, useEffect, useRef } from 'react'
import { Grid, Button, Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CardTag from './components/CardTag/CardTag'
import autoAnimate from '@formkit/auto-animate'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const getNextId = (tagObject) => {
	let nextId = 0
	if (tagObject?.value?.length > 0) {
		const orderedDescTags = tagObject.value.sort((a, b) => b.id - a.id)
		nextId = orderedDescTags[0].id + 1
	}

	return nextId
}

const CardTags = ({ onChangeTags, externalTags, onChangeFieldErrors }) => {
	const [tagsObject, setTagsObject] = useState(externalTags || { value: [] })
	const [indexId, setIndexId] = useState(getNextId(externalTags))
	const classes = useStyles()

	const parent = useRef(null)

	useEffect(() => {
		setTagsObject(externalTags)
		setIndexId(getNextId(externalTags))
	}, [externalTags])

	useEffect(() => {
		onChangeTags(tagsObject)
	}, [tagsObject])

	useEffect(() => {
		parent.current && autoAnimate(parent.current)
	}, [parent])

	const addTag = () => {
		const newTag = {
			id: indexId,
			tag: '',
			query: '',
			subtag: '',
			tag_key: ''
		}
		let newArray = [...tagsObject.value]
		newArray.unshift(newTag)
		setTagsObject({ value: newArray })
		setIndexId(indexId + 1)
	}

	const onChangeName = (value, id) => {
		let object = tagsObject.value.find((element) => element.id === id)
		object.tag_key = value

		if (value.includes('_')) {
			const split = value.split('_')
			object.tag = split[0]
			object.subtag = split[1]
		} else {
			object.tag = value
		}
	}

	const onChangeQuery = (newQueryObject, newQueryText, id) => {
		if (newQueryObject && newQueryText) {
			let object = tagsObject.value.find((element) => element.id === id)
			object.query = newQueryText
			object.jsonLogicFormat = newQueryObject?.format?.jsonLogicFormat
			object.isValidQuery = newQueryObject?.isValid
		}
	}

	const onDelete = (id) => {
		let object = tagsObject.value.find((element) => element.id === id)
		let index = tagsObject.value.indexOf(object)
		tagsObject.value.splice(index, 1)
		setTagsObject({ value: tagsObject.value })
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Button className={classes.button} style={{ marginTop: '25px', marginBottom: '5px' }} onClick={() => addTag()}>
						Nova Regra de Tagueamento +
					</Button>
				</Grid>
			</Grid>
			<Collapse in={tagsObject.value.length > 0} timeout='auto' collapsedSize={'0px'}>
				<div ref={parent} style={{ marginTop: '1em' }}>
					{tagsObject?.value?.map((tagObject) => (
						<Grid item xs={12} key={tagObject.id} style={{ margin: '1em' }}>
							<CardTag
								id={tagObject.id}
								tagObject={tagObject}
								tagsObject={tagsObject}
								onChangeName={onChangeName}
								onChangeQuery={onChangeQuery}
								onDelete={onDelete}
								onChangeFieldErrors={onChangeFieldErrors}
							/>
						</Grid>
					))}
				</div>
			</Collapse>
		</>
	)
}

export default CardTags
