import React from 'react'
import capa from 'assets/img/capa.png'
import engajamentoTwitter from 'assets/img/Engajamento Twitter.png'
import engajamentoFacebook from 'assets/img/Engajamento facebook.png'
import engajamentoInstagram from 'assets/img/Engajamento Instagram.png'
import engajamentoTiktok from 'assets/img/Engajamento Tiktok.png'
import engajamentoYoutube from 'assets/img/Engajamento Youtube.png'
import engajamentoNews from 'assets/img/Engajamento Noticias.png'
import sentimentoTwitterPadrao from 'assets/img/Sentimentos Twitter Padrao.png'
import sentimentoFacebookPadrao from 'assets/img/Sentimentos Facebook Padrao.png'
import sentimentoInstagramPadrao from 'assets/img/Sentimentos Instagram Padrao.png'
import sentimentoTiktokPadrao from 'assets/img/Sentimentos Tiktok Padrao.png'
import sentimentoYoutubePadrao from 'assets/img/Sentimentos Youtube Padrao.png'
import sentimentoTwitterSaldo from 'assets/img/Sentimentos Twitter Saldo.png'
import sentimentoFacebookSaldo from 'assets/img/Sentimentos Facebook Saldo.png'
import sentimentoInstagramSaldo from 'assets/img/Sentimentos Instagram Saldo.png'
import sentimentoTiktokSaldo from 'assets/img/Sentimentos Tiktok Saldo.png'
import sentimentoYoutubeSaldo from 'assets/img/Sentimentos Youtube Saldo.png'
import resumoTwitter from 'assets/img/Resumo Twitter.png'
import resumoFacebook from 'assets/img/Resumo facebook.png'
import resumoInstagram from 'assets/img/Resmo instagram.png'
import resumoTiktok from 'assets/img/Resumo tiktok.png'
import resumoYoutube from 'assets/img/Resumo youtube.png'
import resumoNews from 'assets/img/Resumo noticias.png'
import localidadeGeral from 'assets/img/localidade geral.png'
import localidadeSentimento from 'assets/img/localidade sentimento.png'
import localidadeGenero from 'assets/img/localidade genero.png'
import conta from 'assets/img/Contas.png'
import contaPositiva from 'assets/img/Contas positivas.png'
import contaNegativa from 'assets/img/Contas Negativas.png'
import hashtag from 'assets/img/HashTags.png'
const ReportContentImage = ({ options, cardType }) => {
	return (
		<div>
			<div style={{ border: '1px solid gray', margin: '1em' }}>{<img src={capa} alt='capa' style={{ width: '100%' }} />}</div>
			{options.timelineTwitterEngagement && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoTwitter} alt='engajamentoTwitter' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineFacebookEngagement && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoFacebook} alt='engajamentoFacebook' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineInstagramEngagement && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoInstagram} alt='engajamentoInstagram' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineTiktokEngagement && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoTiktok} alt='engajamentoTiktok' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineYoutubeEngagement && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoYoutube} alt='engajamentoYoutube' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineNewsEngagement && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoNews} alt='engajamentoNews' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineTwitterPosts && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoTwitter} alt='engajamentoTwitter' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineFacebookPosts && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoFacebook} alt='engajamentoFacebook' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineInstagramPosts && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoInstagram} alt='engajamentoInstagram' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineTiktokPosts && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoTiktok} alt='engajamentoTiktok' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineYoutubePosts && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoYoutube} alt='engajamentoYoutube' style={{ width: '100%' }} />
				</div>
			)}
			{options.timelineNewsPosts && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={engajamentoNews} alt='engajamentoNews' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsTwitterBalance && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoTwitterSaldo} alt='sentimentoTwitterSaldo' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsFacebookBalance && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoFacebookSaldo} alt='sentimentoFacebookSaldo' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsInstagramBalance && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoInstagramSaldo} alt='sentimentoInstagramSaldo' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsTiktokBalance && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoTiktokSaldo} alt='sentimentoTiktokSaldo' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsYoutubeBalance && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoYoutubeSaldo} alt='sentimentoYoutubeSaldo' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsTwitterStandard && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoTwitterPadrao} alt='sentimentoTwitterPadrao' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsFacebookStandard && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoFacebookPadrao} alt='sentimentoFacebookPadrao' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsInstagramStandard && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoInstagramPadrao} alt='sentimentoInstagramPadrao' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsTiktokStandard && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoTiktokPadrao} alt='sentimentoTiktokPadrao' style={{ width: '100%' }} />
				</div>
			)}
			{options.sentimentsYoutubeStandard && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={sentimentoYoutubePadrao} alt='sentimentoYoutubePadrao' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryTwitter && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoTwitter} alt='resumoTwitter' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkTwitterAccounts && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={conta} alt='conta' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkTwitterSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={contaPositiva} alt='contaPositiva' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkTwitterSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={contaNegativa} alt='contaNegativa' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkTwitterHashtags && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={hashtag} alt='hashtag' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationTwitterVolume && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGeral} alt='localidadeGeral' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationTwitterSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeSentimento} alt='localidadeSentimento' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationTwitterGender && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGenero} alt='localidadeGenero' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryFacebook && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoFacebook} alt='resumoFacebook' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkFacebookAccounts && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={conta} alt='conta' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkFacebookSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={contaPositiva} alt='contaPositiva' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkFacebookSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={contaNegativa} alt='contaNegativa' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookVolume && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGeral} alt='localidadeGeral' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeSentimento} alt='localidadeSentimento' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookGender && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGenero} alt='localidadeGenero' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryInstagram && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoInstagram} alt='resumoInstagram' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryTiktok && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoTiktok} alt='resumoTiktok' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryYoutube && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoYoutube} alt='resumoYoutube' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookVolume && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGeral} alt='localidadeGeral' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookSentiments && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeSentimento} alt='localidadeSentimento' style={{ width: '100%' }} />
				</div>
			)}
			{options.locationFacebookGender && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={localidadeGenero} alt='localidadeGenero' style={{ width: '100%' }} />
				</div>
			)}
			{options.summaryNews && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={resumoNews} alt='resumoNews' style={{ width: '100%' }} />
				</div>
			)}
			{options.networkNewsAccounts && cardType !== 'profile' && (
				<div style={{ border: '1px solid gray', margin: '1em' }}>
					<img src={conta} alt='conta' style={{ width: '100%' }} />
				</div>
			)}
		</div>
	)
}

export default ReportContentImage
