import React, { useState, memo } from 'react'
import { Grid, Checkbox, Collapse, Chip, TextField, Tooltip, IconButton, Autocomplete, Paper, styled, Card, Button, Badge } from '@mui/material'
import { validateStopAccounts, validateHatersAndLovers } from './utils'
import { MinusIcon, InfoIcon, HeartIcon, AngryIcon, ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import { RED, BLUE, WHITE, GRAY, SOURCE_ELEMENTS } from './config'

const SourceNotIncluded = ({ name }) => (
	<Tooltip title={`Seu plano não inclui esta fonte de dados (${name})`} placement='bottom' enterDelay={100} enterNextDelay={100}>
		<IconButton style={{ padding: '5px', marginLeft: '0.3em' }}>
			<InfoIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
		</IconButton>
	</Tooltip>
)
let lastOnDeleteTime = null

const isFirefox = typeof InstallTrigger !== 'undefined'

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		backgroundColor: '#1d8cf8'
	}
})

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: BLUE,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: BLUE
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const SourceCheckContent = ({ formState, source, hasSource, onChangeForm, messageToast, fieldErrors, onChangeCheck, isChecked }) => {
	const [typingText, setTypingText] = useState('')
	const [typingTextHaters, setTypingTextHaters] = useState('')
	const [typingTextLovers, setTypingTextLovers] = useState('')
	const [openStop, setOpenStop] = useState(false)
	const [openHatersLovers, setOpenHatersLovers] = useState(false)
	const IconReference = SOURCE_ELEMENTS[source]?.icon
	const sourceElementsIcon = <IconReference size={25} style={{ color: '#1d8cf8', marginRight: '0.3em' }} />
	const sourceElementsName = SOURCE_ELEMENTS[source]?.name
	const sourceElementsTagsRegex = SOURCE_ELEMENTS[source]?.tagsRegex
	const sourceElementsType = SOURCE_ELEMENTS[source]?.type

	const onChangeText = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingText(text)
		}
	}
	const onChangeTextHaters = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingTextHaters(text)
		}
	}
	const onChangeTextLovers = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingTextLovers(text)
		}
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				marginLeft: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Button
				onClick={() => {
					if (hasSource) {
						onChangeCheck(!isChecked)
						setOpenStop(false)
						if (source !== 'news') {
							setOpenHatersLovers(false)
						}
					}
				}}
				fullWidth
				style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
			>
				<Grid item xs={12} className='ai-center d-flex'>
					<Checkbox color='primary' checked={isChecked} onChange={(_, value) => onChangeCheck(value)} disabled={!hasSource} />
					{sourceElementsIcon}
					{sourceElementsName}
					{!hasSource && <SourceNotIncluded name={sourceElementsName} />}
				</Grid>
			</Button>

			<Collapse in={isChecked}>
				<Grid style={{ marginTop: '0.5em', marginLeft: '1.5em', fontFamily: 'Poppins', fontSize: '15px', color: '#9a9a9a' }}>Opcional :</Grid>
				<Card
					elevation={0}
					style={{
						borderRadius: '20px',
						margin: '1em',
						backgroundColor: 'rgba(255,255,255,0.6)',
						border: openStop ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
					}}
				>
					<Button
						onClick={() => {
							setOpenStop(!openStop)
							if (source !== 'news') {
								setOpenHatersLovers(false)
							}
						}}
						fullWidth
						style={{
							fontFamily: 'Poppins',
							color: openStop ? '#1d8cf8' : '#9a9a9a',
							justifyContent: 'flex-start'
						}}
					>
						{!openStop && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{openStop && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						{sourceElementsType}s a serem retirad{source === 'news' ? 'o' : 'a'}s
						<StyledBadge
							badgeContent={formState?.stopAccounts[source].length}
							color='error'
							style={{ margin: '0.9em 0 1.1em 1.5em', fontFamily: 'Poppins' }}
						/>
					</Button>
					<Collapse in={openStop}>
						<Grid item xs={12} style={{ width: '100%', padding: '0.5em 1.5em 1.5em 1.5em' }}>
							<Autocomplete
								className='autocomplete-tags-stop-accounts'
								multiple
								freeSolo
								PaperComponent={CustomPaper}
								disableClearable
								inputValue={typingText}
								value={formState?.stopAccounts[source]}
								options={[]}
								onInputChange={(_, value) => onChangeText(value)}
								isOptionEqualToValue={() => false}
								onChange={(event, value) => validateStopAccounts(value, source, 'stop', formState, messageToast, onChangeForm, event)}
								style={{ width: '100%' }}
								renderInput={(params) => (
									<div style={{ position: 'relative' }}>
										<CssTextField
											variant='standard'
											onKeyDown={(event) => {
												if (!sourceElementsTagsRegex?.test(event.key)) {
													event.preventDefault()
												}
											}}
											label={
												<div style={{ display: 'flex' }}>
													<MinusIcon
														size={20}
														style={{ color: GRAY, transform: 'translate(0px, 2px)', marginRight: '7px' }}
													/>
													Adicionar {sourceElementsType}s {source !== 'news' ? 'do' : 'de'}{' '}
													{source !== 'news' ? sourceElementsName : sourceElementsName.toLowerCase()} a serem retirad
													{source === 'news' ? 'o' : 'a'}s
												</div>
											}
											{...params}
										/>
									</div>
								)}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											style={{ color: WHITE, backgroundColor: GRAY, borderColor: GRAY }}
											key={index}
											variant='outlined'
											label={option}
											{...getTagProps({ index })}
											onDelete={(e) => {
												const props = getTagProps({ index })
												props.onDelete(e)
												lastOnDeleteTime = new Date().getTime()
											}}
										/>
									))
								}
							/>
							{typingText && typingText !== '' && 'Pressione Enter para adicionar'}
						</Grid>
					</Collapse>
				</Card>

				{source !== 'news' && (
					<Card
						elevation={0}
						style={{
							borderRadius: '20px',
							margin: '1em',
							backgroundColor: 'rgba(255,255,255,0.6)',
							border: openHatersLovers ? '1px solid #1d8cf8' : '1px solid #9a9a9a'
						}}
					>
						<Button
							onClick={() => {
								setOpenHatersLovers(!openHatersLovers)
								setOpenStop(false)
							}}
							fullWidth
							style={{
								fontFamily: 'Poppins',
								color: openHatersLovers ? '#1d8cf8' : '#9a9a9a',
								justifyContent: 'flex-start'
							}}
						>
							{!openHatersLovers && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
							{openHatersLovers && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
							{sourceElementsType}s Haters/Lovers
							<StyledBadge
								badgeContent={
									formState?.manualClassificationAccount[source]?.haters.length +
									formState?.manualClassificationAccount[source]?.lovers.length
								}
								color='error'
								style={{ margin: '0.9em 0 1.1em 1.5em', fontFamily: 'Poppins' }}
							/>
						</Button>
						<Collapse in={openHatersLovers}>
							<Grid item xs={12} style={{ width: '100%', padding: '0.5em 1.5em 1.5em 1.5em' }}>
								<Autocomplete
									className='autocomplete-tags-accounts'
									multiple
									freeSolo
									PaperComponent={CustomPaper}
									disableClearable
									inputValue={typingTextHaters}
									value={formState?.manualClassificationAccount[source]?.haters}
									options={[]}
									onInputChange={(_, value) => onChangeTextHaters(value)}
									isOptionEqualToValue={() => false}
									onChange={(event, value) =>
										validateHatersAndLovers(value, source, 'haters', formState, messageToast, onChangeForm, event)
									}
									style={{ width: '100%' }}
									renderInput={(params) => (
										<div style={{ position: 'relative' }}>
											<CssTextField
												variant='standard'
												onKeyDown={(event) => {
													if (!sourceElementsTagsRegex?.test(event.key)) {
														event.preventDefault()
													}
												}}
												label={
													<div style={{ display: 'flex' }}>
														<AngryIcon
															size={20}
															style={{ color: RED, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
														Adicionar {sourceElementsType}s Haters do {sourceElementsName}
													</div>
												}
												error={!!fieldErrors?.manualClassificationAccount?.error[source]?.haters}
												helperText={fieldErrors?.manualClassificationAccount?.error[source]?.haters}
												{...params}
											/>
										</div>
									)}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip
												style={{ color: WHITE, backgroundColor: RED, borderColor: RED }}
												key={index}
												variant='outlined'
												label={option}
												{...getTagProps({ index })}
												onDelete={(e) => {
													const props = getTagProps({ index })
													props.onDelete(e)
													lastOnDeleteTime = new Date().getTime()
												}}
											/>
										))
									}
								/>
								{typingTextHaters && typingTextHaters !== '' && 'Pressione Enter para adicionar'}
							</Grid>
							<Grid item xs={12} style={{ width: '100%', padding: '0.5em 1.5em 1.5em 1.5em' }}>
								<Autocomplete
									className='autocomplete-tags-accounts'
									multiple
									freeSolo
									PaperComponent={CustomPaper}
									disableClearable
									inputValue={typingTextLovers}
									value={formState?.manualClassificationAccount[source]?.lovers}
									options={[]}
									onInputChange={(_, value) => onChangeTextLovers(value)}
									isOptionEqualToValue={() => false}
									onChange={(event, value) =>
										validateHatersAndLovers(value, source, 'lovers', formState, messageToast, onChangeForm, event)
									}
									style={{ width: '100%' }}
									renderInput={(params) => (
										<div style={{ position: 'relative' }}>
											<CssTextField
												variant='standard'
												onKeyDown={(event) => {
													if (!sourceElementsTagsRegex?.test(event.key)) {
														event.preventDefault()
													}
												}}
												label={
													<div style={{ display: 'flex' }}>
														<HeartIcon
															size={20}
															style={{ color: BLUE, transform: 'translate(0px, 2px)', marginRight: '7px' }}
														/>
														Adicionar {sourceElementsType}s Lovers do {sourceElementsName}
													</div>
												}
												error={!!fieldErrors?.manualClassificationAccount?.error[source]?.lovers}
												helperText={fieldErrors?.manualClassificationAccount?.error[source]?.lovers}
												{...params}
											/>
										</div>
									)}
									renderTags={(value, getTagProps) =>
										value.map((option, index) => (
											<Chip
												style={{ color: WHITE, backgroundColor: BLUE, borderColor: BLUE }}
												key={index}
												variant='outlined'
												label={option}
												{...getTagProps({ index })}
												onDelete={(e) => {
													const props = getTagProps({ index })
													props.onDelete(e)
													lastOnDeleteTime = new Date().getTime()
												}}
											/>
										))
									}
								/>
								{typingTextLovers && typingTextLovers !== '' && 'Pressione Enter para adicionar'}
							</Grid>
						</Collapse>
					</Card>
				)}
			</Collapse>
		</Card>
	)
}

export default memo(SourceCheckContent)
