//Types
const CARD_ACTION_TYPES = {
	ADD_CARD_COMPARISON: 'ADD_CARD_COMPARISON',
	ADD_COMPARATIVE_CARD_COMPARISON: 'ADD_COMPARATIVE_CARD_COMPARISON',
	RESET_CARDS_COMPARISON: 'RESET_CARDS_COMPARISON',
	REMOVE_CARD_COMPARISON: 'REMOVE_CARD_COMPARISON',
	SAVE_ALL_CARDS_CAN_OPEN: 'SAVE_ALL_CARDS_CAN_OPEN',
	ADD_CARD_COMPARISON_BY_ID: 'ADD_CARD_COMPARISON_BY_ID',
	SAVE_CARD_TYPE_ACTION: 'SAVE_CARD_TYPE_ACTION',
	UPDATE_CARD_COMPARISON_STATUS_ACTION: 'UPDATE_CARD_COMPARISON_STATUS_ACTION'
}

//Actions
const addCardComparisonAction = (card, switchCardId = null) => ({
	//fazer funcionar só com o id em vez do cartão inteiro
	type: CARD_ACTION_TYPES.ADD_CARD_COMPARISON,
	payload: {
		card,
		switchCardId
	}
})

const removeCardComparisonAction = (cardId) => ({
	type: CARD_ACTION_TYPES.REMOVE_CARD_COMPARISON,
	payload: { cardId }
})

const resetCardsComparisonAction = () => ({
	type: CARD_ACTION_TYPES.RESET_CARDS_COMPARISON
})

const saveAllCardsCanOpenAction = (cards, searchPropsForComparativeBar) => {
	let cardsCanOpen = cards.filter((card) => card?.cardStatus?.general === 'S')

	return {
		type: CARD_ACTION_TYPES.SAVE_ALL_CARDS_CAN_OPEN,
		payload: { cardsCanOpen, searchPropsForComparativeBar }
	}
}

const addCardComparisonByIdsAction = (cardId, isComparative) => {
	return {
		type: CARD_ACTION_TYPES.ADD_CARD_COMPARISON_BY_ID,
		payload: { cardId, isComparative }
	}
}

const saveCardTypeAction = (cardType) => {
	return {
		type: CARD_ACTION_TYPES.SAVE_CARD_TYPE_ACTION,
		payload: { cardType }
	}
}

const updateCardComparisonStatusAction = (newComparisonStatus) => {
	return {
		type: CARD_ACTION_TYPES.UPDATE_CARD_COMPARISON_STATUS_ACTION,
		payload: { newComparisonStatus }
	}
}

//Exports
export {
	CARD_ACTION_TYPES,
	addCardComparisonAction,
	removeCardComparisonAction,
	resetCardsComparisonAction,
	saveAllCardsCanOpenAction,
	addCardComparisonByIdsAction,
	saveCardTypeAction,
	updateCardComparisonStatusAction
}
