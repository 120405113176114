import React from 'react'
import { Grid, Card } from '@mui/material'
import NumericMetric from 'pages/AccountManagement/components/NumericMetric/NumericMetric'
import MetaAdsInfo from 'pages/AccountManagement/components/MetaAdsInfo/MetaAdsInfo'

const MetaAdsPostMetrics = ({ cardData }) => {
	const descriptions = cardData.descriptions

	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={12}>
					<MetaAdsInfo post={cardData?.post} />
				</Grid>

				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.clicks}
						title={descriptions.find((item) => item.key === 'meta_ads_clicks')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_clicks')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_clicks')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_15_sec_video_view}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_inline_post_engagement}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={6} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_page_engagement}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_page_engagement')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_post_engagement}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_post_engagement')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_unique_click}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_unique_click')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_unique_click')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_unique_click')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cost_per_video_view}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_video_view')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cpc}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_15_sec_video_view')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cpm}
						title={descriptions.find((item) => item.key === 'meta_ads_cpm')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cpm')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cpm')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.cpp}
						title={descriptions.find((item) => item.key === 'meta_ads_cpp')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cpp')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cpp')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.ctr}
						title={descriptions.find((item) => item.key === 'meta_ads_ctr')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_ctr')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_ctr')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>

				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.frequency}
						title={descriptions.find((item) => item.key === 'meta_ads_frequency')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_frequency')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_frequency')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions}
						title={descriptions.find((item) => item.key === 'meta_ads_impressions')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_impressions')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_impressions')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.inline_link_click_ctr}
						title={descriptions.find((item) => item.key === 'meta_ads_inline_link_click_ctr')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_inline_link_click_ctr')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_inline_link_click_ctr')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.inline_link_clicks}
						title={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_inline_link_clicks')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.inline_post_engagement}
						title={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_cost_per_inline_post_engagement')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.page_engagement}
						title={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_page_engagement')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.post_reactions}
						title={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_post_reactions')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.purchase_roas}
						title={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_purchase_roas')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.reach}
						title={descriptions.find((item) => item.key === 'meta_ads_reach')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_reach')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_reach')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.social_spend}
						title={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_social_spend')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.spend}
						title={descriptions.find((item) => item.key === 'meta_ads_spend')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_spend')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_spend')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_p25_watched_views}
						title={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_video_p25_watched_views')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_p100_watched_views}
						title={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_video_p100_watched_actions')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.video_views}
						title={descriptions.find((item) => item.key === 'meta_ads_video_views')?.title}
						description={descriptions.find((item) => item.key === 'meta_ads_video_views')?.description}
						dataType={descriptions.find((item) => item.key === 'meta_ads_video_views')?.data_type}
						source='metaAds'
						isOverview={true}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default MetaAdsPostMetrics
