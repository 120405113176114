import React, { useState } from 'react'
import { Drawer } from '@mui/material'
import './SideDrawer.css'
import ListItemsTab from './components/ListItemsTab/ListItemsTab'

const SideDrawer = ({ itemsToRender = [], onSelectItem, selectedValue }) => {
	const [open, setOpen] = useState(false)

	const handleDrawerOpen = () => setOpen(true)
	const handleDrawerClose = () => setOpen(false)

	return (
		<Drawer
			id='side-drawer'
			className='drawer-side-drawer'
			variant='persistent'
			anchor='left'
			open={open}
			classes={{
				paper: `paper-side-drawer ${!open && 'paper-side-drawer-closed'}`
			}}
			onMouseOver={handleDrawerOpen}
			onMouseLeave={handleDrawerClose}
			PaperProps={{
				style: {
					backgroundColor: 'rgba(255, 255, 255, 0.25)',
					boxShadow: 'none'
				}
			}}
		>
			<ListItemsTab itemsToRender={itemsToRender} onSelectItem={onSelectItem} selectedValue={selectedValue} />
		</Drawer>
	)
}

export default SideDrawer
