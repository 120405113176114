import React, { useState } from 'react'
import { Drawer, Button, Grid, IconButton, Collapse } from '@mui/material'
import { ChevronRightIcon, CloudDownloadIcon, DeleteBinIcon, CheckIcon, CloseIcon } from 'utils/SystemIcons'
const isFirefox = typeof InstallTrigger !== 'undefined'

const DashboardOption = ({ dashboard, disabled, toggleLoadMenu, onLayoutApply, onDeleteLayout }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const toggleConfirmDelete = () => {
		setTimeout(() => {
			setConfirmDelete(!confirmDelete)
		}, 300)
	}

	const handleConfirmClick = (e, dashboard) => {
		e.preventDefault()
		setTimeout(() => {
			setSubmitting(true)
			onDeleteLayout(dashboard.id)
		}, 300)
	}

	return (
		<Grid item xs={12}>
			<Collapse in={!submitting} timeout={300}>
				<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em', width: '28em' }}>
					<Button
						id={`btn-load-dashboard-${dashboard?.name}`}
						disabled={disabled}
						onClick={() => {
							onLayoutApply(dashboard)
							toggleLoadMenu()
						}}
						style={{
							backgroundColor: '#fff',
							borderRadius: '25px 0px 0px 25px',
							fontFamily: 'Poppins',
							width: `${confirmDelete ? '20.4em' : '25em'}`,
							display: 'flex',
							justifyContent: 'flex-start',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
					>
						<CloudDownloadIcon size={25} style={{ color: '#1d8cf8', marginLeft: '8px', marginRight: '10px' }} />
						{dashboard?.name}
					</Button>
					{!confirmDelete && (
						<Button
							id={`btn-delete-dashboard-${dashboard?.name}`}
							disabled={disabled}
							onClick={toggleConfirmDelete}
							style={{
								color: '#f50057',
								backgroundColor: '#fff',
								borderRadius: '0px 25px 25px 0px',
								fontFamily: 'Poppins',
								width: '2em'
							}}
						>
							<DeleteBinIcon size={25} style={{ color: '#f50057' }} />
						</Button>
					)}
					{confirmDelete && (
						<div style={{ width: '2em', display: 'flex' }}>
							<Button
								id={`btn-confirm-delete-dashboard-${dashboard?.name}`}
								disabled={disabled}
								onClick={(e) => handleConfirmClick(e, dashboard)}
								style={{
									color: '#1d8cf8',
									backgroundColor: '#fff',
									borderRadius: '0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CheckIcon size={25} style={{ color: '#1d8cf8' }} />
							</Button>
							<Button
								id={`btn-cancel-delete-dashboard-${dashboard?.name}`}
								disabled={disabled}
								onClick={toggleConfirmDelete}
								style={{
									color: '#f50057',
									backgroundColor: '#fff',
									borderRadius: '0px 25px 25px 0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CloseIcon size={25} style={{ color: '#f50057' }} />
							</Button>
						</div>
					)}
				</Grid>
			</Collapse>
		</Grid>
	)
}

const DashboardLoadMenu = ({ isOpen, toggleLoadMenu, savedLayoutsList, onLayoutApply, onDeleteLayout }) => {
	return (
		<Drawer
			anchor='right'
			open={isOpen}
			onClose={() => toggleLoadMenu()}
			className='right-drawer-system'
			BackdropProps={{
				timeout: 500,
				style: { backdropFilter: 'blur(3px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
			}}
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<IconButton id='btn-toggle-load-filter' color='primary' onClick={toggleLoadMenu}>
					<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
				</IconButton>
			</Grid>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						marginTop: '0.1em',
						paddingBottom: '1em',
						paddingLeft: '1em',
						paddingRight: '1em',
						maxHeight: '84vh',
						overflow: 'auto'
					}}
				>
					{savedLayoutsList.length > 0 &&
						savedLayoutsList.map((dashboard) => (
							<DashboardOption
								key={dashboard?.id}
								dashboard={dashboard}
								toggleLoadMenu={toggleLoadMenu}
								onLayoutApply={onLayoutApply}
								onDeleteLayout={onDeleteLayout}
							/>
						))}
					{savedLayoutsList.length === 0 && (
						<Grid
							item
							xs={12}
							style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Poppins', fontSize: '24px', color: '#d6d6d6' }}
						>
							salve um dashboard para ver aqui
						</Grid>
					)}
				</Grid>
			</div>
		</Drawer>
	)
}

export default DashboardLoadMenu
