import { Button, Grid } from '@mui/material'
import React, { useState, useEffect, memo } from 'react'
import { CheckIcon } from 'utils/SystemIcons'

const blue = '#1d8af8'
const grey = '#828282'

const CheckButtonGroup = ({
	style,
	checkedControlled,
	onChange,
	fontSize,
	atLeastOneMarked = false,
	uncheckedColor = 'rgba(29, 138, 248, 100%)'
}) => {
	const [checkObjects, setCheckObjects] = useState(checkedControlled)

	useEffect(() => {
		if (JSON.stringify(checkObjects) != JSON.stringify(checkedControlled)) {
			setCheckObjects(checkedControlled)
		}
	}, [checkedControlled])

	const onCheck = (checked, item) => {
		let newCheckObjects = checkObjects.map((it) => (it.label === item.label ? { ...item, checked } : it))
		if ((atLeastOneMarked && newCheckObjects.some((it) => it.checked)) || !atLeastOneMarked) {
			setCheckObjects(newCheckObjects)
			onChange(newCheckObjects)
		}
	}

	return (
		<Grid style={style} container>
			{checkObjects?.map((it) => (
				<Grid key={it.label} item xs={12 / checkObjects.filter((it) => !it.hide).length} style={{ display: it.hide ? 'none' : null }}>
					<Button
						id={`btn-classification-check-${it.label}`}
						onClick={() => onCheck(!it.checked, it)}
						style={{
							outline: 'none',
							height: '4em',
							width: '100%',
							borderRadius: '0%',
							fontFamily: 'Poppins',
							fontSize: 12,
							color: grey
						}}
					>
						<div style={{ color: it.checked && blue, alignItems: 'center', display: 'flex' }}>
							{it.checked && <CheckIcon size={15} style={{ color: blue, marginRight: '5px' }} />}
							<span style={{ fontSize, color: it.checked ? it.color : uncheckedColor }}>{it.label}</span>
						</div>
					</Button>
				</Grid>
			))}
		</Grid>
	)
}

export default memo(CheckButtonGroup)
