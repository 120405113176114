import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '@mui/material'

const NotificationsSkeletonLoad = () => (
	<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
		<Card
			style={{
				borderRadius: '20px',
				lineHeight: 'inherit',
				marginRight: '10px',
				marginLeft: '10px',
				marginBottom: '20px'
			}}
		>
			<Skeleton height='153px' width='100%' style={{ lineHeight: 'inherit' }} />
		</Card>
	</div>
)

export default NotificationsSkeletonLoad
