import React, { useState, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import { saveGlobalFiltersTermAction, resetGlobalFiltersTermAction } from 'store/redux/actions/global.actions'
import { Drawer, Button, Grid, Divider, styled, IconButton, Tooltip } from '@mui/material'
import { FilterIcon, ChevronRightIcon, CloudUploadIcon, CloudDownloadIcon } from 'utils/SystemIcons'
import ClassificationFilters from './components/ClassificationFilters/ClassificationFilters'
import ManifestationsGlobalFilters from './components/ManifestationsGlobalFilters/ManifestationsGlobalFilters'
import AdvancedGlobalFilters from './components/AdvancedGlobalFilters/AdvancedGlobalFilters'
import GroupSpecificGlobalFilters from './components/GroupSpecificGlobalFilters/GroupSpecificGlobalFilters'
import SaveFilter from './components/SaveFilter/SaveFilter'
import LoadFilter from './components/LoadFilter/LoadFilter'
import './GlobalFilters.css'
import StopWordsFilters from './components/StopWordsFilters/StopWordsFilters'
import TagsFilters from './components/TagsFilters/TagsFilters'
import AuraSightGlobalFilters from './components/AuraSightGlobalFilters/AuraSightGlobalFilters'
import constantsVox from 'constants-vox'

const isFirefox = typeof InstallTrigger !== 'undefined'

const StyledButton = styled(Button)({
	minWidth: '7em',
	color: 'rgb(82, 95, 127)',
	fontFamily: 'Poppins',
	fontWeight: '500'
})

const GlobalFilters = ({
	globalFiltersRedux,
	saveGlobalFiltersDispatch,
	resetGlobalFiltersDispatch,
	canShowGlobalFiltersRedux,
	cardType,
	userProductsRedux
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [newFilters, setNewFilters] = useState(globalFiltersRedux)
	const [openClassification, setOpenClassification] = useState(false)
	const [openManifestations, setOpenManifestations] = useState(false)
	const [openGroupFilter, setOpenGroupFilter] = useState(false)
	const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false)
	const [openAuraSightFilters, setOpenAuraSightFilters] = useState(false)
	const [openSaveMenu, setOpenSaveMenu] = useState(false)
	const [openLoadMenu, setOpenLoadMenu] = useState(false)
	const [openStopWords, setOpenStopWords] = useState(false)
	const [openTags, setOpenTags] = useState(false)

	const resetLastFilters = () => {
		setNewFilters(globalFiltersRedux)
	}

	useEffect(() => {
		setNewFilters(globalFiltersRedux)
	}, [])

	useEffect(() => {
		setNewFilters(globalFiltersRedux)
	}, [canShowGlobalFiltersRedux])

	useEffect(() => {
		if (openAdvancedFilters) {
			setOpenAdvancedFilters(false)
		}
	}, [newFilters.sources])

	if (!canShowGlobalFiltersRedux) {
		return null
	}

	const toggleDrawer = () => {
		if (isOpen) {
			setIsOpen(false)
		} else {
			setIsOpen(true)
			resetLastFilters()
		}
	}

	const saveFilters = () => {
		if (newFilters.source !== globalFiltersRedux.sources) {
			saveGlobalFiltersDispatch({
				...newFilters,
				advancedFilters: newFilters.advancedFilters
			})
		} else {
			saveGlobalFiltersDispatch(newFilters)
		}
		toggleDrawer()
	}

	const resetReduxFilters = () => {
		resetGlobalFiltersDispatch(true)
		toggleDrawer()
	}

	const onClassificationTypeChange = (newClassification) => {
		setNewFilters({ ...newFilters, classification: newClassification })
	}

	const onStopWordsChange = (isStopWordsActive) => {
		setNewFilters({ ...newFilters, isStopWordsActive })
	}

	const onImpactChange = (newImpacts) => {
		setNewFilters({ ...newFilters, classification: { ...newFilters.classification, newImpacts } })
	}

	const onTagsGroupChange = (newTags) => {
		setNewFilters({ ...newFilters, groupTags: newTags })
	}

	const onTagsChange = (newTags) => {
		setNewFilters({ ...newFilters, tagsGlobalFilters: newTags })
	}

	const onManifestationsChange = (newManifestations) => {
		let formattedManifestations = {
			higher: newManifestations.higher,
			lower: newManifestations.lower
		}
		setNewFilters({ ...newFilters, manifestations: formattedManifestations })
	}

	const onSpecificGlobalFiltersChange = (newSpecific) => {
		setNewFilters({ ...newFilters, advancedFilters: newSpecific })
	}

	const onAuraSightGlobalFiltersChange = (newAuraSightFilters) => {
		setNewFilters({ ...newFilters, aurasightFilters: newAuraSightFilters })
	}

	const toggleClassification = () => {
		setOpenClassification(!openClassification)
	}
	const toggleStopWords = () => {
		setOpenStopWords(!openStopWords)
	}
	const toggleTags = () => {
		setOpenTags(!openTags)
	}

	const toggleManifestations = () => {
		setOpenManifestations(!openManifestations)
	}

	const toggleAdvancedFilters = () => {
		setOpenAdvancedFilters(!openAdvancedFilters)
	}

	const toggleAuraSightFilters = () => {
		setOpenAuraSightFilters(!openAuraSightFilters)
	}

	const toggleGroupFilter = () => {
		setOpenGroupFilter(!openGroupFilter)
	}

	const toggleSaveMenu = () => {
		setOpenSaveMenu(!openSaveMenu)
	}

	const toggleLoadMenu = () => {
		setOpenLoadMenu(!openLoadMenu)
	}

	const loadSavedFilter = (newFilter) => {
		saveGlobalFiltersDispatch({ ...newFilter, sources: globalFiltersRedux.sources })
		toggleDrawer()
	}

	const list = () => (
		<>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<Grid item xs={6}>
					<IconButton id='btn-toggle-filters' color='primary' onClick={toggleDrawer}>
						<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
					</IconButton>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Tooltip title={'carregar filtros'} placement='top' enterDelay={100} enterNextDelay={100}>
						<IconButton id='btn-download-filters' color='primary' onClick={toggleLoadMenu} style={{ marginRight: '0.6em' }}>
							<CloudDownloadIcon size={25} style={{ color: '#1d8cf8' }} />
						</IconButton>
					</Tooltip>
					<Tooltip title={'salvar filtros'} placement='top' enterDelay={100} enterNextDelay={100}>
						<IconButton id='btn-upload-filters' color='primary' onClick={toggleSaveMenu} style={{ marginRight: '0.6em' }}>
							<CloudUploadIcon size={25} style={{ color: '#1d8cf8' }} />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid
				container
				style={{
					paddingBottom: '1em',
					paddingLeft: '1em',
					paddingRight: '1em',
					width: '46em',
					maxHeight: window.innerHeight - 50,
					overflow: 'auto'
				}}
			>
				{cardType === 'term' && (
					<>
						<ClassificationFilters
							toggleClassification={toggleClassification}
							openClassification={openClassification}
							onClassificationTypeChange={onClassificationTypeChange}
							newFilters={newFilters}
							onImpactChange={onImpactChange}
						/>
						<StopWordsFilters
							toggleStopWords={toggleStopWords}
							openStopWords={openStopWords}
							onStopWordsChange={onStopWordsChange}
							newFilters={newFilters}
						/>
						<TagsFilters toggleTags={toggleTags} openTags={openTags} onTagsChange={onTagsChange} newFilters={newFilters} />
					</>
				)}

				<ManifestationsGlobalFilters
					onChange={onManifestationsChange}
					valueControlled={newFilters.manifestations}
					toggleManifestations={toggleManifestations}
					openManifestations={openManifestations}
				/>

				{cardType === 'term' && (
					<>
						<AdvancedGlobalFilters
							show={true}
							onChange={(newAdvancedFilters) => onSpecificGlobalFiltersChange(newAdvancedFilters)}
							valueControlled={newFilters.advancedFilters}
							toggleAdvancedFilters={toggleAdvancedFilters}
							openAdvancedFilters={openAdvancedFilters}
						/>
					</>
				)}

				{cardType === 'group' && (
					<>
						<GroupSpecificGlobalFilters
							tags={newFilters.groupTags}
							onTagsGroupChange={onTagsGroupChange}
							toggleGroupFilter={toggleGroupFilter}
							openGroupFilter={openGroupFilter}
						/>
					</>
				)}

				{cardType === 'term' && userProductsRedux?.find((it) => it === constantsVox.PRODUCT_TYPES.AURASIGHT_MANAGER) && (
					<AuraSightGlobalFilters
						onChange={(newAuraSightFilters) => onAuraSightGlobalFiltersChange(newAuraSightFilters)}
						valueControlled={newFilters.aurasightFilters}
						toggleFilters={toggleAuraSightFilters}
						openFilters={openAuraSightFilters}
					/>
				)}
			</Grid>
			<Grid container style={{ width: '46em', padding: '0.5em' }}>
				<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
				<Grid
					xs={6}
					item
					style={{ marginTop: '2em', paddingBottom: '4em' }}
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='center'
				>
					<Button
						id='btn-apply-filters'
						fullWidth
						variant='outlined'
						onClick={saveFilters}
						color='primary'
						style={{
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							color: '#1d8cf8',
							borderColor: '#1d8cf8',
							borderRadius: '100px',
							marginRight: '10px'
						}}
					>
						Aplicar filtros
					</Button>
				</Grid>

				<Grid
					xs={6}
					item
					style={{ marginTop: '2em', paddingBottom: '4em' }}
					container
					direction='row'
					justifyContent='flex-end'
					alignItems='center'
				>
					<Button
						id='btn-reset-filters'
						fullWidth
						variant='outlined'
						onClick={resetReduxFilters}
						color='primary'
						style={{
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							color: '#1d8cf8',
							borderColor: '#1d8cf8',
							borderRadius: '100px',
							marginLeft: '10px'
						}}
					>
						Limpar Filtros
					</Button>
				</Grid>
			</Grid>
		</>
	)

	return (
		<>
			<StyledButton id='btn-all-filters' size='small' onClick={() => toggleDrawer()} style={{ marginLeft: '15px' }}>
				<FilterIcon size={20} color={'#1d8cf8'} style={{ marginRight: '5px' }} />
				filtrar
			</StyledButton>
			<Drawer
				anchor='right'
				open={isOpen}
				onClose={() => toggleDrawer()}
				className='right-drawer-system'
				BackdropProps={{
					timeout: 500,
					style: { backdropFilter: 'blur(10px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
				}}
				PaperProps={{
					style: {
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				{list()}
			</Drawer>
			<SaveFilter isOpen={openSaveMenu} toggleSaveMenu={toggleSaveMenu} type={cardType} config={newFilters} />
			<LoadFilter
				isOpen={openLoadMenu}
				toggleLoadMenu={toggleLoadMenu}
				type={cardType}
				loadSavedFilter={loadSavedFilter}
				openSaveMenu={openSaveMenu}
			/>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({
	saveGlobalFiltersDispatch: (newFilters) => dispatch(saveGlobalFiltersTermAction(newFilters)),
	resetGlobalFiltersDispatch: (keepSource) => dispatch(resetGlobalFiltersTermAction(keepSource))
})

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters,
	canShowGlobalFiltersRedux:
		store.card.comparison.type === 'term' || store.card.comparison.type === 'profile' || store.card.comparison.type === 'group',
	cardType: store.card.comparison.type,
	userProductsRedux: store.user.product
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(GlobalFilters))
