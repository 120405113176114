import React from 'react'
import { EyeIcon, MessageIcon, SettingsIcon, UsersIcon, UserIcon, GroupIcon, AddressCardIcon, FinancialIcon } from 'utils/SystemIcons'

const getAndFormatCardsNames = (cards) => {
	return cards.map((card) => (card.name.length > 11 ? card.name.substr(0, 11) + '...' : card.name)).join(', ')
}

const findNavigationBreadcrumbs = (routeName, cards) => {
	switch (routeName) {
		case '/monitoring/sentiment-analysis':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring'
				},
				{
					label: 'Temas',
					icon: <MessageIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring',
					pathState: { cardType: 'term' }
				},
				{
					label: getAndFormatCardsNames(cards),
					icon: <AddressCardIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/profile':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring'
				},
				{
					label: 'Meu Perfil',
					icon: <SettingsIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/sub-users':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} style={{ marginTop: '-0.2em' }} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring'
				},
				{
					label: 'Sub Usuários',
					icon: <UsersIcon size={16} style={{ marginTop: '-0.2em' }} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/monitoring/social-profile':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring'
				},
				{
					label: 'Contas',
					icon: <UserIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring',
					pathState: { cardType: 'profile' }
				},
				{
					label: getAndFormatCardsNames(cards),
					icon: <AddressCardIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/monitoring/accounts-group':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring'
				},
				{
					label: 'Grupos',
					icon: <GroupIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/monitoring',
					pathState: { cardType: 'group' }
				},
				{
					label: getAndFormatCardsNames(cards),
					icon: <AddressCardIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/financial':
			return [
				// {
				// 	label: 'Home',
				// 	icon: <HomeIcon size={16} className='fix-icon-margin-bottom-negative' />,
				// 	path: '/products',
				// 	isLast: false
				// },
				{
					label: 'Financeiro',
					icon: <FinancialIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/financial',
					isLast: true
				}
			]
		case 'Monitoramento':
			return [
				{
					label: 'Monitoramento',
					icon: <EyeIcon size={16} style={{ marginTop: '-0.2em' }} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		case '/account-management/social-profile':
			return [
				{
					label: 'Minhas contas',
					icon: <UserIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: '/account-management'
				},
				{
					label: getAndFormatCardsNames(cards),
					icon: <AddressCardIcon size={16} className='fix-icon-margin-bottom-negative' />,
					path: null,
					isLast: true
				}
			]
		default:
			break
	}
}

export { findNavigationBreadcrumbs }
