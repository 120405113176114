import React, { memo } from 'react'
import NetworkSummarySkeletonLoad from 'components/SkeletonLoads/NetworkSummarySkeletonLoad/NetworkSummarySkeletonLoad'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { Grid, Card } from '@mui/material'
import { NetworkIcon } from 'utils/SystemIcons'

const NetworkSummaryProp = ({ isReport, isGraphComponent }) => {
	return (
		<Card elevation={isReport ? 0 : 1} style={{ borderRadius: '20px', width: '100%' }}>
			<Grid container spacing={3} style={!isReport ? { background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' } : {}}>
				<GraphTitle
					isReport={isReport}
					title={'Top 10 Influenciadores'}
					icon={
						<NetworkIcon
							size={30}
							style={{
								color: '#1d8cf8'
							}}
						/>
					}
					description={`
						Os principais Influenciadores para redes sociais e fontes de notícias
					`}
				/>
			</Grid>
			<Grid style={{ marginLeft: '10px', marginRight: '10px', marginTop: '20px' }}>
				<div style={!isGraphComponent && isReport ? { backgroundColor: 'white', borderRadius: '4px' } : { borderRadius: '4px' }}>
					<div style={{ textAlign: 'center' }}>
						<NetworkSummarySkeletonLoad />
					</div>
				</div>
			</Grid>
		</Card>
	)
}

export default memo(NetworkSummaryProp)
