import React, { useEffect, useState, useRef, memo } from 'react'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import AnimatedNumber from 'react-animated-number'
import { CircularProgress, styled } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { UserFillIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const coinColor = '#3274fc'

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		borderRadius: '20px',
		backdropFilter: 'blur(10px)',
		backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#1d8cf8'
	}
}))

const nFormatter = (num) => {
	let isNegative = num < 0
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'QUA' },
		{ value: 1e18, symbol: 'QUI' }
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	let item = lookup
		.slice()
		.reverse()
		.find((item) => {
			let number = isNegative ? -num : num
			return number >= item.value
		})
	return item ? (num / item.value).toFixed(item.value === 1 ? 0 : 1).replace(rx, '$1') + item.symbol : '0'
}

const CreditsProgress = ({ value, maxValue }) => {
	const [thisValue, setThisValue] = useState(0)
	const isMounted = useRef(true)

	useEffect(() => {
		setThisValue(value)
		return () => {
			isMounted.current = false
		}
	}, [value])

	return (
		<>
			<div style={{ margin: '1em' }}>
				<div style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#878787', fontSize: '1.9em' }}>Contas restantes</div>
				<div
					style={{
						marginTop: '1em',
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{thisValue >= 0 ? (
						<CircularProgress
							size={40}
							thickness={6}
							variant='determinate'
							value={(thisValue / maxValue) * 100}
							style={{ color: 'rgba(29, 140, 248,0.6)' }}
						/>
					) : (
						<CircularProgress
							size={40}
							thickness={6}
							variant='determinate'
							value={(thisValue / maxValue) * 100}
							style={{ color: '#FF6347' }}
						/>
					)}
					<div style={{ marginLeft: '10px', fontFamily: 'Poppins', fontWeight: 'bold', color: '#878787', fontSize: '17px' }}>
						{thisValue} / {maxValue}
					</div>
				</div>
				<div
					style={{
						fontSize: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						fontFamily: 'Poppins',
						fontWeight: 'bold',
						color: '#878787',
						letterSpacing: '0.1em',
						maxWidth: '18em',
						marginTop: '2em'
					}}
				>
					Você libera espaço ao desativar uma conta ativa (pausar/deletar)
				</div>
			</div>
		</>
	)
}

const ProfilesCountGauge = () => {
	let { data } = useUpdateData({
		url: '/credits/get-profiles-count-info',
		updateItemName: 'termsCountInfo',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: true,
		queryName: 'profilesCountInfo'
	})

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [data])

	const isMounted = useRef(true)

	let value = data?.remaining || 0
	let maxValue = data?.max || 0

	return (
		<HtmlTooltip title={<CreditsProgress value={value} maxValue={maxValue} />} arrow placement='bottom' enterDelay={200} enterNextDelay={200}>
			<div
				style={{
					display: 'flex',
					alignContent: 'center',
					alignItems: 'center',
					marginRight: '3em',
					marginTop: '0em',
					cursor: 'pointer'
				}}
			>
				<UserFillIcon size={20} style={{ color: value < 0 ? '#DC143C' : coinColor, marginRight: '0.1em' }} />
				<AnimatedNumber
					id='span-credits'
					value={value}
					style={{
						fontFamily: 'Poppins',
						fontWeight: 'bold',
						color: value < 0 ? '#DC143C' : coinColor,
						fontSize: '17px'
					}}
					duration={1000}
					formatValue={(n) => nFormatter(n)}
					frameStyle={(percentage) => (percentage > 20 && percentage < 80 ? { opacity: '0.4' } : { opacity: '1' })}
				/>
			</div>
		</HtmlTooltip>
	)
}
export default memo(ProfilesCountGauge)
