import dayjs from 'dayjs'
const now = dayjs()

export { DEFAULT_OCCURRENCE, DEFAULT_OCCURRENCE_OPTIONAL, DEFAULT_EXCLUSIVE }

//oq for '' e [] passar pra null na hora de enviar pro processamento
const DEFAULT_OCCURRENCE = [
	//x
	{ key: 'author_username', value: '', name: 'Nome da conta*', type: 'text' },
	{ key: 'content_text', value: '', name: 'Conteúdo em texto da postagem*', type: 'text' },
	{ key: 'content_posted_at', value: now, name: 'Data da publicação*', type: 'date' },
	{ key: 'content_posted_at_hour', value: '00:00:00', name: 'Hora da publicação', type: 'dateHour' }
]
const DEFAULT_OCCURRENCE_OPTIONAL = [
	{ key: 'author_name', value: '', name: 'Nome do usuário', type: 'text' },
	{ key: 'content_url', value: '', name: 'Url', type: 'text' },
	{ key: 'content_attachments', value: null, name: 'Anexar um arquivo', type: 'file' } //sempre um só
]

const DEFAULT_EXCLUSIVE = {
	twitter: [
		{ key: 'content_title', value: '', name: 'Título da Notícia', type: 'text' },
		{ key: 'content_engagement_public_like_count', value: 0, name: 'Curtidas', type: 'number' },
		{ key: 'content_engagement_public_comment_count', value: 0, name: 'Respostas', type: 'number' },
		{ key: 'content_engagement_public_share_count', value: 0, name: 'Repostagens', type: 'number' },
		{ key: 'content_engagement_public_quote_count', value: 0, name: 'Citações', type: 'number' }
	],
	facebook: [
		{ key: 'content_title', value: '', name: 'Título da Postagem', type: 'text' },
		{ key: 'content_engagement_public_like_count', value: 0, name: 'Curtidas', type: 'number' },
		{ key: 'content_engagement_public_comment_count', value: 0, name: 'Respostas', type: 'number' },
		{ key: 'content_engagement_public_share_count', value: 0, name: 'Compartilhamentos', type: 'number' },
		{ key: 'content_engagement_public_love_count', value: 0, name: 'Amei', type: 'number' },
		{ key: 'content_engagement_public_angry_count', value: 0, name: 'Grr', type: 'number' },
		{ key: 'content_engagement_public_haha_count', value: 0, name: 'Haha', type: 'number' },
		{ key: 'content_engagement_public_sad_count', value: 0, name: 'Triste', type: 'number' },
		{ key: 'content_engagement_public_wow_count', value: 0, name: 'Wow', type: 'number' }
	],
	instagram: [
		{ key: 'content_title', value: '', name: 'Título da Notícia', type: 'text' },
		{ key: 'content_engagement_public_like_count', value: 0, name: 'Curtidas', type: 'number' },
		{ key: 'content_engagement_public_comment_count', value: 0, name: 'Respostas', type: 'number' },
		{ key: 'content_engagement_public_view_count', value: 0, name: 'Visualizações de vídeo', type: 'number' }
	],
	tiktok: [
		{ key: 'content_title', value: '', name: 'Título da Notícia', type: 'text' },
		{ key: 'content_engagement_public_like_count', value: 0, name: 'Curtidas', type: 'number' },
		{ key: 'content_engagement_public_comment_count', value: 0, name: 'Comentários', type: 'number' },
		{ key: 'content_engagement_public_share_count', value: 0, name: 'Compartilhamentos', type: 'number' },
		{ key: 'content_engagement_public_view_count', value: 0, name: 'Visualizações de vídeo', type: 'number' }
	],
	youtube: [
		{ key: 'content_title', value: '', name: 'Título da Postagem', type: 'text' },
		{ key: 'content_engagement_public_like_count', value: 0, name: 'Curtidas', type: 'number' },
		{ key: 'content_engagement_public_comment_count', value: 0, name: 'Comentários', type: 'number' },
		{ key: 'content_engagement_public_view_count', value: 0, name: 'Visualizações de vídeo', type: 'number' }
	],
	news: [{ key: 'content_title', value: '', name: 'Título da Notícia', type: 'text' }]
}
