import React, { useState, useEffect, memo } from 'react'
import AddAnalysis from '../../../../../../../components/AddAnalysis/AddAnalysis'
// import GraficoImpacto from 'graphs/GraficoImpacto/GraficoImpacto'
import GraficoNowcasting from 'graphs/GraficoNowcasting/components/GraficoImpactoNowcasting/GraficoImpactoNowcasting'

import './AnalysisContentBodyAccount.css'

const defaultOptions = {
	type: 'engagement',
	engagement: true,
	social: false,
	posts: false,
	gender: false,
	users: false,
	men: false,
	women: false,
	noClass: false,
	politics: false,
	left: false,
	right: false,
	bolsonarist: false,
	notPolarized: false,
	comments: false,
	retweets: false
}

const graficoTweetcasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'twitter' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoTweetcastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'twitter' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoFacecasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'facebook' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoFacecastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'facebook' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoInstacasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'instagram' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoInstacastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'instagram' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoTiktokcasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'tiktok' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoTiktokcastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'tiktok' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)

const sentimentAnalysisGraphs = (
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts
) => {
	let graphs = []
	if (timelineTwitterEngagement) {
		graphs.push({
			name: 'Engajamento Twitter',
			graph: graficoTweetcasting,
			source: 'twitter'
		})
	}
	if (timelineTwitterPosts) {
		graphs.push({
			name: 'Postagens Twitter',
			graph: graficoTweetcastingPosts,
			source: 'twitter'
		})
	}
	if (timelineFacebookEngagement) {
		graphs.push({
			name: 'Engajamento Facebook',
			graph: graficoFacecasting,
			source: 'facebook'
		})
	}
	if (timelineFacebookPosts) {
		graphs.push({
			name: 'Postagens Facebook',
			graph: graficoFacecastingPosts,
			source: 'facebook'
		})
	}
	if (timelineInstagramEngagement) {
		graphs.push({
			name: 'Engajamento Instagram',
			graph: graficoInstacasting,
			source: 'instagram'
		})
	}
	if (timelineInstagramPosts) {
		graphs.push({
			name: 'Postagens Instagram',
			graph: graficoInstacastingPosts,
			source: 'instagram'
		})
	}
	if (timelineTiktokEngagement) {
		graphs.push({
			name: 'Engajamento Tiktok',
			graph: graficoTiktokcasting,
			source: 'tiktok'
		})
	}
	if (timelineTiktokPosts) {
		graphs.push({
			name: 'Postagens Tiktok',
			graph: graficoTiktokcastingPosts,
			source: 'tiktok'
		})
	}
	return graphs
}

const addAnalysis = (
	<div style={{ marginTop: '3em' }}>
		<AddAnalysis />
	</div>
)

const renderGraphs = (
	graphs,
	cards,
	filters,
	viewType,
	graphInterval,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts
) => {
	if (graphs.length === 0) {
		return null
	}

	return (
		<>
			{graphs.map((objGraph, index) => (
				<div key={index + 'render'}>
					<h3 style={{ marginTop: '4em', color: '#000' }}>{objGraph.name}</h3>
					<div className={viewType === 'horizontal' ? 'break-print' : index % 2 ? 'break-print' : ''}>
						{objGraph.subName && <h4 style={{ marginTop: '1em', color: '#000' }}>{objGraph.subName}</h4>}
						{objGraph?.graph(
							cards,
							filters,
							graphInterval,
							timelineTwitterEngagement,
							timelineFacebookEngagement,
							timelineInstagramEngagement,
							timelineTiktokEngagement,
							timelineTwitterPosts,
							timelineFacebookPosts,
							timelineInstagramPosts,
							timelineTiktokPosts
						)}
						{addAnalysis}
					</div>
				</div>
			))}
		</>
	)
}

const AnalysisContentBodyAccount = ({
	cards,
	partialLoad,
	filters,
	viewType,
	graphInterval,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts
}) => {
	const [listGraphs, setListGraphs] = useState([])
	const [sources, setSources] = useState(partialLoad)

	useEffect(() => {
		setSources(partialLoad)
	}, [partialLoad])

	useEffect(() => {
		ListGraphs()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sources])

	const ListGraphs = async () => {
		const verifiedGraphs = sentimentAnalysisGraphs(
			timelineTwitterEngagement,
			timelineFacebookEngagement,
			timelineInstagramEngagement,
			timelineTiktokEngagement,
			timelineTwitterPosts,
			timelineFacebookPosts,
			timelineInstagramPosts,
			timelineTiktokPosts
		)
		let alertGraphs = verifiedGraphs.filter((it) => sources[it.source])
		setListGraphs(alertGraphs)
	}

	return (
		<div className='analysis-body-container'>
			{renderGraphs(
				listGraphs,
				cards,
				filters,
				viewType,
				graphInterval,
				timelineTwitterEngagement,
				timelineFacebookEngagement,
				timelineInstagramEngagement,
				timelineTiktokEngagement,
				timelineTwitterPosts,
				timelineFacebookPosts,
				timelineInstagramPosts,
				timelineTiktokPosts
			)}
		</div>
	)
}

export default memo(AnalysisContentBodyAccount)
