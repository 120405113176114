import React, { useState, useEffect } from 'react'
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder'
import MaterialConfig from 'react-awesome-query-builder/lib/config/mui'
import { FormHelperText } from '@mui/material'

let InitialConfig = MaterialConfig

import 'react-awesome-query-builder/lib/css/styles.css'
import './QueryBuilder.css'

const excludeOperators = ['proximity', 'some', 'all', 'not_like', 'starts_with', 'ends_with', 'like', 'is_not_empty', 'is_empty', 'not_equal']

const buildConfigDefault = (
	config,
	isTerm,
	isNotification,
	hasTwitterSearch,
	hasFacebookSearch,
	hasInstagramSearch,
	hasTiktokSearch,
	hasYoutubeSearch
) => {
	const searchFields = {
		termField: {
			excludeOperators,
			label: 'Termo',
			type: 'text',
			fieldSettings: {
				// validateValue: (val, _fieldSettings) => {
				validateValue: (val) => {
					if (val.trim().length === 0) {
						return 'Não pode conter apenas espaços'
					} else if (val.length > 2) {
						return null
					} else {
						return 'Deve ter no minimo 3 caracteres'
					}
				}
			}
		},
		hashtagField: {
			excludeOperators,
			label: '# Hashtag',
			type: 'text',
			fieldSettings: {
				validateValue: (val) => {
					if (val.trim().length === 1 && val.charAt(0) == '#') {
						return 'Não pode conter apenas espaços'
					} else if (val?.split('')[0] == '#' && val.length > 2) {
						return null
					} else {
						return 'Deve começar com # e ter no minimo 3 caracteres'
					}
				}
			}
		},
		AccountField: {
			excludeOperators,
			label: '@ Menção',
			type: 'text',
			fieldSettings: {
				validateValue: (val) => {
					if (val.trim().length === 1 && val.charAt(0) == '@') {
						return 'Não pode conter apenas espaços'
					} else if (val?.split('')[0] == '@' && val.length > 2) {
						return null
					} else {
						return 'Deve começar com @ e ter no minimo 3 caracteres'
					}
				}
			}
		}
	}

	const hashtagField = {
		excludeOperators,
		label: '# Hashtag',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '#') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '#' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com # e ter no minimo 3 caracteres'
				}
			}
		}
	}

	const twitterAccountField = {
		excludeOperators,
		label: '@ Menção Twitter',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '@') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '@' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com @ e ter no minimo 3 caracteres'
				}
			}
		}
	}

	const facebookPageField = {
		excludeOperators,
		label: '@ Menção Facebook',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '@') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '@' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com @ e ter no minimo 3 caracteres'
				}
			}
		}
	}

	const instagramAccountField = {
		excludeOperators,
		label: '@ Menção Instagram',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '@') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '@' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com @ e ter no minimo 3 caracteres'
				}
			}
		}
	}

	const tiktokAccountField = {
		excludeOperators,
		label: '@ Menção Tiktok',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '@') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '@' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com @ e ter no minimo 3 caracteres'
				}
			}
		}
	}

	const youtubeAccountField = {
		excludeOperators,
		label: '@ Menção Youtube',
		type: 'text',
		fieldSettings: {
			validateValue: (val) => {
				if (val.trim().length === 1 && val.charAt(0) == '@') {
					return 'Não pode conter apenas espaços'
				} else if (val?.split('')[0] == '@' && val.length > 2) {
					return null
				} else {
					return 'Deve começar com @ e ter no minimo 3 caracteres'
				}
			}
		}
	}

	let termfields = {
		termField: {
			excludeOperators,
			label: 'Termo',
			type: 'text',
			fieldSettings: {
				validateValue: (val) => {
					if (!/^[a-zA-Z0-9 \u00C0-\u00FF]*$/.test(val)) {
						return 'Não pode conter caracteres especiais'
					} else if (val.trim().length === 0) {
						return 'Não pode conter apenas espaços'
					} else if (val.length > 2) {
						return null
					} else {
						return 'Deve ter no minimo 3 caracteres'
					}
				}
			}
		}
	}

	if (hasTwitterSearch) {
		termfields.twitterAccountField = twitterAccountField
		termfields.hashtagField = hashtagField
	}

	if (hasFacebookSearch) {
		termfields.facebookPageField = facebookPageField
		if (!termfields.hashtagField) {
			termfields.hashtagField = hashtagField
		}
	}

	if (hasInstagramSearch) {
		termfields.instagramAccountField = instagramAccountField
		if (!termfields.hashtagField) {
			termfields.hashtagField = hashtagField
		}
	}

	if (hasTiktokSearch) {
		termfields.tiktokAccountField = tiktokAccountField
		if (!termfields.hashtagField) {
			termfields.hashtagField = hashtagField
		}
	}

	if (hasYoutubeSearch) {
		termfields.youtubeAccountField = youtubeAccountField
		if (!termfields.hashtagField) {
			termfields.hashtagField = hashtagField
		}
	}

	const notificationFields = {
		wordField: {
			excludeOperators,
			label: 'Apareça a palavra:',
			type: 'text',
			fieldSettings: {
				// validateValue: (val, _fieldSettings) => {
				validateValue: (val) => {
					if (val.length > 2) {
						return null
					} else {
						return 'Deve ter no minimo 3 caracteres'
					}
				}
			}
		},
		engagementField: {
			excludeOperators,
			label: 'O engajamento alcance o valor de:',
			type: 'number'
		},
		postsField: {
			excludeOperators,
			label: 'Os posts alcancem um valor de:',
			type: 'number',
			fieldSettings: {
				validateValue: (val) => {
					if (val > 0) {
						return null
					} else {
						return 'Deve ter no minimo 3 caracteres'
					}
				}
			}
		}
	}

	let fields

	if (isTerm) {
		fields = termfields
	} else if (isNotification) {
		fields = notificationFields
	} else {
		fields = searchFields
	}

	return { ...config, fields }
}

const queryValueDefault = { id: QbUtils.uuid(), type: 'group' }

const loadQuery = (query, config) => {
	if (query === null) {
		return QbUtils.checkTree(QbUtils.loadTree(queryValueDefault), config)
	} else if (JSON.stringify(query) !== '{}' && query !== undefined) {
		const logic = QbUtils.loadFromJsonLogic(query, config)
		const tree = QbUtils.checkTree(logic, config)
		return tree
	}
}

const translateQuery = (query, isTerm, isNotification) => {
	if (query !== undefined) {
		let newStr
		newStr = query.toString()
		if (isTerm) {
			newStr = newStr.replaceAll('AND', 'E')
			newStr = newStr.replaceAll('OR', 'OU')
			newStr = newStr.replaceAll('termField', 'termo')
			newStr = newStr.replaceAll('hashtagField', 'hashtag')
			newStr = newStr.replaceAll('twitterAccountField', 'Conta do Twitter')
			newStr = newStr.replaceAll('facebookPageField', 'Conta do Facebook')
			newStr = newStr.replaceAll('instagramAccountField', 'Conta do Instagram')
			newStr = newStr.replaceAll('tiktokAccountField', 'Conta do Tiktok')
			newStr = newStr.replaceAll('youtubeAccountField', 'Conta do Youtube')
		} else if (isNotification) {
			newStr = newStr.replaceAll('AND', 'E')
			newStr = newStr.replaceAll('OR', 'OU')
			newStr = newStr.replaceAll('wordField', 'palavra')
			newStr = newStr.replaceAll('engagementField', 'engajamento')
			newStr = newStr.replaceAll('postsField', 'posts')
		} else {
			newStr = newStr.replaceAll('termField = ', '')
			newStr = newStr.replaceAll('hashtagField = ', '')
			newStr = newStr.replaceAll('AccountField = ', '')
			newStr = newStr.replace(/'/g, '"')
		}

		return newStr
	}
}

const QueryBuilder = ({
	getSearchTermsOnAdd = null,
	jsonQuery = null,
	error,
	createCard = false,
	onChangeLabel = null,
	isNotification = false,
	hasTwitterSearch = false,
	hasFacebookSearch = false,
	hasInstagramSearch = false,
	hasTiktokSearch = false,
	hasYoutubeSearch = false,
	onChangeQuery = null,
	justOldFormatQuery = false
}) => {
	const [tree, setTree] = useState(null)
	const [jsonTree, setjsonTree] = useState({})
	const [thisError, setThisError] = useState(error)
	const [queryText, setQueryText] = useState({ label: '', color: '#1d8cf8' })
	const [configDefault, setConfigDefault] = useState(
		buildConfigDefault(
			InitialConfig,
			createCard,
			isNotification,
			hasTwitterSearch,
			hasFacebookSearch,
			hasInstagramSearch,
			hasTiktokSearch,
			hasYoutubeSearch
		)
	)

	useEffect(() => {
		if (onChangeLabel) {
			onChangeLabel(queryText.label)
		}
	}, [queryText.label])

	useEffect(() => {
		if (onChangeQuery) {
			onChangeQuery(jsonTree)
		}
	}, [jsonTree])

	useEffect(() => {
		InitialConfig.conjunctions.AND.label = 'E'
		InitialConfig.conjunctions.OR.label = 'OU'
		InitialConfig.settings.addGroupLabel = 'Agrupamento'
		InitialConfig.settings.addRuleLabel = 'Termo'
		InitialConfig.settings.fieldPlaceholder = 'Tipo'
		InitialConfig.settings.renderSize = 'large'
		InitialConfig.settings.showErrorMessage = true
		InitialConfig.settings.canReorder = true
		InitialConfig.settings.canLeaveEmptyGroup = false
		InitialConfig.settings.renderOperator = () => null
		InitialConfig.settings.renderValueSources = () => null
		InitialConfig.widgets.text.valuePlaceholder = 'Escreva o nome'
		if (createCard) {
			InitialConfig.settings.showNot = false
			InitialConfig.settings.maxNesting = 2
			InitialConfig.settings.canRegroup = false
		} else if (isNotification) {
			InitialConfig.settings.addRuleLabel = 'Nova regra'
			InitialConfig.settings.fieldPlaceholder = 'Caso:'
			InitialConfig.settings.showNot = false
			InitialConfig.settings.maxNesting = 0
			InitialConfig.settings.canRegroup = false
			InitialConfig.widgets.text.valuePlaceholder = 'Palavra'
			InitialConfig.widgets.number.valuePlaceholder = 'Valor'
			InitialConfig.settings.maxNumberOfRules = 4
		} else {
			InitialConfig.settings.notLabel = 'Não'
			InitialConfig.settings.showNot = true
			InitialConfig.settings.canRegroup = true
		}
		setTree(
			loadQuery(
				jsonQuery,
				buildConfigDefault(
					InitialConfig,
					createCard,
					isNotification,
					hasTwitterSearch,
					hasFacebookSearch,
					hasInstagramSearch,
					hasTiktokSearch,
					hasYoutubeSearch
				)
			)
		)
		setConfigDefault(
			buildConfigDefault(
				InitialConfig,
				createCard,
				isNotification,
				hasTwitterSearch,
				hasFacebookSearch,
				hasInstagramSearch,
				hasTiktokSearch,
				hasYoutubeSearch
			)
		)
	}, [])

	useEffect(() => {
		if (getSearchTermsOnAdd !== null) {
			getSearchTermsOnAdd(jsonTree)
		}
	}, [jsonTree])

	useEffect(() => {
		setThisError(error)
	}, [error])

	useEffect(() => {
		if (jsonQuery) {
			setTree(
				loadQuery(
					jsonQuery,
					buildConfigDefault(
						InitialConfig,
						createCard,
						isNotification,
						hasTwitterSearch,
						hasFacebookSearch,
						hasInstagramSearch,
						hasTiktokSearch,
						hasYoutubeSearch
					)
				)
			)
		}
	}, [jsonQuery])

	const renderBuilder = (props) => (
		<div className='query-builder-container' style={{ padding: '10px' }}>
			<div className='query-builder qb-lite'>
				<Builder {...props} />
			</div>
		</div>
	)

	const onChange = (immutableTree) => {
		setTree(immutableTree)
		let jsonLogicFormat = QbUtils.jsonLogicFormat(immutableTree, configDefault).logic
		let elasticSearchFormat = QbUtils.elasticSearchFormat(immutableTree, configDefault)
		let sqlFormat = QbUtils.sqlFormat(immutableTree, configDefault)
		setQueryText({
			label: translateQuery(sqlFormat, createCard, isNotification),
			color: QbUtils.isValidTree(immutableTree) ? '#1d8cf8' : 'red'
		})
		setjsonTree({ format: { jsonLogicFormat, elasticSearchFormat, sqlFormat }, isValid: QbUtils.isValidTree(immutableTree) })
	}

	return (
		<>
			<div>
				<Query {...configDefault} value={tree} onChange={onChange} renderBuilder={!justOldFormatQuery ? renderBuilder : () => {}} />
			</div>
			<div style={{ marginLeft: '30px' }}>
				<FormHelperText style={{ color: queryText.color, fontSize: '16px' }}>{queryText.label}</FormHelperText>
			</div>
			{!justOldFormatQuery && (
				<div style={{ marginLeft: '30px' }}>
					<FormHelperText style={{ color: 'red', fontSize: '16px' }}>{thisError}</FormHelperText>
				</div>
			)}
		</>
	)
}

export default QueryBuilder
