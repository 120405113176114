import React, { useState, memo, useEffect } from 'react'
import { Grid, Button, Slide } from '@mui/material'
import { connect } from 'react-redux'
import api from 'service/service'
import constantsVox from 'constants-vox'
import lodash from 'lodash'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import AccountManagerMetricsFacebook from 'pages/AccountManagement/components/AccountManagerMetricsFacebook/AccountManagerMetricsFacebook'
import AccountManagerMetricsInstagram from 'pages/AccountManagement/components/AccountManagerMetricsInstagram/AccountManagerMetricsInstagram'
import AccountManagerMetricsMetaAds from 'pages/AccountManagement/components/AccountManagerMetricsMetaAds/AccountManagerMetricsMetaAds'
import AccountManagerSkeletonLoad from 'components/SkeletonLoads/AccountManagerSkeletonLoad/AccountManagerSkeletonLoad'
import { LAYOUTS_DEFAULT_FACEBOOK } from '../../../../components/AccountManagerMetricsFacebook/utils/AccountManagerMetricsFacebookUtils'
import AccountManagerToolBar from '../AccountManagerToolBar/AccountManagerToolBar'
import { LAYOUTS_DEFAULT_INSTAGRAM } from '../../../../components/AccountManagerMetricsInstagram/utils/AccountManagerMetricsInstagramUtils'
import { LAYOUTS_DEFAULT_META_ADS } from '../../../../components/AccountManagerMetricsMetaAds/utils/AccountManagerMetricsMetaAdsUtils'
import { DEFAULT_WIDGETS_FACEBOOK } from 'pages/AccountManagement/components/AccountManagerMetricsFacebook/utils/AccountManagerMetricsFacebookUtils'
import { DEFAULT_WIDGETS_INSTAGRAM } from 'pages/AccountManagement/components/AccountManagerMetricsInstagram/utils/AccountManagerMetricsInstagramUtils'
import { DEFAULT_WIDGETS_META_ADS } from 'pages/AccountManagement/components/AccountManagerMetricsMetaAds/utils/AccountManagerMetricsMetaAdsUtils'

const isFirefox = typeof InstallTrigger !== 'undefined'

const LAYOUTS_DEFAULT = {
	product_type: constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT,
	layouts: {
		instagram: LAYOUTS_DEFAULT_INSTAGRAM,
		facebook: LAYOUTS_DEFAULT_FACEBOOK,
		metaAds: LAYOUTS_DEFAULT_META_ADS
	}
}

const WIDGETS_DEFAULT = {
	facebook: DEFAULT_WIDGETS_FACEBOOK,
	instagram: DEFAULT_WIDGETS_INSTAGRAM,
	metaAds: DEFAULT_WIDGETS_META_ADS
}

let initialLayout = {}

const AccountManagerContent = ({ cardList, globalFiltersTermRedux, isSharing, sharingDashboard }) => {
	const [metrics, setMetrics] = useState([])
	const [loadingMetrics, setLoadingMetrics] = useState(true)
	const [loadingLayout, setLoadingLayout] = useState(true)
	const [currentLayout, setCurrentLayout] = useState(sharingDashboard?.layouts || LAYOUTS_DEFAULT)
	const [savedLayoutsList, setSavedLayoutsList] = useState([])
	const [layoutName, setLayoutName] = useState('')
	const [editMode, setEditMode] = useState(false)
	const [addWidgetMode, setAddWidgetMode] = useState(false)
	const [widgetsOnscreen, setWidgetsOnscreen] = useState(sharingDashboard?.active_widgets || WIDGETS_DEFAULT)
	const loadMetrics = () => {
		setLoadingMetrics(true)
		if (globalFiltersTermRedux.sources.length === 1) {
			api.get(
				`/account-management/analytics/get-social-network-analytics/${cardList.map((it) => it.id)}/${globalFiltersTermRedux.sources}/${
					globalFiltersTermRedux.fromDate
				}/${globalFiltersTermRedux.toDate}/${globalFiltersTermRedux.fromHour}/${globalFiltersTermRedux.toHour}`
			).then((res) => {
				setMetrics(res.data)
				setLoadingMetrics(false)
			})
		}
	}

	useEffect(() => {
		loadMetrics()
	}, [cardList, globalFiltersTermRedux])

	useEffect(() => {
		loadInitialLayout()
	}, [])

	const loadInitialLayout = async () => {
		setLoadingLayout(true)
		if (isSharing) {
			setWidgetsOnscreen(sharingDashboard.active_widgets)
			setCurrentLayout(sharingDashboard.layouts)
			initialLayout = sharingDashboard.layouts
		} else {
			api.get(`/dashboard/get-initial-layout/${constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}/${cardList[0].id}`)
				.then((res) => {
					if (res?.data) {
						setWidgetsOnscreen(res?.data?.active_widgets)
						setCurrentLayout(res?.data)
						initialLayout = res?.data
					}
				})
				.finally(() => {
					setLoadingLayout(false)
				})
		}
	}

	const onSaveLayout = async (referenceId, dashboardLayoutId) => {
		if (!referenceId && (!layoutName || layoutName === '')) {
			return alert('Nome precisa ser preenchido')
		}
		setLoadingLayout(true)
		const verifiedLayoutId = referenceId ? initialLayout?.id : dashboardLayoutId

		api.post('/dashboard/save-layout', {
			name: layoutName,
			productType: constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT,
			referenceId,
			layouts: currentLayout.layouts,
			dashboardLayoutId: verifiedLayoutId,
			activeWidgets: widgetsOnscreen
		})
			.then((res) => {
				setCurrentLayout(res?.data?.data)
				if (referenceId) {
					initialLayout = res?.data?.data
				}
				if (res?.data?.toast) {
					sendMessageToast(res?.data?.toast?.message, res?.data?.toast?.type)
				}
			})
			.finally(() => {
				setLoadingLayout(false)
			})
	}

	const onGetSavedLayouts = () => {
		setLoadingLayout(true)
		api.get(`/dashboard/get-saved-layouts/${constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}`)
			.then((res) => {
				if (res?.data) {
					setSavedLayoutsList(res?.data)
				}
			})
			.finally(() => {
				setLoadingLayout(false)
			})
	}

	const onDeleteLayout = (dashboardLayoutId) => {
		setLoadingLayout(true)
		api.post('/dashboard/delete-layout', { dashboardLayoutId })
			.finally(() => {
				setLoadingLayout(false)
			})
			.finally(() => setLayoutName(''))
	}

	const onDefaultLayout = () => {
		setWidgetsOnscreen(WIDGETS_DEFAULT)
		setCurrentLayout({ ...currentLayout, ...LAYOUTS_DEFAULT })
		setLayoutName('')
	}

	const onLayoutApply = (applyLayoutInfo) => {
		setWidgetsOnscreen(applyLayoutInfo.active_widgets)
		setLayoutName(applyLayoutInfo.name)
		setCurrentLayout(applyLayoutInfo)
	}

	const onChangeTemplateName = (event) => {
		setLayoutName(event.target.value)
	}

	const handleOnLayoutChange = (layouts, source) => {
		let newLayouts = lodash.cloneDeep(currentLayout)
		newLayouts.layouts[source] = layouts
		setCurrentLayout(newLayouts)
	}

	const toggleEditMode = () => {
		setEditMode(!editMode)
	}

	const deactivateEditMode = () => {
		setEditMode(false)
	}

	const toggleAddWidgetMode = () => {
		setAddWidgetMode(!addWidgetMode)
	}

	const handleWidgetsOnScreen = (widgets, source) => {
		setWidgetsOnscreen({ ...widgetsOnscreen, [source]: widgets })
	}

	const handleRemoveWidget = (widget, source) => {
		const newWidgets = widgetsOnscreen[source].filter((it) => it !== widget)
		setWidgetsOnscreen({ ...widgetsOnscreen, [source]: newWidgets })
	}

	return (
		<>
			{!isSharing && (
				<AccountManagerToolBar
					loadingMetrics={loadingMetrics}
					loadingLayout={loadingLayout}
					onDefaultLayout={onDefaultLayout}
					onSaveLayout={onSaveLayout}
					cardList={cardList}
					currentLayout={currentLayout}
					onGetSavedLayouts={onGetSavedLayouts}
					layoutName={layoutName}
					savedLayoutsList={savedLayoutsList}
					onChangeTemplateName={onChangeTemplateName}
					onLayoutApply={onLayoutApply}
					onDeleteLayout={onDeleteLayout}
					editMode={editMode}
					toggleEditMode={toggleEditMode}
					addWidgetMode={addWidgetMode}
					toggleAddWidgetMode={toggleAddWidgetMode}
					deactivateEditMode={deactivateEditMode}
					activeWidgets={widgetsOnscreen}
				/>
			)}
			<Grid item xs={12}>
				{!loadingMetrics &&
					metrics.map((it) => (
						<div key={it} style={{ marginTop: '1em' }}>
							{globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('facebook') && (
								<AccountManagerMetricsFacebook
									data={it?.facebook}
									layout={currentLayout.layouts.facebook}
									onLayoutChange={handleOnLayoutChange}
									editMode={editMode}
									addWidgetMode={addWidgetMode}
									toggleAddWidgetMode={toggleAddWidgetMode}
									widgetsOnscreen={widgetsOnscreen.facebook}
									handleWidgetsOnScreen={handleWidgetsOnScreen}
									handleRemoveWidget={handleRemoveWidget}
									cards={cardList}
									descriptions={metrics[0]?.facebook?.widgetsDescriptions}
								/>
							)}
							{globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('instagram') && (
								<AccountManagerMetricsInstagram
									data={it?.instagram}
									layout={currentLayout.layouts.instagram}
									onLayoutChange={handleOnLayoutChange}
									editMode={editMode}
									addWidgetMode={addWidgetMode}
									toggleAddWidgetMode={toggleAddWidgetMode}
									widgetsOnscreen={widgetsOnscreen.instagram}
									handleWidgetsOnScreen={handleWidgetsOnScreen}
									handleRemoveWidget={handleRemoveWidget}
									cards={cardList}
									descriptions={metrics[0]?.instagram?.widgetsDescriptions}
								/>
							)}
							{globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('metaAds') && (
								<AccountManagerMetricsMetaAds
									data={it?.metaAds}
									layout={currentLayout.layouts.metaAds}
									onLayoutChange={handleOnLayoutChange}
									editMode={editMode}
									addWidgetMode={addWidgetMode}
									toggleAddWidgetMode={toggleAddWidgetMode}
									widgetsOnscreen={widgetsOnscreen.metaAds}
									handleWidgetsOnScreen={handleWidgetsOnScreen}
									handleRemoveWidget={handleRemoveWidget}
									cards={cardList}
									descriptions={metrics[0]?.metaAds?.widgetsDescriptions}
								/>
							)}
						</div>
					))}
				{loadingMetrics && (
					<Grid item xs={12} style={{ marginLeft: '0.5em', marginRight: '1.6em', marginTop: '1em' }}>
						<AccountManagerSkeletonLoad />
					</Grid>
				)}
			</Grid>
			{!isSharing && (
				<Slide direction='up' in={editMode && !addWidgetMode} mountOnEnter unmountOnExit>
					<Button
						onClick={toggleEditMode}
						style={{
							position: 'fixed',
							display: 'flex',
							justifyContent: 'center',
							bottom: '0',
							right: '44.6vw',
							marginBottom: '2em',
							fontFamily: 'Poppins',
							borderRadius: '100px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.6)'}`,
							border: '1px solid rgba(29, 140, 248, 0.1)',
							boxShadow: '0 3px 5px 0 rgba(200, 200, 200, 0.7)',
							height: '4em',
							fontWeight: 'bold',
							paddingLeft: '1em',
							paddingRight: '1em'
						}}
					>
						Finalizar Edição
					</Button>
				</Slide>
			)}
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(AccountManagerContent))
