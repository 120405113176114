import React, { useEffect, useState, memo } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Tooltip,
	IconButton,
	Button,
	Grid,
	TextField,
	Select,
	FormControl,
	MenuItem,
	Card,
	InputLabel
} from '@mui/material'
import { ChevronLeftIcon, WhatsappIcon, CloseIcon } from 'utils/SystemIcons'
import { makeStyles } from '@mui/styles'

const isFirefox = typeof InstallTrigger !== 'undefined'

const useStyles = makeStyles({
	newButton: {
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 ) !important',
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
		fontFamily: 'Poppins',
		borderRadius: '100px',
		backgroundColor: 'rgba(255, 255, 255, 0.0)',
		color: '#1d8cf8 !important',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.5) !important'
		},
		letterSpacing: '0.1px !important',
		textTransform: 'none !important',
		justifyContent: 'center !important',
		width: '200px',
		height: '40px'
	},
	underline: {
		color: '#1d8cf8',
		'&::after': {
			borderBottom: '2px solid #1d8cf8'
		},
		'&::before': {
			borderBottom: '2px solid transparent'
		}
	},
	inputLabelRoot: {
		color: 'darkgrey',
		'&.Mui-focused': {
			color: 'darkgrey'
		}
	}
})

const countriesOptions = [
	{
		label: 'Brasil +55',
		value: '55'
	}
]

const WhatsappConfiguration = ({ whatsappNumbers, setWhatsappNumbers, open, onClose }) => {
	const [country, setCountry] = useState('55')
	const [inputValue, setInputValue] = useState('')
	const [thisNumbers, setThisNumbers] = useState(whatsappNumbers)

	useEffect(() => {
		setInputValue('')
		setThisNumbers(whatsappNumbers)
	}, [])

	const classes = useStyles()

	const handleChangeCountry = (e) => {
		setCountry(e.target.value)
	}

	const handleInput = (e) => {
		setInputValue(fromatNumber(e.target.value))
	}

	const fromatNumber = (number) => {
		let fromattedPhoneNumber

		switch (country) {
			case '55':
				fromattedPhoneNumber = fromatPhoneNumberBrasil(number)
				break
		}

		return fromattedPhoneNumber
	}

	const fromatPhoneNumberBrasil = (value) => {
		if (!value) {
			return value
		}

		const phoneNumber = value.replace(/[^\d]/g, '')
		const phoneNumberLength = phoneNumber.length

		if (phoneNumberLength < 4) {
			return phoneNumber
		}

		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`
		}
		return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 11)}`
	}

	const addCountryCode = (value, isClean = false) => {
		switch (country) {
			case '55':
				if (isClean) {
					return `55${value}`
				} else {
					return `+55 ${value}`
				}
		}
	}

	const addNumber = () => {
		if (inputValue !== '') {
			setThisNumbers([...thisNumbers, addCountryCode(inputValue)])
		}
		setInputValue('')
	}

	const removeNumber = (number) => {
		const auxNumbers = [...thisNumbers]
		const numIndex = auxNumbers.indexOf(number)
		auxNumbers.splice(numIndex, 1)
		setThisNumbers(auxNumbers)
	}

	const handleClose = () => {
		setWhatsappNumbers(thisNumbers)
		onClose()
	}

	const numberDisplay = (number) => {
		return (
			<Card
				elevation={0}
				style={{
					marginTop: '10px',
					marginLeft: '1px',
					marginRight: '1px',
					borderRadius: '10px 10px 10px 10px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 0 5px 0 rgba( 200, 200, 200, 0.7 )'
				}}
			>
				<Grid item xs={12} container justifyContent='center' alignItems='center'>
					<Grid item xs={1} container justifyContent='center' alignItems='center'>
						<WhatsappIcon size={20} style={{ color: '#25D366' }} />
					</Grid>
					<Grid
						item
						xs={10}
						style={{
							borderLeft: '1px solid rgba(100, 100, 100, 0.3)'
						}}
					>
						<div style={{ marginLeft: '30px' }}>{number}</div>
					</Grid>
					<Grid item xs={1} container justifyContent='center' alignItems='center'>
						<IconButton onClick={() => removeNumber(number)} color='primary' component='span'>
							<CloseIcon size={25} color='#1d8cf8' />
						</IconButton>
					</Grid>
				</Grid>
			</Card>
		)
	}

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={open}
			onClose={handleClose}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
					minHeight: '350px'
				}
			}}
		>
			<DialogTitle>
				<div style={{ display: 'flex', alignItems: 'center', color: '#1d8cf8' }}>
					<Tooltip title='Voltar'>
						<IconButton color='primary' component='span' onClick={handleClose}>
							<ChevronLeftIcon size={25} color='#1d8cf8' />
						</IconButton>
					</Tooltip>
				</div>
			</DialogTitle>
			<DialogContent>
				<Card
					elevation={0}
					style={{
						borderRadius: '10px 10px 10px 10px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 0 5px 0 rgba( 200, 200, 200, 0.7 )'
					}}
				>
					<Grid container style={{ marginTop: '5px', marginBottom: '0px' }} spacing={1} alignItems='center'>
						<Grid item xs={4}>
							<FormControl variant={'filled'} style={{ width: '100%' }} size={'medium'}>
								<InputLabel classes={{ root: classes.inputLabelRoot }} htmlFor='outlined-age-native-simple'>
									Código do País
								</InputLabel>

								<Select
									style={{ backgroundColor: 'transparent', width: '100%' }}
									disableUnderline
									className={classes.underline}
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left'
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left'
										},
										PaperProps: {
											style: {
												marginTop: '-7px',
												marginLeft: '-1px',
												borderRadius: '0px 0px 10px 10px',
												backdropFilter: 'blur(10px)',
												backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
												borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
												boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
											}
										}
									}}
									value={country}
									onChange={handleChangeCountry}
								>
									{countriesOptions?.length > 0 &&
										countriesOptions.map((item) => {
											return (
												<MenuItem key={item.value} value={item.value}>
													{item.label}
												</MenuItem>
											)
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<TextField value={inputValue} onChange={(e) => handleInput(e)} size='small' fullWidth placeholder='Novo número' />
						</Grid>
						<Grid item xs={4} container justifyContent='center'>
							<Button onClick={addNumber} classes={{ root: classes.newButton }}>
								Adicionar número
							</Button>
						</Grid>
					</Grid>
				</Card>
				<div style={{ height: '500px', overflow: 'auto' }}>{thisNumbers.map((number) => numberDisplay(number))}</div>
			</DialogContent>
		</Dialog>
	)
}

export default memo(WhatsappConfiguration)
