import React, { useState, useRef, useEffect } from 'react'
import { Grow, InputAdornment, Box, Button, CircularProgress, Container, FormControl, FormHelperText, TextField, Link } from '@mui/material'
import { Lock } from '@mui/icons-material'
import api from 'service/service'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const ResetPassword = () => {
	const { token } = useParams()
	const [serverMessage, setServerMessage] = useState()
	const [serverErrorsResetPassword, setServerErrorsResetPassword] = useState()
	const { register: register1, formState: formState1, handleSubmit: handleSubmit1, watch } = useForm()
	const [loadingResetPassword, setLoadingResetPassword] = useState(false)
	const [loadingContent, setLoadingContent] = useState(true)
	const [showForm, setShowForm] = useState(false)
	const password = useRef({})
	password.current = watch('password', '')

	useEffect(() => {
		onLoad()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onLoad = async () => {
		setLoadingContent(true)

		try {
			const reponse = await api.post('/reset-password/verify-token', {
				token: token
			})

			if (reponse.status === 200) {
				setShowForm(true)
			} else {
				setShowForm(false)
				setServerMessage('Ops, token inválido')
			}
		} catch (e) {
			setServerMessage('Ops, token inválido')
		} finally {
			setLoadingContent(false)
		}
	}

	const onSubmitResetPassword = async (formData) => {
		setLoadingResetPassword(true)
		setServerErrorsResetPassword('')

		try {
			const response = await api.post('/reset-password', {
				token,
				...formData
			})

			if (response.status === 200) {
				setShowForm(false)
				setServerMessage(response.data.toast.message)
			} else {
				setServerErrorsResetPassword('Falha na requisição.')
			}
		} catch (e) {
			if (e.response.status === 422) {
				e.response?.data.errors.forEach((e) => {
					setServerErrorsResetPassword(e[Object.keys(e)])
				})
			} else if (e.response?.data) {
				setServerErrorsResetPassword(e.response?.data?.message)
			} else {
				setServerErrorsResetPassword('Falha na requisição.')
			}
		} finally {
			setLoadingResetPassword(false)
		}
	}

	return (
		<>
			<Container>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<Grow in={true}>
						<Box bgcolor='background.paper' pt={2} pb={2} pl={2} pr={2} mt={10} mb={2}>
							<Box display='flex' justifyContent='center' alignItems='center' pb={4} pt={4}>
								<div className='logo'></div>
							</Box>
							<Box>
								{loadingContent && (
									<Box display='flex' justifyContent='center' alignItems='center' m={1}>
										<CircularProgress />
									</Box>
								)}
								{!loadingContent && showForm && (
									<form onSubmit={handleSubmit1(onSubmitResetPassword)} key={1}>
										<FormControl fullWidth={true} error={formState1.errors ? true : false}>
											<Box m={1}>
												<TextField
													id='password'
													label='Nova Senha'
													variant='outlined'
													fullWidth={true}
													type='password'
													autoComplete='current-password'
													error={formState1.errors.password ? true : false}
													helperText={formState1.errors.password ? formState1.errors.password.message : ''}
													{...register1('password', {
														// required: 'Senha é obrigatório',
														// minLength: {
														//     value: 6,
														//     message: 'Sua senha deve conter no mínimo 6 caracteres'
														// }
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<Lock />
															</InputAdornment>
														)
													}}
												/>
											</Box>
											<Box m={1}>
												<TextField
													id='password_confirm'
													label='Confirme a nova senha'
													variant='outlined'
													fullWidth={true}
													type='password'
													autoComplete='current-password'
													error={formState1.errors.password_confirm ? true : false}
													helperText={formState1.errors.password_confirm ? formState1.errors.password_confirm.message : ''}
													{...register1('password_confirm', {
														// validate: value => value === password.current || 'Senhas não conferem'
													})}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<Lock />
															</InputAdornment>
														)
													}}
												/>
											</Box>

											<Box m={1}>
												{serverErrorsResetPassword && (
													<FormHelperText className='fix-errors-custom'>{serverErrorsResetPassword}</FormHelperText>
												)}
											</Box>

											<Box m={2} textAlign='right'>
												{!loadingResetPassword && (
													<Button variant='contained' color='primary' type='submit'>
														Salvar
													</Button>
												)}
												{loadingResetPassword && <CircularProgress />}
											</Box>
										</FormControl>
									</form>
								)}
								{serverMessage && (
									<>
										<Box display='flex' justifyContent='center' alignItems='center' m={1}>
											<h4 style={{ color: '#000000' }}>{serverMessage}</h4>
										</Box>
										<Box m={4} textAlign='center'>
											<Link href='/login' color='primary'>
												Entrar
											</Link>
										</Box>
									</>
								)}
							</Box>
						</Box>
					</Grow>
				</Box>
			</Container>
		</>
	)
}

export default ResetPassword
