import moment from 'moment'

const DEFAULT_FEED_CONFIG = {
	source: null,
	authorNameSearch: null,
	order: { date: null, relevancy: 'desc' },
	classification: 'automatic',
	fromDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
	contentType: 'all',
	authorIsVerified: 'all'
}

const DEFAULT_FEED_EMPTY = { loading: true, data: [], endResults: false }

const PAGE_SIZE = 20

export { DEFAULT_FEED_CONFIG, DEFAULT_FEED_EMPTY, PAGE_SIZE }
