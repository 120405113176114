import React, { memo, useState } from 'react'
import { Button, Collapse, Grid } from '@mui/material'
import { ChevronUpIcon, ChevronDownIcon } from 'utils/SystemIcons'
import MultipleMediaViewer from 'components/MultipleMediaViewer/MultipleMediaViewer'

const OccurrenceAuraSight = ({ color, aurasightData }) => {
	const [isOpen, setIsOpen] = useState(false)
	const mediasDownloaded = aurasightData?.mediasDownloaded
	const mediasInferenced = aurasightData?.mediasInferenced

	const handleToggle = () => {
		setIsOpen((open) => !open)
	}
	return (
		<div style={{ margin: '30px', color: '#757575', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'center' }}>
			{(mediasDownloaded || mediasInferenced) && (
				<>
					<div className='divider-thin-default' />
					Aurasight
					<Collapse in={isOpen}>
						<>
							{isOpen && (
								<Grid container spacing={2}>
									<Grid item xs={6}>
										Downloads:
										<MultipleMediaViewer multipleMedias={mediasDownloaded} containerStyle={{ borderRadius: '10px' }} />
									</Grid>
									<Grid item xs={6}>
										Inferências:
										<MultipleMediaViewer multipleMedias={mediasInferenced} containerStyle={{ borderRadius: '10px' }} />
									</Grid>
								</Grid>
							)}
						</>
					</Collapse>
					<Button onClick={handleToggle} style={{ width: '100%', borderRadius: '0px 0px 25px 25px', height: '2em' }}>
						{isOpen ? <ChevronUpIcon size={20} style={{ color }} /> : <ChevronDownIcon size={20} style={{ color }} />}
					</Button>
				</>
			)}
		</div>
	)
}

export default memo(OccurrenceAuraSight)
