import React, { useState, useEffect, memo, Fragment } from 'react'
import './AnalysisContentBodyTerm.css'
import AddAnalysis from '../../../../../../../components/AddAnalysis/AddAnalysis'
// import GraficoImpacto from 'graphs/GraficoImpacto/GraficoImpacto'
import SensitivityByTime from 'graphs/GraficoSensibilidade/components/SensitivityByTime/SensitivityByTime'
import GraficoNowcasting from 'graphs/GraficoNowcasting/components/GraficoImpactoNowcasting/GraficoImpactoNowcasting'

const defaultOptions = {
	type: 'engagement',
	engagement: true,
	social: false,
	posts: false,
	gender: false,
	users: false,
	men: false,
	women: false,
	noClass: false,
	politics: false,
	left: false,
	right: false,
	bolsonarist: false,
	notPolarized: false,
	comments: false,
	retweets: false
}

// const graficoImpacto = (cards) => <GraficoImpacto id_pesquisa={id_pesquisa}/>
const graficoSensibilidadeTwitter = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'twitter' }}
		isReport={true}
		graphTypeReport='outcome'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeTwitterDefault = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'twitter' }}
		isReport={true}
		graphTypeReport='default'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeFacebook = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'facebook' }}
		isReport={true}
		graphTypeReport='outcome'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeFacebookDefault = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'facebook' }}
		isReport={true}
		graphTypeReport='default'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeInstagram = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'instagram' }}
		isReport={true}
		graphTypeReport='outcome'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeInstagramDefault = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'instagram' }}
		isReport={true}
		graphTypeReport='default'
		defaultGraphTime={graphInterval}
	/>
)

const graficoSensibilidadeTiktok = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'tiktok' }}
		isReport={true}
		graphTypeReport='outcome'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeTiktokDefault = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'tiktok' }}
		isReport={true}
		graphTypeReport='default'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeYoutube = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'youtube' }}
		isReport={true}
		graphTypeReport='outcome'
		defaultGraphTime={graphInterval}
	/>
)
const graficoSensibilidadeYoutubeDefault = (cards, filtersReport, graphInterval) => (
	<SensitivityByTime
		cards={cards}
		filters={{ ...filtersReport, source: 'youtube' }}
		isReport={true}
		graphTypeReport='default'
		defaultGraphTime={graphInterval}
	/>
)
const graficoTweetcasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'twitter' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoTweetcastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'twitter' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoFacecasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'facebook' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoFacecastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'facebook' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoInstacasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'instagram' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoInstacastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'instagram' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoTiktokcasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'tiktok' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoTiktokcastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'tiktok' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoYoutubecasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'youtube' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoYoutubecastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'youtube' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)
const graficoNewscasting = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting cards={cards} filters={{ ...filtersReport, source: 'news' }} isReport={true} defaultGraphTime={graphInterval} />
)
const graficoNewscastingPosts = (cards, filtersReport, graphInterval) => (
	<GraficoNowcasting
		cards={cards}
		filters={{ ...filtersReport, source: 'news' }}
		isReport={true}
		defaultGraphTime={graphInterval}
		options={{ ...defaultOptions, type: 'posts', engagement: false, posts: true }}
	/>
)

const sentimentAnalysisGraphs = (
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineYoutubeEngagement,
	timelineNewsEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	timelineYoutubePosts,
	timelineNewsPosts
) => {
	let graphs = []
	if (timelineTwitterEngagement) {
		graphs.push({
			name: 'Engajamento Twitter',
			graph: graficoTweetcasting,
			source: 'twitter'
		})
	}
	if (timelineTwitterPosts) {
		graphs.push({
			name: 'Postagens Twitter',
			graph: graficoTweetcastingPosts,
			source: 'twitter'
		})
	}
	if (timelineFacebookEngagement) {
		graphs.push({
			name: 'Engajamento Facebook',
			graph: graficoFacecasting,
			source: 'facebook'
		})
	}
	if (timelineFacebookPosts) {
		graphs.push({
			name: 'Postagens Facebook',
			graph: graficoFacecastingPosts,
			source: 'facebook'
		})
	}
	if (timelineInstagramEngagement) {
		graphs.push({
			name: 'Engajamento Instagram',
			graph: graficoInstacasting,
			source: 'instagram'
		})
	}
	if (timelineInstagramPosts) {
		graphs.push({
			name: 'Postagens Instagram',
			graph: graficoInstacastingPosts,
			source: 'instagram'
		})
	}
	if (timelineTiktokEngagement) {
		graphs.push({
			name: 'Engajamento Tiktok',
			graph: graficoTiktokcasting,
			source: 'tiktok'
		})
	}
	if (timelineTiktokPosts) {
		graphs.push({
			name: 'Postagens Tiktok',
			graph: graficoTiktokcastingPosts,
			source: 'tiktok'
		})
	}
	if (timelineYoutubeEngagement) {
		graphs.push({
			name: 'Engajamento Youtube',
			graph: graficoYoutubecasting,
			source: 'youtube'
		})
	}
	if (timelineYoutubePosts) {
		graphs.push({
			name: 'Postagens Youtube',
			graph: graficoYoutubecastingPosts,
			source: 'youtube'
		})
	}
	if (timelineNewsEngagement) {
		graphs.push({
			name: 'Engajamento Notícias',
			graph: graficoNewscasting,
			source: 'news'
		})
	}
	if (timelineNewsPosts) {
		graphs.push({
			name: 'Postagens Notícias',
			graph: graficoNewscastingPosts,
			source: 'news'
		})
	}
	return graphs
}

const sentimentGraphs = (
	sentimentsTwitterStandard,
	sentimentsTwitterBalance,
	sentimentsFacebookStandard,
	sentimentsFacebookBalance,
	sentimentsInstagramStandard,
	sentimentsInstagramBalance,
	sentimentsTiktokStandard,
	sentimentsTiktokBalance,
	sentimentsYoutubeStandard,
	sentimentsYoutubeBalance
) => {
	let graphs = []
	if (sentimentsTwitterStandard) {
		graphs.push({
			name: 'Sentimentalização Twitter',
			graph: graficoSensibilidadeTwitterDefault,
			source: 'twitter',
			subName: 'Padrão'
		})
	}
	if (sentimentsTwitterBalance) {
		graphs.push({
			name: 'Sentimentalização Twitter',
			graph: graficoSensibilidadeTwitter,
			source: 'twitter',
			subName: 'Saldo (positivo menos negativo)'
		})
	}
	if (sentimentsFacebookStandard) {
		graphs.push({
			name: 'Sentimentalização Facebook',
			graph: graficoSensibilidadeFacebookDefault,
			source: 'facebook',
			subName: 'Padrão'
		})
	}
	if (sentimentsFacebookBalance) {
		graphs.push({
			name: 'Sentimentalização Facebook',
			graph: graficoSensibilidadeFacebook,
			source: 'facebook',
			subName: 'Saldo (positivo menos negativo)'
		})
	}
	if (sentimentsInstagramStandard) {
		graphs.push({
			name: 'Sentimentalização Instagram',
			graph: graficoSensibilidadeInstagramDefault,
			source: 'instagram',
			subName: 'Padrão'
		})
	}
	if (sentimentsInstagramBalance) {
		graphs.push({
			name: 'Sentimentalização Instagram',
			graph: graficoSensibilidadeInstagram,
			source: 'instagram',
			subName: 'Saldo (positivo menos negativo)'
		})
	}
	if (sentimentsTiktokStandard) {
		graphs.push({
			name: 'Sentimentalização Tiktok',
			graph: graficoSensibilidadeTiktokDefault,
			source: 'tiktok',
			subName: 'Padrão'
		})
	}
	if (sentimentsTiktokBalance) {
		graphs.push({
			name: 'Sentimentalização Tiktok',
			graph: graficoSensibilidadeTiktok,
			source: 'tiktok',
			subName: 'Saldo (positivo menos negativo)'
		})
	}
	if (sentimentsYoutubeStandard) {
		graphs.push({
			name: 'Sentimentalização Youtube',
			graph: graficoSensibilidadeYoutubeDefault,
			source: 'youtube',
			subName: 'Padrão'
		})
	}
	if (sentimentsYoutubeBalance) {
		graphs.push({
			name: 'Sentimentalização Youtube',
			graph: graficoSensibilidadeYoutube,
			source: 'youtube',
			subName: 'Saldo (positivo menos negativo)'
		})
	}
	return graphs
}

const addAnalysis = (
	<div style={{ marginTop: '3em' }}>
		<AddAnalysis />
	</div>
)

const renderGraphs = (
	graphs,
	sentimentGraphs,
	cards,
	filters,
	viewType,
	graphInterval,
	sentimentsTwitterStandard,
	sentimentsTwitterBalance,
	sentimentsFacebookStandard,
	sentimentsFacebookBalance,
	sentimentsInstagramStandard,
	sentimentsInstagramBalance,
	sentimentsTiktokStandard,
	sentimentsTiktokBalance,
	sentimentsYoutubeStandard,
	sentimentsYoutubeBalance,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineYoutubeEngagement,
	timelineNewsEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	timelineYoutubePosts,
	timelineNewsPosts
) => {
	if (graphs.length === 0) {
		return null
	}

	return (
		<>
			{graphs.map((objGraph, index) => (
				<div key={index + 'render'}>
					<h3 style={{ marginTop: '4em', color: '#000' }}>{objGraph.name}</h3>
					<div className={viewType === 'horizontal' ? 'break-print' : index % 2 ? 'break-print' : ''}>
						{objGraph.subName && <h4 style={{ marginTop: '1em', color: '#000' }}>{objGraph.subName}</h4>}
						{objGraph?.graph(
							cards,
							filters,
							graphInterval,
							sentimentsTwitterStandard,
							sentimentsTwitterBalance,
							sentimentsFacebookStandard,
							sentimentsFacebookBalance,
							sentimentsInstagramStandard,
							sentimentsInstagramBalance,
							sentimentsTiktokStandard,
							sentimentsTiktokBalance,
							sentimentsYoutubeStandard,
							sentimentsYoutubeBalance,
							timelineTwitterEngagement,
							timelineFacebookEngagement,
							timelineInstagramEngagement,
							timelineTiktokEngagement,
							timelineYoutubeEngagement,
							timelineNewsEngagement,
							timelineTwitterPosts,
							timelineFacebookPosts,
							timelineInstagramPosts,
							timelineTiktokPosts,
							timelineYoutubePosts,
							timelineNewsPosts
						)}
						{addAnalysis}
					</div>
				</div>
			))}

			{cards.map((id) => (
				<Fragment key={id}>
					{sentimentGraphs.map((objGraph, index) => (
						<div key={index + 'render'}>
							<h3 style={{ marginTop: '4em', color: '#000' }}>{objGraph.name}</h3>
							<div className={viewType === 'horizontal' ? 'break-print' : index % 2 ? 'break-print' : ''}>
								{objGraph.subName && <h4 style={{ marginTop: '1em', color: '#000' }}>{objGraph.subName}</h4>}
								{objGraph?.graph(
									[id],
									filters,
									graphInterval,
									sentimentsTwitterStandard,
									sentimentsTwitterBalance,
									sentimentsFacebookStandard,
									sentimentsFacebookBalance,
									sentimentsInstagramStandard,
									sentimentsInstagramBalance,
									sentimentsTiktokStandard,
									sentimentsTiktokBalance,
									sentimentsYoutubeStandard,
									sentimentsYoutubeBalance,
									timelineTwitterEngagement,
									timelineFacebookEngagement,
									timelineInstagramEngagement,
									timelineTiktokEngagement,
									timelineYoutubeEngagement,
									timelineNewsEngagement,
									timelineTwitterPosts,
									timelineFacebookPosts,
									timelineInstagramPosts,
									timelineTiktokPosts,
									timelineYoutubePosts,
									timelineNewsPosts
								)}
								{addAnalysis}
							</div>
						</div>
					))}
				</Fragment>
			))}
		</>
	)
}

const AnalysisContentBodyTerm = ({
	cards,
	partialLoad,
	filters,
	viewType,
	graphInterval,
	sentimentsTwitterStandard,
	sentimentsTwitterBalance,
	sentimentsFacebookStandard,
	sentimentsFacebookBalance,
	sentimentsInstagramStandard,
	sentimentsInstagramBalance,
	sentimentsTiktokStandard,
	sentimentsTiktokBalance,
	sentimentsYoutubeStandard,
	sentimentsYoutubeBalance,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	timelineYoutubeEngagement,
	timelineNewsEngagement,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	timelineYoutubePosts,
	timelineNewsPosts
}) => {
	const [listGraphs, setListGraphs] = useState([])
	const [listSentimentGraphs, setListSentimentGraphs] = useState([])
	const [sources, setSources] = useState(partialLoad)

	useEffect(() => {
		setSources(partialLoad)
	}, [partialLoad])

	useEffect(() => {
		ListGraphs()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sources])

	const ListGraphs = async () => {
		const verifiedGraphs = sentimentAnalysisGraphs(
			timelineTwitterEngagement,
			timelineFacebookEngagement,
			timelineInstagramEngagement,
			timelineTiktokEngagement,
			timelineYoutubeEngagement,
			timelineNewsEngagement,
			timelineTwitterPosts,
			timelineFacebookPosts,
			timelineInstagramPosts,
			timelineTiktokPosts,
			timelineYoutubePosts,
			timelineNewsPosts
		)
		let alertGraphs = verifiedGraphs.filter((it) => sources[it.source])
		setListGraphs(alertGraphs)

		const verifiedSentimentGraphs = sentimentGraphs(
			sentimentsTwitterStandard,
			sentimentsTwitterBalance,
			sentimentsFacebookStandard,
			sentimentsFacebookBalance,
			sentimentsInstagramStandard,
			sentimentsInstagramBalance,
			sentimentsTiktokStandard,
			sentimentsTiktokBalance,
			sentimentsYoutubeStandard,
			sentimentsYoutubeBalance
		)
		let formattedSentimentGraphs = verifiedSentimentGraphs.filter((it) => sources[it.source])
		setListSentimentGraphs(formattedSentimentGraphs)
	}

	return (
		<div className='analysis-body-container'>
			{renderGraphs(
				listGraphs,
				listSentimentGraphs,
				cards,
				filters,
				viewType,
				graphInterval,
				sentimentsTwitterStandard,
				sentimentsTwitterBalance,
				sentimentsFacebookStandard,
				sentimentsFacebookBalance,
				sentimentsInstagramStandard,
				sentimentsInstagramBalance,
				sentimentsTiktokStandard,
				sentimentsTiktokBalance,
				sentimentsYoutubeStandard,
				sentimentsYoutubeBalance,
				timelineTwitterEngagement,
				timelineFacebookEngagement,
				timelineInstagramEngagement,
				timelineTiktokEngagement,
				timelineYoutubeEngagement,
				timelineNewsEngagement,
				timelineTwitterPosts,
				timelineFacebookPosts,
				timelineInstagramPosts,
				timelineTiktokPosts,
				timelineYoutubePosts,
				timelineNewsPosts
			)}
		</div>
	)
}

export default memo(AnalysisContentBodyTerm)
