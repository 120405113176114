import React from 'react'
import { Grid } from '@mui/material'
import GraficoNowcasting from 'graphs/GraficoNowcasting/GraficoNowcasting'

const SocialProfileContent = ({ cardList, isFetchingData }) => {
	return (
		<>
			<Grid item xs={12}>
				<GraficoNowcasting cards={cardList} isFetchingData={isFetchingData} />
			</Grid>
		</>
	)
}

export default SocialProfileContent
