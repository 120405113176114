import React, { memo, useState, useEffect } from 'react'
import GraficoNowcasting from 'graphs/GraficoNowcasting/GraficoNowcasting'
import GraficoSensibilidade from 'graphs/GraficoSensibilidade/GraficoSensibilidade'
import GenderAgeDistribution from 'graphs/GenderAgeDistribution/GenderAgeDistribution'
import TopComments from '../TopComments/TopComments'
import WordClouds from '../../../components/WordClouds/WordClouds'
import { Grid } from '@mui/material'

const SentimentAnalysisContent = ({ cardStatus, cards, isFetchingData }) => {
	const [cardList, setCardList] = useState(cards)
	const canShowTwitter = () => cardStatus.twitter === 'S'
	const canShowFacebook = () => cardStatus.facebook === 'S'
	const canShowInstagram = () => cardStatus.instagram === 'S'
	const canShowTiktok = () => cardStatus.tiktok === 'S'
	const canShowYoutube = () => cardStatus.youtube === 'S'
	const canShowNews = () => cardStatus.news === 'S'

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	return (
		<Grid container spacing={3}>
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube() || canShowNews()) && (
				<>
					<GraficoNowcasting cards={cardList} isFetchingData={isFetchingData} />
					<TopComments cards={cardList} />
					<WordClouds cardStatus={cardStatus} cards={cardList} isDialog={false} />
				</>
			)}
			{(canShowTwitter() || canShowFacebook() || canShowInstagram() || canShowTiktok() || canShowYoutube()) && (
				<>
					<GenderAgeDistribution cards={cardList} isFetchingData={isFetchingData} />
					<GraficoSensibilidade cards={cardList} isFetchingData={isFetchingData} />
				</>
			)}
		</Grid>
	)
}

export default memo(SentimentAnalysisContent)
