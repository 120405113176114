import React, { useState, useEffect, memo, Fragment, useRef } from 'react'
import moment from 'moment-timezone'
import Chart from 'react-apexcharts'
import { graphOptionsSensLine } from '../../../utils/GraphOptionsSensLine'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import InspectIntervalButton from '../../../components/InspectIntervalButton/InspectIntervalButton'
import { Grid } from '@mui/material'
import SensitivityByTimeMenu from '../SensitivityByTimeMenu/SensitivityByTimeMenu'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import GraphLegend from 'components/GraphLegend/GraphLegend'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const defaultDate = {
	fromDate: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD'),
	toDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
}

let interval = defaultDate
let canLockTime = false

const getGraphHeight = (comparativeSize, isComparative, isReport, fullScreenState) => {
	let windowSize = null
	if (fullScreenState) {
		windowSize = window.innerHeight / 2.8
	} else if (isReport) {
		windowSize = 350
	} else {
		windowSize = window.innerHeight / 1.5
	}

	if (isReport || !isComparative) {
		return windowSize
	} else if (!fullScreenState) {
		return windowSize / comparativeSize
	} else {
		return windowSize / 2 + 70
	}
}

const onIntervalChange = (xaxis, filters, graphInterval) => {
	if (xaxis === undefined || xaxis.min === undefined || xaxis.max === undefined) {
		interval = { fromDate: filters.fromDate, toDate: filters.toDate, fromHour: filters.fromHour, toHour: filters.toHour }
		canLockTime = false
	} else {
		const fromDate = moment(xaxis.min).tz('UTC').format('YYYY-MM-DD')
		const toDate = moment(xaxis.max).tz('UTC').format('YYYY-MM-DD')
		const fromHour = graphInterval === 'day' ? '00:00:00' : moment(xaxis.min).format('HH:mm:ss')
		const toHour = graphInterval === 'day' ? '23:59:59' : moment(xaxis.max).format('HH:mm:ss')
		canLockTime = true
		interval = { fromDate, toDate, fromHour, toHour }
	}
}

const getOptions = (graphType, isReport, graphInterval, maxGraphY, minGraphY, maxY, minY, maxX, minX, filters) => {
	return graphOptionsSensLine(onIntervalChange, maxY, minY, maxX, minX, maxGraphY, minGraphY, graphType, isReport, graphInterval, filters)
}
const getMaxAndMinXY = (data) => {
	let maxY = Math.max(...data.formatted.map((it) => it.optional.maxY))
	let minY = Math.min(...data.formatted.map((it) => it.optional.minY))
	let maxX = Math.max(...data.formatted.map((it) => it.optional.maxX))
	let minX = Math.min(...data.formatted.map((it) => it.optional.minX))
	minY == Infinity ? (minY = 0) : minY

	if (minX === 0) {
		minX = new Date(interval.fromDate).getTime()
	}
	if (maxX === 0) {
		maxX = new Date(interval.toDate).getTime()
	}

	return { maxY, minY, maxX, minX }
}

const RenderGraph = memo(
	({ graphType, isReport, graphInterval, cardId, title, maxYGraph, minYGraph, series, height, maxY, minY, maxX, minX, filters, id, cardIds }) => {
		const [graphOptions, setGraphOptions] = useState(getOptions(graphType, isReport, graphInterval, maxYGraph, minYGraph, maxY, minY, maxX, minX))

		useEffect(() => {
			setGraphOptions(getOptions(graphType, isReport, graphInterval, maxYGraph, minYGraph, maxY, minY, maxX, minX, filters))
		}, [series])

		return (
			<div key={cardId}>
				{/* <GraphLegend title={title} id={id} cardIds={cardIds} /> */}
				<GraphLegend title={title} />
				<Chart options={graphOptions} series={series} width='100%' height={height} />
			</div>
		)
	}
)

const SensitivityByTime = ({
	cards,
	fullScreenState = false,
	isReport = false,
	graphTypeReport,
	onPartialLoading,
	isFetchingData,
	filters,
	defaultGraphTime = 'day',
	lockTimeInterval
}) => {
	const [cardList, setCardList] = useState(cards)
	const [loading, setLoading] = useState(true)
	const [graphInterval, setGraphInterval] = useState(defaultGraphTime)
	const [date, setDate] = useState({ fromDate: filters.fromDate, toDate: filters.toDate })
	const [hour, setHour] = useState({ fromHour: filters.fromHour, toHour: filters.toHour })
	const [graphType, setGraphType] = useState(graphTypeReport || 'default')
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (isMounted.current) {
			setDate({ fromDate: filters.fromDate, toDate: filters.toDate })
		}
	}, [filters.fromDate, filters.toDate])

	useEffect(() => {
		if (isMounted.current) {
			setHour({ fromHour: filters.fromHour, toHour: filters.toHour })
		}
	}, [filters.fromHour, filters.toHour])

	useEffect(() => {
		if (isMounted.current) {
			setCardList(cards)
		}
	}, [cards])

	useEffect(() => {
		if (filters.fromDate !== interval.fromDate || filters.toDate !== interval.toDate) {
			interval = { fromDate: filters.fromDate, toDate: filters.toDate }
		}
	}, [filters.fromDate, filters.toDate])

	useEffect(() => {
		canLockTime = false
		setGraphInterval(defaultGraphTime)
	}, [defaultGraphTime])

	const getBody = () => ({
		configGlobalFilters: { cardIds: cardList?.map((card) => card.id), cardType: cardList[0].type.toUpperCase() },
		globalFilters: { ...filters, fromHour: hour?.fromHour, toHour: hour?.toHour },
		componentProperties: { graphInterval, graphType }
	})

	let { data, error, isFetching, isFetched } = useUpdateData({
		url: '/analysis/sensitivity',
		updateItemName: 'Sensitivity by Time',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (isFetched && isMounted.current) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched, graphInterval, graphType, date, fullScreenState])

	//Report states start-----
	useEffect(() => {
		if (graphTypeReport && isMounted.current) {
			setGraphType(graphTypeReport)
		}
	}, [graphTypeReport])

	useEffect(() => {
		if (!loading && onPartialLoading && isMounted.current) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	//Report states end-----

	if (verifyServerResponseCanShowToast(error) && !isReport && isMounted.current) {
		sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
	}

	const handleChangeGraphType = (type) => {
		if (isMounted.current) {
			setGraphType(type)
		}
	}

	const handleChangeGraphTime = (time) => {
		if (isMounted.current) {
			setGraphInterval(time)
		}
	}

	const onClickLockInterval = () => {
		canLockTime = false
		lockTimeInterval(interval)
	}

	const getInterval = () => interval
	const getCanLockTime = () => canLockTime

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					{!isReport && (
						<div style={{ pointerEvents: loading ? 'none' : 'all' }}>
							<SensitivityByTimeMenu
								fromDate={filters?.fromDate}
								toDate={filters?.toDate}
								handleChangeGraphType={handleChangeGraphType}
								handleChangeGraphTime={handleChangeGraphTime}
								initialGraphTime={defaultGraphTime}
								getCanLockTime={getCanLockTime}
								onClickLockInterval={onClickLockInterval}
							/>
						</div>
					)}
				</Grid>
			</Grid>

			<Grid style={{ marginTop: '2.5em', marginLeft: '25px', marginRight: '20px', marginBottom: '5px' }}>
				{loading ? (
					<GraphSkeletonLoad />
				) : (
					<>
						<br />
						<div
							style={{
								color: 'black',
								textAlign: 'left',
								fontFamily: 'Poppins'
							}}
						>
							<div style={{ marginTop: '20px' }}>
								<GraphLegend
									title={data?.formatted?.optional?.title || ''}
									id={cards[0]?.id}
									cardIds={cards.map((card) => card.id)}
								/>
							</div>
							{data?.formatted?.map((fd, index) => (
								<Fragment key={fd.optional.cardId}>
									<RenderGraph
										data={data}
										graphType={graphType}
										isReport={isReport}
										graphInterval={graphInterval}
										title={fd.optional.title}
										maxYGraph={fd.optional.maxY}
										minYGraph={fd.optional.minY}
										series={fd.series}
										height={getGraphHeight(data?.formatted?.length, data?.formatted?.length > 1, isReport, fullScreenState)}
										source={filters.sources}
										{...getMaxAndMinXY(data)}
										filters={filters}
										id={fd?.optional?.cardId}
										cardIds={cards.map((card) => card.id)}
									/>
								</Fragment>
							))}
						</div>

						<Grid container direction='row' justifyContent='space-between' alignItems='center'>
							{!isReport && fullScreenState && (
								<div>
									<InspectIntervalButton cards={cardList} getInterval={getInterval} filters={filters} cardType={cards[0]?.type} />
								</div>
							)}
							{data?.lastUpdate && !isReport && (
								<h5
									style={{
										textAlign: 'right',
										fontFamily: 'Poppins',
										fontWeight: '300',
										paddingRight: '10px',
										color: 'rgba(220,220,220,100%)'
									}}
								>
									Última atualização: {data?.lastUpdate || 'Indisponível'}
								</h5>
							)}
						</Grid>
					</>
				)}
			</Grid>
		</>
	)
}

export default memo(SensitivityByTime)
