import React, { useState, useEffect, memo, useRef } from 'react'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { Grid } from '@mui/material'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'
import Chart from 'react-apexcharts'
import GraphLegend from 'components/GraphLegend/GraphLegend'

const blue = 'rgb(29, 138, 248)'
const pink = 'rgb(237, 40, 146)'
const lightgreen = 'rgb(0, 222, 174)'
const lightgrey = 'rgb(220, 220, 220)'

const genderOptions = {
	colors: [blue, pink, lightgreen, lightgrey],
	chart: {
		type: 'donut'
	},
	labels: ['Masculino', 'Feminino', 'Organização', 'Sem class.'],
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	legend: {
		show: true,
		position: 'bottom'
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
}

const RenderGraph = memo(({ cardId, index, title, series, numberOfOptions, isHorizontal, firstAxis, secondAxis, cardIds }) => {
	const formatValue = (value) => {
		if (value !== null) {
			if (Number.isInteger(value)) {
				return value
			} else if (Number.isFinite(value)) {
				return value?.toFixed(2)
			} else {
				return 1
			}
		} else {
			return null
		}
	}

	const graphOptions = {
		colors: [blue, pink],
		chart: {
			type: 'bar',
			height: 350,
			stacked: true,
			toolbar: {
				show: true
			},
			zoom: {
				enabled: false
			}
		},
		plotOptions: {
			bar: {
				horizontal: isHorizontal,
				borderRadius: 15,
				borderRadiusApplication: 'end', // "around" / "end"
				borderRadiusWhenStacked: 'last' // "all"/"last"
			}
		},
		grid: {
			show: true,
			borderColor: 'rgba(82, 95, 127, 0.3)',
			position: 'back',
			[firstAxis]: {
				lines: {
					show: false
				}
			},
			[secondAxis]: {
				lines: {
					show: true
				}
			}
		},
		dataLabels: {
			enabled: true,
			offsetX: 0,
			textAnchor: 'middle',
			style: {
				fontSize: '13px',
				fontFamily: 'Roboto',
				colors: ['#fff']
			},
			background: {
				enabled: true,
				foreColor: '#fff',
				padding: 4,
				borderRadius: 10,
				borderWidth: 1,
				borderColor: 'transparent',
				opacity: 0.15
			},
			dropShadow: {
				enabled: true,
				top: 1,
				left: 0,
				blur: 1,
				color: '#000',
				opacity: 0.45
			}
		},
		[firstAxis]: {
			type: 'category',
			categories: ['Menos de 18 anos', 'Entre 19 e 29 anos', 'Entre 30 e 39 anos', 'Mais de 40 anos'],
			labels: {
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				}
			}
		},
		[secondAxis]: {
			type: 'category',
			categories: ['Menos de 18 anos', 'Entre 19 e 29 anos', 'Entre 30 e 39 anos', 'Mais de 40 anos'],
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: -10,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => formatValue(value)
			}
		},
		legend: {
			enabled: true,
			position: 'top',
			offsetY: 15,
			markers: {
				radius: 12
			}
		},
		fill: {
			opacity: 1
		}
	}

	return (
		<Grid
			item
			xs={!isHorizontal ? getXs(index, numberOfOptions) : 12}
			key={cardId}
			style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins' }}
		>
			<div style={{ marginBottom: '1em' }}>
				<GraphLegend title={title} id={cardId} cardIds={cardIds} />
			</div>
			<Chart type={'bar'} options={graphOptions} series={series} width='100%' height={'400px'} />
		</Grid>
	)
})

const getXs = (index, numberOfOptions) => {
	if (numberOfOptions === 1) {
		return 12
	} else if (numberOfOptions === 2) {
		return 6
	} else if (numberOfOptions === 3) {
		return 4
	} else if (numberOfOptions === 4) {
		return 6
	} else if (numberOfOptions === 5) {
		if (index === 0 || index === 1) {
			return 6
		} else {
			return 4
		}
	}
}

const GenderAgeChart = ({ cards, filters, isFetchingData, onPartialLoading, selectedOption }) => {
	const [cardList, setCardList] = useState(cards)
	const [loading, setLoading] = useState(true)

	const isMounted = useRef(true)

	useEffect(() => {
		setCardList(cards)
	}, [cards])

	const getBody = () => ({
		configGlobalFilters: { cardIds: cardList?.map((card) => card.id), cardType: cardList[0].type.toUpperCase() },
		globalFilters: filters
	})

	let { data, isFetching, isFetched } = useUpdateData({
		url: '/analysis/gender-age',
		updateItemName: 'Gender and Age Distribution',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: isFetchingData,
		queryName: 'gender_age_distribution',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (isFetched && isMounted.current) {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched])

	useEffect(() => {
		if (!loading && onPartialLoading && isMounted.current) {
			onPartialLoading(isFetching)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching])

	return (
		<>
			{loading ? (
				<Grid style={{ marginTop: '2.5em', paddingLeft: '15px', paddingRight: '15px', marginBottom: '5px' }}>
					<GraphSkeletonLoad style={{ backgroundColor: 'red' }} />
				</Grid>
			) : (
				<Grid container spacing={3} style={{ marginTop: '2.5em', paddingLeft: '15px', paddingRight: '15px', marginBottom: '5px' }}>
					{selectedOption === 'bars' &&
						data?.formatted?.map((fd, index) => (
							<RenderGraph
								key={fd.info.id}
								cardId={fd.info.id}
								index={index}
								title={fd.info.name}
								series={fd.seriesMaleFemale}
								numberOfOptions={data?.formatted?.length}
								isHorizontal={false}
								firstAxis={'xaxis'}
								secondAxis={'yaxis'}
								cardIds={cards.map((card) => card.id)}
							/>
						))}
					{selectedOption === 'infographic' && (
						<>
							<Grid item xs={6}>
								{data?.formatted?.map((fd, index) => (
									<RenderGraph
										key={fd.info.id}
										cardId={fd.info.id}
										index={index}
										title={fd.info.name + ' (idade)'}
										series={fd.seriesAll}
										numberOfOptions={data?.formatted?.length}
										isHorizontal={true}
										firstAxis={'yaxis'}
										secondAxis={'xaxis'}
										cardIds={cards.map((card) => card.id)}
									/>
								))}
							</Grid>
							<Grid item xs={6}>
								{data?.formatted?.map((fd, index) => (
									<Grid key={fd.info.id}>
										<div style={{ padding: '1em' }}>
											<GraphLegend title={fd.info.name + ' (gênero)'} id={fd?.id} cardIds={cards.map((card) => card.id)} />
										</div>
										<div style={{ padding: '1em' }}>
											<Chart options={genderOptions} series={fd.seriesTotal} type='donut' width={'100%'} height={'400px'} />
										</div>
									</Grid>
								))}
							</Grid>
						</>
					)}
				</Grid>
			)}
		</>
	)
}

export default memo(GenderAgeChart)
