import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { CardContent, Grid } from '@mui/material'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'
const red = 'rgb(255, 43, 107)'
const lightgrey = 'rgb(220, 220, 220)'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: true
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const PercentageComparativeMetric = ({ data, title, icon, xs, isReport }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let positives = []
		let negatives = []
		let neutral = []
		let bots = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			if (element?.selectedInterval?.metrics?.post_count !== null) {
				seriesData.push(element?.selectedInterval?.metrics?.post_count || 0)
			}
			if (element?.selectedInterval?.metrics?.like_count_good !== null) {
				positives.push(element?.selectedInterval?.metrics?.like_count_good || 0)
			}
			if (element?.selectedInterval?.metrics?.like_count_bad !== null) {
				negatives.push(element?.selectedInterval?.metrics?.like_count_bad || 0)
			}
			if (element?.selectedInterval?.metrics?.like_count_neutral !== null) {
				neutral.push(element?.selectedInterval?.metrics?.like_count_neutral || 0)
			}
			if (element?.selectedInterval?.metrics?.like_count_likely_bot !== null) {
				bots.push(element?.selectedInterval?.metrics?.like_count_likely_bot || 0)
			}
		}
		const series = [
			{
				name: 'Positivos',
				data: positives
			},
			{
				name: 'Negativos',
				data: negatives
			},
			{
				name: 'Neutros',
				data: neutral
			}
		]

		const optionsBars = {
			colors: [blue, red, purple, lightgrey],
			chart: {
				type: 'bar',
				stacked: true,
				stackType: '100%',
				toolbar: {
					show: !isReport
				}
			},
			legend: {
				show: false
			},
			plotOptions: {
				bar: {
					horizontal: true
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: -10,
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: true,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}
		setBarsData(apexData)
	}

	if (barsData.series.every((it) => it.data.length < 1)) {
		return <></>
	}

	return (
		<Grid item xs={xs}>
			<CardContent>
				<h4 style={{ textAlign: 'left', font: 'Poppins', fontWeight: 'lighter', fontSize: '15px' }}>
					{icon}
					{title}
				</h4>
				<Chart
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
					options={barsData.options}
					series={barsData.series}
					type='bar'
					width={'100%'}
					height={150}
				/>
			</CardContent>
		</Grid>
	)
}

export default PercentageComparativeMetric
