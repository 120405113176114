import {
	TextField,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	Dialog,
	DialogActions,
	DialogContentText,
	Box,
	ListItemText,
	ListItemIcon,
	List,
	ListItem,
	Checkbox,
	Avatar,
	Grid,
	IconButton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from 'service/service'
import { CloseIcon } from 'utils/SystemIcons'

const ImageAnnotationCreateProject = ({ isOpen, handleOnClose }) => {
	const [logos, setLogos] = useState([])
	const [selectedLogos, setSelectedLogos] = useState(new Set())
	const [openDialog, setOpenDialog] = useState(false)
	const [projectName, setProjectName] = useState('')
	const [file, setFile] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const fetchLogos = async () => {
			try {
				const response = await api.get('/image-annotation/logos')
				if (Array.isArray(response.data.data)) {
					setLogos(response.data.data)
				} else {
					throw new Error('Unexpected response format')
				}
			} catch (err) {
				console.error('Error fetching logos:', err)
				setError('Error fetching logos')
			} finally {
				setLoading(false)
			}
		}

		fetchLogos()
	}, [])

	const createProject = async (fileContent) => {
		const logoIds = Array.from(selectedLogos)
		try {
			await api.post(
				'/image-annotation/create-project',
				{ name: projectName, logoIds, fileName: file?.name, fileType: file?.type, fileContent },
				{ headers: { 'Content-Type': 'application/json' } }
			)
			alert('Projeto criado com sucesso!')
			setOpenDialog(false)
			handleOnClose()
			setSelectedLogos(new Set())
			setProjectName('')
			setFile(null)
		} catch (error) {
			console.error('Erro ao criar projeto:', error)
			alert('Erro ao criar projeto.')
		}
	}

	const handleCreateProject = async () => {
		if (!projectName) {
			alert('Digite um nome para criar o projeto.')
			return
		}
		let fileContent = null
		if (file) {
			const reader = new FileReader()
			reader.onload = async (e) => {
				fileContent = e.target.result
				await createProject(fileContent)
			}
			reader.readAsDataURL(file)
		} else {
			await createProject(fileContent)
		}
	}

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0]
		setFile(selectedFile)
	}

	const handleRemoveFile = () => {
		setFile(null)
		document.getElementById('file-input').value = null
	}

	const handleToggleLogoSelection = (id) => {
		const newSelectedLogos = new Set(selectedLogos)
		if (newSelectedLogos.has(id)) {
			newSelectedLogos.delete(id)
		} else {
			newSelectedLogos.add(id)
		}
		setSelectedLogos(newSelectedLogos)
	}

	return (
		<Dialog open={isOpen} onClose={handleOnClose}>
			<DialogTitle>Criar Novo Projeto</DialogTitle>
			<DialogContent>
				<List>
					{logos.map((logo) => (
						<ListItem key={logo.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
							<Checkbox checked={selectedLogos.has(logo.id)} onChange={() => handleToggleLogoSelection(logo.id)} />
							<ListItemIcon>
								<Avatar
									src={logo.imagePath}
									sx={{ width: 56, height: 56, objectFit: 'contain', backgroundColor: 'white' }}
									variant='square'
								/>
							</ListItemIcon>
							<ListItemText primary={logo.name} secondary={`${logo.cleanName}`} />
							<Typography style={{ marginLeft: '16px', color: logo.projectName[0] === 'Logo ainda sem projetos' ? 'green' : 'black' }}>
								{logo.projectName.join(', ')}
							</Typography>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
				<Button variant='contained' color='secondary' onClick={() => setOpenDialog(true)} disabled={selectedLogos.size === 0}>
					Criar projeto com logos
				</Button>
				<Button
					variant='contained'
					onClick={() => {
						handleOnClose()
						setSelectedLogos(new Set())
					}}
				>
					Cancelar
				</Button>
			</Box>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogTitle>Criar Novo Projeto</DialogTitle>
				<DialogContent>
					<DialogContentText>Digite o nome do novo projeto:</DialogContentText>
					<TextField
						autoFocus
						margin='dense'
						label='Nome do Projeto'
						type='text'
						fullWidth
						value={projectName}
						onChange={(e) => setProjectName(e.target.value)}
						required
					/>
					<DialogContentText>Anexar arquivo de modelo base:</DialogContentText>
					<Button variant='contained' component='label'>
						Selecionar arquivo
						<input id='file-input' hidden style={{ display: 'none' }} type='file' onChange={handleFileChange} />
					</Button>
					{file && (
						<Grid container>
							<Grid item xs={11} alignContent={'center'}>
								<DialogContentText>{file.name}</DialogContentText>
							</Grid>
							<Grid item xs={1}>
								<IconButton onClick={handleRemoveFile}>
									<CloseIcon color='#1d8cf8' />
								</IconButton>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)} color='primary'>
						Cancelar
					</Button>
					<Button onClick={handleCreateProject} color='primary'>
						Criar
					</Button>
				</DialogActions>
			</Dialog>
		</Dialog>
	)
}

export default ImageAnnotationCreateProject
