import React, { useState } from 'react'
import { Grid, Card, Button, Switch, Collapse } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'

const subTag = (obj, onChangeTopicOptions) => {
	return (
		<Grid key={obj?.subtagName} item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Grid container justifyContent='space-between'>
				<Grid item xs={9}>
					<Button
						disabled
						style={{
							height: '36.5px',
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							fontSize: '12px',
							color: '#9a9a9a',
							justifyContent: 'space-between',
							borderRadius: '20px',
							width: '100%',
							backgroundColor: 'rgba(255,255,255,0)',
							marginTop: '10px',
							marginBottom: '10px',
							marginLeft: '10px',
							marginRight: '0px'
						}}
					>
						{obj?.subtagName}
					</Button>
				</Grid>
				<Grid item xs={3} container style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
					<div style={{ marginLeft: '30px', marginTop: '7px' }}>
						<Switch onClick={() => onChangeTopicOptions(obj)} color='primary' checked={obj?.active} />
					</div>
				</Grid>
			</Grid>
		</Grid>
	)
}

const TagOption = ({ obj, onChangeTopicOptions }) => {
	const [isOpen, setIsOpen] = useState(false)

	const isComp = obj?.subtags?.length > 0

	return (
		<Grid item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Card
				elevation={0}
				style={{
					borderRadius: '20px',
					marginTop: '1em',
					margin: '10px',
					width: '95%',
					backgroundColor: 'rgba(255,255,255,0.6)'
				}}
			>
				<Grid container justifyContent='space-between'>
					<Grid item xs={10}>
						<Button
							onClick={() => isComp && setIsOpen(!isOpen)}
							disabled={!isComp}
							fullWidth
							style={{
								height: '36.5px',
								fontFamily: 'Poppins',
								fontWeight: 'bold',
								color: '#1d8cf8',
								justifyContent: 'flex-start',
								borderRadius: '0px',
								width: '100%',
								backgroundColor: 'rgba(255,255,255,1)',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								paddingLeft: '15px'
							}}
						>
							{isComp && !isOpen && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
							{isComp && isOpen && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
							{obj?.name}
						</Button>
					</Grid>
					<Grid item xs={2} container alignContent='center' justifyContent='flex-end' style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
						<div style={{ marginLeft: '29px' }}>
							<Switch onClick={() => onChangeTopicOptions(obj)} color='primary' checked={obj?.active} />
						</div>
					</Grid>
				</Grid>
				{isComp && (
					<Collapse in={isOpen}>
						<div style={{ height: '10px' }} />
						<Grid container>{obj?.subtags?.map((sub) => subTag(sub, onChangeTopicOptions))}</Grid>
					</Collapse>
				)}
			</Card>
		</Grid>
	)
}

export default TagOption
