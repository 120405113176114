import { Grid } from '@mui/material'
import SelectVox from 'components/SelectVox/SelectVox'
import React, { useEffect, useRef, useState, memo } from 'react'
import api from 'service/service'
import { MapPinIcon } from 'utils/SystemIcons'

const mapIcon = <MapPinIcon size={20} style={{ color: '#1d8cf8', marginRight: '3px' }} />

const LocationGlobalFilters = ({ locationControlled, onChange, id = '' }) => {
	const [countries, setCountries] = useState([])
	const [regions, setRegions] = useState([])
	const [states, setStates] = useState([])
	const [cities, setCities] = useState([])

	const [loadingCountries, setLoadingCountries] = useState(false)
	const [loadingStates, setLoadingStates] = useState(false)
	const [loadingCities, setLoadingCities] = useState(false)
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		setLoadingCountries(true)
		api.get('/location/filters-available').then((res) => {
			if (isMounted.current) {
				setCountries(res.data.countries)
				setRegions(res.data.regions)
				setLoadingCountries(false)
			}
		})
		return () => {
			isMounted.current = false
		}
	}, [])

	const handleChangeCountry = (country) => {
		if (country) {
			onChange({
				country: country?.value || country,
				region: locationControlled?.state || '',
				state: locationControlled?.state || '',
				city: locationControlled?.city || ''
			})
		} else {
			onChange({ country: '', region: '', state: '', city: '' })
		}
	}

	const handleChangeRegion = (region) => {
		if (region) {
			setLoadingStates(true)
			api.get(`/location/get-by-country-and-region-id/${locationControlled?.country}/${region.id}`).then((res) => {
				if (isMounted.current) {
					setStates(res.data)
					setLoadingStates(false)
				}
			})
			onChange({
				country: locationControlled?.country,
				region: region?.value || region,
				state: locationControlled?.state || '',
				city: locationControlled?.city || ''
			})
		} else {
			onChange({ country: locationControlled?.country, region: '', state: '', city: '' })
		}
	}

	const handleChangeState = (state) => {
		if (state) {
			setLoadingCities(true)
			api.get(`/location/get-by-state-id/${state.id}`).then((res) => {
				if (isMounted.current) {
					setCities(res.data)
					setLoadingCities(false)
				}
			})
			onChange({
				country: locationControlled.country,
				region: locationControlled.region,
				state: state?.value || state,
				city: locationControlled?.city || ''
			})
		} else {
			onChange({ country: locationControlled.country, region: locationControlled.region, state: '', city: '' })
		}
	}

	const handleChangeCity = (city) => {
		onChange({
			country: locationControlled.country,
			region: locationControlled.region,
			state: locationControlled.state,
			city: city?.value || city
		})
	}

	return (
		<Grid container item spacing={3}>
			<Grid
				container
				item
				xs={12}
				style={{
					fontFamily: 'Poppins',
					fontSize: '15px',
					color: 'rgba(0, 0, 0, 0.65)'
				}}
			>
				{mapIcon}
				Localidade
			</Grid>
			<Grid item xs={4}>
				<SelectVox
					isLoading={loadingCountries}
					label='País'
					items={countries}
					onChange={handleChangeCountry}
					hasEmpty
					initialValue={locationControlled?.country}
					emptyLabel='Sem Filtro'
					id={`${id}-countries`}
				/>
			</Grid>
			{locationControlled.country && (
				<Grid item xs={4}>
					<SelectVox
						isLoading={loadingCountries}
						label='Região'
						items={regions}
						onChange={handleChangeRegion}
						hasEmpty
						initialValue={locationControlled?.region}
						emptyLabel='Sem Filtro'
						id={`${id}-regions`}
					/>
				</Grid>
			)}
			{locationControlled.region && (
				<Grid item xs={4}>
					<SelectVox
						isLoading={loadingStates}
						label='Estado'
						items={states}
						onChange={handleChangeState}
						hasEmpty
						initialValue={locationControlled?.state}
						emptyLabel='Sem Filtro'
						id={`${id}-states`}
					/>
				</Grid>
			)}
			{locationControlled.state && (
				<Grid item xs={4}>
					<SelectVox
						isLoading={loadingCities}
						label='Cidade'
						items={cities}
						onChange={handleChangeCity}
						hasEmpty
						initialValue={locationControlled?.city}
						emptyLabel='Sem Filtro'
						id={`${id}-cities`}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default memo(LocationGlobalFilters)
