import React, { useState, useEffect } from 'react'
import { Button, Dialog, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import constantsVox from 'constants-vox'

const grey = 'rgba(184, 184, 184, 100%)'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const MultipleImageView = ({ cardData, source, type }) => {
	const [openDialogImage, setOpenDialogImage] = useState(false)
	const [postImageUrl, setPostImageUrl] = useState(null)
	const [subImages, setSubImages] = useState(null)
	const classes = useStyles()

	useEffect(() => {
		definePostImageUrl()
	}, [cardData])

	const definePostImageUrl = () => {
		if (type !== constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
			let mediaType = {
				image: null,
				video: null,
				link: null,
				music: null,
				animatedGif: null
			}
			if (cardData?.content?.attachments) {
				cardData?.content?.attachments.map((it) => {
					switch (it?.type) {
						case 'image':
							mediaType.image = it?.url
							break
						case 'video':
							mediaType.video = it?.url
							break
						case 'link':
							mediaType.link = it?.url
							break
						case 'music':
							mediaType.music = it?.url
							break
						case 'animated_gif':
							mediaType.animatedGif = it?.url
							break
					}
				})
			}
			setPostImageUrl(mediaType.image || null)
		} else {
			if (source === 'twitter') {
				setPostImageUrl(cardData?.media_url || null)
			} else if (source === 'news') {
				setPostImageUrl(cardData?.url_image ? cardData?.url_image : cardData?.base64_image)
			} else if (source === 'facebook') {
				setPostImageUrl(cardData.post?.attachment?.picture_url)
				//account manager
				const images =
					cardData.post?.media_urls &&
					cardData.post?.media_urls[0]?.subattachments
						?.filter((it) => it.media_url !== cardData.post?.attachment?.picture_url && it.media_type !== 'video')
						.map((it) => it.media_url)
				setSubImages(images)
			} else if (source === 'instagram') {
				setPostImageUrl(cardData.post?.attachment?.picture_url)
				//account manager
				const images =
					cardData.post?.children &&
					cardData.post?.children?.filter((it) => it.media_url !== cardData.post?.attachment?.picture_url).map((it) => it.media_url)
				setSubImages(images)
			} else if (['tiktok', 'youtube', 'metaAds'].includes(source)) {
				setPostImageUrl(cardData.post?.attachment?.picture_url)
			}
		}
	}

	const handleOnCloseImage = () => {
		setOpenDialogImage(!openDialogImage)
	}

	return (
		<>
			{postImageUrl && (
				<>
					<Grid item xs={2} container justifyContent='flex-end'>
						<Button
							onClick={() => {
								setOpenDialogImage(!openDialogImage)
							}}
							style={{ borderRadius: '20px', maxHeight: '115px' }}
						>
							<img
								onError={() => setPostImageUrl(null)}
								style={{
									width: '100px',
									height: '100px',
									objectFit: 'cover',
									borderRadius: '20px',
									marginBlock: 'auto'
								}}
								src={postImageUrl}
							/>
						</Button>
					</Grid>
					<Dialog
						style={{ margin: 'auto' }}
						id='dialog-app'
						open={openDialogImage}
						onClose={handleOnCloseImage}
						BackdropProps={{ classes: { root: classes.backDrop } }}
						PaperProps={{
							style: {
								borderRadius: '20px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
							}
						}}
					>
						<Carousel showArrows={true} showThumbs={false} showStatus={false}>
							{[postImageUrl, ...(subImages || [])].map((it) => (
								<div key={it}>
									<img
										src={it}
										style={{
											maxHeight: '40em',
											maxWidth: '40em',
											borderRadius: '20px',
											marginBlock: 'auto'
										}}
									/>
									{/* <p>Legend 1</p> */}
								</div>
							))}
						</Carousel>
					</Dialog>
				</>
			)}
		</>
	)
}

export default MultipleImageView
