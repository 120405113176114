import React, { Fragment, useState } from 'react'
import { IconButton } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import DialogContents from './DialogContents'

const grey = 'rgba(200, 200, 200, 100%)'

const DialogInformation = ({ title, text }) => {
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<IconButton color='primary' onClick={handleClickOpen} style={{ outline: 'none' }}>
				<HelpOutline style={{ width: '20px', height: '20px', color: grey }} />
			</IconButton>
			<DialogContents open={open} handleClose={handleClose} title={title} text={text} />
		</>
	)
}

export default DialogInformation
