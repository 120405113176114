import React, { useState, useRef } from 'react'
import { RewindIcon } from 'utils/SystemIcons'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import FullScreenButton from 'components/FullScreenButton/FullScreenButton'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { Grid, Card } from '@mui/material'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import MultiSeriesGraph from '../MultiSeriesGraph/MultiSeriesGraph'
import FinancialSideMenu from '../FinancialSideMenu/FinancialSideMenu'

const defaultOptions = {
	predOnlyPositive: true,
	predFocus: false,
	predWithFocus: false,
	predWithoutFocus: false,
	predPosteriorAverage: false,
	predMultiSerie: false,
	predMultiSeriePostAverage: false,
	predMultiSeriePostAverageBenchmark: false
}

const Backtesting = ({ variable, country, inicialDate, timeInterval, isFetchingData }) => {
	const [fullScreenState, setFullScreenState] = useState(false)
	const [options, setOptions] = useState(defaultOptions)

	const handle = useFullScreenHandle()
	const loadingBarRef = useRef()

	const onPartialLoading = (status) => {
		loadingBarRef.current.load(status)
	}

	const onChangeOptions = (newOptions) => {
		setOptions(newOptions)
	}

	return (
		<Grid container item spacing={0} direction='row' justifyContent='space-between'>
			<Grid item lg={12} md={12} sm={12}>
				<FullScreen handle={handle} onChange={() => setFullScreenState(!fullScreenState)}>
					<Card style={{ height: '100%', width: '100%', borderRadius: '20px' }}>
						<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
							<Grid item xs={6}>
								<GraphTitle title='Backtesting' icon={<RewindIcon size={25} style={{ color: '#1d8cf8' }} />} description={''} />
							</Grid>
							<Grid item xs={6} container justifyContent='flex-end'>
								<FinancialSideMenu options={options} onChangeOptions={onChangeOptions} />
								<FullScreenButton fullScreenState={fullScreenState} handle={handle} />
							</Grid>
						</Grid>
						<div style={{ marginTop: '10px' }}>
							<LoadingBar ref={loadingBarRef} />
						</div>
						<MultiSeriesGraph
							options={options}
							onPartialLoading={onPartialLoading}
							fullScreenState={fullScreenState}
							variable={variable}
							country={country}
							inicialDate={inicialDate}
							timeInterval={timeInterval}
							isFetchingData={isFetchingData}
						/>
					</Card>
				</FullScreen>
			</Grid>
		</Grid>
	)
}

export default Backtesting
