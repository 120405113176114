import React from 'react'
import { FiPercent } from 'react-icons/fi'
import { Grid } from '@mui/material'
import SentimentsComp from '../SentimentsComp/SentimentsComp'
import GenderComp from '../GenderComp/GenderComp'
import GeneralMetric from '../GeneralMetric/GeneralMetric'
import PercentageComparativeMetric from '../PercentageComparativeMetric/PercentageComparativeMetric'
import AgeComp from '../AgeComp/AgeComp'
import {
	TwitterIcon,
	ShareIcon,
	UsersIcon,
	LikeIcon,
	HeartIcon,
	WowIcon,
	SadIcon,
	AngryIcon,
	CommentIcon,
	EyeIcon,
	TiktokIcon,
	NewsIcon,
	HahaIcon,
	FacebookIcon,
	InstagramIcon,
	YoutubeIcon,
	RetweetIcon,
	PageIcon
} from 'utils/SystemIcons'
const lightgreen = 'rgb(0, 222, 174)'
const red = 'rgb(255, 43, 107)'
const yellow = 'rgb(255, 162, 0)'

const twitterIcon = <TwitterIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const facebookIcon = <FacebookIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const instagramIcon = <InstagramIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const tiktokIcon = <TiktokIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const newsIcon = <NewsIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const youtubeIcon = <YoutubeIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const sharesIcon = <ShareIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const usersIcon = <UsersIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const percentIcon = <FiPercent size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const likeIconMetric = <LikeIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const heartIconMetric = <HeartIcon size={15} style={{ color: red, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const hahaIconMetric = <HahaIcon size={15} style={{ color: yellow, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const wowIconMetric = <WowIcon size={15} style={{ color: yellow, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const sadIconMetric = <SadIcon size={15} style={{ color: yellow, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const angryIconMetric = <AngryIcon size={15} style={{ color: red, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const commentIconMetric = <CommentIcon size={15} style={{ color: lightgreen, transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const visIconMetric = <EyeIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const retweetIconMetric = <RetweetIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />
const pageIconMetric = <PageIcon size={15} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '5px' }} />

const postsPerUserIcon = (SourceIcon, MetricIcon) => (
	<>
		{MetricIcon}
		<SourceIcon
			size={10}
			style={{
				position: 'relative',
				transform: 'translate(0px, 0px)',
				color: '#1d8cf8',
				marginRight: '5px'
			}}
		/>
	</>
)

const sourcesProperties = {
	twitter: {
		rawIcon: TwitterIcon,
		formattedIcon: twitterIcon,
		share: { name: 'Reposts', icon: retweetIconMetric },
		comment: { name: 'Respostas', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric, xs: 6 },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon, xs: 3 },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon, xs: 3 },
		followersCount: { name: 'Seguidores', icon: twitterIcon, xs: 3 }
	},
	facebook: {
		rawIcon: FacebookIcon,
		formattedIcon: facebookIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: likeIconMetric, xs: 3 },
		authorUnique: { name: 'Páginas Únicas', icon: pageIconMetric, xs: 3 },
		postsPerAuthor: { name: 'Posts / Página', icon: pageIconMetric },
		authorLikesCount: { name: 'Curtidas da Página', icon: facebookIcon, xs: 3 }
	},
	instagram: {
		rawIcon: InstagramIcon,
		formattedIcon: instagramIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric, xs: 3 },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon, xs: 3 },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon, xs: 3 },
		followersCount: { name: 'Seguidores', icon: instagramIcon, xs: 3 }
	},
	tiktok: {
		rawIcon: TiktokIcon,
		formattedIcon: tiktokIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: heartIconMetric, xs: 3 },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon, xs: 3 },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon, xs: 3 }
	},
	youtube: {
		rawIcon: YoutubeIcon,
		formattedIcon: youtubeIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		like: { name: 'Curtidas', icon: likeIconMetric, xs: 3 },
		authorUnique: { name: 'Autores Únicos', icon: usersIcon, xs: 3 },
		postsPerAuthor: { name: 'Posts / Autor', icon: usersIcon, xs: 3 }
	},
	news: {
		rawIcon: NewsIcon,
		formattedIcon: newsIcon,
		share: { name: 'Compartilhamentos', icon: sharesIcon },
		comment: { name: 'Comentários', icon: commentIconMetric },
		authorUnique: { name: 'Páginas Únicas', icon: pageIconMetric, xs: 3 },
		postsPerAuthor: { name: 'Posts / Página', icon: pageIconMetric, xs: 3 }
	}
}

const SummaryMetricsComp = ({ data, isReport = false, sources, cardIds }) => {
	return (
		<Grid container style={{ marginTop: '10px' }} id='summary-metrics-comp'>
			<GeneralMetric
				data={data}
				title={'Número total de ' + (sources[0] === 'news' ? 'notícias' : 'posts')}
				icon={sourcesProperties[sources[0]].formattedIcon}
				metric='post_count'
				xs={6}
				isReport={isReport}
				cardIds={cardIds}
			/>
			<Grid container item xs={6}>
				{sources[0] !== 'news' && (
					<>
						<Grid item xs={4}>
							<SentimentsComp data={data} isReport={isReport} hideOnEmpty />
						</Grid>
						<Grid item xs={4}>
							<GenderComp data={data} isReport={isReport} hideOnEmpty />
						</Grid>
						<Grid item xs={4}>
							<AgeComp data={data} isReport={isReport} hideOnEmpty />
						</Grid>
					</>
				)}
			</Grid>
			{['twitter', 'instagram'].includes(sources[0]) && (
				<GeneralMetric
					data={data}
					title={sourcesProperties[sources[0]].followersCount.name}
					icon={sourcesProperties[sources[0]].followersCount.icon}
					metric='followers_count'
					xs={3}
					isReport={isReport}
					cardIds={cardIds}
				/>
			)}

			{sources[0] === 'facebook' && (
				<GeneralMetric
					data={data}
					title={sourcesProperties[sources[0]].authorLikesCount.name}
					icon={sourcesProperties[sources[0]].authorLikesCount.icon}
					metric='author_likes_count'
					xs={3}
					isReport={isReport}
					cardIds={cardIds}
				/>
			)}
			<GeneralMetric
				data={data}
				title={sourcesProperties[sources[0]].authorUnique.name}
				icon={sourcesProperties[sources[0]].authorUnique.icon}
				metric='author_count_original'
				xs={3}
				isReport={isReport}
				cardIds={cardIds}
			/>
			<GeneralMetric
				data={data}
				title={sourcesProperties[sources[0]].postsPerAuthor.name}
				icon={postsPerUserIcon(sourcesProperties[sources[0]].rawIcon, sourcesProperties[sources[0]].postsPerAuthor.icon)}
				metric='posts_per_author'
				xs={3}
				isReport={isReport}
				cardIds={cardIds}
			/>
			{['twitter', 'facebook', 'tiktok', 'news'].includes(sources[0]) && (
				<GeneralMetric
					data={data}
					title={sourcesProperties[sources[0]].share.name}
					icon={sourcesProperties[sources[0]].share.icon}
					metric='share_count'
					xs={3}
					isReport={isReport}
					cardIds={cardIds}
				/>
			)}
			<GeneralMetric
				data={data}
				title={sourcesProperties[sources[0]].comment.name}
				icon={sourcesProperties[sources[0]].comment.icon}
				metric='comment_count'
				xs={3}
				isReport={isReport}
				cardIds={cardIds}
			/>
			{sources[0] !== 'news' && (
				<GeneralMetric
					data={data}
					title={sourcesProperties[sources[0]].like.name}
					icon={sourcesProperties[sources[0]].like.icon}
					metric='like_count'
					xs={sourcesProperties[sources[0]].like.xs}
					isReport={isReport}
					cardIds={cardIds}
				/>
			)}
			{sources[0] === 'facebook' && (
				<>
					<GeneralMetric data={data} title='Amei' icon={heartIconMetric} metric='love_count' xs={3} isReport={isReport} cardIds={cardIds} />
					<GeneralMetric data={data} title='HaHa' icon={hahaIconMetric} metric='haha_count' xs={3} isReport={isReport} cardIds={cardIds} />
					<GeneralMetric data={data} title='Wow' icon={wowIconMetric} metric='wow_count' xs={3} isReport={isReport} cardIds={cardIds} />
					<GeneralMetric data={data} title='Triste' icon={sadIconMetric} metric='sad_count' xs={3} isReport={isReport} cardIds={cardIds} />
					<GeneralMetric data={data} title='Grr' icon={angryIconMetric} metric='angry_count' xs={3} isReport={isReport} cardIds={cardIds} />
				</>
			)}
			{['instagram', 'tiktok', 'youtube'].includes(sources[0]) && (
				<GeneralMetric
					data={data}
					title='Visualizações'
					icon={visIconMetric}
					metric='view_count'
					xs={3}
					isReport={isReport}
					cardIds={cardIds}
				/>
			)}
			{['twitter'].includes(sources[0]) && (
				<GeneralMetric data={data} title='Citações' icon={visIconMetric} metric='quote_count' xs={6} isReport={isReport} cardIds={cardIds} />
			)}
			{sources[0] !== 'news' && (
				<PercentageComparativeMetric
					data={data}
					title='Curtidas percentual'
					icon={percentIcon}
					metric='like_count'
					xs={12}
					isReport={isReport}
				/>
			)}
		</Grid>
	)
}

export default SummaryMetricsComp
