import React, { useState } from 'react'
import api from 'service/service'
import { Grid, Button, Card, Dialog, DialogTitle } from '@mui/material'
import { DeleteBinIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const RemoveTopicDialog = ({ topic, openDeleteDialog, toggleDeleteTopic, update, cardId }) => {
	const [submitting, setSubmitting] = useState(false)

	const removeTopic = (event) => {
		event.preventDefault()

		setSubmitting(true)
		api.post('/topic-analysis/remove-topic', {
			topicId: topic.topic_modeling_ia_id,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleDeleteTopic()
				update()
			})
	}

	return (
		<Dialog
			id='dialog-app'
			open={openDeleteDialog}
			onClose={toggleDeleteTopic}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='sm'
		>
			<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
				<DialogTitle>
					<div className='jc-space-between d-flex ai-center'>
						<div style={{ color: '#ff2b6b' }}>
							<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' /> Deseja realmente deletar:{' '}
						</div>
					</div>
					<div style={{ marginTop: '1em' }}>{topic.title}?</div>
				</DialogTitle>

				<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='space-between'>
					<Button onClick={toggleDeleteTopic} style={{ borderRadius: '25px' }}>
						CANCELAR
					</Button>
					<Button disabled={submitting} style={{ color: '#ff2b6b', borderRadius: '25px' }} onClick={removeTopic}>
						DELETAR
					</Button>
				</Grid>
			</Card>
		</Dialog>
	)
}

export default RemoveTopicDialog
