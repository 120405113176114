import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'

const blue = 'rgb(29, 138, 248)'
const pink = 'rgb(237, 40, 146)'
const lightgrey = 'rgb(220, 220, 220)'
const lightgreen = 'rgb(0, 222, 174)'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: false
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const GenderComp = ({ data, isReport, hideOnEmpty }) => {
	const [barsData, setBarsData] = useState(barsDataProps)
	const [hide, setHide] = useState(false)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let masc = []
		let fem = []
		let org = []
		let noClass = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			seriesData.push(element?.selectedInterval?.metrics?.total_tweets || 0)

			let gender = element?.selectedInterval?.metrics?.gender_count

			masc.push(gender?.male || 0)
			fem.push(gender?.female || 0)
			org.push(gender?.organization || 0)
			noClass.push(gender?.notClassified || 0)
		}

		const series = [
			{
				name: 'Masculino',
				data: masc
			},
			{
				name: 'Feminino',
				data: fem
			},
			{
				name: 'Organização',
				data: org
			},
			{
				name: 'Sem class',
				data: noClass
			}
		]

		const optionsBars = {
			colors: [blue, pink, lightgreen, lightgrey],
			chart: {
				type: 'bar',
				stacked: true,
				stackType: '100%',
				toolbar: {
					show: !isReport
				}
			},
			plotOptions: {
				bar: {
					horizontal: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories
			},
			fill: {
				opacity: 1
			},
			yaxis: {
				show: false
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		if (hideOnEmpty) {
			let hideData = []
			series.forEach((it) => hideData.push(...it.data.filter((d) => d)))
			setHide(hideData.length < 1)
		}

		setBarsData(apexData)
	}

	return (
		<>
			{!hide && (
				<Chart
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
					options={barsData.options}
					series={barsData.series}
					type='bar'
					width={'100%'}
					height={300}
				/>
			)}
		</>
	)
}

export default GenderComp
