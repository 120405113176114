import React from 'react'
import { ListIcon, UserIcon } from 'utils/SystemIcons'

const analysisIcon = <UserIcon className='icon-list-items-tab' size='20' />
const occurrencesIcon = <ListIcon className='icon-list-items-tab' size='20' />
// const postsMetricsIcon = <MessageIcon className='icon-list-items-tab' size='20' />

const getStatus = (sources, postsStatus) => {
	if (postsStatus === 'P') {
		return {
			name: 'loading',
			label: 'Estamos capturando as postagens, isso pode demorar alguns minutos!'
		}
	}
	let hasError = sources.every((s) => s === 'W')
	if (hasError) {
		return {
			name: 'error',
			label: 'Ocorreu algum erro ao buscar dados.'
		}
	}
	let hasWarning = sources.every((s) => s === 'E')
	if (hasWarning) {
		return {
			name: 'warning',
			label: 'Nenhum dado encontrado para esta busca.'
		}
	}
	let isLoading = sources.every((s) => s === 'P')
	if (isLoading) {
		return { name: 'loading', label: 'Processando dados.' }
	} else {
		return { name: 'success', label: '' }
	}
}

const menuDefaultNew = (twitterStatus = 'P', facebookStatus = 'P', instagramStatus = 'P', tiktokStatus = 'P', metaAdsStatus = 'P', postsStatus) => {
	return [
		{
			// twitter || facebook || instagram || tiktok
			label: 'Análise da Conta',
			tab: 'analysis',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus, metaAdsStatus]),
			icon: analysisIcon
		},
		// {
		// 	// twitter || facebook || instagram || tiktok
		// 	label: 'Análise das Postagens',
		// 	tab: 'postsMetrics',
		// 	status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus], postsStatus),
		// 	icon: postsMetricsIcon
		// },
		{
			// twitter || facebook || instagram || tiktok
			label: 'Feed de Postagens',
			tab: 'occurrences',
			status: getStatus([twitterStatus, facebookStatus, instagramStatus, tiktokStatus, metaAdsStatus], postsStatus),
			icon: occurrencesIcon
		}
	]
}

const getValuesAndDates = (values, dates) => {
	const valuesAndDates = []

	values.forEach((value, index) => {
		valuesAndDates.push([dates[index], value])
	})

	return valuesAndDates
}

export { menuDefaultNew, getValuesAndDates }
