import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, TextField, Autocomplete, Paper, styled, Collapse } from '@mui/material'
import { BookmarkIcon, GridIcon, LoadingIcon } from 'utils/SystemIcons'
import api from 'service/service'
import LocalNewInsightTerm from './components/LocalNewInsightTerm'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const categoriesPtBr = {
	GENERAL: 'GERAL',
	POLITICS: 'POLÍTICA',
	RELIGION: 'RELIGIÃO',
	'MEDIA CHANNEL': 'CANAL DE MÍDIA'
}

const GeneralNewInsight = ({ formState, fieldErrors, onChangeForm, statusType, card, sourceInfo }) => {
	const [categories, setCategories] = useState([])
	const [loading, setLoading] = useState(true)
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		if (statusType === 'edit') {
			onChangeForm({ cardName: card?.name })
		}
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	const load = async () => {
		setLoading(true)

		const response = await api.get('/search-category/all')
		let thisCategories = response?.data?.map((category) => ({
			label: categoriesPtBr[category.category],
			value: category.id
		}))

		if (isMounted.current) {
			setCategories(thisCategories)
			if (statusType === 'edit') {
				onChangeForm({
					cardName: card?.name,
					category: thisCategories.filter((category) => {
						return category.label === categoriesPtBr[sourceInfo?.category]
					})[0]
				})
			}
			setLoading(false)
		}
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<span className='color-blue fs-1-5'>Informações do cartão</span>
			</Grid>
			<Grid item xs={12}>
				<CssTextField
					variant='standard'
					label={
						<div style={{ display: 'flex' }}>
							<BookmarkIcon size={20} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
							Nome do cartão
						</div>
					}
					error={!!fieldErrors?.cardName?.error}
					helperText={fieldErrors?.cardName?.error}
					size='small'
					className='d-flex'
					style={{ color: '#1d8cf8', borderRadius: '4px' }}
					value={formState.cardName}
					onChange={(e) => onChangeForm({ cardName: e.target.value })}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					className='adjust-autocomplete'
					freeSolo
					PaperComponent={CustomPaper}
					disabled={loading}
					noOptionsText='Não encontrado'
					disableClearable
					value={formState.category}
					onChange={(_, value) => onChangeForm({ category: value })}
					options={categories}
					getOptionLabel={(option) => option?.label || ''}
					style={{ width: '100%' }}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option.value} style={{ fontFamily: 'Poppins' }}>
								{option.label}
							</li>
						)
					}}
					renderInput={(params) => (
						<div style={{ position: 'relative' }}>
							<CssTextField
								variant='standard'
								label={
									<div style={{ display: 'flex' }}>
										{loading ? (
											<LoadingIcon
												size={20}
												thickness={1}
												style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
											/>
										) : (
											<GridIcon size={20} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
										)}
										Categoria
									</div>
								}
								error={!!fieldErrors?.category?.error}
								helperText={fieldErrors?.category?.error}
								{...params}
							/>
						</div>
					)}
				/>
			</Grid>

			{!loading && (
				<Grid item xs={12}>
					<Collapse in={!loading}>
						<LocalNewInsightTerm
							formState={formState}
							onChangeForm={onChangeForm}
							fieldErrors={fieldErrors}
							statusType={statusType}
							sourceInfo={sourceInfo}
							hasNoCategorySelected={!formState?.category?.value}
						/>
					</Collapse>
				</Grid>
			)}
		</Grid>
	)
}

export default memo(GeneralNewInsight)
