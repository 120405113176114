import React, { useState, useEffect, memo } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import ComparativeBarItem from './components/ComparativeBarItem/ComparativeBarItem'
import SaveCompButton from './components/SaveCompButton/SaveCompButton'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import api from 'service/service'
import { saveAllCardsCanOpenAction } from 'store/redux/actions/card.actions'
import constantsVox from 'constants-vox'

const getUrl = (tab) => {
	if (tab === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
		return `/account-management/profile/get-all?folderId=ALL_ID&page=${null}`
	} else {
		return `/monitoring/card/get-all-by-user-id/${tab}?folderId=ALL_ID&page=${null}&isCardSearchingFilter=${null}&filterCardName=${null}`
	}
}

const ComparativeBar = ({ cards, style, searchPropsForComparativeBar, saveAllCardsCanOpenDispatch }) => {
	const [size, setSize] = useState(cards.length)
	const [newAllCardsCanOpen, setNewAllCardsCanOpen] = useState([])

	useEffect(() => {
		setSize(cards.length)
	}, [cards.length])

	useEffect(() => {
		let tab = searchPropsForComparativeBar.tab
		let sources = searchPropsForComparativeBar.sources
		api.get(getUrl(tab))
			.then((response) => {
				saveAllCardsCanOpenDispatch(response?.data?.items || [], { tab, sources })
				setNewAllCardsCanOpen(response?.data?.items.filter((card) => card?.cardStatus?.general === 'S') || [])
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}, [])

	return (
		<div style={style}>
			<div style={{ display: 'flex' }}>
				<Grid container spacing={3}>
					{newAllCardsCanOpen &&
						cards?.map((card, index) => (
							<ComparativeBarItem
								name={card.name}
								cardId={card.id}
								index={index}
								size={size}
								candidatesList={newAllCardsCanOpen}
								legendItems={cards}
								key={card.id}
								isSelected={true}
								isSolo={cards?.length == 1}
								card={card}
							/>
						))}
					{newAllCardsCanOpen && size !== 5 && (
						<ComparativeBarItem
							id='input-comparative'
							name='Comparar'
							index={size}
							size={size}
							candidatesList={newAllCardsCanOpen}
							legendItems={cards}
							isComp={true}
							isSolo={true}
							isSelected={false}
						/>
					)}
				</Grid>
				{size !== 1 && <SaveCompButton ids={cards} />}
			</div>
		</div>
	)
}

const mapStateToProps = ({ store: { card } }) => ({
	searchPropsForComparativeBar: card.searchPropsForComparativeBar
})
const mapDispatchToProps = (dispatch) => ({
	saveAllCardsCanOpenDispatch: (allCards, searchPropsForComparativeBar) =>
		dispatch(saveAllCardsCanOpenAction(allCards, searchPropsForComparativeBar))
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(ComparativeBar))
