import React, { memo } from 'react'
import { Grid } from '@mui/material'
import './OccurrencesContentBody.css'

import Occurrences from 'components/Occurrences/Occurrences'

const OccurrencesContentBody = ({ cards, filters, pageSize, orderDate, orderRelevance, type, wordCloudName }) => {
	return (
		<div className='occurrences-body-container'>
			<Grid container>
				<Grid item className='break-print'>
					<Grid item xs={12}>
						<Occurrences
							cards={cards}
							filters={filters}
							isReport={true}
							pageSize={pageSize}
							orderExternal={{ date: orderDate, relevance: orderRelevance }}
							type={type}
							filtersWordClouds={{ name: wordCloudName }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

export default memo(OccurrencesContentBody)
