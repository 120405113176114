import React from 'react'
import { Grid, TextField, styled } from '@mui/material'

const blue = 'rgba(29, 138, 248, 1)'
const grey = 'rgba(0, 0, 0, 0.65)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const RangeGlobalFilters = ({ icon, title, value, onChange, id = '' }) => {
	const onChangeValue = (newValue, type) => {
		onChange({ ...value, [type]: newValue })
	}

	return (
		<>
			<Grid container item xs={12} style={{ marginLeft: '0px', marginBottom: '2px', fontFamily: 'Poppins', fontSize: '15px', color: grey }}>
				{icon}
				{title}
			</Grid>
			<Grid container item xs={12} spacing={3}>
				<Grid item xs={6}>
					<CssTextField
						id={`${id}-higher`}
						variant='standard'
						onChange={(e) => onChangeValue(e.target.value, 'higher')}
						value={value.higher}
						label={<div>Maiores ou iguais a</div>}
						type='number'
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<CssTextField
						id={`${id}-lower`}
						variant='standard'
						value={value.lower}
						onChange={(e) => onChangeValue(e.target.value, 'lower')}
						label='Menores ou iguais a'
						type='number'
						fullWidth
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default RangeGlobalFilters
