import React, { memo, useEffect, useRef, useState } from 'react'
import { NotificationIcon } from 'utils/SystemIcons'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { Drawer, IconButton, Badge, styled } from '@mui/material'
import api from 'service/service'
import NotificationCard from './components/NotificationCard/NotificationCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationsSkeletonLoad from 'components/SkeletonLoads/NotificationsSkeletonLoad/NotificationsSkeletonLoad'

const isFirefox = typeof InstallTrigger !== 'undefined'
const overflow = isFirefox ? 'auto' : 'overlay'

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		backgroundColor: '#f50057'
	}
})

let maxResultsPerPage = 10
let isFirstRender = true
let page = 0
const DEFAULT_FEED_EMPTY = { loading: true, data: [], endResults: false }

const Notifications = ({ toggle, isOpen }) => {
	const [anchorMenu, setAnchorMenu] = useState(isOpen)
	const [notificationsFeed, setNotificationsFeed] = useState(DEFAULT_FEED_EMPTY)
	const [isLoadingUnreadToRead, setIsLoadingUnreadToRead] = useState(false)
	const [isReactQueryEnabled, setIsReactQueryEnabled] = useState(true)
	const isMounted = useRef(true)
	const scrollableDivNotifications = useRef(null)
	const { data, refetch, isFetching } = useUpdateData({
		url: `/notification/not-dismissed/${page}`,
		updateItemName: 'updateUnreadNotifications',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: isReactQueryEnabled
	})

	useEffect(() => {
		if (!anchorMenu && !isFirstRender && !isLoadingUnreadToRead && !isFetching && notificationsFeed?.data.length > 0 && isReactQueryEnabled) {
			page = 0
			setIsReactQueryEnabled(false)
			const hasNotificationsToRead = !!notificationsFeed.data?.find((it) => !it.read_at)
			if (hasNotificationsToRead) {
				setIsLoadingUnreadToRead(true)
				api.post('/notification/unread-to-read-all').finally(() => {
					setIsLoadingUnreadToRead(false)
					setNotificationsFeed(DEFAULT_FEED_EMPTY)
					setIsReactQueryEnabled(true)
					refetch()
				})
			} else {
				setNotificationsFeed(DEFAULT_FEED_EMPTY)
				setIsReactQueryEnabled(true)
				refetch()
			}
		}

		isFirstRender = false
	}, [anchorMenu])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (!isLoadingUnreadToRead && !isFetching && anchorMenu) {
			if (data?.notifications?.length > 0) {
				let newData = [...data.notifications]
				if (page > 0) {
					newData = [...notificationsFeed.data, ...newData]
				}
				setNotificationsFeed({
					data: newData,
					loading: false,
					endResults: data?.notifications?.length < maxResultsPerPage
				})
			} else {
				setNotificationsFeed({ ...notificationsFeed, loading: false })
			}
		}
	}, [data?.notifications, isLoadingUnreadToRead, anchorMenu, isFetching])

	useEffect(() => {
		setAnchorMenu(isOpen)
	}, [isOpen])

	const onClose = () => {
		toggle()
	}

	const loadMore = () => {
		page += 1
		refetch()
	}

	return (
		<>
			<IconButton size='small' onClick={() => toggle()} style={{ marginRight: '20px' }}>
				<StyledBadge badgeContent={data.notReadCount == 0 ? null : data.notReadCount} color='error' style={{ fontFamily: 'Poppins' }}>
					<NotificationIcon size={25} color={anchorMenu ? '#1d8cf8' : '#a3a3a3'} />
				</StyledBadge>
			</IconButton>
			<Drawer
				anchor='right'
				// elevation={2}
				open={anchorMenu}
				onClose={onClose}
				BackdropProps={{
					timeout: 500,
					style: { backdropFilter: 'blur(10px)', backgroundColor: 'rgba(200, 200, 200, 0.1)' }
				}}
				PaperProps={{
					style: {
						backdropFilter: 'blur(10px)',
						backgroundColor: 'rgba(250, 250, 250, 0.5)',
						border: '1px solid rgba( 255, 255, 255, 0.6 )',
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				<div
					ref={scrollableDivNotifications}
					id='scrollableDivNotifications'
					style={{ width: '450px', margin: 'auto', overflow: overflow, marginTop: '4em' }}
				>
					<div style={{ width: '100%', margin: 'auto', height: '100%' }}>
						{!(notificationsFeed?.loading || isLoadingUnreadToRead) && notificationsFeed?.data?.length > 0 && (
							<InfiniteScroll
								dataLength={notificationsFeed.data?.length}
								next={loadMore}
								hasMore={!notificationsFeed.endResults}
								loader={<NotificationsSkeletonLoad />}
								// endMessage={<OccurrencesEmptyFeed classification={filters.classification} tab={tab}/>}
								scrollableTarget='scrollableDivNotifications'
							>
								{notificationsFeed?.data?.map((it) => (
									<NotificationCard key={it.id} data={it} onClose={onClose} refetchNotifications={refetch} />
								))}
							</InfiniteScroll>
						)}
						{notificationsFeed?.data?.length === 0 && !(notificationsFeed?.loading || isLoadingUnreadToRead) && (
							<h2 style={{ color: '#e0e0e0', fontWeight: 'lighter', textAlign: 'center' }}>Nenhuma notificação encontrada</h2>
						)}
						{(notificationsFeed?.loading || isLoadingUnreadToRead || (isFetching && page === 0)) &&
							notificationsFeed?.data?.length === 0 && <NotificationsSkeletonLoad />}
					</div>
				</div>
			</Drawer>
		</>
	)
}

export default memo(Notifications)
