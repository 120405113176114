import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import ProfileInstagramHeader from '../ProfileInstagramHeader/ProfileInstagramHeader'
import { formatMetricsAccountManagerInstagram } from 'pages/AccountManagement/utils/accountManagerMetrics.utils'
import DragResizeDashboard from 'components/DragResizeDashboard/DragResizeDashboard'
import { getComponentsToRender } from './utils/AccountManagerMetricsInstagramUtils'
import AccountManagerAddWidgets from '../../modules/SocialProfileAccountManagement/components/AccountManagerAddWidgets/AccountManagerAddWidgets'

const AccountManagerMetricsInstagram = ({
	data,
	layout,
	onLayoutChange,
	editMode,
	addWidgetMode,
	toggleAddWidgetMode,
	widgetsOnscreen,
	handleWidgetsOnScreen,
	handleRemoveWidget,
	cards,
	descriptions
}) => {
	const [instagramMetrics, setInstagramMetrics] = useState(null)
	const [droppingItem, setDroppingItem] = useState({ i: 'defaultDrag', w: 0, h: 0 })
	const [isDropping, setIsDropping] = useState(false)

	useEffect(() => {
		setInstagramMetrics(formatMetricsAccountManagerInstagram(data))
	}, [data])

	const onChangeDragItem = (item) => {
		setDroppingItem(item)
	}

	const handleAddWidget = (widget, newLayout) => {
		onLayoutChange(newLayout, 'instagram')
		handleWidgetsOnScreen([...widgetsOnscreen, widget], 'instagram')
	}

	const setIsDroping = (droppingState) => {
		setIsDropping(droppingState)
	}

	const removeWidget = (keyName) => {
		handleRemoveWidget(keyName, 'instagram')
	}

	return (
		<>
			{data ? (
				<Grid container>
					<Grid item xs={addWidgetMode ? 10 : 12} style={{ marginLeft: '0.5em', paddingRight: addWidgetMode ? '1em' : '0.67em' }}>
						<ProfileInstagramHeader analytics={instagramMetrics?.analytics} />
					</Grid>
					<Grid item xs={addWidgetMode ? 10 : 12}>
						<DragResizeDashboard
							editMode={editMode}
							layouts={layout}
							onLayoutChange={(_, layout) => onLayoutChange({ md: layout.md, sm: layout.sm }, 'instagram')}
							droppingItem={droppingItem}
							handleAddWidget={handleAddWidget}
							addWidgetMode={addWidgetMode}
							isDroppable={isDropping}
						>
							{getComponentsToRender(instagramMetrics, editMode, addWidgetMode, removeWidget, cards, descriptions)
								.filter((widget) => widgetsOnscreen.includes(widget.key))
								.map((it) => (
									<div key={it.key}>{it.Component}</div>
								))}
						</DragResizeDashboard>
					</Grid>
					{addWidgetMode && (
						<Grid item xs={2} style={{ position: 'fixed', zIndex: '1', top: '8em', right: '1em', width: '100%', height: '50vh' }}>
							<AccountManagerAddWidgets
								addWidgetMode={addWidgetMode}
								toggleAddWidgetMode={toggleAddWidgetMode}
								onChangeDragItem={onChangeDragItem}
								widgetsOnscreen={widgetsOnscreen}
								setIsDroping={setIsDroping}
								source='instagram'
								descriptions={descriptions}
							/>
						</Grid>
					)}
				</Grid>
			) : (
				'Não possui a conta linkada'
			)}
		</>
	)
}

export default AccountManagerMetricsInstagram
