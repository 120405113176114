export default {
	AUTH_TOKEN_KEY: 'authToken',
	SHARING_AUTH_TOKEN_KEY: 'sharingToken',
	JAVASCRIPT: {
		FORMAT_DATETIMEZONE_DEFAULT_SYSTEM: 'YYYY-MM-DD HH:mm:ss ZZ', //Ex: 2021-03-10 10:16:04 -0300
		FORMAT_DATETIME_DEFAULT_SYSTEM: 'YYYY-MM-DD HH:mm:ss', //Ex: 2021-03-10 10:16:04
		FORMAT_DATE_DEFAULT_SYSTEM: 'YYYY-MM-DD', //Ex: 2021-03-10
		FORMAT_DATE_DEFAULT_BRAZIL: 'DD/MM/YYYY', //Ex: 10/03/2021
		FORMAT_TIME_DEFAULT_SYSTEM: 'HH:mm:ss' //Ex: 10:16:04
	},
	MANUAL_CLASSIFICATION_DICTIONARY: {
		POSITIVE: 'positive',
		NEGATIVE: 'negative'
	},
	SOCIAL_SOURCES: {
		TWITTER: 'TWITTER',
		FACEBOOK: 'FACEBOOK',
		INSTAGRAM: 'INSTAGRAM',
		TIKTOK: 'TIKTOK',
		NEWS: 'NEWS',
		META_ADS: 'META_ADS',
		YOUTUBE: 'YOUTUBE'
	},
	EXPORT_DATA: {
		STATUS: {
			PROCESSING: 'PROCESSING',
			DONE: 'DONE'
		}
	},
	CARD_TYPE: {
		TERM: 'TERM',
		ACCOUNT: 'PROFILE',
		GROUP: 'GROUP',
		REPORT: {
			GENERAL: 'REPORT',
			TWITTER: 'REPORT_TWITTER',
			FACEBOOK: 'REPORT_FACEBOOK'
		},
		ACCOUNT_MANAGEMENT: 'ACCOUNT_MANAGEMENT'
	},
	LOGO_REPORT: {
		DEFAULT_URL: 'https://s3.amazonaws.com/voxradar.public.static/voxradar-logo.png'
	},
	EMAIL_EXPORT: {
		HEADER_DEFAULT_URL: 'https://s3.amazonaws.com/static.email/email-header-vox-radar.png'
	},
	TOAST: {
		TYPES: {
			SUCCESS: 'SUCCESS',
			WARNING: 'WARNING',
			INFO: 'INFO',
			ERROR: 'ERROR'
		}
	},
	CARD_CATEGORY: {
		GENERAL: 'GENERAL',
		POLITICS: 'POLITICS',
		MEDIA_CHANNEL: 'MEDIA_CHANNEL',
		RELIGION: 'RELIGION'
	},
	CARD_PROFILE_UPDATE_POSTS: {
		STATUS: {
			PROCESSING: 'PROCESSING',
			SUCCESS: 'SUCCESS',
			INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
			INSUFFICIENT_CREDIT: 'INSUFFICIENT_CREDIT',
			ERROR: 'ERROR'
		}
	},
	CARD_TERM_TOPIC_ANALYSIS: {
		STATUS: {
			PROCESSING: 'PROCESSING',
			SUCCESS: 'SUCCESS',
			INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
			INSUFFICIENT_CREDIT: 'INSUFFICIENT_CREDIT',
			ERROR: 'ERROR'
		}
	},
	CARD: {
		SEARCH_TYPE: {
			TERM: 'TERM',
			PROFILE: 'PROFILE'
		},
		STATUS: {
			CREATE: 'C',
			ALTER: 'A',
			EMPTY: 'E',
			PENDING: 'P',
			SUCCESS: 'S',
			WRONG: 'W'
		}
	},
	SHARING: {
		DASHBOARD: {
			WEB_PATH: '/sharing/dashboard/'
		}
	},
	WHITE_LABEL: {
		BASE_DOMAINS: ['localhost:3000', 'dashboard-dev.voxradar.com.br', 'dashboard.voxradar.com.br']
	},
	MEDIA_TYPE: {
		IMAGE: 'image',
		VIDEO: 'video',
		FILE: 'file'
	},
	PRODUCT_TYPES: {
		ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
		SOCIAL_LISTENING: 'SOCIAL_LISTENING',
		FINANCIAL: 'FINANCIAL',
		AURASIGHT_MANAGER: 'AURASIGHT_MANAGER'
	}
}
