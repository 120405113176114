import React from 'react'
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const now = dayjs()
const minDateDefault = dayjs(moment('2020-01-01').tz('America/Sao_Paulo').toDate())

const DEFAULT_LABEL = 'Date'
const DEFAULT_FORMAT_VIEWS = ['day', 'month', 'year']

const BasicDatePicker = ({
	mask = 'dd/mm/yyyy',
	format = 'DD/MM/YYYY',
	label = DEFAULT_LABEL,
	formatViews = DEFAULT_FORMAT_VIEWS,
	showMaskOnFocus = true,
	showMaskOnHover = true,
	value = { now },
	onChange,
	style,
	minDate = minDateDefault,
	maxDate = now
}) => {
	const formattedValue = typeof value === 'string' ? dayjs(value) : value
	return (
		<div style={style}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={['DatePicker']}>
					<DatePicker
						format={format}
						style={{ padding: 0 }}
						mask={mask}
						showMaskOnFocus={showMaskOnFocus}
						showMaskOnHover={showMaskOnHover}
						value={formattedValue}
						onChange={onChange}
						label={label}
						views={formatViews}
						slotProps={{ textField: { size: 'small' } }}
						maxDate={maxDate}
						minDate={minDate}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</div>
	)
}

export default BasicDatePicker
