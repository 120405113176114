import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { CardContent, Grid } from '@mui/material'
import { setLegendColorDictonary, getLegendColorById } from 'utils/graphFunctions'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar'
		},
		labels: [''],
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true
			}
		}
	},
	series: [{ data: [0] }]
}

const GeneralMetric = ({ data, title, icon, metric, xs, isReport, cardIds = data.map((card) => card.cardId) }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let colorDictionary = setLegendColorDictonary(cardIds)
		let colors = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			if (metric === 'posts_per_author') {
				let postsPerAuthor = 0
				if (element?.selectedInterval?.metrics.author_count_original === null) {
					continue
				}

				if (element?.selectedInterval?.metrics.author_count_original !== 0) {
					postsPerAuthor = (
						element?.selectedInterval?.metrics.post_count / element?.selectedInterval?.metrics.author_count_original
					).toFixed(2)
				}
				let value = postsPerAuthor
				isNaN(value) || value === null || value === undefined ? (value = 0) : true
				seriesData.push(value)
			} else {
				if (element?.selectedInterval?.metrics[metric] !== null) {
					seriesData.push(element?.selectedInterval?.metrics[metric] || 0)
				}
			}
			colors.push(getLegendColorById(element.cardId, colorDictionary))
		}

		const optionsBars = {
			colors: colors,
			chart: {
				type: 'bar',
				toolbar: {
					show: !isReport
				}
			},
			plotOptions: {
				bar: {
					borderRadius: 8,
					horizontal: true,
					dataLabels: {
						position: 'bottom'
					},
					distributed: true
				}
			},
			tooltip: {
				enabled: true
			},
			legend: {
				show: false,
				position: 'bottom'
			},
			dataLabels: {
				enabled: true,
				textAnchor: 'start',
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto',
					colors: colors
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: 'transparent',
					opacity: 0.15
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: true,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series: [{ name: title, data: seriesData }]
		}
		setBarsData(apexData)
	}
	if (barsData.series.every((it) => it.data.length < 1)) {
		return <></>
	}

	return (
		<Grid item xs={xs}>
			<CardContent>
				<h4 style={{ textAlign: 'left', font: 'Poppins', fontWeight: 'lighter', fontSize: '15px' }}>
					{icon}
					{title}
				</h4>
				<Chart
					style={{
						color: 'black',
						textAlign: 'left',
						fontFamily: 'Poppins'
					}}
					options={barsData.options}
					series={barsData.series}
					type='bar'
					width='100%'
					height={150}
				/>
			</CardContent>
		</Grid>
	)
}

export default GeneralMetric
