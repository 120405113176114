import React, { useEffect, useState, memo } from 'react'
import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Drawer, styled } from '@mui/material'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import { ShareIcon, ChevronRightIcon, LinkShareIcon } from 'utils/SystemIcons'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import DateInput from 'components/DateInput/DateInput'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import constantsVox from 'constants-vox'

const isFirefox = typeof InstallTrigger !== 'undefined'

const DEFAULT_SHARING_EXPIRE_DATE = dayjs(moment().add(1, 'w').tz('America/Sao_Paulo').toDate())
const MIN_DATE = dayjs()
const MAX_DATE = dayjs(moment().add(2, 'M').tz('America/Sao_Paulo').toDate())
const SERVICE_HISTORY_NAME = 'sharingDashboard'

const GET_DEFAULT_SHARING_DASHBOARD = (layouts, widgets, name) => ({
	name: name,
	expireDate: DEFAULT_SHARING_EXPIRE_DATE,
	layouts,
	widgets
})

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const SharingDashboardToolbar = ({ isOpen, toggleShareMenu, cards, currentLayout, activeWidgets }) => {
	const [sharingDashboard, setSharingDashboard] = useState(GET_DEFAULT_SHARING_DASHBOARD(currentLayout, activeWidgets, cards[0].name))
	const [submitting, setSubmitting] = useState(false)
	const [openHistory, setOpenHistory] = useState(false)
	const cardType = cards[0].type
	const cardsIds = cards.map((card) => card.id)

	useEffect(() => {
		setSharingDashboard((prevState) => ({ ...prevState, layouts: currentLayout, widgets: activeWidgets }))
	}, [currentLayout, activeWidgets])

	const toggleOpenGenerateDrawer = () => {
		toggleShareMenu()
		setSharingDashboard(GET_DEFAULT_SHARING_DASHBOARD(currentLayout, activeWidgets, cards[0].name))
	}

	const handleOnGenerateLink = () => {
		setSubmitting(true)
		api.post('/sharing/dashboard/generate-link', {
			cardType,
			referenceIds: cardsIds,
			sharingDashboard
		})
			.then((response) => {
				const baseUrl = window.location.origin
				const path = constantsVox.SHARING.DASHBOARD.WEB_PATH
				const fullLink = baseUrl + path + response.data.sharingLinkToken
				navigator.clipboard.writeText(fullLink)
				sendMessageToast('Link copiado com sucesso!', constantsVox.TOAST.TYPES.SUCCESS)
				toggleOpenGenerateDrawer()
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const handleChangeCreateDashboard = (field, value) => {
		setSharingDashboard((prevState) => ({ ...prevState, [field]: value }))
	}

	const toggleOpenHistory = () => {
		setOpenHistory((prevState) => !prevState)
	}

	return (
		<Drawer
			anchor='right'
			open={isOpen}
			onClose={() => toggleOpenGenerateDrawer()}
			className='right-drawer-system'
			BackdropProps={{
				timeout: 500,
				style: { backdropFilter: 'blur(3px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
			}}
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<IconButton id='btn-toggle-save-menu-dashboard' color='primary' onClick={toggleShareMenu}>
					<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
				</IconButton>
			</Grid>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						backgroundColor: 'white',
						paddingTop: '0.5em',
						paddingLeft: '1em',
						paddingRight: '1em',
						paddingBottom: '1em',
						width: '25em',
						height: '18em',
						marginTop: '1em',
						marginLeft: '3em',
						borderRadius: '25px'
					}}
				>
					<Grid item xs={12} style={{ fontFamily: 'Poppins' }} container justifyContent='flex-start' alignItems='center'>
						<ShareIcon size={25} style={{ color: '#1d8cf8', marginRight: '10px' }} />
						Compartilhar Dashboard
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em' }}>
						<CssTextField
							id='input-dashboard-name-share'
							disabled={submitting}
							fullWidth
							variant='standard'
							onChange={(event) => handleChangeCreateDashboard('name', event.target.value)}
							value={sharingDashboard.name}
							label={'Nome do dashboard'}
						/>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em', marginBottom: '2em' }} container justifyContent='center'>
						<DateInput
							value={sharingDashboard.expireDate}
							onChange={(expireDate) => handleChangeCreateDashboard('expireDate', dayjs(expireDate).format('YYYY-MM-DD'))}
							label='Data de expiração'
							style={{ borderRadius: '4px' }}
							minDate={MIN_DATE}
							maxDate={MAX_DATE}
						/>
					</Grid>
					<Grid item xs={12} container justifyContent='space-between' alignItems='center'>
						<Button
							id='btn-copy-link-dashboard'
							disabled={sharingDashboard.name === '' || submitting}
							onClick={handleOnGenerateLink}
							variant='outlined'
							style={{ borderRadius: '100px', fontFamily: 'Poppins' }}
						>
							<LinkShareIcon size={25} style={{ color: '#1d8cf8', marginRight: '10px' }} />
							Copiar Link
						</Button>
						<Button onClick={toggleOpenHistory} disabled={submitting} style={{ fontFamily: 'Poppins', borderRadius: '100px' }}>
							Ver compartilhados
						</Button>
					</Grid>
				</Grid>
			</div>
			<Dialog
				id='dialog-history-sharing-dashboard'
				open={openHistory}
				style={{ marginTop: '-6%' }}
				onClose={toggleOpenHistory}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
						maxWidth: '80em'
					}
				}}
				BackdropProps={{
					style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
				}}
				fullWidth={true}
				maxWidth='lg'
			>
				<DialogContent style={{ padding: 0, margin: 0, height: '590px', position: 'relative' }}>
					<ServiceHistory
						style={{ width: '100%', height: '100%' }}
						isOpen={openHistory}
						toggleOpenHistory={toggleOpenHistory}
						cardType={constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT}
						serviceName={SERVICE_HISTORY_NAME}
						customTitle='Histórico de links compartilhados'
						rightButton={true}
					/>
				</DialogContent>
			</Dialog>
		</Drawer>
	)
}

export default memo(SharingDashboardToolbar)
