import React, { memo } from 'react'
import { Button, Grid, Collapse, Card, Divider } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import CheckButtonGroup from 'components/CheckButtonGroup/CheckButtonGroup'
const lightGrey = 'rgba(184, 184, 184, 100%)'

const AuraSightGlobalFilters = ({ toggleFilters, openFilters, onChange, valueControlled }) => {
	const aurasightStates = [
		{ key: 'hasMediaInference', label: 'Mídias com inferência', checked: valueControlled.hasMediaInference },
		{ key: 'hasMediaDownload', label: 'Mídias com download', checked: valueControlled.hasMediaDownload }
	]

	const onChangeAuraSightStates = (list) => {
		let aurasightStatesNew = {}

		list.forEach((item) => {
			aurasightStatesNew[item.key] = item.checked
		})
		onChange(aurasightStatesNew)
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Button
					id='btn-toggle-classification'
					onClick={toggleFilters}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openFilters && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openFilters && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Aurasight
				</Button>

				<Collapse in={openFilters} style={{ width: '100%' }}>
					<Grid container style={{ marginTop: '5px' }}>
						<Grid item xs={12} container>
							{/* <Grid xs={3} item>
								<div
									style={{
										height: '48px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										fontFamily: 'Poppins',
										fontWeight: 'bold',
										fontSize: '13px',
										color: '#707070'
									}}
								>
									Aurasight:
								</div>
							</Grid> */}
							<Grid xs={12} item>
								<CheckButtonGroup
									checkedControlled={aurasightStates}
									onChange={onChangeAuraSightStates}
									fontSize='1.2em'
									uncheckedColor={lightGrey}
								/>
							</Grid>
							<Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)', height: '0.5px', width: '100%', marginTop: '-1px' }} />
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Card>
	)
}

export default memo(AuraSightGlobalFilters)
