import React, { useState, useEffect, memo, useRef } from 'react'
import { HiOutlineTranslate } from 'react-icons/hi'
import { Grid, TextField, Collapse, Autocomplete, Paper, styled } from '@mui/material'
import api from 'service/service'
import { GlobeIcon, LoadingIcon, FlagIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const LocalNewInsight = ({ formState, fieldErrors, onChangeForm, statusType, sourceInfo, hasNoCategorySelected }) => {
	const [countries, setCountries] = useState([])
	const [languages, setLanguages] = useState([])
	const [politicalParties, setPoliticalParties] = useState([])
	const [loading, setLoading] = useState(true)
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	const load = async () => {
		setLoading(true)
		const countries = await api.get('/search-country/all')
		const thisCountries = countries?.data?.map((country) => ({
			label: country.printable_name_pt,
			value: country.id,
			lang_id: country.languages_id
		}))

		const languages = await api.get('/search-language/all')
		const thisLanguages = languages?.data?.map((language) => ({
			label: language.language,
			value: language.id
		}))

		const parties = await api.get('/search-party/all')
		const thisParties = parties?.data?.map((party) => ({
			id: party.id,
			label: party.initials,
			value: party.number,
			name: party.name,
			classification: party.classification
		}))

		if (isMounted.current) {
			setCountries(thisCountries)
			setLanguages(thisLanguages)
			setPoliticalParties(thisParties)
			if (statusType === 'edit') {
				const editForm = {
					country: thisCountries.filter((country) => country.label === sourceInfo?.printableName)[0],
					language: thisLanguages.filter((language) => language.label === sourceInfo?.generalLanguage)[0],
					politicalParty: thisParties.filter((party) => party.label === sourceInfo?.initials)[0] || ''
				}
				onChangeForm(editForm)
			}
			setLoading(false)
		}
	}

	const handleSelectCountry = (country) => {
		const languageFound = languages?.find((l) => l.value === country.lang_id)
		if (languageFound) {
			onChangeForm({ country, language: languageFound })
		} else {
			onChangeForm({ country })
		}
	}

	return (
		<Grid container spacing={3}>
			{/* <Grid item xs={12} className='mb-2i mt-1i'>
				<span className='color-blue fs-1-5'>Localidade</span>
			</Grid> */}
			<Grid item xs={12}>
				<Autocomplete
					className='adjust-autocomplete'
					freeSolo
					PaperComponent={CustomPaper}
					disabled={loading || hasNoCategorySelected}
					noOptionsText='Não encontrado'
					disableClearable
					value={formState.country}
					onChange={(_, value) => handleSelectCountry(value)}
					options={countries}
					getOptionLabel={(option) => option?.label || ''}
					style={{ width: '100%' }}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option.value} style={{ fontFamily: 'Poppins' }}>
								{option.label}
							</li>
						)
					}}
					renderInput={(params) => (
						<div style={{ position: 'relative' }}>
							<CssTextField
								variant='standard'
								label={
									<div style={{ display: 'flex' }}>
										{loading ? (
											<LoadingIcon
												size={20}
												thickness={1}
												style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
											/>
										) : (
											<GlobeIcon size={20} style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
										)}
										País
									</div>
								}
								error={!!fieldErrors?.country?.error}
								helperText={fieldErrors?.country?.error}
								{...params}
							/>
						</div>
					)}
				/>
			</Grid>
			<br />
			<Grid item xs={12}>
				<Grid item xs={12}>
					<Autocomplete
						className='adjust-autocomplete'
						freeSolo
						PaperComponent={CustomPaper}
						disabled={loading || hasNoCategorySelected}
						noOptionsText='Não encontrado'
						disableClearable
						value={formState.language}
						onChange={(_, value) => onChangeForm({ language: value })}
						options={languages}
						getOptionLabel={(option) => option?.label || ''}
						style={{ width: '100%' }}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.value} style={{ fontFamily: 'Poppins' }}>
									{option.label}
								</li>
							)
						}}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<CssTextField
									variant='standard'
									label={
										<div style={{ display: 'flex' }}>
											{loading ? (
												<LoadingIcon
													size={20}
													thickness={1}
													style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
												/>
											) : (
												<HiOutlineTranslate
													size={20}
													style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
												/>
											)}
											Idioma
										</div>
									}
									error={!!fieldErrors?.language?.error}
									helperText={fieldErrors?.language?.error}
									{...params}
								/>
							</div>
						)}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ marginTop: formState?.category?.value === 2 && formState?.country?.label === 'Brasil' && '1.5em' }}>
				<Collapse in={formState?.category?.value === 2 && formState?.country?.label === 'Brasil'}>
					<Autocomplete
						className='adjust-autocomplete'
						freeSolo
						PaperComponent={CustomPaper}
						disabled={loading}
						noOptionsText='Não encontrado'
						disableClearable
						value={formState.politicalParty}
						onChange={(_, value) => onChangeForm({ politicalParty: value })}
						options={politicalParties}
						getOptionLabel={(option) => option?.label || ''}
						style={{ width: '100%' }}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id} style={{ fontFamily: 'Poppins' }}>
									{option.label}
								</li>
							)
						}}
						renderInput={(params) => (
							<div style={{ position: 'relative' }}>
								<CssTextField
									variant='standard'
									label={
										<div style={{ display: 'flex' }}>
											{loading ? (
												<LoadingIcon
													size={20}
													thickness={1}
													style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
												/>
											) : (
												<FlagIcon
													size={20}
													style={{ color: '#1d8cf8', transform: 'translate(0px, 2px)', marginRight: '7px' }}
												/>
											)}
											Partido
										</div>
									}
									error={!!fieldErrors?.politicalParty?.error}
									helperText={fieldErrors?.politicalParty?.error}
									{...params}
								/>
							</div>
						)}
					/>
				</Collapse>
			</Grid>
		</Grid>
	)
}

export default memo(LocalNewInsight)
