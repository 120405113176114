import React, { useState, memo } from 'react'
import { Grid, Card, Button, Switch, Collapse } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import { toggleSideMenuOption } from '../../utils/SideMenuFunctions'

const MultiOption = ({ mainChecked, isComp, MainTitle, option, value, thisOptions, onChangeOptions, content, sources }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Grid item xs={12} container direction='row' alignContent='center' justifyContent='space-between'>
			<Card
				elevation={0}
				style={{
					borderRadius: '20px',
					marginTop: '1em',
					margin: '10px',
					width: '95%',
					backgroundColor: 'rgba(255,255,255,0.6)'
				}}
			>
				<Grid container justifyContent='space-between'>
					<Grid item xs={isComp ? 12 : 9}>
						<Button
							id={`btn-menu-collapse-${option}`}
							onClick={() => setIsOpen(!isOpen)}
							fullWidth
							style={{
								height: '36.5px',
								fontFamily: 'Poppins',
								fontWeight: 'bold',
								color: '#1d8cf8',
								justifyContent: 'flex-start',
								borderRadius: '0px',
								width: '100%',
								backgroundColor: 'rgba(255,255,255,1)'
							}}
						>
							{!isOpen && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
							{isOpen && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
							{MainTitle}
						</Button>
					</Grid>
					{!isComp && (
						<Grid item xs={3} container style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
							<div style={{ marginLeft: '29px' }}>
								<Switch
									onClick={() => toggleSideMenuOption(option, value, thisOptions, onChangeOptions, isComp, sources)}
									color='primary'
									checked={mainChecked}
								/>
							</div>
						</Grid>
					)}
				</Grid>
				<Collapse in={isOpen}>
					<div style={{ height: '10px' }} />
					<Grid container>{content}</Grid>
				</Collapse>
			</Card>
		</Grid>
	)
}
export default memo(MultiOption)
