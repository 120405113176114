import { TwitterIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, RadioWavesIcon } from 'utils/SystemIcons'
export {
	TAGS_REGEX_TWITTER,
	TAGS_REGEX_FACEBOOK,
	TAGS_REGEX_INSTAGRAM,
	TAGS_REGEX_TIKTOK,
	TAGS_REGEX_YOUTUBE,
	RED,
	BLUE,
	WHITE,
	GRAY,
	SOURCE_ELEMENTS
}

const TAGS_REGEX_TWITTER = /^[a-zA-Z0-9_@;]+$/
const TAGS_REGEX_FACEBOOK = /^[a-zA-Z0-9.@;]+$/
const TAGS_REGEX_INSTAGRAM = /^[a-zA-Z0-9.@;]+$/
const TAGS_REGEX_TIKTOK = /^[a-zA-Z0-9.@;]+$/
const TAGS_REGEX_YOUTUBE = /^[a-zA-Z0-9.@;]+$/
const TAGS_REGEX_NEWS = /^[^\p{Z}\s]+$/u

const RED = 'rgba(255, 43, 107, 100%)'
const BLUE = 'rgba(29, 138, 248, 100%)'
const WHITE = '#ffffff'
const GRAY = '#9a9a9a'

const SOURCE_ELEMENTS = {
	twitter: {
		icon: TwitterIcon,
		name: 'Twitter',
		type: 'conta',
		tagsRegex: TAGS_REGEX_TWITTER
	},
	facebook: {
		icon: FacebookIcon,
		name: 'Facebook',
		type: 'página',
		tagsRegex: TAGS_REGEX_FACEBOOK
	},
	instagram: {
		icon: InstagramIcon,
		name: 'Instagram',
		type: 'conta',
		tagsRegex: TAGS_REGEX_INSTAGRAM
	},
	tiktok: {
		icon: TiktokIcon,
		name: 'Tiktok',
		type: 'conta',
		tagsRegex: TAGS_REGEX_TIKTOK
	},
	youtube: {
		icon: YoutubeIcon,
		name: 'Youtube',
		type: 'conta',
		tagsRegex: TAGS_REGEX_YOUTUBE
	},
	news: {
		icon: RadioWavesIcon,
		name: 'Notícias',
		type: 'domínio',
		tagsRegex: TAGS_REGEX_NEWS
	}
}
