import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, Card, Button } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import PostTags from 'components/Occurrences/components/OccurrencesFeed/components/OccurrenceCard/components/PostTags/PostTags'

const TagsFilters = ({ onTagsChange, toggleTags, openTags, newFilters }) => {
	const [thisTags, setThisTags] = useState(newFilters?.tagsGlobalFilters || [])
	const isMounted = useRef(true)

	useEffect(() => {
		setThisTags(newFilters?.tagsGlobalFilters)
	}, [newFilters?.tagsGlobalFilters])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		onTagsChange(thisTags)
	}, [thisTags])

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginTop: '1em',
				width: '100%',
				backgroundColor: 'rgba(255,255,255,0.6)',
				border: '1px solid rgba(200, 200, 200, 0.2)'
			}}
		>
			<Grid container item xs={12}>
				<Button
					id='btn-toggle-tags-filter'
					onClick={toggleTags}
					fullWidth
					style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#1d8cf8', justifyContent: 'flex-start' }}
				>
					{!openTags && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
					{openTags && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
					Tags
				</Button>
			</Grid>
			<PostTags
				style={{ padding: '0em 1em 0em 1em' }}
				isOpen={openTags}
				isFocused={false}
				occurrenceId={null}
				cardId={null}
				sources={null}
				initialTags={thisTags}
				isFilter={true}
				onChangeTags={setThisTags}
				toggleTags={toggleTags}
			/>
		</Card>
	)
}

export default memo(TagsFilters)
