import { CircularProgress, TextField, styled, Button, DialogTitle, DialogContent, Dialog } from '@mui/material'
import React, { useState } from 'react'
import api from 'service/service'
import ImageUploader from 'react-images-upload'
import imageCompression from 'browser-image-compression'
import blobToBase64 from 'utils/blobToBase64'

const FormContainer = styled('form')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(2),
	maxWidth: 400,
	margin: '0 auto',
	padding: theme.spacing(2),
	backgroundColor: '#f7f7f7',
	borderRadius: theme.spacing(1)
}))

const SubmitButton = styled(Button)(({ theme }) => ({
	backgroundColor: '#1976d2',
	color: '#fff',
	'&:hover': {
		backgroundColor: '#1565c0'
	}
}))

const ImageAnnotationCreateLogo = ({ isOpen, handleOnClose }) => {
	const [name, setName] = useState('')
	const [cleanName, setCleanName] = useState('')
	const [image, setImage] = useState(null)
	const [loading, setLoading] = useState(false)

	const handleImageChange = async (imageFile, image64) => {
		if (image64[0].startsWith('data:image/webp')) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
				fileType: 'image/jpeg' // Specify the output format as JPEG
			}
			const compressedFile = await imageCompression(imageFile[0], options)
			setImage(await blobToBase64(compressedFile))
		} else {
			setImage(image64[0])
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		setLoading(true)

		try {
			await api.post('/image-annotation/create-logo', { name, cleanName, image })
			alert('Logo criado com sucesso!')
		} catch (error) {
			console.error('Erro ao criar logo:', error)
			alert('Erro ao criar logo.')
		} finally {
			setLoading(false)
			handleOnClose()
		}
	}

	return (
		<Dialog open={isOpen} onClose={handleOnClose}>
			<DialogTitle>Criar Novo Logo</DialogTitle>
			<DialogContent>
				<FormContainer onSubmit={handleSubmit}>
					<TextField label='Nome do Logo' value={name} onChange={(e) => setName(e.target.value)} variant='outlined' required />
					<TextField
						label='Nome Limpo do Logo'
						value={cleanName}
						onChange={(e) => setCleanName(e.target.value)}
						variant='outlined'
						required
					/>
					<ImageUploader
						buttonClassName='image-button'
						withIcon={false}
						withLabel={true}
						label={image && <img src={`${image}`} alt='logo' style={{ width: '400px', height: '300px' }} />}
						buttonText={'Selecionar imagem'}
						fileContainerStyle={{
							backgroundColor: 'transparent',
							boxShadow: 'none'
						}}
						onChange={handleImageChange}
						singleImage
						imgExtension={['.png', '.jpg', '.jpeg', '.webp']}
						maxFileSize={5242880}
					/>
					<SubmitButton type='submit' variant='contained' disabled={loading}>
						{loading ? <CircularProgress size={24} /> : 'Criar Logo'}
					</SubmitButton>
				</FormContainer>
			</DialogContent>
		</Dialog>
	)
}

export default ImageAnnotationCreateLogo
