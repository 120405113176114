import React from 'react'
import { BsFillCircleFill } from 'react-icons/bs'
import { setLegendColorDictonary, getLegendColorById } from 'utils/graphFunctions'

const GraphLegend = ({ title, id, cardIds }) => {
	const getColor = () => {
		if (id && cardIds) {
			let colorDictionary = setLegendColorDictonary(cardIds)
			return getLegendColorById(id, colorDictionary)
		} else {
			return '#008FFB'
		}
	}

	return (
		<div
			style={{
				position: 'absolute',
				display: 'flex',
				fontFamily: 'Poppins',
				marginLeft: '4em',
				color: '#67779e',
				marginTop: '-0.6em',
				whiteSpace: 'nowrap'
			}}
		>
			{title !== '' && (
				<div>
					<BsFillCircleFill size={10} style={{ color: getColor(), position: 'relative', transform: 'translate(-2px, -1px)' }} />
				</div>
			)}
			<div style={{ textTransform: 'capitalize', marginTop: '-1.5px', marginLeft: '5px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
				{title}
			</div>
		</div>
	)
}

export default GraphLegend
