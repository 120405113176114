import React, { useState, useEffect, memo } from 'react'
import { ChevronUpIcon, OrderIcon, ExportIcon, SearchIcon, TagIcon, PlusIcon, SelectMultipleIcon } from 'utils/SystemIcons'
import { Card, Grid, Collapse, Button, Tooltip, IconButton, TextField, Divider, Badge } from '@mui/material'
import FilterMenu from './components/FilterMenu/FilterMenu'
import OccurrencesCardSelector from './components/OccurrencesCardSelector/OccurrencesCardSelector'
import { itemActiveColor, getFirstCard } from '../../utils/occurrencesFunctions'
import styled from '@emotion/styled'
import ExportPostsData from './components/ExportPostsData/ExportPostsData'
import ModalAddOccurrence from './components/ModalAddOccurrence/ModalAddOccurrence'
import constantsVox from 'constants-vox'

const tooltipDelay = 500

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		right: -6,
		top: 12,
		backgroundColor: '#f50057'
	}
})

const CssTextFieldSearch = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const FilterBar = ({
	cards,
	onCardChange,
	orderFiltersControlled,
	onOrderFiltersChange,
	isDialog = false,
	loading,
	mainComparison,
	filters,
	hideCardSelector,
	type,
	onEnterSearch,
	isComment,
	wordCloudName,
	onChangeExternalOpenTags,
	externalOpenTags,
	isTopPosts = false,
	selectedPostsIds,
	onChangeExternalMultipleSelection,
	externalMultipleSelection,
	isWidget = false
}) => {
	const [selectedCard, setSelectedCard] = useState(getFirstCard(cards, mainComparison))
	const [openedMenuItem, setOpenedMenuItem] = useState(null)
	const [orderFilters, setOrderFilters] = useState(orderFiltersControlled)
	const [allCardsSelect, setAllCardsSelect] = useState(cards)
	const [isFocused, setIsFocused] = useState(false)
	const [contentTextSearch, setcontentTextSearch] = useState('')

	useEffect(() => {
		setAllCardsSelect(cards)
		setSelectedCard(getFirstCard(cards, mainComparison))
	}, [cards])

	useEffect(() => {
		onOrderFiltersChange(orderFilters)
	}, [orderFilters])

	const handleOpenMenuItem = (itemName) => {
		if (itemName === openedMenuItem) {
			setOpenedMenuItem(null)
		} else {
			setOpenedMenuItem(itemName)
		}
	}

	const onCardChangeFilter = (newCard) => {
		setSelectedCard(newCard)
		onCardChange(newCard)
	}

	const onFilterMenuChange = (order) => {
		setOrderFilters(order)
	}

	useEffect(() => {
		setOpenedMenuItem(null)
	}, [filters])

	const handleChangeSearch = (text) => {
		setcontentTextSearch(text)
	}

	return (
		<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
			<Card
				elevation={0}
				style={{
					flexDirection: 'column',
					width: isDialog ? '95%' : '60%',
					marginRight: isWidget ? '0px' : '9px',
					marginTop: '1.5em',
					marginBottom: '10px',
					pointerEvents: loading ? 'none' : 'all',
					borderRadius: isWidget ? '0px' : '20px',
					borderBottom: '1px solid rgba(28,110,164,0.08)',
					marginLeft: isDialog ? '0.9em' : '0'
				}}
			>
				<Grid spacing={0} container justifyContent={'flex-end'}>
					{!isTopPosts && (
						<Grid item xs={4} container direction='row' justifyContent='flex-start' alignItems='center'>
							<Tooltip title='Ordenar' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<IconButton
									style={{ outline: 'none', float: 'right', backgroundColor: 'transparent', marginLeft: '10px' }}
									onClick={() => handleOpenMenuItem('more')}
								>
									<OrderIcon size={20} style={{ color: itemActiveColor(openedMenuItem === 'more') }} />
								</IconButton>
							</Tooltip>
							{type === constantsVox.CARD_TYPE.TERM.toLowerCase() && !isComment && (
								<Tooltip title='Exportar postagens' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<IconButton
										style={{ outline: 'none', float: 'right', backgroundColor: 'transparent' }}
										onClick={() => handleOpenMenuItem('export')}
									>
										<StyledBadge
											badgeContent={selectedPostsIds.length == 0 ? null : selectedPostsIds.length}
											color='error'
											style={{ fontFamily: 'Poppins' }}
											variant={selectedPostsIds.length > 0 ? 'dot' : ''}
										>
											<ExportIcon size={20} style={{ color: itemActiveColor(openedMenuItem === 'export') }} />
										</StyledBadge>
									</IconButton>
								</Tooltip>
							)}
							{cards[0]?.type === 'term' && (
								<Tooltip title='Adicionar postagem' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
									<IconButton
										style={{ outline: 'none', float: 'right', backgroundColor: 'transparent' }}
										onClick={() => handleOpenMenuItem('addOccurrence')}
									>
										<PlusIcon size={20} style={{ color: itemActiveColor(openedMenuItem === 'addOccurrence') }} />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					)}
					<Grid item xs={4}>
						{isComment && (
							<CssTextFieldSearch
								id='input-search-text-comments'
								variant='standard'
								InputLabelProps={{ shrink: (contentTextSearch !== '' && !!contentTextSearch) || isFocused }}
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
								value={contentTextSearch}
								onChange={(e) => handleChangeSearch(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										onEnterSearch(null, contentTextSearch)
									}
								}}
								fullWidth
								label={
									<div style={{ display: 'flex' }}>
										<SearchIcon size={17} style={{ color: blue, marginTop: '4px' }} />
										<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>
											Buscar nos comentários
										</div>
									</div>
								}
							/>
						)}
					</Grid>
					{!isTopPosts && (
						<Grid
							item
							xs={4}
							style={{
								alignContent: 'center',
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'flex-end'
							}}
						>
							<>
								{type === 'term' && (
									<Tooltip
										title={selectedPostsIds?.length > 0 ? 'Limpar seleção' : 'Selecionar multiplos posts'}
										enterDelay={tooltipDelay}
										enterNextDelay={tooltipDelay}
									>
										<IconButton
											id='btn-multiple-selection'
											style={{ outline: 'none', float: 'right', backgroundColor: 'transparent' }}
											onClick={onChangeExternalMultipleSelection}
										>
											<SelectMultipleIcon size={20} style={{ color: externalMultipleSelection ? blue : grey }} />
										</IconButton>
									</Tooltip>
								)}
							</>
							<>
								{type === 'term' && (
									<Tooltip title='Ver Tags' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
										<IconButton
											id='btn-show-occurrences-tags'
											style={{ outline: 'none', float: 'right', backgroundColor: 'transparent' }}
											onClick={onChangeExternalOpenTags}
										>
											<TagIcon size={20} style={{ color: externalOpenTags ? blue : grey }} />
										</IconButton>
									</Tooltip>
								)}
							</>
						</Grid>
					)}
					{allCardsSelect.length > 1 && (
						<Divider style={{ backgroundColor: 'rgba(200,200,200,0.1)', height: '1px', marginTop: '-1px', width: '100%' }} />
					)}
					<Grid item xs={12} container justifyContent={isTopPosts ? 'flex-start' : 'center'}>
						{allCardsSelect && !hideCardSelector && (
							<Grid item xs={4}>
								<OccurrencesCardSelector cards={allCardsSelect} onCardChange={onCardChangeFilter} selectedCard={selectedCard} />
							</Grid>
						)}
					</Grid>
				</Grid>
				{type === constantsVox.CARD_TYPE.TERM.toLowerCase() && (
					<Collapse in={openedMenuItem === 'export'} unmountOnExit>
						<ExportPostsData
							cardId={selectedCard.id}
							cardStatus={selectedCard?.cardStatus}
							filters={filters}
							type={type}
							wordCloudName={wordCloudName}
							cardName={selectedCard?.name}
							selectedPostsIds={selectedPostsIds}
						/>
						<Grid container direction='row' justifyContent='center' alignItems='center'>
							<Button onClick={() => handleOpenMenuItem('export')} fullWidth>
								<ChevronUpIcon size={20} style={{ color: '#1d8cf8' }} />
							</Button>
						</Grid>
					</Collapse>
				)}
				<Collapse in={openedMenuItem === 'more'} unmountOnExit>
					<FilterMenu
						HandleClickMore={() => handleOpenMenuItem('more')}
						onFilterMenuChange={onFilterMenuChange}
						openMenu={openedMenuItem === 'more'}
						orderFilters={orderFilters}
					/>
					<Grid container direction='row' justifyContent='center' alignItems='center'>
						<Button onClick={() => handleOpenMenuItem('more')} fullWidth>
							<ChevronUpIcon size={20} style={{ color: '#1d8cf8' }} />
						</Button>
					</Grid>
				</Collapse>
				{openedMenuItem === 'addOccurrence' && (
					<ModalAddOccurrence
						isOpen={openedMenuItem === 'addOccurrence'}
						handleOnClose={() => handleOpenMenuItem(null)}
						cardId={selectedCard.id}
						cardName={selectedCard.name}
						searchType={selectedCard.searchType}
					/>
				)}
			</Card>
		</div>
	)
}

export default memo(FilterBar)
