import React, { useState, memo } from 'react'
import { Grid, Button, Switch, Collapse } from '@mui/material'
import { ChevronDownIcon, ChevronUpIcon } from 'utils/SystemIcons'
import { toggleReportContentOption } from '../../utils/ReportContentFunctions'

const MultiOption = ({ mainChecked, MainTitle, option, value, thisOptions, setThisOptions, content, isSubOption }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<Grid
				container
				justifyContent='space-between'
				style={{ paddingLeft: '10px', marginTop: '3px', marginBottom: '3px', paddingRight: '10px' }}
			>
				<Grid item xs={10}>
					<Button
						onClick={() => setIsOpen(!isOpen)}
						fullWidth
						style={{
							height: '36.5px',
							fontFamily: 'Poppins',
							fontWeight: 'bold',
							fontSize: isSubOption ? '12px' : '14px',
							color: mainChecked ? '#1d8cf8' : '#9a9a9a',
							justifyContent: 'flex-start',
							borderRadius: '9px 0px 0px 9px',
							width: '100%',
							backgroundColor: 'rgba(255,255,255,1)'
						}}
					>
						{!isOpen && <ChevronDownIcon size={20} style={{ marginRight: '10px' }} />}
						{isOpen && <ChevronUpIcon size={20} style={{ marginRight: '10px' }} />}
						{MainTitle}
					</Button>
				</Grid>
				<Grid
					item
					xs={2}
					style={{
						display: 'flex',
						backgroundColor: 'rgba(255,255,255,1)',
						justifyContent: 'center',
						borderRadius: '0px 9px 9px 0px'
					}}
				>
					<Switch
						onClick={() => toggleReportContentOption(option, value, thisOptions, setThisOptions)}
						color='primary'
						checked={mainChecked}
					/>
				</Grid>
			</Grid>
			<Collapse in={isOpen} style={{ width: '100%' }}>
				{content}
			</Collapse>
		</>
	)
}
export default memo(MultiOption)
