import React, { useState, useEffect, memo } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'

const tabsize = 44

const useStyles = makeStyles(() => ({
	tab: {
		height: tabsize,
		minHeight: tabsize,
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
}))

const NetworkSummarySelector = ({ view, onChangeView, sources, cardType }) => {
	const [thisView, setThisView] = useState(view || 'accounts')
	const classes = useStyles()

	useEffect(() => {
		setThisView(view)
	}, [view])

	const handleSelect = (_, newValue) => {
		setThisView(newValue)
		onChangeView(newValue)
	}

	return (
		<>
			{cardType !== 'group' && (
				<Tabs
					style={{ marginTop: '0px', marginLeft: '8px', textAlign: 'left', float: 'left' }}
					centered
					value={thisView}
					onChange={handleSelect}
					TabIndicatorProps={{ style: { background: blue } }}
				>
					<Tab
						id='tab-top-accounts'
						label='contas'
						value={'accounts'}
						style={{ outline: 'none' }}
						className={classes.tab}
						disabled={false}
					/>
					{sources.includes('twitter') && (
						<Tab
							id='tab-top-hashtags'
							label='hashtags'
							value={'hashtags'}
							style={{ outline: 'none' }}
							className={classes.tab}
							disabled={false}
						/>
					)}
					<Tab
						id='tab-top-sentiments'
						label='sentimentos'
						value={'sentiments'}
						style={{ outline: 'none' }}
						className={classes.tab}
						disabled={false}
					/>
				</Tabs>
			)}
		</>
	)
}

export default memo(NetworkSummarySelector)
