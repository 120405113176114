import React from 'react'
import Outliers from '../Outliers/Outliers'
import { Grid } from '@mui/material'

const OutliersContent = ({ variable, country, timeInterval, isFetchingData }) => {
	return (
		<Grid container style={{ marginTop: '0px' }} spacing={3}>
			<Grid item xs={12}>
				<Outliers variable={variable} country={country} timeInterval={timeInterval} isFetchingData={isFetchingData} />
			</Grid>
		</Grid>
	)
}

export default OutliersContent
