import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid } from '@mui/material'

const TagViewerSkeletonLoad = () => (
	<div style={{ marginBottom: '1em', padding: '0.5em' }}>
		<Grid container>
			<Grid item xs={12}>
				<Skeleton height='36em' style={{ borderRadius: '20px' }} />
			</Grid>
		</Grid>
	</div>
)

export default TagViewerSkeletonLoad
