import React, { memo, useState } from 'react'
import { Button, Card, Divider, Grid } from '@mui/material'
import { LinkIcon, UnlinkIcon, FacebookIcon, InstagramIcon, CheckIconBold, LoadingIcon, MetaAdsIcon } from 'utils/SystemIcons'
import ConfirmDisconnect from '../ConfirmDisconnect/ConfirmDisconnect'
import NetworkButtonSkeletonLoad from 'components/SkeletonLoads/NetworkButtonSkeletonLoad/NetworkButtonSkeletonLoad'
import './NetworkButton.css'

const defineIcon = (network, color, connectedStatus) => {
	switch (network) {
		case 'facebook':
			return <FacebookIcon size={40} style={{ color: `${connectedStatus ? '#fff' : color}`, marginLeft: '0px' }} />
		case 'instagram':
			return <InstagramIcon size={40} style={{ color: `${connectedStatus ? '#fff' : color}`, marginLeft: '0px' }} />
		default:
			return <MetaAdsIcon size={40} style={{ color: `${connectedStatus ? '#fff' : color}`, marginLeft: '0px' }} />
	}
}

const defineClassName = (network) => {
	switch (network) {
		case 'facebook':
			return 'orbBackFacebook'
		case 'instagram':
			return 'orbBackInstagram'
		default:
			return 'orbBackMetaAds'
	}
}

const NetworkButton = ({ network, color, connectedStatus, onConnect, onDisconnect, cardAccountId, loading, label, buttonLoading }) => {
	const [openConfirmDisconnect, setOpenConfirmDisconnect] = useState(false)

	const toggleConfirmDisconnect = () => {
		setOpenConfirmDisconnect(!openConfirmDisconnect)
	}

	const onConfirmDisconnect = () => {
		onDisconnect(cardAccountId, network)
		toggleConfirmDisconnect()
	}

	return (
		<>
			{!loading ? (
				<Card
					elevation={1}
					style={{
						marginLeft: '0em',
						textTransform: 'none',
						paddingLeft: '0em',
						paddingRight: '0em',
						height: '10em',
						width: '10em',
						borderRadius: '30px',
						backgroundColor: 'transparent'
					}}
				>
					<div
						style={{
							alignContent: 'center',
							justifyContent: 'center',
							alignItems: 'center',
							height: '77%',
							backdropFilter: 'blur(10px)',
							backgroundColor: 'rgba(250, 250, 250, 0.1)'
						}}
					>
						<div>
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: '1.6em',
										height: '1.6em',
										borderRadius: '100px',
										backgroundColor: `${connectedStatus ? 'rgba(76, 207, 81,0.6)' : 'transparent'}`,
										margin: '0.5em'
									}}
								>
									<CheckIconBold size={15} style={{ color: `${connectedStatus ? '#fff' : 'transparent'}` }} />
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', marginTop: '-0.5em' }}>
								{connectedStatus && (
									<div
										className={defineClassName(network)}
										style={{
											position: 'absolute',
											borderRadius: '100px'
										}}
									></div>
								)}
								{defineIcon(network, color, connectedStatus)}
							</div>
							<div
								style={{
									fontFamily: 'Poppins',
									color: `${connectedStatus ? '#fff' : color}`,
									display: 'flex',
									justifyContent: 'center',
									paddingTop: '0.6em',
									fontSize: '14px',
									fontWeight: 'bold',
									textShadow: `${connectedStatus ? '0px 0px 4px rgba(20,9,5,0.51)' : 'none'}`
								}}
							>
								{label}
							</div>
						</div>
					</div>
					<Divider style={{ backgroundColor: 'transparent', height: '1px', width: '100%' }} />
					{!loading && (
						<Grid style={{ color: color }}>
							{!connectedStatus && (
								<Button disabled={buttonLoading} fullWidth color='inherit' onClick={() => onConnect()}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										{buttonLoading ? (
											<LoadingIcon size={15} thickness={1} color='#1d8cf8' />
										) : (
											<>
												<LinkIcon
													size={18}
													style={{
														cursor: 'pointer',
														color: color,
														marginLeft: '0px',
														marginRight: '3px',
														marginTop: '2px'
													}}
												/>
												<div
													style={{
														fontFamily: 'Poppins',
														fontSize: '13px',
														color: color,
														textTransform: 'none',
														fontWeight: 'bold'
													}}
												>
													Conectar
												</div>
											</>
										)}
									</div>
								</Button>
							)}
							{connectedStatus && (
								<Button disabled={buttonLoading} fullWidth color='inherit' onClick={() => toggleConfirmDisconnect()}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										{buttonLoading ? (
											<LoadingIcon size={15} thickness={1} color='#1d8cf8' />
										) : (
											<>
												<UnlinkIcon
													size={18}
													style={{
														cursor: 'pointer',
														color: 'rgba(20,9,5,0.5)',
														marginLeft: '0px',
														marginRight: '3px',
														marginTop: '2px'
													}}
												/>
												<div
													style={{
														fontFamily: 'Poppins',
														fontSize: '13px',
														color: 'rgba(20,9,5,0.5)',
														textTransform: 'none',
														fontWeight: 'bold'
														// textShadow: `${connectedStatus ? '0px 0px 6px rgba(20,9,5,0.51)' : 'none'}`
													}}
												>
													Desconectar
												</div>
											</>
										)}
									</div>
								</Button>
							)}
						</Grid>
					)}
					<ConfirmDisconnect isOpen={openConfirmDisconnect} onClose={toggleConfirmDisconnect} onConfirm={onConfirmDisconnect} />
				</Card>
			) : (
				<NetworkButtonSkeletonLoad />
			)}
		</>
	)
}

export default memo(NetworkButton)
