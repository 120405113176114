import React, { memo, useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { graphOptionsArea } from 'graphs/utils/GraphOptionsArea'
import GraphSkeletonLoad from 'components/SkeletonLoads/GraphSkeletonLoad/GraphSkeletonLoad'

const emptyProp = {
	options: {
		chart: {
			type: 'line'
		}
	},
	series: [
		{
			name: '',
			data: [0]
		}
	]
}

const defaultOptions = {
	type: 'engagement',
	engagement: false,
	social: false,
	posts: true,
	gender: false,
	users: false,
	men: false,
	women: false,
	noClass: false,
	politics: false,
	comments: false,
	retweets: false
}

const graphTime = 'day'
const comparison = 'absolute'
const isReport = false

const volumetricCurve = ({ cards, filters, cardType = 'term', options = defaultOptions, onChangeLoading = null, onChangeData = null }) => {
	const [loading, setLoading] = useState(true)

	const getBody = () => ({
		configGlobalFilters: {
			cardType: cardType.toUpperCase(),
			cardIds: cards?.map((card) => card.id)
		},
		globalFilters: filters,
		componentProperties: {
			comparison,
			graphTime,
			hasEngagement: options.engagement,
			hasPosts: options.posts,
			hasUsers: options.users,
			hasMen: options.men,
			hasWomen: options.women,
			hasNoClass: options.noClass,
			hasLeft: options.left_wing,
			hasRight: options.right_wing,
			hasCenterLeft: options.centre_left_wing,
			hasCenterRight: options.centre_right_wing,
			hasCenter: options.centre,
			hasComments: options.comments,
			hasShares: options.retweets,
			type: options.type
		}
	})

	let { data, isFetched } = useUpdateData({
		url: '/analysis/nowcasting/get-graph-data',
		updateItemName: 'Nowcasting',
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: true,
		queryName: 'relevanceChange_useUpdateData',
		method: 'post',
		body: getBody()
	})

	useEffect(() => {
		if (isFetched === true) {
			setLoading(false)

			if (onChangeLoading) {
				onChangeLoading(false)
			}
		}
	}, [isFetched])

	useEffect(() => {
		if (onChangeData) {
			onChangeData(data)
		}
	}, [data])

	return (
		<Grid item xs={12} style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
			{loading ? (
				<GraphSkeletonLoad />
			) : (
				<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>
					<Chart
						options={graphOptionsArea(
							isReport,
							data?.formatted?.options?.title || '',
							data?.formatted?.options?.colors,
							'smooth',
							false,
							graphTime,
							false,
							false,
							true,
							'timelinePostsTopics',
							true
						)}
						series={data?.formatted?.series || emptyProp.series}
						type={options.engagement === false && cards.length === 1 ? 'line' : 'area'}
						width={'100%'}
						height={'450px'}
					/>
				</div>
			)}
		</Grid>
	)
}

export default memo(volumetricCurve)
