import React, { useEffect, useState, memo } from 'react'
import { Grid, Button } from '@mui/material'
import './Stepper.css'

const formatStepData = (stepData, visitedIndex) => stepData.map((item, index) => ({ ...item, visited: item?.visited || visitedIndex === index }))

const getClassName = (isProfile, isGroup, isReport) => {
	if (isProfile) {
		return 'button-stepper-profile'
	} else if (isGroup) {
		return 'button-stepper-group'
	} else if (isReport) {
		return 'button-stepper-report'
	} else {
		return 'button-stepper'
	}
}
const Stepper = ({
	data,
	justifyContent,
	onChange,
	value,
	resetState,
	noChangeOnClick = false,
	isProfile = false,
	spacing = 3,
	isGroup = false,
	isReport = false
}) => {
	const [stepData, setStepData] = useState(data)

	useEffect(() => {
		setStepData(formatStepData(stepData, value - 1))
	}, [value])

	useEffect(() => {
		setStepData(formatStepData(data, value - 1))
	}, [resetState])

	const handleChangeStep = (stepValue) => {
		if (!noChangeOnClick) {
			onChange({ selectedStep: stepValue, currentStep: value })
		}
	}

	if (!stepData) {
		return null
	}

	return (
		<Grid container style={{ marginTop: '10px' }} justifyContent={justifyContent}>
			{stepData.map((it, index) => (
				<Grid container item xs={spacing} key={it.label + it.value}>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Button
							className={getClassName(isProfile, isGroup, isReport)}
							style={{ opacity: value === index + 1 ? 1 : 0.5 }}
							disabled={!it.visited}
							color='primary'
							onClick={() => handleChangeStep(index + 1)}
						>
							{index + 1}
						</Button>
					</Grid>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						{it.label}
					</Grid>
				</Grid>
			))}
		</Grid>
	)
}

export default memo(Stepper)
