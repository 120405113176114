import React from 'react'
import { Button, Dialog, IconButton, Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChevronLeftIcon, ArrowRightIcon, AlertCircle, LinkIcon, ExternalLinkIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'
const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	root: {
		color: blue,
		font: 'Poppins',
		fontWeight: '15'
	},
	label: {
		textTransform: 'capitalize'
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const MessageFacebook = ({ isOpen, onClose, title, onConfirm }) => {
	const classes = useStyles()

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth='sm'
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
		>
			<div>
				<IconButton color={'primary'} onClick={onClose}>
					<ChevronLeftIcon color={'#1d8cf8'} />
				</IconButton>
			</div>

			<h2 style={{ paddingBottom: 20, textAlign: 'center', fontFamily: 'Poppins', fontWeight: 'lighter' }}>{title}</h2>
			<Card
				elevation={1}
				style={{
					borderRadius: '25px',
					margin: '1em',
					padding: '1.5em',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`
				}}
			>
				<Grid item xs={12}>
					<div style={{ display: 'flex', marginBottom: '20px' }}>
						<div style={{ marginTop: '2px', marginRight: '8px' }}>
							<AlertCircle size={20} color={'#1d8cf8'} />
						</div>
						<div style={{ fontFamily: 'Poppins', color: '#1d8cf8' }}>Para Integrar uma conta do Facebook, ela precisa:</div>
					</div>
					<div style={{ display: 'flex', marginBottom: '20px' }}>
						<div style={{ marginTop: '2px', marginRight: '8px' }}>
							<ArrowRightIcon size={20} color={'#1d8cf8'} />
						</div>

						<div>
							<div style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>Ser uma Página do Facebook.</div>
							<div style={{ fontFamily: 'Poppins' }}>Não é possível integrar contas de usuários</div>
						</div>
					</div>
					<div>
						<a
							href={'https://www.voxradar.com.br/politica-de-privacidade/'}
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none', outlineColor: 'none' }}
						>
							<Button
								size='small'
								className={classes.button}
								style={{
									fontSize: '12px'
								}}
								variant='outlined'
							>
								<ExternalLinkIcon size={13} style={{ color: '#1d8cf8', marginRight: '5px' }} />
								Política de privacidade
							</Button>
						</a>
					</div>
				</Grid>
			</Card>
			<Grid container style={{ padding: '1em' }}>
				<Button onClick={onClose} style={{ marginRight: '20px' }} className={classes.button}>
					Minha conta não atende aos requisitos
				</Button>
				<Button onClick={onConfirm} className={classes.mainButton}>
					<LinkIcon size={22} style={{ cursor: 'pointer', color: '#FFF', marginRight: '5px' }} />
					Conectar Facebook
				</Button>
			</Grid>
		</Dialog>
	)
}

export default MessageFacebook
