import React, { useState, useEffect } from 'react'
import api from 'service/service'
import {
	Grid,
	Button,
	IconButton,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	Autocomplete,
	TextField,
	styled,
	Paper,
	Checkbox
} from '@mui/material'
import { CloseIcon, CircleIcon, DeleteBinIcon } from 'utils/SystemIcons'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast, orderKeys } from 'utils/generalFunctions'
import { useUpdateData } from 'hooks/ReactQueryHooks'

const isFirefox = typeof InstallTrigger !== 'undefined'

const IconSize = 25
const Iconstyle = { color: '#1d8cf8', marginRight: '0.3em' }

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: '#1d8cf8',
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: '#1d8cf8'
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />
const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />

const RestoreTopics = ({ cardTermId, thisFilters, update, isFetchingData }) => {
	const [submitting, setSubmitting] = useState(false)
	const [topicsOptions, setTopicsOptions] = useState([])
	const [typingText, setTypingText] = useState('')
	const [OpenDeleted, setOpenDeleted] = useState(false)

	useEffect(() => {
		setTypingText('')
		setTopicsOptions([])
	}, [OpenDeleted])

	const getUrlRequest = () => {
		const source = thisFilters.sources
		const orderedObj = orderKeys(thisFilters)

		const filters = Buffer.from(JSON.stringify(orderedObj)).toString('base64')

		return `topic-analysis/get-deleted-topics/${cardTermId}/${source}/${encodeURIComponent(filters)}`
	}

	let { data, refetch } = useUpdateData({
		url: getUrlRequest(),
		updateItemName: 'voxradarAiRestoreTopics',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: isFetchingData,
		queryName: 'voxradar_ai_restore_topics'
	})

	const toggelDeleted = () => {
		setOpenDeleted(!OpenDeleted)
	}

	const RestoreTopics = (event) => {
		event.preventDefault()

		const topicsToRestoreIds = topicsOptions.map((topic) => topic.topic_modeling_ia_id)

		setSubmitting(true)
		api.post('/topic-analysis/restore-topics', {
			topicsToRestoreIds: topicsToRestoreIds
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggelDeleted()
				refetch()
				update()
			})
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={option.topic_modeling_ia_id} style={{ fontFamily: 'Poppins' }}>
				<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				{option.title}
			</li>
		)
	}

	const handleChange = (newValue) => {
		setTopicsOptions(newValue)
	}

	return (
		<>
			{data?.length > 0 && (
				<Button
					id='btn-open-deleted-topics'
					onClick={toggelDeleted}
					variant='outlined'
					style={{
						fontFamily: 'Poppins',
						borderRadius: '100px',
						fontSize: '14px',
						borderColor: 'rgba(29, 138, 248, 0.3)',
						maxHeight: '36.5px',
						marginRight: '2em'
					}}
				>
					<DeleteBinIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
					Restaurar
				</Button>
			)}
			<Dialog
				open={OpenDeleted}
				onClose={toggelDeleted}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				fullWidth={true}
				maxWidth={'md'}
				PaperProps={{
					style: {
						overflowX: 'hidden',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(220, 220, 220, 0.4)'
					}
				}}
			>
				<DialogTitle>
					<div className='jc-space-between d-flex ai-center'>
						<div>
							<DeleteBinIcon className='fix-icon-margin-bottom-negative' size={IconSize} style={Iconstyle} />
							Restaurar tópicos deletados
						</div>
						<div>
							<IconButton color='primary' style={{ outline: 'none' }} onClick={toggelDeleted}>
								<CloseIcon color='#1d8cf8' size={20} />
							</IconButton>
						</div>
					</div>
				</DialogTitle>
				<DialogContent style={{ overflowY: 'hidden', marginBottom: '0.2em', marginTop: '0.2em' }}>
					<CardContent style={{ borderRadius: '4px' }}>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								disableCloseOnSelect
								autoComplete
								autoHighlight
								PaperComponent={CustomPaper}
								options={data}
								value={topicsOptions}
								noOptionsText='Não encontrado'
								inputValue={typingText}
								onInputChange={(_, value) => setTypingText(value)}
								onChange={(_, value) => handleChange(value)}
								getOptionLabel={(option) => option?.title}
								ListboxProps={{
									style: {
										maxHeight: '210px'
									}
								}}
								renderInput={(params) => (
									<CssTextField
										variant='standard'
										fullWidth
										label={
											<div style={{ display: 'flex' }}>
												<CircleIcon
													size={20}
													style={{
														color: '#1d8cf8',
														transform: 'translate(0px, 2px)',
														marginRight: '7px'
													}}
												/>
												{typingText && typingText !== ''
													? 'Pressione Enter para adicionar o tópico'
													: 'Selecione os tópicos que quer restaurar'}
											</div>
										}
										// error={!!fieldErrors?.accounts?.error}
										// helperText={fieldErrors?.accounts?.error}
										{...params}
									/>
								)}
								renderOption={optionRenderer}
							/>
						</Grid>
					</CardContent>
					<CardContent style={{ borderRadius: '4px' }}>
						<Grid item xs={12} container justifyContent={'center'}>
							<Button
								style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
								variant='outlined'
								color='primary'
								disabled={submitting || topicsOptions.length < 1}
								onClick={RestoreTopics}
							>
								Restaurar
							</Button>
						</Grid>
					</CardContent>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default RestoreTopics
