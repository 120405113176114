import moment from 'moment-timezone'

const stringTmzToMoment = (string) => moment(string, 'YYYY-MM-DD HH:mm:ss Z').tz('America/Sao_Paulo')

const timeToMoment = (time) => moment(time).tz('America/Sao_Paulo')

const buildOptionsGraphBarFacebook = () => {
	const options = {
		chart: {
			type: 'bar',
			height: 350
		},
		plotOptions: {
			bar: {
				colors: {
					ranges: [
						{
							from: -100,
							to: -46,
							color: '#F15B46'
						},
						{
							from: -45,
							to: 0,
							color: '#FEB019'
						}
					]
				},
				columnWidth: '80%'
			}
		},
		dataLabels: {
			enabled: false
		},
		yaxis: {
			title: {
				text: 'Growth'
			},
			labels: {
				formatter: function (y) {
					return y.toFixed(0) + '%'
				}
			}
		},
		xaxis: {
			type: 'datetime',
			categories: [
				'2011-01-01',
				'2011-02-01',
				'2011-03-01',
				'2011-04-01',
				'2011-05-01',
				'2011-06-01',
				'2011-07-01',
				'2011-08-01',
				'2011-09-01',
				'2011-10-01',
				'2011-11-01',
				'2011-12-01',
				'2012-01-01',
				'2012-02-01',
				'2012-03-01',
				'2012-04-01',
				'2012-05-01',
				'2012-06-01',
				'2012-07-01',
				'2012-08-01',
				'2012-09-01',
				'2012-10-01',
				'2012-11-01',
				'2012-12-01',
				'2013-01-01',
				'2013-02-01',
				'2013-03-01',
				'2013-04-01',
				'2013-05-01',
				'2013-06-01',
				'2013-07-01',
				'2013-08-01',
				'2013-09-01'
			],
			labels: {
				rotate: -90
			}
		}
	}

	return options
}

const buildOptionsGraphAreaFacebook = () => {
	const options = {
		chart: {
			id: 'area-datetime',
			type: 'area',
			height: 350,
			zoom: {
				autoScaleYaxis: true
			}
		},
		dataLabels: {
			enabled: false
		},
		markers: {
			size: 0,
			style: 'hollow'
		},
		xaxis: {
			type: 'datetime',
			min: new Date('01 Mar 2012').getTime(),
			tickAmount: 6
		},
		tooltip: {
			x: {
				format: 'dd MMM yyyy'
			}
		},
		fill: {
			type: 'gradient',
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 100]
			}
		}
	}
	return options
}

const setLegendColorDictonary = (ids) => {
	const legendColors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
	let colors = []
	for (let index = 0; index < ids.length; index++) {
		colors.push({
			id: ids[index],
			color: legendColors[index]
		})
	}
	return colors
}

const getLegendColorById = (id, colorDictonary) => {
	for (let index = 0; index < colorDictonary.length; index++) {
		if (colorDictonary[index].id === id) {
			return colorDictonary[index].color
		}
	}
}

export { stringTmzToMoment, timeToMoment, buildOptionsGraphBarFacebook, buildOptionsGraphAreaFacebook, setLegendColorDictonary, getLegendColorById }
