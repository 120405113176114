import React, { useState, useEffect } from 'react'
import api from 'service/service'
import {
	Grid,
	Button,
	IconButton,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	Autocomplete,
	TextField,
	styled,
	Paper,
	Checkbox
} from '@mui/material'
import { CloseIcon, CircleIcon } from 'utils/SystemIcons'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const iconSize = 25
const iconstyle = { color: '#1d8cf8', marginRight: '0.3em' }
const mergeIconStyle = { color: '#1d8cf8', marginLeft: '-0.8em', marginRight: '0.3em' }

const MergeIcon = (
	<>
		<CircleIcon className='fix-icon-margin-bottom-negative' size={iconSize} style={iconstyle} />
		<CircleIcon className='fix-icon-margin-bottom-negative' size={iconSize} style={mergeIconStyle} />
	</>
)

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: '#1d8cf8',
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: '#1d8cf8'
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />
const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />

const MergeTopicDialog = ({ topic, openMergeDialog, toggleMergeTopic, update, topics, cardId }) => {
	const [submitting, setSubmitting] = useState(false)
	const [topicsOptions, setTopicsOptions] = useState([])
	const [typingText, setTypingText] = useState('')

	useEffect(() => {
		setTypingText('')
		setTopicsOptions([])
	}, [openMergeDialog])

	const mergeTopics = (event) => {
		event.preventDefault()

		const topicsToMergeIds = [...topicsOptions.map((topic) => topic.topic_modeling_ia_id), ...topic.topic_modeling_ia_ids].sort()

		setSubmitting(true)
		api.post('/topic-analysis/merge-topics', {
			topicId: topic.topic_modeling_ia_id,
			topicsToMergeIds: topicsToMergeIds,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				toggleMergeTopic()
				update()
			})
	}

	const optionRenderer = (props, option, { selected }) => {
		return (
			<li {...props} key={option.topic_modeling_ia_id} style={{ fontFamily: 'Poppins' }}>
				<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
				{option.title}
			</li>
		)
	}

	const handleChange = (newValue) => {
		setTopicsOptions(newValue)
	}

	return (
		<Dialog
			open={openMergeDialog}
			onClose={toggleMergeTopic}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={true}
			maxWidth={'md'}
			PaperProps={{
				style: {
					overflowX: 'hidden',
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(220, 220, 220, 0.4)'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div>
						{MergeIcon}
						Juntar Tópicos
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={toggleMergeTopic}>
							<CloseIcon color='#1d8cf8' size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ overflowY: 'hidden', marginBottom: '0.2em', marginTop: '0.2em' }}>
				<CardContent style={{ borderRadius: '4px' }}>
					<div style={{ fontFamily: 'Poppins', marginBottom: '1em' }}>{topic.title}</div>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							disableCloseOnSelect
							autoComplete
							autoHighlight
							PaperComponent={CustomPaper}
							options={topics.filter((option) => option.topic_modeling_ia_id !== topic.topic_modeling_ia_id)}
							value={topicsOptions}
							noOptionsText='Não encontrado'
							inputValue={typingText}
							onInputChange={(_, value) => setTypingText(value)}
							onChange={(_, value) => handleChange(value)}
							getOptionLabel={(option) => option?.title}
							ListboxProps={{
								style: {
									maxHeight: '210px'
								}
							}}
							renderInput={(params) => (
								<CssTextField
									variant='standard'
									fullWidth
									label={
										<div style={{ display: 'flex' }}>
											<CircleIcon
												size={20}
												style={{
													color: '#1d8cf8',
													transform: 'translate(0px, 2px)',
													marginRight: '7px'
												}}
											/>
											{typingText && typingText !== ''
												? 'Pressione Enter para adicionar o tópico'
												: 'Selecionar tópicos para juntar a este'}
										</div>
									}
									{...params}
								/>
							)}
							renderOption={optionRenderer}
						/>
					</Grid>
				</CardContent>
				<CardContent style={{ borderRadius: '4px' }}>
					<Grid item xs={12} container justifyContent={'center'}>
						<Button
							style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
							variant='outlined'
							color='primary'
							disabled={submitting || topicsOptions.length < 1}
							onClick={mergeTopics}
						>
							Juntar
						</Button>
					</Grid>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default MergeTopicDialog
