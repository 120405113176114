import React from 'react'
import Backtesting from '../Backtesting/Backtesting'
import { Grid } from '@mui/material'

const BacktestingContent = ({ variable, country, inicialDate, timeInterval, isFetchingData }) => {
	return (
		<Grid container style={{ marginTop: '0px' }} spacing={3}>
			<Grid item xs={12}>
				<Backtesting
					variable={variable}
					country={country}
					inicialDate={inicialDate}
					timeInterval={timeInterval}
					isFetchingData={isFetchingData}
				/>
			</Grid>
		</Grid>
	)
}

export default BacktestingContent
