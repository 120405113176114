import React, { memo } from 'react'
import Chart from 'react-apexcharts'
import { Grid } from '@mui/material'
import moment from 'moment'
import constants from 'constants-vox'

const emptyProp = {
	options: {
		chart: {
			type: 'bar'
		}
	},
	series: [
		{
			name: '',
			data: []
		}
	]
}

const formatValue = (value) => {
	if (value !== null) {
		if (Number.isInteger(value)) {
			return value
		} else {
			return value?.toFixed(2)
		}
	} else {
		return null
	}
}

const formatSeriesToBars = (series) => {
	const formated = series.map((serie) => {
		return {
			name: serie.name,
			data: serie.data.map((data) => data[1])
		}
	})
	return formated
}

const getCategories = (data) => {
	if (data?.series) {
		return data.series[0].data.map((data) => data[0])
	} else {
		return []
	}
}

const TagGraphBars = ({ data, height = window.innerHeight / 1.2 - 120 }) => {
	const getOptions = () => {
		if (data?.options) {
			return {
				colors: [
					'#008FFB',
					'#00E396',
					'#FEB019',
					'#FF4560',
					'#775DD0',
					'#104469',
					'#3cb44b',
					'#ffe119',
					'#4363d8',
					'#f58231',
					'#911eb4',
					'#46f0f0',
					'#f032e6',
					'#bcf60c',
					'#fabebe',
					'#008080',
					'#e6beff',
					'#9a6324',
					'#fffac8',
					'#800000',
					'#aaffc3',
					'#808000',
					'#ffd8b1',
					'#000075',
					'#808080',
					'#e3e1e1',
					'#000000'
				],
				chart: {
					height: 350,
					type: 'bar',
					stacked: true,
					stackType: '100%',
					toolbar: {
						show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: false,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom',
						export: {
							csv: {
								filename: 'voxradar_export',
								columnDelimiter: ',',
								dateFormatter(timestamp) {
									return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
								}
							}
						}
					}
				},
				dataLabels: {
					enabled: true,
					offsetX: 0,
					textAnchor: 'middle',
					style: {
						fontSize: '13px',
						fontFamily: 'Roboto',
						colors: ['#fff']
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderRadius: 10,
						borderWidth: 1,
						borderColor: 'transparent',
						opacity: 0.15
					},
					dropShadow: {
						enabled: true,
						top: 1,
						left: 0,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				},
				stroke: {
					curve: 'smooth'
				},
				tooltip: {
					x: {
						format: 'dd/MM/yy HH:mm'
					}
				},
				xaxis: {
					type: 'datetime',
					categories: getCategories(data),
					labels: {
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						datetimeUTC: false
					}
				},
				yaxis: {
					labels: {
						show: true,
						align: 'right',
						minWidth: 0,
						maxWidth: 160,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label'
						},
						offsetX: -10,
						offsetY: 0,
						rotate: 0,
						formatter: (value) => formatValue(value)
					},
					max: 100
				},
				legend: {
					show: true,
					showForSingleSeries: true
				}
			}
		} else {
			return emptyProp.options
		}
	}

	return (
		<Grid style={{ marginTop: '2.5em', marginLeft: '20px', marginRight: '20px', marginBottom: '5px' }}>
			<div style={{ color: 'black', textAlign: 'left', fontFamily: 'Poppins', marginTop: '25px' }}>
				{data?.series.length > 0 ? (
					<Chart options={getOptions()} series={formatSeriesToBars(data?.series)} type={'bar'} width={'100%'} height={height} />
				) : (
					<Chart options={emptyProp.options} series={emptyProp.series} type={'area'} width={'100%'} height={height} />
				)}
			</div>
		</Grid>
	)
}

export default memo(TagGraphBars)
