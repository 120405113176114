import React, { useState, memo } from 'react'
import { Button, Dialog, DialogContent } from '@mui/material'
import { CommentIcon } from 'utils/SystemIcons'
import Occurrences from 'components/Occurrences/Occurrences'

const isFirefox = typeof InstallTrigger !== 'undefined'

const ViewComments = ({ cardData, cardColor, source, classification }) => {
	const [open, setOpen] = useState(false)

	const onClickGetComments = () => {
		setOpen(true)
	}

	const handleOnClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Button
				id='btn-occurrences-comments'
				size='small'
				onClick={onClickGetComments}
				style={{
					marginLeft: '8px',
					borderRadius: '100px',
					fontFamily: 'Poppins',
					fontSize: '12px',
					color: cardColor
				}}
			>
				<CommentIcon size={13} style={{ color: cardColor, marginRight: '5px' }} />
				comentários
			</Button>
			{open && (
				<Dialog
					style={{ margin: 'auto' }}
					id='dialog-app-comments'
					fullWidth={true}
					maxWidth='md'
					open={open}
					onClose={handleOnClose}
					BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
				>
					<DialogContent style={{ overflowY: 'hidden', marginBottom: '15px' }}>
						<Occurrences
							cards={[cardData]}
							isDialog={true}
							externalFeed={null}
							filters={{ sources: [source], classification }}
							type='ACCOUNT_MANAGEMENT'
							isComment
						/>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}
export default memo(ViewComments)
