const formatMetricsAccountManagerFacebook = (metricsToFormat) => {
	const metrics = metricsToFormat.facebookApiSeries
	// if (!metrics || metrics.length === 0) return null
	const latestMetric = metrics[metrics.length - 1]
	// const accountName = latestMetric?.name
	//top métricas

	const findLatestAvailableMetric = (metrics, metricName) => {
		let latestMetricValue = metrics[metrics.length - 1]?.[metricName]
		let previousIndex = metrics.length - 1
		while (!latestMetricValue && previousIndex > 0) {
			previousIndex--
			latestMetricValue = metrics[previousIndex]?.[metricName]
		}
		return metrics[previousIndex]
	}

	const latestAvailableMetricPageFansCity = findLatestAvailableMetric(metrics, 'page_fans_city')
	const latestAvailableMetricPageFansCountry = findLatestAvailableMetric(metrics, 'page_fans_country')
	const latestAvailableMetricPageFansLocale = findLatestAvailableMetric(metrics, 'page_fans_locale')

	const topMetrics = {
		cityFans: latestAvailableMetricPageFansCity?.page_fans_city,
		fansCountry: latestAvailableMetricPageFansCountry?.page_fans_country,
		fansLocale: latestAvailableMetricPageFansLocale?.page_fans_locale
	}
	const topMetricsFormatted = {}
	for (const prop in topMetrics) {
		let values = []
		let locations = []
		if (topMetrics[prop]) {
			const entries = Object.entries(topMetrics[prop])
			entries.sort((a, b) => b[1] - a[1])
			entries.slice(0, 10).forEach(([key, value]) => {
				values.push(value)
				locations.push(key)
			})
		}
		topMetricsFormatted[prop] = { values, locations }
	}
	//gráficos
	const fanFormatted = { options: { title: '' }, series: [{ name: 'Fans', type: 'area', data: [] }] }
	const followersFormatted = { options: { title: '' }, series: [{ name: 'Seguidores', type: 'area', data: [] }] }
	const engagedUsersFormated = { options: { title: '' }, series: [{ name: 'Usuários engajados', type: 'area', data: [] }] }
	const fanRemovesFormatted = { options: { title: '' }, series: [{ name: 'Fans desfeitos', type: 'area', data: [] }] }
	const impressionsFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Total', type: 'area', data: [] },
			{ name: 'Únicas', type: 'area', data: [] },
			{ name: 'Pagas', type: 'area', data: [] }
		]
	}
	const postsFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Interações', type: 'area', data: [] },
			{ name: 'Impressões', type: 'area', data: [] },
			{ name: 'Impressões pagas', type: 'area', data: [] }
		]
	}

	const viewsFormatted = {
		options: { title: '' },
		series: [{ name: 'Total', type: 'area', data: [] }]
	}

	const activityActionTypeUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const reactionsFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Likes', type: 'area', data: [] },
			{ name: 'Amei', type: 'area', data: [] },
			{ name: 'Grr', type: 'area', data: [] },
			{ name: 'Haha', type: 'area', data: [] },
			{ name: 'Wow', type: 'area', data: [] },
			{ name: 'Triste', type: 'area', data: [] },
			{ name: 'Total', type: 'area', data: [] }
		]
	}

	const pageConsumptionsFormatted = {
		options: { title: '' },
		series: []
	}

	const pageFansByLikeSourceUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const pageVideoViewTimeFormatted = {
		options: { title: '' },
		series: [{ name: 'Tempo de visualização dos vídeos', type: 'area', data: [] }]
	}

	const pageVideoViewsFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Total', type: 'area', data: [] },
			{ name: 'Pagas', type: 'area', data: [] }
		]
	}

	const pagePositiveFeedBackByTypeFormatted = {
		options: { title: '' },
		series: []
	}

	const pageNegativeFeedBackByTypeFormatted = {
		options: { title: '' },
		series: []
	}

	const pagePostsImpressionsUniqueFormatted = {
		options: { title: '' },
		series: [{ name: 'Impressões únicas', type: 'area', data: [] }]
	}

	const pageContentActivityByAgeGenderUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const pageContentActivityByCityUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const pageFansByUnlikeSourceUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const pageFanAddsFormatted = {
		options: { title: '' },
		series: [{ name: 'Novas curtidas', type: 'area', data: [] }]
	}

	const pageViewsByProfileTabTotalFormatted = {
		options: { title: '' },
		series: []
	}

	const pageContentActivityByCountryUniqueFormatted = {
		options: { title: '' },
		series: []
	}

	const pageNewUniqueFollowsFormatted = {
		options: { title: '' },
		series: [{ name: 'Novos seguidores únicos', type: 'area', data: [] }]
	}

	const pageNewUniqueUnfollowsFormatted = {
		options: { title: '' },
		series: [{ name: 'Deixaram de seguir', type: 'area', data: [] }]
	}

	let genderAgeTimelineFormatted = {
		options: { title: '' },
		series: []
	}

	let genderAgeFormatted = {
		formatted: [
			{
				info: {
					id: latestMetric?.account_manager_facebook_account_id || '',
					name: latestMetric?.name || ''
				},
				seriesAll: [],
				seriesMaleFemale: [],
				seriesTotal: []
			}
		],
		lastUpdate: latestMetric?.date || ''
	}

	const latestAvailableMetric = findLatestAvailableMetric(metrics, 'page_fans_gender_age')

	if (latestAvailableMetric?.page_fans_gender_age) {
		genderAgeFormatted = {
			formatted: [
				{
					info: {
						id: latestAvailableMetric.account_manager_facebook_account_id,
						name: latestAvailableMetric.name
					},
					seriesAll: [
						{
							name: 'Todos',
							data: [
								(latestAvailableMetric?.page_fans_gender_age['F.13-17'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.13-17'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.13-17'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.18-24'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.18-24'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.18-24'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.25-34'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.25-34'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.25-34'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.35-44'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.35-44'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.35-44'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.45-54'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.45-54'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.45-54'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.55-64'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.55-64'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.55-64'] || 0),
								(latestAvailableMetric?.page_fans_gender_age['F.65+'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['M.65+'] || 0) +
									(latestAvailableMetric?.page_fans_gender_age['U.65+'] || 0)
							]
						}
					],
					seriesMaleFemale: [
						{
							name: 'Masculino',
							data: [
								latestAvailableMetric?.page_fans_gender_age['M.13-17'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.18-24'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.25-34'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.35-44'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.45-54'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.55-64'] || 0,
								latestAvailableMetric?.page_fans_gender_age['M.65+'] || 0
							]
						},
						{
							name: 'Feminino',
							data: [
								latestAvailableMetric?.page_fans_gender_age['F.13-17'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.18-24'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.25-34'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.35-44'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.45-54'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.55-64'] || 0,
								latestAvailableMetric?.page_fans_gender_age['F.65+'] || 0
							]
						},
						{
							name: 'Indefinido',
							data: [
								latestAvailableMetric?.page_fans_gender_age['U.13-17'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.18-24'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.25-34'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.35-44'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.45-54'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.55-64'] || 0,
								latestAvailableMetric?.page_fans_gender_age['U.65+'] || 0
							]
						}
					],
					seriesTotal: [
						(latestAvailableMetric?.page_fans_gender_age['M.13-17'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.18-24'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.25-34'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.35-44'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.45-54'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.55-64'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['M.65+'] || 0),
						(latestAvailableMetric?.page_fans_gender_age['F.13-17'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.18-24'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.25-34'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.35-44'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.45-54'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.55-64'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['F.65+'] || 0),
						(latestAvailableMetric?.page_fans_gender_age['U.13-17'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.18-24'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.25-34'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.35-44'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.45-54'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.55-64'] || 0) +
							(latestAvailableMetric?.page_fans_gender_age['U.65+'] || 0)
					]
				}
			],
			lastUpdate: latestMetric.date
		}
	}

	metrics?.forEach((it) => {
		fanFormatted.series[0].data.push([it.date, it.fan_count])
		followersFormatted.series[0].data.push([it.date, it.followers_count])
		engagedUsersFormated.series[0].data.push([it.date, it.page_engaged_users])
		fanRemovesFormatted.series[0].data.push([it.date, it.page_fan_removes])

		impressionsFormatted.series[0].data.push([it.date, it.page_impressions])
		impressionsFormatted.series[1].data.push([it.date, it.page_impressions_unique])
		impressionsFormatted.series[2].data.push([it.date, it.page_impressions_paid])

		postsFormatted.series[0].data.push([it.date, it.page_post_engagements])
		postsFormatted.series[1].data.push([it.date, it.page_posts_impressions])
		postsFormatted.series[2].data.push([it.date, it.page_posts_impressions_paid])

		viewsFormatted.series[0].data.push([it.date, it.page_views_total])

		reactionsFormatted.series[0].data.push([it.date, it.reactions_like])
		reactionsFormatted.series[1].data.push([it.date, it.reactions_love])
		reactionsFormatted.series[2].data.push([it.date, it.reactions_anger])
		reactionsFormatted.series[3].data.push([it.date, it.reactions_haha])
		reactionsFormatted.series[4].data.push([it.date, it.reactions_wow])
		reactionsFormatted.series[5].data.push([it.date, it.reactions_sorry])
		reactionsFormatted.series[6].data.push([it.date, it.reactions_total])

		pageVideoViewTimeFormatted.series[0].data.push([it.date, it.page_video_view_time])

		pageVideoViewsFormatted.series[0].data.push([it.date, it.page_video_views])
		pageVideoViewsFormatted.series[1].data.push([it.date, it.page_video_views_paid])

		pagePostsImpressionsUniqueFormatted.series[0].data.push([it.date, it.page_posts_impressions_unique])

		pageFanAddsFormatted.series[0].data.push([it.date, it.page_fan_adds])

		pageNewUniqueFollowsFormatted.series[0].data.push([it.date, it.page_new_unique_follows])

		pageNewUniqueUnfollowsFormatted.series[0].data.push([it.date, it.page_new_unique_unfollows])

		extractAndAddInArraySeriesFromJSON(it.page_consumptions_by_consumption_type, it.date, pageConsumptionsFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_fans_by_like_source_unique, it.date, pageFansByLikeSourceUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_positive_feedback_by_type, it.date, pagePositiveFeedBackByTypeFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_negative_feedback_by_type, it.date, pageNegativeFeedBackByTypeFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_content_activity_by_action_type_unique, it.date, activityActionTypeUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_content_activity_by_age_gender_unique, it.date, pageContentActivityByAgeGenderUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_content_activity_by_country_unique, it.date, pageContentActivityByCountryUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_content_activity_by_city_unique, it.date, pageContentActivityByCityUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_fans_by_unlike_source_unique, it.date, pageFansByUnlikeSourceUniqueFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_views_by_profile_tab_total, it.date, pageViewsByProfileTabTotalFormatted)
		extractAndAddInArraySeriesFromJSON(it.page_fans_gender_age, it.date, genderAgeTimelineFormatted)
	})

	//soma series total
	const engagedUsersFormatedTotal = sumTotalFromSeries(engagedUsersFormated.series, 'total')
	const fanRemovesFormattedTotal = sumTotalFromSeries(fanRemovesFormatted.series, 'total')
	const pageVideoViewTimeFormattedTotal = sumTotalFromSeries(pageVideoViewTimeFormatted.series, 'total')
	const pagePostsImpressionsUniqueFormattedTotal = sumTotalFromSeries(pagePostsImpressionsUniqueFormatted.series, 'total')
	const pageFanAddsFormattedTotal = sumTotalFromSeries(pageFanAddsFormatted.series, 'total')
	const pageNewUniqueFollowsFormattedTotal = sumTotalFromSeries(pageNewUniqueFollowsFormatted.series, 'total')
	const pageNewUniqueUnfollowsFormattedTotal = sumTotalFromSeries(pageNewUniqueUnfollowsFormatted.series, 'total')

	//calcula o total individualmente de cada serie
	const viewsFormattedTotal = sumTotalFromSeries(viewsFormatted.series, 'individual')
	const impressionsFormattedTotal = sumTotalFromSeries(impressionsFormatted.series, 'individual')
	const activityActionTypeUniqueFormattedTotal = sumTotalFromSeries(activityActionTypeUniqueFormatted.series, 'individual')
	const postsFormattedTotal = sumTotalFromSeries(postsFormatted.series, 'individual')
	const reactionsFormattedTotal = sumTotalFromSeries(reactionsFormatted.series, 'individual')
	const pageVideoViewsFormattedTotal = sumTotalFromSeries(pageVideoViewsFormatted.series, 'individual')
	const pageConsumptionsFormattedTotal = sumTotalFromSeries(pageConsumptionsFormatted.series, 'individual')
	const pageFansByLikeSourceUniqueFormattedTotal = sumTotalFromSeries(pageFansByLikeSourceUniqueFormatted.series, 'individual')
	const pagePositiveFeedBackByTypeFormattedTotal = sumTotalFromSeries(pagePositiveFeedBackByTypeFormatted.series, 'individual')
	const pageNegativeFeedBackByTypeFormattedTotal = sumTotalFromSeries(pageNegativeFeedBackByTypeFormatted.series, 'individual')
	const pageContentActivityByAgeGenderUniqueFormattedTotal = sumTotalFromSeries(pageContentActivityByAgeGenderUniqueFormatted.series, 'individual')
	const pageContentActivityByCountryUniqueFormattedTotal = sumTotalFromSeries(pageContentActivityByCountryUniqueFormatted.series, 'individual')
	const pageContentActivityByCityUniqueFormattedTotal = sumTotalFromSeries(pageContentActivityByCityUniqueFormatted.series, 'individual')
	const pageFansByUnlikeSourceUniqueFormattedTotal = sumTotalFromSeries(pageFansByUnlikeSourceUniqueFormatted.series, 'individual')
	const pageViewsByProfileTabTotalFormattedTotal = sumTotalFromSeries(pageViewsByProfileTabTotalFormatted.series, 'individual')

	//Format gender age
	genderAgeTimelineFormatted.series.forEach((it) => (it.name = formatGenderAgeName(it.name)))
	genderAgeTimelineFormatted.series.sort((a, b) => a.name.split(' ')[1].localeCompare(b.name.split(' ')[1]))

	const graphs = {
		fanFormatted,
		followersFormatted,
		engagedUsersFormated,
		fanRemovesFormatted,
		impressionsFormatted,
		postsFormatted,
		viewsFormatted,
		activityActionTypeUniqueFormatted,
		reactionsFormatted,
		genderAgeFormatted,
		genderAgeTimelineFormatted,
		pageConsumptionsFormatted,
		pageFansByLikeSourceUniqueFormatted,
		pageVideoViewsFormatted,
		pagePositiveFeedBackByTypeFormatted,
		pageNegativeFeedBackByTypeFormatted,
		pageContentActivityByAgeGenderUniqueFormatted,
		pageContentActivityByCityUniqueFormatted,
		pageFansByUnlikeSourceUniqueFormatted,
		pageViewsByProfileTabTotalFormatted,
		pageVideoViewTimeFormatted,
		pagePostsImpressionsUniqueFormatted,
		pageFanAddsFormatted,
		pageNewUniqueFollowsFormatted,
		pageNewUniqueUnfollowsFormatted,
		pageContentActivityByCountryUniqueFormatted
	}

	const graphsTotalMetrics = {
		//total
		// fanFormattedTotal, //tem em outro objeto mais preciso
		// followersFormattedTotal, //tem em outro objeto mais preciso no .analytics
		engagedUsersFormatedTotal,
		fanRemovesFormattedTotal,
		pageVideoViewTimeFormattedTotal,
		pagePostsImpressionsUniqueFormattedTotal,
		pageFanAddsFormattedTotal,
		pageNewUniqueFollowsFormattedTotal,
		pageNewUniqueUnfollowsFormattedTotal,
		//individual
		viewsFormattedTotal,
		impressionsFormattedTotal,
		activityActionTypeUniqueFormattedTotal,
		postsFormattedTotal,
		reactionsFormattedTotal,
		pageVideoViewsFormattedTotal,
		pageConsumptionsFormattedTotal,
		pageFansByLikeSourceUniqueFormattedTotal,
		pagePositiveFeedBackByTypeFormattedTotal,
		pageNegativeFeedBackByTypeFormattedTotal,
		pageContentActivityByAgeGenderUniqueFormattedTotal,
		pageContentActivityByCountryUniqueFormattedTotal,
		pageContentActivityByCityUniqueFormattedTotal,
		pageFansByUnlikeSourceUniqueFormattedTotal,
		pageViewsByProfileTabTotalFormattedTotal
	}
	return { ...metricsToFormat, apiSeries: { topMetrics: topMetricsFormatted, graphs, graphsTotalMetrics } }
}

const formatMetricsAccountManagerInstagram = (metricsToFormat) => {
	const metrics = metricsToFormat.instagramApiSeries

	const latestMetric = metrics[metrics.length - 1]
	// const accountName = latestMetric?.name
	//top métricas
	const topMetrics = {
		followersByCity: latestMetric?.total_followers_by_city,
		followersByCountry: latestMetric?.total_followers_by_country
	}
	const topMetricsFormatted = {}
	for (const prop in topMetrics) {
		let values = []
		let keys = []
		if (topMetrics[prop]) {
			const entries = Object.entries(topMetrics[prop])
			entries.sort((a, b) => b[1] - a[1])
			entries.slice(0, 10).forEach(([key, value]) => {
				values.push(value)
				keys.push(key)
			})
		}
		topMetricsFormatted[prop] = { values, keys }
	}
	//gráficos
	const followsFormatted = { options: { title: '' }, series: [{ name: 'Seguindo', type: 'area', data: [] }] }
	const followersFormatted = { options: { title: '' }, series: [{ name: 'Seguidores', type: 'area', data: [] }] }
	const newFollowersFormatted = { options: { title: '' }, series: [{ name: 'Novos seguidores', type: 'area', data: [] }] }
	const profileViewsFormatted = { options: { title: '' }, series: [{ name: 'Total', type: 'area', data: [] }] }
	const viewsImpressionsReachFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Alcance', type: 'area', data: [] },
			{ name: 'Impressões', type: 'area', data: [] }
		]
	}
	let followersByCityFormatted = {
		options: { title: '' },
		series: []
	}
	let followersByCountryFormatted = {
		options: { title: '' },
		series: []
	}

	let genderTimelineFormatted = {
		options: { title: '' },
		series: []
	}

	let ageTimelineFormatted = {
		options: { title: '' },
		series: []
	}

	let genderAgeFormatted = {
		formatted: [
			{
				info: {
					id: latestMetric?.account_manager_facebook_account_id || '',
					name: latestMetric?.name || ''
				},
				seriesAll: [],
				seriesMaleFemale: [],
				seriesTotal: []
			}
		],
		lastUpdate: latestMetric?.date || ''
	}
	if (latestMetric?.total_followers_by_age || latestMetric?.total_followers_by_gender) {
		let age = latestMetric?.total_followers_by_age
		let gender = latestMetric?.total_followers_by_gender
		genderAgeFormatted = {
			formatted: [
				{
					info: {
						id: latestMetric.account_manager_instagram_account_id,
						name: latestMetric.name
					},
					seriesAll: [
						{
							name: 'Todos',
							data: [
								age['13-17'] || 0,
								age['18-24'] || 0,
								age['25-34'] || 0,
								age['35-44'] || 0,
								age['45-54'] || 0,
								age['55-64'] || 0,
								age['65+'] || 0
							]
						}
					],
					// seriesMaleFemale: [
					// 	{
					// 		name: 'Masculino',
					// 		data: [gender.M || 0]
					// 	},
					// 	{
					// 		name: 'Feminino',
					// 		data: [gender.F || 0]
					// 	},
					// 	{
					// 		name: 'Indefinido',
					// 		data: [gender.U || 0]
					// 	}
					// ],
					seriesTotal: [gender.M || 0, gender.F || 0, gender.U || 0]
				}
			],
			lastUpdate: latestMetric.date
		}
	}

	const clicksAndEmailContactsFormatted = {
		options: { title: '' },
		series: [
			{ name: 'Contatos de email', type: 'area', data: [] },
			{ name: 'Clicks em mensagens', type: 'area', data: [] },
			{ name: 'Clicks de direcionamento', type: 'area', data: [] },
			{ name: 'Clicks de ligação de telefone', type: 'area', data: [] },
			{ name: 'Clicks para acessar o site', type: 'area', data: [] }
		]
	}

	metrics?.forEach((it) => {
		followsFormatted.series[0].data.push([it.date, it.follows_count])
		followersFormatted.series[0].data.push([it.date, it.followers_count])
		newFollowersFormatted.series[0].data.push([it.date, it.new_followers_count])

		profileViewsFormatted.series[0].data.push([it.date, it.profile_views])

		viewsImpressionsReachFormatted.series[0].data.push([it.date, it.reach])
		viewsImpressionsReachFormatted.series[1].data.push([it.date, it.impressions])

		clicksAndEmailContactsFormatted.series[0].data.push([it.date, it.email_contacts])
		clicksAndEmailContactsFormatted.series[1].data.push([it.date, it.text_message_clicks])
		clicksAndEmailContactsFormatted.series[2].data.push([it.date, it.get_directions_clicks])
		clicksAndEmailContactsFormatted.series[3].data.push([it.date, it.phone_calls_clicks])
		clicksAndEmailContactsFormatted.series[4].data.push([it.date, it.website_clicks])

		extractAndAddInArraySeriesFromJSON(it.total_followers_by_city, it.date, followersByCityFormatted)
		extractAndAddInArraySeriesFromJSON(it.total_followers_by_country, it.date, followersByCountryFormatted)
		extractAndAddInArraySeriesFromJSON(it.total_followers_by_gender, it.date, genderTimelineFormatted)
		extractAndAddInArraySeriesFromJSON(it.total_followers_by_age, it.date, ageTimelineFormatted)
	})

	//pega último valor
	// const followsFormattedTotal = latestMetric.total_follows_count
	// const followersFormattedTotal = latestMetric.total_followers_count
	const mediaCountFormattedTotal = latestMetric?.total_media_count
	//soma series
	const newFollowersFormattedTotal = sumTotalFromSeries(newFollowersFormatted.series, 'total')
	const profileViewsFormattedTotal = sumTotalFromSeries(profileViewsFormatted.series, 'total')
	const clicksAndEmailContactsFormattedTotal = sumTotalFromSeries(clicksAndEmailContactsFormatted.series, 'individual')
	const viewsImpressionsReachFormattedTotal = sumTotalFromSeries(viewsImpressionsReachFormatted.series, 'individual')

	//sort and slice
	followersByCityFormatted.series = followersByCityFormatted.series
		.sort((a, b) => b.data[b.data.length - 1][1] - a.data[a.data.length - 1][1])
		.slice(0, 10)
	followersByCountryFormatted.series = followersByCountryFormatted.series
		.sort((a, b) => b.data[b.data.length - 1][1] - a.data[a.data.length - 1][1])
		.slice(0, 10)

	//Format gender age
	formatOrderGenderNameInstagram(genderTimelineFormatted.series)
	ageTimelineFormatted.series.sort((a, b) => a.name.localeCompare(b.name))

	const genderAgeTimelineFormatted = {
		genderTimelineFormatted,
		ageTimelineFormatted
	}

	const graphs = {
		followsFormatted,
		followersFormatted,
		genderAgeFormatted,
		genderAgeTimelineFormatted,
		newFollowersFormatted,
		clicksAndEmailContactsFormatted,
		viewsImpressionsReachFormatted,
		followersByCityFormatted,
		followersByCountryFormatted,
		profileViewsFormatted
	}

	const graphsTotalMetrics = {
		mediaCountFormattedTotal, //não tem gráfico dessa, é só o valor final mesmo
		newFollowersFormattedTotal,
		clicksAndEmailContactsFormattedTotal,
		viewsImpressionsReachFormattedTotal,
		profileViewsFormattedTotal
	}

	return { ...metricsToFormat, apiSeries: { topMetrics: topMetricsFormatted, graphs, graphsTotalMetrics } }
}

const formatMetricsAccountManagerMetaAds = (metricsToFormat) => {
	const breakdownFilter = 'overall'
	const metricObjectName = 'overall'
	const metrics = metricsToFormat.metaAdsApiSeries.filter((it) => it.breakdown === breakdownFilter)

	//gráficos
	const clicksFormatted = { options: { title: '' }, series: [{ name: 'Clicks', type: 'area', data: [] }] }
	const impressionsFormatted = { options: { title: '' }, series: [{ name: 'Impressões', type: 'area', data: [] }] }
	const pageEngagementFormatted = { options: { title: '' }, series: [{ name: 'Engajamento da página', type: 'area', data: [] }] }
	const postEngagementFormatted = { options: { title: '' }, series: [{ name: 'Engajamento dos posts', type: 'area', data: [] }] }
	const postReactionsFormatted = { options: { title: '' }, series: [{ name: 'Reações dos posts', type: 'area', data: [] }] }
	const spendFormatted = { options: { title: '' }, series: [{ name: 'Gastos', type: 'area', data: [] }] }
	const videoViewsFormatted = { options: { title: '' }, series: [{ name: 'Visualizações de vídeos', type: 'area', data: [] }] }
	const purchaseRoasFormatted = { options: { title: '' }, series: [{ name: 'Purchase roas*', type: 'area', data: [] }] }
	const videoP25WatchedViewsFormatted = { options: { title: '' }, series: [{ name: 'Video p25 watched views*', type: 'area', data: [] }] }
	const videoP100WatchedViewsFormatted = { options: { title: '' }, series: [{ name: 'Video p100 watched views*', type: 'area', data: [] }] }
	const socialSpendFormatted = { options: { title: '' }, series: [{ name: 'Social spend*', type: 'area', data: [] }] }
	const inlineLinkClicksFormatted = { options: { title: '' }, series: [{ name: 'Inline link clicks*', type: 'area', data: [] }] }
	const inlinePostEngagementFormatted = { options: { title: '' }, series: [{ name: 'Inline post engagement*', type: 'area', data: [] }] }
	const reachFormatted = { options: { title: '' }, series: [{ name: 'Alcance', type: 'area', data: [] }] }
	const inlineLinkClickCtrFormatted = { options: { title: '' }, series: [{ name: 'Inline link click ctr*', type: 'area', data: [] }] }
	const costPerUniqueClickFormatted = { options: { title: '' }, series: [{ name: 'Custo por click único', type: 'area', data: [] }] }
	const cppFormatted = { options: { title: '' }, series: [{ name: 'Cpp*', type: 'area', data: [] }] }
	const frequencyFormatted = { options: { title: '' }, series: [{ name: 'Frequência', type: 'area', data: [] }] }
	const conversionsFormatted = { options: { title: '' }, series: [{ name: 'Conversions*', type: 'area', data: [] }] }
	const costPer15SecVideoViewFormatted = { options: { title: '' }, series: [{ name: 'Cost per 15 sec video view*', type: 'area', data: [] }] }
	const costPerPostEngagementFormatted = { options: { title: '' }, series: [{ name: 'Custo por engajamento de post', type: 'area', data: [] }] }
	const costPerVideoViewFormatted = { options: { title: '' }, series: [{ name: 'Custo por visualização de vídeo', type: 'area', data: [] }] }
	const costPerPageEngagementFormatted = { options: { title: '' }, series: [{ name: 'Custo por engajamento da página*', type: 'area', data: [] }] }
	const costPerInlinePostEngagementFormatted = {
		options: { title: '' },
		series: [{ name: 'Cost per inline post engagement*', type: 'area', data: [] }]
	}
	const cpcFormatted = { options: { title: '' }, series: [{ name: 'Cpc*', type: 'area', data: [] }] }
	const cpmFormatted = { options: { title: '' }, series: [{ name: 'Cpm*', type: 'area', data: [] }] }
	const ctrFormatted = { options: { title: '' }, series: [{ name: 'Ctr*', type: 'area', data: [] }] }

	metrics?.forEach((it) => {
		clicksFormatted.series[0].data.push([it.date, 'clicks' in it ? it.clicks[metricObjectName] || 0 : 0])
		impressionsFormatted.series[0].data.push([it.date, 'impressions' in it ? it.impressions[metricObjectName] : 0 || 0])
		pageEngagementFormatted.series[0].data.push([it.date, 'page_engagement' in it ? it.page_engagement[metricObjectName] || 0 : 0])
		postEngagementFormatted.series[0].data.push([it.date, 'post_engagement' in it ? it.post_engagement[metricObjectName] || 0 : 0])
		postReactionsFormatted.series[0].data.push([it.date, 'post_reactions' in it ? it.post_reactions[metricObjectName] || 0 : 0])
		spendFormatted.series[0].data.push([it.date, 'spend' in it ? it.spend[metricObjectName] || 0 : 0])
		videoViewsFormatted.series[0].data.push([it.date, 'video_views' in it ? it.video_views[metricObjectName] || 0 : 0])
		purchaseRoasFormatted.series[0].data.push([it.date, 'purchase_roas' in it ? it.purchase_roas[metricObjectName] || 0 : 0])
		videoP25WatchedViewsFormatted.series[0].data.push([
			it.date,
			'video_p25_watched_views' in it ? it.video_p25_watched_views[metricObjectName] || 0 : 0
		])
		videoP100WatchedViewsFormatted.series[0].data.push([
			it.date,
			'video_p100_watched_views' in it ? it.video_p100_watched_views[metricObjectName] || 0 : 0
		])
		socialSpendFormatted.series[0].data.push([it.date, 'social_spend' in it ? it.social_spend[metricObjectName] || 0 : 0])
		inlineLinkClicksFormatted.series[0].data.push([it.date, 'inline_link_clicks' in it ? it.inline_link_clicks[metricObjectName] || 0 : 0])
		inlinePostEngagementFormatted.series[0].data.push([
			it.date,
			'inline_post_engagement' in it ? it.inline_post_engagement[metricObjectName] || 0 : 0
		])
		reachFormatted.series[0].data.push([it.date, 'reach' in it ? it.reach[metricObjectName] || 0 : 0])
		inlineLinkClickCtrFormatted.series[0].data.push([
			it.date,
			'inline_link_click_ctr' in it ? it.inline_link_click_ctr[metricObjectName] || 0 : 0
		])
		costPerUniqueClickFormatted.series[0].data.push([
			it.date,
			'cost_per_unique_click' in it ? it.cost_per_unique_click[metricObjectName] || 0 : 0
		])
		cppFormatted.series[0].data.push([it.date, 'cpp' in it ? it.cpp[metricObjectName] || 0 : 0])
		frequencyFormatted.series[0].data.push([
			it.date,
			'frequency' in it && !isNaN(it.frequency[metricObjectName]) ? it.frequency[metricObjectName] || 0 : 0
		])
		conversionsFormatted.series[0].data.push([
			it.date,
			'conversions' in it && !isNaN(it.conversions[metricObjectName]) ? it.conversions[metricObjectName] || 0 : 0
		])
		costPer15SecVideoViewFormatted.series[0].data.push([
			it.date,
			'cost_per_15_sec_video_view' in it ? it.cost_per_15_sec_video_view[metricObjectName] || 0 : 0
		])
		costPerPostEngagementFormatted.series[0].data.push([
			it.date,
			'cost_per_post_engagement' in it ? it.cost_per_post_engagement[metricObjectName] || 0 : 0
		])
		costPerVideoViewFormatted.series[0].data.push([it.date, 'cost_per_video_view' in it ? it.cost_per_video_view[metricObjectName] || 0 : 0])
		costPerPageEngagementFormatted.series[0].data.push([
			it.date,
			'cost_per_page_engagement' in it ? it.cost_per_page_engagement[metricObjectName] || 0 : 0
		])
		costPerInlinePostEngagementFormatted.series[0].data.push([
			it.date,
			'cost_per_inline_post_engagement' in it ? it.cost_per_inline_post_engagement[metricObjectName] || 0 : 0
		])
		cpcFormatted.series[0].data.push([it.date, 'cpc' in it ? it.cpc[metricObjectName] || 0 : 0])
		cpmFormatted.series[0].data.push([it.date, 'cpm' in it ? it.cpm[metricObjectName] || 0 : 0])
		ctrFormatted.series[0].data.push([it.date, 'ctr' in it ? it.ctr[metricObjectName] || 0 : 0])
	})

	//soma series
	const clicksFormattedTotal = sumTotalFromSeries(clicksFormatted.series, 'total')
	const impressionsFormattedTotal = sumTotalFromSeries(impressionsFormatted.series, 'total')
	const pageEngagementFormattedTotal = sumTotalFromSeries(pageEngagementFormatted.series, 'total')
	const postEngagementFormattedTotal = sumTotalFromSeries(postEngagementFormatted.series, 'total')
	const postReactionsFormattedTotal = sumTotalFromSeries(postReactionsFormatted.series, 'total')
	const spendFormattedTotal = sumTotalFromSeries(spendFormatted.series, 'total')
	const videoViewsFormattedTotal = sumTotalFromSeries(videoViewsFormatted.series, 'total')
	const purchaseRoasFormattedTotal = sumTotalFromSeries(purchaseRoasFormatted.series, 'total')
	const videoP25WatchedViewsFormattedTotal = sumTotalFromSeries(videoP25WatchedViewsFormatted.series, 'total')
	const videoP100WatchedViewsFormattedTotal = sumTotalFromSeries(videoP100WatchedViewsFormatted.series, 'total')
	const socialSpendFormattedTotal = sumTotalFromSeries(socialSpendFormatted.series, 'total')
	const inlineLinkClicksFormattedTotal = sumTotalFromSeries(inlineLinkClicksFormatted.series, 'total')
	const inlinePostEngagementFormattedTotal = sumTotalFromSeries(inlinePostEngagementFormatted.series, 'total')
	const reachFormattedTotal = ''
	const inlineLinkClickCtrFormattedTotal = ''
	const costPerUniqueClickFormattedTotal = ''
	const cppFormattedTotal = ''
	const frequencyFormattedTotal = ''
	const conversionsFormattedTotal = ''
	const costPer15SecVideoViewFormattedTotal = ''
	const costPerPostEngagementFormattedTotal = spendFormattedTotal / postEngagementFormattedTotal
	const costPerVideoViewFormattedTotal = spendFormattedTotal / videoViewsFormattedTotal
	const costPerPageEngagementFormattedTotal = spendFormattedTotal / pageEngagementFormattedTotal
	const costPerInlinePostEngagementFormattedTotal =
		inlinePostEngagementFormattedTotal === 0 || spendFormattedTotal === 0 ? 0 : spendFormattedTotal / inlinePostEngagementFormattedTotal
	const cpcFormattedTotal = spendFormattedTotal === 0 || clicksFormattedTotal === 0 ? 0 : spendFormattedTotal / clicksFormattedTotal
	const cpmFormattedTotal =
		(spendFormattedTotal === 0 || impressionsFormattedTotal === 0 ? 0 : spendFormattedTotal / impressionsFormattedTotal) * 1000
	const ctrFormattedTotal =
		(clicksFormattedTotal === 0 || impressionsFormattedTotal === 0 ? 0 : clicksFormattedTotal / impressionsFormattedTotal) * 100

	const graphsTotalMetrics = {
		clicksFormattedTotal,
		impressionsFormattedTotal,
		pageEngagementFormattedTotal,
		postEngagementFormattedTotal,
		postReactionsFormattedTotal,
		spendFormattedTotal,
		videoViewsFormattedTotal,
		purchaseRoasFormattedTotal,
		videoP25WatchedViewsFormattedTotal,
		videoP100WatchedViewsFormattedTotal,
		socialSpendFormattedTotal,
		inlineLinkClicksFormattedTotal,
		inlinePostEngagementFormattedTotal,
		reachFormattedTotal,
		inlineLinkClickCtrFormattedTotal,
		costPerUniqueClickFormattedTotal,
		cppFormattedTotal,
		frequencyFormattedTotal,
		conversionsFormattedTotal,
		costPer15SecVideoViewFormattedTotal,
		costPerPostEngagementFormattedTotal,
		costPerVideoViewFormattedTotal,
		costPerPageEngagementFormattedTotal,
		costPerInlinePostEngagementFormattedTotal,
		cpcFormattedTotal,
		cpmFormattedTotal,
		ctrFormattedTotal
	}

	const graphs = {
		clicksFormatted,
		impressionsFormatted,
		pageEngagementFormatted,
		postEngagementFormatted,
		postReactionsFormatted,
		spendFormatted,
		videoViewsFormatted,
		purchaseRoasFormatted,
		videoP25WatchedViewsFormatted,
		videoP100WatchedViewsFormatted,
		socialSpendFormatted,
		inlineLinkClicksFormatted,
		inlinePostEngagementFormatted,
		reachFormatted,
		inlineLinkClickCtrFormatted,
		costPerUniqueClickFormatted,
		cppFormatted,
		frequencyFormatted,
		conversionsFormatted,
		costPer15SecVideoViewFormatted,
		costPerPostEngagementFormatted,
		costPerVideoViewFormatted,
		costPerPageEngagementFormatted,
		costPerInlinePostEngagementFormatted,
		cpcFormatted,
		cpmFormatted,
		ctrFormatted
	}

	return { ...metricsToFormat, apiSeries: { graphs, graphsTotalMetrics } }
}

const sumTotalFromSeries = (series, type) => {
	if (type === 'individual') {
		let sumSeries = series.map((serie) => ({
			total: serie.data.reduce((total, value) => total + value[1], 0),
			serieName: serie.name
		}))
		let sum = { keys: [], values: [] }
		sumSeries.forEach((ss) => {
			sum.keys.push(ss.serieName)
			sum.values.push(ss.total)
		})
		return sum
	} else if (type === 'total') {
		return series.reduce((total, obj) => {
			const sumMatrix = obj.data.reduce((sum, element) => sum + element[1], 0)
			return total + sumMatrix
		}, 0)
	}
}

const extractAndAddInArraySeriesFromJSON = (jsonObject, date, arrayData) => {
	let object = Object.entries(jsonObject || {})
	if (object.length !== 0) {
		object.forEach((obj) => {
			const foundObject = arrayData.series.find((item) => item.name.includes(obj[0]))
			if (foundObject) {
				foundObject.data.push([date, obj[1]])
			} else {
				arrayData.series.push({ name: obj[0], type: 'area', data: [[date, obj[1]]] })
			}
		})
	}
}

const formatGenderAgeName = (name) => name.replace('F.', 'Feminino ').replace('M.', 'Masculino ').replace('U.', 'Indefinido ')

const formatOrderGenderNameInstagram = (series) => {
	series.forEach((it) => (it.name = it.name.replace('M', 'aaa').replace('F', 'bbb').replace('U', 'ccc')))
	series.sort((a, b) => a.name.localeCompare(b.name))
	series.forEach((it) => (it.name = it.name.replace('aaa', 'Masculino').replace('bbb', 'Feminino').replace('ccc', 'Indefinido')))
}

export { formatMetricsAccountManagerFacebook, formatMetricsAccountManagerInstagram, formatMetricsAccountManagerMetaAds }
