import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import mainReducer from './reducers/main.reducer'
import { taskMiddleware } from 'react-palm/tasks'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reduxStore = combineReducers({
	store: mainReducer
})

export const store = createStore(reduxStore, {}, composeEnhancers(applyMiddleware(taskMiddleware)))
