import React, { useState, memo } from 'react'
import { Card, Grid, IconButton, Slide, styled, TextField, Divider } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { ChevronRightIcon, SearchIcon } from 'utils/SystemIcons'
import { LAYOUTS_DEFAULT_FACEBOOK } from 'pages/AccountManagement/components/AccountManagerMetricsFacebook/utils/AccountManagerMetricsFacebookUtils'
import { LAYOUTS_DEFAULT_INSTAGRAM } from 'pages/AccountManagement/components/AccountManagerMetricsInstagram/utils/AccountManagerMetricsInstagramUtils'
import { LAYOUTS_DEFAULT_META_ADS } from 'pages/AccountManagement/components/AccountManagerMetricsMetaAds/utils/AccountManagerMetricsMetaAdsUtils'
const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 0.1)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: blue
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: grey
		},
		'&:hover fieldset': {
			borderColor: grey
		},
		'&.Mui-focused fieldset': {
			borderColor: blue
		}
	}
})

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		borderRadius: '20px',
		backdropFilter: 'blur(10px)',
		backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
		border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
		boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
	}
}))

const WidgetItem = ({ widget, onChangeDragItem, setIsDroping, descriptions }) => {
	const [isGrabing, setIsGrabbing] = React.useState(false)
	const [isTooltipOpen, setIsTooltipOpen] = React.useState(true)
	const [isHovering, setIsHovering] = React.useState(false)

	const descriptionObject = descriptions?.find((description) => description?.key === widget.i)
	const title = descriptionObject?.title
	const description = descriptionObject?.description

	const TooltipDescription = () => {
		return (
			<>
				{isTooltipOpen ? (
					<div style={{ margin: '1em' }}>
						<div style={{ fontFamily: 'Poppins', color: '#878787', fontSize: '1.6em', fontWeight: 'bold', marginBottom: '1em' }}>
							{title || ''}
						</div>
						<div style={{ fontFamily: 'Poppins', color: '#878787', fontSize: '1.4em' }}>{description || ''}</div>
					</div>
				) : (
					<div style={{ height: '8px', borderRadius: '100px' }}></div>
				)}
			</>
		)
	}

	return (
		<div
			style={{
				marginTop: '3px',
				marginBottom: '1em',
				cursor: isGrabing ? 'grabbing' : 'grab'
			}}
			key={widget.i}
			onPointerEnter={() => setIsHovering(true)}
			onPointerLeave={() => setIsHovering(false)}
			draggable={true}
			onMouseDown={() => setIsGrabbing(true)}
			onMouseUp={() => setIsGrabbing(false)}
			onDragStart={(e) => {
				setIsGrabbing(false)
				setIsDroping(true)
				setIsTooltipOpen(false)
				onChangeDragItem({ i: 'dropItem', keyName: widget.i, ...widget })
				e.dataTransfer.setData('text/plain', '')
			}}
			onDragEnd={() => {
				setIsDroping(false)
				setIsTooltipOpen(true)
			}}
		>
			<HtmlTooltip title={<TooltipDescription />} placement='left' enterDelay={0} enterNextDelay={0}>
				<Card
					elevation={1}
					style={{
						height: '1.5em',
						marginLeft: '0.4em',
						width: '89%',
						padding: '0.5em',
						borderRadius: '25px',
						borderBottom: '1px solid rgba(28,110,164,0.08)',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
						display: 'flex',
						alignItems: 'center',
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`
					}}
				>
					<div
						style={{
							margin: '8px',
							fontFamily: 'Poppins',
							color: `${isHovering ? 'rgba(29, 138, 248, 0.7)' : '#878787'}`,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
					>
						{title}
					</div>
				</Card>
			</HtmlTooltip>
		</div>
	)
}

const widgets = {
	facebook: LAYOUTS_DEFAULT_FACEBOOK,
	instagram: LAYOUTS_DEFAULT_INSTAGRAM,
	metaAds: LAYOUTS_DEFAULT_META_ADS
}

const AccountManagerAddWidgets = ({ addWidgetMode, toggleAddWidgetMode, onChangeDragItem, widgetsOnscreen, setIsDroping, source, descriptions }) => {
	const [widgetNameFilter, setWidgetNameFilter] = useState('')

	return (
		<Slide direction='left' in={addWidgetMode}>
			<Card
				elevation={1}
				style={{
					height: '82.5vh',
					padding: '0.3em',
					borderRadius: '25px',
					borderBottom: '1px solid rgba(28,110,164,0.08)',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`
				}}
			>
				<Grid container justifyContent='flex-start' style={{ marginBottom: '1em' }}>
					<IconButton style={{ marginTop: '0.4em' }} color='primary' onClick={toggleAddWidgetMode}>
						<ChevronRightIcon color='rgba(29, 138, 248, 0.7)' />
					</IconButton>
					<CssTextField
						value={widgetNameFilter}
						style={{ marginLeft: '1em' }}
						variant='standard'
						onChange={(e) => setWidgetNameFilter(e.target.value)}
						label={
							<div style={{ display: 'flex', marginTop: '-6px', whiteSpace: 'nowrap' }}>
								<SearchIcon size={17} style={{ position: 'relative', transform: 'translate(0px, 5px)', color: '#3d8cf8' }} />
								<div style={{ marginLeft: '5px', marginTop: '0px', fontFamily: 'Poppins', fontSize: '15px' }}>Buscar Widget</div>
							</div>
						}
					/>
					<Divider style={{ backgroundColor: 'transparent', height: '1px', width: '100%', marginTop: '-2px' }} />
				</Grid>
				<div style={{ height: '75vh', overflowY: 'auto', paddingRight: '1em' }}>
					{widgets[source].md
						.filter(
							(widget) =>
								!widgetsOnscreen.includes(widget.i) &&
								descriptions
									?.find((description) => description.key === widget.i)
									?.title?.replace(/,/g, ' ')
									?.toLowerCase()
									?.indexOf((widgetNameFilter || '').toString().toLowerCase()) > -1
						)
						.map((widget) => (
							<WidgetItem
								key={widget.i}
								widget={widget}
								onChangeDragItem={onChangeDragItem}
								setIsDroping={setIsDroping}
								descriptions={descriptions}
							/>
						))}
				</div>
			</Card>
		</Slide>
	)
}

export default memo(AccountManagerAddWidgets)
