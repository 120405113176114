Moment.locale('pt-br')
import React, { useState, useEffect } from 'react'
import {
	Grid,
	Dialog,
	IconButton,
	CardContent,
	DialogContent,
	DialogTitle,
	Collapse,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { InfoIcon, CloseIcon, LoadingIcon, CardIcon, CheckIcon, ForbiddenIcon } from 'utils/SystemIcons'
import Moment from 'moment-timezone'
import api from 'service/service'
import { isEmpty } from 'lodash'
import 'moment/locale/pt-br'
import constantsVox from 'constants-vox'

const grey = '#b8b8b8'

const useStyles = makeStyles({
	backDrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(220, 220, 220, 0.4)'
	},
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	}
})

const isFirefox = typeof InstallTrigger !== 'undefined'

const checkValidConfig = (configList) => {
	if (isEmpty(configList)) {
		return 'Não existem configs para este cartão!'
	} else {
		return (
			<TableContainer sx={{ minWidth: '35em', paddingTop: '1em' }}>
				<Table sx={{ backgroundColor: 'white', borderRadius: '20px' }}>
					<TableHead>
						<TableRow>
							<TableCell align='center' sx={{ fontWeight: 'bold' }}>
								Status
							</TableCell>
							<TableCell align='center' sx={{ fontWeight: 'bold' }}>
								Termo
							</TableCell>
							<TableCell align='center' sx={{ fontWeight: 'bold' }}>
								Última coleta
							</TableCell>
							<TableCell align='center' sx={{ fontWeight: 'bold' }}>
								Próxima coleta
							</TableCell>
							<TableCell align='center' sx={{ fontWeight: 'bold' }}>
								Intervalo coleta
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{configList.map((row) => (
							<TableRow key={row.term} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell align='center'>{checkStatus(row.data_load_status)}</TableCell>
								<TableCell component='th' scope='row' align='center'>
									{row.term.toLowerCase()}
								</TableCell>
								<TableCell align='center'>{Moment(row.data_load_last_date).format('DD/MM/YYYY - HH:mm:ss')}</TableCell>
								<TableCell align='center'>
									{Moment(Moment(row.data_load_last_date).add(row.time_next_process_in_minutes, 'minutes')).format(
										'DD/MM/YYYY - HH:mm:ss'
									)}
								</TableCell>
								<TableCell align='center'>{formatInterval(row.time_next_process_in_minutes)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

const checkStatus = (status) => {
	switch (status) {
		case constantsVox.CARD.STATUS.CREATE:
		case constantsVox.CARD.STATUS.ALTER:
		case constantsVox.CARD.STATUS.PENDING:
			return (
				<Tooltip title={'Processando'} placement='bottom'>
					<IconButton disableRipple>
						<LoadingIcon size={25} style={{ color: '#ac60f7' }} />
					</IconButton>
				</Tooltip>
			)
		case constantsVox.CARD.STATUS.EMPTY:
			return (
				<Tooltip title={'Vazio'} placement='bottom'>
					<IconButton disableRipple>
						<ForbiddenIcon size={25} style={{ color: '#ac60f7' }} />
					</IconButton>
				</Tooltip>
			)
		case constantsVox.CARD.STATUS.WRONG:
			return (
				<Tooltip title={'Erro'} placement='bottom'>
					<IconButton disableRipple>
						<CloseIcon size={25} style={{ color: '#f50057' }} />
					</IconButton>
				</Tooltip>
			)
		case constantsVox.CARD.STATUS.SUCCESS:
			return (
				<Tooltip title={'Sucesso'} placement='bottom'>
					<IconButton disableRipple>
						<CheckIcon size={25} style={{ color: '#1d8cf8' }} />
					</IconButton>
				</Tooltip>
			)
	}
}

const formatInterval = (interval) => {
	const hours = interval / 60
	const minutes = interval % 60
	return minutes === 0 ? `${hours} horas` : `${Math.floor(hours)}h e ${minutes}min`
}

const ModalCardConfig = ({ isOpen, handleOnClose, card, color, source }) => {
	const classes = useStyles()
	const [cardInfo, setCardInfo] = useState(null)
	const [configList, setConfigList] = useState(null)
	const [loadingConfigList, setLoadingConfigList] = useState(false)

	useEffect(() => {
		setCardInfo(card)
	}, [card])

	useEffect(() => {
		setLoadingConfigList(true)
		if (cardInfo) {
			api.get(`/monitoring/card/get-config-by-type-and-source/${cardInfo.id}/${cardInfo?.type}/${source}`)
				.then((response) => {
					setConfigList(response.data)
				})
				.finally(() => setLoadingConfigList(false))
		}
	}, [cardInfo])

	return (
		<Dialog
			id='dialog-app'
			fullWidth={true}
			maxWidth='md'
			style={{ marginTop: '4%' }}
			open={isOpen}
			onClose={handleOnClose}
			BackdropProps={{ classes: { root: classes.backDrop } }}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.7)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )',
					maxWidth: '50em'
				}
			}}
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					<div style={{ color: color, fontSize: 20 }}>
						<InfoIcon className='fix-icon-margin-bottom-negative' size={25} color={color} /> Configs do cartão
					</div>
					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={handleOnClose}>
							<CloseIcon color={color} size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ paddingTop: 0, marginTop: 0 }}>
				<CardContent style={{ padding: 0, margin: 0 }}>
					<Grid
						container
						style={{
							padding: '1.5em',
							borderRadius: '20px',
							color: 'rgb(120, 120, 120)',
							fontFamily: 'Poppins',
							fontWeight: '300',
							fontSize: '20px',
							justifyContent: 'center'
						}}
					>
						<Grid item xs={12} style={{ display: 'flex', paddingTop: 20, paddingLeft: '0.6em' }}>
							<CardIcon
								className='fix-icon-margin-bottom-negative'
								size={25}
								color={color}
								style={{ marginTop: '0.15em', marginRight: '0.4em', color }}
							/>
							<div style={{ color: color, fontSize: 20 }}>{cardInfo?.name}</div>
							{loadingConfigList && (
								<div style={{ marginLeft: '10px', marginTop: '2px' }}>
									<LoadingIcon size={20} thickness={1} style={{ color: color }} />
								</div>
							)}
						</Grid>

						<Collapse in={!loadingConfigList}>{!loadingConfigList && checkValidConfig(configList)}</Collapse>
					</Grid>
				</CardContent>
			</DialogContent>
		</Dialog>
	)
}

export default ModalCardConfig
