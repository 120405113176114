import constantsVox from 'constants-vox'
import { isEmpty, isNull, isUndefined, cloneDeep } from 'lodash'

const getConfigGroupStatus = (configStatuses) => {
	const priorityList = ['C', 'A', 'S', 'P', 'E', 'W']
	let mainStatus = null

	for (let status of priorityList) {
		let statusFound = configStatuses.find((it) => it === status)
		if (statusFound) {
			mainStatus = statusFound
			break
		}
	}

	return mainStatus
}

const verifyCanShowMenuItem = (dataMenu) => {
	let card_data_status = getConfigGroupStatus(dataMenu.map((it) => it.cardDataStatus))
	let twitter_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.twitterStatus))
	let facebook_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.facebookStatus))
	let instagram_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.instagramStatus))
	let tiktok_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.tiktokStatus))
	let youtube_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.youtubeStatus))
	let news_data_status = getConfigGroupStatus(dataMenu.map((it) => it.config.newsStatus))

	return {
		card_data_status,
		twitter_data_status,
		news_data_status,
		facebook_data_status,
		instagram_data_status,
		tiktok_data_status,
		youtube_data_status
	}
}

const verifyCanShowMenuItemSocialProfile = (dataMenu) => {
	let card_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.general))
	let twitter_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.twitter))
	let facebook_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.facebook))
	let instagram_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.instagram))
	let tiktok_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.tiktok))

	return {
		card_data_status,
		twitter_data_status,
		facebook_data_status,
		instagram_data_status,
		tiktok_data_status
	}
}

const getAccountGroupStatus = (accountStatuses) => {
	const priorityList = ['C', 'A', 'S', 'P', 'E', 'W']
	let mainStatus = null

	for (let status of priorityList) {
		let statusFound = accountStatuses.find((it) => it === status)
		if (statusFound) {
			mainStatus = statusFound
			break
		}
	}

	return mainStatus
}

const verifyServerResponseCanShowToast = (apiResponse) => {
	return !!apiResponse?.response?.data?.toast || !!apiResponse?.data?.toast
}

const isEmptyStr = function (value) {
	return isEmpty(value) || isNull(value) || isUndefined(value) || value == 'null' || value == 'undefined'
}

const verifyCardComparisonStatuses = (cardComparison, type) => {
	if (type === 'group') {
		return { general: 'S', twitter: 'S', facebook: 'S', instagram: 'S', tiktok: null, news: null, metaAds: null }
	}

	let general = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.general))
	let twitter = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.twitter))
	let facebook = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.facebook))
	let instagram = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.instagram))
	let tiktok = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.tiktok))
	let youtube = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.youtube))
	let metaAds = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.metaAds))

	if (type === constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT) {
		return {
			general,
			twitter,
			facebook,
			instagram,
			tiktok,
			metaAds
		}
	} else {
		let news = getConfigGroupStatus(cardComparison.map((it) => it.cardStatus?.news))
		return {
			general,
			twitter,
			facebook,
			instagram,
			tiktok,
			youtube,
			news
		}
	}
}

const verifyCanShowMenuItemSocialProfileAccountManagement = (dataMenu) => {
	const card_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.general))
	const twitter_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.twitter))
	const facebook_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.facebook))
	const instagram_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.instagram))
	const tiktok_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.tiktok))
	const meta_ads_data_status = getAccountGroupStatus(dataMenu.map((it) => it.cardStatus?.metaAds))

	//posts carregam mais lentamente, só pra avisar pro usuário que tá carregando se for o caso
	const twitterDataLoadStatusPosts = getAccountGroupStatus(dataMenu.map((it) => it.dataLoadStatusPosts?.twitter))
	const facebookDataLoadStatusPosts = getAccountGroupStatus(dataMenu.map((it) => it.dataLoadStatusPosts?.facebook))
	const instagramDataLoadStatusPosts = getAccountGroupStatus(dataMenu.map((it) => it.dataLoadStatusPosts?.instagram))
	const tiktokDataLoadStatusPosts = getAccountGroupStatus(dataMenu.map((it) => it.dataLoadStatusPosts?.tiktok))
	const metaAdsDataLoadStatusPosts = getAccountGroupStatus(dataMenu.map((it) => it.dataLoadStatusPosts?.metaAds))

	return {
		card_data_status,
		twitter_data_status,
		facebook_data_status,
		instagram_data_status,
		tiktok_data_status,
		meta_ads_data_status,
		twitterDataLoadStatusPosts,
		facebookDataLoadStatusPosts,
		instagramDataLoadStatusPosts,
		tiktokDataLoadStatusPosts,
		metaAdsDataLoadStatusPosts
	}
}

const orderKeys = (obj) => {
	let keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
		if (k1 < k2) {
			return -1
		} else if (k1 > k2) {
			return +1
		} else {
			return 0
		}
	})

	let i
	let after = {}

	for (i = 0; i < keys.length; i++) {
		after[keys[i]] = obj[keys[i]]
		delete obj[keys[i]]
	}

	for (i = 0; i < keys.length; i++) {
		obj[keys[i]] = after[keys[i]]
	}
	return obj
}

function fillObjectBlankProperties(original, current) {
	const filledObject = cloneDeep(current)
	const originalKeys = Object.keys(original)

	for (let key of originalKeys) {
		if (!(key in filledObject)) {
			filledObject[key] = original[key]
		}
	}

	return filledObject
}

// function fillObjectBlankProperties(original, current) {
// 	let filledObject = cloneDeep(current)
// 	let originalkeys = Object.keys(original)
// 	for (let i = 0; i < originalkeys.length; i++) {
// 		if (typeof filledObject[originalkeys[i]] != typeof original[originalkeys[i]]) {
// 			filledObject[originalkeys[i]] = original[originalkeys[i]]
// 		}
// 		if (filledObject[originalkeys[i]] != original[originalkeys[i]]) {
// 			filledObject[originalkeys[i]] = original[originalkeys[i]]
// 		}
// 	}
// 	return filledObject
// }

const round = (value, minimumFractionDigits, maximumFractionDigits) => {
	if (value === null || isNaN(value) || value === 0) {
		return 0
	}

	const formattedValue = value.toLocaleString('en', {
		useGrouping: false,
		minimumFractionDigits,
		maximumFractionDigits
	})

	return Number(formattedValue)
}

export {
	verifyCanShowMenuItem,
	verifyServerResponseCanShowToast,
	isEmptyStr,
	verifyCanShowMenuItemSocialProfile,
	verifyCardComparisonStatuses,
	verifyCanShowMenuItemSocialProfileAccountManagement,
	orderKeys,
	fillObjectBlankProperties,
	round
}
