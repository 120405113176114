import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Tooltip, Grid } from '@mui/material'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { NeutralIcon, PlusIcon, MinusIcon, CloseIcon } from 'utils/SystemIcons'

import api from 'service/service'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'
const purple = 'rgba(172, 96, 247, 100%)'
const red = 'rgba(255, 43, 107, 100%)'
const black = 'rgba(77, 77, 77, 100%)'
const lightGrey = 'rgba(184, 184, 184, 70%)'

const useStyles = makeStyles(() => ({
	buttonBlue: { height: 56, minHeight: 56, color: blue },
	buttonPurple: { height: 56, minHeight: 56, color: purple },
	buttonRed: { height: 56, minHeight: 56, color: red },
	buttonGrey: { height: 56, minHeight: 56, color: grey }
}))

const iconSize = 20
const tooltipDelay = 500

const Plus = ({ color }) => <PlusIcon size={iconSize} style={{ color: color }} />
const Neutral = ({ color }) => <NeutralIcon size={iconSize} style={{ color: color }} />
const Minus = ({ color }) => <MinusIcon size={iconSize} style={{ color: color }} />
const Irrelevant = ({ color }) => <CloseIcon size={iconSize} style={{ color: color }} />

const OccurrenceRelevance = ({
	occurrenceId,
	id_pesquisa,
	source,
	onRelevanceSubmitting,
	onHideCard,
	onColorChange,
	setColorChangeSubmitting,
	automaticOrManualSentiment,
	classification,
	setCardVisible,
	cardType,
	onSentimetntTextChange
}) => {
	const [submitting, setSubmitting] = useState(false)
	const [activeSentiment, setActiveSentiment] = useState(!automaticOrManualSentiment.isAutomatic && automaticOrManualSentiment.value)
	const classes = useStyles()

	useEffect(() => {
		if (activeSentiment) {
			onSentimetntTextChange(activeSentiment)
		}
	}, [activeSentiment])

	const saveRelevance = async (impact) => {
		setSubmitting(true)
		onRelevanceSubmitting(true)
		await api
			.post('/occurrences/update-occurrence-relevancy', {
				occurrenceId,
				id_pesquisa,
				impact,
				source,
				cardType
			})
			.then(() => {
				changeCardColor(impact, classification)
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				onRelevanceSubmitting(false)
				setColorChangeSubmitting(false)
			})
	}

	const hideCard = (impact) => {
		if (onHideCard) {
			setCardVisible(false)
			setTimeout(() => {
				onHideCard(impact)
			}, 270)
		}
	}

	const changeCardColor = (impact, classification) => {
		switch (impact) {
			case 'good':
				onColorChange(blue)
				if (!classification.positive || !classification.hasManualClassification) {
					hideCard(impact)
				}
				break
			case 'neutral':
				onColorChange(purple)
				if (!classification.neutral || !classification.hasManualClassification) {
					hideCard(impact)
				}
				break
			case 'bad':
				onColorChange(red)
				if (!classification.negative || !classification.hasManualClassification) {
					hideCard(impact)
				}
				break
			default:
				onColorChange(black)
				if (!classification.irrelevant || !classification.hasManualClassification) {
					hideCard(impact)
				}
				break
		}
	}

	const handleClick = (type) => {
		if (!submitting) {
			switch (type) {
				case 'plus':
					saveRelevance('good')
					setActiveSentiment('positive')
					break
				case 'neut':
					saveRelevance('neutral')
					setActiveSentiment('neutral')
					break
				case 'min':
					saveRelevance('bad')
					setActiveSentiment('negative')
					break
				case 'irrelevant':
					saveRelevance('irrelevant')
					setActiveSentiment('irrelevant')
					break
				default:
					break
			}
		}
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs>
					<Tooltip title='Classificar como positiva' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
						<Button
							id='btn-occurrences-positive'
							onClick={() => handleClick('plus')}
							className={classes.buttonBlue}
							fullWidth={true}
							style={{ outline: 'none', borderRadius: '100px' }}
							disabled={submitting}
						>
							<Plus color={activeSentiment === 'positive' ? blue : lightGrey} />
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs>
					<Tooltip title='Classificar como neutra' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
						<Button
							id='btn-occurrences-neutral'
							onClick={() => handleClick('neut')}
							className={classes.buttonPurple}
							fullWidth={true}
							style={{ outline: 'none', borderRadius: '100px' }}
							disabled={submitting}
						>
							<Neutral color={activeSentiment === 'neutral' ? purple : lightGrey} />
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs>
					<Tooltip title='Classificar como negativa' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
						<Button
							id='btn-occurrences-negative'
							onClick={() => handleClick('min')}
							className={classes.buttonRed}
							fullWidth={true}
							style={{ outline: 'none', borderRadius: '100px' }}
							disabled={submitting}
						>
							<Minus color={activeSentiment === 'negative' ? red : lightGrey} />
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs>
					<Tooltip title='Classificar como irrelevante' placement='top' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
						<Button
							id='btn-occurrences-irrelevant'
							onClick={() => handleClick('irrelevant')}
							className={classes.buttonGrey}
							fullWidth={true}
							style={{ outline: 'none', borderRadius: '100px' }}
							disabled={submitting}
						>
							<Irrelevant color={activeSentiment === 'irrelevant' ? black : lightGrey} />
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
		</>
	)
}

export default OccurrenceRelevance
