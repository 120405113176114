import React, { useState } from 'react'
import { Grid, Button, IconButton, Menu } from '@mui/material'
import { DotsVerticalIcon, EditIcon, DeleteBinIcon, CircleIcon } from 'utils/SystemIcons'
import EditTopicDialog from '../EditTopicDialog/EditTopicDialog'
import RemoveTopicDialog from '../RemoveTopicDialog/RemoveTopicDialog'
import MergeTopicDialog from '../MergeTopicDialog/MergeTopicDialog'
import SplitTopicsDialog from '../SplitTopicsDialog/SplitTopicsDialog'

const isFirefox = typeof InstallTrigger !== 'undefined'

const Buttonstyle = {
	borderRadius: '100px',
	textAlign: 'center',
	color: '#9A9A9A',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '15em',
	fontFamily: 'Poppins'
}

const iconSize = 15
const iconstyle = { color: '#9A9A9A', marginRight: '0.3em' }
const mergeIconStyle = { marginLeft: '-0.8em', marginRight: '0.3em' }
const splitIconStyle = { marginLeft: '-0.2em', marginRight: '0.3em' }

const EditOption = <EditIcon size={iconSize} style={iconstyle} />
const DeleteOption = <DeleteBinIcon size={iconSize} style={iconstyle} />

const MergeOption = (
	<>
		<CircleIcon size={iconSize} style={iconstyle} />
		<CircleIcon size={iconSize} style={mergeIconStyle} />
	</>
)

const SlitOption = (
	<>
		<CircleIcon size={iconSize} style={iconstyle} />
		<CircleIcon size={iconSize} style={splitIconStyle} />
	</>
)

const ButtonOption = ({ title, icon, onClick, disabled = false }) => (
	<Grid item xs={12} style={{ height: '3em' }} container justifyContent='center' alignItems='center'>
		<Button style={Buttonstyle} onClick={onClick} disabled={disabled}>
			{icon}
			{title}
		</Button>
	</Grid>
)

const TopicItemMenu = ({ topic, update, topics, cardId }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [openMergeDialog, setOpenMergeDialog] = useState(false)
	const [openSplitDialog, setOpenSplitDialog] = useState(false)

	const toggleMenu = (event) => {
		if (anchorEl) {
			setAnchorEl(null)
		} else {
			setAnchorEl(event.currentTarget)
		}
	}

	const toggleEditTopic = () => {
		setOpenEditDialog(!openEditDialog)
	}

	const toggleDeleteTopic = () => {
		setOpenDeleteDialog(!openDeleteDialog)
	}

	const toggleMergeTopic = () => {
		setOpenMergeDialog(!openMergeDialog)
	}

	const toggleSplitTopic = () => {
		setOpenSplitDialog(!openSplitDialog)
	}

	return (
		<>
			<IconButton onClick={toggleMenu}>
				<DotsVerticalIcon size={20} style={{ color: '#1d8cf8', cursor: 'pointer' }} />
			</IconButton>
			<Menu
				open={!!anchorEl}
				onClose={toggleMenu}
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				PaperProps={{
					style: {
						minWidth: '15em',
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.0)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				<ButtonOption
					title='EDITAR'
					icon={EditOption}
					onClick={() => {
						toggleMenu()
						toggleEditTopic()
					}}
				/>
				{topics?.filter((topic) => topic.topic_modeling_ia_type === 'TOPIC')?.length > 1 && (
					<ButtonOption
						title='JUNTAR'
						icon={MergeOption}
						onClick={() => {
							toggleMenu()
							toggleMergeTopic()
						}}
					/>
				)}
				{topic?.topic_modeling_ia_ids?.length > 1 && (
					<ButtonOption
						title='SEPARAR'
						icon={SlitOption}
						onClick={() => {
							toggleMenu()
							toggleSplitTopic()
						}}
					/>
				)}
				<ButtonOption
					title='DELETAR'
					icon={DeleteOption}
					onClick={() => {
						toggleMenu()
						toggleDeleteTopic()
					}}
				/>
			</Menu>
			<EditTopicDialog topic={topic} openEditDialog={openEditDialog} toggleEditTopic={toggleEditTopic} update={update} cardId={cardId} />
			<RemoveTopicDialog
				topic={topic}
				openDeleteDialog={openDeleteDialog}
				toggleDeleteTopic={toggleDeleteTopic}
				update={update}
				cardId={cardId}
			/>
			<MergeTopicDialog
				topic={topic}
				openMergeDialog={openMergeDialog}
				toggleMergeTopic={toggleMergeTopic}
				update={update}
				topics={topics?.filter((topic) => topic.topic_modeling_ia_type === 'TOPIC')}
				cardId={cardId}
			/>
			<SplitTopicsDialog
				topic={topic}
				openSplitDialog={openSplitDialog}
				toggleSplitTopic={toggleSplitTopic}
				update={update}
				topics={topics?.filter((topic) => topic.topic_modeling_ia_type === 'MERGED')}
				cardId={cardId}
			/>
		</>
	)
}

export default TopicItemMenu
