import React, { memo, Fragment } from 'react'
import { Grid } from '@mui/material'
import './InstagramContentBodyTerm.css'

import Summary from 'components/Summary/Summary'
import NetworkSummary from 'components/NetworkSummary/NetworkSummary'
import LocationStateAndRegionGraph from 'graphs/LocationStateAndRegionGraph/LocationStateAndRegionGraph'

const InstagramContentBodyTerm = ({
	cards,
	filters,
	summaryInstagram,
	networkInstagramAccounts,
	networkInstagramSentiments,
	locationInstagramVolume,
	locationInstagramSentiments,
	locationInstagramGender
}) => {
	return (
		<div className='instagram-body-container'>
			{summaryInstagram && (
				<Grid container>
					<Grid container item className='break-print'>
						<Grid item xs={12}>
							<Summary cards={cards} isReport={true} filtersReport={{ ...filters, source: 'instagram' }} />
						</Grid>
					</Grid>
				</Grid>
			)}

			{cards?.map((card) => (
				<Fragment key={card.id}>
					{networkInstagramAccounts && (
						<Grid container className='break-print' key={card.id}>
							<Grid item xs={12}>
								<NetworkSummary
									cardsIds={card.id}
									filters={{ ...filters, source: 'instagram' }}
									isReport={true}
									initialView={'accounts'}
								/>
							</Grid>
						</Grid>
					)}
					{networkInstagramSentiments && (
						<Grid container className='break-print' key={card.id}>
							<Grid item xs={12}>
								<NetworkSummary
									cardsIds={card.id}
									filters={{ ...filters, source: 'instagram' }}
									hasTitle={false}
									isReport={true}
									initialView={'sentiments'}
								/>
							</Grid>
						</Grid>
					)}
				</Fragment>
			))}

			{locationInstagramVolume && (
				<Grid container className='break-print'>
					<Grid item xs={12}>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'instagram' }}
							initialLocationView='volume'
							isReport={true}
							hasReportTitle={true}
						/>
					</Grid>
				</Grid>
			)}
			{locationInstagramGender && (
				<Grid container className='break-print'>
					<Grid xs={12}>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'instagram' }}
							initialLocationView='gender'
							isReport={true}
						/>
					</Grid>
				</Grid>
			)}
			{locationInstagramSentiments && (
				<Grid container className='break-print'>
					<Grid item xs={12}>
						<LocationStateAndRegionGraph
							cards={cards}
							filters={{ ...filters, source: 'instagram' }}
							initialLocationView='impact'
							isReport={true}
						/>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

export default memo(InstagramContentBodyTerm)
