import React, { useEffect, useState, memo } from 'react'
import { Grid, Card, IconButton, Slide } from '@mui/material'
import { DotsVerticalIcon, ChevronRightIcon } from 'utils/SystemIcons'
import TopicOption from '../TopicOption/TopicOption'

const isFirefox = typeof InstallTrigger !== 'undefined'

const createTopicOptions = (objects) => {
	let result = objects.map((obj) => ({ name: obj.name, active: true }))
	return result
}

const defineInitialFilterNames = (objects) => {
	let result = []

	objects.forEach((obj) => {
		let { name } = obj

		result.push(name)
	})

	return result
}

const TopicSideMenu = ({ series, onChangeFilter }) => {
	const [openSideMenu, setOpenSideMenu] = useState(false)
	const [topicOptions, setTopicOptions] = useState({})
	const [filterNames, setFilterNames] = useState(defineInitialFilterNames(series))

	useEffect(() => {
		setTopicOptions(createTopicOptions(series))
	}, [series])

	useEffect(() => {
		onChangeFilter(filterNames, openSideMenu)
	}, [filterNames])

	const onClickSideMenuButton = () => {
		setOpenSideMenu(!openSideMenu)
	}

	const onChangeTopicOptions = (obj) => {
		let newfilterNames = [...filterNames]

		if (obj.active) {
			newfilterNames = filterNames.filter((name) => name !== obj.name)
		} else {
			newfilterNames.push(obj.name)
		}

		let newHierarchy = topicOptions.map((it) => ({ ...it, active: it.name === obj.name ? !it.active : it.active }))

		setTopicOptions(newHierarchy)
		setFilterNames(newfilterNames)
	}

	return (
		<>
			<IconButton color='primary' onClick={onClickSideMenuButton}>
				<DotsVerticalIcon size={25} style={{ color: '#1d8cf8' }} />
			</IconButton>
			<Slide direction='left' in={openSideMenu} style={{ position: 'absolute', zIndex: '100' }}>
				<Card
					style={{
						width: '50vw',
						height: '58.5vh',
						borderRadius: '20px 0px 20px 20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}}
				>
					<IconButton color='primary' onClick={onClickSideMenuButton}>
						<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
					</IconButton>
					<div style={{ height: '50vh', overflowY: 'auto' }}>
						{topicOptions !== {} && (
							<Grid container>
								{Object.keys(topicOptions).map((name, index) => {
									return <TopicOption key={name + index} obj={topicOptions[name]} onChangeTopicOptions={onChangeTopicOptions} />
								})}
							</Grid>
						)}
					</div>
				</Card>
			</Slide>
		</>
	)
}

export default memo(TopicSideMenu)
