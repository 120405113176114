const lightGreen = 'rgb(0, 222, 174)'
const lightGrey = 'rgb(220, 220, 220)'
const pink = 'rgb(237, 40, 146)'
const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'
const purple = 'rgba(172, 96, 247, 100%)'
const red = 'rgba(255, 43, 107, 100%)'

const buildOptionsBar = (categories, isStacked, isVertical, colors, showForSingleSeries, isReport) => {
	let defaultOptions = {
		colors,
		chart: {
			type: 'bar',
			stacked: isStacked,
			stackType: '100%'
		},
		plotOptions: {
			bar: {
				horizontal: isVertical ? false : true,
				dataLabels: {
					position: 'center'
				}
			}
		},
		dataLabels: {
			enabled: true,
			offsetX: 0,
			style: {
				fontSize: '14px',
				fontFamily: 'Roboto',
				colors: ['#fff']
			},
			dropShadow: {
				enabled: true,
				top: 1,
				left: 0,
				blur: 1,
				color: '#000',
				opacity: 0.45
			},
			hideOverflowingLabels: true
		},
		xaxis: {
			categories
		},
		yaxis: {
			show: true
		},
		fill: {
			opacity: 1
		},
		legend: {
			showForSingleSeries
		}
	}

	if (isReport) {
		defaultOptions.chart.toolbar = { show: false }
	}

	if (isStacked) {
		defaultOptions.grid = {
			show: false
		}

		if (isVertical) {
			defaultOptions.yaxis.show = false
		}
	}

	return defaultOptions
}

const buildLocationRegionGraphOptions = (categories, isStacked, isVertical, type, series, isReport) => {
	let categoriesColors = series?.map((s) => s.name) || []
	let showForSingleSeries = true

	//Melhorar esse código 24/05/2022
	let colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#ffcc00']
	if (type === 'volume') {
		showForSingleSeries = false
	} else if (type === 'gender') {
		colors = categoriesColors?.map((cc) => {
			switch (cc) {
				case 'Feminino':
					return pink
				case 'Masculino':
					return blue
				case 'Sem class.':
					return lightGrey
				case 'Organização':
					return lightGreen
				default:
					return lightGrey
			}
		})
	} else if (type === 'impact') {
		colors = categoriesColors?.map((cc) => {
			switch (cc) {
				case 'Positivo':
					return blue
				case 'Negativo':
					return red
				case 'Neutro':
					return purple
				case 'Bot':
					return grey
				default:
					return grey
			}
		})
	}
	return buildOptionsBar(categories, isStacked, isVertical, colors, showForSingleSeries, isReport)
}

export { buildLocationRegionGraphOptions }
