import React, { useState, useEffect } from 'react'
import { Drawer, Grid, IconButton, Button, Collapse } from '@mui/material'
import { ChevronRightIcon, CloudDownloadIcon, DeleteBinIcon, CheckIcon, CloseIcon } from 'utils/SystemIcons'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import api from 'service/service'
import '../../GlobalFilters.css'

const isFirefox = typeof InstallTrigger !== 'undefined'

const Filter = ({ filter, disabled, loadSavedFilter, toggleLoadMenu, removeFilter }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const toggleConfirmDelete = () => {
		setTimeout(() => {
			setConfirmDelete(!confirmDelete)
		}, 300)
	}

	const handleConfirmClick = (e, id) => {
		e.preventDefault()
		setTimeout(() => {
			setSubmitting(true)
			removeFilter(e, id)
		}, 300)
	}

	return (
		<Grid item xs={12}>
			<Collapse in={!submitting} timeout={300}>
				<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em', width: '28em' }}>
					<Button
						id={`btn-load-filter-${filter?.name}`}
						disabled={disabled}
						onClick={() => {
							loadSavedFilter(filter?.config)
							toggleLoadMenu()
						}}
						style={{
							backgroundColor: '#fff',
							borderRadius: '25px 0px 0px 25px',
							fontFamily: 'Poppins',
							width: `${confirmDelete ? '20.4em' : '25em'}`,
							display: 'flex',
							justifyContent: 'flex-start'
						}}
					>
						<CloudDownloadIcon size={25} style={{ color: '#1d8cf8', marginLeft: '8px', marginRight: '10px' }} />
						{filter?.name}
					</Button>
					{!confirmDelete && (
						<Button
							id={`btn-delete-filter-${filter?.name}`}
							disabled={disabled}
							onClick={toggleConfirmDelete}
							style={{
								color: '#f50057',
								backgroundColor: '#fff',
								borderRadius: '0px 25px 25px 0px',
								fontFamily: 'Poppins',
								width: '2em'
							}}
						>
							<DeleteBinIcon size={25} style={{ color: '#f50057' }} />
						</Button>
					)}
					{confirmDelete && (
						<div style={{ width: '2em', display: 'flex' }}>
							<Button
								id={`btn-confirm-delete-filter-${filter?.name}`}
								disabled={disabled}
								onClick={(e) => handleConfirmClick(e, filter.id)}
								style={{
									color: '#1d8cf8',
									backgroundColor: '#fff',
									borderRadius: '0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CheckIcon size={25} style={{ color: '#1d8cf8' }} />
							</Button>
							<Button
								id={`btn-cancel-delete-filter-${filter?.name}`}
								disabled={disabled}
								onClick={toggleConfirmDelete}
								style={{
									color: '#f50057',
									backgroundColor: '#fff',
									borderRadius: '0px 25px 25px 0px',
									fontFamily: 'Poppins',
									width: '0.5em'
								}}
							>
								<CloseIcon size={25} style={{ color: '#f50057' }} />
							</Button>
						</div>
					)}
				</Grid>
			</Collapse>
		</Grid>
	)
}

const LoadFilter = ({ isOpen, toggleLoadMenu, type, loadSavedFilter, openSaveMenu }) => {
	const [submitting, setSubmitting] = useState(false)

	const { data, refetch } = useUpdateData({
		url: `/global-filters/get-filters/${type}`,
		updateItemName: 'filters',
		refetchInterval: 60000,
		freshDataTime: 60000
	})

	useEffect(() => {
		refetch()
	}, [isOpen, openSaveMenu])

	const removeFilter = async (event, filterId) => {
		event.preventDefault()

		setSubmitting(true)

		api.post('/global-filters/remove-filter', {
			filterId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				refetch()
			})
	}

	return (
		<Drawer
			anchor='right'
			open={isOpen}
			onClose={() => toggleLoadMenu()}
			className='right-drawer-system'
			BackdropProps={{
				timeout: 500,
				style: { backdropFilter: 'blur(3px)', backgroundColor: `${isFirefox ? 'rgba(200, 200, 200, 0.5)' : 'rgba(200, 200, 200, 0.1)'}` }
			}}
			PaperProps={{
				style: {
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
		>
			<Grid item xs={12} container style={{ maxHeight: '2.6em', marginTop: '18px' }}>
				<IconButton id='btn-toggle-load-filter' color='primary' onClick={toggleLoadMenu}>
					<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
				</IconButton>
			</Grid>
			<div style={{ width: '30em' }}>
				<Grid
					container
					style={{
						marginTop: '0.1em',
						paddingBottom: '1em',
						paddingLeft: '1em',
						paddingRight: '1em',
						maxHeight: '84vh',
						overflow: 'auto'
					}}
				>
					{data.length > 0 &&
						data.map((filter) => (
							<Filter
								key={filter?.id}
								filter={filter}
								disabled={submitting}
								loadSavedFilter={loadSavedFilter}
								toggleLoadMenu={toggleLoadMenu}
								removeFilter={removeFilter}
							/>
						))}
					{data.length === 0 && (
						<Grid
							item
							xs={12}
							style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Poppins', fontSize: '24px', color: '#d6d6d6' }}
						>
							salve um filtro para ver aqui
						</Grid>
					)}
				</Grid>
			</div>
		</Drawer>
	)
}

export default LoadFilter
