import React, { useState, useEffect, memo } from 'react'
import { Button, Grid, Dialog, TextField, IconButton, CardContent, DialogContent, DialogTitle, Card, styled, DialogContentText } from '@mui/material'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { CloseIcon, FolderIcon, AlertIconSimple } from 'utils/SystemIcons'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import constantsVox from 'constants-vox'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const ModalNewFolder = ({ isOpen, toggle, onCreatedFolder, type, canCreate }) => {
	const [folderName, setGroupName] = useState('')
	const [submitting, setSubmitting] = useState(false)

	const registerGroup = () => {
		let isGroupNameCorrect = true
		if (folderName === '') {
			sendMessageToast('Por favor digite um nome válido para a pasta.', constantsVox.TOAST.TYPES.WARNING)
			isGroupNameCorrect = false
		}

		if (isGroupNameCorrect) {
			setSubmitting(true)
			api.post('/folder/create', { folderName, type })
				.then((response) => {
					onCreatedFolder()
					toggle()
					if (response?.data?.toast) {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
					}
				})
				.catch((error) => {
					if (verifyServerResponseCanShowToast(error)) {
						sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
					}
				})
				.finally(() => {
					setSubmitting(false)
				})
		}
	}

	useEffect(() => {
		setGroupName('')
	}, [isOpen])

	return (
		<Dialog
			id='dialog-app'
			open={isOpen}
			onClose={toggle}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
			fullWidth={true}
			maxWidth='sm'
		>
			<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
				<DialogTitle>
					<div className='jc-space-between d-flex ai-center'>
						<div>
							<FolderIcon className='fix-icon-margin-bottom-negative' size={25} color='#1d8cf8' style={{ marginRight: '5px' }} />
							{!canCreate && <AlertIconSimple style={{ marginLeft: '-5px', marginRight: '10px', color: '#1d8cf8' }} />}
							{canCreate && <>Criar nova pasta</>}
							{!canCreate && <>Sem permissão para criar nova pasta</>}
						</div>
						<div>
							<IconButton color='primary' style={{ outline: 'none' }} onClick={toggle}>
								<CloseIcon color='#1d8cf8' size={20} />
							</IconButton>
						</div>
					</div>
				</DialogTitle>
				{canCreate && (
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12}>
								<CssTextField
									variant='standard'
									label='Nome'
									size='small'
									className='d-flex'
									style={{ color: '#1d8cf8', borderRadius: '4px' }}
									value={folderName}
									onChange={(e) => setGroupName(e.target.value)}
								/>
							</Grid>
						</CardContent>
						<CardContent style={{ borderRadius: '4px' }}>
							<Grid item xs={12} container justifyContent={'center'}>
								<Button
									style={{ borderRadius: '25px', minWidth: '10em', fontFamily: 'Poppins' }}
									variant='outlined'
									color='primary'
									disabled={submitting || folderName == ''}
									onClick={registerGroup}
								>
									Salvar
								</Button>
							</Grid>
						</CardContent>
					</DialogContent>
				)}
				{!canCreate && (
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>contate o administrador</DialogContentText>
					</DialogContent>
				)}
			</Card>
		</Dialog>
	)
}

export default memo(ModalNewFolder)
