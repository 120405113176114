import React, { memo } from 'react'
import Chart from 'react-apexcharts'
import Skeleton from 'react-loading-skeleton'

let buildPieOptions = (colors, labels) => ({
	colors,
	labels,
	legend: {
		position: 'bottom'
	},
	dataLabels: {
		enabled: true
	},
	plotOptions: {
		pie: {
			donut: {
				size: '65%'
			}
		}
	},
	fill: {
		opacity: 0.9
	},
	stroke: {
		colors: ['transparent']
	},
	tooltip: {
		enabled: true
	}
})

const formatSeriesToPie = (series) => {
	let seriesFormatted = []
	if (series?.length > 0) {
		series.forEach((s) => seriesFormatted.push(...s.data))
	}
	return seriesFormatted
}

const getLabelsFromSeries = (series) => series?.map((s) => s.name) || []

const PieChart = ({ series, height, width, colors, labels, isLoading }) => {
	return (
		<>
			{isLoading ? (
				<Skeleton height={height} width={width} />
			) : (
				!series?.every((e) => e?.data[0] === 0) && (
					<Chart
						options={buildPieOptions(colors, labels || getLabelsFromSeries(series))}
						series={formatSeriesToPie(series)}
						width={width}
						height={height}
						type='donut'
					/>
				)
			)}
		</>
	)
}

export default memo(PieChart)
