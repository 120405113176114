import React, { memo } from 'react'
import { connect } from 'react-redux'
import Occurrences from 'components/Occurrences/Occurrences'

const OccurrencesContent = ({ cards, globalFiltersRedux, type }) => {
	return <Occurrences cards={cards} isDialog={false} externalFeed={null} filters={globalFiltersRedux} type={type} />
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps, null)(memo(OccurrencesContent))
