import React, { memo, useState } from 'react'
import { Grid, Button, Card, Dialog, DialogTitle } from '@mui/material'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import api from 'service/service'
import { PauseIcon, PlayIcon } from 'utils/SystemIcons'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'

const isFirefox = typeof InstallTrigger !== 'undefined'

const verifyUserType = (user) => {
	//essa função tá ruim, mas é como tá funcionando hj em dia
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const ActivatePauseAccountsDialog = ({ cardId, operation, close, color, onSuccess, user }) => {
	const [submitting, setSubmitting] = useState(false)
	const activatePauseAccounts = (event) => {
		event.preventDefault()
		if (!operation) {
			return
		}
		const userOrSubUserId = verifyUserType(user)

		setSubmitting(true)
		api.post('/card-group/monitoring/activate-pause-accounts-by-card-group-id', {
			cardId,
			isActivateAll: operation === 'activate',
			userOrSubUserId
		})
			.then((response) => {
				onSuccess()
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				setSubmitting(false)
				close()
			})
	}

	return (
		<Dialog
			id='activate-pause-accounts-dialog'
			open={!!operation}
			onClose={close}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='sm'
		>
			<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
				<DialogTitle>
					<div className='jc-space-between d-flex ai-center'>
						<div style={{ color }}>
							<span style={{ marginRight: '0.3em' }}>
								{operation === 'pause' ? (
									<PauseIcon className='fix-icon-margin-bottom-negative' size={25} color={color} />
								) : (
									<PlayIcon className='fix-icon-margin-bottom-negative' size={25} color={color} />
								)}
							</span>
							Deseja realmente {operation === 'pause' ? 'pausar' : 'ativar'} todas as contas deste cartão?
						</div>
					</div>
				</DialogTitle>

				<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='space-between'>
					<Button onClick={close} style={{ borderRadius: '25px' }}>
						CANCELAR
					</Button>
					<Button disabled={submitting} style={{ color, borderRadius: '25px' }} onClick={activatePauseAccounts}>
						{operation === 'pause' ? 'PAUSAR' : 'ATIVAR'}
					</Button>
				</Grid>
			</Card>
		</Dialog>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(ActivatePauseAccountsDialog))
