import React, { useState, memo, useEffect } from 'react'
import Chart from 'react-apexcharts'

const blue = 'rgb(29, 138, 248)'
const purple = 'rgb(172, 96, 247)'
const red = 'rgb(255, 43, 107)'
const lightgrey = 'rgb(220, 220, 220)'

const barsDataProps = {
	options: {
		chart: {
			type: 'bar',
			toolbar: {
				show: false
			},
			stacked: true,
			stackType: '100%'
		},
		labels: [''],
		plotOptions: {
			plotOptions: {
				bar: {
					horizontal: true
				}
			}
		}
	},
	series: [{ data: [0] }]
}

const MiniCardSentimentComp = ({ data }) => {
	const [barsData, setBarsData] = useState(barsDataProps)

	useEffect(() => {
		load()
	}, [data])

	const load = () => {
		let categories = []
		let seriesData = []

		let positives = []
		let negatives = []
		let neutral = []
		// let bots = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index]
			categories.push(element?.name || '')
			seriesData.push(element?.selectedInterval?.metrics?.post_count || 0)
			positives.push(element?.selectedInterval?.metrics?.post_count_good || 0)
			negatives.push(element?.selectedInterval?.metrics?.post_count_bad || 0)
			neutral.push(element?.selectedInterval?.metrics?.post_count_neutral || 0)
			// bots.push(element?.selectedInterval?.metrics?.posts_count_likely_bot || 0)
		}

		const series = [
			{
				name: 'Positivos',
				data: positives
			},

			{
				name: 'Neutros',
				data: neutral
			},
			{
				name: 'Negativos',
				data: negatives
			}
		]

		const optionsBars = {
			colors: [blue, purple, red, lightgrey],
			chart: {
				type: 'bar',
				toolbar: {
					show: false
				},
				stacked: true,
				stackType: '100%'
			},
			plotOptions: {
				bar: {
					horizontal: true,
					borderRadius: 5,
					borderRadiusApplication: 'around', // "around" / "end"
					borderRadiusWhenStacked: 'all' // "all"/"last"
				}
			},
			dataLabels: {
				enabled: true,
				offsetX: 0,
				textAnchor: 'middle',
				style: {
					fontSize: '13px',
					fontFamily: 'Roboto',
					colors: ['#fff']
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: 'transparent',
					opacity: 0.15
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 0,
					blur: 1,
					color: '#000',
					opacity: 0.45
				}
			},
			xaxis: {
				categories,
				labels: {
					show: false
				},
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: data?.length > 1 ? true : false,
					align: 'right',
					minWidth: 0,
					maxWidth: 160,
					style: {
						colors: [],
						fontSize: '11px',
						fontFamily: 'Poppins',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-label'
					}
				}
			},
			legend: {
				enabled: true,
				markers: {
					radius: 12
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},

			fill: {
				opacity: 1
			},
			grid: {
				show: false
			}
		}

		const apexData = {
			options: optionsBars,
			series
		}

		setBarsData(apexData)
	}

	return (
		<Chart
			style={{
				color: 'black',
				textAlign: 'left',
				fontFamily: 'Poppins'
			}}
			options={barsData.options}
			series={barsData.series}
			type='bar'
			width={'100%'}
			height='70%'
		/>
	)
}

export default memo(MiniCardSentimentComp)
