import React, { memo } from 'react'
import { Button, Card, Dialog, DialogTitle, Grid } from '@mui/material'
import { UnlinkIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const ConfirmDisconnect = ({ isOpen, onClose, onConfirm }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='sm'
		>
			<Card style={{ margin: '20px', background: 'transparent' }} elevation={0}>
				<DialogTitle>
					<div style={{ color: '#ff2b6b' }}>
						<UnlinkIcon className='fix-icon-margin-bottom-negative' size={25} color='#ff2b6b' style={{ marginRight: '0.5em' }} />
						Deseja realmente desconectar a conta?
					</div>
				</DialogTitle>

				<Grid item xs={12} style={{ marginTop: '30px' }} container justifyContent='space-between'>
					<Button onClick={onClose} style={{ borderRadius: '25px' }}>
						CANCELAR
					</Button>
					<Button style={{ color: '#ff2b6b', borderRadius: '25px' }} onClick={onConfirm}>
						Desconectar
					</Button>
				</Grid>
			</Card>
		</Dialog>
	)
}

export default memo(ConfirmDisconnect)
