import React, { useState, useEffect } from 'react'
import { Card } from '@mui/material'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = '#d6d6d6'

const OccurrencesEmptyFeed = ({ isEndMessage = false, selectedPostCommentsNumber, isWidget = false }) => {
	const [cardTitle, setCardTitle] = useState('')
	const [cardColor, setCardColor] = useState(blue)

	useEffect(() => {
		defineFeedMessage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const defineFeedMessage = () => {
		if (selectedPostCommentsNumber > 0) {
			return setCardTitle('Estamos capturando os comentários deste post, isso pode demorar alguns minutos')
		} else {
			setCardColor(grey)
			setCardTitle('Sem mais ocorrências para esse intervalo')
		}
	}

	return (
		<Card
			elevation={0}
			style={{
				borderRadius: '25px',
				marginBottom: isWidget ? '4em' : '0.5em',
				marginLeft: isWidget ? '25px' : '0px',
				marginRight: isWidget ? '25px' : '0px'
			}}
		>
			<h3>
				<div
					style={{
						color: cardColor,
						marginTop: isEndMessage ? '30px' : '150px',
						marginBottom: isEndMessage ? '30px' : '150px',
						fontWeight: '500',
						fontSize: '24px',
						textAlign: 'center',
						float: 'center',
						padding: '1em',
						fontFamily: 'Poppins'
					}}
				>
					{cardTitle}
				</div>
			</h3>
		</Card>
	)
}

export default OccurrencesEmptyFeed
