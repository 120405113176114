import React from 'react'
import { FiMaximize, FiMinimize } from 'react-icons/fi'
import { Tooltip, IconButton } from '@mui/material'

const FullScreenButton = ({ fullScreenState, handle }) => {
	return (
		<>
			{fullScreenState && (
				<div style={{ float: 'right', marginTop: '2px', marginRight: '10px' }}>
					<Tooltip title='Tela cheia' placement='left'>
						<IconButton color='primary' onClick={handle.enter} style={{ outline: 'none' }}>
							<FiMaximize
								size={25}
								style={{
									position: 'relative',
									transform: 'translate(0px, px)',
									color: '#1d8cf8'
								}}
							/>
						</IconButton>
					</Tooltip>
				</div>
			)}

			{!fullScreenState && (
				<div style={{ float: 'right', marginRight: '10px' }}>
					<Tooltip title='Sair da tela cheia' placement='left'>
						<IconButton color='primary' onClick={handle.exit} style={{ outline: 'none' }}>
							<FiMinimize
								size={25}
								style={{
									position: 'relative',
									transform: 'translate(0.3px, -1.5px)',
									color: '#1d8cf8'
								}}
							/>
						</IconButton>
					</Tooltip>
				</div>
			)}
		</>
	)
}

export default FullScreenButton
