import React from 'react'
import { Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'

const LightTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: 'transparent',
		font: 'Poppins',
		fontSize: 12,
		width: '80%'
	}
}))(Tooltip)

const TooltipCustom = ({ text, children, color, placement = 'bottom' }) => (
	<LightTooltip title={<div style={{ color, marginTop: '-20px' }}>{text}</div>} placement={placement}>
		{children}
	</LightTooltip>
)

export default TooltipCustom
