import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid } from '@mui/material'

const GraphNetworkSkeletonLoad = () => {
	const height = window.innerHeight - 350

	return (
		<div style={{ marginBottom: '2em', padding: '2em' }}>
			<Grid container>
				<Grid item xs={12}>
					<Skeleton height={height} style={{ borderRadius: '20px' }} />
				</Grid>
			</Grid>
		</div>
	)
}

export default GraphNetworkSkeletonLoad
