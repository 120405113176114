import React, { useState, memo, useEffect } from 'react'
import { Grid, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { HistoryIcon, RefreshIconMd } from 'utils/SystemIcons'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import { orderKeys } from 'utils/generalFunctions'
import TopicAnalysisViewer from './components/TopicAnalysisViewer/TopicAnalysisViewer'
import TopicAnalysisRequest from 'components/TopicAnalysis/components/TopicAnalysisRequest/TopicAnalysisRequest'
import TopicList from './components/TopicList/TopicList'
import VolumetricCurve from './components/VolumetricCurve/VolumetricCurve'
import aiIcon from 'assets/img/whiteIcon.png'
import ServiceHistory from 'components/ServiceHistory/ServiceHistory'
import TopicInsight from './components/TopicInsight/TopicInsight'
import RestoreTopics from './components/RestoreTopics/RestoreTopics'

const useStyles = makeStyles({
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const serviceName = 'topicAnalysis'

const TopicAnalysis = ({ cards, isFetchingData, globalFiltersTermRedux }) => {
	const [thisFilters, setThisFilters] = useState(globalFiltersTermRedux)
	const [openRequest, setOpenRequest] = useState(true)
	const [openHistory, setOpenHistory] = useState(false)
	const [analysisId, setAnalysisId] = useState(null)
	const [status, setStatus] = useState('PROCESSING')
	const classes = useStyles()
	const cardType = cards[0]?.type

	useEffect(() => {
		reset()
	}, [globalFiltersTermRedux])

	const getBody = () => ({
		configGlobalFilters: { cardIds: [cards[0].id], sources: thisFilters.sources, cardType: cardType.toUpperCase() },
		componentProperties: { filtersBase64: Buffer.from(JSON.stringify(orderKeys(thisFilters))).toString('base64'), analysisId }
	})

	let { data, isFetched, refetch } = useUpdateData({
		url: '/topic-analysis/get-analysis',
		updateItemName: 'voxradarAiTopics',
		refetchInterval: 5000,
		freshDataTime: 5000,
		isEnabled: isFetchingData && status !== 'SUCCESS',
		queryName: 'voxradar_ai_topics',
		method: 'post',
		body: getBody()
	})

	const reset = () => {
		setThisFilters(globalFiltersTermRedux)
		setStatus('PROCESSING')
		setAnalysisId(null)
	}

	const applyHistory = (history) => {
		setAnalysisId(history.id)
		setThisFilters(history.filters)
		setStatus('PROCESSING')
		refetch()
	}

	const SwitchOpenRequest = () => {
		setOpenRequest(!openRequest)
	}

	const onClickHistoryButton = () => {
		setOpenHistory(!openHistory)
	}

	useEffect(() => {
		if (data?.topics) {
			setStatus(data?.topics[0]?.status)
			setOpenRequest(data?.topics[0]?.status === 'PROCESSING')
		}
	}, [data])

	const update = () => {
		refetch()
	}

	const changeStatus = () => {
		setStatus('PROCESSING')
	}

	return (
		<>
			<Grid container item xs={12}>
				<div style={{ height: '100%', width: '100%', borderRadius: '20px', position: 'relative' }}>
					<ServiceHistory
						style={{ width: '60%', height: '100%' }}
						showFilterBar={true}
						cardId={cards[0].id}
						isOpen={openHistory}
						toggleOpenHistory={onClickHistoryButton}
						cardType={cardType}
						serviceName={serviceName}
						cardName={cards[0]?.name}
						applyHistory={applyHistory}
					/>
					<Grid container>
						{globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('news') ? (
							<Grid container direction='row' justifyContent='center' alignItems='center' style={{ height: '450px' }}>
								<h2 id='span-location-map-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
									Componente não disponível para a fonte escolhida (Notícias)
								</h2>
							</Grid>
						) : (
							<>
								{data?.topics && status !== 'ERROR' && (
									<TopicAnalysisRequest
										cards={cards}
										filters={globalFiltersTermRedux}
										open={openRequest}
										SwitchOpenRequest={SwitchOpenRequest}
										status={status}
										changeStatus={changeStatus}
									/>
								)}
								{['SUCCESS', 'PROCESSING', undefined].includes(status) ? (
									data?.graphData?.series?.length > 0 ? (
										<TopicAnalysisViewer data={data.graphData} isFetched={isFetched} source={thisFilters.sources} />
									) : (
										<VolumetricCurve cards={cards} filters={globalFiltersTermRedux} />
									)
								) : (
									<Grid container direction='row' justifyContent='center' alignItems='center' style={{ height: '450px' }}>
										<h2 id='span-location-map-error' style={{ fontFamily: 'Poppins', color: '#b0b0b0', fontWeight: 'normal' }}>
											{status === 'INSUFFICIENT_DATA'
												? 'Sem dados suficientes para criar a análise'
												: 'Não foi possível fazer a análise, caso queira refazê-la, vá no histórico'}
										</h2>
									</Grid>
								)}
							</>
						)}
					</Grid>
					{!(globalFiltersTermRedux.sources.length === 1 && globalFiltersTermRedux.sources.includes('news')) && (
						<Grid container style={{ marginLeft: '2em', marginBottom: '1em' }}>
							{!(data?.graphData?.series?.length > 0) && cards.length === 1 && (
								<Button
									id='btn-open-ai'
									disableElevation
									disabled={openRequest || !['SUCCESS', 'PROCESSING', undefined].includes(status) || isFetched === false}
									variant='outlined'
									onClick={SwitchOpenRequest}
									style={{
										outline: 'none',
										maxHeight: '36.5px',
										whiteSpace: 'nowrap',
										marginRight: '2em'
									}}
									className={classes.mainButton}
								>
									<img src={aiIcon} style={{ width: '26px', marginRight: '6px' }} />
									Obter Tópicos
								</Button>
							)}

							{status === 'SUCCESS' && <TopicInsight dataTopics={data} cards={cards} />}
							<Button
								id='btn-open-topics-history'
								onClick={onClickHistoryButton}
								variant='outlined'
								style={{
									fontFamily: 'Poppins',
									borderRadius: '100px',
									fontSize: '14px',
									borderColor: 'rgba(29, 138, 248, 0.3)',
									maxHeight: '36.5px',
									marginRight: '2em'
								}}
							>
								<HistoryIcon size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
								Histórico
							</Button>

							{status === 'SUCCESS' &&
								globalFiltersTermRedux.fromDate !== thisFilters.fromDate &&
								globalFiltersTermRedux.toDate !== thisFilters.toDate && (
									<Button
										id='btn-reset-topics'
										onClick={() => reset()}
										variant='outlined'
										style={{
											fontFamily: 'Poppins',
											borderRadius: '100px',
											fontSize: '14px',
											borderColor: 'rgba(29, 138, 248, 0.3)',
											maxHeight: '36.5px',
											marginRight: '2em'
										}}
									>
										<RefreshIconMd size={21} style={{ color: '#1d8cf8', cursor: 'pointer', marginRight: '5px' }} />
										Intervalo Atual
									</Button>
								)}

							{status === 'SUCCESS' && (
								<RestoreTopics cardTermId={cards[0].id} thisFilters={thisFilters} update={update} isFetchingData={isFetchingData} />
							)}
						</Grid>
					)}
				</div>
			</Grid>
			<TopicList data={data} source={thisFilters.sources} filters={thisFilters} cards={cards} cardType={cardType} update={update} />
		</>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(TopicAnalysis))
