import React, { useEffect, useState, useRef } from 'react'
import { Card, CardContent, Grid, Tooltip, Button, Dialog } from '@mui/material'
import {
	LikeIcon,
	ExternalLinkIcon,
	UserIcon,
	UsersIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	TwitterIcon,
	NewsIcon,
	MetaAdsIcon
} from 'utils/SystemIcons'
import moment from 'moment'
import constants from 'constants-vox'
import EmptyImageFeed from 'assets/img/twitterEmpty.jpg'
import InstagramIconImage from 'assets/img/instagram-icon.png'
import TiktokIconImage from 'assets/img/tiktok-icon.png'
import YoutubeIconImage from 'assets/img/youtube-icon.png'
import FacebookIconImage from 'assets/img/facebook-icon.png'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(184, 184, 184, 100%)'
const purple = 'rgba(172, 96, 247, 100%)'
const red = 'rgba(255, 43, 107, 100%)'
const black = 'rgba(77, 77, 77, 100%)'

const iconSize = 14
const tooltipDelay = 200

const CommentMetrics = ({ cardData, source, cardColor }) => {
	return (
		<>
			{source === 'twitter' && (
				<Grid container justifyContent='flex-end'>
					{!cardData?._source.is_retweet && (
						<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Seguidores' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
									<UsersIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{cardData?._source?.comment?.followers_count}
								</div>
							</Tooltip>
						</Grid>
					)}
					<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
						<Tooltip title='Seguindo' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
							<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
								<UserIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
								{cardData?._source?.comment?.following_count}
							</div>
						</Tooltip>
					</Grid>
				</Grid>
			)}
			{(source === 'facebook' || source === 'instagram' || source === 'tiktok' || source === 'youtube') && (
				<Grid container justifyContent='flex-end'>
					{!cardData?._source.is_retweet && (
						<Grid item xs={3} style={{ textAlign: 'right', color: cardColor, fontSize: '14px', wordSpacing: '4px' }}>
							<Tooltip title='Curtidas' placement='top-end' enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
								<div style={{ cursor: 'pointer', width: 'fit-content', margin: '0 0 auto auto' }}>
									<LikeIcon size={iconSize} style={{ color: cardColor }} className='fix-icon-margin-bottom-negative-1' />{' '}
									{cardData?._source?.comment?.like_count}
								</div>
							</Tooltip>
						</Grid>
					)}
				</Grid>
			)}
		</>
	)
}

const truncateWithElipsis = (str, no_words) => {
	if (str && str.length > no_words) {
		let cutted = str.slice(0, no_words)
		let cleaned = cutted
		if (cutted.slice(cutted.length - 3) === '...') {
			cleaned = cleaned.slice(0, cleaned.length - 3)
		}
		return cleaned + ` ${cleaned?.length >= no_words ? ' ...' : ''}`
	}

	return str
}

const CommentCard = ({ cardData, source }) => {
	const [imageUrl, setImageUrl] = useState(null)
	const [text, setText] = useState('')
	const [highlight, setHighlight] = useState(null)
	const [name, setName] = useState('')
	const [url, setUrl] = useState('')
	const [postImageUrl, setPostImageUrl] = useState(null)
	const [openDialogImage, setOpenDialogImage] = useState(false)
	const [cardColor, setCardColor] = useState(grey)
	const isMounted = useRef(true)

	const handleOnCloseImage = () => {
		setOpenDialogImage(!openDialogImage)
	}

	useEffect(() => {
		isMounted.current = true
		defineCardColor()
		defineCardText()
		defineAvatarImage()
		defineName()
		defineExternalLink()
		definePostImageUrl()
		return () => (isMounted.current = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const defineCardColor = () => {
		let color = grey
		switch (cardData?._source?.general_automatic_classification?.sentiment?.classification) {
			case 'good':
				color = blue
				break
			case 'bad':
				color = red
				break
			case 'neutral':
				color = purple
				break
			case 'irrelevant':
				color = black
				break
			default:
				break
		}

		setCardColor(color)
	}

	const defineSentimentText = () => {
		let text = ''
		switch (cardData?._source?.general_automatic_classification?.sentiment?.classification) {
			case 'good':
				text = 'positivo'
				break
			case 'bad':
				text = 'negativo'
				break
			case 'neutral':
				text = 'neutro'
				break
			case 'irrelevant':
				text = 'irrelevante'
				break
			default:
				break
		}

		return text
	}

	const defineAvatarImage = () => {
		if (source === 'twitter') {
			if (isMounted.current) {
				setImageUrl(checkEmptyImage(cardData?._source?.comment?.profile_image_url))
			}
		} else if (source === 'facebook') {
			if (isMounted.current) {
				setImageUrl(cardData?._source?.page?.profile_image_url)
			}
		} else if (source === 'instagram') {
			if (isMounted.current) {
				setImageUrl(cardData?._source?.page?.profile_image_url)
			}
		} else if (source === 'tiktok') {
			if (isMounted.current) {
				setImageUrl(cardData?._source?.page?.profile_image_url)
			}
		} else if (source === 'youtube') {
			if (isMounted.current) {
				setImageUrl(cardData?._source?.page?.profile_image_url)
			}
		} else {
			if (isMounted.current && imageUrl !== null) {
				setImageUrl(null)
			}
		}
	}

	const defineName = () => {
		if (source === 'twitter') {
			if (isMounted.current) {
				setName(cardData?._source?.comment?.name)
			}
		} else if (source === 'facebook') {
			if (isMounted.current) {
				setName(cardData?._source?.comment?.username)
			}
		} else if (source === 'instagram') {
			if (isMounted.current) {
				setName(cardData?._source?.comment?.username)
			}
		} else if (source === 'tiktok') {
			if (isMounted.current) {
				setName(cardData?._source?.comment?.username)
			}
		} else if (source === 'youtube') {
			if (isMounted.current) {
				setName(cardData?._source?.comment?.username)
			}
		}
	}

	const defineExternalLink = () => {
		if (source === 'twitter') {
			setUrl(`https://twitter.com/${cardData?._source?.comment?.comment_id}/statuses/${cardData?._source?.comment?.comment_id}`)
		} else if (source === 'facebook') {
			setUrl(cardData?._source?.post?.url)
		} else if (source === 'instagram') {
			setUrl(cardData?._source?.post?.url)
		} else if (source === 'tiktok') {
			setUrl(cardData?._source?.post?.url)
		} else if (source === 'youtube') {
			setUrl(cardData?._source?.post?.url)
		}
	}

	const defineImageSourceImage = () => {
		if (source === 'twitter') {
			return imageUrl || EmptyImageFeed
		} else if (source === 'facebook') {
			return FacebookIconImage
		} else if (source === 'instagram') {
			return InstagramIconImage
		} else if (source === 'tiktok') {
			return TiktokIconImage
		} else if (source === 'youtube') {
			return YoutubeIconImage
		} else {
			return EmptyImageFeed
		}
	}

	const definePostImageUrl = () => {
		if (source === 'facebook') {
			setPostImageUrl(cardData?._source?.comment?.attachment?.media?.image?.src || null)
		}
	}

	const checkEmptyImage = (url) => {
		if (url === '' || !url) {
			return null
		} else {
			return url
		}
	}

	const displayCardText = () => {
		if (highlight) {
			return <div dangerouslySetInnerHTML={{ __html: highlight }}></div>
		} else {
			return text
		}
	}

	const defineCardText = () => {
		if (source) {
			let text = truncateWithElipsis(cardData?._source?.comment?.comment, 300)
			if (cardData?.highlight) {
				setHighlight(cardData?.highlight['comment.comment'][0])
			}
			setText(text)
		}
	}

	const defineIcon = (color) => {
		if (source === 'twitter') {
			return <TwitterIcon color={color} size={16} />
		} else if (source === 'news') {
			return <NewsIcon color={color} size={16} />
		} else if (source === 'facebook') {
			return <FacebookIcon color={color} size={16} />
		} else if (source === 'instagram') {
			return <InstagramIcon color={color} size={16} />
		} else if (source === 'tiktok') {
			return <TiktokIcon color={color} size={16} />
		} else if (source === 'metaAds') {
			return <MetaAdsIcon color={color} size={16} />
		}
	}

	return (
		<div
			style={{
				marginBottom: '25px'
			}}
		>
			<Card elevation={0} style={{ borderRadius: '20px' }}>
				<CardContent style={{ padding: '2em' }}>
					<Grid container>
						<Grid item xs={12} style={{ display: 'flex' }}>
							<img
								style={{ width: '50px', height: '50px', borderRadius: '50px', border: `solid 2px ${cardColor}` }}
								src={defineImageSourceImage(source)}
								onError={() => setImageUrl(null)}
								alt='...'
							/>
							<div
								style={{
									fontFamily: 'Poppins',
									color: cardColor,
									margin: '10px',
									fontWeight: '600',
									fontSize: '16px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									width: '100%'
								}}
							>
								{name}
							</div>
							<div
								style={{
									marginLeft: '2em',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									color: cardColor,
									fontFamily: 'Poppins',
									fontWeight: '600'
								}}
							>
								{defineIcon(cardColor)}
								<div style={{ marginLeft: '0.5em', textTransform: 'capitalize' }}>{source}</div>
								{cardData?._source?.general_automatic_classification?.sentiment?.classification && (
									<div style={{ marginLeft: '2em' }}>{defineSentimentText()}</div>
								)}
							</div>
						</Grid>
						<Grid
							item
							xs={10}
							style={{
								marginTop: '3em',
								fontFamily: 'Roboto',
								fontWeight: '100',
								color: '#9A9A9A',
								fontSize: '15px',
								textAlign: 'left'
							}}
						>
							{displayCardText()}
						</Grid>
						{postImageUrl && (
							<Grid item xs={2} container justifyContent='flex-end'>
								<Button
									onClick={() => {
										setOpenDialogImage(!openDialogImage)
									}}
									style={{ borderRadius: '20px' }}
								>
									<img
										onError={() => setPostImageUrl(null)}
										style={{
											width: '100px',
											height: '100px',
											objectFit: 'cover',
											borderRadius: '20px',
											marginBlock: 'auto'
										}}
										src={postImageUrl}
									/>
								</Button>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							style={{
								marginTop: '1em',
								fontFamily: 'Roboto',
								fontWeight: '100',
								color: '#9A9A9A',
								fontSize: '11px',
								textAlign: 'left',
								wordSpacing: '4px'
							}}
						>
							{moment(cardData?._source?.comment?.posted_at, constants.JAVASCRIPT.FORMAT_DATETIMEZONE_DEFAULT_SYSTEM).format(
								'DD/MM/YYYY HH:mm:ss'
							)}
						</Grid>
						<Grid item xs={12}>
							<CommentMetrics cardData={cardData} source={source} cardColor={cardColor} />
						</Grid>
						{source === 'twitter' && (
							<Grid item xs={12} style={{ marginTop: '1em' }}>
								<a
									href={url || ''}
									target='_blank'
									rel='noopener noreferrer'
									style={{ textDecoration: 'none', outlineColor: 'none' }}
								>
									<Button
										id='btn-occurrences-open'
										size='small'
										style={{
											borderRadius: '100px',
											fontFamily: 'Poppins',
											fontSize: '12px',
											color: cardColor
										}}
									>
										<ExternalLinkIcon size={13} style={{ color: cardColor, marginRight: '5px' }} />
										Abrir original
									</Button>
								</a>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
			<Dialog
				style={{ margin: 'auto' }}
				id='dialog-app'
				open={openDialogImage}
				onClose={handleOnCloseImage}
				BackdropProps={{ style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' } }}
				PaperProps={{
					style: {
						borderRadius: '20px',
						backdropFilter: 'blur(10px)',
						backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
						border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
						boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
					}
				}}
			>
				<img
					style={{
						display: 'block',
						width: 'auto',
						height: 'auto'
					}}
					src={postImageUrl}
				/>
			</Dialog>
		</div>
	)
}

export default CommentCard
