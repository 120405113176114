import api from 'service/service'
import { useQuery, useQueryClient } from 'react-query'

let searchParameters = async (updateItemNameRequest, method, urlRequest, body) => {
	updateItemNameRequest //só pro eslint parar de acusar que não está sendo usado, deixei como exemplo de usar algum parametro de fora
	let { data } = await api[method](urlRequest, body)
	return data
}

const useUpdateData = ({
	url,
	updateItemName,
	initialGraphData,
	refetchInterval = 600000,
	freshDataTime = 600000,
	isEnabled = true,
	queryName = 'useUpdateData',
	method = 'get',
	body = {}
}) => {
	//600000 = 10min
	return useQuery([queryName, url, updateItemName, body], () => searchParameters(updateItemName, method, url, body), {
		//podia ir direto a variavel la pra cima, mas deixei de exemplo de como passar variavel pra função searchParameters
		initialData: initialGraphData || [],
		staleTime: freshDataTime,
		refetchInterval,
		initialDataUpdatedAt: Date.now() - freshDataTime,
		keepPreviousData: true,
		enabled: isEnabled
	})
}

export { useUpdateData, useQueryClient }
