import React, { memo, useRef } from 'react'
import { connect } from 'react-redux'
import { Grid, Card } from '@mui/material'
import Occurrences from 'components/Occurrences/Occurrences'
import GraphTitle from 'components/GraphTitle/GraphTitle'
import { ListIcon } from 'utils/SystemIcons'
import LoadingBar from 'components/LoadingBar/LoadingBar'

const TopComments = ({ cards, globalFiltersRedux, type = 'term' }) => {
	const loadingBarRef = useRef()

	return (
		<Grid item xs={6}>
			<Card style={{ height: '100%', borderRadius: '20px', background: 'transparent' }}>
				<Grid container spacing={3} style={{ background: '#fbfbfb', height: '70px', borderBottom: '1px solid #f5f5f5' }}>
					<Grid item xs={12}>
						<GraphTitle
							id='span-sentiments-nowcasting'
							title='Top 10 Postagens'
							icon={<ListIcon size={25} style={{ color: '#1d8cf8' }} />}
						/>
					</Grid>
				</Grid>
				<div>
					<LoadingBar ref={loadingBarRef} />
				</div>
				<Occurrences
					cards={cards}
					isDialog={true}
					externalFeed={null}
					filters={globalFiltersRedux}
					type={type}
					isTopPosts={true}
					pageSize={10}
					orderExternal={{ date: null, relevance: 'desc' }}
					isWidget={true}
				/>
			</Card>
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersRedux: store.global.filters
})

export default connect(mapStateToProps, null)(memo(TopComments))
