import { Box, Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import ImageAnnotationListLogos from './components/ImageAnnotationListLogos'
import ImageAnnotationListProjects from './components/ImageAnnotationListProjects'

const useStyles = makeStyles({
	tab: {
		minWidth: 160,
		width: 160,
		height: 60,
		minHeight: 60,
		color: 'rgba(184, 184, 184, 100%)'
	}
})

const ImageAnnotation = () => {
	const [tabValue, setTabValue] = useState('list-logos')
	const classes = useStyles()

	const handleChange = (_, newValue) => {
		setTabValue(newValue)
	}

	return (
		<Box style={{ width: '100%', maxWidth: 800, margin: '0 auto', padding: '16px', backgroundColor: '#f7f7f7' }}>
			<Tabs value={tabValue} onChange={handleChange} TabIndicatorProps={{ style: { background: 'rgba(29, 138, 248, 100%)' } }}>
				<Tab value='list-logos' style={{ outline: 'none' }} className={classes.tab} label={'Lista de Logos'} />
				<Tab value='list-projects' style={{ outline: 'none' }} className={classes.tab} label={'Lista de Projetos'} />
			</Tabs>
			<Box style={{ marginTop: '16px' }}>
				{tabValue === 'list-logos' && <ImageAnnotationListLogos />}
				{tabValue === 'list-projects' && <ImageAnnotationListProjects />}
			</Box>
		</Box>
	)
}

export default ImageAnnotation
