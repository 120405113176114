import React from 'react'

const blue = 'rgba(29, 138, 248, 100%)'
const white = '#ffffff'

const CardTagLabel = ({ tag }) => {
	let splitTag = [tag]

	if (tag.includes('_')) {
		splitTag = tag.split('_')
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					backgroundColor: blue
				}}
			>
				{splitTag[0]}
			</div>
			{splitTag.length > 1 && (
				<div
					style={{
						marginLeft: '8px',
						paddingLeft: '8px',
						paddingRight: '8px',
						backgroundColor: white,
						borderColor: blue,
						color: blue,
						border: '1px solid white',
						borderRadius: '25px'
					}}
				>
					{splitTag[1]}
				</div>
			)}
		</div>
	)
}

export default CardTagLabel
