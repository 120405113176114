import constants from '../../constants-vox'
import moment from 'moment'
let pt = require('apexcharts/dist/locales/pt.json')

export const graphOptionsSensLine = (onIntervalChange, maxY, minY, maxX, minX, maxYGraph, minYGraph, graphType, isReport, graphInterval, filters) => {
	let utc = graphInterval === 'days' ? true : false
	let tooltipFormat = graphInterval === 'hour' ? 'dd MMM HH:mm' : 'dd MMM'

	let options = {
		chart: {
			events: {
				zoomed: (_, { xaxis }) => {
					onIntervalChange(xaxis, filters, graphInterval)
				}
			},
			foreColor: '#525f7f',
			type: 'line',
			height: 350,
			locales: [pt],
			defaultLocale: 'pt',
			animations: {
				enabled: true
			},
			selection: {
				enabled: true,
				fill: {
					color: '#1d8cf8',
					opacity: 0.1
				},
				stroke: {
					width: 1,
					dashArray: 3,
					color: '#1d8cf8',
					opacity: 0.4
				}
			},
			zoom: {
				enabled: true,
				type: 'x'
			},
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: !isReport,
					selection: false,
					zoom: !isReport,
					zoomin: !isReport,
					zoomout: !isReport,
					pan: false,
					reset: !isReport | '<img src="/static/icons/reset.png" width="20">',
					customIcons: []
				},
				autoSelected: 'zoom',
				export: {
					csv: {
						filename: 'voxradar_export',
						columnDelimiter: ',',
						dateFormatter(timestamp) {
							return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
						}
					}
				}
			}
		},
		grid: {
			show: true,
			borderColor: 'rgba(82, 95, 127, 0.3)',
			position: 'back',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'round'
			// width: 4
		},
		markers: {
			size: 3,
			strokeColors: 'rgba(255, 255, 255, 0)',
			hover: {
				size: 9
			}
		},
		tooltip: {
			marker: {
				show: true
			},
			x: {
				show: true,
				format: tooltipFormat,
				formatter: undefined
			}
		},
		theme: {
			palette: 'palette1'
		},
		xaxis: {
			max: maxX,
			min: minX,
			type: 'datetime',
			axisBorder: {
				show: true,
				color: 'rgba(82, 95, 127, 0.3)',
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: 0
			},
			labels: {
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				datetimeUTC: utc
			}
		},
		yaxis: {
			max: maxY,
			min: minY,
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: 0,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => Math.trunc(value)
			}
		}
	}

	//caso precise no futuro para comparação
	// if (!isReport) {
	// 	options.chart.id = `sensitivity-by-time-${cardId}`
	// 	options.chart.group = 'sensitivity-by-time'
	// }

	if (graphType === 'default') {
		options.colors = ['#008FFB', '#FF4560']

		options.legend = {
			show: true,
			fontSize: isReport ? '15px' : '12px',
			showForSingleSeries: true,
			markers: {
				fillColors: ['#008FFB', '#FF4560']
			}
		}

		options.fill = {
			opacity: 1,
			type: 'solid',
			gradient: {}
		}
	} else {
		let isInflection = maxYGraph >= 0 && minYGraph <= 0
		let colorStops = []

		if (maxYGraph > 0) {
			colorStops.push(
				{
					offset: 0,
					color: '#1bcafa',
					opacity: 1
				},
				{
					offset: isInflection ? (100 * maxYGraph) / (maxYGraph - minYGraph) - 5 : 100,
					color: '#1d8cf8',
					opacity: 1
				}
			)
		}

		if (isInflection) {
			colorStops.push({
				offset: (100 * maxYGraph) / (maxYGraph - minYGraph),
				color: '#ac60f7',
				opacity: 1
			})
		}
		if (minYGraph < 0) {
			colorStops.push(
				{
					offset: isInflection ? (100 * maxYGraph) / (maxYGraph - minYGraph) + 5 : 0,
					color: '#ff2b8e',
					opacity: 1
				},
				{
					offset: 100,
					color: '#ff2b4e',
					opacity: 1
				}
			)
		}

		options.legend = {
			show: false,
			showForSingleSeries: false
		}

		options.fill = {
			opacity: 0.9,
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: 'vertical',
				shadeIntensity: 1,
				gradientToColors: ['#ff2b6b'],
				opacityFrom: 1,
				opacityTo: 1,
				colorStops
			}
		}
		options.colors = ['rgba(255,255,255, 0.5)', 'transparent', 'transparent', 'transparent', 'transparent']
	}

	return options
}
