import React, { useEffect, memo, useRef, useState } from 'react'
import { Grid, TextField, Checkbox, Autocomplete, styled, Paper, createFilterOptions } from '@mui/material'
import { SearchIcon, LoadingIcon } from 'utils/SystemIcons'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import GroupSpecificGlobalFilters from 'components/GlobalFilters/components/GroupSpecificGlobalFilters/GroupSpecificGlobalFilters'
import api from 'service/service'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const filterOptions = createFilterOptions({
	matchFrom: 'any',
	limit: 200
})

const icon = <CheckBoxOutlineBlankIcon fontSize='small' color='primary' />
const checkedIcon = <CheckBoxIcon fontSize='small' color='primary' />

const defaultOptions = []
const selectAll = { id: -1, name: '', value: 'select-all', type: 'selectAll' }

const AccountsNewInsightGroup = ({ formState, fieldErrors, onChangeForm, sourceInfo }) => {
	const [typingText, setTypingText] = useState('')
	const [initialValue, setInitialValue] = useState([])
	const [tagsFilterOpen, setTagsFilterOpen] = useState(false)
	const [tags, setTags] = useState([])
	const [loading, setLoading] = useState(true)
	const [initialOptions, setInitialOptions] = useState(defaultOptions)
	const [options, setOptions] = useState(defaultOptions)
	const [accounts, setAccounts] = useState([])
	const [allSelected, setAllSelected] = useState(false)

	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	const load = async () => {
		setLoading(true)

		const response = await api.get('/social-profile/get-all-by-user-id')
		const data = response?.data?.items
		if (isMounted.current) {
			let sortedData = data
				?.filter((p) => p.type === 'profile' || p.type === 'selectAll')
				.sort(function (a, b) {
					return a.name === b.name ? 0 : a.name < b.name ? -1 : 1
				})

			let items = [selectAll, ...sortedData]

			setInitialOptions(items)

			setInitialValue(data?.filter((p) => p.type === 'profile' && sourceInfo?.accounts.find((it) => it.id === p.id)))
		}

		setLoading(false)
	}

	useEffect(() => {
		if (initialOptions !== options) {
			setOptions(initialOptions)
		}
	}, [initialOptions])

	useEffect(() => {
		if (initialOptions.length > 0) {
			if (tags.length > 0) {
				let filtered = [...initialOptions]
				setOptions(filtered.filter((p) => tags.some((r) => p.cardTags?.value.includes(r)) || p.type === 'selectAll'))
			} else {
				setOptions(initialOptions)
			}
		}
	}, [tags])

	useEffect(() => {
		onChangeForm({
			accounts: initialValue
		})
	}, [initialValue])

	useEffect(() => {
		setAccounts(formState?.accounts)
	}, [formState?.accounts])

	useEffect(() => {
		if (accounts.length === 0) {
			setAllSelected(false)
		}
	}, [accounts])

	const handleChange = (newValue) => {
		if (!(newValue[0]?.type === 'selectAll' && newValue?.length === 1)) {
			onChangeForm({
				accounts: newValue.length === 0 ? [] : newValue.filter((it) => it.type !== 'selectAll')
			})
		}
	}

	const onTagsChange = (newTags) => {
		setTags(newTags)
	}

	const handleSelectAll = () => {
		setAllSelected(true)
		let filteredAccounts = accounts.filter((it) => it.type !== 'selectAll')
		let filteredOptions = options.filter((it) => it.type !== 'selectAll')

		handleChange([...filteredAccounts, ...filteredOptions])
	}

	const optionRenderer = (props, option, { selected }) => {
		if (option.type === 'selectAll') {
			if (!allSelected && tags.length > 0 && options.length > 1 && accounts.length === 0) {
				return (
					<li {...props} key={option.id} onClick={() => handleSelectAll()} style={{ width: '100%', height: '100%' }}>
						<div style={{ fontFamily: 'Poppins', marginLeft: '0.7em', color: '#1d8cf8' }}>Selecionar Tudo</div>
					</li>
				)
			}
		} else {
			return (
				<li {...props} key={option.id} style={{ fontFamily: 'Poppins' }}>
					<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
					{option.name}
				</li>
			)
		}
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<span className='fs-1-5' style={{ color: '#d41993' }}>
					Contas a serem adicionadas no grupo
				</span>
			</Grid>
			<Grid item xs={12}>
				<GroupSpecificGlobalFilters
					tags={tags}
					onTagsGroupChange={onTagsChange}
					toggleGroupFilter={() => setTagsFilterOpen(!tagsFilterOpen)}
					openGroupFilter={tagsFilterOpen}
					isCreateGroup={true}
				/>
			</Grid>

			<Grid item xs={12} style={{ marginBottom: '20px', marginTop: '20px' }}>
				<Autocomplete
					disabled={loading}
					multiple
					disableCloseOnSelect
					autoComplete
					autoHighlight
					PaperComponent={CustomPaper}
					options={options}
					value={accounts}
					filterOptions={filterOptions}
					noOptionsText='Não encontrado'
					inputValue={typingText}
					onInputChange={(_, value) => setTypingText(value)}
					onChange={(_, value) => handleChange(value)}
					getOptionLabel={(option) => option?.name}
					getOptionDisabled={(option) =>
						(option.type === 'selectAll' && allSelected) ||
						(option.type === 'selectAll' && tags.length < 1) ||
						(option.type === 'selectAll' && options.length < 2) ||
						(option.type === 'selectAll' && accounts.length !== 0)
					}
					ListboxProps={{
						style: {
							maxHeight: '210px'
						}
					}}
					renderInput={(params) => (
						<CssTextField
							variant='standard'
							fullWidth
							label={
								<div style={{ display: 'flex' }}>
									{loading ? (
										<LoadingIcon
											size={20}
											thickness={1}
											style={{
												color: '#d41993',
												transform: 'translate(0px, 2px)',
												marginRight: '7px'
											}}
										/>
									) : (
										<SearchIcon
											size={20}
											style={{
												color: '#d41993',
												transform: 'translate(0px, 2px)',
												marginRight: '7px'
											}}
										/>
									)}
									{typingText && typingText !== '' ? 'Pressione Enter para adicionar a conta' : 'Buscar contas'}
								</div>
							}
							error={!!fieldErrors?.accounts?.error}
							helperText={fieldErrors?.accounts?.error}
							{...params}
						/>
					)}
					renderOption={optionRenderer}
				/>
			</Grid>
		</Grid>
	)
}

export default memo(AccountsNewInsightGroup)
