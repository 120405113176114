import React, { useEffect, memo, useRef } from 'react'
import { Grid, TextField, styled } from '@mui/material'
import { AtSignIcon } from 'utils/SystemIcons'

const blue = 'rgba(29, 138, 248, 100%)'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const AccountNewInsightReport = ({ formState, fieldErrors, onChangeForm, statusType, card }) => {
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		if (statusType === 'edit') {
			onChangeForm({ twitterAccount: card?.account })
		}
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<span className='fs-1-5' style={{ color: '#18c1c4' }}>
					Em relação a conta :
				</span>
			</Grid>
			<Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }}>
				<CssTextField
					variant='standard'
					label={
						<div style={{ display: 'flex' }}>
							<AtSignIcon size={20} style={{ color: '#18c1c4', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
							Conta a ser analisada
						</div>
					}
					error={!!fieldErrors?.twitterAccount?.error}
					helperText={fieldErrors?.twitterAccount?.error}
					size='small'
					className='d-flex'
					style={{ color: '#18c1c4', borderRadius: '4px' }}
					value={formState.twitterAccount}
					placeholder='@josesilva'
					onChange={(e) => onChangeForm({ twitterAccount: e.target.value })}
				/>
			</Grid>
		</Grid>
	)
}

export default memo(AccountNewInsightReport)
