import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useUpdateData } from 'hooks/ReactQueryHooks'

const getProductToUpdate = () => {
	if (window.location.pathname.includes('/monitoring')) {
		return 'social_listening'
	} else if (window.location.pathname.includes('/account-management')) {
		return 'account_manager'
	} else {
		return null
	}
}

const LastLoginUserUpdater = () => {
	const [productName, setProductName] = useState(getProductToUpdate())
	const location = useLocation()

	useEffect(() => {
		setProductName(getProductToUpdate())
	}, [location.pathname])

	useUpdateData({
		url: '/user/update-last-login',
		updateItemName: 'UserLastLogin',
		refetchInterval: 3600000,
		freshDataTime: 3600000,
		isEnabled: !!productName,
		queryName: 'updateLastLogin',
		method: 'post',
		body: {
			product: productName
		}
	})
}

export default LastLoginUserUpdater
