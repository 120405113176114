import React, { useState, useEffect, useRef } from 'react'
import { Grid, TextField, Chip, Autocomplete, Paper, styled } from '@mui/material'
import { GroupIcon, LoadingIcon } from 'utils/SystemIcons'
import api from 'service/service'
import './GroupsNewInsightProfile.css'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import ButtonModalNewInsight from 'pages/Monitoring/components/ButtonModalNewInsight/ButtonModalNewInsight'
import { connect } from 'react-redux'
let lastOnDeleteTime = null
const cardTypeGroups = 'group'

const isFirefox = typeof InstallTrigger !== 'undefined'

const white = '#ffffff'
const blue = '#1d8cf8'
const purple = '#ac60f7'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const CustomPaper = (props) => {
	return (
		<Paper
			{...props}
			sx={{
				minWidth: '15em',
				borderRadius: '20px',
				backdropFilter: 'blur(10px)',
				backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
				border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
				boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
			}}
		/>
	)
}

const GroupsNewInsightProfile = ({ formState, onChangeForm, fieldErrors, user, isOptional, getLoadedGroups, showNew = true }) => {
	const [typingText, setTypingText] = useState('')
	const [groups, setGroups] = useState([])
	const [autocompleteGroups, setAutocompleteGroups] = useState([])
	const [loading, setLoading] = useState(true)
	const isMounted = useRef(true)

	const permissionCanCreate = !user.subUser || (user.subUser && user.subUser.permissionCreate)

	useEffect(() => {
		isMounted.current = true
		load()
		return () => {
			isMounted.current = false
		}
	}, [])

	useEffect(() => {
		if (!loading) {
			const filterGroups = autocompleteGroups.filter((ag) => formState?.groups?.find((fgv) => fgv === ag.key))
			setGroups(filterGroups)
		}
	}, [formState?.groups, autocompleteGroups, loading])

	useEffect(() => {
		if (getLoadedGroups) {
			getLoadedGroups({ groups: autocompleteGroups, loading })
		}
	}, [autocompleteGroups, loading])

	const onChangeText = (text) => {
		const dateDiff = new Date().getTime() - lastOnDeleteTime
		if (dateDiff > 100) {
			setTypingText(text)
		}
	}

	const load = async () => {
		setLoading(true)

		await api
			.get(`/monitoring/card/get-all-by-user-id/${cardTypeGroups}?folderId=ALL_ID`)
			.then((response) => {
				if (isMounted.current) {
					setAutocompleteGroups(response.data?.items.map((it) => ({ key: it.id, value: it.name })) || [])
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
			})
	}

	return (
		<Grid container spacing={3}>
			{isOptional && (
				<Grid item xs={12} className='mb-1i mt-1i'>
					<span className='fs-1-5' style={{ color: purple }}>
						Grupos para esse monitoramento (Opcional)
					</span>
				</Grid>
			)}
			<Grid item xs={12} style={{ width: '100%' }}>
				<Autocomplete
					disabled={loading}
					className='groups-profile'
					multiple
					freeSolo
					PaperComponent={CustomPaper}
					getOptionLabel={(opt) => opt.value}
					disableClearable
					inputValue={typingText}
					value={groups}
					options={autocompleteGroups}
					onInputChange={(_, value) => onChangeText(value)}
					onChange={(_, value) =>
						onChangeForm({
							groups: value.map((v) => v.key)
						})
					}
					style={{ width: '100%' }}
					renderOption={(props, option) => {
						return (
							<li {...props} key={props.id} style={{ fontFamily: 'Poppins' }}>
								{option.value}
							</li>
						)
					}}
					renderInput={(params) => (
						<div style={{ position: 'relative' }}>
							<CssTextField
								variant='standard'
								label={
									<div style={{ display: 'flex' }}>
										{loading ? (
											<LoadingIcon
												size={20}
												thickness={1}
												style={{ color: '#ac60f7', transform: 'translate(0px, 2px)', marginRight: '7px' }}
											/>
										) : (
											<GroupIcon size={20} style={{ color: '#ac60f7', transform: 'translate(0px, 2px)', marginRight: '7px' }} />
										)}
										Grupos
									</div>
								}
								error={!!fieldErrors?.groups?.error}
								helperText={fieldErrors?.groups?.error}
								{...params}
							/>
						</div>
					)}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								style={{ color: white, backgroundColor: purple, borderColor: purple }}
								key={index}
								variant='outlined'
								label={option.value}
								{...getTagProps({ index })}
								onDelete={(e) => {
									const props = getTagProps({ index })
									props.onDelete(e)
									lastOnDeleteTime = new Date().getTime()
								}}
							/>
						))
					}
				/>
				{showNew && (
					<div style={{ marginTop: '2em' }}>
						<ButtonModalNewInsight type='group' canCreate={permissionCanCreate} onSubmit={load} createByOnlyGroupName />
					</div>
				)}

				<div style={{ height: '1em', marginTop: '0.3em', color: blue }}>
					{typingText && typingText !== '' && 'Pressione Enter para adicionar o grupo'}
				</div>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = ({ store: { user } }) => ({
	user
})

export default connect(mapStateToProps)(GroupsNewInsightProfile)
