import { FACEBOOK_APP_ID } from 'base'

/* eslint-disable no-undef */
const initFacebookSdk = () => {
	return new Promise((resolve, reject) => {
		if (!FACEBOOK_APP_ID) {
			reject(new Error('Facebook App ID not found'))
			return
		}

		window.fbAsyncInit = function () {
			FB.init({
				appId: FACEBOOK_APP_ID,
				cookie: true,
				xfbml: true,
				version: 'v19.0'
			})

			resolve()
		}
		;(function (d, s, id) {
			let js
			const fjs = d.getElementsByTagName(s)[0]
			if (d.getElementById(id)) {
				return
			}
			js = d.createElement(s)
			js.id = id
			js.src = 'https://connect.facebook.net/en_US/sdk.js'
			fjs.parentNode.insertBefore(js, fjs)
		})(document, 'script', 'facebook-jssdk')
	})
}

export default initFacebookSdk
