import React, { useState, useEffect, memo } from 'react'
import { Grid, Button, Divider, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { BsDot } from 'react-icons/bs'

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

const checkOrderChevron = (type) => {
	switch (type) {
		case 'asc':
			return <FiChevronUp size={15} style={{ color: blue, marginRight: '5px' }} />
		case 'desc':
			return <FiChevronDown size={15} style={{ color: blue, marginRight: '5px' }} />
		default:
			return <BsDot size={15} style={{ color: grey, marginRight: '5px' }} />
	}
}
const FilterMenu = ({ HandleClickMore, onFilterMenuChange, openMenu, orderFilters, isAccountManagerTwitter = false }) => {
	const classes = useStyles()
	const [filters, setFilters] = useState({
		dateOrder: orderFilters?.date,
		relevanceOrder: orderFilters?.relevance
	})

	useEffect(() => {
		let newFilters = {
			dateOrder: orderFilters?.date,
			relevanceOrder: orderFilters?.relevance
		}
		if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
			setFilters(newFilters)
		}
	}, [orderFilters])

	useEffect(() => {
		reset()
	}, [openMenu])

	const reset = () => {
		setFilters({
			dateOrder: orderFilters?.date,
			relevanceOrder: orderFilters?.relevance
		})
	}

	const handleDateOrderChange = () => {
		switch (filters.dateOrder) {
			case null:
				setFilters({ ...filters, dateOrder: 'asc' })
				break
			case 'asc':
				setFilters({ ...filters, dateOrder: 'desc' })
				break
			case 'desc':
				setFilters({ ...filters, dateOrder: null })
				break
			default:
				setFilters({ ...filters, dateOrder: orderFilters?.order?.date })
				break
		}
	}

	const handleRelevancyOrderChange = () => {
		switch (filters.relevanceOrder) {
			case null:
				setFilters({ ...filters, relevanceOrder: 'asc' })
				break
			case 'asc':
				setFilters({ ...filters, relevanceOrder: 'desc' })
				break
			case 'desc':
				setFilters({ ...filters, relevanceOrder: null })
				break
			default:
				setFilters({ ...filters, relevanceOrder: orderFilters?.relevance })
				break
		}
	}

	const handleApply = () => {
		onFilterMenuChange({ date: filters.dateOrder, relevance: filters.relevanceOrder })
		HandleClickMore()
	}

	return (
		<Grid container spacing={0}>
			<Grid item xs={12} style={{ margin: '0px' }}>
				<h4 style={{ fontFamily: 'Poppins', textAlign: 'center', marginTop: '30px', letterSpacing: '4px' }}>ORDENAR POR:</h4>
				<div style={{ marginTop: '-10px', display: 'flex', justifyContent: 'center' }}>
					{!isAccountManagerTwitter && (
						<Button
							style={{ outline: 'none', height: '4em', width: '13em', borderRadius: '0%' }}
							className={classes.tab}
							onClick={handleRelevancyOrderChange}
						>
							{checkOrderChevron(filters.relevanceOrder)}
							Engajamento
						</Button>
					)}
					{isAccountManagerTwitter && (
						<Tooltip title='Não disponível para contas do Twitter' placement='top'>
							<Button style={{ outline: 'none', height: '4em', width: '13em', borderRadius: '0%' }} className={classes.tab}>
								{checkOrderChevron(null)}
								Engajamento
							</Button>
						</Tooltip>
					)}
					<Button
						size='large'
						style={{ outline: 'none', height: '4em', width: '13em', borderRadius: '0%' }}
						className={classes.tab}
						onClick={handleDateOrderChange}
					>
						{checkOrderChevron(filters.dateOrder)}
						Data
					</Button>
				</div>
				<Divider />
			</Grid>

			<Grid item xs={12} style={{ margin: '30px' }}>
				<div className={classes.buttonGroup}>
					<Button
						variant='outlined'
						fullWidth={true}
						style={{ outline: 'none', borderRadius: '100px' }}
						className={classes.button}
						onClick={handleApply}
					>
						Aplicar
					</Button>
				</div>
			</Grid>
		</Grid>
	)
}

export default memo(FilterMenu)
