import React, { useState, memo } from 'react'
import { Grid, Button, Collapse, Tooltip } from '@mui/material'
import {
	DeleteBinIcon,
	CheckIcon,
	TwitterIcon,
	FacebookIcon,
	InstagramIcon,
	TiktokIcon,
	YoutubeIcon,
	AllSourcesIcon,
	LoadingIcon,
	AlertExclamationIcon,
	ErrorIcon,
	RefreshIcon
} from 'utils/SystemIcons'
import api from 'service/service'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import { connect } from 'react-redux'
import constantsVox from 'constants-vox'

const topicAnalysisStatus = constantsVox.CARD_TERM_TOPIC_ANALYSIS.STATUS
const serviceName = 'voxIa'

const defineIcon = (sources) => {
	if (sources.length > 1) {
		return <AllSourcesIcon size={16} color='#1d8cf8' style={{ marginTop: '3px' }} />
	} else if (sources.includes('twitter')) {
		return <TwitterIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('facebook')) {
		return <FacebookIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('instagram')) {
		return <InstagramIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('tiktok')) {
		return <TiktokIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	} else if (sources.includes('youtube')) {
		return <YoutubeIcon size={16} style={{ color: '#1d8cf8', marginTop: '4px' }} />
	}
}

const getStatusTextAndIcon = (status, mainText) => {
	let icon = <>?</>
	let text = ''
	if (status === topicAnalysisStatus.SUCCESS) {
		icon = <CheckIcon size={25} color='#1d8cf8' style={{ marginTop: '0.3em' }} />
		text = mainText
	} else if (status === topicAnalysisStatus.PROCESSING) {
		icon = <LoadingIcon size={25} thickness={2} color='#ac60f7' style={{ marginTop: '0.3em' }} />
		text = 'Processando'
	} else if (status === topicAnalysisStatus.INSUFFICIENT_DATA) {
		icon = <AlertExclamationIcon size={25} color='#eb9834' style={{ marginTop: '0.3em' }} />
		text = 'Sem dados suficientes'
	} else if (status === topicAnalysisStatus.INSUFFICIENT_CREDIT) {
		icon = <AlertExclamationIcon size={25} color='#eb9834' style={{ marginTop: '0.3em' }} />
		text = 'Sem créditos suficientes'
	} else if (status === topicAnalysisStatus.ERROR) {
		icon = <ErrorIcon size={25} color='#ff0000' style={{ marginTop: '0.3em' }} />
		text = 'Erro'
	} else {
		text = 'Status não identificado'
	}

	return { icon, text }
}

const TopicAnalysisHistoryItem = ({ item, toggleOpenHistory, remainingCreditsRedux, reload, cardId, applyHistory }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [hideSubmitting, setHideSubmitting] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const canRefreshItem = ![topicAnalysisStatus.SUCCESS, topicAnalysisStatus.PROCESSING, topicAnalysisStatus.INSUFFICIENT_DATA].includes(item.status)

	const loadSavedFilter = () => {
		applyHistory(item)
	}

	const toggleConfirmDelete = () => {
		setTimeout(() => {
			setConfirmDelete(!confirmDelete)
		}, 300)
	}

	const handleConfirmClick = (e, id) => {
		e.preventDefault()
		setTimeout(() => {
			setHideSubmitting(true)
			removeFilter(e, id)
		}, 300)
	}

	const onClickHistoryItem = (item) => {
		if (item.status === topicAnalysisStatus.SUCCESS) {
			loadSavedFilter()
			toggleOpenHistory()
		} else {
			sendMessageToast(
				'Análise não pode ser aberta, ' +
					(item.status === topicAnalysisStatus.PROCESSING ? 'espere até o processamento terminar' : 'gere novamente para visualizar'),
				constantsVox.TOAST.TYPES.WARNING
			)
		}
	}

	const removeFilter = async (event, analysisId) => {
		event.preventDefault()
		api.post('/topic-analysis/remove-analysis', {
			analysisId,
			cardId
		})
			.then((response) => {
				if (response?.data?.toast) {
					sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
				}
			})
			.catch((error) => {
				if (verifyServerResponseCanShowToast(error)) {
					sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
				}
			})
	}

	const handleClickRefreshAnalysis = async (item) => {
		setSubmitting(true)
		try {
			const { data: creditCostAmount } = await api.get(`/credits/get-cost-amount/${serviceName}`)
			const credit = {
				LIGHT: creditCostAmount?.lightCreditCost,
				FULL: creditCostAmount?.fullCreditCost
			}
			if (remainingCreditsRedux < credit[item.version]) {
				sendMessageToast('Não possui créditos suficientes para fazer a análise', constantsVox.TOAST.TYPES.ERROR)
			} else {
				const analysisId = item.id

				api.post('topic-analysis/refresh-analysis', { analysisId })
					.then((response) => {
						sendMessageToast(response?.data?.toast?.message, response?.data?.toast?.type)
						reload(false, true)
					})
					.catch((error) => {
						if (verifyServerResponseCanShowToast(error)) {
							sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
						}
					})
					.finally(() => {
						setSubmitting(false)
					})
			}
		} catch (error) {
			sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
		}
	}

	return (
		<Grid item container>
			<Collapse in={!hideSubmitting} timeout={300} style={{ width: '100%' }}>
				<Grid container justifyContent='flex-start' alignItems='flex-start' style={{ padding: '1em' }}>
					{canRefreshItem && (
						<Grid item xs={1}>
							{submitting ? (
								<Tooltip title='Carregando requisição' placement='bottom-start' enterDelay={500} enterNextDelay={500}>
									<Button
										style={{
											height: '37px',
											backgroundColor: '#fff',
											borderRadius: '25px 0px 0px 25px',
											fontFamily: 'Poppins',
											width: '100%'
										}}
									>
										<LoadingIcon size={16} thickness={2} color='#ac60f7' style={{ marginTop: '0.3em' }} />
									</Button>
								</Tooltip>
							) : (
								<Tooltip title='Refazer análise' placement='bottom-start' enterDelay={500} enterNextDelay={500}>
									<Button
										id={`btn-refresh-item-${item?.title}`}
										disabled={hideSubmitting}
										onClick={() => handleClickRefreshAnalysis(item)}
										style={{
											height: '37px',
											backgroundColor: '#fff',
											borderRadius: '25px 0px 0px 25px',
											fontFamily: 'Poppins',
											width: '100%'
										}}
									>
										<RefreshIcon size={16} style={{ color: '#1d8cf8' }} />
									</Button>
								</Tooltip>
							)}
						</Grid>
					)}
					<Grid item xs={canRefreshItem ? 7 : 8}>
						<Tooltip
							title={getStatusTextAndIcon(item.status, item?.title)?.text}
							placement='bottom-start'
							enterDelay={500}
							enterNextDelay={500}
						>
							<Button
								disabled={hideSubmitting}
								id={`btn-load-item-${item?.title}`}
								onClick={() => onClickHistoryItem(item)}
								style={{
									maxHeight: '37px',
									minHeight: '37px',
									backgroundColor: '#fff',
									borderRadius: canRefreshItem ? '0px' : '25px 0px 0px 25px',
									width: '100%',
									display: 'flex',
									justifyContent: 'flex-start',
									alignContent: 'flex-start'
								}}
							>
								<div
									style={{
										width: '100%',
										color: 'rgba(100,100,100, 0.9)',
										marginLeft: '3px',
										display: 'flex',
										justifyContent: 'flex-start',
										alignContent: 'flex-start'
									}}
								>
									<div style={{ marginRight: '8px', marginTop: '3px' }}>{defineIcon(item?.sources)}</div>
									<div
										style={{
											fontFamily: 'Poppins',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											fontSize: '13px',
											textOverflow: 'ellipsis',
											textAlign: 'left',
											marginTop: '4px'
										}}
									>
										{item?.title}
									</div>
								</div>
								{getStatusTextAndIcon(item?.status)?.icon}
							</Button>
						</Tooltip>
					</Grid>
					<Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						{confirmDelete ? (
							<Collapse in={confirmDelete} timeout={300} style={{ width: '100%' }}>
								<Button
									id={`btn-confirm-delete-item-${item?.title}`}
									disabled={hideSubmitting}
									onClick={(e) => handleConfirmClick(e, item.id)}
									style={{
										color: '#1d8cf8',
										backgroundColor: '#fff',
										borderRadius: '0px',
										fontFamily: 'Poppins',
										width: '50%'
									}}
								>
									Excluir
								</Button>
								<Button
									id={`btn-cancel-delete-item-${item?.title}`}
									disabled={hideSubmitting}
									onClick={toggleConfirmDelete}
									style={{
										color: '#f50057',
										backgroundColor: '#fff',
										borderRadius: '0px 25px 25px 0px',
										fontFamily: 'Poppins',
										width: '50%'
									}}
								>
									Cancelar
								</Button>
							</Collapse>
						) : (
							<Tooltip title={'Excluir análise'} placement='bottom' enterDelay={500} enterNextDelay={500}>
								<Button
									id={`btn-delete-item-${item?.title}`}
									disabled={hideSubmitting}
									onClick={toggleConfirmDelete}
									style={{
										height: '37px',
										color: 'rgba(100,100,100, 0.9)',
										backgroundColor: '#fff',
										borderRadius: '0px 25px 25px 0px',
										fontFamily: 'Poppins',
										width: '100%'
									}}
								>
									<DeleteBinIcon size={20} style={{ color: 'rgba(100,100,100, 0.9)' }} />
								</Button>
							</Tooltip>
						)}
					</Grid>
				</Grid>
			</Collapse>
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	remainingCreditsRedux: store?.user?.creditInfo?.remainingMonth
})

export default connect(mapStateToProps)(memo(TopicAnalysisHistoryItem))
