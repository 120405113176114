import React, { useState, useEffect, memo } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, CardContent, Grid, Button, Collapse, CircularProgress } from '@mui/material'
import { CloseIcon, PlusIcon, NextIcon, BeforeIcon, EditIcon } from 'utils/SystemIcons'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import GeneralNewInsightGroup from './Components/GeneralNewInsightGroup/GeneralNewInsightGroup'
import AccountsNewInsightGroup from './Components/AccountsNewInsightGroup/AccountsNewInsightGroup'
import api from 'service/service'
import constantsVox from 'constants-vox'
import createNewCardFieldValidations from 'utils/field-validations/createNewCardFieldValidations'
import { verifyServerResponseCanShowToast } from 'utils/generalFunctions'
import Stepper from 'components/Stepper/Stepper'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

const initialFormState = {
	cardName: '',
	accounts: []
}

const isFirefox = typeof InstallTrigger !== 'undefined'

const initialErrors = {}

const getStepperData = (createByOnlyGroupName) => {
	let steps = [{ label: '', value: 1 }]
	if (!createByOnlyGroupName) {
		steps.push({ label: '', value: 2 })
	}
	return steps
}

const getIds = (accounts) => {
	return accounts.map((account) => account.id)
}

const verifyUserType = (user) => {
	if (user.proceedAsAdminId) {
		return { user: user.proceedAsAdminId }
	} else if (isEmpty(user.subUser)) {
		return { user: user.id_usuario }
	} else {
		return { subUser: user.subUser.id }
	}
}

const NewMonitoringDialogGroup = ({ onSubmit, close, card = null, statusType = 'create', user, createByOnlyGroupName }) => {
	const [fieldErrors, setFieldErrors] = useState(initialErrors)
	const [submitting, setSubmitting] = useState(false)
	const [formState, setFormState] = useState(initialFormState)
	const [cardInfo, setCardInfo] = useState(null)
	const [sourceInfo, setSourceInfo] = useState(null)
	const [loadingSourceInfo, setLoadingSourceInfo] = useState(false)
	const [step, setStep] = useState(1)

	useEffect(() => {
		setCardInfo(card)
	}, [card])

	useEffect(() => {
		if (statusType === 'edit') {
			setLoadingSourceInfo(true)
			if (cardInfo) {
				api.get(`/monitoring/card/get-info-by-type/${cardInfo.id}/${cardInfo?.type}`)
					.then((response) => {
						setSourceInfo(response.data)
					})
					.finally(() => setLoadingSourceInfo(false))
			}
		}
	}, [cardInfo])

	const nextStep = (newStep, submit = false) => {
		if (newStep < step) {
			let resetStep2 = {
				accounts: []
			}

			let newFormState = {}

			if (newStep === 1) {
				newFormState = { ...formState, ...resetStep2 }
			}

			setFormState(newFormState)
			setStep(newStep)
		} else {
			let errors = { ...fieldErrors, ...createNewCardFieldValidations.verifyCanNextStepGroup(formState, step) }
			setFieldErrors(errors)

			let hasError = Object.values(errors).some((e) => e.error && e.step == step)
			if (!hasError) {
				if ((step === 2 || (step === 1 && createByOnlyGroupName)) && submit) {
					const userOrSubUserId = verifyUserType(user)
					setSubmitting(true)
					let cardName = formState.cardName
					let accounts = getIds(formState.accounts)
					let cardId = card?.id
					api.post('/card-group/monitoring/new-group', { cardName, accounts, statusType, cardId, userOrSubUserId, createByOnlyGroupName })
						.then(() => {
							onSubmit()
							sendMessageToast(
								statusType === 'create'
									? createByOnlyGroupName
										? 'Novo grupo criado'
										: 'Novo cartão adicionado'
									: 'Cartão alterado',
								constantsVox.TOAST.TYPES.SUCCESS
							)
							close(null)
						})
						.catch((error) => {
							setFieldErrors(error.response.data?.fieldErrors)
							if (verifyServerResponseCanShowToast(error)) {
								sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
							}
						})
						.finally(() => {
							setSubmitting(false)
						})
				} else {
					setStep(newStep)
				}
			}
		}
	}

	const onChangeForm = (newValue) => {
		setFormState({ ...formState, ...newValue })
	}

	return (
		<Dialog
			open={true}
			onClose={close}
			PaperProps={{
				style: {
					borderRadius: '20px',
					backdropFilter: 'blur(10px)',
					backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
					border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
					boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
				}
			}}
			BackdropProps={{
				style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
			}}
			fullWidth={true}
			maxWidth='md'
		>
			<DialogTitle>
				<div className='jc-space-between d-flex ai-center'>
					{statusType === 'create' && (
						<div>
							<PlusIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#d41993' }} /> Novo monitoramento de
							grupo
						</div>
					)}
					{statusType === 'edit' && (
						<div>
							<EditIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#d41993' }} /> Editar cartão de grupo
							{loadingSourceInfo && (
								<CircularProgress thickness={2} style={{ marginLeft: '10px', color: '#d41993', width: '20px', height: '20px' }} />
							)}
						</div>
					)}

					<div>
						<IconButton color='primary' style={{ outline: 'none' }} onClick={close}>
							<CloseIcon color='#d41993' size={20} />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<Collapse in={!loadingSourceInfo}>
				{!loadingSourceInfo && (
					<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
						<CardContent>
							<Stepper justifyContent='center' data={getStepperData(createByOnlyGroupName)} value={step} isGroup={true} />
							<Grid container style={{ padding: '1.5em' }}>
								{step === 1 && (
									<GeneralNewInsightGroup
										formState={formState}
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										statusType={statusType}
										card={card}
										sourceInfo={sourceInfo}
									/>
								)}
								{step === 2 && (
									<AccountsNewInsightGroup
										formState={formState}
										onChangeForm={onChangeForm}
										fieldErrors={fieldErrors}
										sourceInfo={sourceInfo}
									/>
								)}
							</Grid>

							<Grid container justifyContent='space-between' style={{ marginTop: '1em' }}>
								<Grid item>
									{step !== 1 && (
										<Button
											style={{ borderRadius: '25px' }}
											disabled={step === 1 || submitting}
											color='primary'
											onClick={() => nextStep(step - 1)}
										>
											<BeforeIcon size={24} /> <span style={{ marginTop: '3px' }}>Anterior</span>
										</Button>
									)}
								</Grid>
								<Grid item className='mt-autoi mb-autoi ml-6i'>
									{step === 2 || (step === 1 && createByOnlyGroupName) ? (
										<Button
											style={{ borderRadius: '25px' }}
											color='primary'
											onClick={() => nextStep(step + 1, true)}
											disabled={submitting}
										>
											{statusType === 'create' ? 'Criar cartão' : 'Salvar mudanças'}
										</Button>
									) : (
										<Button
											style={{ borderRadius: '25px' }}
											color='primary'
											onClick={() => nextStep(step + 1, false)}
											disabled={submitting}
										>
											<span style={{ marginTop: '3px' }}>Próximo</span> <NextIcon size={24} />
										</Button>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</DialogContent>
				)}
			</Collapse>
		</Dialog>
	)
}

const mapStateToProps = ({ store }) => ({
	user: store?.user
})

export default connect(mapStateToProps)(memo(NewMonitoringDialogGroup))
