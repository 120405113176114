import React, { useEffect, useState, memo } from 'react'
import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TypeAnimation } from 'react-type-animation'
import { GridLoader } from 'react-spinners'
import logo from 'assets/img/react-logo-2.png'
import logoWhite from 'assets/img/whiteIcon.png'
import AiProgressBar from '../AiProgressBar/AiProgressBar'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
import api from 'service/service'
import { LoadingIcon } from 'utils/SystemIcons'

import './Css/loader.css'

const blue = 'rgba(29, 138, 248, 100%)'
const serviceName = 'graphInterpretationIa'

const useStyles = makeStyles({
	button: {
		fontFamily: 'Poppins',
		fontWeight: 'bolder',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		height: '100%',
		width: '100%',
		color: blue,
		borderRadius: '20px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const renderButtonContent = (creditsLoading, creditCostAmount, graphLoading) => {
	let componentToRender = <></>

	if (creditsLoading || graphLoading) {
		componentToRender = <LoadingIcon size={50} thickness={1} style={{ color: '#ac60f7' }} alignCenter />
	} else {
		componentToRender = (
			<Grid container justifyContent='center'>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: '10px'
					}}
				>
					<img src={logo} style={{ width: '100px' }} />
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: '16px',
						whiteSpace: 'nowrap'
					}}
				>
					Analisar com IA
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						position: 'absolute',
						marginTop: '14em',
						justifyContent: 'center',
						alignItems: 'center',
						fontFamily: 'Poppins',
						color: '#9e9e9e',
						fontWeight: 'bold',
						fontSize: '10px',
						whiteSpace: 'nowrap'
					}}
				>
					( isso usará {creditCostAmount} crédito )
				</Grid>
			</Grid>
		)
	}

	return componentToRender
}

const AiAnalysisRequest = ({ onClick, loading, status, handleReady, credits, graphLoading }) => {
	const [inProgress, setInProgress] = useState(true)
	const [creditsLoading, setCreditsLoading] = useState(true)
	const [creditCostAmount, setCreditCostAmount] = useState(null)
	const classes = useStyles()

	useEffect(() => {
		load()
	}, [])

	useEffect(() => {
		if (status === 'PROCESSING') {
			setInProgress(true)
		} else if (status === 'SUCCESS') {
			setInProgress(false)
		}
	}, [status])

	const load = () => {
		api.get(`/credits/get-cost-amount/${serviceName}`)
			.then((response) => {
				setCreditCostAmount(response.data)
				setCreditsLoading(false)
			})
			.catch((error) => {
				sendMessageToast(error?.response?.data?.toast?.message, error?.response?.data?.toast?.type)
			})
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '79vh' }}>
			{(status === 'INSUFFICIENT_DATA' || status === 'ERROR') && credits > 0 && (
				<div>
					<div>
						<img src={logo} style={{ width: '100px' }} />
					</div>
					<div>
						<TypeAnimation
							sequence={
								status === 'ERROR'
									? ['Ocorreu um erro no processamento, selecione outro período.']
									: ['Sem dados suficientes para análise, selecione outro período.']
							}
							speed={80}
							wrapper='div'
							cursor={false}
							repeat={1}
							style={{
								display: 'flex',
								justifyContent: 'center',
								fontFamily: 'Poppins',
								fontSize: '20px',
								margin: '0.5em',
								color: '#c2c2c2'
							}}
						/>
					</div>
				</div>
			)}
			{!loading && (status === 'INSUFFICIENT_CREDIT' || credits < 1) && status !== 'SUCCESS' && status !== 'PROCESSING' && (
				<div>
					<div>
						<img src={logo} style={{ width: '100px' }} />
					</div>
					<div>
						<TypeAnimation
							sequence={['Sem créditos suficientes para análise, contate o suporte para obter mais créditos.']}
							speed={80}
							wrapper='div'
							cursor={false}
							repeat={1}
							style={{
								display: 'flex',
								justifyContent: 'center',
								fontFamily: 'Poppins',
								fontSize: '20px',
								margin: '0.5em',
								color: '#c2c2c2'
							}}
						/>
					</div>
				</div>
			)}
			{!loading && !status && credits > 0 && (
				<Button id='btn-ai-analysis' className={classes.button} onClick={onClick} disabled={creditsLoading || graphLoading}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%'
						}}
					>
						{renderButtonContent(creditsLoading, creditCostAmount, graphLoading)}
					</div>
				</Button>
			)}
			{((loading && status !== 'INSUFFICIENT_DATA' && status !== 'ERROR' && status !== 'INSUFFICIENT_CREDIT') ||
				(status && status !== 'INSUFFICIENT_DATA' && status !== 'ERROR' && status !== 'INSUFFICIENT_CREDIT')) && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
						marginTop: '-34px'
					}}
				>
					<div className='orb'></div>
					<div style={{ position: 'absolute' }}>
						<img src={logoWhite} style={{ width: '100px' }} />
					</div>
					<div
						style={{
							position: 'absolute',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '3px'
						}}
					>
						<GridLoader color={'rgba(255,255,255, 0.2)'} size={26} />
					</div>
					<div
						style={{
							height: '1em',
							width: '5em',
							position: 'absolute',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '9em'
						}}
					>
						<AiProgressBar inProgress={inProgress} handleReady={handleReady} />
					</div>
				</div>
			)}
		</div>
	)
}

export default memo(AiAnalysisRequest)
