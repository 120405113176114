import React, { useState, useEffect } from 'react'
import api from 'service/service'
import { Grid, Card, List, ListItem, ListItemText, Divider, Typography, TextField } from '@mui/material'
import moment from 'moment'
import SelectVox from 'components/SelectVox/SelectVox'
import { SearchIcon } from 'utils/SystemIcons'
import TrendsTwitterSkeletonLoad from 'components/SkeletonLoads/TrendingTwitterSkeletonLoad/TrendingTwitterSkeletonLoad'

const Trending = () => {
	const [countries, setCountries] = useState([])
	const [selectedCountry, setSelectedCountry] = useState({ value: '23424768', label: 'Brasil' })
	const [locals, setLocals] = useState([])
	const [trends, setTrends] = useState([])
	const [filteredTrends, setFilteredTrends] = useState([])
	const [loadingCountries, setLoadingCountries] = useState(true)
	const [loadingLocals, setLoadinglocals] = useState(true)
	const [loadingTrends, setLoadingTrends] = useState(null)
	const [filtro, setFiltro] = useState('')
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [woeid, setWoeid] = useState('')

	useEffect(() => {
		loadCountries()
	}, [])

	useEffect(() => {
		if (woeid !== '') {
			loadTrends()
		}
	}, [date, woeid])

	useEffect(() => {
		if (filtro.toString().toLowerCase() !== '') {
			setFilteredTrends(
				trends
					.map((it) => [
						it[0],
						it[1].filter((it2) => it2.trend?.replace(/,/g, ' ').toLowerCase().indexOf(filtro.toString().toLowerCase()) > -1)
					])
					.filter((result) => result[1].length > 0)
			)
		} else {
			setFilteredTrends(trends)
		}
	}, [filtro, trends])

	const loadCountries = () => {
		setLoadingCountries(true)
		api.get('/trends/get-trending-countries').then((res) => {
			const formatedCountries = formatCountries(res?.data)
			setCountries(formatedCountries)
			setLoadingCountries(false)
			setSelectedCountry(formatedCountries?.filter((c) => c.value === '23424768')[0])
			loadLocals()
		})
	}

	const loadLocals = () => {
		setLoadinglocals(true)
		api.get('/trends/get-trending-cities').then((res) => {
			const formatedLocals = formatLocals(res.data)

			let newLocals = []

			newLocals.push({ value: selectedCountry.value, label: 'De todo o país' })
			newLocals.push(...formatedLocals)
			setLocals(newLocals)
			setWoeid(newLocals[0].value)
			setLoadinglocals(false)
		})
	}

	const loadTrends = () => {
		setLoadingTrends(true)
		api.get(`/trends/get-trending/${date}/${woeid}`).then((res) => {
			setTrends(Object.entries(res.data).reverse())
			setFilteredTrends(Object.entries(res.data).reverse())
			setLoadingTrends(false)
		})
	}

	const datesOptions = () => {
		const dates = [
			{
				label: `Hoje - ${moment().format('DD/MM/YYYY')}`,
				value: moment().format('YYYY-MM-DD')
			},
			{
				label: `Ontem - ${moment().subtract(1, 'd').format('DD/MM/YYYY')}`,
				value: moment().subtract(1, 'd').format('YYYY-MM-DD')
			}
		]
		for (let count = 2; count < 7; count++) {
			const momentDay = moment().subtract(count, 'd')
			dates.push({ label: momentDay.format('DD/MM/YYYY'), value: momentDay.format('YYYY-MM-DD') })
		}
		return dates
	}

	const formatCountries = (countries) => {
		return countries.map((country) => ({ value: country?.woeid, label: country?.printable_name_pt }))
	}

	const formatLocals = (locals) => {
		return locals.map((local) => ({ value: local?.woeid, label: local?.city_name }))
	}

	const handleChangeDate = (date) => {
		setDate(date?.value)
	}

	const handleChangeCountry = (country) => {
		if (country) {
			setSelectedCountry(country)
			loadLocals()
		}
	}

	const handleChangeLocal = (city) => {
		if (city) {
			setWoeid(city.value)
		}
	}

	return (
		<Grid
			spacing={3}
			container
			direction='row'
			justifyContent='flex-start'
			alignItems='center'
			style={{ paddingLeft: '1.5em', paddingRight: '1.5em' }}
		>
			<Grid item xs={4}>
				<SelectVox
					isLoading={false}
					label='Data'
					items={datesOptions()}
					onChange={handleChangeDate}
					initialValue={moment().format('YYYY-MM-DD')}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectVox
					isLoading={loadingCountries}
					label='País'
					items={countries}
					onChange={handleChangeCountry}
					initialValue={selectedCountry?.value}
				/>
			</Grid>
			{locals.length > 0 && (
				<Grid item xs={4}>
					<SelectVox
						isLoading={loadingLocals}
						label='Localidade'
						items={locals}
						onChange={handleChangeLocal}
						initialValue={selectedCountry?.value}
					/>
				</Grid>
			)}
			<Grid item xs={12} style={{ marginTop: '-15px' }}>
				<TextField
					variant='standard'
					disabled={trends.length == 0}
					value={filtro}
					onChange={(e) => setFiltro(e.target.value)}
					style={{ width: '247px' }}
					label={
						<div style={{ display: 'flex' }}>
							<SearchIcon /> <div style={{ marginLeft: '7px', marginTop: '1px', fontSize: '14px' }}>Buscar em trends</div>
						</div>
					}
				/>
			</Grid>
			{!loadingTrends && (
				<div style={{ width: '100%', display: 'flex', paddingLeft: '2em', overflow: 'scroll' }}>
					{filteredTrends.map((hour) => (
						<div key={hour[0]}>
							<Card
								style={{
									borderRadius: '20px',
									marginRight: '20px',
									marginTop: '20px',
									marginBottom: '10px',
									height: '700px',
									width: '250px',
									maxHeight: '70vh'
								}}
							>
								<List component='nav'>
									<ListItem style={{ justifyContent: 'center' }}>
										<Typography gutterBottom variant='h5' className='m-0' style={{ color: '#1d8cf8' }}>
											{(moment().format('HH') === hour[0] || moment().format('H') === hour[0]) &&
											date === moment().format('YYYY-MM-DD')
												? 'Agora'
												: `${hour[0]}:00`}
										</Typography>
									</ListItem>
									<Divider />
									<div style={{ overflow: 'auto', maxHeight: '60vh' }}>
										{hour[1]?.map((it) => (
											<a
												key={it.id}
												rel='noopener noreferrer'
												href={`https://twitter.com/search?q=${it.trend}`}
												target='_blank'
												style={{ color: 'inherit', textDecoration: 'inherit' }}
											>
												<ListItem>
													<div>{it?.order}º</div>
													<Divider orientation='vertical' variant='middle' />
													<div>
														<ListItemText primary={it.trend} />
														{it.tweet_volume && (
															<span
																style={{
																	color: '#a1a1a1',
																	fontWeight: 'lighter',
																	fontSize: '13px'
																}}
															>
																{it.tweet_volume} tweets
															</span>
														)}
													</div>
												</ListItem>
												<Divider />
											</a>
										))}
										<div style={{ height: '10vh' }} />
									</div>
								</List>
							</Card>
						</div>
					))}
				</div>
			)}
			{loadingTrends && <TrendsTwitterSkeletonLoad />}
		</Grid>
	)
}

export default Trending
