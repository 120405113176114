import React, { useState } from 'react'
import { Grid, Button, Dialog, DialogContent, Divider } from '@mui/material'
import { TwitterIcon, FacebookIcon, InstagramIcon, ChartIcon, AllSourcesIcon } from 'utils/SystemIcons'
import { makeStyles } from '@mui/styles'
import { TypeAnimation } from 'react-type-animation'
import TopicLineGraph from 'components/TopicAnalysis/components/TopicLineGraph/TopicLineGraph'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'

const useStyles = makeStyles({
	mainButton: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 80%)',
		boxShadow: 'none',
		color: 'white',
		borderRadius: '100px',
		background: 'rgba(29, 138, 248, 80%)',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 50%)',
			backgroundColor: 'rgba(29, 138, 248, 50%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	},
	button: {
		fontFamily: 'Poppins',
		border: '1px solid rgba(29, 138, 248, 25%)',
		boxShadow: 'none',
		color: blue,
		borderRadius: '100px',
		background: 'transparent',
		'&:hover': {
			border: '1px solid rgba(29, 138, 248, 90%)',
			backgroundColor: 'rgba(29, 138, 248, 5%)',
			boxShadow: '0px 0px -10px -17px rgba(0,0,0,255)'
		}
	}
})

const titleStyle = {
	marginLeft: '5px',
	width: '93%',
	textAlign: 'left',
	fontFamily: 'Poppins',
	color: '#1d8cf8',
	fontSize: '20px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}

const defineIcon = (sources) => {
	const iconStyle = { color: '#1d8cf8', marginTop: '7px' }

	if (sources.includes('twitter')) {
		return <TwitterIcon size={16} style={iconStyle} />
	} else if (sources.includes('facebook')) {
		return <FacebookIcon size={16} style={iconStyle} />
	} else if (sources.includes('instagram')) {
		return <InstagramIcon size={16} style={iconStyle} />
	} else {
		return <AllSourcesIcon size={25} color={iconStyle.color} style={{ marginTop: '2px' }} />
	}
}

const ExpandingGraph = ({ source, graphData, title, buttonName, colors }) => {
	const [openDialog, setOpenDialog] = useState(false)

	let data = { options: graphData.options, series: graphData.series }
	data.series.name = title

	const classes = useStyles()

	const handleClose = () => {
		setOpenDialog(false)
	}

	return (
		<>
			<Button
				disableElevation
				variant='outlined'
				onClick={() => setOpenDialog(!openDialog)}
				style={{ outline: 'none', maxHeight: '25px', whiteSpace: 'nowrap' }}
				className={classes.button}
			>
				<div style={{ display: 'flex', marginLeft: '5px', marginRight: '5px' }}>
					<ChartIcon size={20} style={{ color: blue, marginRight: '5px', marginTop: '2px' }} />
					{buttonName || 'Ver gráfico'}
				</div>
			</Button>

			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					fullWidth={true}
					maxWidth={'lg'}
					PaperProps={{
						style: {
							overflowX: 'hidden',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(255, 255, 255, 0.4)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogContent style={{ overflowY: 'hidden', marginBottom: '0.2em', marginTop: '0.2em' }}>
						<>
							<Grid item xs={12} style={{ minHeight: '31px', marginLeft: '20px', paddingRight: '39px' }}>
								{!!title && (
									<div style={{ display: 'flex' }}>
										<div style={{ width: '20px', marginRight: '5px' }}>{defineIcon(source)}</div>
										<TypeAnimation
											sequence={[`${title}`]}
											speed={99}
											wrapper='div'
											cursor={false}
											repeat={1}
											style={titleStyle}
										/>
									</div>
								)}
								<Divider style={{ backgroundColor: 'rgba(0,0,0,0.1)', height: '1px', marginTop: '0em', width: '100%' }} />
							</Grid>
							<TopicLineGraph data={data} height={'400px'} colors={colors} />
						</>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

export default ExpandingGraph
