import React, { memo } from 'react'
import { Grid } from '@mui/material'

import './TiktokContentBodyAccount.css'
import Summary from 'components/Summary/Summary'

const TiktokContentBodyAccount = ({ cards, filters, summaryTiktok }) => {
	return (
		<div className='tiktok-body-container'>
			{summaryTiktok && (
				<Grid container>
					<Grid container item className='break-print'>
						<Grid item xs={12}>
							<Summary cards={cards} filtersReport={{ ...filters, source: 'tiktok' }} isReport />
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

export default memo(TiktokContentBodyAccount)
