import React, { useState } from 'react'
import { menuDefaultNew } from './utils/functionsFinancial'
import SideDrawer from 'components/SideDrawer/SideDrawer'
import { Grid } from '@mui/material'
import MetricsContent from './components/MetricsContent/MetricsContent'
import BacktestingContent from './components/BacktestingContent/BacktestingContent'
import OutliersContent from './components/OutliersContent/OutliersContent'
import SelectVox from 'components/SelectVox/SelectVox'
import moment from 'moment-timezone'

const loadTabsAllFalse = {
	metrics: false,
	backtesting: false,
	outliers: false,
	modelsummary: false
}

const economicVariablesOptions = [
	{
		label: 'Inflação',
		value: 'inflation'
	}
]

const countriesOptions = [
	{
		label: 'Brasil',
		value: 'BR'
	}
]

const timeIntervalOptions = [
	{
		label: '3 meses',
		value: '3m'
	},
	{
		label: '6 meses',
		value: '6m'
	}
]

const initialDateOptions = [
	{
		label: '7 dias átras',
		value: moment(new Date(), 'YYYY-MM-DD').subtract(6, 'days').format('YYYY-MM-DD')
	},
	{
		label: '30 dias átras',
		value: moment(new Date(), 'YYYY-MM-DD').subtract(29, 'days').format('YYYY-MM-DD')
	},
	{
		label: '90 dias átras',
		value: moment(new Date(), 'YYYY-MM-DD').subtract(89, 'days').format('YYYY-MM-DD')
	},
	{
		label: '12 meses átras',
		value: moment(new Date(), 'YYYY-MM-DD').subtract(11, 'months').format('YYYY-MM-DD')
	},
	{
		label: '5 anos átras',
		value: moment(new Date(), 'YYYY-MM-DD').subtract(4, 'years').format('YYYY-MM-DD')
	}
]

const defaultInitialDate = moment(new Date(), 'YYYY-MM-DD').subtract(89, 'days').format('YYYY-MM-DD')

const variant = 'filled'

const Financial = () => {
	const menuState = menuDefaultNew()
	const [tab, setTab] = useState('metrics')
	const [variable, setVariable] = useState('inflation')
	const [country, setCountry] = useState('BR')
	const [timeInterval, setTimeInterval] = useState('3m')
	const [inicialDate, setInicialDate] = useState(defaultInitialDate)
	const [canLoadTab, setCanLoadTab] = useState({ ...loadTabsAllFalse, socialProfile: true })

	const onSelectItem = (item) => {
		switch (item) {
			case 'metrics':
				setCanLoadTab({ ...loadTabsAllFalse, metrics: true })
				break
			case 'backtesting':
				setCanLoadTab({ ...loadTabsAllFalse, backtesting: true })
				break
			case 'outliers':
				setCanLoadTab({ ...loadTabsAllFalse, outliers: true })
				break
			case 'modelsummary':
				setCanLoadTab({ ...loadTabsAllFalse, modelsummary: true })
				break
			default:
				break
		}
		setTab(item)
	}

	const handleChangeVariables = (newVariable) => {
		setVariable(newVariable?.value)
	}

	const handleChangeCoutries = (newCountry) => {
		setCountry(newCountry?.value)
	}

	const handleChangeTimeInterval = (newTimeInterval) => {
		setTimeInterval(newTimeInterval?.value)
	}

	const handleChangeInitialDate = (newDate) => {
		setInicialDate(newDate?.value)
	}

	return (
		<>
			<SideDrawer itemsToRender={menuState} onSelectItem={onSelectItem} selectedValue={tab} />
			<div className='page-content'>
				<Grid container style={{ marginTop: '5px', marginBottom: '0px' }} spacing={2}>
					<Grid item xs={3}>
						<SelectVox
							isLoading={false}
							label='Variável econômica'
							items={economicVariablesOptions}
							onChange={handleChangeVariables}
							initialValue={'inflation'}
							variant={variant}
							isCard={true}
							size='small'
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectVox
							isLoading={false}
							label='País'
							items={countriesOptions}
							onChange={handleChangeCoutries}
							initialValue={'BR'}
							variant={variant}
							isCard={true}
							size='small'
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectVox
							isLoading={false}
							label='Ver a partir de'
							items={initialDateOptions}
							onChange={handleChangeInitialDate}
							initialValue={defaultInitialDate}
							variant={variant}
							isCard={true}
							size='small'
						/>
					</Grid>
					<Grid item xs={3}>
						<SelectVox
							isLoading={false}
							label='Intervalo'
							items={timeIntervalOptions}
							onChange={handleChangeTimeInterval}
							initialValue={'3m'}
							variant={variant}
							isCard={true}
							size='small'
						/>
					</Grid>
				</Grid>
				{canLoadTab.metrics && tab === 'metrics' && (
					<Grid container>
						<MetricsContent
							variable={variable}
							country={country}
							inicialDate={inicialDate}
							timeInterval={timeInterval}
							isFetchingData={tab === 'metrics'}
						/>
					</Grid>
				)}
				{canLoadTab.backtesting && tab === 'backtesting' && (
					<Grid container>
						<BacktestingContent
							variable={variable}
							country={country}
							inicialDate={inicialDate}
							timeInterval={timeInterval}
							isFetchingData={tab === 'backtesting'}
						/>
					</Grid>
				)}
				{canLoadTab.outliers && tab === 'outliers' && (
					<Grid container>
						<OutliersContent
							variable={variable}
							country={country}
							inicialDate={inicialDate}
							timeInterval={timeInterval}
							isFetchingData={tab === 'outliers'}
						/>
					</Grid>
				)}
			</div>
		</>
	)
}

export default Financial
