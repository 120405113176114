import React, { memo, useEffect, useState } from 'react'
import { Grid, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { useUpdateData } from 'hooks/ReactQueryHooks'
import InfluencerImage from './components/InfluencerImage'

const COUNTRY_CODE_DEFAULT = 76

const useStyles = makeStyles(() => ({
	tab: {
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 300,
		color: grey
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		fontFamily: 'Poppins',
		color: grey,
		width: '50%',
		borderColor: grey,
		'&:hover': {
			color: blue,
			borderColor: blue,
			backgroundColor: 'transparent'
		}
	}
}))

const blue = '#1d8af8'
const grey = '#828282'

const sortList = (list, type) => {
	let sortedList = []
	if (type === 'influencers') {
		sortedList = list?.sort((a, b) => b.engagement - a.engagement)
	} else {
		sortedList = list?.sort((a, b) => b.posts - a.posts)
	}
	return sortedList
}

const formatElementsToMap = (list, type) => {
	let swappedList = list
	if (type === 'influencers') {
		swappedList = [list[0], list[2], list[1]]
	}
	return swappedList
}

const RenderList = ({ list, type }) => (
	<Paper style={{ width: '100%' }}>
		<TableContainer style={{ maxHeight: '20em' }}>
			<Table stickyHeader aria-label='sticky table'>
				<TableHead>
					<TableRow style={{ fontSize: '1.1em' }}>
						<TableCell>Nome</TableCell>
						<TableCell>{type === 'cities' ? 'Posts' : 'Engajamento'}</TableCell>
						<TableCell>{type === 'cities' ? 'Engajamento' : 'Posts'}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortList(list?.location, type)?.map((row, idx) => (
						<TableRow hover role='checkbox' tabIndex={-1} key={idx}>
							{formatElementsToMap(Object.values(row), type)?.map((column, idx2) => (
								<InfluencerImage key={`{${idx}${idx2}`} type={type} column={column} idx2={idx2} />
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Paper>
)

let isFirstTime = true

const TopMetricsLocation = ({ globalFiltersTermRedux, card, selectedStates, canLoad, getIsLoading }) => {
	const [value, setValue] = useState('cities')
	const classes = useStyles()

	useEffect(() => {
		//resetando o estado quando muda de source ou de cartçao
		setValue('cities')
	}, [globalFiltersTermRedux.sources, card])

	const onValueChange = (_, newValue) => {
		setValue(newValue)
	}

	const getBodyTopMetrics = () => ({
		configGlobalFilters: {
			cardIds: [card.id],
			cardType: card.type.toUpperCase()
		},
		globalFilters: {
			...globalFiltersTermRedux,
			advancedFilters: {
				...globalFiltersTermRedux.advancedFilters,
				authorLocation: {
					...globalFiltersTermRedux.advancedFilters.authorLocation,
					country: COUNTRY_CODE_DEFAULT,
					state: selectedStates ? selectedStates?.join(',') : ''
				}
			}
		},
		componentProperties: {
			locationTopType: value
		}
	})
	let { data, isFetching, isFetched } = useUpdateData({
		url: '/location/get-location-top',
		updateItemName: 'LocationMap' + card.id,
		initialGraphData: [],
		refetchInterval: 600000,
		freshDataTime: 600000,
		isEnabled: canLoad,
		queryName: 'useUpdateData',
		method: 'post',
		body: getBodyTopMetrics()
	})

	useEffect(() => {
		if (isFetched && isFirstTime) {
			isFirstTime = false
		}
	}, [isFetched])

	useEffect(() => {
		getIsLoading({ value: isFetching, isFirstTime })
	}, [isFetching])

	return (
		<Grid container spacing={0}>
			{(isFetching && isFirstTime) || !canLoad ? (
				<Grid item xs={12}>
					<Skeleton height='382px' width='100%' style={{ borderRadius: '20px' }} />
				</Grid>
			) : (
				<>
					<Grid item xs={12}>
						<Tabs value={value} onChange={onValueChange} TabIndicatorProps={{ style: { background: blue } }}>
							<Tab id='tab-cities' label='Top Cidades' value='cities' style={{ outline: 'none' }} className={classes.tab} />
							<Tab
								id='tab-influencers'
								label='Top Influenciadores'
								value='influencers'
								style={{ outline: 'none' }}
								className={classes.tab}
							/>
						</Tabs>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1em' }}>
						<RenderList list={data[0]} type={value} />
					</Grid>
				</>
			)}
		</Grid>
	)
}

const mapStateToProps = ({ store }) => ({
	globalFiltersTermRedux: store.global.filters
})

export default connect(mapStateToProps)(memo(TopMetricsLocation))
