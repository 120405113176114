import React, { memo } from 'react'
import { Grid } from '@mui/material'
import './NewsContentBodyTerm.css'

import Summary from 'components/Summary/Summary'
import NetworkSummary from 'components/NetworkSummary/NetworkSummary'

const NewsContentBodyTerm = ({ cards, filters, networkNewsAccounts, summaryNews }) => {
	return (
		<div className='news-body-container'>
			{summaryNews && (
				<Grid container>
					<Grid container item className='break-print' xs={12}>
						<Summary cards={cards} isReport={true} filtersReport={{ ...filters, source: 'news' }} />
					</Grid>
				</Grid>
			)}
			{networkNewsAccounts &&
				cards?.map((card) => (
					<Grid container className='break-print' key={card.id}>
						<Grid item xs={12}>
							<NetworkSummary cardsIds={card.id} filters={{ ...filters, source: 'news' }} isReport={true} initialOrder={'posts'} />
						</Grid>
					</Grid>
				))}
		</div>
	)
}

export default memo(NewsContentBodyTerm)
