import React, { memo } from 'react'
import { Grid, Tooltip, IconButton, Divider } from '@mui/material'
import { PlayIcon, PauseIcon, DiscIcon } from 'utils/SystemIcons'

const PausedFilter = ({ isCardSearching, onPausedFilterChange }) => {
	return (
		<Grid item xs={4} container justifyContent='flex-end'>
			<div style={{ display: 'flex', marginTop: '5px', marginRight: '5px' }}>
				<div style={{ marginLeft: '4px', marginRight: '4px' }}>
					<Tooltip title='Ver ativados' enterDelay={500} enterNextDelay={500}>
						<IconButton style={{ color: '#3d8cf8' }} onClick={() => onPausedFilterChange(true)}>
							<PlayIcon size={25} color={isCardSearching === true ? '#3d8cf8' : '#9a9a9a'} />
						</IconButton>
					</Tooltip>
					{isCardSearching === true && <Divider style={{ backgroundColor: '#3d8cf8', height: '1px', width: '100%', marginTop: '4px' }} />}
				</div>
				<div style={{ marginLeft: '4px', marginRight: '4px' }}>
					<Tooltip title='Ver pausados' enterDelay={500} enterNextDelay={500}>
						<IconButton style={{ color: '#3d8cf8' }} onClick={() => onPausedFilterChange(false)}>
							<PauseIcon size={25} color={isCardSearching === false ? '#3d8cf8' : '#9a9a9a'} />
						</IconButton>
					</Tooltip>
					{isCardSearching === false && <Divider style={{ backgroundColor: '#3d8cf8', height: '1px', width: '100%', marginTop: '4px' }} />}
				</div>
				<div style={{ marginLeft: '4px', marginRight: '4px' }}>
					<Tooltip title='Ver todos' enterDelay={500} enterNextDelay={500}>
						<IconButton style={{ color: '#3d8cf8' }} onClick={() => onPausedFilterChange(null)}>
							<DiscIcon size={25} color={isCardSearching === null ? '#3d8cf8' : '#9a9a9a'} />
						</IconButton>
					</Tooltip>
					{isCardSearching === null && <Divider style={{ backgroundColor: '#3d8cf8', height: '1px', width: '100%', marginTop: '4px' }} />}
				</div>
			</div>
		</Grid>
	)
}

export default memo(PausedFilter)
