import React from 'react'
import {
	getEngagementFormatted,
	getCommentSharesFormatted,
	getActivityFormatted,
	getClicksUniqueFormatted,
	getImpressionsViralAndNotUniqueFormatted,
	getImpressionsPaidOrganicUniqueFormatted,
	getImpressionsFanPaidOrganicUniqueFormatted,
	getImpressionsByStoryTypeUniqueFormatted,
	getNegativeFeedBackFormatted
} from '../../../../utils/viewPostMetrics.utils'
import { Grid } from '@mui/material'
import BarMetrics from 'pages/AccountManagement/components/BarMetrics/BarMetrics'
import NumericMetric from 'pages/AccountManagement/components/NumericMetric/NumericMetric'

const FacebookPostTypeMetrics = ({ cardData }) => {
	const engagement = getEngagementFormatted(cardData)
	const commentShare = getCommentSharesFormatted(cardData)
	const negativeFeedBack = getNegativeFeedBackFormatted(cardData)
	const activity = getActivityFormatted(cardData)
	const impressionsByStoryTypeUnique = getImpressionsByStoryTypeUniqueFormatted(cardData)
	const impressionsFanPaidOrganicUnique = getImpressionsFanPaidOrganicUniqueFormatted(cardData)
	const impressionsViralAndNotUnique = getImpressionsViralAndNotUniqueFormatted(cardData)
	const impressionsPaidOrganicUnique = getImpressionsPaidOrganicUniqueFormatted(cardData)
	const clicks = getClicksUniqueFormatted(cardData)
	const descriptions = cardData.descriptions

	return (
		<>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.activity}
						title={descriptions.find((item) => item.key === 'facebook_activity')?.title}
						description={descriptions.find((item) => item.key === 'facebook_activity')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.activity_unique}
						title={descriptions.find((item) => item.key === 'facebook_activity_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_activity_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.clicks_unique}
						title={descriptions.find((item) => item.key === 'facebook_clicks_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_clicks_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={3} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.engaged_fan}
						title={descriptions.find((item) => item.key === 'facebook_engaged_fan')?.title}
						description={descriptions.find((item) => item.key === 'facebook_engaged_fan')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={3} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.engaged_users}
						title={descriptions.find((item) => item.key === 'facebook_engaged_users')?.title}
						description={descriptions.find((item) => item.key === 'facebook_engaged_users')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={3} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions}
						title={descriptions.find((item) => item.key === 'facebook_impressions')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
				<Grid item xs={3} style={{ height: '10em' }}>
					<NumericMetric
						data={cardData?.post?.metrics?.impressions_unique}
						title={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_unique')?.description}
						source='facebook'
						isOverview={true}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={engagement.values}
						categories={engagement.labels}
						title={descriptions.find((item) => item.key === 'facebook_reactions')?.title}
						description={descriptions.find((item) => item.key === 'facebook_reactions')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={commentShare.values}
						categories={commentShare.labels}
						title={descriptions.find((item) => item.key === 'facebook_shares_comments')?.title}
						description={descriptions.find((item) => item.key === 'facebook_shares_comments')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={activity.values}
						categories={activity.labels}
						title='Atividade'
						description='Atividade dos posts por tipo'
						label='Quantidade'
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsViralAndNotUnique.values}
						categories={impressionsViralAndNotUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_viral_nonviral_unique')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_viral_nonviral_unique')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsPaidOrganicUnique.values}
						categories={impressionsPaidOrganicUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_paid_unique_organic')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_paid_unique_organic')?.description}
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsFanPaidOrganicUnique.values}
						categories={impressionsFanPaidOrganicUnique.labels}
						title={descriptions.find((item) => item.key === 'facebook_impressions_fan_unique_and_paid')?.title}
						description={descriptions.find((item) => item.key === 'facebook_impressions_fan_unique_and_paid')?.description}
						label='Quantidade'
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: '1em' }}>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={impressionsByStoryTypeUnique.values}
						categories={impressionsByStoryTypeUnique.labels}
						title='Impressões'
						description='Impressões únicas por tipo'
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={clicks.values}
						categories={clicks.labels}
						title='Clicks no post'
						description='Clicks únicos no post por tipo'
						label='Quantidade'
					/>
				</Grid>
				<Grid item xs={4} style={{ height: '20em' }}>
					<BarMetrics
						style={{ paddingBottom: '2em' }}
						isHorizontal={false}
						values={negativeFeedBack.values}
						categories={negativeFeedBack.labels}
						title='Feedbacks Negativos'
						description='O número de vezes que as pessoas executaram uma ação negativa na sua publicação (por exemplo, a ocultaram).'
						label='Quantidade'
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default FacebookPostTypeMetrics
