import React, { memo, useEffect } from 'react'
import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@mui/material'
import constantsVox from 'constants-vox'

const SearchTypeNewInsightTerm = ({ formState, onChangeForm, fieldErrors, card, statusType }) => {
	const handleChangeSearchType = (event) => {
		let searchType = event.target.value
		onChangeForm({ searchType })
	}

	useEffect(() => {
		if (statusType === 'edit') {
			onChangeForm({ searchType: card.searchType })
		}
	}, [card])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} className='mb-2i mt-1i'>
				<span className='color-blue fs-1-5'>Configuração de busca</span>
			</Grid>
			<Grid item xs={12}>
				<FormControl>
					<RadioGroup
						row
						aria-labelledby='term-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={formState.searchType}
						onChange={handleChangeSearchType}
					>
						<FormControlLabel
							value={constantsVox.CARD.SEARCH_TYPE.TERM}
							control={<Radio />}
							label='Internet'
							disabled={statusType === 'edit'}
						/>
						<FormControlLabel
							value={constantsVox.CARD.SEARCH_TYPE.PROFILE}
							control={<Radio />}
							label='Grupos'
							disabled={statusType === 'edit'}
						/>
					</RadioGroup>
				</FormControl>
				<div style={{ fontSize: '12px', color: '#9a9a9a' }}>
					{formState?.searchType === constantsVox.CARD.SEARCH_TYPE.TERM &&
						'Selecione esta opção para realizar uma busca em toda a internet. Isso inclui redes sociais públicas, portais de notícias e blogs. É ideal para obter uma visão ampla e geral sobre um assunto ou para monitorar tendências amplas.'}
					{formState?.searchType === constantsVox.CARD.SEARCH_TYPE.PROFILE &&
						'Selecione esta opção para realizar uma busca em um grupo específico de contas pré-cadastradas por você. Isso permite um monitoramento mais focado e específico de contas selecionadas, como influenciadores, concorrentes ou parceiros de negócios. É ideal para análises mais detalhadas e direcionadas.'}
				</div>
				<div style={{ padding: '1em' }}>
					<FormHelperText style={{ fontSize: '16px', color: '#d32f2f', fontFamily: 'Poppins' }}>
						{fieldErrors?.searchType?.error}
					</FormHelperText>
				</div>
			</Grid>
		</Grid>
	)
}

export default memo(SearchTypeNewInsightTerm)
