import React, { useEffect, useState } from 'react'
import { PlusIcon, EditIcon, CloseIcon, LoadingIcon, BookmarkIcon, AlertIconSimple, CardIcon } from 'utils/SystemIcons'
import {
	Grid,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Collapse,
	CardContent,
	TextField,
	styled,
	DialogContentText,
	Tooltip
} from '@mui/material'
import api from 'service/service'
import constantsVox from 'constants-vox'
import { sendMessageToast } from 'componentsVox/Toast/Toast'
// import { verifyCanShowMenuItemSocialProfile } from 'utils/generalFunctions'

const isFirefox = typeof InstallTrigger !== 'undefined'

const type = 'account-manager'

const color = '#18c1c4'

const blue = '#1d8af8'

const CssTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		color: blue,
		fontFamily: 'Poppins',
		'& input': {
			textAlign: 'left'
		}
	},
	'& label.Mui-focused': {
		color: blue
	}
})

const getCardTypeName = (cardType) => {
	let name = ''
	switch (cardType) {
		case 'term':
			name = 'Tema'
			break
		case 'profile':
			name = 'Conta'
			break
		case 'group':
			name = 'Grupo'
			break
		case 'report':
			name = 'Relatório'
			break
		case constantsVox.CARD_TYPE.ACCOUNT_MANAGEMENT:
			name = 'Minhas Contas'
			break
		default:
			break
	}
	return name
}

const NewProfileDialogAccountManagement = ({
	isDisabled,
	loadingSourceInfo,
	onCreated,
	onEdited,
	editCard,
	resetEditCard,
	noButton,
	cardType,
	canCreate
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [profileName, setProfileName] = useState('')
	const [thisEditCard, setThisEditCard] = useState(null)
	const [newInfoModal, setNewInfoModal] = useState(false)
	const [error, setError] = useState('')

	useEffect(() => {
		if (editCard) {
			setThisEditCard(editCard)
			setProfileName(editCard?.name)
			setIsOpen(editCard?.isOpen)
		}
	}, [editCard])

	const toggleNewProfileAccountManagement = () => {
		api.get(`/user/user-settings/${type}`)
			.then(() => {
				if (editCard?.isOpen) {
					resetEditCard()
				} else {
					setIsOpen(!isOpen)
				}
			})
			.catch((error) => {
				if (editCard?.isOpen) {
					setIsOpen(!isOpen)
				} else {
					setError(error?.response?.data?.toast?.message)
					setNewInfoModal(true)
				}
			})
			.finally()
	}

	const handleClickSaveOrCreate = () => {
		api.post('/account-management/profile/create-or-edit', { profileId: thisEditCard?.id || null, name: profileName })
			.then(() => {
				if (thisEditCard) {
					onEdited()
				} else {
					onCreated()
					setIsOpen(!isOpen)
				}
			})
			.catch((e) => {
				if (e?.response?.data?.toast) {
					sendMessageToast(e?.response?.data?.toast?.message, e?.response?.data?.toast?.type)
				}
			})
	}

	useEffect(() => {
		if (!isOpen && !editCard) {
			setProfileName('')
		}
	}, [isOpen])

	const handleClose = () => {
		setNewInfoModal(false)
	}

	return (
		<>
			{!noButton && (
				<Button
					className='outline-box-shadow-focus-none button-new-card-sentiment-analysis'
					onClick={toggleNewProfileAccountManagement}
					disabled={isDisabled}
				>
					Novo +
				</Button>
			)}
			{newInfoModal && (
				<Dialog
					open={newInfoModal}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogTitle id='alert-dialog-title'>
						<Grid container direction='row'>
							<Grid item xs={11} container justifyContent='flex-start' alignItems='center'>
								<CardIcon style={{ marginRight: '0px', color }} />
								<AlertIconSimple style={{ marginLeft: '-5px', marginRight: '10px', color }} />
								Limite de cartões de gerenciamento de contas atingido
							</Grid>
							<Grid spacing={1} item xs={1} container direction='row' justifyContent='flex-end' alignItems='center'>
								<Tooltip title='Fechar'>
									<IconButton onClick={() => handleClose()} style={{ marginTop: '-4px', marginRight: '-20px' }}>
										<CloseIcon color={color} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</DialogTitle>

					<DialogContent>
						<DialogContentText id='alert-dialog-description'>{error}</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
			{(canCreate || thisEditCard) && (
				<Dialog
					open={isOpen}
					onClose={toggleNewProfileAccountManagement}
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: { backdropFilter: 'blur(3px)', backgroundColor: 'rgba(220, 220, 220, 0.4)' }
					}}
					fullWidth={true}
					maxWidth='md'
				>
					{
						<DialogTitle>
							<div className='jc-space-between d-flex ai-center'>
								{!thisEditCard && (
									<div>
										<PlusIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#18c1c4' }} /> Novo perfil de{' '}
										{getCardTypeName(cardType)}
									</div>
								)}
								{thisEditCard && (
									<div>
										<EditIcon className='fix-icon-margin-bottom-negative' size={25} style={{ color: '#18c1c4' }} /> Editar perfil
										de {getCardTypeName(cardType)}
										{loadingSourceInfo && (
											<LoadingIcon
												thickness={2}
												style={{ marginLeft: '10px', color: '#18c1c4', width: '20px', height: '20px' }}
											/>
										)}
									</div>
								)}
								<div>
									<IconButton color='primary' style={{ outline: 'none' }} onClick={toggleNewProfileAccountManagement}>
										<CloseIcon color='#18c1c4' size={20} />
									</IconButton>
								</div>
							</div>
						</DialogTitle>
					}
					<Collapse in={!loadingSourceInfo}>
						{!loadingSourceInfo && (
							<DialogContent style={{ padding: '1.5em', paddingTop: 0 }}>
								<CardContent>
									<Grid item xs={12} className='mb-2i mt-1i'>
										<span className='fs-1-5' style={{ color: '#18c1c4' }}>
											Informações do perfil
										</span>
									</Grid>
									<Grid container style={{ padding: '1.5em' }}>
										<CssTextField
											fullWidth
											variant='standard'
											label={
												<div style={{ display: 'flex' }}>
													<BookmarkIcon
														size={20}
														style={{ color: '#18c1c4', transform: 'translate(0px, 2px)', marginRight: '7px' }}
													/>
													Nome do perfil
												</div>
											}
											// error={!!fieldErrors?.cardName?.error}
											// helperText={fieldErrors?.cardName?.error}
											size='small'
											className='d-flex'
											style={{ color: '#18c1c4', borderRadius: '4px' }}
											value={profileName}
											onChange={(e) => setProfileName(e.target.value)}
										/>
									</Grid>
								</CardContent>
							</DialogContent>
						)}
					</Collapse>

					<Button style={{ padding: '1.5em', margin: '0.5em', borderRadius: '100px', color: '#18c1c4' }} onClick={handleClickSaveOrCreate}>
						{thisEditCard ? 'Salvar' : 'Criar'}
					</Button>
				</Dialog>
			)}
			{!canCreate && !thisEditCard && (
				<Dialog
					fullWidth
					open={isOpen}
					onClose={toggleNewProfileAccountManagement}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					PaperProps={{
						style: {
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}
					}}
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(220, 220, 220, 0.4)'
						}
					}}
				>
					<DialogTitle id='alert-dialog-title'>
						<Grid container direction='row'>
							<Grid item xs={11} container justifyContent='flex-start' alignItems='center'>
								<CardIcon style={{ marginRight: '0px', color: '#18c1c4' }} />
								<AlertIconSimple style={{ marginLeft: '-5px', marginRight: '10px', color: '#18c1c4' }} />
								Sem permissão para criar novos cartões
							</Grid>
							<Grid spacing={1} item xs={1} container direction='row' justifyContent='flex-end' alignItems='center'>
								<Tooltip title='Fechar'>
									<IconButton onClick={toggleNewProfileAccountManagement} style={{ marginTop: '-4px', marginRight: '-20px' }}>
										<CloseIcon color={'#18c1c4'} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>contate o administrador</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

export { NewProfileDialogAccountManagement }
