import constants from 'constants-vox'
import moment from 'moment'
let pt = require('apexcharts/dist/locales/pt.json')

export const graphOptionsFinancial = (isReport, title, colors, curve = 'smooth', graphTime) => {
	let utc = graphTime === 'days' ? true : false
	let tooltipFormat = graphTime === 'hour' ? 'dd MMM HH:mm' : 'dd MMM'

	let options = {
		colors: colors,
		chart: {
			foreColor: '#525f7f',
			height: 350,
			locales: [pt],
			defaultLocale: 'pt',
			animations: {
				enabled: false
			},
			selection: {
				enabled: true,
				fill: {
					color: '#1d8cf8',
					opacity: 0.1
				},
				stroke: {
					width: 1,
					dashArray: 3,
					color: '#1d8cf8',
					opacity: 0.4
				}
			},
			zoom: {
				enabled: true,
				type: 'x'
			},
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: !isReport,
					selection: false,
					zoom: !isReport,
					zoomin: !isReport,
					zoomout: !isReport,
					pan: false,
					reset: !isReport | '<img src="/static/icons/reset.png" width="20">',
					customIcons: []
				},
				autoSelected: 'zoom',
				export: {
					csv: {
						filename: 'voxradar_export',
						columnDelimiter: ',',
						dateFormatter(timestamp) {
							return moment(timestamp).format(constants.JAVASCRIPT.FORMAT_DATETIME_DEFAULT_SYSTEM)
						}
					}
				}
			}
		},
		title: {
			text: `${title}`,
			align: 'left',
			margin: 10,
			offsetX: 0,
			offsetY: 0,
			floating: false,
			style: {
				fontSize: '25px',
				fontWeight: 'regular',
				fontFamily: 'Poppins',
				color: '#525f7f'
			}
		},
		grid: {
			show: true,
			borderColor: 'rgba(82, 95, 127, 0.3)',
			position: 'back',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			colors: colors,
			curve: curve
		},
		fill: {
			colors: colors,
			opacity: 1,
			type: 'solid'
		},
		markers: {
			colors: colors,
			size: 0,
			strokeColors: 'rgba(255, 255, 255, 0)',
			hover: {
				size: 9
			}
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			markers: {
				fillColors: colors
			}
		},
		tooltip: {
			shared: true,
			marker: {
				show: true
			},
			x: {
				show: true,
				format: tooltipFormat,
				formatter: undefined
			}
		},
		theme: {
			palette: 'palette1'
		},
		xaxis: {
			type: 'datetime',
			axisBorder: {
				show: true,
				color: 'rgba(82, 95, 127, 0.3)',
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: 0
			},
			labels: {
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				datetimeUTC: utc
			}
		},
		yaxis: {
			labels: {
				show: true,
				align: 'right',
				minWidth: 0,
				maxWidth: 160,
				style: {
					colors: [],
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label'
				},
				offsetX: -10,
				offsetY: 0,
				rotate: 0,
				formatter: (value) => (value !== null ? value : null)
			}
		}
	}

	return options
}
