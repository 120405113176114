import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { MenuItem, Select, FormControl, Collapse, Grid, Typography, styled } from '@mui/material'
import { EmojiIcon, WordsIcon, HashtagIcon, AtSignIcon, UserIcon } from 'utils/SystemIcons'

const isFirefox = typeof InstallTrigger !== 'undefined'

const blue = 'rgba(29, 138, 248, 100%)'
const grey = 'rgba(0, 0, 0, 4%)'
const darkGrey = 'rgba(0, 0, 0, 25%)'

const useStyles = makeStyles(() => ({
	formControl: {
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: blue
		},
		width: '100%'
	},
	select: {
		'&:hover': {
			borderColor: grey
		},
		'&:before': {
			borderColor: grey
		},
		'&:after': {
			borderColor: blue
		},
		height: '35px',
		'& .MuiSvgIcon-root': {
			color: darkGrey
		}
	}
}))

const StyledFormControl = styled(FormControl)({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: blue
	},
	width: '100%'
})

const StyledSelect = styled(Select)({
	'&:hover': {
		borderColor: grey
	},
	'&:before': {
		borderColor: grey
	},
	'&:after': {
		borderColor: blue
	},
	height: '35px',
	'& .MuiSvgIcon-root': {
		color: darkGrey
	}
})

const getIcons = (index, title, filters) => {
	if (index === 'words') {
		return (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<WordsIcon
					size={15}
					style={{
						color: 'rgba(29, 138, 248, 100%)',
						marginRight: '10px'
					}}
				/>
				<Typography variant='inherit' style={{ color: 'rgba(29, 138, 248, 100%)' }}>
					{title}
				</Typography>
			</Grid>
		)
	}
	if (index === 'emojis') {
		return filters.sources.length === 1 && filters.sources.includes('news') ? (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<EmojiIcon size={15} style={{ marginRight: '10px' }} />
				<Typography variant='inherit'>{title}</Typography>
			</Grid>
		) : (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<EmojiIcon
					size={15}
					style={{
						color: 'rgba(29, 138, 248, 100%)',
						marginRight: '10px'
					}}
				/>
				<Typography variant='inherit' style={{ color: 'rgba(29, 138, 248, 100%)' }}>
					{title}
				</Typography>
			</Grid>
		)
	}
	if (index === 'bio') {
		return filters.sources.includes(['facebook', 'instagram', 'tiktok', 'youtube', 'news']) ? (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<UserIcon size={15} style={{ marginRight: '10px' }} />
				<Typography variant='inherit'>{title}</Typography>
			</Grid>
		) : (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<UserIcon
					size={15}
					style={{
						color: 'rgba(29, 138, 248, 100%)',
						marginRight: '10px'
					}}
				/>
				<Typography variant='inherit' style={{ color: 'rgba(29, 138, 248, 100%)' }}>
					{title}
				</Typography>
			</Grid>
		)
	}
	if (index === 'mentions') {
		return filters.sources.length === 1 && filters.sources.includes('news') ? (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<AtSignIcon size={15} style={{ marginRight: '10px' }} />
				<Typography variant='inherit'>{title}</Typography>
			</Grid>
		) : (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<AtSignIcon
					size={15}
					style={{
						color: 'rgba(29, 138, 248, 100%)',
						marginRight: '10px'
					}}
				/>
				<Typography variant='inherit' style={{ color: 'rgba(29, 138, 248, 100%)' }}>
					{title}
				</Typography>
			</Grid>
		)
	}
	if (index === 'hashtags') {
		return filters.sources.length === 1 && filters.sources.includes('news') ? (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<HashtagIcon size={15} style={{ marginRight: '10px' }} />
				<Typography variant='inherit'>{title}</Typography>
			</Grid>
		) : (
			<Grid container justifyContent='flex-start' alignItems='center'>
				<HashtagIcon
					size={15}
					style={{
						color: 'rgba(29, 138, 248, 100%)',
						marginRight: '10px'
					}}
				/>
				<Typography variant='inherit' style={{ color: 'rgba(29, 138, 248, 100%)' }}>
					{title}
				</Typography>
			</Grid>
		)
	}
}

const SelectOptionList = ({ options, onOptionChange, selectedOption, filters }) => {
	const [optionsList, setOptionsList] = useState(options)
	const [selectedOptionList, setSelectedOptionList] = useState(selectedOption)
	const classes = useStyles()

	useEffect(() => {
		setSelectedOptionList(selectedOption)
	}, [selectedOption])

	useEffect(() => {
		if (!selectedOption || !options.find((it) => it.type === selectedOptionList)) {
			onOptionChange(options[0])
			setSelectedOptionList(options[0].type)
		}
		setOptionsList(options)
	}, [options])

	const handleSelect = (e) => {
		let foundOption = optionsList.find((option) => option.type === e.target.value)
		setSelectedOptionList(foundOption.type)
		onOptionChange(foundOption)
	}

	const label = (index, title) => (
		<div
			style={{
				marginLeft: '15px',
				marginRight: '10px',
				marginTop: '4px',
				fontSize: '14px',
				fontFamily: 'Poppins',
				color: 'rgb(154,154,154)',
				whiteSpace: 'nowrap'
			}}
		>
			{getIcons(index, title, filters)}
		</div>
	)

	return (
		<Collapse in={optionsList.length > 1}>
			<StyledFormControl className={classes.formControl}>
				<StyledSelect
					id='btn-select-wordcloud'
					variant='standard'
					className={classes.select}
					value={selectedOptionList}
					onChange={handleSelect}
					style={{ marginTop: '7px' }}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left'
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left'
						},
						PaperProps: {
							style: {
								marginTop: '0px',
								marginLeft: '-1px',
								borderRadius: '0px 0px 25px 25px',
								backdropFilter: 'blur(10px)',
								backgroundColor: `${isFirefox ? 'rgba(250, 250, 250, 1)' : 'rgba(250, 250, 250, 0.5)'}`,
								borderBottom: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								borderLeft: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								borderRight: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
								boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
							}
						}
					}}
				>
					{optionsList.map((option) => {
						return (
							<MenuItem
								id={`btn-select-wordcloud-${option.type}`}
								disabled={
									(filters.sources.length === 1 && filters.sources.includes('news') && option.type !== 'words') ||
									(['facebook', 'instagram', 'tiktok', 'youtube'].some((it) => filters.sources.includes(it)) &&
										option.type === 'bio')
								}
								key={option.type}
								value={option.type}
							>
								{label(option.type, option.title)}
							</MenuItem>
						)
					})}
				</StyledSelect>
			</StyledFormControl>
		</Collapse>
	)
}

export default SelectOptionList
