import React from 'react'
import Content from '../../components/Content/ContentReport'
import OccurrencesContentBody from './components/ContentBodiesOccurrences/OccurrencesContentBody/OccurrencesContentBody'
import { TwitterIcon, FacebookIcon, InstagramIcon, TiktokIcon, YoutubeIcon, NewsIcon } from 'utils/SystemIcons'

const contentHeaderTwitterIcon = <TwitterIcon size={50} style={{ color: '#1DA1F2' }} />
const contentHeaderFacebookIcon = <FacebookIcon size={50} style={{ color: '#3b5998' }} />
const contentHeaderInstagramIcon = <InstagramIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderTiktokIcon = <TiktokIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderYoutubeIcon = <YoutubeIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderNewsIcon = <NewsIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />

const getOccurrencesIcon = (source) => {
	switch (source) {
		case 'twitter':
			return contentHeaderTwitterIcon
		case 'facebook':
			return contentHeaderFacebookIcon
		case 'instagram':
			return contentHeaderInstagramIcon
		case 'tiktok':
			return contentHeaderTiktokIcon
		case 'youtube':
			return contentHeaderYoutubeIcon
		case 'news':
			return contentHeaderNewsIcon
		default:
			break
	}
}

const OccurrencesContent = ({ filters, cardsIdsObject, pageSize, orderDate, orderRelevance, type, wordCloudName }) => {
	return (
		<Content headerIcon={getOccurrencesIcon(filters.sources)} headerText='Postagens' breakPrint hide={false}>
			<OccurrencesContentBody
				cards={cardsIdsObject}
				filters={filters}
				pageSize={pageSize}
				orderDate={orderDate}
				orderRelevance={orderRelevance}
				type={type}
				wordCloudName={wordCloudName}
			/>
		</Content>
	)
}

export default OccurrencesContent
