import React, { useEffect, useState, memo } from 'react'
import { Grid, Card, IconButton, Slide } from '@mui/material'
import { DotsVerticalIcon, ChevronRightIcon } from 'utils/SystemIcons'
import TagOption from '../TagOption/TagOption'

const isFirefox = typeof InstallTrigger !== 'undefined'

const createHierarchy = (objects) => {
	let result = {}

	objects.forEach((obj) => {
		let { name } = obj

		if (name.includes('_')) {
			let [tagName, subtagName] = name.split('_')

			if (!result[tagName]) {
				result[tagName] = { name: tagName, active: true, subtags: [] }
			}

			let subtagObject = { name, subtagName: subtagName, active: false }
			result[tagName].active = true
			result[tagName].subtags.push(subtagObject)
		} else if (!result[name]) {
			result[name] = { name, active: true, subtags: [] }
		}
	})

	return result
}

const defineInitialFilterNames = (objects) => {
	let result = []

	objects.forEach((obj) => {
		let { name } = obj

		!name.includes('_') && result.push(name)
	})

	return result
}

const TagSideMenu = ({ series, onChangeFilter }) => {
	const [openSideMenu, setOpenSideMenu] = useState(false)
	const [hierarchy, setHierarchy] = useState({})
	const [filterNames, setFilterNames] = useState(defineInitialFilterNames(series))

	useEffect(() => {
		setHierarchy(createHierarchy(series))
	}, [series])

	useEffect(() => {
		onChangeFilter(filterNames, openSideMenu)
	}, [filterNames])

	const onClickSideMenuButton = () => {
		setOpenSideMenu(!openSideMenu)
	}

	const onChangeHierarchy = (obj) => {
		if (obj.active === false) {
			let newfilterNames = [...filterNames]
			newfilterNames.push(obj.name)
			setFilterNames(newfilterNames)
		} else {
			let newfilterNames = [...filterNames]
			newfilterNames = filterNames.filter((name) => name !== obj.name)
			setFilterNames(newfilterNames)
		}

		if (!obj.name.includes('_')) {
			let newHierarchy = { ...hierarchy }
			newHierarchy[obj.name].active = !newHierarchy[obj.name].active
			setHierarchy(newHierarchy)
		} else {
			let [tagName, subtagName] = obj.name.split('_')
			let newHierarchy = { ...hierarchy }
			let subtag = newHierarchy[tagName].subtags.find((subtag) => subtag.subtagName === subtagName)
			subtag.active = !subtag.active
			setHierarchy(newHierarchy)
		}
	}

	const onChangeAllSubtags = (obj, active) => {
		let allSubtagNames = obj.subtags.map((subtag) => subtag.name)
		let newfilterNames = []
		if (active) {
			let mergedArray = [...filterNames, ...allSubtagNames]
			newfilterNames = [...new Set(mergedArray)]
		} else {
			newfilterNames = [...filterNames]
			newfilterNames = newfilterNames.filter((el) => !allSubtagNames.includes(el))
		}
		setFilterNames(newfilterNames)

		let newSubtags = obj.subtags.map((subtag) => ({ ...subtag, active: active }))
		let newHierarchy = { ...hierarchy }
		newHierarchy[obj.name].subtags = newSubtags
		setHierarchy(newHierarchy)
	}

	return (
		<>
			<IconButton id='btn-open-tags-menu' color='primary' onClick={onClickSideMenuButton}>
				<DotsVerticalIcon size={25} style={{ color: '#1d8cf8' }} />
			</IconButton>
			<Slide direction='left' in={openSideMenu} mountOnEnter unmountOnExit>
				<Grid style={{ position: 'absolute', zIndex: '100' }}>
					<Card
						style={{
							width: '400px',
							height: '86vh',
							borderRadius: '20px',
							backdropFilter: 'blur(10px)',
							backgroundColor: `${isFirefox ? 'rgba(245, 245, 245, 1)' : 'rgba(245, 245, 245, 0.5)'}`,
							border: `1px solid ${isFirefox ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}`,
							boxShadow: '0 2px 5px 0 rgba( 200, 200, 200, 0.7 )'
						}}
					>
						<IconButton id='btn-toggle-tags-menu' color='primary' onClick={onClickSideMenuButton}>
							<ChevronRightIcon size={25} style={{ color: '#1d8cf8' }} />
						</IconButton>
						<div style={{ height: '78vh', overflowY: 'auto' }}>
							<Grid container>
								{Object.keys(hierarchy).map((name, index) => {
									return (
										<TagOption
											key={name + index}
											obj={hierarchy[name]}
											onChangeHierarchy={onChangeHierarchy}
											onChangeAllSubtags={onChangeAllSubtags}
										/>
									)
								})}
							</Grid>
						</div>
					</Card>
				</Grid>
			</Slide>
		</>
	)
}

export default memo(TagSideMenu)
