import React from 'react'
import Content from '../../../../components/Content/ContentReport'
import { AnalysisIcon, TwitterIcon, FacebookIcon, InstagramIcon, TiktokIcon } from 'utils/SystemIcons'
import TwitterContentBodyAccount from './components/ContentBodiesAccount/TwitterContentBodyAccount/TwitterContentBodyAccount'
import AnalysisContentBodyAccount from './components/ContentBodiesAccount/AnalysisContentBodyAccount/AnalysisContentBodyAccount'
import FacebookContentBodyAccount from './components/ContentBodiesAccount/FacebookContentBodyAccount/FacebookContentBodyAccount'
import InstagramContentBodyAccount from './components/ContentBodiesAccount/InstagramContentBodyAccount/InstagramContentBodyAccount'
import TiktokContentBodyAccount from './components/ContentBodiesAccount/TiktokContentBodyAccount/TiktokContentBodyAccount'

const contentHeaderAnalysisIcon = <AnalysisIcon size={50} style={{ color: '#1d8cf8' }} />
const contentHeaderTwitterIcon = <TwitterIcon size={50} style={{ color: '#1DA1F2' }} />
const contentHeaderFacebookIcon = <FacebookIcon size={50} style={{ color: '#3b5998' }} />
const contentHeaderInstagramIcon = <InstagramIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />
const contentHeaderTiktokIcon = <TiktokIcon size={50} style={{ color: '#1DA1F2', marginTop: '10px' }} />

const ReportAccount = ({
	verifiedDatasourcesSummary,
	cardsIdsObject,
	filters,
	viewType,
	graphInterval,
	timelineTwitterEngagement,
	timelineFacebookEngagement,
	timelineInstagramEngagement,
	timelineTiktokEngagement,
	summaryTwitter,
	summaryFacebook,
	summaryInstagram,
	summaryTiktok,
	timelineTwitterPosts,
	timelineFacebookPosts,
	timelineInstagramPosts,
	timelineTiktokPosts,
	twitter,
	facebook,
	instagram,
	tiktok
}) => {
	return (
		<>
			<Content
				headerIcon={contentHeaderAnalysisIcon}
				headerText='Análise de Sentimentos'
				breakPrint
				hide={
					(!verifiedDatasourcesSummary.twitter &&
						!verifiedDatasourcesSummary.facebook &&
						!verifiedDatasourcesSummary.instagram &&
						!verifiedDatasourcesSummary.tiktok &&
						!verifiedDatasourcesSummary.news) ||
					(!twitter && !facebook && !instagram && !tiktok) ||
					(!timelineTwitterEngagement &&
						!timelineTwitterPosts &&
						!timelineFacebookEngagement &&
						!timelineFacebookPosts &&
						!timelineInstagramEngagement &&
						!timelineInstagramPosts &&
						!timelineTiktokEngagement &&
						!timelineTiktokPosts)
				}
			>
				<AnalysisContentBodyAccount
					cards={cardsIdsObject}
					filters={filters}
					partialLoad={{
						twitter: verifiedDatasourcesSummary.twitter,
						facebook: verifiedDatasourcesSummary.facebook,
						instagram: verifiedDatasourcesSummary.instagram,
						tiktok: verifiedDatasourcesSummary.tiktok,
						news: verifiedDatasourcesSummary.news
					}}
					viewType={viewType}
					graphInterval={graphInterval}
					timelineTwitterEngagement={timelineTwitterEngagement}
					timelineTwitterPosts={timelineTwitterPosts}
					timelineFacebookEngagement={timelineFacebookEngagement}
					timelineFacebookPosts={timelineFacebookPosts}
					timelineInstagramEngagement={timelineInstagramEngagement}
					timelineInstagramPosts={timelineInstagramPosts}
					timelineTiktokEngagement={timelineTiktokEngagement}
					timelineTiktokPosts={timelineTiktokPosts}
				/>
			</Content>
			{verifiedDatasourcesSummary.twitter && (
				<Content headerIcon={contentHeaderTwitterIcon} headerText='Twitter' breakPrint hide={!verifiedDatasourcesSummary.twitter || !twitter}>
					<TwitterContentBodyAccount filters={filters} cards={cardsIdsObject} summaryTwitter={summaryTwitter} />
				</Content>
			)}
			{verifiedDatasourcesSummary.facebook && (
				<Content
					headerIcon={contentHeaderFacebookIcon}
					headerText='Facebook'
					breakPrint
					hide={!verifiedDatasourcesSummary.facebook || !facebook}
				>
					<FacebookContentBodyAccount filters={filters} cards={cardsIdsObject} summaryFacebook={summaryFacebook} />
				</Content>
			)}
			{verifiedDatasourcesSummary.instagram && (
				<Content
					headerIcon={contentHeaderInstagramIcon}
					headerText='Instagram'
					breakPrint
					hide={!verifiedDatasourcesSummary.instagram || !instagram}
				>
					<InstagramContentBodyAccount filters={filters} cards={cardsIdsObject} summaryInstagram={summaryInstagram} />
				</Content>
			)}
			{verifiedDatasourcesSummary.tiktok && (
				<Content headerIcon={contentHeaderTiktokIcon} headerText='Tiktok' breakPrint hide={!verifiedDatasourcesSummary.tiktok || !tiktok}>
					<TiktokContentBodyAccount filters={filters} cards={cardsIdsObject} summaryTiktok={summaryTiktok} />
				</Content>
			)}
		</>
	)
}

export default ReportAccount
